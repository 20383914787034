import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { CreditCardOff } from 'tabler-icons-react';

import {
   NoItemsNotification,
   SectionContainer,
   StyledButton,
   StyledTable,
} from 'components/shared';
import { costTypeLabels } from 'constants/costTypeLabels';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import {
   additionalCostsClientTableHeaders,
   additionalCostsSupplierTableHeaders,
} from 'constants/tableHeaders';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { Charge, OrderStatus } from 'interfaces';
import MobileCard from 'pages/ClientPanel/OrdersPage/OrderDetailsPage/MobileCard/MobileCard';
import { Banner, TableCell, TableRow } from 'storybook';
import { getDayLabel } from 'utils/functions';
import { currencyFormatter } from 'utils/numberFormatter';

import styles from './AdditionalChargesSection.module.css';

type AdditionalChargesSectionProps = {
   additionalCharges: Charge[];
   chargesTotalAmount?: number;
   onAddAdditionalCharges?: () => void;
   supplierMode?: boolean;
   orderStatus?: OrderStatus;
   onClickReportDamagedLostPallets?: () => void;
};

const AdditionalChargesSection = ({
   additionalCharges,
   chargesTotalAmount,
   onClickReportDamagedLostPallets,
   onAddAdditionalCharges,
   supplierMode,
   orderStatus,
}: AdditionalChargesSectionProps) => {
   const { isMobile } = useResponsiveLayout();

   const renderContent = () => {
      if (!supplierMode && isMobile) {
         if (!additionalCharges.length) {
            return (
               <NoItemsNotification
                  icon={<CreditCardOff color="#A6A6B2" size={48} />}
                  description="Brak dodatkowych opłat."
               />
            );
         } else {
            return (
               <div>
                  {additionalCharges.map((additionalCost, index) => (
                     <MobileCard key={index} data={additionalCost} />
                  ))}
               </div>
            );
         }
      } else {
         return (
            <StyledTable
               columnHeaders={
                  supplierMode
                     ? additionalCostsSupplierTableHeaders
                     : additionalCostsClientTableHeaders
               }
               className={classNames({ [styles.tableWithChargesSummary]: supplierMode })}
            >
               {isEmpty(additionalCharges) ? (
                  <tr>
                     <td colSpan={supplierMode ? 6 : 4}>
                        <NoItemsNotification
                           icon={<CreditCardOff color="#A6A6B2" size={48} />}
                           description="Brak dodatkowych opłat."
                           desktopSmallNoItemsNotification
                        />
                     </td>
                  </tr>
               ) : (
                  <>
                     {additionalCharges.map((additionalCost, index) => (
                        <TableRow key={index}>
                           <TableCell>
                              {additionalCost.palletType
                                 ? PALLET_TYPE_LABELS[additionalCost.palletType]
                                 : '-'}
                           </TableCell>
                           <TableCell>
                              {additionalCost.otherChargeName ||
                                 costTypeLabels[additionalCost.chargeType]}
                           </TableCell>
                           <TableCell>
                              {additionalCost.retentionPeriodDays
                                 ? `${additionalCost.retentionPeriodDays} ${getDayLabel(
                                      additionalCost.retentionPeriodDays,
                                   )}`
                                 : '-'}
                           </TableCell>
                           <TableCell align="right">
                              {additionalCost.palletsAmount || '-'}
                           </TableCell>
                           {supplierMode && (
                              <>
                                 <TableCell align="right">
                                    {additionalCost.price
                                       ? currencyFormatter.format(additionalCost.price)
                                       : '-'}
                                 </TableCell>
                                 <TableCell align="right">
                                    {additionalCost.totalAmount
                                       ? currencyFormatter.format(additionalCost.totalAmount)
                                       : '-'}
                                 </TableCell>
                              </>
                           )}
                        </TableRow>
                     ))}
                     {supplierMode && additionalCharges.length !== 0 && chargesTotalAmount && (
                        <tr className={styles.chargesTotalAmountRow}>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td></td>
                           <td colSpan={2} className={styles.chargesTotalAmountCell}>
                              <div className={styles.chargesTotalAmountContainer}>
                                 Suma: <span>{currencyFormatter.format(chargesTotalAmount)}</span>
                              </div>
                           </td>
                        </tr>
                     )}
                  </>
               )}
            </StyledTable>
         );
      }
   };

   return (
      <SectionContainer title="Dodatkowe opłaty">
         {supplierMode && orderStatus === 'DELIVERED' && (
            <StyledButton
               variant="outlined-primary"
               text="Zgłoś uszkodzenie / zaginięcie palet"
               onClick={onClickReportDamagedLostPallets}
               className={styles.additionalActionBtn}
            />
         )}
         {supplierMode && orderStatus === 'DURING_BILLING' && (
            <StyledButton
               variant="outlined-primary"
               text="Dodaj obciążenia"
               onClick={onAddAdditionalCharges}
               className={styles.additionalActionBtn}
            />
         )}
         {renderContent()}
         {!supplierMode && additionalCharges.length !== 0 && (
            <Banner
               style={{ marginTop: 24 }}
               fullWidth
               variant="info"
               children="Jeśli jesteś zainteresowany sumą kosztów dodatkowych opłat, skontaktuj się z biurem obsługi klienta."
            />
         )}
      </SectionContainer>
   );
};

export default AdditionalChargesSection;
