import { Fragment } from 'react';

import classNames from 'classnames';

import { PalletRoundedIcon } from 'assets';
import { NoItemsNotification, SectionContainer, StyledTable } from 'components/shared';
import {
   lostDamagedPalletsSupplierOrderDetailsTableHeaders,
   lostDamagedPalletsSupplierReportDetailsTableHeaders,
} from 'constants/tableHeaders';
import { DamagedPalletDTO, LostDamagedPalletReport } from 'interfaces';

import LostDamagedPalletReportsTableItem from './LostDamagedPalletReportsTableItem/LostDamagedPalletReportsTableItem';
import LostDamagedPalletsTableItem from './LostDamagedPalletsTableItem/LostDamagedPalletsTableItem';
import styles from './LostDamagedPalletsInDeliverySection.module.css';

type LostDamagedPalletsInDeliverySectionProps =
   | OrderLostDamagedPalletsInDeliverySectionProps
   | ReportLostDamagedPalletsInDeliverySectionProps;

type OrderLostDamagedPalletsInDeliverySectionProps = {
   title: string;
   mode: 'SUPPLIER_ORDER_DETAILS';
   lostDamagedPalletsData: LostDamagedPalletReport[];
   isLoading?: boolean;
   className?: string;
};

type ReportLostDamagedPalletsInDeliverySectionProps = {
   title: string;
   mode: 'PALLET_REPORT_DETAILS';
   lostDamagedPalletsData: DamagedPalletDTO[];
   isLoading?: boolean;
   className?: string;
};

const TABLE_HEADERS = {
   SUPPLIER_ORDER_DETAILS: lostDamagedPalletsSupplierOrderDetailsTableHeaders,
   PALLET_REPORT_DETAILS: lostDamagedPalletsSupplierReportDetailsTableHeaders,
};

const LostDamagedPalletsInDeliverySection = ({
   title,
   mode,
   lostDamagedPalletsData,
   isLoading,
   className,
}: LostDamagedPalletsInDeliverySectionProps) => {
   let tableRows;

   if (mode === 'PALLET_REPORT_DETAILS') {
      tableRows = lostDamagedPalletsData.map((lostDamagedPalletsItem, rowIndex) => (
         <LostDamagedPalletsTableItem
            key={rowIndex}
            lostDamagedPalletsItem={lostDamagedPalletsItem}
         />
      ));
   } else {
      tableRows = lostDamagedPalletsData.map((lostDamagedPalletReport, mainRowIndex) => (
         <Fragment key={mainRowIndex}>
            <LostDamagedPalletReportsTableItem
               lostDamagedPalletReport={lostDamagedPalletReport}
               isFirstRow={!mainRowIndex}
            />
            {lostDamagedPalletReport.damagedPallets.map(
               (damagedPalletsItem, innerRowIndex) =>
                  innerRowIndex !== 0 && (
                     <LostDamagedPalletsTableItem
                        key={innerRowIndex}
                        lostDamagedPalletsItem={damagedPalletsItem}
                        innerTableRow
                     />
                  ),
            )}
         </Fragment>
      ));
   }

   return (
      <SectionContainer
         title={title}
         isLoading={isLoading}
         className={classNames(styles.section, className)}
      >
         <StyledTable columnHeaders={TABLE_HEADERS[mode]}>
            {tableRows.length ? (
               tableRows
            ) : (
               <tr>
                  <td colSpan={TABLE_HEADERS[mode].length}>
                     <NoItemsNotification
                        icon={<PalletRoundedIcon />}
                        description="Brak zgłoszonych uszkodzeń."
                        desktopSmallNoItemsNotification
                     />
                  </td>
               </tr>
            )}
         </StyledTable>
      </SectionContainer>
   );
};

export default LostDamagedPalletsInDeliverySection;
