import { Loader } from '@mantine/core';
import classNames from 'classnames';

import { Button, ButtonProps } from 'storybook';
import { getButtonLoaderColor } from 'utils/functions';

import styles from './StyledButton.module.css';

interface StyledButtonProps extends Omit<ButtonProps, 'loader'> {
   variant?: ButtonVariants;
}

export type ButtonVariants =
   | 'filled-primary'
   | 'outlined-primary'
   | 'filled-danger'
   | 'outlined-danger'
   | 'filled-warning'
   | 'outlined-warning'
   | 'text';

const StyledButton = ({ variant, ...props }: StyledButtonProps) => {
   return (
      <Button
         {...props}
         loader={<Loader size="xs" color={variant ? getButtonLoaderColor(variant) : undefined} />}
         className={classNames(
            styles.btn,
            {
               [styles.filledPrimary]: variant === 'filled-primary',
               [styles.outlinedPrimary]: variant === 'outlined-primary',
               [styles.filledDanger]: variant === 'filled-danger',
               [styles.outlinedDanger]: variant === 'outlined-danger',
               [styles.filledWarning]: variant === 'filled-warning',
               [styles.outlinedWarning]: variant === 'outlined-warning',
               [styles.text]: variant === 'text',
               [styles.disabled]: props.disabled,
               [styles.loading]: props.loading,
            },
            props.className,
         )}
      />
   );
};

export default StyledButton;
