import OrderTerms from 'components/PlaceOrderFormCommon/OrderTerms/OrderTerms';
import { SectionContainer, StyledButton, ToolTip } from 'components/shared';
import { OrderSummaryAndOrderDetailsPallets } from 'interfaces/commonInterfacesAndTypes';
import { Banner, Divider } from 'storybook';

import PalletAmountList from './PalletAmountList/PalletAmountList';
import PalletAmountSummary from './PalletAmountSummary/PalletsAmountSummary';
import OverviewPalletConfigurationCard from './PalletConfigurationCard/PalletConfigurationCard';
import PalletsSummaryWithTotalPriceAndAmount from './PalletsSummaryWithTotalPriceAndAmount/PalletsSummaryWithTotalPriceAndAmount';
import styles from './OverviewPalletsConfigurationSection.module.css';

type OverviewPalletsConfigurationSectionProps = {
   mode?: 'ORDER_DETAILS' | 'PLACE_ORDER';
   logisticMinimumAchieved?: boolean;
   isOrderFree?: boolean;
   isClientView?: boolean;
   isDisabledEditAction?: boolean;
   pallets: OrderSummaryAndOrderDetailsPallets;
   palletsSum: number;
   palletsTotalPrice?: number;
   onClickEdit?: () => void;
};

const OverviewPalletsConfigurationSection = ({
   mode,
   pallets,
   isOrderFree,
   isClientView,
   isDisabledEditAction,
   logisticMinimumAchieved,
   palletsSum,
   palletsTotalPrice,
   onClickEdit,
}: OverviewPalletsConfigurationSectionProps) => {
   const summaryContainerContent =
      palletsTotalPrice !== undefined ? (
         <PalletsSummaryWithTotalPriceAndAmount
            palletsTotalPrice={palletsTotalPrice}
            palletsSum={palletsSum}
            isOrderFree={!!isOrderFree}
         />
      ) : (
         <>
            <PalletAmountList pallets={pallets} />
            <PalletAmountSummary palletsSum={palletsSum} />
         </>
      );

   return (
      <SectionContainer title="Konfiguracja palet">
         {logisticMinimumAchieved === false && (
            <Banner
               style={{ marginBottom: 24 }}
               children={
                  mode === 'ORDER_DETAILS'
                     ? 'Zamówienie nie spełnia minimum logistycznego.'
                     : 'Zamówienie nie spełnia minimum logistycznego. Edytuj ilości palet lub wyślij zapytanie, a obsługa klienta skontaktuje się z Tobą jak najszybciej.'
               }
               variant="warning"
               fullWidth
            />
         )}
         {onClickEdit && (
            <ToolTip
               content="Zakończ edycję dostawy, aby edytować palety"
               variant="right"
               visible={isDisabledEditAction}
            >
               <StyledButton
                  variant="outlined-primary"
                  text="Edytuj palety"
                  onClick={onClickEdit}
                  disabled={isDisabledEditAction}
               />
            </ToolTip>
         )}
         <div className={styles.summaryCardsContainer}>
            {pallets.map(pallet => (
               <OverviewPalletConfigurationCard
                  key={pallet.name}
                  pallet={pallet}
                  isClientView={isClientView}
                  isOrderFree={isOrderFree}
               />
            ))}
         </div>
         <Divider variant="horizontal" />
         <div className={styles.summaryContainer}>{summaryContainerContent}</div>
         <OrderTerms />
      </SectionContainer>
   );
};

export default OverviewPalletsConfigurationSection;
