import { CustomModal, CustomModalProps } from '../shared';

import styles from './CancelPalletReportModal.module.css';

type DeleteStorageModalProps = CustomModalProps & {
   onAcceptClick: () => void;
   loading: boolean;
};

const CancelPalletReportModal = ({
   onClose,
   opened,
   loading,
   onAcceptClick,
}: DeleteStorageModalProps) => (
   <CustomModal
      icon="ALERT_TRIANGLE"
      innerTitle="Anuluj zgłoszenie"
      onClose={onClose}
      opened={opened}
      isLoading={loading}
      modalContentClassName={styles.modalContent}
      primaryButtonProps={{
         text: 'Anuluj zgłoszenie',
         variant: 'filled-danger',
         onClick: onAcceptClick,
      }}
      secondaryButtonProps={{
         text: 'Wróć',
         variant: 'outlined-danger',
         onClick: onClose,
      }}
   >
      <div className={styles.descriptionContainer}>
         <p className={styles.plainText}>Czy jesteś pewny, że chcesz anulować to zgłoszenie?</p>
         <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
         <p className={styles.boldRedText}>Zgłoszone palety trafią na saldo nieuszkodzonych.</p>
      </div>
   </CustomModal>
);

export default CancelPalletReportModal;
