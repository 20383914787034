import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '@mantine/core';
import classNames from 'classnames';

import { TruckDeliveryEmptyPallet } from '../../../assets';
import { pickUpsTableHeaders } from '../../../constants/tableHeaders';
import { PalletPickUpDTO } from '../../../interfaces';
import { NoItemsNotificationWithLink, StyledTable } from '../../shared';
import { PalletPickUpTableItem } from '../index';

import styles from './PalletPickUpsTable.module.css';

type PalletPickUpsTableProps = {
   pickUps: PalletPickUpDTO[];
   handleLoadNextItems: () => void;
   last: boolean;
   firstPickUpFetchLoading: boolean;
   openCreatePalletPickUpModal: () => void;
};

const PalletPickUpsTable = ({
   pickUps,
   handleLoadNextItems,
   last,
   firstPickUpFetchLoading,
   openCreatePalletPickUpModal,
}: PalletPickUpsTableProps) => (
   <div className={styles.tableLoaderContainer}>
      <div id="tableContainerId" className={styles.tableContainer}>
         <InfiniteScroll
            dataLength={pickUps.length}
            next={handleLoadNextItems}
            hasMore={!last && !firstPickUpFetchLoading}
            loader={
               <div className={styles.loader}>
                  <Loader color={'var(--primary-green)'} />
               </div>
            }
            scrollableTarget="tableContainerId"
         >
            <StyledTable
               columnHeaders={pickUpsTableHeaders}
               headerClassName={styles.tableHeader}
               className={styles.pickUpsTable}
            >
               {pickUps.map(pickUp => (
                  <PalletPickUpTableItem key={pickUp.id} pickUp={pickUp} />
               ))}
            </StyledTable>
         </InfiniteScroll>
         {!pickUps.length && (
            <div className={styles.emptyListContainer}>
               <NoItemsNotificationWithLink
                  icon={<TruckDeliveryEmptyPallet />}
                  desctiption="Nie zgłoszono jeszcze żadnych odbiorów"
                  linkText="Zgłoś odbiór palet"
                  onClick={openCreatePalletPickUpModal}
               />
            </div>
         )}
      </div>
      {firstPickUpFetchLoading && (
         <div className={classNames(styles.loader, styles.mainTableLoader)}>
            <Loader color={'var(--primary-green)'} />
         </div>
      )}
   </div>
);
export default PalletPickUpsTable;
