import classNames from 'classnames';

import { ImagesContainer } from 'components/shared';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { DamagedPalletDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './LostDamagedPalletsTableItem.module.css';

type LostDamagedPalletsTableItemProps = {
   lostDamagedPalletsItem: DamagedPalletDTO;
   innerTableRow?: boolean;
};

const LostDamagedPalletsTableItem = ({
   lostDamagedPalletsItem,
   innerTableRow,
}: LostDamagedPalletsTableItemProps) => (
   <TableRow className={classNames({ [styles.innerTableRow]: innerTableRow })}>
      {innerTableRow && <TableCell />}
      <TableCell>{PALLET_TYPE_LABELS[lostDamagedPalletsItem.type]}</TableCell>
      <TableCell align="right">{lostDamagedPalletsItem.amount}</TableCell>
      <TableCell>
         <ImagesContainer imageURLs={lostDamagedPalletsItem.images} />
      </TableCell>
   </TableRow>
);

export default LostDamagedPalletsTableItem;
