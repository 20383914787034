import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { Pencil, Trash } from 'tabler-icons-react';

import AdmissionPalletsDistributionCenterModal from 'components/AdmissionPalletsDistributionCenterModal/AdmissionPalletsDistributionCenterModal';
import DistributionCenterData from 'components/DistributionCenterData/DistributionCenterData';
import DistributionCenterPallets from 'components/DistributionCenterPallets/DistributionCenterPallets';
import EditPalletsCommercialNetworkModal from 'components/EditPalletsCommercialNetworkModal/EditPalletsCommercialNetworkModal';
import { ActionsDropdown, SectionContainer, StyledButton } from 'components/shared';
import { AddOrEditDistributionCenterModal } from 'components/SupplierPanel';
import TransferPalletsDistributionCenterModal from 'components/TransferPalletsDistributionCenterModal/TransferPalletsDistributionCenterModal';
import { DISTRIBUTION_CENTER_PAGE_BANNERS } from 'constants/banners';
import { distributionCenterEmptyObject } from 'constants/commercialNetworksFormDefaultValues';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import WithAuth from 'hoc/withAuth';
import {
   BalanceDistributionCenterForHook,
   BannerData,
   CommercialNetwork,
   DistributionCenter,
   DistributionCenterDTO,
   TransferPalletBalance,
} from 'interfaces';
import RemoveDistributionCenterModal from 'pages/SupplierPanel/RemoveCommercialNetworkModal/RemoveCommercialNetworkModal';
import { HTTPService } from 'service';
import { TransferPalletsDistributionCenter } from 'service/http/requests';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { getDefaultTransferPalletsValues, scrollToElement } from 'utils/functions';
import { transformObjectPalletToArray } from 'utils/functions/transformObjectPalletToArray';

import styles from './DistributionCenterDetailsPage.module.css';

const DistributionCenterDetails = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [distributionCenter, setDistributionCenter] = useState<DistributionCenter>(
      distributionCenterEmptyObject,
   );
   const [removeDistributionCenter, setRemoveDistributionCenter] = useState(false);
   const [commercialNetwork, setCommercialNetwork] = useState<CommercialNetwork>({
      id: '',
      name: '',
   });
   const [isOpenedEditBalanceModal, setIsOpenedEditBalanceModal] = useState(false);
   const [shouldShowInnerBanner, setShouldShowInnerBanner] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const headerContainerRef = useRef<null | HTMLDivElement>(null);
   const { id: distributionCenterId } = useParams();
   const { state: locationState } = useLocation();
   const [loadingInModal, setLoadingInModal] = useState(false);
   const [isOpenedAdmission, setIsOpenedAdmission] = useState(false);
   const [isOpenedTransferPalletsModal, setIsOpenedTransferPalletsModal] = useState(false);
   const [isOpenedEditDistributionCenter, setIsOpenedEditDistributionCenter] = useState(false);

   const handleOpenDeleteModal = () => {
      setRemoveDistributionCenter(true);
   };
   const distributionCenterDetailsOptions = [
      {
         icon: <Trash />,
         text: 'Usuń adres',
         onClick: handleOpenDeleteModal,
         dangerOption: true,
      },
   ];

   const fetchDistributionCenter = useCallback(() => {
      setIsLoading(true);
      HTTPService.getDistributionCenter(locationState.commercialNetworkId, distributionCenterId!)
         .then(({ data: distributionCenterData }) => {
            HTTPService.getCommercialNetworksDetails(
               distributionCenterData.commercialNetworkId!,
            ).then(response => {
               setCommercialNetwork(response.data);
            });
            const distributionCenterInformation: DistributionCenter = {
               ...distributionCenterData,
               todayBalance: {
                  ...distributionCenterData.todayBalance,
                  balance: transformObjectPalletToArray(
                     distributionCenterData.todayBalance.balance!,
                  ),
               },
            };
            setDistributionCenter(distributionCenterInformation);
         })
         .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA))
         .finally(() => setIsLoading(false));
   }, [distributionCenterId, locationState.commercialNetworkId]);

   useEffect(() => {
      fetchDistributionCenter();
   }, [fetchDistributionCenter]);

   const handleDataChange = (date: Date) => {
      const dateFormatted = new Date(date).toLocaleDateString('en-GB');
      HTTPService.getBalanceForDistributionCenter(
         locationState.commercialNetworkId,
         distributionCenterId!,
         dateFormatted,
      )
         .then(response => {
            setDistributionCenter(prevState => ({
               ...prevState,
               todayBalance: {
                  ...prevState.todayBalance,
                  balance: transformObjectPalletToArray(response.data.balance),
                  date: response.data.date,
                  id: response.data.id,
               },
            }));
         })
         .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA, true));
   };

   const handleAdmissionPalletsClick = () => {
      setIsOpenedAdmission(true);
   };
   const handleTransferPalletsClick = () => {
      setIsOpenedTransferPalletsModal(true);
   };

   const handleEditPallets = () => {
      setIsOpenedEditBalanceModal(true);
   };

   const transferPallets = (
      balanceDistribution: TransferPalletBalance[],
      commercialNetworkId: string,
      setError: (value: string) => void,
   ) => {
      setLoadingInModal(true);
      const date = distributionCenter.todayBalance.date;
      const transferedPalletBalances: TransferPalletsDistributionCenter[] = balanceDistribution.map(
         balance => ({
            palletType: balance.palletName,
            balance: {
               valid: Number(balance.validAvailablePallets),
               invalid: Number(balance.invalidAvailablePallets),
            },
         }),
      );
      HTTPService.transferPalletsCommercialNetwork({
         commercialNetworkId,
         distributionCenterId: distributionCenterId!,
         transferedPalletBalances,
         date,
      })
         .then(() => {
            setBannerData(DISTRIBUTION_CENTER_PAGE_BANNERS.TRANSFER_PALLETS_SUCCESS);
            setIsOpenedTransferPalletsModal(false);
            fetchDistributionCenter();
         })
         .catch(error => {
            const errorDescription =
               error.response.data.code === 'TRANSFER_FOR_COLLECTION_NEGATIVE_AMOUNT'
                  ? 'Nie masz tylu palet ze statusem "Zwroty"'
                  : DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION;
            errorHandler(error, () => setError(errorDescription));
         })
         .finally(() => setLoadingInModal(false));
   };

   const handleSubmit = (
      balanceDistribution: BalanceDistributionCenterForHook[],
      commercialNetworkId: string,
      balanceId: string,
   ) => {
      setLoadingInModal(true);
      const formattedBalance = balanceDistribution.map(balance => ({
         palletType: balance.palletName,
         balance: {
            availableForCollection: {
               valid: Number(balance.validAvailablePallets),
               invalid: Number(balance.invalidAvailablePallets),
            },
            palletReturn: {
               valid: Number(balance.validReturnPallets),
               invalid: Number(balance.invalidReturnPallets),
            },
         },
      }));

      distributionCenterId &&
         HTTPService.editBalanceForDistributionCenter(
            commercialNetworkId,
            distributionCenterId,
            balanceId,
            formattedBalance,
         )
            .then(({ data: distributionCenterData }) => {
               setDistributionCenter(prevState => ({
                  ...prevState,
                  todayBalance: {
                     ...prevState.todayBalance,
                     balance: transformObjectPalletToArray(distributionCenterData.balance),
                     balanceSum: distributionCenterData.balanceSum,
                     date: distributionCenterData.date,
                     id: distributionCenterData.id,
                  },
               }));
            })
            .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION, true))
            .finally(() => setLoadingInModal(false));
      setShouldShowInnerBanner(true);
      setIsOpenedEditBalanceModal(false);
   };

   const admissionPalletsIntoStorage = (
      palletBalance: TransferPalletBalance[],
      destination: { userAddressId?: string; storageId: string },
      setError: (value: string) => void,
      setRows: (rows: TransferPalletBalance[]) => void,
   ) => {
      setLoadingInModal(true);
      const date = distributionCenter.todayBalance.date;
      const formattedBalance: TransferPalletsDistributionCenter[] = palletBalance.map(balance => ({
         palletType: balance.palletName,
         balance: {
            valid: Number(balance.validAvailablePallets),
            invalid: Number(balance.invalidAvailablePallets),
         },
      }));
      HTTPService.admissionPalletsCommercialNetwork({
         commercialNetworkId: commercialNetwork.id,
         distributionCenterId: distributionCenterId!,
         palletBalances: formattedBalance,
         date,
         destination: {
            userAddressId: destination.userAddressId!,
            storageId: destination.storageId,
         },
      })
         .then(() => {
            fetchDistributionCenter();
            setIsOpenedAdmission(false);
            setBannerData(DISTRIBUTION_CENTER_PAGE_BANNERS.ADMISSION_PALLETS);
            setRows(getDefaultTransferPalletsValues());
         })
         .catch(error => {
            const errorDescription =
               error.response.data.code === 'TRANSFER_FOR_COLLECTION_NEGATIVE_AMOUNT'
                  ? 'Nie masz tylu palet w Magazynie początkowym'
                  : DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION;
            errorHandler(error, () => setError(errorDescription));
         })
         .finally(() => {
            setLoadingInModal(false);
         });
   };

   const handleSuccessEditDistributionCenter = (data: DistributionCenterDTO) => {
      setDistributionCenter(prev => ({
         ...prev,
         address: data.address,
         contactEmail: data.contactEmail,
         phoneNumber: data.phoneNumber,
      }));
   };

   const handleError = (error: unknown, errorDescription: string, withScrollingToTop?: boolean) => {
      if (withScrollingToTop) {
         scrollToElement(headerContainerRef);
      }
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: errorDescription,
         }),
      );
   };

   return (
      <>
         {isLoading ? (
            <div className={styles.loader}>
               <Loader color={'var(--light-green)'} />
            </div>
         ) : (
            <>
               <RemoveDistributionCenterModal
                  opened={removeDistributionCenter}
                  onClose={() => setRemoveDistributionCenter(false)}
                  selectedItem={distributionCenter}
                  mode="DC"
               />
               <AddOrEditDistributionCenterModal
                  commercialNetwork={commercialNetwork}
                  opened={!!isOpenedEditDistributionCenter}
                  onClose={() => setIsOpenedEditDistributionCenter(false)}
                  onSuccess={handleSuccessEditDistributionCenter}
                  mode="edit"
                  distributionCenter={distributionCenter}
               />
               {isOpenedEditBalanceModal && (
                  <EditPalletsCommercialNetworkModal
                     opened
                     setEditDistributionPallets={setIsOpenedEditBalanceModal}
                     distributionCenter={distributionCenter}
                     isLoading={loadingInModal}
                     onSubmit={handleSubmit}
                     mode="edit"
                  />
               )}
               <TransferPalletsDistributionCenterModal
                  setTransferPallets={setIsOpenedTransferPalletsModal}
                  isOpened={isOpenedTransferPalletsModal}
                  distributionCenter={distributionCenter}
                  isLoading={loadingInModal}
                  onSubmit={transferPallets}
                  mode="transfer"
               />
               <AdmissionPalletsDistributionCenterModal
                  opened={isOpenedAdmission}
                  setIsOpenedAdmission={setIsOpenedAdmission}
                  commercialNetwork={commercialNetwork}
                  distributionCenter={distributionCenter}
                  onSubmit={admissionPalletsIntoStorage}
               />
               <div className={styles.detailsHeader}>
                  <div ref={headerContainerRef} className={styles.header}>
                     <div className={styles.headerLeft}>
                        <h1>Szczegóły</h1>
                        <StyledButton
                           type="button"
                           text="Przyjęcie na magazyn"
                           variant="filled-primary"
                           onClick={handleAdmissionPalletsClick}
                        />
                        <StyledButton
                           type="button"
                           text="Przerzuć do odbioru"
                           variant="outlined-primary"
                           onClick={handleTransferPalletsClick}
                        />
                     </div>
                     <ActionsDropdown large options={distributionCenterDetailsOptions!} />
                  </div>
                  {bannerData && (
                     <Banner
                        style={{ marginTop: 24 }}
                        fullWidth
                        variant={bannerData.variant}
                        children={bannerData.description}
                        withCloseIcon
                        onClose={() => setBannerData(null)}
                     />
                  )}
                  <SectionContainer
                     className={styles.distributionCenterContainer}
                     title="Dane Centum Dystrybucyjnego"
                  >
                     <StyledButton
                        variant="text"
                        type="button"
                        text="Edytuj dane"
                        className={styles.editBtn}
                        icon={<Pencil />}
                        onClick={() => setIsOpenedEditDistributionCenter(true)}
                     />
                     <DistributionCenterData
                        distributionCenter={distributionCenter}
                        commercialNetwork={commercialNetwork}
                     />
                  </SectionContainer>
                  <DistributionCenterPallets
                     distributionCenter={distributionCenter}
                     handleDataChange={handleDataChange}
                     onEditPallets={handleEditPallets}
                     shouldShowBanner={shouldShowInnerBanner}
                     setShowBanner={setShouldShowInnerBanner}
                  />
               </div>
            </>
         )}
      </>
   );
};

export default WithAuth(DistributionCenterDetails, 'ROLE_SYSTEM_ADMIN');
