import uniqueId from 'lodash/uniqueId';

import { DATE_FORMAT_WITH_DASHES } from 'constants/dateFormats';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { PALLETS_ITEM_PREFIX, SEND_PALLETS_ITEM_PREFIX } from 'constants/uniqueIdPrefixes';
import { PALLET_TYPES } from 'service/excel/common';
import { parseDate } from 'utils/functions';
import { isValidDateFromString } from 'utils/validation';

import { VGL_CLIENT_COMMERCIAL_NETWORK_DATA } from '../constants';
import { VGLClientSendPalletsItemData } from '../types';

export const vglClientSendPalletsItemToSendPalletsItemMapper = (
   vglClientSendPalletsItem: VGLClientSendPalletsItemData,
) => {
   const { commercialNetwork, commercialNetworkAddress } = VGL_CLIENT_COMMERCIAL_NETWORK_DATA[
      vglClientSendPalletsItem['do Centrum Dystr.']
   ] || { commercialNetwork: '', commercialNetworkAddress: '' };
   const palletsItems = vglClientSendPalletsItem.pallets.map(palletItem => {
      const palletType = PALLET_TYPES[palletItem['typ palety']] || '';
      return {
         uuid: uniqueId(PALLETS_ITEM_PREFIX),
         palletsAmount: palletItem['ilość'] ? palletItem['ilość'].toString() : '',
         palletType: {
            value: palletType,
            label: PALLET_TYPE_LABELS[palletType] || '',
         },
      };
   });
   return {
      uuid: uniqueId(SEND_PALLETS_ITEM_PREFIX),
      sendDate: isValidDateFromString(
         vglClientSendPalletsItem['Data ost.mod.'],
         DATE_FORMAT_WITH_DASHES,
      )
         ? parseDate(vglClientSendPalletsItem['Data ost.mod.'], DATE_FORMAT_WITH_DASHES)
         : null,
      externalCommercialNetworkNumber: vglClientSendPalletsItem['nr STO'] || '',
      commercialNetworkName: { label: commercialNetwork, value: commercialNetwork },
      commercialNetworkAddress: {
         label: commercialNetworkAddress,
         value: commercialNetworkAddress,
      },
      palletsItems: palletsItems,
   };
};
