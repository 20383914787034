/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { auth } from 'config/firebase';

import { currentEnvName } from '../../config/currentEnvironment';

const URLs: { [key: string]: string } = {
   dev: 'https://api.dev.ndhp.softwarepartner.pl/',
   uat: 'https://api.uat.ndhp.softwarepartner.pl/',
   prod: 'https://api.panel.ndhp.pl/',
};

class AxiosInstance {
   constructor() {
      this.axios = axios.create({ baseURL: URLs[currentEnvName] });
      this.axios.interceptors.request.use(async config => {
         const token = await auth.currentUser?.getIdToken();
         if (token) {
            config.headers = {
               ...config.headers,
               Authorization: `Bearer ${token}`,
            };
         }
         return config;
      });
   }

   private axios;

   public get = <T = any>(...params: [string, AxiosRequestConfig?]): Promise<AxiosResponse<T>> =>
      this.axios.get<T>(...params);

   public post = <T = any>(
      ...params: [string, any?, AxiosRequestConfig?]
   ): Promise<AxiosResponse<T>> => this.axios.post<T>(...params);

   public delete = <T = any>(...params: [string, AxiosRequestConfig?]): Promise<AxiosResponse<T>> =>
      this.axios.delete<T>(...params);

   public patch = <T = any>(
      ...params: [string, any?, AxiosRequestConfig?]
   ): Promise<AxiosResponse<T>> => this.axios.patch<T>(...params);

   public put = <T = any>(
      ...params: [string, any?, AxiosRequestConfig?]
   ): Promise<AxiosResponse<T>> => this.axios.put<T>(...params);
}

export default AxiosInstance;
