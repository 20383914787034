import { SupplierPlaceOrderData } from 'interfaces';

import { DEFAULT_PALLET_CUSTOM_PRICES } from './defaultPalletCustomPrices';

export const PLACE_ORDER_FORM_DEFAULT_VALUES: () => SupplierPlaceOrderData = () => ({
   client: null,
   address: null,
   palletDeliveryDate: null,
   pallets: [],
   storage: null,
   palletCustomPrices: DEFAULT_PALLET_CUSTOM_PRICES(),
   deliveryType: null,
});
