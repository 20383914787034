import classNames from 'classnames';

import { CheckGreenIcon } from 'assets';
import { PalletSpecification } from 'interfaces';
import { palletImages } from 'utils/palletImages';

import styles from './PalletTypeCard.module.css';

type PalletTypeCardProps = {
   palletType: PalletSpecification;
   onClick?: () => void;
   isSelected?: boolean;
   disabled?: boolean;
};

const PalletTypeCard = ({ palletType, onClick, isSelected, disabled }: PalletTypeCardProps) => {
   return (
      <div
         onClick={onClick}
         className={classNames(styles.palletContainer, {
            [styles.palletContainerActive]: isSelected,
            [styles.palletContainerDisabled]: disabled,
         })}
      >
         {isSelected && <CheckGreenIcon className={styles.checkIcon} />}
         <img src={palletImages[palletType.name]} />
         <h3>{palletType.label}</h3>
         <p>{palletType.dimensions}</p>
      </div>
   );
};

export default PalletTypeCard;
