import { useCallback, useMemo, useRef, useState } from 'react';

import { Plus, Search, Trash } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import {
   NoItemsNotificationWithLink,
   SectionContainer,
   StyledButton,
   StyledSelect,
   StyledTable,
} from 'components/shared';
import { AddOrEditDistributionCenterModal, AddStoragePointModal } from 'components/SupplierPanel';
import { COMMERCIAL_NETWORK_DEFAULT_ITEM } from 'constants/commercialNetworksFormDefaultValues';
import { commercialNetworksTableHeaders } from 'constants/tableHeaders';
import { useTableForm } from 'hooks/useTableForm';
import {
   CommercialNetwork,
   CommercialNetworkDetailsDTO,
   CommercialNetworkItem,
   DistributionCenterDTO,
   FormStepProps,
   Option,
} from 'interfaces';
import { Banner, MultiSelect, TableCell, TableRow } from 'storybook';
import { getAddressLabel, scrollToElement } from 'utils/functions';
import { commercialNetworksFormValidation } from 'utils/validation';

import FormStepsFooter from '../../FormStepsFooter/FormStepsFooter';

import styles from './CommercialNetworksForm.module.css';

interface CommercialNetworksFormProps extends FormStepProps<CommercialNetworkItem[]> {
   commercialNetworkNameOptions: Option[];
   commercialNetworkAddressOptions: {
      [key: string]: Option[];
   };
   onAddCommercialNetwork: (addedCommercialNetwork: CommercialNetworkDetailsDTO) => void;
   onAddDistributionCenter: (
      selectedCommercialNetworkId: string,
      addedDistributionCenter: DistributionCenterDTO,
   ) => void;
}

const CommercialNetworksForm = ({
   initialState,
   commercialNetworkNameOptions,
   commercialNetworkAddressOptions,
   onSubmit,
   setCurrentStep,
   onAddCommercialNetwork,
   onAddDistributionCenter,
}: CommercialNetworksFormProps) => {
   const {
      rows: commercialNetworkItems,
      errors: commercialNetworkItemsErrors,
      touched: commercialNetworkItemsTouched,
      commonErrorsOfTouchedInputs: commonErrors,
      addRow: handleAddCommercialNetworkItem,
      removeRow: handleRemoveCommercialNetworkItem,
      handleChangeValue: handleChangeCommercialNetworkItem,
      handleSubmitForm,
   } = useTableForm<CommercialNetworkItem>({
      initialState,
      onSubmit,
      validations: commercialNetworksFormValidation,
   });
   const [commercialNetworkToAddDistributionCenter, setCommercialNetworkToAddDistributionCenter] =
      useState<CommercialNetwork | null>(null);
   const [openedAddCommercialNetworkModal, setOpenedAddCommercialNetworkModal] = useState(false);
   const commercialNetworkNameSelectRef = useRef<{ closeDropdown: () => void }>();
   const commercialNetworkAddressMultiSelectRef = useRef<{ closeDropdown: () => void }>();
   const tableRef = useRef<null | HTMLDivElement>(null);

   const availableCommercialNetworkNameOptions = useMemo(() => {
      const seletectedCommercialNetworkNames = commercialNetworkItems.map(
         commercialNetworkItem => commercialNetworkItem.name.value,
      );
      return commercialNetworkNameOptions.filter(
         commercialNetworkNameOption =>
            !seletectedCommercialNetworkNames.includes(commercialNetworkNameOption.value),
      );
   }, [commercialNetworkItems, commercialNetworkNameOptions]);

   const handleChangeCommercialNetworkItemName = (value: Option, rowIndex: number) => {
      handleChangeCommercialNetworkItem(rowIndex, 'name', value);
      commercialNetworkItems[rowIndex].addresses.length &&
         handleChangeCommercialNetworkItem(rowIndex, 'addresses', []);
   };

   const transformSelectedAddressOptionsLabel = (
      values: Option[],
      selectedCommercialNetworkId: string,
   ) => {
      if (commercialNetworkAddressOptions[selectedCommercialNetworkId]) {
         return values.length ===
            commercialNetworkAddressOptions[selectedCommercialNetworkId].length
            ? 'Wybrano wszystkie adresy'
            : `Wybrano ${values.length} ${getAddressLabel(values.length)}`;
      } else {
         return '';
      }
   };

   const handleClickAddCommercialNetwork = () => {
      setOpenedAddCommercialNetworkModal(true);
      commercialNetworkNameSelectRef?.current?.closeDropdown();
   };

   const handleClickAddDistributionCenter = (id: string, name: string) => {
      setCommercialNetworkToAddDistributionCenter({
         id,
         name,
      });
      commercialNetworkAddressMultiSelectRef?.current?.closeDropdown();
   };

   const handleCloseAddDistributionCenterModal = useCallback(
      () => setCommercialNetworkToAddDistributionCenter(null),
      [],
   );

   const handleCloseAddStoragePointModal = useCallback(
      () => setOpenedAddCommercialNetworkModal(false),
      [],
   );

   const handleAddNextCommercialNetwork = () => {
      handleAddCommercialNetworkItem(COMMERCIAL_NETWORK_DEFAULT_ITEM());
      scrollToElement(tableRef);
   };

   return (
      <>
         <AddStoragePointModal
            opened={openedAddCommercialNetworkModal}
            addCommercialNetworkMode
            onClose={handleCloseAddStoragePointModal}
            onSuccess={onAddCommercialNetwork}
         />
         <AddOrEditDistributionCenterModal
            opened={!!commercialNetworkToAddDistributionCenter}
            commercialNetwork={commercialNetworkToAddDistributionCenter}
            onClose={handleCloseAddDistributionCenterModal}
            onSuccess={onAddDistributionCenter}
            mode="add"
         />
         <form onSubmit={handleSubmitForm} className={styles.formContainer}>
            <SectionContainer title="Konfiguracja Sieci Handlowych">
               {!!commercialNetworkItems.length && (
                  <>
                     <div className={styles.scrollableContainer}>
                        <StyledTable
                           tableRef={tableRef}
                           columnHeaders={commercialNetworksTableHeaders}
                        >
                           <>
                              {commercialNetworkItems.map((commercialNetworkItem, rowIndex) => (
                                 <TableRow key={commercialNetworkItem.uuid}>
                                    <TableCell className={styles.commercialNetworkNameCell}>
                                       <StyledSelect
                                          selectRef={commercialNetworkNameSelectRef}
                                          value={commercialNetworkItem.name}
                                          options={availableCommercialNetworkNameOptions}
                                          onChange={(value: Option) =>
                                             handleChangeCommercialNetworkItemName(value, rowIndex)
                                          }
                                          withFilter
                                          filterProps={{
                                             placeholder: 'Szukaj',
                                             icon: (
                                                <Search size={20} strokeWidth={2} color="#3E3E4B" />
                                             ),
                                          }}
                                          dropdownBottomSection={
                                             <StyledButton
                                                type="button"
                                                variant="filled-primary"
                                                text="Dodaj sieć"
                                                icon={<Plus size={20} strokeWidth={2} />}
                                                fullWidth
                                                onClick={handleClickAddCommercialNetwork}
                                             />
                                          }
                                          portalTarget={document.body}
                                          error={
                                             commercialNetworkItemsTouched[rowIndex]?.name &&
                                             !!commercialNetworkItemsErrors[rowIndex]?.name
                                          }
                                          classNames={{
                                             select: styles.commercialNetworkNameSelect,
                                             optionsContainer:
                                                styles.commercialNetworkNameOptionsContainer,
                                          }}
                                       />
                                    </TableCell>
                                    <TableCell className={styles.commercialNetworkAddressCell}>
                                       <MultiSelect
                                          ref={commercialNetworkAddressMultiSelectRef}
                                          values={commercialNetworkItem.addresses}
                                          options={
                                             commercialNetworkAddressOptions[
                                                commercialNetworkItem.name.value
                                             ]
                                          }
                                          onChange={(values: Option[]) =>
                                             handleChangeCommercialNetworkItem(
                                                rowIndex,
                                                'addresses',
                                                values,
                                             )
                                          }
                                          transformSelectedOptionsLabel={(options: Option[]) =>
                                             transformSelectedAddressOptionsLabel(
                                                options,
                                                commercialNetworkItem.name.value,
                                             )
                                          }
                                          disabled={!commercialNetworkItem.name.value}
                                          withFilter
                                          filterProps={{
                                             placeholder: 'Szukaj',
                                             icon: (
                                                <Search size={20} strokeWidth={2} color="#3E3E4B" />
                                             ),
                                          }}
                                          dropdownBottomSection={
                                             <StyledButton
                                                type="button"
                                                variant="filled-primary"
                                                text="Dodaj adres"
                                                icon={<Plus size={20} strokeWidth={2} />}
                                                fullWidth
                                                onClick={() =>
                                                   handleClickAddDistributionCenter(
                                                      commercialNetworkItem.name.value,
                                                      commercialNetworkItem.name.label,
                                                   )
                                                }
                                             />
                                          }
                                          portalTarget={document.body}
                                          error={
                                             commercialNetworkItemsTouched[rowIndex]?.addresses &&
                                             !!commercialNetworkItemsErrors[rowIndex]?.addresses
                                          }
                                          classNames={{
                                             container:
                                                styles.commercialNetworkAddressesMultiSelect,
                                             optionsContainer:
                                                styles.commercialNetworkAddressOptionsContainer,
                                          }}
                                       />
                                    </TableCell>
                                    <TableCell
                                       className={styles.removeCommercialNetworkItemBtnCell}
                                    >
                                       {!!rowIndex && (
                                          <StyledButton
                                             type="button"
                                             variant="text"
                                             onClick={() =>
                                                handleRemoveCommercialNetworkItem(rowIndex)
                                             }
                                             icon={<Trash />}
                                             className={styles.removeCommercialNetworkItemBtn}
                                          />
                                       )}
                                    </TableCell>
                                 </TableRow>
                              ))}
                           </>
                        </StyledTable>
                     </div>
                     <StyledButton
                        type="button"
                        variant="outlined-primary"
                        text="Dodaj kolejną Sieć Handlową"
                        onClick={handleAddNextCommercialNetwork}
                     />
                  </>
               )}
               {!commercialNetworkItems.length && (
                  <NoItemsNotificationWithLink
                     icon={<PalletsStackIcon />}
                     desctiption="Nie przypisano sieci handlowych."
                     linkText="Dodaj sieć handlową"
                     onClick={handleAddNextCommercialNetwork}
                  />
               )}
               {!!commonErrors.size && (
                  <div className={styles.bannersContainer}>
                     {Array.from(commonErrors).map(commonError => (
                        <Banner
                           key={commonError}
                           variant="error"
                           children={commonError}
                           fullWidth
                           className={styles.errorBanner}
                        />
                     ))}
                  </div>
               )}
            </SectionContainer>
            <FormStepsFooter
               backDisabled={false}
               disabledNextButton={!commercialNetworkItems.length}
               onBack={() => setCurrentStep((prevValue: number) => prevValue - 1)}
            />
         </form>
      </>
   );
};

export default CommercialNetworksForm;
