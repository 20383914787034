import { TRANSFER_ACTION_TYPE_LABELS_AND_ICONS } from 'constants/transferActionOverview/transferActionTypeLabelsAndIcons';
import { TransferActionType, TransferActionUserDTO } from 'interfaces';
import { getFormattedDateTimeFromString } from 'utils/functions';

import styles from './PalletsTransferHistoryActionHeader.module.css';

export type PalletsTransferHistoryActionHeaderProps = {
   type: TransferActionType;
   createdBy: TransferActionUserDTO;
   orderId?: string;
   createdDate: string;
};

const PalletsTransferHistoryActionHeader = ({
   type,
   createdBy,
   orderId,
   createdDate,
}: PalletsTransferHistoryActionHeaderProps) => {
   const { icon, label } = TRANSFER_ACTION_TYPE_LABELS_AND_ICONS[type];
   const formattedDateTime = getFormattedDateTimeFromString(createdDate);
   const orderIdInfo = orderId ? ` / ID: ${orderId}` : '';

   return (
      <div className={styles.mainContainer}>
         <div className={styles.leftPartContainer}>
            <div className={styles.actionTypeContainer}>
               {icon}
               <span>{label}</span>
            </div>
            <span className={styles.additionalInfoContainer}>{createdBy.name + orderIdInfo}</span>
         </div>
         <span className={styles.dateTimeContainer}>{formattedDateTime}</span>
      </div>
   );
};

export default PalletsTransferHistoryActionHeader;
