import { AddCommercialNetworkFormValues, AddStorageFormValues } from 'interfaces';

export const addCommercialNetworkFormDefaultValues: AddCommercialNetworkFormValues = {
   name: '',
   phoneNumber: '',
   contactEmail: '',
   street: '',
   zipCode: '',
   city: '',
};

export const addStorageFormDefaultValues: AddStorageFormValues = {
   street: '',
   zipCode: '',
   city: '',
};

export const addCommercialNetworkFormDefaultTouchedValues = {
   name: false,
   phoneNumber: false,
   contactEmail: false,
   street: false,
   zipCode: false,
   city: false,
};

export const addStorageFormDefaultTouchedValues = {
   street: false,
   zipCode: false,
   city: false,
};
