import React from 'react';

import classNames from 'classnames';

import styles from './Divider.module.css';

export interface DividerProps {
   variant: 'vertical' | 'horizontal';
   className?: string;
}

export const Divider = ({ variant, className }: DividerProps) => {
   return (
      <div
         className={classNames(styles.divider, className, {
            [styles.vertical]: variant === 'vertical',
            [styles.horizontal]: variant === 'horizontal',
         })}
      />
   );
};
