import { EMPTY_INPUT_ERROR } from 'constants/validationErrors';

import { isNonEmpty } from '.';

type Option = { value: string; label: string };

export const commercialNetworksFormValidation = {
   name: (name: Option) => !isNonEmpty(name.value) && EMPTY_INPUT_ERROR,
   addresses: (addresses: Option[]) => !addresses.length && EMPTY_INPUT_ERROR,
};
