import { Loader } from '@mantine/core';
import classNames from 'classnames';

import { getButtonLoaderColor } from 'utils/functions';

import styles from './UploadPDFButton.module.css';

type UploadPDFButtonProps = {
   id?: string;
   onUploadDocument: (document: FileList) => void;
   variant?: 'filled-primary' | 'outlined-primary';
   className?: string;
   isLoading?: boolean;
};

const UploadPDFButton = ({
   id,
   onUploadDocument,
   variant = 'filled-primary',
   className,
   isLoading,
}: UploadPDFButtonProps) => (
   <>
      <label htmlFor={id}>
         <div
            className={classNames(
               styles.btn,
               {
                  [styles.filledPrimary]: variant === 'filled-primary',
                  [styles.outlinedPrimary]: variant === 'outlined-primary',
                  [styles.loading]: isLoading,
               },
               className,
            )}
         >
            {isLoading && <Loader size="xs" color={getButtonLoaderColor(variant)} />}
            Dodaj dokumenty
         </div>
      </label>
      <input
         id={id}
         onChange={e => onUploadDocument(e.target.files!)}
         type="file"
         accept="application/pdf"
         className={styles.input}
         disabled={isLoading}
      />
   </>
);

export default UploadPDFButton;
