export const USER = '/api/v1/user';
export const CURRENT_USER_DETAILS = '/api/v1/user/me';
export const CREATE_CLIENT = '/api/v1/user/client';
export const USER_DETAILS = (id: string) => `/api/v1/user/${id}`;
export const USER_COMMENT = (id: string) => `/api/v1/user/${id}/comment`;
export const CHANGE_USER_BLOCKED_STATUS = (id: string) => `/api/v1/user/${id}/blocked-status`;
export const DEACTIVATE_USER = (id: string) => `/api/v1/user/${id}/deactivate`;

export const PALLET = '/api/v1/pallet';
export const PALLET_REQUIRED_NUMBER = '/api/v1/pallet/required-number';
export const USED_PALLET_TYPES = '/api/v1/pallet/used';
export const RECEIVED_LOST_DAMAGED_PALLETS = '/api/v1/pallet/received';
export const LOST_DAMAGED_PALLETS_REPORT_DETAILS = (id: string) => `/api/v1/pallet/${id}`;
export const LOST_DAMAGED_PALLETS_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/pallet/user/${clientId}`;
export const PALLETS_SHIPMENT_ADDRESSES_VERIFICATION =
   '/api/v1/pallet/shipment/address-verification';
export const PALLETS_SHIPMENT_ADDRESSES_VERIFICATION_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/pallet/user/${clientId}/shipment/address-verification`;

export const PALLETS_BALANCE_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/user/${clientId}/balance`;
export const RENTED_PALLETS_BALANCE_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/user/${clientId}/balance/rented`;
export const LOST_DAMAGED_PALLETS_BALANCE_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/user/${clientId}/balance/lost-or-damaged`;

export const CLIENT_PALLET_BALANCES = '/api/v1/user/balance/all';
export const RENTED_PALLETS_BALANCE = '/api/v1/user/balance/rented';
export const LOST_DAMAGED_PALLETS_BALANCE = '/api/v1/user/balance/lost-or-damaged';

export const ORDER = '/api/v1/order';
export const REMAINING_PALLETS = '/api/v1/order/remaining-pallets';
export const REPORT_PALLETS = '/api/v1/order/report-pallets';
export const PALLETS_SHIPMENT = '/api/v1/order/shipment-of-pallets';
export const ADMIN_ORDER = '/api/v1/order/admin';
export const ADMIN_ORDERS = '/api/v1/order/all';
export const ADMIN_UNREAD_ORDERS_COUNTS = '/api/v1/order/all/count';
export const ADMIN_ADDITIONAL_ORDERS = '/api/v1/order/admin/additional-orders';
export const ORDER_DETAILS = (id: string) => `/api/v1/order/${id}`;
export const REPORT_PALLETS_FROM_ORDER = (id: string) => `/api/v1/order/${id}/report-pallets`;
export const SUBMIT_ORDER_FOR_BILING = (id: string) => `/api/v1/order/${id}/submit-for-billing`;
export const SEND_PALLETS_FROM_ORDER = (id: string) => `/api/v1/order/${id}/shipment-of-pallets`;
export const EDIT_SEND_PALLETS_FROM_ORDER = (id: string) => `/api/v1/order/${id}/send-pallets`;
export const CHANGE_ORDER_STATUS = (id: string) => `/api/v1/order/${id}/status`;
export const DELETE_ORDER = (id: string) => `/api/v1/order/${id}`;
export const UPDATE_ORDER = (id: string) => `/api/v1/order/${id}`;
export const ADD_ADDITIONAL_CHARGES = (id: string) => `/api/v1/order/${id}/additional-charges`;
export const GET_ORDERS_DISTRIBUTION_CENTER = (distributionCenterId: string) =>
   `/api/v1/order/distribution-center/${distributionCenterId}`;
export const EDIT_PALLETS = (id: string) => `/api/v1/order/${id}/edit-pallets`;
export const MERGE_ADDITIONAL_ORDERS = (id: string) => `/api/v1/order/${id}/merge-additional-order`;
export const REPORT_PALLETS_BY_ADMIN = (clientId: string) =>
   `/api/v1/order/user/${clientId}/report-pallets`;
export const PALLETS_SHIPMENT_BY_ADMIN = (clientId: string) =>
   `/api/v1/order/user/${clientId}/shipment-of-pallets`;
export const REMAINING_PALLETS_BY_ADMIN = (clientId: string) =>
   `/api/v1/order/user/${clientId}/remaining-pallets`;
export const EDIT_INTERNAL_ORDER_ID = (orderId: string) =>
   `/api/v1/order/${orderId}/internal-order-id`;

export const DOCUMENT = '/api/v1/document';
export const CREATE_AND_SEND_INVOICE = '/api/v1/document/invoice';
export const DELETE_DOCUMENT = (id: string) => `/api/v1/document/${id}`;

export const COMMERCIAL_NETWORK = '/api/v1/commercial-network';
export const COMMERCIAL_NETWORK_DETAILS = (commercialNetworkId: string) =>
   `/api/v1/commercial-network/${commercialNetworkId}`;
export const ALL_COMMERCIAL_NETWORKS = '/api/v1/commercial-network/all';
export const DELETE_COMMERCIAL_NETWORK = (id: string) => `/api/v1/commercial-network/${id}`;
export const CAN_COMMERCIAL_NETWORK_BE_DELETED = (id: string) =>
   `/api/v1/commercial-network/${id}/scheduled-pallet-transfers`;

export const DISTRIBUTION_CENTER = (id: string) =>
   `/api/v1/commercial-network/${id}/distribution-center`;

export const DISTRIBUTION_CENTER_BALANCE = (
   commercialNetworkId: string,
   distributionCenterId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}/balance`;

export const DISTRIBUTION_CENTER_BY_ID = (
   commercialNetworkId: string,
   distributionCenterId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}`;

export const CAN_DISTRIBUTION_CENTER_BE_DELETED = (
   commercialNetworkId: string,
   distributionCenterId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}/balance/scheduled-pallet-transfers`;

export const EDIT_DISTRIBUTION_CENTER_BALANCE = (
   commercialNetworkId: string,
   distributionCenterId: string,
   balanceId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}/balance/${balanceId}`;

export const DISTRIBUTION_CENTER_TRANSFER_PALLETS = (
   commercialNetworkId: string,
   distributionCenterId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}/balance/transfer-for-collection`;

export const DISTRIBUTION_CENTER_ADMISSION_PALLETS = (
   commercialNetworkId: string,
   distributionCenterId: string,
) =>
   `/api/v1/commercial-network/${commercialNetworkId}/distribution-center/${distributionCenterId}/balance/accept-into-destination`;

export const STORAGE = '/api/v1/storage';
export const STORAGE_BALANCE = (id: string) => `/api/v1/storage/${id}/balance`;
export const CAN_STORAGE_BE_DELETED = (id: string) =>
   `/api/v1/storage/${id}/scheduled-pallet-transfers`;
export const DELETE_STORAGE = (id: string) => `/api/v1/storage/${id}`;

export const TRANSFER_PALLETS_INSIDE_STORAGE = '/api/v1/pallet/internal-transfer';

export const TRANSFER_PALETTES = '/api/v1/pallet/transfer';
export const CHANGE_REPORT_STATUS = (id: string) => `/api/v1/pallet/${id}/status`;

export const GUS = '/api/v1/user/gus';

export const PALLET_PICK_UP = '/api/v1/pallet-pick-up';

export const DISTRIBUTION_CENTER_USER_BALANCE = '/api/v1/distribution-center/balance';
export const DISTRIBUTION_CENTER_USER = '/api/v1/distribution-center';
export const GET_ORDERS_DISTRIBUTION_CENTER_USER = '/api/v1/order/distribution-center';
export const CLIENT_CONTACT_DETAILS_BY_CLIENT_ID = (clientId: string) =>
   `/api/v1/user/contact-details/${clientId}`;

export const GET_TRANSFER_HISTORY_OVERVIEW = '/api/v1/transfer-history';
export const GET_TRANSFER_ACTION_DETAILS_BY_ACTION_ID = (actionId: string) =>
   `/api/v1/transfer-history/${actionId}`;
export const GET_TRANSFER_HISTORY_OVERVIEW_IN_XLSX_FORMAT = '/api/v1/transfer-history/file';

export const GET_PALLET_PICK_UPS = '/api/v1/pallet-pick-up/all';
export const ACCEPT_PALLET_PICK_UP = (pickUpId: string) =>
   `/api/v1/pallet-pick-up/${pickUpId}/accept`;
export const REJECT_PALLET_PICK_UP = (pickUpId: string) =>
   `/api/v1/pallet-pick-up/${pickUpId}/reject`;
