import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@mantine/core';

import { ClientForm } from 'components/SupplierPanel';
import {
   clientDataFormInitialValues,
   palletTypesFormInitialValues,
} from 'constants/clientFormStepsInitialValues';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { SUPPLIER_CLIENTS } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import {
   ClientDataFormProps,
   CreateCommercialNetworkAddressesDTO,
   PalletTypesFormProps,
   RateConfigForm,
} from 'interfaces';
import { CommercialNetworkItem } from 'interfaces';
import { HTTPService, rateConfigFormToRateConfigDTOMapper } from 'service';
import { Banner } from 'storybook';
import { logNetworkError } from 'utils/logNetworkError';
import {
   parseClientUserDTOIntoClientDataFormData,
   parseClientUserDTOIntoRateConfigFormData,
   parsePalletsPricingPerAddressFormDataIntoClientDeliveryAddress,
} from 'utils/parsers';

import styles from './EditClientPage.module.css';

const EditClientPage = () => {
   const navigateTo = useNavigate();
   const [clientStepFormData, setClientStepFormData] = useState(clientDataFormInitialValues);
   const [palletTypesForm, setPalletTypesForm] = useState(palletTypesFormInitialValues);
   const [commercialNetworksForClient, setCommercialNetworksForClient] = useState<
      CommercialNetworkItem[]
   >([]);
   const [rateConfigStepFormData, setRateConfigStepFormData] = useState<RateConfigForm>();
   const [loading, setLoading] = useState(false);
   const [banner, setBanner] = useState('');
   const { id: clientId } = useParams();
   const fetchClientInformation = useCallback(() => {
      setLoading(true);
      clientId &&
         Promise.all([
            HTTPService.getUserDetails(clientId),
            HTTPService.getCommercialNetworks(
               { userId: clientId },
               { page: 0, size: PAGINATION_MAX_SIZE },
            ),
         ])
            .then(([getUserDetailsResponse, getCommercialNetworksResponse]) => {
               const { content: commercialNetworksContent } = getCommercialNetworksResponse.data;
               const clientDetails = getUserDetailsResponse.data;
               const { availablePalletTypes } = getUserDetailsResponse.data;
               const parsedClientDataFormData =
                  parseClientUserDTOIntoClientDataFormData(clientDetails);
               const parsedRateConfigFormData =
                  parseClientUserDTOIntoRateConfigFormData(clientDetails);
               setClientStepFormData(parsedClientDataFormData);
               setRateConfigStepFormData(parsedRateConfigFormData);
               setPalletTypesForm({ availablePalletTypes });
               const refactoredCommercialNetworks: CommercialNetworkItem[] =
                  commercialNetworksContent.map(element => {
                     const { addresses, id, name } = element;
                     const commercialNetworkItem = {
                        uuid: id,
                        addresses: addresses.map(item => {
                           const addressForNetwork = {
                              value: item.id,
                              label: item.address.street,
                           };
                           return addressForNetwork;
                        }),
                        name: {
                           value: id,
                           label: name,
                        },
                     };
                     return commercialNetworkItem;
                  });
               setCommercialNetworksForClient(refactoredCommercialNetworks);
            })
            .catch(err => {
               logNetworkError(err);
               setBanner('Podczas pobierania danych klienta wystąpił błąd');
            })
            .finally(() => setLoading(false));
   }, [clientId]);

   useEffect(() => {
      fetchClientInformation();
   }, [fetchClientInformation]);

   const handleEditClient = (
      clientData: ClientDataFormProps,
      palletTypes: PalletTypesFormProps,
      rateConfigData: RateConfigForm,
      commercialNetworksData: CommercialNetworkItem[],
      onFinish: () => void,
      onError: (error: unknown) => void,
   ) => {
      const commercialNetworks: CreateCommercialNetworkAddressesDTO[] = commercialNetworksData.map(
         commercialNetworksDataItem => ({
            commercialNetworkId: commercialNetworksDataItem.name.value,
            distributionCenterIds: commercialNetworksDataItem.addresses.map(
               address => address.value,
            ),
         }),
      );
      const clientDeliveryAddresses = rateConfigData.palletRates.perAddress.map(
         palletRatesPerAddress =>
            parsePalletsPricingPerAddressFormDataIntoClientDeliveryAddress(
               palletRatesPerAddress,
               clientData.deliveryAddresses,
            ),
      );
      const rateConfig = rateConfigFormToRateConfigDTOMapper(rateConfigData);
      clientId &&
         HTTPService.updateClient(
            {
               ...clientData,
               nip: clientData.nip.replaceAll('-', ''),
               deliveryAddresses: clientDeliveryAddresses,
               ...palletTypes,
               rateConfig,
               commercialNetworks,
            },
            clientId,
         )
            .then(() => navigateTo(SUPPLIER_CLIENTS, { state: { successEditClient: true } }))
            .catch(error => {
               onError(error);
               logNetworkError(error);
            })
            .finally(() => onFinish());
   };

   return (
      <>
         {loading || !rateConfigStepFormData ? (
            <div className={styles.loaderContainer}>
               <Loader color="var(--primary-green)" />
            </div>
         ) : (
            <>
               {banner && (
                  <Banner
                     variant="error"
                     children={banner}
                     fullWidth
                     className={styles.errorBanner}
                  />
               )}
               <ClientForm
                  commercialNetworksFormInitialValues={commercialNetworksForClient}
                  clientDataFormInitialValues={clientStepFormData}
                  palletTypesFormInitialValues={palletTypesForm}
                  ratesConfigurationFormInitialValues={rateConfigStepFormData}
                  onSubmit={handleEditClient}
               />
            </>
         )}
      </>
   );
};
export default WithAuth(EditClientPage, 'ROLE_SYSTEM_ADMIN');
