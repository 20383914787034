import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'constants/dateFormats';
import { parseNumber } from 'utils/functions';

export const isNonEmpty = (value: string | Date | null | undefined) => {
   if (typeof value === 'object') {
      return value !== null;
   } else {
      return value !== null && value !== undefined && value.trim().length > 0;
   }
};

export const isEmail = (email: string) => {
   return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
   );
};

export const isZipCode = (zipCode: string) => {
   return /^[0-9]{2}-[0-9]{3}$/.test(zipCode);
};

export const isInteger = (value: string) => {
   return /^[0-9]+$/.test(value);
};

export const isNumeric = (value: string) => {
   const convertedValue = parseNumber(value);
   return !isNaN(convertedValue);
};

export const isValidPassword = (value: string) => value.length >= 6;

export const isValidNIP = (nip: string) => {
   return /^[0-9]{10}$/.test(nip);
};

export const moreThanZero = (value: string) => {
   const convertedValue = parseNumber(value);
   return !!convertedValue && convertedValue > 0;
};

export const moreOrEqualZero = (value: string) => {
   const convertedValue = parseNumber(value);
   return convertedValue >= 0;
};

export const isValidDateFromString = (date: string, format?: string) => {
   try {
      return DateTime.fromFormat(date, format || DATE_FORMAT).isValid;
   } catch {
      return false;
   }
};
