import { useCallback, useEffect, useMemo, useState } from 'react';

import { DatePicker } from '@mantine/dates';
import { Calendar } from 'tabler-icons-react';

import { FiltersModal, FiltersModalProps, StyledAutocomplete } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { useForm } from 'hooks';
import {
   ClientUserDTO,
   CommercialNetworkDetailsDTO,
   Option,
   StorageDTO,
   TransferActionsOverviewFiltersForm,
} from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';
import { sortOptions } from 'utils/functions/sortOptions';
import {
   parseClientUserDTOIntoOption,
   parseCommercialNetworkDetailsDTOIntoOption,
   parseDistributionCenterDTOIntoOption,
   parseStorageDTOsIntoOptionGroups,
} from 'utils/parsers';
import { transferActionsOverviewFiltersFormValidation } from 'utils/validation';

import styles from './SupplierPalletsTransferHistoryFiltersModal.module.css';

type SupplierPalletsTransferHistoryFiltersModalProps = Pick<
   FiltersModalProps,
   'opened' | 'onClose'
> & {
   initialFormData: TransferActionsOverviewFiltersForm;
   onSubmit: (filters: TransferActionsOverviewFiltersForm) => void;
};

const SupplierPalletsTransferHistoryFiltersModal = ({
   opened,
   initialFormData,
   onSubmit,
   onClose,
}: SupplierPalletsTransferHistoryFiltersModalProps) => {
   const [clientsData, setClientsData] = useState<ClientUserDTO[]>([]);
   const [commercialNetworksData, setCommercialNetworksData] = useState<
      CommercialNetworkDetailsDTO[]
   >([]);
   const [storagesData, setStoragesData] = useState<StorageDTO[]>([]);
   const [isOptionsDataLoading, setIsOptionsDataLoading] = useState(false);
   const [isOptionsDataError, setIsOptionsDataError] = useState<boolean>(false);

   const {
      values: filterValues,
      errorsList: filterErrorsList,
      touched: filtersTouched,
      setValues: setFilterValues,
      selectChangeHandler: selectFilterChangeHandler,
      submitHandler: submitFiltersHandler,
   } = useForm({
      initialState: initialFormData,
      validations: transferActionsOverviewFiltersFormValidation,
      onSubmit: () => {
         onSubmit(filterValues as TransferActionsOverviewFiltersForm);
      },
   });

   useEffect(() => {
      setFilterValues(initialFormData);
   }, [initialFormData, setFilterValues]);

   const {
      client: selectedClient,
      commercialNetwork: selectedCommercialNetwork,
      distributionCenter: selectedDistributionCenter,
      storage: selectedStorage,
      dateFrom: selectedDateFrom,
      dateTo: selectedDateTo,
   } = filterValues as TransferActionsOverviewFiltersForm;

   const handleChangeClient = (newClient: Option) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         client: newClient,
         clientAddress: undefined,
      }));
   };

   const handleClearClient = () => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         client: undefined,
         clientAddress: undefined,
      }));
   };

   const handleChangeCommercialNetwork = (newCommercialNetwork: Option) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         commercialNetwork: newCommercialNetwork,
         distributionCenter: undefined,
      }));
   };

   const handleClearCommercialNetwork = () => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         commercialNetwork: undefined,
         distributionCenter: undefined,
      }));
   };

   const handleChangeDistributionCenter = (newDistributionCenter: Option) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         distributionCenter: newDistributionCenter,
      }));
   };

   const handleChangeStorage = (newStorage: Option) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         storage: newStorage,
      }));
   };

   const handleChangeDateFrom = (newDate: Date | null) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         dateFrom: newDate,
      }));
   };

   const handleChangeDateTo = (newDate: Date | null) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         dateTo: newDate,
      }));
   };

   const handleOptionsDataError = (error: unknown) =>
      errorHandler(error, () => setIsOptionsDataError(true));

   const handleFetchFiltersData = useCallback(() => {
      setIsOptionsDataLoading(true);
      Promise.all([
         HTTPService.getUsers({
            page: 0,
            size: PAGINATION_MAX_SIZE,
         }),
         HTTPService.getCommercialNetworks(undefined, { size: PAGINATION_MAX_SIZE }),
         HTTPService.getStorageList({ page: 0, size: PAGINATION_MAX_SIZE }),
      ])
         .then(([getUsersResponse, getNetworksResponse, getStoragesResponse]) => {
            setClientsData(getUsersResponse.data.content);
            setCommercialNetworksData(getNetworksResponse.data.content);
            setStoragesData(getStoragesResponse.data.content);
         })
         .catch(handleOptionsDataError)
         .finally(() => setIsOptionsDataLoading(false));
   }, []);

   useEffect(() => {
      handleFetchFiltersData();
   }, [handleFetchFiltersData]);

   const clientOptions = useMemo(
      () => clientsData.map(parseClientUserDTOIntoOption),
      [clientsData],
   );

   const commercialNetworkOptions = useMemo(() => {
      const parsedCommercialNetworkOptions = commercialNetworksData.map(
         parseCommercialNetworkDetailsDTOIntoOption,
      );
      return sortOptions(parsedCommercialNetworkOptions);
   }, [commercialNetworksData]);

   const distributionCenterOptions = useMemo(() => {
      const selectedCommercialNetworkAddresses =
         commercialNetworksData.find(
            commercialNetworkDataItem =>
               commercialNetworkDataItem.id === selectedCommercialNetwork?.value,
         )?.addresses || [];
      return selectedCommercialNetworkAddresses.map(parseDistributionCenterDTOIntoOption);
   }, [commercialNetworksData, selectedCommercialNetwork?.value]);

   const storageOptions = useMemo(
      () => parseStorageDTOsIntoOptionGroups(storagesData),
      [storagesData],
   );

   return (
      <FiltersModal
         opened={opened}
         isLoading={isOptionsDataLoading}
         error={isOptionsDataError ? DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA : undefined}
         onSubmit={submitFiltersHandler}
         onClose={onClose}
      >
         <FiltersModal.GroupContainer>
            <StyledAutocomplete
               value={selectedClient}
               label="Wybierz klienta"
               options={clientOptions}
               classNames={{
                  container: styles.autocompleteContainer,
               }}
               onSelect={handleChangeClient}
               onClear={handleClearClient}
            />
         </FiltersModal.GroupContainer>
         <FiltersModal.GroupContainer>
            <StyledAutocomplete
               label="Wybierz sieć handlową"
               value={selectedCommercialNetwork}
               options={commercialNetworkOptions}
               classNames={{
                  container: styles.autocompleteContainer,
               }}
               onSelect={handleChangeCommercialNetwork}
               onClear={handleClearCommercialNetwork}
            />
            <StyledAutocomplete
               label="Wybierz centrum dystrybucyjne"
               value={selectedDistributionCenter}
               disabled={!selectedCommercialNetwork}
               options={distributionCenterOptions}
               classNames={{
                  container: styles.autocompleteContainer,
               }}
               onSelect={handleChangeDistributionCenter}
               onClear={() => selectFilterChangeHandler(undefined, 'distributionCenter')}
            />
         </FiltersModal.GroupContainer>
         <FiltersModal.GroupContainer>
            <StyledAutocomplete
               label="Wybierz magazyn"
               value={selectedStorage}
               options={storageOptions}
               classNames={{
                  container: styles.autocompleteContainer,
               }}
               onSelect={handleChangeStorage}
               onClear={() => selectFilterChangeHandler(undefined, 'storage')}
            />
         </FiltersModal.GroupContainer>
         <FiltersModal.CombinedRow>
            <DatePicker
               label="Data akcji od"
               value={selectedDateFrom}
               inputFormat="DD/MM/YYYY"
               icon={<Calendar color="#3E3E4B" size="20" />}
               locale="pl"
               error={filtersTouched.dateFrom && filterErrorsList.dateFrom}
               onChange={handleChangeDateFrom}
            />
            <DatePicker
               label="Data akcji do"
               value={selectedDateTo}
               inputFormat="DD/MM/YYYY"
               icon={<Calendar color="#3E3E4B" size="20" />}
               locale="pl"
               error={filtersTouched.dateTo && filterErrorsList.dateTo}
               onChange={handleChangeDateTo}
            />
         </FiltersModal.CombinedRow>
      </FiltersModal>
   );
};

export default SupplierPalletsTransferHistoryFiltersModal;
