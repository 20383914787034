import { OrderDetailsPallet, SupplierOrderSummaryPallet } from 'interfaces';
import { Divider } from 'storybook';
import { countPallets } from 'utils/functions';
import { currencyFormatter } from 'utils/numberFormatter';

import styles from './PalletTotalPriceSection.module.css';

type PalletTotalPriceSectionProps = {
   pallet: SupplierOrderSummaryPallet | OrderDetailsPallet;
   isOrderFree?: boolean;
};

const PalletTotalPriceSection = ({ pallet, isOrderFree }: PalletTotalPriceSectionProps) => (
   <>
      <Divider variant="horizontal" className={styles.palletTotalPriceDivider} />
      <div className={styles.palletTotalPriceContainer}>
         <span>Kwota:</span>
         <span>
            <span className={styles.totalPrice}>
               {pallet.pricePerUnit && !isOrderFree
                  ? currencyFormatter.format(countPallets(pallet) * pallet.pricePerUnit)
                  : '-'}
            </span>{' '}
            PLN
         </span>
      </div>
   </>
);

export default PalletTotalPriceSection;
