import uniqueId from 'lodash/uniqueId';

import { DATE_FORMAT } from 'constants/dateFormats';
import { SEND_PALLETS_ITEM_PREFIX } from 'constants/uniqueIdPrefixes';
import { parseDate } from 'utils/functions';

import { excelPalletsItemToPalletsItemMapper } from '../mappers';
import { ExcelSendPalletsItem } from '../types';

export const excelSendPalletsItemToSendPalletsItemMapper = (
   excelSendPalletsItem: ExcelSendPalletsItem,
) => {
   const palletsItems = excelSendPalletsItem.pallets.map(excelPalletsItemToPalletsItemMapper);

   return {
      uuid: uniqueId(SEND_PALLETS_ITEM_PREFIX),
      sendDate: parseDate(excelSendPalletsItem.sendDate, DATE_FORMAT),
      externalCommercialNetworkNumber: excelSendPalletsItem.externalCommercialNetworkNumber,
      commercialNetworkName: {
         label: excelSendPalletsItem.commercialNetworkName,
         value: excelSendPalletsItem.commercialNetworkName,
      },
      commercialNetworkAddress: {
         label: excelSendPalletsItem.commercialNetworkAddress,
         value: excelSendPalletsItem.commercialNetworkAddress,
      },
      palletsItems: palletsItems,
   };
};
