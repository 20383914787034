import { ReactNode, RefObject } from 'react';
import InfiniteScroll, { Props as InfiniteScrollProps } from 'react-infinite-scroll-component';

import { Loader } from '@mantine/core';
import classNames from 'classnames';

import styles from './CustomInfiniteScroll.module.css';

type CustomInfiniteScrollProps = {
   containerId: string;
   containerRef?: RefObject<HTMLDivElement>;
   noItemsNotificationElement?: ReactNode;
   isInitialLoading?: boolean;
   infiniteScrollClassNames?: {
      outerContainer?: string;
      innerContainer?: string;
      outerLoader?: string;
   };
} & Pick<InfiniteScrollProps, 'children' | 'dataLength' | 'next' | 'hasMore'>;

const CustomInfiniteScroll = ({
   containerId,
   children,
   noItemsNotificationElement,
   containerRef,
   dataLength,
   next,
   hasMore,
   isInitialLoading,
   infiniteScrollClassNames,
}: CustomInfiniteScrollProps) => (
   <div className={classNames(styles.outerContainer, infiniteScrollClassNames?.outerContainer)}>
      <div
         id={containerId}
         ref={containerRef}
         className={classNames(styles.innerContainer, infiniteScrollClassNames?.innerContainer)}
      >
         <InfiniteScroll
            dataLength={dataLength}
            next={next}
            hasMore={hasMore}
            loader={
               <div className={styles.innerLoader}>
                  <Loader color={'var(--primary-green)'} />
               </div>
            }
            scrollableTarget={containerId}
         >
            {children}
         </InfiniteScroll>
         {dataLength === 0 && !isInitialLoading && noItemsNotificationElement}
      </div>
      {isInitialLoading && (
         <div className={classNames(styles.outerLoader, infiniteScrollClassNames?.outerLoader)}>
            <Loader color={'var(--primary-green)'} />
         </div>
      )}
   </div>
);

export default CustomInfiniteScroll;
