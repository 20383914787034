import React, { FocusEvent } from 'react';

import classNames from 'classnames';
import { ArrowDown } from 'tabler-icons-react';

import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { PalletType } from 'interfaces/commonInterfacesAndTypes';
import { Card, CardProps } from 'storybook';
import { currencyFormatter } from 'utils/numberFormatter';
import { palletImages } from 'utils/palletImages';

import styles from './ChangePalletPriceCard.module.css';

interface ChangePalletPriceCardProps extends CardProps {
   name: PalletType;
   dimensions: string;
   contractPrice?: number;
   value?: number;
   onPriceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
   onPriceBlur: (event: FocusEvent<HTMLInputElement>, fieldName: string) => void;
   inputName: string;
   errors: { [key: string]: string };
}

const ChangePalletPriceCard = ({
   name,
   dimensions,
   contractPrice,
   value,
   onPriceChange,
   onPriceBlur,
   inputName,
   errors,
   ...props
}: ChangePalletPriceCardProps) => (
   <Card
      title={props.title}
      icon={<img className={styles.cardPalletImage} src={palletImages[name]} />}
      size="sm"
      bottomContent={
         <div className={styles.cardBottomContent}>
            <p className={styles.dimensions}>{dimensions}</p>
            <p className={styles.contractPriceDescription}>
               Cena umowna (przy spełnionym minimum logistycznym) (PLN)
            </p>
            <p className={styles.contractPrice}>
               {contractPrice ? currencyFormatter.format(contractPrice) : ''}
            </p>
            <ArrowDown className={styles.arrowIcon} />
            <p className={styles.customPriceDescription}>
               Cena przy <span>nie spełnionym</span> minimum logistycznym (PLN)
            </p>
            <StyledTextInput
               name={inputName}
               type="text"
               value={value}
               onChange={onPriceChange}
               onBlur={event => onPriceBlur(event, inputName)}
               error={!!errors[inputName]}
               helperText={errors[inputName] ? errors[inputName] : undefined}
               className={classNames({
                  [styles.error]: errors[inputName],
               })}
            />
         </div>
      }
      className={classNames(styles.card, props.className)}
   />
);

export default ChangePalletPriceCard;
