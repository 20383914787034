import { CommonRatePerPalletConfig, CommonRatePerPalletConfigFormData } from 'interfaces';
import { formatNumberToPrice } from 'utils/functions';

export const parseCommonRatePerPalletConfigIntoCommonRatePerPalletConfigFormData: (
   commonRatePerPalletConfig: CommonRatePerPalletConfig,
) => CommonRatePerPalletConfigFormData = commonRatePerPalletConfig => {
   const { palletType, personalPickUpPrice, damagePrice, lostPrice, wrongReceiver } =
      commonRatePerPalletConfig;

   return {
      palletType,
      personalPickUpPrice: formatNumberToPrice(personalPickUpPrice),
      damagePrice: formatNumberToPrice(damagePrice),
      lostPrice: formatNumberToPrice(lostPrice),
      wrongReceiver: formatNumberToPrice(wrongReceiver),
   };
};
