import { SectionContainer } from 'components/shared';
import { PalletBalance } from 'interfaces';
import { Divider } from 'storybook';

import { DamageLossPalletsSummary } from '..';

import styles from './DamagedLostByClientPalletsSummarySection.module.css';

type DamagedLostByClientPalletsSummarySectionProps = {
   damagedByClientPallets: PalletBalance;
   lostOrDestroyedByClientPallets: PalletBalance;
   isSupplierView?: boolean;
   onClickDamagedByClientPalletsDetails: () => void;
   onClickLostOrDestroyedByClientPalletsDetails: () => void;
};

const LOST_DAMAGED_BY_SUPPLIER_VIEW_LABEL = 'z winy klienta';
const LOST_DAMAGED_BY_CLIENT__VIEW_LABEL = 'z mojej winy';

const DamagedLostByClientPalletsSummarySection = ({
   damagedByClientPallets,
   lostOrDestroyedByClientPallets,
   isSupplierView,
   onClickDamagedByClientPalletsDetails,
   onClickLostOrDestroyedByClientPalletsDetails,
}: DamagedLostByClientPalletsSummarySectionProps) => (
   <SectionContainer
      title={`Zgubione, zniszczone lub uszkodzone palety ${
         isSupplierView ? LOST_DAMAGED_BY_SUPPLIER_VIEW_LABEL : LOST_DAMAGED_BY_CLIENT__VIEW_LABEL
      }`}
      subtitle="Palety z dostarczonych zamówień"
   >
      <div className={styles.innerContainer}>
         <DamageLossPalletsSummary
            title={`Zgubione lub zniszczone ${
               isSupplierView
                  ? LOST_DAMAGED_BY_SUPPLIER_VIEW_LABEL
                  : LOST_DAMAGED_BY_CLIENT__VIEW_LABEL
            }`}
            damageLossPalletsSummaryData={lostOrDestroyedByClientPallets}
            onClickDetails={onClickLostOrDestroyedByClientPalletsDetails}
            className={styles.damagedLostPalletsByClientSummary}
         />
         <Divider variant="vertical" className={styles.divider} />
         <DamageLossPalletsSummary
            title={`Uszkodzone ${
               isSupplierView
                  ? LOST_DAMAGED_BY_SUPPLIER_VIEW_LABEL
                  : LOST_DAMAGED_BY_CLIENT__VIEW_LABEL
            }`}
            damageLossPalletsSummaryData={damagedByClientPallets}
            onClickDetails={onClickDamagedByClientPalletsDetails}
            className={styles.damagedLostPalletsByClientSummary}
         />
      </div>
   </SectionContainer>
);

export default DamagedLostByClientPalletsSummarySection;
