import classNames from 'classnames';

import { TextInput, TextInputProps } from 'storybook';

import styles from './StyledTextInput.module.css';

const StyledTextInput = ({ ...props }: TextInputProps) => (
   <TextInput
      {...props}
      className={classNames(styles.textInput, { [styles.error]: props.error }, props.className)}
   />
);

export default StyledTextInput;
