import { AvailablePalletStatusDTO, ReturnPalletDTO } from 'interfaces/dto/availablePalletDTO';

export const getTransferReturnPalletsSumValue = (
   transferPalletsValues: Partial<ReturnPalletDTO>,
) => {
   if (transferPalletsValues.invalidReturnPallets && transferPalletsValues.validReturnPallets) {
      return (
         Number(transferPalletsValues.invalidReturnPallets) +
         Number(transferPalletsValues.validReturnPallets)
      );
   } else if (
      !transferPalletsValues.invalidReturnPallets &&
      !transferPalletsValues.validReturnPallets
   ) {
      return 0;
   } else if (!transferPalletsValues.invalidReturnPallets) {
      return +transferPalletsValues.validReturnPallets!;
   }
   return +transferPalletsValues.invalidReturnPallets!;
};

export const getTransferAvailablePalletsSumValue = (
   transferPalletsValues: Partial<AvailablePalletStatusDTO>,
) => {
   if (
      transferPalletsValues.invalidAvailablePallets &&
      transferPalletsValues.validAvailablePallets
   ) {
      return (
         Number(transferPalletsValues.invalidAvailablePallets) +
         Number(transferPalletsValues.validAvailablePallets)
      );
   } else if (
      !transferPalletsValues.invalidAvailablePallets &&
      !transferPalletsValues.validAvailablePallets
   ) {
      return 0;
   } else if (!transferPalletsValues.invalidAvailablePallets) {
      return +transferPalletsValues.validAvailablePallets!;
   }
   return +transferPalletsValues.invalidAvailablePallets!;
};
