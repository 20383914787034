import {
   Dispatch,
   forwardRef,
   SetStateAction,
   useCallback,
   useEffect,
   useImperativeHandle,
   useState,
} from 'react';

import { EditInternalOrderIdModal } from 'components/ClientPanel';
import {
   AdditionalChargesSection,
   OverviewDeliverySection,
   OverviewPalletsConfigurationSection,
   OverviewRentalPeriodSection,
} from 'components/Order';
import { SendPalletsSection, SendToSettlementModal } from 'components/OrderDetails';
import { ReportLostDamagedPalletsModal } from 'components/ReportLostDamagedPalletsModal';
import { SendPalletsModal } from 'components/SendPalletsModal';
import { StyledButton } from 'components/shared';
import SuccessReportLostDamagedPalletsBySupplierModal from 'components/SuccessReportLostDamagedPalletsBySupplierModal/SuccessReportLostDamagedPalletsBySupplierModal';
import { CLIENT_ORDER_DETAILS_BANNERS } from 'constants/banners';
import { orderStatusesWithVisibleAdditionalSections } from 'constants/orderStatusesWithVisibleAdditionalSections';
import { useRegularOrderDetails } from 'hooks/useRegularOrderDetails';
import { useSendPalletsForm } from 'hooks/useSendPalletsForm';
import {
   BannerData,
   ClientOrderDetailsPageModalsOrDialogs,
   ClientUserDTO,
   CommercialNetworkDetailsDTO,
   GroupedLostDamagedPalletItems,
   OrderDetails,
   OrderDetailsDTO,
   OrderDetailsPallet,
   RegularOrderDetails,
} from 'interfaces';
import {
   getCommercialNetworkOptions,
   getOrderDetailsData,
   getStorageAddressLabel,
} from 'utils/functions';

import styles from './RegularOrderDetailsPage.module.css';

type RegularOrderDetailsPageProps = {
   order: RegularOrderDetails;
   client: ClientUserDTO;
   commercialNetworks: CommercialNetworkDetailsDTO[];
   pallets: OrderDetailsPallet[];
   palletsSum: number;
   fetchOrder: (orderId: string) => void;
   setOrder: Dispatch<SetStateAction<OrderDetails | undefined>>;
   scrollToTop: () => void;
   showBanner: (bannerData: BannerData | null) => void;
};

const RegularOrderDetailsPage = forwardRef(
   (
      {
         order,
         client,
         commercialNetworks,
         pallets,
         palletsSum,
         fetchOrder,
         setOrder,
         scrollToTop,
         showBanner,
      }: RegularOrderDetailsPageProps,
      ref,
   ) => {
      const {
         groupedLostDamagedPalletItems,
         sendingAddressOptions,
         setCommercialNetworkAddressOptions,
         setGroupedLostDamagedPalletItems,
         handleAddCommercialNetworkOption,
         handleAddCommercialNetworkAddressOption,
      } = useSendPalletsForm();
      const { availablePalletTypesToReport, availablePalletTypesToSend } = useRegularOrderDetails({
         order,
         groupedLostDamagedPalletItems,
      });
      const [selectedModal, setSelectedModal] =
         useState<ClientOrderDetailsPageModalsOrDialogs>(null);

      useImperativeHandle(ref, () => ({
         openModal: setSelectedModal,
      }));

      const prepareAddressOptionsData = useCallback(() => {
         const commercialNetworkDeliveryAddressOptions =
            getCommercialNetworkOptions(commercialNetworks);
         setCommercialNetworkAddressOptions({
            nameOptions: commercialNetworkDeliveryAddressOptions.nameOptions,
            addressOptions: commercialNetworkDeliveryAddressOptions.addressOptions,
         });
      }, [commercialNetworks, setCommercialNetworkAddressOptions]);

      useEffect(() => {
         prepareAddressOptionsData();
      }, [prepareAddressOptionsData]);

      const handleReportDamagedPallets = (
         groupedLostDamagedPalletItemsData: GroupedLostDamagedPalletItems,
      ) => {
         setGroupedLostDamagedPalletItems(groupedLostDamagedPalletItemsData);
         setSelectedModal('SEND_PALLETS_MODAL');
      };

      const handleEditInternalOrderIdSuccess = (updatedOrderDetailsDTO: OrderDetailsDTO) => {
         showBanner(CLIENT_ORDER_DETAILS_BANNERS.EDIT_INTERNAL_ORDER_ID_SUCCESS);
         setOrder(getOrderDetailsData(updatedOrderDetailsDTO));
      };

      const handleSuccessSendForSettlement = useCallback(
         (updatedOrder: OrderDetailsDTO) => {
            showBanner(CLIENT_ORDER_DETAILS_BANNERS.SEND_FOR_SETTLEMENT);
            setOrder(getOrderDetailsData(updatedOrder));
            scrollToTop();
         },
         [scrollToTop, setOrder, showBanner],
      );

      const handleSuccessReportLostDestroyedPalletsByClient = useCallback(() => {
         showBanner(CLIENT_ORDER_DETAILS_BANNERS.REPORT_LOST_DESTROYED_PALLETS_BY_CLIENT);
         fetchOrder(order.id);
      }, [fetchOrder, order.id, showBanner]);

      const handleSuccessReportLostDestroyedPalletsBySupplier = useCallback(() => {
         showBanner(CLIENT_ORDER_DETAILS_BANNERS.REPORT_LOST_DESTROYED_PALLETS_BY_SUPPLIER);
         setSelectedModal('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL');
         setOrder(prevOrder =>
            prevOrder ? { ...prevOrder, includesReceivedDamagedOrLostPallets: true } : undefined,
         );
      }, [setOrder, showBanner]);

      const handleSuccessSendOrEditPallets = useCallback(
         (orderDetails: OrderDetailsDTO) => {
            showBanner(CLIENT_ORDER_DETAILS_BANNERS.SEND_PALLETS);
            setOrder(getOrderDetailsData(orderDetails));
            scrollToTop();
         },
         [scrollToTop, setOrder, showBanner],
      );

      const handleSuccessReportSendDamagedPallets = useCallback(
         (damagedBySupplier: boolean) => {
            fetchOrder(order.id);
            setGroupedLostDamagedPalletItems(null);
            damagedBySupplier
               ? setSelectedModal('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL')
               : showBanner(CLIENT_ORDER_DETAILS_BANNERS.SEND_DAMAGED_PALLETS);
         },
         [fetchOrder, order.id, setGroupedLostDamagedPalletItems, showBanner],
      );

      const handleCloseModal = useCallback(() => setSelectedModal(null), []);

      const isVisibleAdditionalSections =
         orderStatusesWithVisibleAdditionalSections.includes(order.status) ||
         (order.previousStatus &&
            orderStatusesWithVisibleAdditionalSections.includes(order.previousStatus));
      const isOrderDeliveredStatus = order.status === 'DELIVERED';

      return (
         <>
            {selectedModal === 'EDIT_INTERNAL_ORDER_ID_MODAL' && (
               <EditInternalOrderIdModal
                  orderId={order.id}
                  internalOrderId={order.internalOrderId}
                  onEditInternalOrderIdSuccess={handleEditInternalOrderIdSuccess}
                  onClose={handleCloseModal}
               />
            )}
            <SendToSettlementModal
               onSendForSettlementSuccess={handleSuccessSendForSettlement}
               orderId={order.id}
               additionalCharges={order.additionalCharges}
               remainingPalletsToBeSend={order.remainingPalletsToBeSend}
               opened={selectedModal === 'SETTLEMENT_MODAL'}
               onClose={handleCloseModal}
            />
            <ReportLostDamagedPalletsModal
               orderId={order.id}
               availablePalletTypes={availablePalletTypesToReport}
               remainingPalletsToBeSend={order.remainingPalletsToBeSend}
               onSuccessReportLostDestroyedPalletsByClient={
                  handleSuccessReportLostDestroyedPalletsByClient
               }
               onSuccessReportLostDestroyedPalletsBySupplier={
                  handleSuccessReportLostDestroyedPalletsBySupplier
               }
               onReportDamagedPallets={handleReportDamagedPallets}
               opened={selectedModal === 'REPORT_DAMAGE_LOSS_PALLETS_MODAL'}
               onClose={handleCloseModal}
            />
            <SendPalletsModal
               orderId={order.id}
               availablePalletTypes={availablePalletTypesToSend}
               groupedLostDamagedPalletItems={groupedLostDamagedPalletItems}
               sendingAddressOptions={sendingAddressOptions}
               onAddCommercialNetwork={handleAddCommercialNetworkOption}
               onAddCommercialNetworkAddress={handleAddCommercialNetworkAddressOption}
               onSuccessSendOrEditPalletsFromOrder={handleSuccessSendOrEditPallets}
               onSuccessReportSendDamagedPalletsFromOrder={handleSuccessReportSendDamagedPallets}
               opened={selectedModal === 'SEND_PALLETS_MODAL'}
               clientCompanyName={client.companyName}
               onClose={() => {
                  handleCloseModal();
                  setGroupedLostDamagedPalletItems(null);
               }}
            />
            <SuccessReportLostDamagedPalletsBySupplierModal
               opened={selectedModal === 'SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL'}
               onClose={handleCloseModal}
            />
            <OverviewDeliverySection
               viewMode="CLIENT"
               address={order.personalPickUp ? 'SELF_PICKUP' : order.address}
               storageAddress={
                  order.storage && order.status !== 'WAITING'
                     ? getStorageAddressLabel(order.storage)
                     : undefined
               }
            />
            <OverviewRentalPeriodSection
               palletDeliveryDate={order.palletDeliveryDate}
               holdingPeriod={order.retentionDate}
               retentionPeriodDays={String(client.rateConfig?.retentionConfig.retentionPeriodDays)}
            />
            <OverviewPalletsConfigurationSection
               pallets={pallets}
               palletsSum={palletsSum}
               isClientView
            />
            {isVisibleAdditionalSections && (
               <>
                  <AdditionalChargesSection additionalCharges={order.additionalCharges} />
                  <SendPalletsSection
                     sendPallets={order.sendPallets}
                     remainingPalletsToBeSend={order.remainingPalletsToBeSend}
                     onClickSendPallets={
                        isOrderDeliveredStatus
                           ? () => setSelectedModal('SEND_PALLETS_MODAL')
                           : undefined
                     }
                  />
               </>
            )}
            {isOrderDeliveredStatus && (
               <StyledButton
                  className={styles.submitBtn}
                  onClick={() => setSelectedModal('SETTLEMENT_MODAL')}
                  text="Prześlij do rozliczenia"
                  variant="filled-primary"
               />
            )}
         </>
      );
   },
);

export default RegularOrderDetailsPage;
