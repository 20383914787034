export const VGL_CLIENT_COMMERCIAL_NETWORK_DATA: Record<
   string,
   { commercialNetwork: string; commercialNetworkAddress: string }
> = {
   DC03: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Biedronkowa 1, 49-318 Skarbimierz',
   },
   DC05: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Biedronkowa 1, 59-300 Lubin',
   },
   DC06: { commercialNetwork: 'Biedronka', commercialNetworkAddress: 'Leśna 33, 07-200 Wyszków' },
   DC07: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Biały Bór 4, 86-300 Grudziądz',
   },
   DC08: { commercialNetwork: 'Biedronka', commercialNetworkAddress: 'Żniwna 3, 62-025 Kostrzyn' },
   DC09: { commercialNetwork: 'Biedronka', commercialNetworkAddress: 'Wolicka 12, 32-830 Wojnicz' },
   DC10: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Tarczyńska 121, 96-320 Mszczonów',
   },
   DC13: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Strefowa 9, 75-202 Koszalin',
   },
   DC14: { commercialNetwork: 'Biedronka', commercialNetworkAddress: 'Sosnowa 14, 98-200 Sieradz' },
   DC15: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Kartuska 491, 80-298 Gdańsk',
   },
   DC16: { commercialNetwork: 'Biedronka', commercialNetworkAddress: 'Gazowa 3, 21-100 Lubartów' },
   DC17: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Podchruście 9, 32-085 Modlnica',
   },
   DC18: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Św. Jerzego 3, 05-808 Parzniew',
   },
   DC19: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Szyb Walenty 10, 41-700 Ruda Śląska',
   },
   DC20: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Innowacyjna 6, 41-218 Sosnowiec',
   },
   DC22: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Biedronkowa 5, 11-034 Stawiguda',
   },
   DC83: {
      commercialNetwork: 'Biedronka',
      commercialNetworkAddress: 'Metalowców 6, 66-400 Gorzów Wlkp',
   },
};
