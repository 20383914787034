import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { REPORT_TYPE_LABELS } from 'constants/reportTypes';
import { PalletReportDTO } from 'interfaces';

import styles from './DamagedLostByClientPalletsCard.module.css';

type DamagedLostByClientPalletsCardProps = {
   damageLossPalletsData: PalletReportDTO;
};

const DamagedLostByClientPalletsCard = ({
   damageLossPalletsData,
}: DamagedLostByClientPalletsCardProps) => (
   <div className={styles.container}>
      <div className={styles.dataItem}>
         <span className={styles.label}>Numer zamówienia</span>
         <span className={styles.value}>#{damageLossPalletsData.orderId}</span>
      </div>
      <div className={styles.dataItem}>
         <span className={styles.label}>Rodzaj uszkodzenia</span>
         <span className={styles.value}>
            {REPORT_TYPE_LABELS[damageLossPalletsData.damagedPallets[0].reportType]}
         </span>
      </div>
      {damageLossPalletsData.damagedPallets.map((damageLossPalletsItem, index) => (
         <div key={index} className={styles.dataItemsGroup}>
            <div>
               <span className={styles.label}>Typ palety</span>
               <span className={styles.value}>
                  {PALLET_TYPE_LABELS[damageLossPalletsItem.type]}
               </span>
            </div>
            <div>
               <span className={styles.label}>Ilość palet</span>
               <span className={styles.value}>{damageLossPalletsItem.amount}</span>
            </div>
         </div>
      ))}
   </div>
);

export default DamagedLostByClientPalletsCard;
