import { ChangeEvent } from 'react';

import { Search } from 'tabler-icons-react';

import { PageHeader } from 'components/layout';
import { StatusLegend, StyledTextInput } from 'components/shared';
import { pickUpStatusesLegend } from 'constants/pickUpStatuses';

import styles from './SupplierPalletsPickUpsHeader.module.css';

type SupplierPalletsPickUpsHeaderProps = {
   clientNameFilter: string;
   disabled: boolean;
   onClientNameFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SupplierPalletsPickUpsHeader = ({
   clientNameFilter,
   disabled,
   onClientNameFilterChange,
}: SupplierPalletsPickUpsHeaderProps) => {
   const leftPart = (
      <div className={styles.leftPartHeader}>
         <StyledTextInput
            type="text"
            value={clientNameFilter}
            placeholder="Nazwa firmy"
            disabled={disabled}
            className={styles.searchInput}
            leftIcon={<Search size={20} color="var(--primary-text)" />}
            onChange={onClientNameFilterChange}
         />
      </div>
   );

   const rightPart = (
      <StatusLegend statusDataItems={pickUpStatusesLegend} className={styles.statusLegend} />
   );

   return (
      <PageHeader
         headerLeftElement={leftPart}
         headerRightElement={rightPart}
         className={styles.header}
      />
   );
};

export default SupplierPalletsPickUpsHeader;
