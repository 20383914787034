import { SectionContainer } from 'components/shared';
import { EMPTY_VALUE_LABEL } from 'constants/commonLabels';

import styles from './OverviewInternalOrderIdSection.module.css';

type OverviewInternalOrderIdSectionProps = {
   internalOrderId: string;
};

const OverviewInternalOrderIdSection = ({
   internalOrderId,
}: OverviewInternalOrderIdSectionProps) => (
   <SectionContainer title="Wewnętrzny numer zamówienia">
      <span className={styles.labelText}>Wewnętrzny numer zamówienia</span>
      <span className={styles.valueText}>{internalOrderId || EMPTY_VALUE_LABEL}</span>
   </SectionContainer>
);

export default OverviewInternalOrderIdSection;
