import { useCallback, useMemo, useRef, useState } from 'react';

import { useDebouncedValue } from '@mantine/hooks';

import { PALLETS_TRANSFER_HISTORY_PAGE_BANNERS } from 'constants/banners';
import { DATE_FORMAT_WITH_DASHES_REVERSED } from 'constants/dateFormats';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { PAGINATION_SIZE } from 'constants/pagination';
import { usePagination } from 'hooks/usePagination';
import {
   BannerData,
   TransferActionOverviewDTO,
   TransferActionsOverviewFilters,
   TransferActionsOverviewFiltersForm,
} from 'interfaces';
import { HTTPService } from 'service';
import { initializeDefaultTransferActionsOverviewFiltersForm } from 'utils/dataInitializers';
import { errorHandler } from 'utils/errorHandler';
import { fileDownload } from 'utils/fileDownload';
import { getFormattedDate } from 'utils/functions';
import { parseTransferActionsOverviewFiltersFormIntoTransferActionsOverviewAdditionalFilters } from 'utils/parsers';

export const usePalletsTransferHistory = () => {
   const [transferActionsData, setTransferActionsData] = useState<TransferActionOverviewDTO[]>([]);
   const [transferActionsDataItemsTotalCount, setTransferActionsDataItemsTotalCount] = useState(0);
   const [isTransferActionsDataFirstFetchLoading, setIsTransferActionsDataFirstFetchLoading] =
      useState(false);
   const [isTransferActionsDataError, setIsTransferActionsDataError] = useState(false);

   const [isTransferActionsDataInXLSXFormatLoading, setIsTransferActionsDataInXLSXFormatLoading] =
      useState(false);

   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const [orderIdFilter, setOrderIdFilter] = useState('');
   const [debouncedOrderIdFilter] = useDebouncedValue(orderIdFilter, DEBOUNCE_DELAY);

   const [filtersFormValues, setFiltersFormValues] = useState<TransferActionsOverviewFiltersForm>(
      initializeDefaultTransferActionsOverviewFiltersForm(),
   );

   const infiniteScrollContainer = useRef<HTMLDivElement>(null);

   const filters = useMemo<TransferActionsOverviewFilters>(() => {
      const parsedTransferActionsOverviewAdditionalFilters =
         parseTransferActionsOverviewFiltersFormIntoTransferActionsOverviewAdditionalFilters(
            filtersFormValues,
         );
      if (debouncedOrderIdFilter) {
         return {
            ...parsedTransferActionsOverviewAdditionalFilters,
            orderId: debouncedOrderIdFilter,
         };
      }
      return parsedTransferActionsOverviewAdditionalFilters;
   }, [debouncedOrderIdFilter, filtersFormValues]);

   const handleTransferActionsDataError = (error: unknown) =>
      errorHandler(error, () => setIsTransferActionsDataError(true));

   const fetchTransferActionsData = useCallback(
      (currentPage: number, filter?: TransferActionsOverviewFilters) => {
         currentPage === 0 && setIsTransferActionsDataFirstFetchLoading(true);
         HTTPService.getTransferHistoryOverview(
            { page: currentPage, size: PAGINATION_SIZE },
            filter,
         )
            .then(({ data: getTransferHistoryOverviewResponseData }) => {
               if (currentPage === 0) {
                  setTransferActionsData(getTransferHistoryOverviewResponseData.content);
                  setTransferActionsDataItemsTotalCount(
                     getTransferHistoryOverviewResponseData.totalElements,
                  );
                  infiniteScrollContainer.current &&
                     (infiniteScrollContainer.current.scrollTop = 0);
               } else {
                  setTransferActionsData(prevTransferActionsData =>
                     prevTransferActionsData.concat(getTransferHistoryOverviewResponseData.content),
                  );
               }
            })
            .catch(handleTransferActionsDataError)
            .finally(() => currentPage === 0 && setIsTransferActionsDataFirstFetchLoading(false));
      },
      [],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchTransferActionsData,
      filter: filters,
   });

   const handleTransferActionsDataInXLSXFormatError = (error: unknown) =>
      errorHandler(error, () =>
         setBannerData(PALLETS_TRANSFER_HISTORY_PAGE_BANNERS.GET_DATA_IN_XLSX_FORMAT_ERROR),
      );

   const handleGetTransferActionsDataInXLSXFormat = () => {
      setIsTransferActionsDataInXLSXFormatLoading(true);
      HTTPService.getTransferHistoryOverviewInXLSXFormat(filters)
         .then(getTransferHistoryOverviewInXLSXFormatResponseData => {
            const binaryData = [];
            binaryData.push(getTransferHistoryOverviewInXLSXFormatResponseData.data);
            const blob = new Blob(binaryData, {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const href = URL.createObjectURL(blob);
            const currentData = getFormattedDate(new Date(), DATE_FORMAT_WITH_DASHES_REVERSED);
            fileDownload(href, `Historia ruchów paletowych ${currentData}.xlsx`);
            URL.revokeObjectURL(href);
            setBannerData(PALLETS_TRANSFER_HISTORY_PAGE_BANNERS.GET_DATA_IN_XLSX_FORMAT_SUCCESS);
         })
         .catch(handleTransferActionsDataInXLSXFormatError)
         .finally(() => setIsTransferActionsDataInXLSXFormatLoading(false));
   };

   const additionalFiltersCount = useMemo(() => {
      return Object.values(filtersFormValues).filter(Boolean).length;
   }, [filtersFormValues]);

   return {
      transferActionsData,
      transferActionsDataItemsTotalCount,
      isTransferActionsDataFirstFetchLoading,
      isTransferActionsDataError,
      orderIdFilter,
      filtersFormValues,
      additionalFiltersCount,
      isTransferActionsDataInXLSXFormatLoading,
      infiniteScrollContainer,
      bannerData,
      setOrderIdFilter,
      setFiltersFormValues,
      setBannerData,
      handleLoadNextItems,
      handleGetTransferActionsDataInXLSXFormat,
   };
};
