import { getPalletLabel } from 'utils/functions';

import styles from './PalletAmountSummary.module.css';

type PalletAmountSummaryProps = {
   palletsSum: number;
};

const PalletAmountSummary = ({ palletsSum }: PalletAmountSummaryProps) => (
   <div className={styles.summary}>
      <h3>Suma:</h3>
      <span>
         <span className={styles.summaryTextLarge}>{palletsSum}</span>
         {` ${getPalletLabel(palletsSum)}`}
      </span>
   </div>
);

export default PalletAmountSummary;
