import { useMediaQuery } from 'react-responsive';

export const useResponsiveLayout = () => {
   const isMobile = useMediaQuery({
      query: '(max-width: 760px)',
   });

   const isTablet = useMediaQuery({
      query: '(max-width: 1050px)',
   });

   return { isMobile, isTablet };
};
