import React, { ChangeEvent } from 'react';

import { StyledTextInput } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { editPalletBalance } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './EditClientBalanceTableRow.module.css';

const getSumForPalletType = (row: editPalletBalance) =>
   Number(row.rented) +
   Number(row.damagedByUserFault) +
   Number(row.lostOrDestroyedByUserFault) +
   Number(row.receivedDamaged);

type EditClientBalanceTableRowProps = {
   row: editPalletBalance;
   rowIndex: number;
   errors: Record<string, string | boolean>[];
   touched: Record<string, boolean>[];
   handleChangeValue: (rowIndex: number, field: string, value: string) => void;
};

type ConfigurationBalanceAll = {
   amount?: string;
   balanceType: 'rented' | 'lostOrDestroyedByUserFault' | 'damagedByUserFault' | 'receivedDamaged';
};

const EditClientBalanceTableRow = ({
   row,
   rowIndex,
   errors,
   touched,
   handleChangeValue,
}: EditClientBalanceTableRowProps) => {
   const firstCell = PALLET_TYPES_LABELS_WITH_DIMENSIONS[row.palletName];
   const error = errors[rowIndex];
   const touchedItem = touched[rowIndex];
   const EMPTY_BALANCE = '-';
   const sum = row.isAvailableForUser ? getSumForPalletType(row) : EMPTY_BALANCE;

   const configurationEditBalanceAll: ConfigurationBalanceAll[] = [
      {
         amount: row.rented,
         balanceType: 'rented',
      },
      {
         amount: row.lostOrDestroyedByUserFault,
         balanceType: 'lostOrDestroyedByUserFault',
      },
      {
         amount: row.damagedByUserFault,
         balanceType: 'damagedByUserFault',
      },
      {
         amount: row.receivedDamaged,
         balanceType: 'receivedDamaged',
      },
   ];

   return (
      <TableRow className={styles.tableRow} key={rowIndex}>
         <TableCell>
            <div className={styles.nameCell}>
               <p className={styles.paletteType}>{firstCell.label}</p>
               <p className={styles.paletteDimensions}>{firstCell.dimensions}</p>
            </div>
         </TableCell>
         {configurationEditBalanceAll.map((configuration, inputIndex) => {
            return (
               <TableCell align="right" className={styles.cellWithoutArrow} key={inputIndex}>
                  {row.isAvailableForUser ? (
                     <StyledTextInput
                        type="number"
                        value={configuration.amount}
                        className={styles.input}
                        error={
                           !!error[configuration.balanceType] &&
                           touchedItem[configuration.balanceType]
                        }
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           handleChangeValue(
                              rowIndex,
                              configuration.balanceType,
                              event.target.value,
                           );
                        }}
                     />
                  ) : (
                     EMPTY_BALANCE
                  )}
               </TableCell>
            );
         })}

         <TableCell align="right" className={styles.summaryCell}>
            {sum}
         </TableCell>
      </TableRow>
   );
};
export default EditClientBalanceTableRow;
