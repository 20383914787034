import 'dayjs/locale/pl';
import 'dayjs/locale/pl';

import { ChangeEvent, MouseEvent, useCallback } from 'react';

import classNames from 'classnames';

import {
   editPalletsTableHeaders,
   sendDamagedPalletsTableHeaders,
   sendPalletsTableHeaders,
} from 'constants/tableHeaders';
import {
   CommercialNetworkAddressOptions,
   Option,
   SendPalletsItem,
   SendPalletsItemsErrors,
   SendPalletsItemsTouched,
} from 'interfaces';
import { Table } from 'storybook';

import SendPalletsItemRow from './SendPalletsItemRow/SendPalletsItemRow';
import styles from './SendPalletsTable.module.css';

type SendPalletsTableProps = {
   sendPalletsItems: SendPalletsItem[];
   sendPalletsItemsErrors: SendPalletsItemsErrors[];
   sendPalletsItemsTouched: SendPalletsItemsTouched[];
   destinationNameOptions: Option[];
   destinationAddressOptions: CommercialNetworkAddressOptions;
   availablePalletTypes: Option[];
   onAddPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemoveSendPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemovePalletsItem: (sendPalletsItemIndex: number, palletsItemIndex: number) => void;
   onChangeSendPalletsItem: (
      sendPalletsItemIndex: number,
      field: string,
      value: string | Date | null | Option | boolean,
      sendPalletsItem: SendPalletsItem,
      clearTouchedState?: boolean,
   ) => void;
   onChangePalletsItem: (
      sendPalletsItemIndex: number,
      palletsItemIndex: number,
      field: string,
      value: string | Option,
   ) => void;
   onAddCommercialNetwork: (newCommercialNetwork: string) => void;
   onAddCommercialNetworkAddress: (
      commercialNetwork: string,
      newCommercialNetworkAddress: string,
   ) => void;
   sendDamagedPalletsMode?: boolean;
   className?: string;
   isModalEditMode?: boolean;
   onChangeIncludesDamagedPallets: (
      rowIndex: number,
      value: MouseEvent & ChangeEvent<HTMLInputElement>,
      sendPalletsItem: SendPalletsItem,
   ) => void;
};

const SendPalletsTable = ({
   sendPalletsItems,
   sendPalletsItemsErrors,
   sendPalletsItemsTouched,
   destinationNameOptions,
   destinationAddressOptions,
   availablePalletTypes,
   onAddPalletsItem,
   onRemoveSendPalletsItem,
   onRemovePalletsItem,
   onChangeSendPalletsItem,
   onChangePalletsItem,
   onAddCommercialNetwork,
   onAddCommercialNetworkAddress,
   sendDamagedPalletsMode,
   className,
   isModalEditMode,
   onChangeIncludesDamagedPallets,
}: SendPalletsTableProps) => {
   const onSelectDestinationOptions = useCallback(
      (rowIndex: number, value: Option, sendPalletsItem: SendPalletsItem) => {
         onChangeSendPalletsItem(rowIndex, 'commercialNetworkName', value, sendPalletsItem);
         const newCommercialNetworkAddressValue =
            destinationAddressOptions[value.value].length === 1
               ? destinationAddressOptions[value.value][0]
               : {
                    value: '',
                    label: '',
                 };
         onChangeSendPalletsItem(
            rowIndex,
            'commercialNetworkAddress',
            newCommercialNetworkAddressValue,
            sendPalletsItem,
            true,
         );
      },
      [destinationAddressOptions, onChangeSendPalletsItem],
   );

   const sendPalletsHeaders = isModalEditMode ? editPalletsTableHeaders : sendPalletsTableHeaders;

   return (
      <form className={className}>
         <Table
            columnHeaders={
               sendDamagedPalletsMode ? sendDamagedPalletsTableHeaders : sendPalletsHeaders
            }
            headerClassName={classNames(styles.tableHeader, {
               [styles.withEmptyColumn]: sendDamagedPalletsMode,
            })}
            className={styles.table}
         >
            {sendPalletsItems.map((sendPalletsItem, rowIndex) => (
               <SendPalletsItemRow
                  key={sendPalletsItem.uuid}
                  sendPalletsItem={sendPalletsItems[rowIndex]}
                  sendPalletsItemErrors={sendPalletsItemsErrors[rowIndex]}
                  sendPalletsItemTouched={sendPalletsItemsTouched[rowIndex]}
                  destinationNameOptions={destinationNameOptions}
                  destinationAddressOptions={destinationAddressOptions}
                  availablePalletTypes={availablePalletTypes}
                  rowIndex={rowIndex}
                  isModalEditMode={isModalEditMode}
                  sendDamagedPalletsMode={sendDamagedPalletsMode}
                  isVisibleRemoveButton={sendPalletsItems.length > 1}
                  onAddPalletsItem={onAddPalletsItem}
                  onChangeIncludesDamagedPallets={onChangeIncludesDamagedPallets}
                  onChangeSendPalletsItem={onChangeSendPalletsItem}
                  onSelectDestinationOptions={onSelectDestinationOptions}
                  onAddCommercialNetwork={onAddCommercialNetwork}
                  onAddCommercialNetworkAddress={onAddCommercialNetworkAddress}
                  onChangePalletsItem={onChangePalletsItem}
                  onRemoveSendPalletsItem={onRemoveSendPalletsItem}
                  onRemovePalletsItem={onRemovePalletsItem}
               />
            ))}
         </Table>
      </form>
   );
};

export default SendPalletsTable;
