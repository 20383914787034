import { useCallback, useEffect, useState } from 'react';

import { AxiosError } from 'axios';

import { CustomModal, StyledSelect, StyledTextInput } from 'components/shared';
import { MaskedInput } from 'components/shared';
import {
   addCommercialNetworkFormDefaultTouchedValues,
   addCommercialNetworkFormDefaultValues,
   addStorageFormDefaultTouchedValues,
   addStorageFormDefaultValues,
} from 'constants/addStoragePointFormDefaultValues';
import { ADD_STORAGE_POINT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { STORAGE_POINT_TYPES, storagePointTypes } from 'constants/storagePointTypes';
import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';
import { CommercialNetworkDetailsDTO, Option } from 'interfaces';
import { HTTPService } from 'service';
import { CreateCommercialNetworkRequest } from 'service/http/requests';
import { logNetworkError } from 'utils/logNetworkError';
import {
   addCommercialNetworkFormValidations,
   addStorageFormValidations,
} from 'utils/validation/addStoragePointFormValidation';

import styles from './AddStoragePointModal.module.css';

type AddStoragePointModalProps = {
   opened: boolean;
   addCommercialNetworkMode?: boolean;
   onClose: () => void;
   onSuccess: (commercialNetwork: CommercialNetworkDetailsDTO) => void;
};

const AddStoragePointModal = ({
   opened,
   addCommercialNetworkMode,
   onClose,
   onSuccess,
}: AddStoragePointModalProps) => {
   const [storagePointType, setStoragePointType] = useState<Option>();
   const [validations, setValidations] = useState<unknown[]>([]);

   const handleSubmitForm = () => {
      let handleSubmitFunction = () => {
         // empty function
      };
      switch (storagePointType?.value) {
         case STORAGE_POINT_TYPES.COMMERCIAL_NETWORK:
            handleSubmitFunction = handleAddCommercialNetwork;
            break;
         case STORAGE_POINT_TYPES.EXTERNAL_STORAGE:
         case STORAGE_POINT_TYPES.INTERNAL_STORAGE:
            handleSubmitFunction = handleAddStorage;
            break;
      }
      handleSubmitFunction();
   };

   const {
      values,
      touched,
      errorsList,
      changeHandler,
      submitHandler,
      clearForm,
      setValues,
      setTouched,
   } = useForm({
      initialState: {},
      validations,
      onSubmit: handleSubmitForm,
   });

   const handleCloseModal = useCallback(() => {
      !addCommercialNetworkMode && setStoragePointType(undefined);
      clearForm();
      onClose();
   }, [clearForm, onClose, addCommercialNetworkMode]);

   const {
      isLoading,
      errorInfo,
      showCloseWarning,
      setIsLoading,
      setErrorInfo,
      handleHideCloseWarning,
      handleShowCloseWarning,
      closeModalHandler,
   } = useModal({ onClose: handleCloseModal });

   const handleChangeStoragePointType = useCallback(
      (selectedOption: Option) => {
         const selectedAddCommercialNetworkMode =
            selectedOption.value === STORAGE_POINT_TYPES.COMMERCIAL_NETWORK;
         setTouched(
            selectedAddCommercialNetworkMode
               ? addCommercialNetworkFormDefaultTouchedValues
               : addStorageFormDefaultTouchedValues,
         );

         setValues(
            selectedAddCommercialNetworkMode
               ? addCommercialNetworkFormDefaultValues
               : addStorageFormDefaultValues,
         );
         setValidations(
            selectedAddCommercialNetworkMode
               ? addCommercialNetworkFormValidations
               : addStorageFormValidations,
         );
         setStoragePointType(selectedOption);
      },
      [setTouched, setValues],
   );

   useEffect(() => {
      addCommercialNetworkMode &&
         handleChangeStoragePointType({
            label: 'Sieć Handlowa',
            value: STORAGE_POINT_TYPES.COMMERCIAL_NETWORK,
         });
   }, [addCommercialNetworkMode, handleChangeStoragePointType]);

   const handleAddCommercialNetwork = () => {
      setIsLoading(true);
      const { name, city, street, zipCode, contactEmail, phoneNumber } = values;
      const commercialNetworkData: CreateCommercialNetworkRequest = {
         name,
         distributionCenter: {
            address: {
               city,
               street,
               zipCode,
            },
            contactEmail,
            phoneNumber,
         },
      };
      HTTPService.createCommercialNetwork(commercialNetworkData)
         .then(response => {
            closeModalHandler();
            onSuccess(response.data);
         })
         .catch(handleAddCommercialNetworkError)
         .finally(() => setIsLoading(false));
   };

   const handleAddCommercialNetworkError = (error: unknown) => {
      const isAxiosErrorDescriptionExists =
         error instanceof AxiosError &&
         error.response?.data?.code &&
         ADD_STORAGE_POINT_ERROR_DESCRIPTIONS[error.response.data.code];
      setErrorInfo(
         isAxiosErrorDescriptionExists
            ? ADD_STORAGE_POINT_ERROR_DESCRIPTIONS[error.response?.data.code]
            : 'Podczas dodania Sieci Handlowej wystąpił błąd. Spróbuj ponownie.',
      );
      logNetworkError(error);
   };

   const handleAddStorage = () => {
      //TODO: to implement
      console.warn('handleAddStorage');
   };

   const addressInputs = (
      <>
         <StyledTextInput
            type="text"
            name="street"
            label="Ulica, numer domu, numer mieszkania"
            value={values.street}
            helperText={touched.street && errorsList.street}
            className={styles.input}
            onChange={changeHandler}
            error={touched.street && errorsList.street}
            fullWidth
         />
         <div className={styles.inputsGroup}>
            <MaskedInput
               type="text"
               name="zipCode"
               label="Kod pocztowy"
               value={values.zipCode}
               helperText={touched.zipCode && errorsList.zipCode}
               mask="99-999"
               className={styles.zipCodeInput}
               onChange={changeHandler}
               error={touched.zipCode && errorsList.zipCode}
            />
            <StyledTextInput
               type="text"
               name="city"
               label="Miejscowość"
               value={values.city}
               helperText={touched.city && errorsList.city}
               className={styles.cityInput}
               onChange={changeHandler}
               error={touched.city && errorsList.city}
            />
         </div>
      </>
   );

   let formContent;
   switch (storagePointType?.value) {
      case STORAGE_POINT_TYPES.COMMERCIAL_NETWORK:
         formContent = (
            <>
               <StyledTextInput
                  type="text"
                  name="name"
                  label="Nazwa Sieci Handlowej"
                  value={values.name}
                  helperText={touched.name && errorsList.name}
                  className={styles.input}
                  onChange={changeHandler}
                  error={touched.name && errorsList.name}
                  fullWidth
               />
               {addressInputs}
               <StyledTextInput
                  type="text"
                  name="phoneNumber"
                  label="Numer telefonu (opcjonalnie)"
                  value={values.phoneNumber}
                  helperText={
                     (touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber
                  }
                  className={styles.input}
                  onChange={changeHandler}
                  error={(touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber}
                  fullWidth
               />
               <StyledTextInput
                  type="text"
                  name="contactEmail"
                  label="E-mail kontaktowy (opcjonalnie)"
                  value={values.contactEmail}
                  helperText={
                     (touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail
                  }
                  className={styles.input}
                  onChange={changeHandler}
                  error={(touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail}
                  fullWidth
               />
            </>
         );
         break;
      case STORAGE_POINT_TYPES.EXTERNAL_STORAGE:
      case STORAGE_POINT_TYPES.INTERNAL_STORAGE:
         formContent = addressInputs;
         break;
   }

   return (
      <CustomModal
         opened={opened}
         onClose={handleShowCloseWarning}
         isLoading={isLoading}
         error={errorInfo}
         title="Dodaj nowy punkt magazynowy"
         primaryButtonProps={{
            text: 'Dodaj punkt magazynowy',
            variant: 'filled-primary',
            disabled: !storagePointType || !!Object.keys(errorsList).length,
         }}
         secondaryButtonProps={{ text: 'Anuluj', variant: 'text', onClick: handleShowCloseWarning }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         modalContentClassName={styles.modalContent}
         onSubmit={submitHandler}
      >
         <div className={styles.formContentContainer}>
            {addCommercialNetworkMode ? (
               <div className={styles.commercialNetworkTypeContainer}>
                  <p>Typ magazynu</p>
                  <p>Sieć Handlowa</p>
               </div>
            ) : (
               <StyledSelect
                  label="Wybierz typ magazynu"
                  classNames={{ container: styles.storagePointTypeSelect }}
                  options={storagePointTypes}
                  value={storagePointType}
                  onChange={handleChangeStoragePointType}
               />
            )}
            {formContent}
         </div>
      </CustomModal>
   );
};

export default AddStoragePointModal;
