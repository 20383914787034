import { useCallback, useMemo, useState } from 'react';

import { clone } from 'lodash';

import { EMPTY_INPUT_ERROR, EMPTY_INPUTS_ERROR } from 'constants/validationErrors';

type TableFormProps<T> = {
   initialState: T[];
   onSubmit: (values: T[]) => void;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   validations: { [key: string]: (arrg: any) => false | string };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTableForm = <T extends { [key: string]: any }>({
   initialState,
   onSubmit,
   validations,
}: TableFormProps<T>) => {
   const touchedValues = useMemo(() => {
      if (!initialState.length) {
         return [];
      }
      const touchedValuesItem = Object.keys(initialState[0]).reduce(
         (values: Record<string, boolean>, currentKey) => ({
            ...values,
            [currentKey]: false,
         }),
         {},
      );
      return initialState.map(() => clone(touchedValuesItem));
   }, [initialState]);

   const validate = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field: string, value: any) => (!!validations[field] ? validations[field](value) : false),
      [validations],
   );

   const errorValues = useMemo(() => {
      if (!initialState.length) {
         return [];
      }
      return initialState.map(initialStateItem =>
         Object.keys(initialStateItem).reduce(
            (values: Record<string, string | false>, currentKey) => ({
               ...values,
               [currentKey]: validate(
                  currentKey,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (initialStateItem as Record<string, any>)[currentKey],
               ),
            }),
            {},
         ),
      );
   }, [initialState, validate]);

   const [rows, setRows] = useState(initialState);
   const [errors, setErrors] = useState<Record<string, string | false>[]>(errorValues);
   const [touched, setTouched] = useState<Record<string, boolean>[]>(touchedValues);

   const addRow = (row: T) => {
      const touchedValuesItem = Object.keys(row).reduce(
         (values: Record<string, boolean>, currentKey) => ({
            ...values,
            [currentKey]: false,
         }),
         {},
      );
      const errorValuesItem = Object.keys(row).reduce(
         (values: Record<string, string | false>, currentKey) => ({
            ...values,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [currentKey]: validate(currentKey, (row as Record<string, any>)[currentKey]),
         }),
         {},
      );
      setRows(prevRows => [...prevRows, row]);
      setTouched(prevTouched => [...prevTouched, touchedValuesItem]);
      setErrors(prevErrors => [...prevErrors, errorValuesItem]);
   };

   const removeRow = (rowIndex: number) => {
      setRows(prevRows => {
         const actualRows = [...prevRows];
         actualRows.splice(rowIndex, 1);
         return actualRows;
      });
      setTouched(prevTouched => {
         const actualTouched = [...prevTouched];
         actualTouched.splice(rowIndex, 1);
         return actualTouched;
      });
      setErrors(prevErrors => {
         const actualErrors = [...prevErrors];
         actualErrors.splice(rowIndex, 1);
         return actualErrors;
      });
   };

   const handleChangeValue = (
      rowIndex: number,
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
   ) => {
      setRows(prevRows => {
         const actualRows = [...prevRows];
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         (actualRows[rowIndex] as Record<string, any>)[field] = value;
         return actualRows;
      });
      setTouched(prevTouched => {
         const actualTouched = [...prevTouched];
         actualTouched[rowIndex][field] = true;
         return actualTouched;
      });
      setErrors(prevErros => {
         const actualErrors = [...prevErros];
         actualErrors[rowIndex][field] = validate(field, value);
         return actualErrors;
      });
   };

   const handleSubmitForm = (event?: React.FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      const newTouchedValues = [...touched];
      newTouchedValues.forEach((touchedValuesItem, index) =>
         Object.keys(touchedValuesItem).forEach(key => {
            newTouchedValues[index][key] = true;
         }),
      );
      setTouched(newTouchedValues);
      const formInvalid = errors.some(errorsItem =>
         Object.values(errorsItem).some(errorValue => !!errorValue),
      );
      !formInvalid && onSubmit(rows);
   };

   const commonErrorsOfTouchedInputs = useMemo(() => {
      let allTableErrors: string[] = [];
      errors.forEach((error, rowTableIndex) => {
         Object.entries(error).forEach(([key, value]) => {
            value && touched[rowTableIndex][key] && allTableErrors.push(value as string);
         });
      });
      const countEmptyInputsErrors = allTableErrors.reduce(
         (totalCount, currentError) =>
            currentError === EMPTY_INPUT_ERROR ? totalCount + 1 : totalCount,
         0,
      );
      if (countEmptyInputsErrors > 1) {
         allTableErrors = allTableErrors.filter(error => error !== EMPTY_INPUT_ERROR);
         allTableErrors.push(EMPTY_INPUTS_ERROR);
      }
      return new Set(allTableErrors);
   }, [errors, touched]);

   const hasErrors = useMemo(() => {
      return errors.some(error => Object.values(error).some(Boolean));
   }, [errors]);

   return {
      rows,
      touched,
      errors,
      commonErrorsOfTouchedInputs,
      addRow,
      removeRow,
      handleChangeValue,
      handleSubmitForm,
      setRows,
      hasErrors,
   };
};
