import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Loader, Modal, ModalProps } from '@mantine/core';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { cloneDeep, set, uniq, uniqBy, uniqueId } from 'lodash';

import { CustomInfiniteScroll, CustomModal } from 'components/shared';
import NotFoundOrderAlertModal from 'components/shared/NotFoundOrderAlertModal/NotFoundOrderAlertModal';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import CloseWarningModal from 'components/shared/WarningModal/WarningModals/CloseWarningModal';
import { VGL_COMPANY_NAME } from 'constants/companiesNames';
import {
   INVALID_IMAGE_FILE,
   INVALID_SHIPMENT_PALLET_AMOUNT,
   INVALID_USER_PALLET_TYPES,
   ORDER_PALLET_TYPE_NOT_FOUND,
} from 'constants/errorCodes';
import {
   DEFAULT_EDIT_PALLETS_ITEMS_ERRORS,
   DEFAULT_EDIT_PALLETS_ITEMS_TOUCHED,
   DEFAULT_PALLETS_ITEM,
   DEFAULT_SEND_PALLETS_ITEM,
   DEFAULT_SEND_PALLETS_ITEMS_ERRORS,
   DEFAULT_SEND_PALLETS_ITEMS_TOUCHED,
} from 'constants/palletsTableDefaultValues';
import { PALLETS_ITEM_PREFIX } from 'constants/uniqueIdPrefixes';
import { EMPTY_INPUT_ERROR } from 'constants/validationErrors';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import {
   AddressOption,
   CreateSendPalletDTO,
   ExcessPallets,
   GroupedLostDamagedPalletItems,
   LostDamagedPalletsByClientItem,
   LostDamagedPalletsBySupplierItem,
   Option,
   OrderDetailsDTO,
   OrderDTO,
   PalletReportDTO,
   PalletType,
   SendPalletDTO,
   SendPalletsItem,
} from 'interfaces';
import { HTTPService, ShipmentAddressDestinationsResponse } from 'service';
import { LostDamagedPalletData, PalletReportRequest } from 'service/http/requests';
import { Banner } from 'storybook';
import {
   getSendPalletsItemErrors,
   getTouchedSendPalletsItem,
   parseCreateSendPalletDTOIntoSendPalletAddressDTO,
} from 'utils/functions';
import { getEditPalletsItems } from 'utils/functions/getEditPalletsItems';
import { getPalletLabel } from 'utils/functions/getPalletLabel';
import { sortOptions } from 'utils/functions/sortOptions';
import { logNetworkError } from 'utils/logNetworkError';
import { sendPalletsFormValidations } from 'utils/validation/sendPalletsFormValidation';

import FileUploadingModal from './FileUploadingModal/FileUploadingModal';
import PalletsToSendFileDropzone from './PalletsToSendExcelDropzone/PalletsToSendFileDropzone';
import SendPalletsMobileList from './SendPalletsMobileList/SendPalletsMobileList';
import SendPalletsTable from './SendPalletsTable/SendPalletsTable';
import SendPalletsWithWrongAddressesWarningModal from './SendPalletsWithWrongAddressesWarningModal/SendPalletsWithWrongAddressesWarningModal';
import {
   INITIAL_TABLE_INFINITE_SCROLL_STEP_NUMBER,
   TABLE_INFINITE_SCROLL_STEP_SIZE,
   WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE,
   Y_ZERO_COORDINATE,
} from './SendPalletsModal.consts';
import styles from './SendPalletsModal.module.css';
import { getSubmitButtonText } from './SendPalletsModal.utils';

type SendPalletsModalProps = ModalProps & {
   orderId?: string;
   availablePalletTypes: Option[];
   groupedLostDamagedPalletItems?: GroupedLostDamagedPalletItems | null;
   sendingAddressOptions: AddressOption;
   onAddCommercialNetwork: (newCommercialNetwork: string) => void;
   onAddCommercialNetworkAddress: (
      commercialNetwork: string,
      newCommercialNetworkAddress: string,
   ) => void;
   clientId?: string;
   clientCompanyName?: string;
   onSuccessSendOrEditPalletsFromOrder?: (
      orderDetails: OrderDetailsDTO,
      isEditMode: boolean,
   ) => void;
   onSuccessSendPalletsFromMultipleOrders?: (orders: OrderDTO[]) => void;
   onSuccessReportSendDamagedPalletsFromOrder?: (
      damagedBySupplier: boolean,
      palletReport: PalletReportDTO,
   ) => void;
   onSuccessReportAndSendLostDamagedPalletsFromMultipleOrders?: (
      damagedBySupplier: boolean,
      palletReports: PalletReportDTO[],
   ) => void;
   editPalletsItems?: SendPalletDTO[];
};

const SendPalletsModal = ({
   orderId,
   availablePalletTypes,
   groupedLostDamagedPalletItems,
   sendingAddressOptions,
   clientId,
   clientCompanyName,
   onAddCommercialNetwork,
   onAddCommercialNetworkAddress,
   onSuccessSendOrEditPalletsFromOrder,
   onSuccessSendPalletsFromMultipleOrders,
   onSuccessReportSendDamagedPalletsFromOrder,
   onSuccessReportAndSendLostDamagedPalletsFromMultipleOrders,
   editPalletsItems,
   ...props
}: SendPalletsModalProps) => {
   const isModalEditMode = !!editPalletsItems;
   const [sendPalletsItems, setSendPalletsItems] = useState([DEFAULT_SEND_PALLETS_ITEM()]);
   const [sendPalletsItemsErrors, setSendPalletsItemsErrors] = useState([
      DEFAULT_SEND_PALLETS_ITEMS_ERRORS(),
   ]);
   const [sendPalletsItemsTouched, setSendPalletsItemsTouched] = useState([
      DEFAULT_SEND_PALLETS_ITEMS_TOUCHED(),
   ]);
   const [tableInfiniteScrollStep, setTableInfiniteScrollStep] = useState(
      INITIAL_TABLE_INFINITE_SCROLL_STEP_NUMBER,
   );
   const [isUploadingSendPalletsDataFile, setIsUploadingSendPalletsDataFile] = useState(false);
   const [isSuccessUploadingSendPalletsDataFile, setIsSuccessUploadingSendPalletsDataFile] =
      useState(false);
   const [isPalletsShipmentAddressesDataValid, setIsPalletsShipmentAddressesDataValid] =
      useState(false);
   const [isSendPalletsDataFileUploaded, setIsSendPalletsDataFileUploaded] = useState(false);
   const [isSendPalletsDataFileVerifying, setIsSendPalletsDataFileVerifying] = useState(false);
   const [isSendPalletsDataFileVerified, setIsSendPalletsDataFileVerified] = useState(false);
   const [loading, setLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState<string | null>(null);
   const [showCloseWarning, setShowCloseWarning] = useState(false);
   const [invalidSendDamagePalletsAmountError, setInvalidSendDamagePalletsAmountError] =
      useState(false);
   const [
      isSendPalletsWithWrongAddressesWarningModalOpened,
      setIsSendPalletsWithWrongAddressesWarningModalOpened,
   ] = useState(false);
   const [excessPallets, setExcessPallets] = useState<ExcessPallets | null>(null);
   const [isDefaultCommercialNetworkAddressChecked, setIsDefaultCommercialNetworkAddressChecked] =
      useState(false);
   const sendPalletsTableContainerRef = useRef<HTMLDivElement>(null);
   const { isTablet } = useResponsiveLayout();

   const isSendGroupedLostDamagedPalletsMode = !!groupedLostDamagedPalletItems;
   const isAdminMode = !!clientId;

   const getDestinationIfEditMode = useCallback(
      (editItems: SendPalletDTO[]) => {
         const destinationNameOptionsCopy = cloneDeep(sendingAddressOptions.nameOptions);
         const editPalletsItemsWithNewAddressDestinationType = filterByDestinationType(editItems);
         const newAddressNames = editPalletsItemsWithNewAddressDestinationType.map(
            editPalletsItem => editPalletsItem.displayName,
         );
         const uniqueNewAddressNames = uniq(newAddressNames);
         const newAddressNameOptions = uniqueNewAddressNames.map(addressName => ({
            value: addressName,
            label: addressName,
         }));
         const uniqueAllDestinationNameOptions = uniqBy(
            [...destinationNameOptionsCopy, ...newAddressNameOptions],
            'value',
         );
         return sortOptions(uniqueAllDestinationNameOptions);
      },
      [sendingAddressOptions.nameOptions],
   );

   const getDestinationAddressIfEditMode = useCallback(
      (editItems: SendPalletDTO[]) => {
         const destinationAddressOptionsCopy = cloneDeep(sendingAddressOptions.addressOptions);
         const editPalletsItemsWithNewAddressDestinationType = filterByDestinationType(editItems);
         const newDestinationAddressOptions = editPalletsItemsWithNewAddressDestinationType.reduce<{
            [key: string]: Option[];
         }>((totalAddressItems, currentAddressItem) => {
            const newAddressOption: { label: string; value: string } = {
               label: currentAddressItem.displayAddress,
               value: currentAddressItem.displayAddress,
            };
            const destinationName = currentAddressItem.displayName;
            const addressOptions = totalAddressItems[destinationName];
            if (addressOptions) {
               const isNewAddressOptionExists = addressOptions.find(
                  addressOption => addressOption.value === newAddressOption.value,
               );
               return isNewAddressOptionExists
                  ? totalAddressItems
                  : {
                       ...totalAddressItems,
                       [destinationName]: [...addressOptions, newAddressOption],
                    };
            }
            return {
               ...totalAddressItems,
               [destinationName]: [newAddressOption],
            };
         }, destinationAddressOptionsCopy);
         return newDestinationAddressOptions;
      },
      [sendingAddressOptions.addressOptions],
   );

   const destinationNameOptions = useMemo(() => {
      if (!isModalEditMode) {
         return sendingAddressOptions.nameOptions;
      }
      return getDestinationIfEditMode(editPalletsItems);
   }, [
      isModalEditMode,
      editPalletsItems,
      sendingAddressOptions.nameOptions,
      getDestinationIfEditMode,
   ]);

   const destinationAddressOptions = useMemo(() => {
      if (!isModalEditMode) {
         return sendingAddressOptions.addressOptions;
      }
      return getDestinationAddressIfEditMode(editPalletsItems);
   }, [
      isModalEditMode,
      editPalletsItems,
      sendingAddressOptions.addressOptions,
      getDestinationAddressIfEditMode,
   ]);

   useEffect(() => {
      const isDestinationOptionsAvailable =
         destinationNameOptions.length && Object.keys(destinationAddressOptions).length;
      const isInitialData =
         sendPalletsItems.length === 1 &&
         !sendPalletsItems[0].commercialNetworkName?.value &&
         !sendPalletsItems[0].commercialNetworkAddress?.value &&
         !sendPalletsItems[0].externalCommercialNetworkNumber &&
         !sendPalletsItems[0].palletsItems[0].palletsAmount &&
         !sendPalletsItems[0].palletsItems[0].palletType.value;

      if (
         !isDestinationOptionsAvailable ||
         !isInitialData ||
         isDefaultCommercialNetworkAddressChecked
      ) {
         return;
      }

      setIsDefaultCommercialNetworkAddressChecked(true);
      setSendPalletsItems([
         DEFAULT_SEND_PALLETS_ITEM(destinationNameOptions, destinationAddressOptions),
      ]);
      setSendPalletsItemsErrors([
         DEFAULT_SEND_PALLETS_ITEMS_ERRORS(destinationNameOptions, destinationAddressOptions),
      ]);
   }, [
      destinationAddressOptions,
      destinationNameOptions,
      isDefaultCommercialNetworkAddressChecked,
      sendPalletsItems,
      sendPalletsItems.length,
   ]);

   useEffect(() => {
      if (groupedLostDamagedPalletItems) {
         const { damaged: damagedPalletItemsToSend } = groupedLostDamagedPalletItems;
         const transformedPalletsItems = damagedPalletItemsToSend.map(itemToSend => ({
            uuid: uniqueId(PALLETS_ITEM_PREFIX),
            palletType: itemToSend.palletType,
            palletsAmount: itemToSend.palletsAmount,
         }));
         const palletsItemsErrorsAndTouched: {
            palletType: false;
            palletsAmount: false;
         }[] = damagedPalletItemsToSend.map(() => ({ palletType: false, palletsAmount: false }));
         setSendPalletsItems([
            set(
               DEFAULT_SEND_PALLETS_ITEM(destinationNameOptions, destinationAddressOptions),
               'palletsItems',
               transformedPalletsItems,
            ),
         ]);
         setSendPalletsItemsErrors([
            set(
               DEFAULT_SEND_PALLETS_ITEMS_ERRORS(destinationNameOptions, destinationAddressOptions),
               'palletsItems',
               palletsItemsErrorsAndTouched,
            ),
         ]);
         setSendPalletsItemsTouched([
            set(DEFAULT_SEND_PALLETS_ITEMS_TOUCHED(), 'palletsItems', palletsItemsErrorsAndTouched),
         ]);
      }
   }, [
      destinationAddressOptions,
      destinationNameOptions,
      groupedLostDamagedPalletItems,
      sendingAddressOptions,
   ]);

   useEffect(() => {
      const editPalletsItemsDTO = getEditPalletsItems(editPalletsItems);
      const initialEditSendPalletsTouched = () => {
         return editPalletsItemsDTO?.map(item => {
            return DEFAULT_EDIT_PALLETS_ITEMS_TOUCHED(item.palletsItems.length);
         });
      };
      const initialEditSendPalletsErrors = () => {
         return editPalletsItemsDTO?.map(item => {
            return DEFAULT_EDIT_PALLETS_ITEMS_ERRORS(item.palletsItems.length);
         });
      };
      isModalEditMode && setSendPalletsItems(editPalletsItemsDTO!);
      isModalEditMode && setSendPalletsItemsTouched(initialEditSendPalletsTouched()!);
      isModalEditMode && setSendPalletsItemsErrors(initialEditSendPalletsErrors()!);
   }, [isModalEditMode, editPalletsItems]);

   const closeModal = () => {
      clearModalState();
      props.onClose();
   };

   const handleSuccessSendOrEditPalletsFromOrder = (orderDetails: OrderDetailsDTO) => {
      closeModal();
      onSuccessSendOrEditPalletsFromOrder?.(orderDetails, isModalEditMode);
   };

   const handleSuccessSendPalletsFromMultipleOrders = (orders: OrderDTO[]) => {
      closeModal();
      onSuccessSendPalletsFromMultipleOrders?.(orders);
   };

   const handleSuccessReportSendDamagedPalletsFromOrder = (
      damagedBySupplier: boolean,
      palletReport: PalletReportDTO,
   ) => {
      closeModal();
      onSuccessReportSendDamagedPalletsFromOrder?.(damagedBySupplier, palletReport);
   };

   const handleSuccessReportAndSendLostDamagedPalletsFromMultipleOrders = (
      damagedBySupplier: boolean,
      palletReports: PalletReportDTO[],
   ) => {
      closeModal();
      onSuccessReportAndSendLostDamagedPalletsFromMultipleOrders?.(
         damagedBySupplier,
         palletReports,
      );
   };

   const sendPallets = (palletsToSend: CreateSendPalletDTO[]) => {
      if (isModalEditMode && orderId) {
         return HTTPService.editSendPalletsFromOrder(orderId, palletsToSend).then(
            ({ data: orderDetailsData }) => {
               handleSuccessSendOrEditPalletsFromOrder(orderDetailsData);
            },
         );
      }

      if (orderId) {
         return HTTPService.sendPalletsFromOrder(orderId, palletsToSend).then(
            ({ data: orderDetailsData }) => {
               handleSuccessSendOrEditPalletsFromOrder(orderDetailsData);
            },
         );
      }
      if (!clientId) {
         return HTTPService.sendPalletsFromMultipleOrders(palletsToSend).then(
            ({ data: ordersData }) => {
               handleSuccessSendPalletsFromMultipleOrders(ordersData.ordersWithUpdatedShipment);
            },
         );
      }
      return HTTPService.sendPalletsFromMultipleOrdersByAdmin(clientId, palletsToSend).then(
         ({ data: ordersData }) => {
            handleSuccessSendPalletsFromMultipleOrders(ordersData.ordersWithUpdatedShipment);
         },
      );
   };

   const verifyPalletsShipmentAddresses = async (palletsToSend: CreateSendPalletDTO[]) => {
      setIsSendPalletsDataFileVerifying(true);

      let responseData: ShipmentAddressDestinationsResponse | undefined;
      const parsedPalletsToSend = palletsToSend.map(
         parseCreateSendPalletDTOIntoSendPalletAddressDTO,
      );

      try {
         if (isAdminMode) {
            const response = await HTTPService.clientPalletsShipmentAddressesVerification(
               clientId,
               {
                  addresses: parsedPalletsToSend,
               },
            );
            responseData = response.data;
         } else {
            const response = await HTTPService.palletsShipmentAddressesVerification({
               addresses: parsedPalletsToSend,
            });
            responseData = response.data;
         }
         setIsSendPalletsDataFileVerified(true);
      } catch (error) {
         logNetworkError(error);
         setErrorMessage('Podczas weryfikacji adresów nadań wystąpił błąd.');
      }

      const someInvalidShipmentAddresses = responseData?.destinations.some(
         destination => destination.shouldUserBeChargedForInvalidAddress,
      );

      if (!someInvalidShipmentAddresses) {
         setIsPalletsShipmentAddressesDataValid(true);
      }

      if (someInvalidShipmentAddresses) {
         setSendPalletsItemsErrors(prevErrors => {
            const actualErrors = cloneDeep(prevErrors);
            responseData?.destinations.forEach((destination, index) => {
               if (destination.shouldUserBeChargedForInvalidAddress) {
                  actualErrors[index].commercialNetworkName = WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE;
                  actualErrors[index].commercialNetworkAddress =
                     WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE;
               }
            });
            return actualErrors;
         });
      }

      setIsSendPalletsDataFileVerifying(false);
   };

   const handleSendPallets = (palletsToSend: CreateSendPalletDTO[]) => {
      sendPallets(palletsToSend)
         .catch(error => {
            if (error instanceof AxiosError) {
               if (error.response?.data.code === ORDER_PALLET_TYPE_NOT_FOUND) {
                  setErrorMessage(
                     'Nie można nadać palet, które nie są zdefiniowane w ramach zamówienia.',
                  );
               }
               if (
                  error.response?.data.code === INVALID_SHIPMENT_PALLET_AMOUNT &&
                  error.response?.data.pallets
               ) {
                  const pallets: ExcessPallets = error.response.data.pallets;
                  setExcessPallets(pallets);
               }
               if (error.response?.data.code === INVALID_USER_PALLET_TYPES) {
                  setErrorMessage('Niektóre z nadawanych typów palet nie są przypisane do konta.');
               }
            } else {
               setErrorMessage('Podczas nadania palet wystąpił błąd.');
            }
            logNetworkError(error);
         })
         .finally(() => setLoading(false));
   };

   const reportPallets = (palletsReport: PalletReportRequest, lostDamagedBySupplier: boolean) => {
      if (orderId) {
         return HTTPService.reportPalletsFromOrder(orderId, palletsReport).then(
            ({ data: responseData }) =>
               handleSuccessReportSendDamagedPalletsFromOrder(lostDamagedBySupplier, responseData),
         );
      }
      if (!clientId) {
         return HTTPService.reportPalletsFromMultipleOrders(palletsReport).then(
            ({ data: responseData }) =>
               handleSuccessReportAndSendLostDamagedPalletsFromMultipleOrders(
                  lostDamagedBySupplier,
                  responseData.reports,
               ),
         );
      }
      return HTTPService.reportPalletsFromMultipleOrdersByAdmin(clientId, palletsReport).then(
         ({ data: responseData }) =>
            handleSuccessReportAndSendLostDamagedPalletsFromMultipleOrders(
               lostDamagedBySupplier,
               responseData.reports,
            ),
      );
   };

   const handleReportLostDamagedAndSendDamagedPallets = (palletsToSend: CreateSendPalletDTO[]) => {
      const { damaged, lostOrDestroyed } = groupedLostDamagedPalletItems!;
      const lostDamagedPalletItems = [...damaged, ...lostOrDestroyed];
      const lostDamagedBySupplier = 'images' in lostDamagedPalletItems[0];
      let lostDamagedPallets: LostDamagedPalletData[] = [];
      if (lostDamagedBySupplier) {
         lostDamagedPallets = (lostDamagedPalletItems as LostDamagedPalletsBySupplierItem[]).map(
            item => ({
               type: item.palletType.value as PalletType,
               reportType: item.damageType,
               amount: parseInt(item.palletsAmount, 10),
               images: item.images,
            }),
         );
      } else {
         lostDamagedPallets = (lostDamagedPalletItems as LostDamagedPalletsByClientItem[]).map(
            item => ({
               type: item.palletType.value as PalletType,
               reportType: item.damageType,
               amount: parseInt(item.palletsAmount, 10),
            }),
         );
      }
      const palletsReport: PalletReportRequest = {
         pallets: lostDamagedPallets,
         receivedDamaged: lostDamagedBySupplier,
         sendPallets: palletsToSend,
      };
      reportPallets(palletsReport, lostDamagedBySupplier)
         .catch(error => {
            if (
               error instanceof AxiosError &&
               error.response?.data['code'] === INVALID_IMAGE_FILE
            ) {
               setErrorMessage('Przekroczony limit dla zdjęcia (maks. 5 MB).');
            } else if (
               error instanceof AxiosError &&
               error.response?.data?.errors?.SendDamagePalletsAmountValidator
            ) {
               setInvalidSendDamagePalletsAmountError(true);
            } else {
               setErrorMessage('Podczas wysyłania zgłoszenia wystąpił błąd.');
            }
            logNetworkError(error);
         })
         .finally(() => setLoading(false));
   };

   const validateForm = () => {
      const newTouchedValues = cloneDeep(sendPalletsItemsTouched);
      newTouchedValues.forEach((touchedValuesItem, index) => {
         Object.entries(touchedValuesItem).forEach(([key, value]) => {
            if (!Array.isArray(value)) {
               newTouchedValues[index][key] = true;
            } else {
               touchedValuesItem.palletsItems.forEach((palletsItem, palletsItemIndex) => {
                  newTouchedValues[index].palletsItems[palletsItemIndex].palletType = true;
                  newTouchedValues[index].palletsItems[palletsItemIndex].palletsAmount = true;
               });
            }
         });
      });
      setSendPalletsItemsTouched(newTouchedValues);
      const formValid = !sendPalletsItemsErrors.some(sendPalletsItemErrors => {
         return Object.values(sendPalletsItemErrors).some(sendPalletsItemError => {
            if (!Array.isArray(sendPalletsItemError)) {
               return (
                  !!sendPalletsItemError &&
                  sendPalletsItemError !== WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE
               );
            } else {
               return sendPalletsItemErrors.palletsItems.some(palletsItemError => {
                  return !!palletsItemError.palletType || !!palletsItemError.palletsAmount;
               });
            }
         });
      });

      return formValid;
   };

   const prepareSendPalletsData = () => {
      if (!isModalEditMode) {
         return sendPalletsItems.map(sendPalletsItem => ({
            dateOfShipment: sendPalletsItem.sendDate!.toLocaleDateString('en-GB'),
            externalCommercialNetworkNumber: sendPalletsItem.externalCommercialNetworkNumber,
            commercialNetworkName: sendPalletsItem.commercialNetworkName!.value,
            commercialNetworkAddress: sendPalletsItem.commercialNetworkAddress!.value,
            pallets: sendPalletsItem.palletsItems.map(palletsItem => ({
               palletType: palletsItem.palletType.value as PalletType,
               amount: parseInt(palletsItem.palletsAmount, 10),
            })),
         }));
      }
      return sendPalletsItems.map(sendPalletsItem => ({
         dateOfShipment: sendPalletsItem.sendDate!.toLocaleDateString('en-GB'),
         externalCommercialNetworkNumber: sendPalletsItem.externalCommercialNetworkNumber,
         commercialNetworkName: sendPalletsItem.commercialNetworkName!.value,
         commercialNetworkAddress: sendPalletsItem.commercialNetworkAddress!.value,
         pallets: sendPalletsItem.palletsItems.map(palletsItem => ({
            palletType: palletsItem.palletType.value as PalletType,
            amount: parseInt(palletsItem.palletsAmount, 10),
         })),
         includesDamagedPallets: sendPalletsItem.includesDamagedPallets,
      }));
   };

   const areWrongShipmentAddresses = useMemo(
      () =>
         sendPalletsItemsErrors.some(
            sendPalletsItemsErrorItem =>
               sendPalletsItemsErrorItem.commercialNetworkName ===
                  WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE ||
               sendPalletsItemsErrorItem.commercialNetworkAddress ===
                  WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE,
         ),
      [sendPalletsItemsErrors],
   );

   const handleSubmitButtonClick = () => {
      if (areWrongShipmentAddresses) {
         setIsSendPalletsWithWrongAddressesWarningModalOpened(true);
         return;
      }
      handleSubmitForm();
   };

   const handleSubmitForm = () => {
      setErrorMessage(null);
      const formValid = validateForm();
      if (formValid) {
         setLoading(true);
         const sendPalletsDataToSend = prepareSendPalletsData();
         if (isSendGroupedLostDamagedPalletsMode) {
            handleReportLostDamagedAndSendDamagedPallets(sendPalletsDataToSend);
         } else {
            handleSendPallets(sendPalletsDataToSend);
         }
      }
   };

   const handleVerifyPalletsShipmentAddressesButtonClick = () => {
      setErrorMessage(null);
      const formValid = validateForm();
      if (formValid) {
         const sendPalletsDataToSend = prepareSendPalletsData();
         verifyPalletsShipmentAddresses(sendPalletsDataToSend);
      }
   };

   const handleSuccessUploadSendPalletsDataFromFile = useCallback(
      (sendPalletsItemsData: SendPalletsItem[]) => {
         setIsSendPalletsDataFileUploaded(true);
         setIsSuccessUploadingSendPalletsDataFile(true);
         setIsSendPalletsDataFileVerified(false);
         hideAfterDelaySuccessUploadingSendPalletsDataFileBanner();
         clearSendPalletsDataForm();
         sendPalletsItemsData.forEach(sendPalletsItemData => {
            setSendPalletsItems(prevItems => [...prevItems, sendPalletsItemData]);
            setSendPalletsItemsTouched(prevTouched => [
               ...prevTouched,
               getTouchedSendPalletsItem(sendPalletsItemData),
            ]);
            setSendPalletsItemsErrors(prevErrors => [
               ...prevErrors,
               getSendPalletsItemErrors(sendPalletsItemData),
            ]);
         });
      },
      [],
   );

   const handleFailUploadSendPalletsDataFromFile = useCallback((error: unknown) => {
      const typedError = error as Error;
      setErrorMessage(typedError.message || 'Podczas ładowania danych wystąpił błąd.');
   }, []);

   const handleStartUploadSendPalletsDataFromFile = useCallback(
      () => setIsUploadingSendPalletsDataFile(true),
      [],
   );

   const handleFinishUploadSendPalletsDataFromFile = useCallback(
      () => setIsUploadingSendPalletsDataFile(false),
      [],
   );

   const handleAddSendPalletsItem = () => {
      setSendPalletsItems(prevItems => [
         DEFAULT_SEND_PALLETS_ITEM(destinationNameOptions, destinationAddressOptions),
         ...prevItems,
      ]);
      setSendPalletsItemsTouched(prevTouched => [
         DEFAULT_SEND_PALLETS_ITEMS_TOUCHED(),
         ...prevTouched,
      ]);
      setSendPalletsItemsErrors(prevErrors => [
         DEFAULT_SEND_PALLETS_ITEMS_ERRORS(destinationNameOptions, destinationAddressOptions),
         ...prevErrors,
      ]);
   };

   const handleAddPalletsItem = useCallback((sendPalletsItemIndex: number) => {
      setSendPalletsItems(prevItems => {
         const actualItems = cloneDeep(prevItems);
         actualItems[sendPalletsItemIndex].palletsItems.push(DEFAULT_PALLETS_ITEM());
         return actualItems;
      });
      setSendPalletsItemsTouched(prevTouched => {
         const actualTouched = cloneDeep(prevTouched);
         actualTouched[sendPalletsItemIndex].palletsItems.push({
            palletType: false,
            palletsAmount: false,
         });
         return actualTouched;
      });
      setSendPalletsItemsErrors(prevErros => {
         const actualErrors = cloneDeep(prevErros);
         actualErrors[sendPalletsItemIndex].palletsItems.push({
            palletType: EMPTY_INPUT_ERROR,
            palletsAmount: EMPTY_INPUT_ERROR,
         });
         return actualErrors;
      });
   }, []);

   const handleRemoveSendPalletsItem = useCallback((sendPalletsItemIndex: number) => {
      setSendPalletsItems(prevItems => {
         const actualItems = cloneDeep(prevItems);
         actualItems.splice(sendPalletsItemIndex, 1);
         return actualItems;
      });
      setSendPalletsItemsTouched(prevTouched => {
         const actualTouched = cloneDeep(prevTouched);
         actualTouched.splice(sendPalletsItemIndex, 1);
         return actualTouched;
      });
      setSendPalletsItemsErrors(prevErrors => {
         const actualErrors = cloneDeep(prevErrors);
         actualErrors.splice(sendPalletsItemIndex, 1);
         return actualErrors;
      });
   }, []);

   const handleRemovePalletsItem = useCallback(
      (sendPalletsItemIndex: number, palletsItemIndex: number) => {
         setSendPalletsItems(prevItems => {
            const actualItems = cloneDeep(prevItems);
            actualItems[sendPalletsItemIndex].palletsItems.splice(palletsItemIndex, 1);
            return actualItems;
         });
         setSendPalletsItemsTouched(prevTouched => {
            const actualTouched = cloneDeep(prevTouched);
            actualTouched[sendPalletsItemIndex].palletsItems.splice(palletsItemIndex, 1);
            return actualTouched;
         });
         setSendPalletsItemsErrors(prevErrors => {
            const actualErrors = cloneDeep(prevErrors);
            actualErrors[sendPalletsItemIndex].palletsItems.splice(palletsItemIndex, 1);
            return actualErrors;
         });
      },
      [],
   );

   const handleChangeSendPalletsItem = useCallback(
      (
         sendPalletsItemIndex: number,
         field: string,
         value: string | Date | null | Option | boolean,
         sendPalletsItem: SendPalletsItem,
         clearTouchedState?: boolean,
      ) => {
         setSendPalletsItems(prevItems => {
            const sendPalletsItemToUpdate = cloneDeep(prevItems[sendPalletsItemIndex]);
            sendPalletsItemToUpdate[field] = value;
            return prevItems.map((actualRow, index) => {
               if (index === sendPalletsItemIndex) {
                  return sendPalletsItemToUpdate;
               }
               return actualRow;
            });
         });
         setSendPalletsItemsTouched(prevTouched => {
            const sendPalletsItemTouchedToUpdate = cloneDeep(prevTouched[sendPalletsItemIndex]);
            sendPalletsItemTouchedToUpdate[field] = !clearTouchedState;
            return prevTouched.map((actualRow, index) => {
               if (index === sendPalletsItemIndex) {
                  return sendPalletsItemTouchedToUpdate;
               }
               return actualRow;
            });
         });
         setSendPalletsItemsErrors(prevErrors => {
            const sendPalletsItemErrorsToUpdate = cloneDeep(prevErrors[sendPalletsItemIndex]);

            const changedIncorrectCommercialNetworkName =
               field === 'commercialNetworkName' &&
               sendPalletsItemErrorsToUpdate.commercialNetworkName ===
                  WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE;
            const changedIncorrectCommercialNetworkAddress =
               field === 'commercialNetworkAddress' &&
               sendPalletsItemErrorsToUpdate.commercialNetworkAddress ===
                  WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE;

            if (changedIncorrectCommercialNetworkName || changedIncorrectCommercialNetworkAddress) {
               sendPalletsItemErrorsToUpdate.commercialNetworkName = false;
               sendPalletsItemErrorsToUpdate.commercialNetworkAddress = false;
               setIsSendPalletsDataFileVerified(false);
            }

            sendPalletsItemErrorsToUpdate[field] = validateField(field, value);

            return prevErrors.map((actualRow, index) => {
               if (index === sendPalletsItemIndex) {
                  return sendPalletsItemErrorsToUpdate;
               }
               return actualRow;
            });
         });
      },
      [],
   );

   const handleChangePalletsItem = useCallback(
      (
         sendPalletsItemIndex: number,
         palletsItemIndex: number,
         field: string,
         value: string | Option,
      ) => {
         setSendPalletsItems(prevItems => {
            const actualRows = cloneDeep(prevItems);
            actualRows[sendPalletsItemIndex].palletsItems[palletsItemIndex][field] = value;
            return actualRows;
         });
         setSendPalletsItemsTouched(prevTouched => {
            const actualTouched = cloneDeep(prevTouched);
            (
               actualTouched[sendPalletsItemIndex].palletsItems[palletsItemIndex] as Record<
                  string,
                  boolean
               >
            )[field] = true;
            return actualTouched;
         });
         setSendPalletsItemsErrors(prevErrors => {
            const actualErrors = cloneDeep(prevErrors);
            (
               actualErrors[sendPalletsItemIndex].palletsItems[palletsItemIndex] as Record<
                  string,
                  false | string
               >
            )[field] = validateField(field, value);
            return actualErrors;
         });
      },
      [],
   );

   const validateField = (field: string, value: Option | Date | string | null | boolean) =>
      !!sendPalletsFormValidations[field]
         ? sendPalletsFormValidations[field](value as Option & Date & string & null)
         : false;

   const sendPalletsTableCommonErrors = useMemo(() => {
      const sendPalletsTableAllErrors: string[] = [];
      sendPalletsItemsErrors.forEach((sendPalletsItemErrors, sendPalletsItemIndex) => {
         Object.entries(sendPalletsItemErrors).forEach(([key, value]) => {
            if (value) {
               if (!Array.isArray(value)) {
                  value &&
                     sendPalletsItemsTouched[sendPalletsItemIndex][key] &&
                     sendPalletsTableAllErrors.push(value as string);
               } else {
                  value.forEach((palletsItem, palletsItemIndex) => {
                     Object.entries(palletsItem).forEach(([arrayItemKey, arrayItemValue]) => {
                        arrayItemValue &&
                           (
                              sendPalletsItemsTouched[sendPalletsItemIndex].palletsItems[
                                 palletsItemIndex
                                 //eslint-disable-next-line @typescript-eslint/no-explicit-any
                              ] as Record<string, any>
                           )[arrayItemKey] &&
                           sendPalletsTableAllErrors.push(arrayItemValue as string);
                     });
                  });
               }
            }
         });
      });
      return new Set(sendPalletsTableAllErrors);
   }, [sendPalletsItemsErrors, sendPalletsItemsTouched]);

   const handleConfirmCloseWarning = () => {
      setShowCloseWarning(false);
      clearModalState();
      props.onClose();
   };

   const handleSendPalletsWithWrongAddressesConfirm = () => {
      setIsSendPalletsWithWrongAddressesWarningModalOpened(false);
      handleSubmitForm();
   };

   const closeNotFoundOrderAlertModal = () => setExcessPallets(null);

   const clearModalState = () => {
      resetSendPalletsDataForm();
      setErrorMessage(null);
      setIsSendPalletsDataFileUploaded(false);
      setIsSuccessUploadingSendPalletsDataFile(false);
      setIsSendPalletsDataFileVerified(false);
   };

   const resetSendPalletsDataForm = () => {
      setSendPalletsItems([
         DEFAULT_SEND_PALLETS_ITEM(destinationNameOptions, destinationAddressOptions),
      ]);
      setSendPalletsItemsErrors([
         DEFAULT_SEND_PALLETS_ITEMS_ERRORS(destinationNameOptions, destinationAddressOptions),
      ]);
      setSendPalletsItemsTouched([DEFAULT_SEND_PALLETS_ITEMS_TOUCHED()]);
   };

   const clearSendPalletsDataForm = () => {
      setSendPalletsItems([]);
      setSendPalletsItemsErrors([]);
      setSendPalletsItemsTouched([]);
   };

   const closeInvalidSendDamagePalletsAmountWarning = () =>
      setInvalidSendDamagePalletsAmountError(false);

   const closeSendPalletsWithWrongAddressesWarningModal = () =>
      setIsSendPalletsWithWrongAddressesWarningModalOpened(false);

   const hideAfterDelaySuccessUploadingSendPalletsDataFileBanner = () =>
      setTimeout(() => setIsSuccessUploadingSendPalletsDataFile(false), 5000);

   function filterByDestinationType(sendPalletsArray: SendPalletDTO[]) {
      return sendPalletsArray.filter(sendPallet => sendPallet.destination.type === 'NEW_ADDRESS');
   }

   const onChangeIncludesDamagedPallets = useCallback(
      (
         rowIndex: number,
         value: React.MouseEvent<Element, MouseEvent> & ChangeEvent<HTMLInputElement>,
         sendPalletsItem: SendPalletsItem,
      ) => {
         const { checked } = value.target;
         handleChangeSendPalletsItem(rowIndex, 'includesDamagedPallets', checked, sendPalletsItem);
      },
      [handleChangeSendPalletsItem],
   );

   const handleAddSendPalletsItemClick = () => {
      handleAddSendPalletsItem();
      sendPalletsTableContainerRef.current?.scrollTo({
         top: Y_ZERO_COORDINATE,
         behavior: 'smooth',
      });
   };

   const handleShowNextSendPalletsItems = () =>
      setTableInfiniteScrollStep(prevStep => {
         const newStep = ++prevStep;
         if (sendPalletsItems[prevStep * TABLE_INFINITE_SCROLL_STEP_SIZE]) {
            return newStep;
         }
         return prevStep;
      });

   const visibleSendPalletsItemsCount = useMemo(() => {
      const newVisibleSendPalletsItemsCount =
         tableInfiniteScrollStep * TABLE_INFINITE_SCROLL_STEP_SIZE;
      if (
         sendPalletsItems.length < TABLE_INFINITE_SCROLL_STEP_SIZE ||
         newVisibleSendPalletsItemsCount - sendPalletsItems.length > 0
      ) {
         return sendPalletsItems.length;
      }
      return newVisibleSendPalletsItemsCount;
   }, [sendPalletsItems.length, tableInfiniteScrollStep]);

   const isDisabledSendPalletsButton = useMemo(() => {
      if (!sendPalletsTableCommonErrors.size) {
         return false;
      }
      return !(
         sendPalletsTableCommonErrors.size === 1 &&
         sendPalletsTableCommonErrors.has(WRONG_SHIPMENT_ADDRESS_ERROR_MESSAGE)
      );
   }, [sendPalletsTableCommonErrors]);

   const isVisibleAnyErrorBanner = !!sendPalletsTableCommonErrors.size || !!errorMessage;
   const visibleSendPalletsItems = sendPalletsItems.slice(0, visibleSendPalletsItemsCount);
   const visibleSendPalletsItemsErrors = sendPalletsItemsErrors.slice(
      0,
      visibleSendPalletsItemsCount,
   );
   const visibleSendPalletsItemsTouched = sendPalletsItemsTouched.slice(
      0,
      visibleSendPalletsItemsCount,
   );
   const submitButtonText = getSubmitButtonText(
      isSendPalletsDataFileUploaded,
      isSendPalletsDataFileVerified,
      isModalEditMode,
   );

   const content = (
      <>
         {groupedLostDamagedPalletItems && (
            <>
               <p className={styles.damagedPalletsToSendWarning}>
                  Uwaga! Zgłosiłeś Uszkodzone palety. Proszę, zdefiniuj gdzie zostały one nadane.
               </p>
               <Banner variant="info" fullWidth className={styles.damagedPalletsToSendBanner}>
                  <p>Musisz rozdysponować łącznie:</p>
                  <ul>
                     {groupedLostDamagedPalletItems.damaged.map((damagedPalletsItemToSend, key) => (
                        <li key={key}>
                           {damagedPalletsItemToSend.palletType.label}&nbsp;
                           <span>{`${damagedPalletsItemToSend.palletsAmount} ${getPalletLabel(
                              Number(damagedPalletsItemToSend.palletsAmount),
                           )}.`}</span>
                        </li>
                     ))}
                  </ul>
               </Banner>
            </>
         )}
         {isSuccessUploadingSendPalletsDataFile && (
            <Banner
               variant="success"
               children="Plik został wgrany."
               onClose={() => setIsSuccessUploadingSendPalletsDataFile(false)}
               className={styles.banner}
               fullWidth
               withCloseIcon
            />
         )}
         {isPalletsShipmentAddressesDataValid && (
            <Banner
               variant="success"
               children="Adresy są poprawne."
               onClose={() => setIsPalletsShipmentAddressesDataValid(false)}
               className={styles.banner}
               fullWidth
               withCloseIcon
            />
         )}
         {!isTablet ? (
            <>
               <CustomInfiniteScroll
                  containerId="sendPalletsTableInfiniteScrollContainer"
                  containerRef={sendPalletsTableContainerRef}
                  next={handleShowNextSendPalletsItems}
                  dataLength={visibleSendPalletsItemsCount}
                  hasMore={visibleSendPalletsItemsCount !== sendPalletsItems.length}
                  infiniteScrollClassNames={{
                     outerContainer: styles.scrollableOuterContainer,
                     innerContainer: styles.scrollableInnerContainer,
                  }}
               >
                  <SendPalletsTable
                     sendPalletsItems={visibleSendPalletsItems}
                     sendPalletsItemsErrors={visibleSendPalletsItemsErrors}
                     sendPalletsItemsTouched={visibleSendPalletsItemsTouched}
                     destinationNameOptions={destinationNameOptions}
                     destinationAddressOptions={destinationAddressOptions}
                     availablePalletTypes={availablePalletTypes}
                     onAddPalletsItem={handleAddPalletsItem}
                     onRemoveSendPalletsItem={handleRemoveSendPalletsItem}
                     onRemovePalletsItem={handleRemovePalletsItem}
                     onChangeSendPalletsItem={handleChangeSendPalletsItem}
                     onChangePalletsItem={handleChangePalletsItem}
                     onAddCommercialNetwork={onAddCommercialNetwork}
                     onAddCommercialNetworkAddress={onAddCommercialNetworkAddress}
                     sendDamagedPalletsMode={!!groupedLostDamagedPalletItems}
                     className={styles.sendPalletsTable}
                     isModalEditMode={isModalEditMode}
                     onChangeIncludesDamagedPallets={onChangeIncludesDamagedPallets}
                  />
               </CustomInfiniteScroll>

               <StyledButton
                  type="button"
                  onClick={handleAddSendPalletsItemClick}
                  text="Dodaj kolejne nadanie"
                  variant="outlined-primary"
                  className={styles.addSendPalletsItemBtn}
               />

               {!groupedLostDamagedPalletItems && (
                  <PalletsToSendFileDropzone
                     isVGLCompany={clientCompanyName === VGL_COMPANY_NAME}
                     onStartUpload={handleStartUploadSendPalletsDataFromFile}
                     onFinishUpload={handleFinishUploadSendPalletsDataFromFile}
                     onSuccessUpload={handleSuccessUploadSendPalletsDataFromFile}
                     onErrorUpload={handleFailUploadSendPalletsDataFromFile}
                  />
               )}

               {isVisibleAnyErrorBanner && (
                  <div className={styles.bannersContainer}>
                     {errorMessage && (
                        <Banner
                           variant="error"
                           children={errorMessage}
                           fullWidth
                           className={styles.errorBanner}
                        />
                     )}
                     {Array.from(sendPalletsTableCommonErrors).map(commonError => (
                        <Banner
                           key={commonError}
                           variant="error"
                           children={commonError}
                           fullWidth
                           className={styles.errorBanner}
                        />
                     ))}
                  </div>
               )}
            </>
         ) : (
            <div
               className={classNames(styles.mobileScrollableContainer, {
                  [styles.shorter]: !!groupedLostDamagedPalletItems,
               })}
            >
               {!groupedLostDamagedPalletItems && (
                  <Banner
                     variant="info"
                     children="Możesz wgrać plik excell tylko z przeglądarki na komputerze."
                     fullWidth
                     className={styles.infoBanner}
                  />
               )}
               <SendPalletsMobileList
                  sendPalletsItems={sendPalletsItems}
                  sendPalletsItemsErrors={sendPalletsItemsErrors}
                  sendPalletsItemsTouched={sendPalletsItemsTouched}
                  destinationNameOptions={destinationNameOptions}
                  destinationAddressOptions={destinationAddressOptions}
                  availablePalletTypes={availablePalletTypes}
                  onAddSendPalletsItem={handleAddSendPalletsItem}
                  onAddPalletsItem={handleAddPalletsItem}
                  onRemoveSendPalletsItem={handleRemoveSendPalletsItem}
                  onRemovePalletsItem={handleRemovePalletsItem}
                  onChangeSendPalletsItem={handleChangeSendPalletsItem}
                  onChangePalletsItem={handleChangePalletsItem}
                  onAddCommercialNetwork={onAddCommercialNetwork}
                  onAddCommercialNetworkAddress={onAddCommercialNetworkAddress}
                  sendDamagedPalletsMode={!!groupedLostDamagedPalletItems}
                  isModalEditMode={isModalEditMode}
                  onChangeIncludesDamagedPallets={onChangeIncludesDamagedPallets}
               />
               {isVisibleAnyErrorBanner && (
                  <div className={styles.bannersContainer}>
                     {errorMessage && (
                        <Banner
                           variant="error"
                           children={errorMessage}
                           fullWidth
                           className={styles.errorBanner}
                        />
                     )}
                     {Array.from(sendPalletsTableCommonErrors).map(commonError => (
                        <Banner
                           key={commonError}
                           variant="error"
                           children={commonError}
                           fullWidth
                           className={styles.errorBanner}
                        />
                     ))}
                  </div>
               )}
            </div>
         )}

         <div className={styles.btnContainer}>
            <StyledButton
               text={submitButtonText}
               type="button"
               onClick={
                  isSendPalletsDataFileUploaded && !isSendPalletsDataFileVerified
                     ? handleVerifyPalletsShipmentAddressesButtonClick
                     : handleSubmitButtonClick
               }
               variant="filled-primary"
               disabled={isDisabledSendPalletsButton}
            />
         </div>
      </>
   );

   return (
      <>
         <Modal
            {...props}
            title={
               (groupedLostDamagedPalletItems && 'Nadaj uszkodzone palety') ||
               (isModalEditMode && 'Edytuj nadania') ||
               'Nadaj palety'
            }
            size={1500}
            onClose={() => setShowCloseWarning(true)}
            centered
            className={styles.modalContent}
            classNames={{
               header: groupedLostDamagedPalletItems
                  ? styles.modalHeaderWithSmallMargin
                  : undefined,
               body: styles.modalBody,
            }}
         >
            <>
               <CloseWarningModal
                  opened={showCloseWarning}
                  onClose={() => setShowCloseWarning(false)}
                  onConfirm={handleConfirmCloseWarning}
               />
               <SendPalletsWithWrongAddressesWarningModal
                  opened={isSendPalletsWithWrongAddressesWarningModalOpened}
                  onConfirm={handleSendPalletsWithWrongAddressesConfirm}
                  onCancel={closeSendPalletsWithWrongAddressesWarningModal}
               />
               <NotFoundOrderAlertModal
                  opened={!!excessPallets}
                  onClose={closeNotFoundOrderAlertModal}
                  modalType="SEND_PALLETS"
                  oneOrderAction={!!orderId}
                  excessPallets={excessPallets}
                  returnBtnText="Wróć do nadawania palet"
                  onReturn={closeNotFoundOrderAlertModal}
               />
               <CustomModal
                  opened={invalidSendDamagePalletsAmountError}
                  onClose={closeInvalidSendDamagePalletsAmountWarning}
                  icon="ALERT_TRIANGLE"
                  innerTitle="Uwaga!"
                  modalContentClassName={styles.invalidSendDamagePalletsAmountModalContent}
                  secondaryButtonProps={{
                     text: 'Wróć',
                     variant: 'outlined-primary',
                     onClick: closeInvalidSendDamagePalletsAmountWarning,
                     className: styles.invalidSendDamagePalletsAmountModalButton,
                  }}
                  centeredButtons
               >
                  <p>Nie możesz nadać innej liczby palet niż zgłosiłeś jako uszkodzone.</p>
               </CustomModal>
               <FileUploadingModal
                  opened={isUploadingSendPalletsDataFile}
                  onClose={() => setIsUploadingSendPalletsDataFile(false)}
               />
               {loading || isSendPalletsDataFileVerifying ? (
                  <div className={styles.loader}>
                     <Loader color={'var(--light-green)'} />
                  </div>
               ) : (
                  content
               )}
            </>
         </Modal>
      </>
   );
};

export default SendPalletsModal;
