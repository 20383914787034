import classNames from 'classnames';

import { StyledBadge } from 'components/shared';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { BALANCE_TYPE_LABELS } from 'constants/transferActionOverview/balanceTypeLabels';
import { BalanceType, PalletType, TransferActionType } from 'interfaces';

import styles from './PalletsTransferHistoryActionBodyItem.module.css';

export type PalletsTransferHistoryActionBodyItemProps = {
   actionType: TransferActionType;
   address: string;
   amount: number;
   palletType: PalletType;
   balanceAfter: number;
   balanceType: BalanceType;
   user: string;
   effectiveDate: string;
};

const AMOUNT_SYMBOLS_NUMBER = 5;
const AMOUNT_DEFAULT_SYMBOL = '0';
const MINUS__SIGN = '-';

const PalletsTransferHistoryActionBodyItem = ({
   actionType,
   address,
   amount,
   palletType,
   balanceAfter,
   balanceType,
   user,
   effectiveDate,
}: PalletsTransferHistoryActionBodyItemProps) => {
   const isAmountPositive = amount > 0;
   const isBalanceAfterPositive = balanceAfter > 0;
   const formattedAmount = Math.abs(amount)
      .toString()
      .padStart(AMOUNT_SYMBOLS_NUMBER, AMOUNT_DEFAULT_SYMBOL);
   const amountSign = isAmountPositive ? '' : MINUS__SIGN;
   const fullAmount = amountSign + formattedAmount;
   const balanceTypeLabel = BALANCE_TYPE_LABELS[balanceType];

   let actionVerbLabel;

   if (actionType === 'EDIT_BALANCE') {
      actionVerbLabel = isAmountPositive ? 'dodano do' : 'odjęto od';
   } else {
      actionVerbLabel = isAmountPositive ? 'przyjęto do' : 'wysłano z';
   }

   return (
      <li className={styles.listItem}>
         <div className={styles.leftPartContainer}>
            <span className={styles.greyText}>{effectiveDate}</span>
            <StyledBadge
               variant={isAmountPositive ? 'POSITIVE' : 'NEGATIVE'}
               text={fullAmount}
               className={styles.amount}
            />
            <span className={classNames(styles.palletsType, styles.boldText)}>
               {PALLET_TYPE_LABELS[palletType]}
            </span>
            <span className={styles.actionVerb}>{actionVerbLabel}</span>
            <div className={styles.multipleRowsContainer}>
               <span className={styles.boldText}>{user}</span>
               {address && <span className={styles.greyText}>{address}</span>}
               <span className={styles.greyText}>Typ salda: {balanceTypeLabel}</span>
            </div>
         </div>
         <div className={classNames(styles.balanceAfter, styles.greyText)}>
            saldo:
            <span
               className={
                  isBalanceAfterPositive ? styles.positiveBalanceAfter : styles.negativeBalanceAfter
               }
            >
               {balanceAfter}
            </span>
         </div>
      </li>
   );
};

export default PalletsTransferHistoryActionBodyItem;
