import { sum } from 'lodash';

import {
   OrderDetailsPallet,
   OrderSummaryAndOrderDetailsPallet,
   SupplierOrderSummaryPallet,
} from 'interfaces/commonInterfacesAndTypes';
import { isOrderDetailsPallet } from 'utils/typeGuards';

export const countPallets = (pallet: OrderSummaryAndOrderDetailsPallet) => {
   if (!isOrderDetailsPallet(pallet)) {
      if (pallet.orderType === 'CUSTOM') {
         return parseInt(pallet.amount);
      } else {
         const value = parseInt(pallet.amount);
         return value * pallet.logisticMinimum;
      }
   } else {
      return pallet.amount;
   }
};

export const countPalletsOrderTotalPrice = (
   pallets: SupplierOrderSummaryPallet[] | OrderDetailsPallet[],
) => {
   if (pallets.length === 0) {
      return 0;
   } else {
      let totalPrice = 0;
      if (!isOrderDetailsPallet(pallets[0])) {
         totalPrice = sum(
            (pallets as SupplierOrderSummaryPallet[]).map(pallet =>
               pallet.orderType === 'CUSTOM'
                  ? parseInt(pallet.amount) * (pallet.pricePerUnit || 0)
                  : parseInt(pallet.amount) * pallet.logisticMinimum * (pallet.pricePerUnit || 0),
            ),
         );
      } else {
         totalPrice = sum(
            (pallets as OrderDetailsPallet[]).map(pallet => pallet.amount * pallet.pricePerUnit),
         );
      }
      return totalPrice;
   }
};

export const countPalletsOrderSum = (
   pallets: {
      orderType: 'LOGISTIC_MINIMUM' | 'CUSTOM' | null;
      amount: string;
      logisticMinimum: number;
   }[],
) =>
   sum(
      pallets.map(pallet =>
         pallet.orderType === 'CUSTOM'
            ? parseInt(pallet.amount)
            : parseInt(pallet.amount) * pallet.logisticMinimum,
      ),
   );
