import { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { Plus } from 'tabler-icons-react';

import { CustomModal, StyledButton } from 'components/shared';
import {
   DAMAGED_LOST_CHARGE_ERRORS,
   DEFAULT_ADDITIONAL_CHARGE,
   DEFAULT_ADDITIONAL_CHARGE_ERRORS,
   DEFAULT_ADDITIONAL_CHARGE_TOUCHED,
   OTHER_TYPE_CHARGE_ERRORS,
} from 'constants/additionaChargesFormDefaultValues';
import {
   ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED,
   ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED,
} from 'constants/errorCodes';
import { ADD_ADDITIONAL_CHARGES_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import {
   AdditionalCharge,
   AdditionalChargeErrors,
   AdditionalChargeTouched,
   AdditionalChargeType,
   Option,
   OrderDTO,
} from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { parseNumber } from 'utils/functions';
import { logNetworkError } from 'utils/logNetworkError';
import {
   DamagedLostChargeValidation,
   OtherTypeChargeValidation,
} from 'utils/validation/addAdditionalChargesFormValidation';

import AdditionalChargesList from './AdditionalChargesList/AdditionalChargesList';
import styles from './AddAdditionalChargesModal.module.css';

type AddAdditionalChargesModalProps = {
   orderId: string;
   onSuccessAddAdditionalCharges: (updatedOrder: OrderDTO) => void;
   palletTypes: Option[];
   palletSendingDestinationAddresses: {
      [palletType: string]: Option[];
   } | null;
   opened: boolean;
   onClose: () => void;
};

const AddAdditionalChargesModal = ({
   orderId,
   onSuccessAddAdditionalCharges,
   palletTypes,
   palletSendingDestinationAddresses,
   opened,
   onClose,
}: AddAdditionalChargesModalProps) => {
   const [additionalCharges, setAdditionalCharges] = useState([DEFAULT_ADDITIONAL_CHARGE()]);
   const [additionalChargesErrors, setAdditionalChargesErrors] = useState([
      DEFAULT_ADDITIONAL_CHARGE_ERRORS(),
   ]);
   const [additionalChargesTouched, setAdditionalChargesTouched] = useState([
      DEFAULT_ADDITIONAL_CHARGE_TOUCHED(),
   ]);

   const handleCloseModal = useCallback(() => {
      setAdditionalCharges([DEFAULT_ADDITIONAL_CHARGE()]);
      setAdditionalChargesErrors([DEFAULT_ADDITIONAL_CHARGE_ERRORS()]);
      setAdditionalChargesTouched([DEFAULT_ADDITIONAL_CHARGE_TOUCHED()]);
      onClose();
   }, [onClose]);

   const {
      isLoading,
      errorInfo,
      showCloseWarning,
      setIsLoading,
      setErrorInfo,
      handleShowCloseWarning,
      handleHideCloseWarning,
      closeModalHandler,
   } = useModal({ onClose: handleCloseModal });

   const handleAddAdditionalCharges = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formValid = validateForm();
      if (formValid) {
         setIsLoading(true);
         const transformedAdditionalCharges = additionalCharges.map(additionalCharge => ({
            chargeType: additionalCharge.chargeType!,
            palletType: additionalCharge.palletType?.value,
            palletsAmount: additionalCharge.palletsAmount
               ? Number(additionalCharge.palletsAmount)
               : undefined,
            address: additionalCharge.address
               ? {
                    name: additionalCharge.address.value.name,
                    streetWithCity: additionalCharge.address.value.streetWithCity,
                 }
               : undefined,
            otherChargeName: additionalCharge.otherChargeName,
            amount: additionalCharge.amount ? parseNumber(additionalCharge.amount) : undefined,
         }));
         HTTPService.addAdditionalCharges(orderId, transformedAdditionalCharges)
            .then(response => {
               onSuccessAddAdditionalCharges(response.data);
               closeModalHandler();
            })
            .catch(error => {
               if (
                  error instanceof AxiosError &&
                  (error.response?.data.code === ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED ||
                     error.response?.data.code ===
                        ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED)
               ) {
                  setErrorInfo(ADD_ADDITIONAL_CHARGES_ERROR_DESCRIPTIONS[error.response.data.code]);
               } else {
                  setErrorInfo(ADD_ADDITIONAL_CHARGES_ERROR_DESCRIPTIONS.OTHER);
               }
               logNetworkError(error);
            })
            .finally(() => setIsLoading(false));
      }
   };

   const handleAddAdditionalCharge = useCallback(() => {
      setAdditionalCharges(prevValues => [...prevValues, DEFAULT_ADDITIONAL_CHARGE()]);
      setAdditionalChargesTouched(prevTouched => [
         ...prevTouched,
         DEFAULT_ADDITIONAL_CHARGE_TOUCHED(),
      ]);
      setAdditionalChargesErrors(prevErrors => [...prevErrors, DEFAULT_ADDITIONAL_CHARGE_ERRORS()]);
   }, []);

   const handleRemoveAdditionalCharge = useCallback(
      (uuid: string) => {
         const index = additionalCharges.findIndex(value => value.uuid === uuid);
         if (index >= 0) {
            setAdditionalCharges(prevValues => removeFormItem(prevValues, index));
            setAdditionalChargesTouched(prevTouched => removeFormItem(prevTouched, index));
            setAdditionalChargesErrors(prevErrors => removeFormItem(prevErrors, index));
         }
      },
      [additionalCharges],
   );

   const removeFormItem = <T,>(prevItems: T[], index: number) => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
   };

   const handleChangeAdditionalCharge = useCallback(
      (uuid: string, chargeType: AdditionalChargeType, field: string, value: string | Option) => {
         const index = additionalCharges.findIndex(
            additionalCharge => additionalCharge.uuid === uuid,
         );
         if (index >= 0) {
            setAdditionalCharges(prevValues =>
               changeFormItem(prevValues, index, (item: AdditionalCharge) => (item[field] = value)),
            );
            setAdditionalChargesTouched(prevTouched =>
               changeFormItem(
                  prevTouched,
                  index,
                  (item: AdditionalChargeTouched) => (item[field] = true),
               ),
            );
            setAdditionalChargesErrors(prevErros =>
               changeFormItem(
                  prevErros,
                  index,
                  (item: AdditionalChargeErrors) =>
                     (item[field] = validateField(chargeType, field, value)),
               ),
            );
         }
      },
      [additionalCharges],
   );

   const changeFormItem = <T,>(prevItems: T[], index: number, changeHelper: (item: T) => void) => {
      const newItems = [...prevItems];
      const updatedItem = { ...newItems[index] };
      changeHelper(updatedItem);
      newItems[index] = updatedItem;
      return newItems;
   };

   const handleChangeAdditionalChargeType = useCallback(
      (uuid: string, type: AdditionalChargeType) => {
         const index = additionalCharges.findIndex(
            additionalCharge => additionalCharge.uuid === uuid,
         );
         if (index >= 0) {
            setAdditionalCharges(prevValues => {
               const createItem = () => {
                  const updatedAdditionalCharge = DEFAULT_ADDITIONAL_CHARGE();
                  updatedAdditionalCharge.chargeType = type;
                  return updatedAdditionalCharge;
               };
               return changeChargeType(prevValues, index, createItem);
            });
            setAdditionalChargesTouched(prevTouched => {
               const createTouchedItem = () => {
                  const updatedTouchedItem = DEFAULT_ADDITIONAL_CHARGE_TOUCHED();
                  updatedTouchedItem.chargeType = true;
                  return updatedTouchedItem;
               };
               return changeChargeType(prevTouched, index, createTouchedItem);
            });
            setAdditionalChargesErrors(prevErros => {
               const createErrorItem = () =>
                  type === 'DAMAGE' || type === 'LOST_PALLET'
                     ? DAMAGED_LOST_CHARGE_ERRORS()
                     : OTHER_TYPE_CHARGE_ERRORS();
               return changeChargeType(prevErros, index, createErrorItem);
            });
         }
      },
      [additionalCharges],
   );

   const changeChargeType = <T,>(prevItems: T[], index: number, createItem: () => T) => {
      const newItems = [...prevItems];
      const newItem = createItem();
      newItems[index] = newItem;
      return newItems;
   };

   const validateForm = () => {
      const newTouched = additionalChargesTouched.map((touchedItem, touchedItemIndex) => {
         const newTouchedItem = { ...touchedItem };
         if (additionalCharges[touchedItemIndex].chargeType) {
            Object.keys(newTouchedItem).forEach(key => (newTouchedItem[key] = true));
         } else {
            newTouchedItem.chargeType = true;
         }
         return newTouchedItem;
      });
      setAdditionalChargesTouched(newTouched);
      const formValid = !additionalChargesErrors.some(errorsItem =>
         Object.values(errorsItem).some(Boolean),
      );
      return formValid;
   };

   const validateField = (
      chargeType: AdditionalChargeType,
      field: string,
      value: string | Option,
   ) => {
      if (chargeType === 'DAMAGE' || chargeType === 'LOST_PALLET') {
         return DamagedLostChargeValidation[field](value as Option & string) || false;
      } else {
         return (typeof value === 'string' && OtherTypeChargeValidation[field](value)) || false;
      }
   };

   const additionalChargesCommonErrors = useMemo(() => {
      const additionalChargesAllErrors: string[] = [];
      additionalChargesErrors.forEach((errorsItem, errorsItemIndex) => {
         Object.entries(errorsItem).forEach(
            ([field, error]) =>
               error &&
               additionalChargesTouched[errorsItemIndex][field] &&
               additionalChargesAllErrors.push(error),
         );
      });
      return new Set(additionalChargesAllErrors);
   }, [additionalChargesErrors, additionalChargesTouched]);

   return (
      <CustomModal
         opened={opened}
         onClose={handleShowCloseWarning}
         isLoading={isLoading}
         title="Dodaj obciążenie"
         primaryButtonProps={{
            text: 'Dodaj obciążenie do konta klienta',
            variant: 'filled-primary',
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: handleShowCloseWarning,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         leftSideModalContent
         modalContentClassName={styles.modalContent}
         onSubmit={handleAddAdditionalCharges}
      >
         <div className={styles.formContentContainer}>
            <AdditionalChargesList
               additionalCharges={additionalCharges}
               additionalChargesErrors={additionalChargesErrors}
               additionalChargesTouched={additionalChargesTouched}
               onRemoveAdditionalCharge={handleRemoveAdditionalCharge}
               onChangeAdditionalCharge={handleChangeAdditionalCharge}
               onChangeAdditionalChargeType={handleChangeAdditionalChargeType}
               palletTypes={palletTypes}
               palletSendingDestinationAddresses={palletSendingDestinationAddresses}
               className={styles.additionalChargesList}
            />
            {(!!additionalChargesCommonErrors.size || errorInfo) && (
               <div className={styles.bannersContainer}>
                  {errorInfo && (
                     <Banner variant="error" children={errorInfo} className={styles.errorBanner} />
                  )}
                  {Array.from(additionalChargesCommonErrors).map(commonError => (
                     <Banner
                        key={commonError}
                        variant="error"
                        children={commonError}
                        className={styles.errorBanner}
                     />
                  ))}
               </div>
            )}
            <StyledButton
               type="button"
               text="Dodaj kolejny rodzaj obciążenia"
               onClick={handleAddAdditionalCharge}
               icon={<Plus />}
               variant="text"
               style={{ padding: 0 }}
               className={styles.addAdditionalChargeBtn}
            />
         </div>
      </CustomModal>
   );
};

export default AddAdditionalChargesModal;
