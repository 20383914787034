import classNames from 'classnames';

import { Banner } from 'storybook';
import { getOrderLabel, getOrdersIdsLabel } from 'utils/functions';

import styles from './FreeRelatedOrdersBanner.module.css';

type FreeRelatedOrdersBannerProps = {
   freeRelatedOrderIds: string[];
   className?: string;
   isClientView?: boolean;
};

const FreeRelatedOrdersBanner = ({
   freeRelatedOrderIds,
   className,
   isClientView,
}: FreeRelatedOrdersBannerProps) => {
   const orderLabel = getOrderLabel(freeRelatedOrderIds.length);
   const ordersIdsLabel = getOrdersIdsLabel(freeRelatedOrderIds);
   const freeRelatedOrdersText = `Do tego zamówienia przypisano dodatkowe palety, które są bezpłatne i pochodzą z ${orderLabel} ${ordersIdsLabel}.`;
   const freePalletsColorText = isClientView
      ? 'Dodatkowe palety zaznaczone są na zielono.'
      : 'Dodatkowe palety zaznaczone są na czerwono.';

   const bannerText = (
      <>
         <p>
            {freeRelatedOrdersText}{' '}
            {!isClientView && 'Faktura zostanie wygenerowana tylko na palety płatne.'}
         </p>
         <p>{freePalletsColorText}</p>
      </>
   );

   return (
      <Banner
         fullWidth
         variant="warning"
         className={classNames(styles.banner, className)}
         children={bannerText}
      />
   );
};

export default FreeRelatedOrdersBanner;
