import { ReactNode, useRef } from 'react';
import Dropzone, { Accept, DropEvent, DropzoneRef, FileRejection } from 'react-dropzone';

import classNames from 'classnames';

import DropzoneInput, { DropzoneInputAdditionalProps } from './DropzoneInput/DropzoneInput';
import styles from './CustomDropzone.module.css';

type CustomDropzoneProps = {
   accept?: Accept;
   maxFiles?: number;
   multiple?: boolean;
   noClick?: boolean;
   className?: string;
   inputProps?: DropzoneInputAdditionalProps;
   children: ReactNode;
   onDrop?: <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent,
   ) => void;
};

const CustomDropzone = ({
   accept,
   maxFiles,
   multiple,
   noClick,
   className,
   onDrop,
   inputProps,
   children,
}: CustomDropzoneProps) => {
   const dropzoneRef = useRef<DropzoneRef | null>(null);

   const openDialog = () => {
      if (dropzoneRef.current) {
         dropzoneRef.current.open();
      }
   };

   return (
      <Dropzone
         ref={dropzoneRef}
         accept={accept}
         maxFiles={maxFiles}
         multiple={multiple}
         noClick={noClick}
         onDrop={onDrop}
      >
         {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classNames(styles.container, className)}>
               <div className={styles.content}>
                  {inputProps && (
                     <DropzoneInput
                        {...getInputProps({ refKey: 'innerRef' })}
                        text={inputProps.text}
                        icon={inputProps.icon}
                        className={inputProps.className}
                        onClick={openDialog}
                     />
                  )}
                  {children}
               </div>
            </div>
         )}
      </Dropzone>
   );
};

export default CustomDropzone;
