import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'constants/dateFormats';

export const isOrderWithLeftPalletsToSendInActualMonth = (
   retentionDate: string,
   areLastThreeDaysInMonth: boolean,
   isBeforeToPayStatus: boolean,
) => {
   const orderWithRetentionDateInCurrentMonth =
      DateTime.fromFormat(retentionDate, DATE_FORMAT).month === DateTime.now().month;
   return areLastThreeDaysInMonth && isBeforeToPayStatus && orderWithRetentionDateInCurrentMonth;
};
