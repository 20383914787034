import { FormEvent, useState } from 'react';

import { LoadingOverlay, Modal } from '@mantine/core';
import { cloneDeep } from 'lodash';

import { externalStorageTableHeaders, internalStorageTableHeaders } from 'constants/tableHeaders';
import { Balance, StoragePalletsStates, StorageWithDate } from 'interfaces';
import { Banner } from 'storybook';
import { getFullAddress } from 'utils/functions';

import { StyledButton, StyledModalTitle, StyledTable } from '../shared';
import StorageTableItem from '../StorageTableItem/StorageTableItem';

import styles from './EditStorageBalanceModal.module.css';

type EditStorageBalanceModalProps = {
   storage: StorageWithDate;
   showErrorBanner: boolean;
   loading: boolean;
   onClose: () => void;
   onSubmit: (event: FormEvent<HTMLFormElement>, balance: Balance, date: Date, id: string) => void;
};

const EditStorageBalanceModal = ({
   storage,
   loading,
   showErrorBanner,
   onClose,
   onSubmit,
}: EditStorageBalanceModalProps) => {
   const [balance, setBalance] = useState<Balance>(cloneDeep(storage.balanceForToday) as Balance);

   const handleBalanceChange = (name: string, type: StoragePalletsStates, value: string) => {
      const cpBalance = cloneDeep(balance);
      cpBalance[name][type] = Number(value);
      setBalance(cpBalance);
   };

   const internal = storage.type === 'INTERNAL';

   return (
      <Modal
         size={1000}
         title={
            <StyledModalTitle
               title="Edytuj saldo"
               subtitle={`Edytujesz saldo magazynu ${
                  !internal ? 'zewnętrznego' : 'wewnętrznego'
               } „${getFullAddress(
                  storage.address.street,
                  storage.address.zipCode,
                  storage.address.city,
               )}”.`}
            />
         }
         opened
         onClose={onClose}
      >
         {showErrorBanner && (
            <Banner variant="error" fullWidth style={{ marginBottom: 48 }}>
               Wystąpił błąd. Odśwież stronę i spróbuj ponownie.
            </Banner>
         )}
         <form onSubmit={event => onSubmit(event, balance, storage.date, storage.id)}>
            <div className={styles.modalContent}>
               <StyledTable
                  columnHeaders={
                     internal ? internalStorageTableHeaders : externalStorageTableHeaders
                  }
               >
                  {balance &&
                     Object.entries(balance).map(([name, values]) => (
                        <StorageTableItem
                           handleValueChange={handleBalanceChange}
                           key={name + storage.id + 'edit'}
                           name={name}
                           readyToRent={values.readyToRent}
                           damaged={values.damaged}
                           lost={values.lost}
                           internal={internal}
                        />
                     ))}
               </StyledTable>
               <LoadingOverlay
                  visible={loading}
                  overlayBlur={10}
                  loaderProps={{ color: 'var(--light-green)' }}
               />
            </div>
            <div className={styles.actions}>
               <StyledButton variant="text" text="Anuluj" onClick={onClose} />
               <StyledButton
                  variant="filled-primary"
                  text="Edytuj saldo magazynu"
                  disabled={loading}
               />
            </div>
         </form>
      </Modal>
   );
};

export default EditStorageBalanceModal;
