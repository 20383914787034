import { TimeInput, TimeInputProps } from '@mantine/dates';
import classNames from 'classnames';

import styles from './CustomTimeInput.module.css';

type CustomTimeInputProps = Omit<TimeInputProps, 'error'> & {
   error?: boolean;
   helperText?: string;
};

const CustomTimeInput = ({ error, helperText, ...props }: CustomTimeInputProps) => (
   <div className={styles.container}>
      <TimeInput
         {...props}
         classNames={{
            input: classNames(styles.input, {
               [styles.error]: error,
            }),
            label: styles.label,
         }}
      />
      {helperText && (
         <span className={classNames(styles.helperText, { [styles.error]: error })}>
            {helperText}
         </span>
      )}
   </div>
);
export default CustomTimeInput;
