export const INVALID_EMAIL = 'auth/invalid-email';
export const WRONG_PASSWORD = 'auth/wrong-password';
export const USER_NOT_FOUND = 'auth/user-not-found';
export const TOO_MANY_REQUESTS = 'auth/too-many-requests';
export const EXPIRED_ACTION_CODE = 'auth/expired-action-code';
export const INVALID_ACTION_CODE = 'auth/invalid-action-code';
export const INVALID_IMAGE_FILE = 'INVALID_FILE';
export const INVALID_SHIPMENT_PALLET_AMOUNT = 'INVALID_SHIPMENT_PALLET_AMOUNT';
export const ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED = 'ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED';
export const ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED =
   'ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED';
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const DISTRIBUTION_CENTER_ALREADY_EXISTS = 'DISTRIBUTION_CENTER_ALREADY_EXISTS';
export const GUS_API_COMPANY_NOT_FOUND = 'GUS_API_COMPANY_NOT_FOUND';
export const ORDER_PALLET_TYPE_NOT_FOUND = 'ORDER_PALLET_TYPE_NOT_FOUND';
export const INVALID_USER_PALLET_TYPES = 'INVALID_USER_PALLET_TYPES';
export const TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT = 'TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT';

export const excessPalletsErrors = ['ORDER_PALLET_NOT_FOUND', 'PALLET_REPORT_AMOUNT_EXCEEDED'];
