import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './ButtonGroup.module.css';

export interface ButtonGroupProps {
   children: ReactNode;
   label?: string;
   containerClassName?: string;
   groupContentClassName?: string;
}

export const ButtonGroup = ({
   children,
   label,
   containerClassName,
   groupContentClassName,
}: ButtonGroupProps) => (
   <div className={containerClassName}>
      {label && <p className={styles.btnGroupLabel}>{label}</p>}
      <div className={classNames(styles.btnGroup, groupContentClassName)}>{children}</div>
   </div>
);
