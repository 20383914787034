import CustomModal, { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { useModal } from 'hooks/useModal';

import CombinedFiltersRow from './CombinedFiltersRow/CombinedFiltersRow';
import FiltersGroupContainer from './FiltersGroupContainer/FiltersGroupContainer';
import styles from './FiltersModal.module.css';

export type FiltersModalProps = Pick<
   CustomModalProps,
   'opened' | 'isLoading' | 'children' | 'error' | 'onSubmit' | 'onClose'
> & {
   isCloseWarningEnabled?: boolean;
};

const MODAL_WIDTH = 530;

const FiltersModal = ({
   opened,
   isLoading,
   isCloseWarningEnabled,
   children,
   error,
   onSubmit,
   onClose,
}: FiltersModalProps) => {
   const { showCloseWarning, handleShowCloseWarning, closeModalHandler } = useModal({ onClose });

   const handleClose = isCloseWarningEnabled ? handleShowCloseWarning : onClose;

   return (
      <CustomModal
         opened={opened}
         size={MODAL_WIDTH}
         title="Filtry"
         secondaryButtonProps={{
            text: 'Wróć',
            variant: 'outlined-primary',
            onClick: handleClose,
         }}
         primaryButtonProps={{
            text: 'Zastosuj',
            disabled: !!error,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleClose,
            onConfirm: closeModalHandler,
         }}
         isLoading={isLoading}
         error={error}
         classNames={{ header: styles.modalHeader }}
         onSubmit={onSubmit}
         onClose={handleClose}
      >
         {children}
      </CustomModal>
   );
};

FiltersModal.GroupContainer = FiltersGroupContainer;
FiltersModal.CombinedRow = CombinedFiltersRow;

export default FiltersModal;
