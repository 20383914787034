import {
   ChangeEvent,
   forwardRef,
   useCallback,
   useEffect,
   useImperativeHandle,
   useState,
} from 'react';

import { cloneDeep, debounce } from 'lodash';

import StoragesList from 'components/StoragesList/StoragesList';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { Balance, BannerVariants, StorageWithDate } from 'interfaces';
import { HTTPService } from 'service';
import { getFormattedDate, parseStorageDTOIntoStorageWithDate } from 'utils/functions';

type InternalStoragePageProps = {
   showBanner: (variant: BannerVariants, description: string) => void;
   openAddStorage: () => void;
   onFetchDataError: (error: unknown) => void;
};

const debounceSearchInput = debounce(async (search: string, setStorage) => {
   const { data } = await HTTPService.getStorageList({
      page: 0,
      size: PAGINATION_MAX_SIZE,
      storageType: 'INTERNAL',
      addressSearch: search,
      sort: ['address.city,ASC', 'address.street,ASC'],
   });

   setStorage(data.content.map(content => ({ ...content, date: new Date() })));
}, 1000);

const InternalStoragePage = forwardRef(
   ({ showBanner, openAddStorage, onFetchDataError }: InternalStoragePageProps, ref) => {
      const [internalStorages, setInternalStorages] = useState<StorageWithDate[]>([]);
      const [loading, setLoading] = useState(true);
      const [search, setSearch] = useState('');
      const [reload, setReload] = useState<boolean>(false);

      useImperativeHandle(ref, () => ({
         reloadChild: handleForceReload,
      }));

      useEffect(() => {
         HTTPService.getStorageList({
            page: 0,
            size: PAGINATION_MAX_SIZE,
            storageType: 'INTERNAL',
            sort: ['address.city,ASC', 'address.street,ASC'],
         })
            .then(({ data }) =>
               setInternalStorages(data.content.map(parseStorageDTOIntoStorageWithDate)),
            )
            .catch(onFetchDataError)
            .finally(() => setLoading(false));
         return () => setSearch('');
      }, [onFetchDataError, reload]);

      const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
         setSearch(event.target.value);
         debounceSearchInput(event.target.value, setInternalStorages);
      };

      const handleForceReload = () => setReload(!reload);

      const replaceStorage = useCallback(
         (storageId: string, balance: Balance, date: Date) => {
            const cpInternalStorages = cloneDeep(internalStorages);
            const storageIndex = cpInternalStorages.findIndex(
               internalStorage => internalStorage.id === storageId,
            );
            cpInternalStorages[storageIndex].balanceForToday = balance;
            cpInternalStorages[storageIndex].date = date;
            setInternalStorages(cpInternalStorages);
         },
         [internalStorages],
      );

      const handleDateChange = useCallback(
         (date: Date | null, id: string, onFinish: () => void) => {
            if (date) {
               HTTPService.getStorageBalanceByDate(id, getFormattedDate(date))
                  .then(({ data }) => replaceStorage(id, data, date))
                  .catch(error => onFetchDataError(error))
                  .finally(onFinish);
            }
         },
         [replaceStorage, onFetchDataError],
      );

      return (
         <StoragesList
            storages={internalStorages}
            loading={loading}
            searchValue={search}
            handleSearchChange={handleSearchChange}
            handleDateChange={handleDateChange}
            handleForceReload={handleForceReload}
            showBanner={showBanner}
            openAddStorage={openAddStorage}
            internal
         />
      );
   },
);

export default InternalStoragePage;
