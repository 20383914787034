import dayjs from 'dayjs';

export const calculateMinDate = () => {
   const today = new Date().getDay();
   if (today < 3) {
      return dayjs(new Date()).add(3, 'days').toDate();
   } else {
      return dayjs(new Date()).add(5, 'days').toDate();
   }
};
