import { LostDamagedPalletsBySupplierItem, ReportType } from 'interfaces';

export const checkIfAvailableDamageTypeForPallet = (
   selectedLostDamagedPalletsItem: LostDamagedPalletsBySupplierItem,
   allLostDamagedPalletsItems: LostDamagedPalletsBySupplierItem[],
   damageType: ReportType,
) =>
   !allLostDamagedPalletsItems.some(
      item =>
         item.palletType === selectedLostDamagedPalletsItem.palletType &&
         item.damageType === damageType,
   );
