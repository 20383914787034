import { isEmpty } from 'lodash';

import { CustomModal } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { useModal } from 'hooks/useModal';
import { Charge, OrderDetailsDTO, Pallet } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './SendToSettlementModal.module.css';

type SendToSettlementModalProps = {
   onSendForSettlementSuccess: (updatedOrder: OrderDetailsDTO) => void;
   orderId: string;
   remainingPalletsToBeSend?: Pallet[];
   additionalCharges: Charge[];
   opened: boolean;
   onClose: () => void;
};

const SendToSettlementModal = ({
   onSendForSettlementSuccess,
   orderId,
   additionalCharges,
   remainingPalletsToBeSend,
   opened,
   onClose,
}: SendToSettlementModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const sendOrderForSettlement = () => {
      setIsLoading(true);
      HTTPService.submitOrderForBilling(orderId)
         .then(({ data: responseData }) => {
            closeModalHandler();
            onSendForSettlementSuccess(responseData);
         })
         .catch(err => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(err);
         })
         .finally(() => setIsLoading(false));
   };

   const withAdditionalCharges = !isEmpty(additionalCharges);
   const withRemainingPalletsToBeSend = !isEmpty(remainingPalletsToBeSend);

   return (
      <CustomModal
         opened={opened}
         onClose={closeModalHandler}
         title="Prześlij do rozliczenia"
         {...(withAdditionalCharges && !withRemainingPalletsToBeSend
            ? {
                 innerTitle: 'Uwaga!',
                 icon: 'ALERT_TRIANGLE',
              }
            : {})}
         primaryButtonProps={{
            text:
               !withRemainingPalletsToBeSend && !withAdditionalCharges
                  ? 'Prześlij do rozliczenia'
                  : 'Przesyłam i akceptuję dodatkowe obciążenia',
            variant: 'filled-primary',
            onClick: sendOrderForSettlement,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: closeModalHandler,
         }}
         isLoading={isLoading}
         error={errorInfo}
         size={!withRemainingPalletsToBeSend && !withAdditionalCharges ? 600 : 700}
         modalContentClassName={styles.modalContent}
         leftSideModalContent
      >
         <>
            {withAdditionalCharges && !withRemainingPalletsToBeSend && (
               <p>
                  Zamówienie, które chcesz rozliczyć posiada dodatkowe opłaty za zgubienie,
                  uszkodzenie lub przetrzymanie palet.
               </p>
            )}
            {withRemainingPalletsToBeSend && (
               <div className={styles.remainingPalletsContainer}>
                  <Banner
                     fullWidth
                     children="Zadeklarowane nadania nie są równe ilości wynajętych palet."
                     variant="error"
                  />
                  <p>Pozostały:</p>
                  <ul>
                     {remainingPalletsToBeSend?.map((pallet, index) => (
                        <li key={index}>
                           {PALLET_TYPE_LABELS[pallet.palletType]}{' '}
                           <strong>{pallet.amount} szt.</strong>
                        </li>
                     ))}
                  </ul>
                  <p className={styles.remainingPalletsText}>
                     Brakujące palety trafią na konto palet zagubionych i zostanie naliczone
                     obciążenie finansowe zgodne z zaakceptowanymi w umowie warunkami oferty.
                  </p>
                  {withAdditionalCharges && (
                     <p className={styles.remainingPalletsText}>
                        Ponadto zamówienie, które chcesz rozliczyć posiada już dodatkowe opłaty za
                        zgubienie, uszkodzenie lub przetrzymanie palet.
                     </p>
                  )}
               </div>
            )}
            {!withRemainingPalletsToBeSend && !withAdditionalCharges && (
               <p>
                  Wszystkie palety zostały nadane i zamówienie nie posiada dodatkowych opłat. Po
                  przesłaniu zamówienia do rozliczenia nie będzie możliwości edytowania nadań.
               </p>
            )}
            <p>Czy chcesz kontynuować?</p>
         </>
      </CustomModal>
   );
};

export default SendToSettlementModal;
