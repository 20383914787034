import React from 'react';

import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from '../../../constants/palletTypes';
import { BalancePickUpDTO, PalletPickUpDTO } from '../../../interfaces';
import { TableCell, TableRow } from '../../../storybook';
import { getFormattedDateFromString, getTimeFromString } from '../../../utils/functions';

import styles from './PalletPickUpTableItem.module.css';

type PalletPickUpTableItemProps = {
   pickUp: PalletPickUpDTO;
};

const PalletPickUpTableItem = ({ pickUp }: PalletPickUpTableItemProps) => {
   const flatMapPalletTypesWithPositiveBalance = (balances: BalancePickUpDTO[]) =>
      balances
         .filter(palletBalance => palletBalance.balance.sum > 0)
         .map(palletBalance => PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletBalance.palletType].label)
         .join(', ');

   return (
      <TableRow key={pickUp.id} className={styles.tableRow}>
         <TableCell className={styles.tableCell}>
            {getFormattedDateFromString(pickUp.pickUpTime)}
         </TableCell>
         <TableCell className={styles.tableCell}>{getTimeFromString(pickUp.pickUpTime)}</TableCell>
         <TableCell className={styles.tableCell}>
            {flatMapPalletTypesWithPositiveBalance(pickUp.palletBalances)}
         </TableCell>
         <TableCell className={styles.tableCell} align="right">
            <b>{pickUp.totalSum.sum}</b>
         </TableCell>
         <TableCell className={styles.tableCell} align="right">
            {pickUp.totalSum.valid}
         </TableCell>
         <TableCell className={styles.tableCell} align="right">
            {pickUp.totalSum.invalid}
         </TableCell>
      </TableRow>
   );
};

export default PalletPickUpTableItem;
