import { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './PageHeader.module.css';

type PageHeaderProps = {
   headerLeftElement?: ReactNode;
   headerRightElement?: ReactNode;
   className?: string;
};

const PageHeader = ({ headerLeftElement, headerRightElement, className }: PageHeaderProps) => (
   <div
      className={classNames(
         styles.header,
         {
            [styles.leftSide]: headerLeftElement,
            [styles.rightSide]: headerRightElement,
            [styles.spaceBetween]: headerLeftElement && headerRightElement,
         },
         className,
      )}
   >
      {headerLeftElement}
      {headerRightElement}
   </div>
);

export default PageHeader;
