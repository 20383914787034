import { AlertTriangle } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { ExcessPallets } from 'interfaces/commonInterfacesAndTypes';
import { getPalletLabel } from 'utils/functions/getPalletLabel';

import CustomModal, { CustomModalProps } from '../CustomModal/CustomModal';

import styles from './NotFoundOrderAlertModal.module.css';

type NotFoundOrderAlertModalProps = CustomModalProps & {
   modalType: 'REPORT_DAMAGE_PALLETS' | 'SEND_PALLETS';
   oneOrderAction: boolean;
   excessPallets: ExcessPallets | null;
   returnBtnText: string;
   onReturn: () => void;
};

const NotFoundOrderAlertModal = ({
   modalType,
   oneOrderAction,
   excessPallets,
   returnBtnText,
   onReturn,
   ...props
}: NotFoundOrderAlertModalProps) => (
   <CustomModal {...props} centered className={styles.modal}>
      <div className={styles.container}>
         <div className={styles.contentContainer}>
            <AlertTriangle width="47" height="43" color={'var(--primary-red)'} />
            <h3>Uwaga!</h3>
            <p>
               {oneOrderAction
                  ? 'Do danego zamówienia nie można przypisać palety:'
                  : 'Brak zamówienia, do którego można przypisać palety:'}
            </p>
            <ul>
               {excessPallets &&
                  Object.entries(excessPallets).map(([palletType, amount]) => (
                     <li key={palletType}>
                        {PALLET_TYPE_LABELS[palletType]}{' '}
                        <span>
                           {amount} {getPalletLabel(amount)}
                        </span>
                     </li>
                  ))}
            </ul>
            <p>{`Zmień ilości palet, aby zostały poprawnie ${
               modalType === 'REPORT_DAMAGE_PALLETS' ? 'zgłoszone' : 'nadane'
            }.`}</p>
         </div>
         <div className={styles.btnContainer}>
            <StyledButton text={returnBtnText} onClick={onReturn} variant="outlined-primary" />
         </div>
      </div>
   </CustomModal>
);

export default NotFoundOrderAlertModal;
