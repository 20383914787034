export const PALETTE_STATUSES = {
   READY_TO_RENT: 'READY_TO_RENT',
   DAMAGED: 'DAMAGED',
   LOST: 'LOST',
};

export const PALETTE_STATUSES_LABELS = {
   READY_TO_RENT: 'Gotowe do wynajmu',
   DAMAGED: 'Uszkodzone',
   LOST: 'Stracone',
};

export const PALETTE_STATUSES_OPTIONS = [
   {
      value: PALETTE_STATUSES.READY_TO_RENT,
      label: PALETTE_STATUSES_LABELS.READY_TO_RENT,
   },
   { value: PALETTE_STATUSES.LOST, label: PALETTE_STATUSES_LABELS.LOST },
   { value: PALETTE_STATUSES.DAMAGED, label: PALETTE_STATUSES_LABELS.DAMAGED },
];
