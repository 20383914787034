import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { PalletType, SendPalletDTO } from 'interfaces';

export const getSendPalletTypes = (sendPallets: SendPalletDTO[]) => {
   const palletTypes = sendPallets.reduce<PalletType[]>((allPalletTypes, sendPalletsItem) => {
      const sendPalletsItemPalletTypes = sendPalletsItem.pallets.map(
         palletItem => palletItem.palletType,
      );
      return [...allPalletTypes, ...sendPalletsItemPalletTypes];
   }, []);
   const uniquePalletTypes = new Set(palletTypes);
   const palletTypeOptions = Array.from(uniquePalletTypes).map(palletType => ({
      label: PALLET_TYPE_LABELS[palletType],
      value: palletType,
   }));
   return palletTypeOptions;
};
