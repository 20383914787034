import { ClientUserDTO, RateConfigDTO, RateConfigForm } from 'interfaces';

import { parseClientDeliveryAddressIntoAddressDeliveryRatePerPalletConfig } from './parseClientDeliveryAddressIntoAddressDeliveryRatePerPalletConfig';
import { parseCommonRatePerPalletConfigIntoCommonRatePerPalletConfigFormData } from './parseCommonRatePerPalletConfigIntoCommonRatePerPalletConfigFormData';
import { parseDelayChargePerPalletConfigIntoDelayChargePerPalletConfigForm } from './parseDelayChargePerPalletConfigIntoDelayChargePerPalletConfigForm';
import { parseRetentionConfigIntoRetentionConfigForm } from './parseRetentionConfigIntoRetentionConfigForm';

export const parseClientUserDTOIntoRateConfigFormData: (
   clientUserDTO: ClientUserDTO,
) => RateConfigForm = clientUserDTO => {
   const { rateConfig, deliveryAddresses } = clientUserDTO;
   const { retentionConfig, palletRates, delaysCharges } = rateConfig as RateConfigDTO;

   const commonPalletRates = palletRates.map(
      parseCommonRatePerPalletConfigIntoCommonRatePerPalletConfigFormData,
   );
   const palletRatesPerAddress = deliveryAddresses.map(
      parseClientDeliveryAddressIntoAddressDeliveryRatePerPalletConfig,
   );
   const retentionConfigFormData = parseRetentionConfigIntoRetentionConfigForm(retentionConfig);
   const delaysChargesFormData = delaysCharges.map(
      parseDelayChargePerPalletConfigIntoDelayChargePerPalletConfigForm,
   );

   return {
      palletRates: {
         common: commonPalletRates,
         perAddress: palletRatesPerAddress,
      },
      retentionConfig: retentionConfigFormData,
      delaysCharges: delaysChargesFormData,
   };
};
