import { Option } from '../interfaces';

export const STORAGE_TYPE = {
   INTERNAL: 'INTERNAL',
   EXTERNAL: 'EXTERNAL',
   COMMERCIAL_NETWORK: 'COMMERCIAL_NETWORK',
   CLIENT: 'CLIENT',
};

export const STORAGE_TYPE_PL = {
   INTERNAL: 'wewnętrzny',
   EXTERNAL: 'zewnętrzny',
};

export const STORAGE_TYPE_LABELS: { [key: string]: string } = {
   INTERNAL: 'Magazyny wewnętrzne',
   EXTERNAL: 'Magazyny zewnętrzne',
   COMMERCIAL_NETWORK: 'Sieci handlowe',
};

export const STORAGE_TYPE_LABELS_SINGULAR: { [key: string]: string } = {
   INTERNAL: 'Magazyn wewnętrzny',
   EXTERNAL: 'Magazyn zewnętrzny',
   COMMERCIAL_NETWORK: 'Sieć handlowa',
};

export const STORAGE_TYPES_FOR_PICKER: Option[] = [
   { label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.INTERNAL], value: STORAGE_TYPE.INTERNAL },
   { label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.EXTERNAL], value: STORAGE_TYPE.EXTERNAL },
   {
      label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.COMMERCIAL_NETWORK],
      value: STORAGE_TYPE.COMMERCIAL_NETWORK,
   },
];

export const STORAGE_TYPES_FOR_ADMISSION_PICKER = [
   { label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.INTERNAL], value: STORAGE_TYPE.INTERNAL },
   { label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.EXTERNAL], value: STORAGE_TYPE.EXTERNAL },
];

export const STORAGE_TYPES_FOR_CLIENT_PICKER = [
   { label: 'Adres klienta', value: STORAGE_TYPE.CLIENT },
];
