import classNames from 'classnames';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import { PalletBalance } from 'interfaces';
import { getPalletsAmountLabel } from 'utils/functions';

import styles from './DamageLossPalletsSummary.module.css';

type DamageLossPalletsSummaryProps = {
   title?: string;
   damageLossPalletsSummaryData: PalletBalance;
   onClickDetails: () => void;
   className?: string;
};

const DamageLossPalletsSummary = ({
   title,
   damageLossPalletsSummaryData,
   onClickDetails,
   className,
}: DamageLossPalletsSummaryProps) => (
   <div className={classNames(styles.container, className)}>
      {title && <h4>{title}</h4>}
      <>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Paleta EDHP 800x1200 mm</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('EDHP_PALLET', damageLossPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Euro paleta 800x1200 mm</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('EURO_PALLET', damageLossPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Półpaleta NDHP 600x800 mm</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('HALF_PALLET_NDHP', damageLossPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Ćwierćpaleta CDHP 400x600 mm</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('QUARTER_PALLET_CDHP', damageLossPalletsSummaryData)}
            </span>
         </span>
         <StyledButton
            variant="filled-primary"
            type="button"
            onClick={onClickDetails}
            text="Szczegóły"
         />
      </>
   </div>
);

export default DamageLossPalletsSummary;
