import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';

import { FirebaseError } from '@firebase/util';
import {
   confirmPasswordReset,
   onAuthStateChanged,
   sendPasswordResetEmail,
   signInWithEmailAndPassword,
   signOut,
   verifyPasswordResetCode,
} from 'firebase/auth';

import { auth } from 'config/firebase';
import { ClientUserDTO } from 'interfaces';
import { HTTPService } from 'service';
import { SignInRequest } from 'service/http/requests';
import { logNetworkError } from 'utils/logNetworkError';

import AuthContext from './auth-context';

type AuthContextProps = {
   children: ReactNode;
};

const AuthContextProvider = ({ children }: AuthContextProps) => {
   const [user, setUser] = useState<ClientUserDTO | null>(null);

   useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, signedInUser => {
         if (signedInUser) {
            HTTPService.getCurrentUserDetails()
               .then(({ data: userDetails }) => setUser(userDetails))
               .catch((error: Error) => logNetworkError(error));
         }
      });
      return () => unsubscribe();
   }, []);

   const signIn = useCallback(async ({ email, password }: SignInRequest) => {
      try {
         await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
         if (error instanceof FirebaseError) {
            throw new Error(error.code);
         } else {
            const typedError = error as Error;
            throw new Error(typedError.message);
         }
      }
   }, []);

   const sendPasswordReset = async (email: string) => {
      try {
         await sendPasswordResetEmail(auth, email);
      } catch (error) {
         if (error instanceof FirebaseError) {
            throw new Error(error.code);
         } else {
            const typedError = error as Error;
            throw new Error(typedError.message);
         }
      }
   };

   const resetPassword = async (oobCode: string, newPassword: string) => {
      try {
         await verifyPasswordResetCode(auth, oobCode);
         await confirmPasswordReset(auth, oobCode, newPassword);
      } catch (error) {
         if (error instanceof FirebaseError) {
            throw new Error(error.code);
         } else {
            const typedError = error as Error;
            throw new Error(typedError.message);
         }
      }
   };

   const logout = async () => {
      try {
         await signOut(auth);
         setUser(null);
      } catch (error) {
         const typedError = error as Error;
         throw new Error(typedError.message);
      }
   };

   return (
      <AuthContext.Provider
         value={{
            user,
            signIn,
            sendPasswordReset,
            resetPassword,
            logout,
            setUser,
         }}
      >
         {children}
      </AuthContext.Provider>
   );
};

export default AuthContextProvider;

export const useAuth = () => {
   return useContext(AuthContext);
};
