import { useCallback } from 'react';

import { CustomModal } from 'components/shared';
import { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';

import styles from './SupplierCancelPalletsPickUpModal.module.css';

type SupplierCancelPalletsPickUpModalProps = Omit<CustomModalProps, 'opened'> & {
   selectedPalletsPickUpId: string;
   onCancelPalletsPickUpSuccess: (palletsPickUpId: string) => void;
};

const SupplierCancelPalletsPickUpModal = ({
   selectedPalletsPickUpId,
   onCancelPalletsPickUpSuccess,
   onClose,
   ...props
}: SupplierCancelPalletsPickUpModalProps) => {
   const handleCloseModal = useCallback(() => {
      onClose();
   }, [onClose]);

   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose: handleCloseModal,
   });

   const handleCancelPalletsPickUp = async () => {
      try {
         setIsLoading(true);
         await HTTPService.rejectPalletPickUp(selectedPalletsPickUpId);
         onCancelPalletsPickUpSuccess(selectedPalletsPickUpId);
      } catch (error) {
         errorHandler(error, () => setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION));
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <CustomModal
         {...props}
         opened
         onClose={closeModalHandler}
         icon="ALERT_TRIANGLE"
         innerTitle="Odrzuć zwrot palet"
         error={errorInfo}
         isLoading={isLoading}
         primaryButtonProps={{
            text: 'Odrzuć zwrot',
            variant: 'filled-danger',
            onClick: handleCancelPalletsPickUp,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'outlined-danger',
            onClick: closeModalHandler,
         }}
      >
         <>
            <p className={styles.plainText}>
               Czy jesteś pewien, że chcesz odrzucić ten zwrot palet?
            </p>
            <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
         </>
      </CustomModal>
   );
};

export default SupplierCancelPalletsPickUpModal;
