import { ReactNode } from 'react';

import { BannerData } from 'interfaces';
import { Banner } from 'storybook';

import styles from './DamagedLostPalletsLayout.module.css';

type DamagedLostPalletsLayoutProps = {
   children: ReactNode;
   bannerData?: BannerData | null;
};

const DamagedLostPalletsLayout = ({ children, bannerData }: DamagedLostPalletsLayoutProps) => (
   <div className={styles.mainContainer}>
      {bannerData && (
         <Banner
            fullWidth
            className={styles.banner}
            variant={bannerData.variant}
            children={bannerData.description}
         />
      )}
      {children}
   </div>
);

export default DamagedLostPalletsLayout;
