import classNames from 'classnames';

import { Autocomplete, AutocompleteProps } from 'storybook';

import styles from './StyledAutocomplete.module.css';

const StyledAutocomplete = ({
   classNames: autocompleteClassNames,
   ...props
}: AutocompleteProps) => (
   <Autocomplete
      {...props}
      classNames={{
         container: classNames(styles.container, autocompleteClassNames?.container, {
            [styles.error]: props.error,
            [styles.disabled]: props.disabled,
         }),
         optionsContainer: classNames(
            styles.optionsContainer,
            autocompleteClassNames?.optionsContainer,
         ),
      }}
   />
);

export default StyledAutocomplete;
