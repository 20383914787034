import { OrderSummaryAndOrderDetailsPallet } from 'interfaces';
import { Card } from 'storybook';
import { palletImages } from 'utils/palletImages';
import { isClientOrderSummaryPallet } from 'utils/typeGuards';

import PalletAmountSection from './PalletAmountSection/PalletAmountSection';
import PalletInfoSection from './PalletInfoSection/PalletInfoSection';
import PalletTotalPriceSection from './PalletTotalPriceSection/PalletTotalPriceSection';
import styles from './PalletConfigurationCard.module.css';

type OverviewPalletConfigurationCardProps = {
   pallet: OrderSummaryAndOrderDetailsPallet;
   isClientView?: boolean;
   isOrderFree?: boolean;
};

const OverviewPalletConfigurationCard = ({
   pallet,
   isClientView,
   isOrderFree,
}: OverviewPalletConfigurationCardProps) => {
   const isPalletTotalPriceVisible = !isClientOrderSummaryPallet(pallet) && !isClientView;

   return (
      <Card
         className={styles.card}
         size="sm"
         title={pallet.label}
         icon={<img src={palletImages[pallet.name as keyof typeof palletImages]} />}
         bottomContent={
            <div className={styles.cardContent}>
               <PalletInfoSection
                  pallet={pallet}
                  isOrderFree={isOrderFree}
                  isClientView={isClientView}
               />
               <PalletAmountSection pallet={pallet} isClientView={isClientView} />
               {isPalletTotalPriceVisible && (
                  <PalletTotalPriceSection pallet={pallet} isOrderFree={isOrderFree} />
               )}
            </div>
         }
      />
   );
};

export default OverviewPalletConfigurationCard;
