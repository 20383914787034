import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router-dom';

import { ArrowLeftGreenIcon } from 'assets';
import { Logo, StyledButton, UserDropdown } from 'components/shared';
import { LOGOUT_ERROR_BANNER } from 'constants/banners';
import {
   CLIENT_ORDERS,
   CLIENT_PALLETS,
   CLIENT_PALLETS_TRANSFER_HISTORY,
   DISTRIBUTION_CENTER_PALLET_BALANCE,
   DISTRIBUTION_CENTER_PALLET_PICKUPS,
   DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
   STORAGE_PALLET_BALANCE,
   STORAGE_PALLETS_TRANSFER_HISTORY,
   SUPPLIER_CLIENT_PALLET_BALANCES,
   SUPPLIER_CLIENTS,
   SUPPLIER_COMMERCIAL_NETWORKS,
   SUPPLIER_EXTERNAL_STORAGES,
   SUPPLIER_INTERNAL_STORAGES,
   SUPPLIER_ORDERS,
   SUPPLIER_PALLETS,
   SUPPLIER_PALLETS_TRANSFER_HISTORY,
} from 'constants/routes';
import { BannerData } from 'interfaces';
import { Banner, TopBar } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import SideBar from './SideBar/SideBar';
import styles from './Layout.module.css';

const pagesWithTopBarHeader = [
   CLIENT_ORDERS,
   CLIENT_PALLETS,
   CLIENT_PALLETS_TRANSFER_HISTORY,
   SUPPLIER_ORDERS,
   SUPPLIER_CLIENTS,
   SUPPLIER_INTERNAL_STORAGES,
   SUPPLIER_EXTERNAL_STORAGES,
   SUPPLIER_COMMERCIAL_NETWORKS,
   SUPPLIER_CLIENT_PALLET_BALANCES,
   SUPPLIER_PALLETS,
   SUPPLIER_PALLETS_TRANSFER_HISTORY,
   DISTRIBUTION_CENTER_PALLET_PICKUPS,
   DISTRIBUTION_CENTER_PALLET_BALANCE,
   DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
   STORAGE_PALLET_BALANCE,
   STORAGE_PALLETS_TRANSFER_HISTORY,
];

const TOP_BAR_HEADER_LABELS: { [key: string]: string } = {
   [CLIENT_ORDERS]: 'Zamówienia',
   [CLIENT_PALLETS]: 'Palety',
   [CLIENT_PALLETS_TRANSFER_HISTORY]: 'Historia ruchów paletowych',
   [SUPPLIER_ORDERS]: 'Zamówienia',
   [SUPPLIER_CLIENTS]: 'Klienci',
   [SUPPLIER_INTERNAL_STORAGES]: 'Magazyny',
   [SUPPLIER_EXTERNAL_STORAGES]: 'Magazyny',
   [SUPPLIER_COMMERCIAL_NETWORKS]: 'Magazyny',
   [SUPPLIER_CLIENT_PALLET_BALANCES]: 'Magazyny',
   [SUPPLIER_PALLETS]: 'Palety zgubione, zniszczone lub uszkodzone w dostawie',
   [SUPPLIER_PALLETS_TRANSFER_HISTORY]: 'Historia ruchów paletowych',
   [DISTRIBUTION_CENTER_PALLET_PICKUPS]: 'Odbiory',
   [DISTRIBUTION_CENTER_PALLET_BALANCE]: 'Saldo',
   [DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY]: 'Historia ruchów paletowych',
   [STORAGE_PALLET_BALANCE]: 'Saldo',
   [STORAGE_PALLETS_TRANSFER_HISTORY]: 'Historia ruchów paletowych',
};

const Layout = () => {
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const location = useLocation();
   const navigate = useNavigate();

   const handleLogoutError = (error: unknown) =>
      errorHandler(error, () => setBannerData(LOGOUT_ERROR_BANNER));

   return (
      <div className={styles.layoutContainer}>
         <SideBar />
         <div className={styles.contentContainer}>
            <TopBar
               className={styles.topBar}
               contentLeft={
                  pagesWithTopBarHeader.includes(location.pathname) ? (
                     <>
                        <Logo className={styles.mobileOnlyLogo} />
                        <h2 className={styles.topBarHeader}>
                           {TOP_BAR_HEADER_LABELS[location.pathname]}
                        </h2>
                     </>
                  ) : (
                     <StyledButton
                        onClick={() => {
                           navigate(-1);
                        }}
                        icon={<ArrowLeftGreenIcon />}
                        text="Powrót"
                        variant="text"
                     />
                  )
               }
               contentRight={<UserDropdown onLogoutError={handleLogoutError} />}
            />
            <div className={styles.childrenContainer}>
               {bannerData && (
                  <Banner
                     variant={bannerData.variant}
                     title={bannerData.title}
                     children={bannerData.description}
                     className={styles.banner}
                     onClose={() => setBannerData(null)}
                     withCloseIcon
                     fullWidth
                  />
               )}
               <Outlet />
            </div>
         </div>
      </div>
   );
};
export default Layout;
