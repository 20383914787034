import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { CheckCircleIcon } from 'assets';
import StyledBadge from 'components/shared/StyledBadge/StyledBadge';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import { CLIENT_ORDERS } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { AddressData } from 'interfaces';

import styles from './OrderSuccessPage.module.css';

interface LocationState {
   logisticMinimum: boolean;
   deliveryType: AddressData | 'SELF_PICKUP';
}

export const OrderSuccessPage = () => {
   const data = useLocation().state as LocationState;
   const logisticMinimum = data.logisticMinimum;
   const deliveryType = data.deliveryType;
   return (
      <div className={styles.container}>
         <div className={styles.innerContainer}>
            <CheckCircleIcon />
            <h2>
               {!logisticMinimum || deliveryType === 'SELF_PICKUP'
                  ? 'Twoje zapytanie o palety zostało przesłane.'
                  : 'Twoje zamówienie zostało pomyślnie złożone.'}
            </h2>

            {!logisticMinimum || deliveryType === 'SELF_PICKUP' ? (
               <div className={styles.textSection}>
                  <p>
                     Nasi pracownicy dołożą wszelkich starań, by jak
                     <br /> najszybciej się z Tobą skontaktować.
                  </p>
                  <div className={styles.textBadgeRow}>
                     <p>Do tego czasu zamówienie będzie widoczne na liście jako: </p>
                  </div>
                  <StyledBadge variant="WAITING" text="Oczekujące" />
                  {deliveryType === 'SELF_PICKUP' && (
                     <p>
                        Adres odbioru osobistego możesz znaleźć (po zaakceptowaniu zamówienia) w
                        szczegółach zamówienia oraz w mailu.
                     </p>
                  )}
               </div>
            ) : (
               <div className={styles.textSection}>
                  <p>
                     Twoje zamowienie musi zostać zaakceptowane przez naszego pracownika i do tego
                     czasu zamówienie będzie widoczne na liście jako:
                  </p>
                  <StyledBadge variant="WAITING" text="Oczekujące" />
                  <p>
                     Nasi pracownicy dołożą wszelkich starań, Twoje zamówienie zostało jak
                     najszybciej zaakceptowane.
                  </p>
               </div>
            )}

            <Link to={CLIENT_ORDERS}>
               <StyledButton
                  style={{ marginTop: 45 }}
                  text="Lista zamówień"
                  variant="outlined-primary"
               />
            </Link>
         </div>
      </div>
   );
};
export default WithAuth(OrderSuccessPage, 'ROLE_CLIENT');
