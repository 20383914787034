import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'constants/dateFormats';
import {
   TransferActionsOverviewAdditionalFilters,
   TransferActionsOverviewFiltersForm,
} from 'interfaces';

export const parseTransferActionsOverviewFiltersFormIntoTransferActionsOverviewAdditionalFilters: (
   transferActionsOverviewFiltersForm: TransferActionsOverviewFiltersForm,
) => TransferActionsOverviewAdditionalFilters = transferActionsOverviewFiltersForm => {
   const {
      client: clientFormValue,
      commercialNetwork: commercialNetworkFormValue,
      distributionCenter: distributionCenterFormValue,
      storage: storageFormValue,
      dateFrom: dateFromFormValue,
      dateTo: dateToFormValue,
   } = transferActionsOverviewFiltersForm;

   return {
      clientId: clientFormValue?.value,
      commercialNetworkId: commercialNetworkFormValue?.value,
      distributionCenterId: distributionCenterFormValue?.value,
      storageId: storageFormValue?.value,
      fromDate: dateFromFormValue
         ? DateTime.fromJSDate(dateFromFormValue).toFormat(DATE_FORMAT)
         : undefined,
      toDate: dateToFormValue
         ? DateTime.fromJSDate(dateToFormValue).toFormat(DATE_FORMAT)
         : undefined,
   };
};
