import React, {
   ChangeEvent,
   CSSProperties,
   FocusEvent,
   MouseEvent as ReactMouseEvent,
   ReactNode,
   useState,
} from 'react';

import classNames from 'classnames';

import { EyeCrossedIcon, EyeIcon, InputXIcon } from 'storybook/assets';

import styles from './TextInput.module.css';

export interface TextInputProps {
   className?: string;
   value?: string | number;
   placeholder?: string;
   label?: string;
   additionalContent?: ReactNode;
   helperText?: string;
   disabled?: boolean;
   error?: boolean;
   leftIcon?: ReactNode;
   rightIcon?: ReactNode;
   type: 'text' | 'number' | 'email' | 'password';
   width?: string;
   clearButton?: boolean;
   onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
   onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
   onClick?: (event: ReactMouseEvent<HTMLInputElement>) => void;
   onClear?: () => void;
   name?: string;
   style?: CSSProperties;
   fullWidth?: boolean;
   isTooltipVisible?: boolean;
   isVisibleRightPart?: boolean;
}

export const TextInput = ({
   className,
   value,
   label,
   additionalContent,
   placeholder,
   helperText,
   disabled,
   error,
   leftIcon,
   rightIcon,
   type,
   width,
   clearButton,
   onChange,
   onBlur,
   onClick,
   onClear,
   name,
   style,
   fullWidth,
   isTooltipVisible,
   isVisibleRightPart,
}: TextInputProps) => {
   const [inputType, setInputType] = useState(type);
   const [isFocused, setIsFocused] = useState(false);

   const handlePasswordIconClick = () => {
      if (inputType === 'password') {
         setInputType('text');
      } else {
         setInputType('password');
      }
   };

   const handleFocus = () => setIsFocused(true);

   const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event);
      setIsFocused(false);
   };

   const inputTitle = isTooltipVisible && value ? value.toString() : '';

   return (
      <div
         style={{ width: width, ...style }}
         className={classNames(styles.inputContainer, className, {
            [styles.error]: error,
            [styles.withLeftIcon]: leftIcon,
            [styles.withRightIcon]: rightIcon,
            [styles.withClearButton]: clearButton,
            [styles.passwordInput]: type === 'password',
            [styles.withLabel]: label,
            [styles.fullWidth]: fullWidth,
         })}
      >
         {label && <label>{label}</label>}
         {additionalContent}
         {leftIcon}
         <input
            name={name}
            onChange={onChange}
            onBlur={handleBlur}
            onClick={onClick}
            value={value}
            type={inputType}
            disabled={disabled}
            title={inputTitle}
            placeholder={placeholder}
            onFocus={handleFocus}
            className={classNames(
               { [styles.inputWithVisibleRightPart]: isVisibleRightPart },
               { [styles.focusedInput]: isFocused },
            )}
         />
         {rightIcon}
         {helperText && <span>{helperText}</span>}
         {clearButton && <InputXIcon className={styles.clearIcon} onClick={onClear} />}
         {type === 'password' &&
            (inputType === 'password' ? (
               <EyeIcon className={styles.eyeIcon} onClick={handlePasswordIconClick} />
            ) : (
               <EyeCrossedIcon
                  className={classNames(styles.eyeIcon, styles.eyeCrossedIcon)}
                  onClick={handlePasswordIconClick}
               />
            ))}
      </div>
   );
};
