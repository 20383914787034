import React from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { DotsVertical } from 'tabler-icons-react';

import { useDropdownContentPosition } from 'hooks';

import styles from './ActionsDropdown.module.css';

export type ActionDropdownItem = {
   text: string;
   onClick: () => void;
   icon?: React.ReactNode;
   lastOption?: boolean;
   dangerOption?: boolean;
};

type ActionsDropdownProps = {
   options: ActionDropdownItem[];
   portalTarget?: HTMLElement;
   large?: boolean;
   className?: string;
};

const ActionsDropdown = ({ options, portalTarget, large, className }: ActionsDropdownProps) => {
   const {
      expanded,
      open,
      toggleRef: dotsContainerRef,
      dropdownContentRefFunc,
      dropdownContentPosition,
   } = useDropdownContentPosition({
      portalTarget,
   });

   const dropdownContent = (
      <div
         ref={dropdownContentRefFunc}
         className={classNames(styles.dropdown, {
            [styles.dropdownActive]: expanded,
            [styles.dropdownLarge]: large,
            [styles.withPortal]: portalTarget,
         })}
         style={dropdownContentPosition}
      >
         {options.map((option, index) => (
            <React.Fragment key={index}>
               {option.lastOption && <div className={styles.divider} />}
               <div
                  className={classNames(styles.option, {
                     [styles.lastOption]: option.lastOption,
                     [styles.dangerOption]: option.dangerOption,
                  })}
                  onClick={option.onClick}
                  key={index}
               >
                  {option.icon} {option.text}
               </div>
            </React.Fragment>
         ))}
      </div>
   );

   return (
      <div className={classNames(styles.dropdownContainer, className)}>
         <div
            ref={dotsContainerRef}
            onClick={event => {
               event.stopPropagation();
               open();
            }}
            className={classNames(styles.dotsContainer, {
               [styles.dotsContainerActive]: expanded,
               [styles.dotsContainerLarge]: large,
            })}
         >
            <DotsVertical className={styles.dotsIcon} />
         </div>
         {expanded &&
            (portalTarget ? createPortal(dropdownContent, portalTarget) : dropdownContent)}
      </div>
   );
};

export default ActionsDropdown;
