import React, { CSSProperties, MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Button.module.css';

export interface ButtonProps {
   icon?: ReactNode;
   text?: string;
   className?: string;
   fullWidth?: boolean;
   size?: 'sm';
   onClick?: (event: ReactMouseEvent<HTMLButtonElement>) => void;
   loading?: boolean;
   disabled?: boolean;
   rightIcon?: ReactNode;
   loader?: ReactNode;
   type?: 'button' | 'reset' | 'submit';
   style?: CSSProperties;
}

export const Button = ({
   icon,
   text,
   className,
   fullWidth,
   size,
   onClick,
   loading,
   disabled,
   rightIcon,
   loader,
   type = 'submit',
   style,
}: ButtonProps) => {
   return (
      <button
         style={style}
         type={type}
         disabled={disabled || loading}
         onClick={onClick}
         className={classNames(styles.btn, className, {
            [styles.fullWidth]: fullWidth,
            [styles.small]: size === 'sm',
            [styles.iconLeft]: icon,
            [styles.iconRight]: rightIcon,
            [styles.iconOnly]: !text,
            [styles.loading]: loading,
            [styles.disabled]: disabled,
         })}
      >
         {loading ? loader : icon}
         {text}
         {rightIcon}
      </button>
   );
};
