import classNames from 'classnames';

import { getPalletAmountLabel } from 'utils/functions';

import styles from './PalletAmountListItem.module.css';

type PalletAmountListItemProps = {
   palletInfo: string;
   palletCount: number;
   isFree?: boolean;
};

const PalletAmountListItem = ({ palletInfo, palletCount, isFree }: PalletAmountListItemProps) => (
   <div className={classNames(styles.container, { [styles.isFree]: isFree })}>
      <p>{palletInfo}</p>
      <p>{`${isFree ? '+' : ''}${getPalletAmountLabel(palletCount)}`}</p>
   </div>
);

export default PalletAmountListItem;
