import { FocusEvent, useMemo } from 'react';

import {
   BannerList,
   BannerListItem,
   SharedGridTable,
   SharedGridTableCell,
   SharedGridTableRow,
   StyledTextInput,
} from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { RetentionConfigForm } from 'interfaces';
import { formatStringToPrice, getUniqueErrorMessagesFromErrorsObjectList } from 'utils/functions';
import { isNumeric } from 'utils/validation';

type RentPeriodProps = {
   values: RetentionConfigForm;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   errors: any;
   touched: {
      [x: string]: boolean;
   };
   onChangeRetentionPeriodCost: (enteredValue: string, palletType: string) => void;
   onRetentionPeriodDaysChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const rentPeriodHeaders = ['Rodzaj palety', 'Koszty przetrzymania palety za 1 dzień'];

const RentPeriod = ({
   values,
   errors,
   touched,
   onChangeRetentionPeriodCost,
   onRetentionPeriodDaysChange,
}: RentPeriodProps) => {
   const handleRetentionPeriodCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
         target: { name, value },
      } = event;
      onChangeRetentionPeriodCost(value, name);
   };

   const handleRetentionPeriodCostBlur = (event: FocusEvent<HTMLInputElement>) => {
      const {
         target: { name, value: enteredValue },
      } = event;
      const isNumericValue = isNumeric(enteredValue);
      if (!isNumericValue || enteredValue === '') {
         return;
      }
      const formattedValue = formatStringToPrice(enteredValue);
      onChangeRetentionPeriodCost(formattedValue, name);
   };

   const retentionConfigErrorBannersData = useMemo(() => {
      if (!errors?.retentionConfig?.retentionPalletCosts || !touched.retentionConfig) {
         return null;
      }
      const filteredErrorMessages = errors.retentionConfig.retentionPalletCosts.filter(Boolean);
      const uniqueErrorMessages = getUniqueErrorMessagesFromErrorsObjectList(filteredErrorMessages);
      return uniqueErrorMessages.map<BannerListItem>(uniqueErrorMessage => ({
         key: uniqueErrorMessage,
         variant: 'error',
         children: uniqueErrorMessage,
         fullWidth: true,
      }));
   }, [errors?.retentionConfig?.retentionPalletCosts, touched.retentionConfig]);

   return (
      <>
         <StyledTextInput
            onChange={onRetentionPeriodDaysChange}
            value={values.retentionPeriodDays || ''}
            type="number"
            label="Okres wynajmu (dni)"
            style={{ marginBottom: 36 }}
            placeholder="np. 30"
            error={touched.retentionConfig && errors.retentionConfig?.retentionPeriodDays}
            helperText={touched.retentionConfig && errors.retentionConfig?.retentionPeriodDays}
         />
         {retentionConfigErrorBannersData && (
            <BannerList bannersData={retentionConfigErrorBannersData} />
         )}
         <SharedGridTable
            headers={rentPeriodHeaders}
            rows={[
               values.retentionPalletCosts.map((retentionPalletCost, index) => (
                  <SharedGridTableRow>
                     <SharedGridTableCell>
                        <h3>
                           {
                              PALLET_TYPES_LABELS_WITH_DIMENSIONS[retentionPalletCost.palletType]
                                 .label
                           }
                        </h3>
                        <p>
                           {
                              PALLET_TYPES_LABELS_WITH_DIMENSIONS[retentionPalletCost.palletType]
                                 .dimensions
                           }
                        </p>
                     </SharedGridTableCell>
                     <SharedGridTableCell>
                        <StyledTextInput
                           onChange={handleRetentionPeriodCostChange}
                           onBlur={handleRetentionPeriodCostBlur}
                           name={retentionPalletCost.palletType}
                           value={retentionPalletCost.price}
                           type="text"
                           error={
                              errors.retentionConfig?.retentionPalletCosts &&
                              touched.retentionConfig &&
                              errors.retentionConfig?.retentionPalletCosts[index]?.price
                           }
                        />
                     </SharedGridTableCell>
                  </SharedGridTableRow>
               )),
            ]}
         />
      </>
   );
};

export default RentPeriod;
