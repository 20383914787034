import classNames from 'classnames';

import { OrderStatus } from 'interfaces';
import { Banner } from 'storybook';

import styles from './FreeOrderBanner.module.css';

type FreeOrderBannerProps = {
   orderStatus: OrderStatus;
   isOrderAddedToNextOrder: boolean;
   isClientView?: boolean;
   orderAddedToOrderId: string | null;
   className?: string;
};

const FreeOrderBanner = ({
   orderStatus,
   isOrderAddedToNextOrder,
   isClientView,
   orderAddedToOrderId,
   className,
}: FreeOrderBannerProps) => {
   const freeOrderText = isClientView
      ? 'To zamówienie jest bezpłatne.'
      : 'To zamówienie jest bezpłatne i nie ma możliwości wystawienia faktury.';

   const getBannerText = () => {
      if (!isOrderAddedToNextOrder) {
         return freeOrderText;
      } else if (orderStatus === 'ENDED' && orderAddedToOrderId) {
         return `Palety zostały dołączone do zamówienia nr ${orderAddedToOrderId}. Wszystkie akcje związane z zarządzaniem tymi paletami możesz wykonać z poziomu zamówienia nr ${orderAddedToOrderId}.`;
      }
      return (
         <>
            <p>{freeOrderText}</p>
            <p>
               {isClientView
                  ? 'Poniższe palety zostaną dołączone do kolejnego zamówienia, które złożysz.'
                  : 'Palety z tego zamówienia zostaną dołączone do kolejnego zamówienia klienta.'}
            </p>
         </>
      );
   };

   return (
      <Banner
         fullWidth
         variant="info"
         className={classNames(styles.banner, className)}
         children={getBannerText()}
      />
   );
};

export default FreeOrderBanner;
