import { ChangeEvent, memo, useState } from 'react';

import classNames from 'classnames';

import { StyledTextInput, TextInputWithChangedState } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import {
   ConfigurationEditBalanceAll,
   PalletStatusTypeForHook,
   PalletType,
   TransferPalletBalance,
} from 'interfaces';
import { AvailablePalletStatusDTO, ReturnPalletDTO } from 'interfaces/dto/availablePalletDTO';
import { TableCell, TableRow } from 'storybook';
import {
   getTransferAvailablePalletsSumValue,
   getTransferReturnPalletsSumValue,
} from 'utils/functions/transferPalletsSumValue';

import styles from './DistributionCenterPalletTableItem.module.css';

type StorageTableItem = {
   availablePallets?: AvailablePalletStatusDTO;
   initialAvailablePallets?: TransferPalletBalance & Record<string, unknown>;
   returnPallets?: ReturnPalletDTO;
   name: PalletType;
   mode: string;
   handleChangeValue?: (rowIndex: number, field: string, value: string) => void;
   rowIndex: number;
   error: Record<string, string | boolean>;
   touched: Record<string, boolean>;
};

const DistributionCenterPalletTableItem = ({
   name,
   returnPallets,
   availablePallets,
   initialAvailablePallets,
   mode,
   handleChangeValue,
   rowIndex,
   error,
   touched,
}: StorageTableItem) => {
   const [transferPalletsValues, setTransferPalletsValues] = useState(
      mode === 'transfer'
         ? {
              validAvailablePallets: availablePallets?.validAvailablePallets,
              invalidAvailablePallets: availablePallets?.invalidAvailablePallets,
           }
         : {
              validAvailablePallets: availablePallets?.validAvailablePallets,
              invalidAvailablePallets: availablePallets?.invalidAvailablePallets,
              validReturnPallets: returnPallets?.validReturnPallets,
              invalidReturnPallets: returnPallets?.invalidReturnPallets,
           },
   );

   const editCellValue = (paletteType: PalletStatusTypeForHook) => {
      return paletteType === 'sumOfAvailablePallets'
         ? getTransferAvailablePalletsSumValue(transferPalletsValues)
         : getTransferReturnPalletsSumValue(transferPalletsValues);
   };

   const firstCell = PALLET_TYPES_LABELS_WITH_DIMENSIONS[name];
   const renderCell = (palletType: PalletStatusTypeForHook) => {
      const isSumCell =
         palletType !== 'sumOfAvailablePallets' && palletType !== 'sumOfReturnPallets';
      switch (mode) {
         case 'edit': {
            return handleChangeValue && isSumCell ? (
               <div className={styles.inputContainer}>
                  <StyledTextInput
                     type="number"
                     value={transferPalletsValues[palletType]}
                     className={styles.input}
                     error={!!error[palletType] && touched[palletType]}
                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setTransferPalletsValues(prev => ({
                           ...prev,
                           [palletType]: event.target.value,
                        }));
                        handleChangeValue(rowIndex, palletType, event.target.value);
                     }}
                  />
               </div>
            ) : (
               <span className={styles.editCellValue}>{editCellValue(palletType)}</span>
            );
         }
         case 'transfer': {
            const isValueChanged =
               initialAvailablePallets &&
               transferPalletsValues[palletType as keyof typeof transferPalletsValues] !==
                  initialAvailablePallets[palletType];

            return handleChangeValue && isSumCell ? (
               <div className={styles.inputContainer}>
                  <TextInputWithChangedState
                     type="number"
                     value={transferPalletsValues[palletType]}
                     isValueChanged={isValueChanged}
                     iconPosition="left"
                     className={styles.input}
                     error={!!error[palletType] && touched[palletType]}
                     onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setTransferPalletsValues(prev => ({
                           ...prev,
                           [palletType]: event.target.value,
                        }));
                        handleChangeValue(rowIndex, palletType, event.target.value);
                     }}
                  />
               </div>
            ) : (
               getTransferAvailablePalletsSumValue(transferPalletsValues)
            );
         }
         default:
            break;
      }
   };

   const configurationTransferPallets: ConfigurationEditBalanceAll[] = [
      {
         amount: transferPalletsValues.validAvailablePallets,
         palletType: 'validAvailablePallets',
      },
      {
         amount: transferPalletsValues.invalidAvailablePallets,
         palletType: 'invalidAvailablePallets',
      },
      {
         palletType: 'sumOfAvailablePallets',
      },
   ];
   const configurationEditBalanceAll: ConfigurationEditBalanceAll[] = [
      {
         amount: returnPallets?.validReturnPallets,
         palletType: 'validReturnPallets',
      },
      {
         amount: returnPallets?.invalidReturnPallets,
         palletType: 'invalidReturnPallets',
      },
      {
         palletType: 'sumOfReturnPallets',
         additionalClass: true,
      },
      {
         amount: availablePallets?.validAvailablePallets,
         palletType: 'validAvailablePallets',
      },
      {
         amount: availablePallets?.invalidAvailablePallets,
         palletType: 'invalidAvailablePallets',
      },
      {
         palletType: 'sumOfAvailablePallets',
      },
   ];

   const configuration = returnPallets ? configurationEditBalanceAll : configurationTransferPallets;

   return (
      <TableRow className={styles.tableRow}>
         <TableCell>
            <div className={styles.nameCell}>
               <p className={styles.paletteType}>{firstCell.label}</p>
               <p className={styles.paletteDimensions}>{firstCell.dimensions}</p>
            </div>
         </TableCell>
         {configuration.map(({ palletType, additionalClass }, index) => (
            <TableCell
               key={index}
               align="right"
               className={classNames(styles.cellWithoutArrow, {
                  [styles.cellGap]: additionalClass,
               })}
            >
               {renderCell(palletType)}
            </TableCell>
         ))}
      </TableRow>
   );
};

export default memo(DistributionCenterPalletTableItem);
