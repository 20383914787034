import { useCallback, useEffect, useState } from 'react';

import { Loader } from '@mantine/core';

import DistributionCenterPallets from 'components/DistributionCenterPallets/DistributionCenterPallets';
import { distributionCenterEmptyObject } from 'constants/commercialNetworksFormDefaultValues';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import WithAuth from 'hoc/withAuth';
import { BannerType, BannerVariants, DistributionCenter } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook/components/Banner/Banner';
import { getFormattedDate } from 'utils/functions';
import { transformObjectPalletToArray } from 'utils/functions/transformObjectPalletToArray';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './PalletBalancePage.module.css';

const PalletBalancePage = () => {
   const [banner, setBanner] = useState<BannerType | null>(null);
   const [shouldShowInnerBanner, setShouldShowInnerBanner] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [distributionCenter, setDistributionCenter] = useState<DistributionCenter>(
      distributionCenterEmptyObject,
   );

   const showBanner = (variant: BannerVariants, description: string) =>
      setBanner({ variant, description });

   const fetchDitributionCenter = useCallback(() => {
      HTTPService.getUserDistributionCenterInformation()
         .then(response => {
            const distributionCenterInformation: DistributionCenter = {
               ...response.data,
               todayBalance: {
                  ...response.data.todayBalance,
                  balance: transformObjectPalletToArray(response.data.todayBalance.balance),
               },
            };
            setDistributionCenter(distributionCenterInformation);
         })
         .catch(error => {
            logNetworkError(error);
            showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, []);
   useEffect(() => {
      fetchDitributionCenter();
   }, [fetchDitributionCenter]);

   const handleDataChange = (date: Date) => {
      const dateFormatted = getFormattedDate(date);
      HTTPService.getUserDistributionCenterBalance(dateFormatted)
         .then(response => {
            setDistributionCenter(prevState => ({
               ...prevState,
               todayBalance: {
                  ...prevState.todayBalance,
                  balance: transformObjectPalletToArray(response.data.balance),
                  date: response.data.date,
                  id: response.data.id,
               },
            }));
         })
         .catch(error => {
            logNetworkError(error);
            showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
         });
   };

   return (
      <div className={styles.container}>
         {isLoading ? (
            <div className={styles.loader}>
               <Loader color={'var(--light-green)'} />
            </div>
         ) : (
            <>
               {banner && (
                  <Banner
                     className={styles.banner}
                     variant={banner?.variant}
                     children={banner?.description}
                     fullWidth
                  />
               )}
               <DistributionCenterPallets
                  distributionCenter={distributionCenter!}
                  handleDataChange={handleDataChange}
                  shouldShowBanner={shouldShowInnerBanner}
                  setShowBanner={setShouldShowInnerBanner}
                  mode="DISTRIBUTION-CENTER"
               />
            </>
         )}
      </div>
   );
};

export default WithAuth(PalletBalancePage, 'ROLE_DISTRIBUTION_CENTER');
