import {
   ChangeEvent,
   forwardRef,
   useCallback,
   useEffect,
   useImperativeHandle,
   useRef,
   useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { Search } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import CommercialNetworkTableItem from 'components/CommercialNetworkTableItem/CommercialNetworkTableItem';
import {
   NoItemsNotificationWithLink,
   StyledTable,
   StyledTextInput,
   WhiteCard,
} from 'components/shared';
import { AddOrEditDistributionCenterModal } from 'components/SupplierPanel';
import { COMMERCIAL_NETWORK_PAGE_BANNERS } from 'constants/banners';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_FIRST_PAGE, PAGINATION_SIZE } from 'constants/pagination';
import { commercialNetworkTableHeaders } from 'constants/tableHeaders';
import { usePagination } from 'hooks/usePagination';
import {
   BannerData,
   CommercialNetwork,
   CommercialNetworkDetailsDTO,
   DistributionCenterDTO,
} from 'interfaces';
import RemoveCommercialNetworkModal from 'pages/SupplierPanel/RemoveCommercialNetworkModal/RemoveCommercialNetworkModal';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { resetLocationState } from 'utils/functions/resetLocationState';

import styles from './CommercialNetworkPage.module.css';

type CommercialNetworkPageProps = {
   openAddStorage: () => void;
};

const CommercialNetworkPage = forwardRef(({ openAddStorage }: CommercialNetworkPageProps, ref) => {
   const [commercialNetworks, setCommercialNetworks] = useState<CommercialNetworkDetailsDTO[]>([]);
   const [last, setLast] = useState<boolean>(false);
   const [search, setSearch] = useState<string>('');
   const [debouncedSearchValue] = useDebouncedValue(search, DEBOUNCE_DELAY);
   const [firstNetworkFetchLoading, setFirstNetworkFetchLoading] = useState(true);
   const [commercialNetworkToAddDistributionCenter, setCommercialNetworkToAddDistributionCenter] =
      useState<CommercialNetwork | null>(null);
   const [openRemoveModal, setOpenRemoveModal] = useState(false);
   const [selectedCommercialNetwork, setSelectedCommercialNetwork] =
      useState<CommercialNetworkDetailsDTO>();
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const location = useLocation();

   useImperativeHandle(ref, () => ({
      reloadChild: () => fetchCommercialNetworks(PAGINATION_FIRST_PAGE),
   }));

   const tableContainer = useRef<HTMLDivElement>(null);

   const fetchCommercialNetworks = useCallback((page: number, filter?: string) => {
      HTTPService.getCommercialNetworks(
         filter ? { nameOrStreetOrCityOrZipcodeSearch: filter } : undefined,
         { size: PAGINATION_SIZE, page },
      )
         .then(({ data }) => {
            setCommercialNetworks(prevValue =>
               page === PAGINATION_FIRST_PAGE ? data.content : [...prevValue, ...data.content],
            );
            setLast(data.last);
         })
         .catch(handleFetchCommercialNetworksError)
         .finally(() => setFirstNetworkFetchLoading(false));
   }, []);

   const handleFetchCommercialNetworksError = (error: unknown) =>
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchCommercialNetworks,
      filter: debouncedSearchValue,
   });

   const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
      setSearch(event.target.value);

   const handleAddDistributionCenter = (
      commercialNetworkId: string,
      resData: DistributionCenterDTO,
   ) => {
      setCommercialNetworks(prevState => {
         const index = prevState.findIndex(network => network.id === commercialNetworkId);
         const actual = cloneDeep(prevState);
         actual[index].addresses.push(resData);
         return actual;
      });
      setBannerData(COMMERCIAL_NETWORK_PAGE_BANNERS.ADD_DISTRIBUTION_CENTER_SUCCESS);
   };
   const onAddDistributionCenter = (network: CommercialNetworkDetailsDTO) => {
      setCommercialNetworkToAddDistributionCenter({
         id: network.id,
         name: network.name,
      });
   };
   const handleRemoveCommercialNetwork = (id: string) => {
      setOpenRemoveModal(true);
      const searchedCommercialNetworkById = commercialNetworks.find(
         commercialNetwork => commercialNetwork.id === id,
      );
      setSelectedCommercialNetwork(searchedCommercialNetworkById);
   };

   const onSuccessDelete = () => {
      setBannerData(COMMERCIAL_NETWORK_PAGE_BANNERS.DELETE_COMMERCIAL_NETWORK);
      fetchCommercialNetworks(PAGINATION_FIRST_PAGE);
   };

   useEffect(() => {
      const { successRemoveDistribution } = (location.state || {}) as {
         successRemoveDistribution: boolean;
      };
      if (successRemoveDistribution) {
         setBannerData(COMMERCIAL_NETWORK_PAGE_BANNERS.DELETE_DISTRIBUTION_CENTER);
         resetLocationState();
      }
   }, [location.state]);

   return (
      <>
         <AddOrEditDistributionCenterModal
            commercialNetwork={commercialNetworkToAddDistributionCenter}
            opened={!!commercialNetworkToAddDistributionCenter}
            onClose={() => setCommercialNetworkToAddDistributionCenter(null)}
            onSuccess={handleAddDistributionCenter}
            mode="add"
         />
         {selectedCommercialNetwork && (
            <RemoveCommercialNetworkModal
               opened={openRemoveModal}
               onClose={() => setOpenRemoveModal(false)}
               selectedItem={selectedCommercialNetwork}
               onSuccessDelete={onSuccessDelete}
               mode="CN"
            />
         )}
         {bannerData && (
            <Banner
               variant={bannerData.variant}
               className={styles.banner}
               children={bannerData.description}
               fullWidth
               withCloseIcon
               onClose={() => setBannerData(null)}
            />
         )}
         <div className={styles.headerContainer}>
            <StyledTextInput
               type="text"
               value={search}
               placeholder="Wpisz nazwę, ulicę, kod pocztowy lub miasto"
               leftIcon={<Search />}
               className={styles.searchInput}
               onChange={handleSearchChange}
            />
         </div>
         <WhiteCard className={styles.tableContainer}>
            <>
               <div id="tableContainerId" ref={tableContainer} className={styles.tableContainer}>
                  <InfiniteScroll
                     dataLength={commercialNetworks.length}
                     next={handleLoadNextItems}
                     hasMore={!last && !firstNetworkFetchLoading}
                     loader={
                        <div className={styles.loader}>
                           <Loader color={'var(--primary-green)'} />
                        </div>
                     }
                     scrollableTarget="tableContainerId"
                  >
                     <StyledTable
                        columnHeaders={commercialNetworkTableHeaders}
                        headerClassName={styles.tableHeader}
                        className={styles.networkTable}
                     >
                        {commercialNetworks.map(network => (
                           <CommercialNetworkTableItem
                              key={network.id}
                              network={network}
                              onAddDistributionCenter={() => onAddDistributionCenter(network)}
                              onRemoveCommercialNetwork={handleRemoveCommercialNetwork}
                           />
                        ))}
                     </StyledTable>
                  </InfiniteScroll>
                  {commercialNetworks.length === 0 && !firstNetworkFetchLoading && last && (
                     <NoItemsNotificationWithLink
                        icon={<PalletsStackIcon />}
                        desctiption="Nie ma jeszcze żadnych sieci handlowych."
                        linkText="Dodaj sieć handlową"
                        onClick={openAddStorage}
                     />
                  )}
               </div>
               {firstNetworkFetchLoading && (
                  <div className={classNames(styles.loader, styles.mainTableLoader)}>
                     <Loader color={'var(--primary-green)'} />
                  </div>
               )}
            </>
         </WhiteCard>
      </>
   );
});
export default CommercialNetworkPage;
