import { useCallback, useEffect, useState } from 'react';

type PaginationProps<T> = {
   fetchFunction: (currentPage: number, filter?: T) => void;
   filter?: T;
};

export const usePagination = <T,>({ fetchFunction, filter }: PaginationProps<T>) => {
   const [currentPage, setCurrentPage] = useState(0);

   useEffect(() => {
      fetchFunction(0, filter);
      setCurrentPage(1);
   }, [fetchFunction, filter]);

   const handleLoadNextItems = useCallback(() => {
      fetchFunction(currentPage, filter);
      setCurrentPage(currentPage + 1);
   }, [currentPage, fetchFunction, filter]);

   return {
      setCurrentPage,
      handleLoadNextItems,
      currentPage,
   };
};
