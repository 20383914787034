import { memo, useState } from 'react';

import { LoadingOverlay } from '@mantine/core';

import { externalStorageTableHeaders, internalStorageTableHeaders } from 'constants/tableHeaders';
import { StorageWithDate } from 'interfaces';

import { StyledTable, WhiteCard } from '../shared';
import StorageHeader from '../StorageHeader/StorageHeader';
import styles from '../StoragesList/StoragesList.module.css';
import StorageTableItem from '../StorageTableItem/StorageTableItem';

type StorageItemProps = {
   storage: StorageWithDate;
   handleDateChange: (value: Date | null, id: string, onFinish: () => void) => void;
   handleDeleteClick?: () => void;
   handleEditClick?: () => void;
   internal?: boolean;
};

const StorageItem = ({
   storage,
   handleDateChange,
   handleDeleteClick,
   handleEditClick,
   internal,
}: StorageItemProps) => {
   const [isLoading, setIsLoading] = useState(false);

   const dateChangeHandler = (value: Date | null, id: string) => {
      setIsLoading(true);
      handleDateChange(value, id, () => setIsLoading(false));
   };

   return (
      <WhiteCard>
         <div className={styles.containerPadding}>
            <StorageHeader
               id={storage.id}
               key={storage.id}
               address={storage.address}
               date={storage.date}
               handleDateChange={dateChangeHandler}
               handleDeleteClick={handleDeleteClick}
               handleEditClick={handleEditClick}
            />
            <StyledTable
               columnHeaders={internal ? internalStorageTableHeaders : externalStorageTableHeaders}
            >
               {storage?.balanceForToday &&
                  Object.entries(storage.balanceForToday).map(([name, values]) => (
                     <StorageTableItem
                        key={name + storage.id}
                        name={name}
                        readyToRent={values.readyToRent}
                        damaged={values.damaged}
                        lost={values.lost}
                        internal={internal}
                     />
                  ))}
            </StyledTable>
         </div>
         <LoadingOverlay
            visible={isLoading}
            overlayBlur={10}
            radius="md"
            zIndex="1"
            loaderProps={{ color: 'var(--light-green)' }}
         />
      </WhiteCard>
   );
};

export default memo(StorageItem);
