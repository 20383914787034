import { forwardRef, useEffect, useState } from 'react';

import { Autocomplete, SelectItemProps } from '@mantine/core';
import classNames from 'classnames';

import { ChevronDownIcon } from 'assets';
import { SectionContainer } from 'components/shared';
import { UserOption } from 'interfaces';

import styles from './SelectClientSection.module.css';

interface AutocompleteItemProps extends SelectItemProps {
   address: string;
   zipCode: string;
   city: string;
   value: string;
}

type SelectClientSectionProps = {
   selectedClient: string;
   clientOptions: UserOption[];
   onChangeClient: (userId: string) => void;
   error?: string;
   touched?: boolean;
};

const SelectClientSection = ({
   selectedClient,
   clientOptions,
   onChangeClient,
   error,
   touched,
}: SelectClientSectionProps) => {
   const [enteredText, setEnteredText] = useState(selectedClient);

   useEffect(() => {
      setEnteredText(selectedClient);
   }, [selectedClient]);

   const AutoCompleteItem = forwardRef<HTMLDivElement, AutocompleteItemProps>(
      ({ value, label, address, zipCode, city }: AutocompleteItemProps, ref) => (
         <div
            ref={ref}
            onClick={() => {
               onChangeClient(value);
               setEnteredText(`${label}, ${address}, ${zipCode} ${city}`);
            }}
            className={styles.option}
         >
            <span className={styles.mainText}>{`${label}, `}</span>
            <span className={styles.additionalText}> {`${address}, `}</span>
            <span className={styles.additionalText}>{`${zipCode} `}</span>
            <span className={styles.additionalText}>{`${city}`}</span>
         </div>
      ),
   );

   return (
      <SectionContainer title="Klient">
         <Autocomplete
            label="Wybierz klienta"
            data={clientOptions}
            onChange={setEnteredText}
            value={enteredText}
            filter={(value, item) => item.label.toLowerCase().includes(value.toLowerCase().trim())}
            itemComponent={AutoCompleteItem}
            limit={Number.MAX_SAFE_INTEGER}
            rightSection={<ChevronDownIcon />}
            classNames={{
               root: styles.autocompleteContainer,
               label: styles.autocompleteLabel,
               input: classNames(styles.autocompleteInput, { [styles.error]: error && touched }),
               itemsWrapper: styles.autocompleteDropdownWrapper,
            }}
         />
         {error && touched && <p className={styles.errorText}>{error}</p>}
      </SectionContainer>
   );
};

export default SelectClientSection;
