import { SendPalletsExcelRow } from '../types';

export const mergeSendPalletsExcelRowsWithSamePalletType: (
   sendPalletsExcelRowsWithSamePalletType: SendPalletsExcelRow[],
) => SendPalletsExcelRow = sendPalletsExcelRowsWithSamePalletType => {
   const sendPalletsExcelRowsPalletsAmount = sendPalletsExcelRowsWithSamePalletType.reduce(
      (totalPalletsAmount, currentSendPalletsExcelRow) =>
         totalPalletsAmount + Number(currentSendPalletsExcelRow.palletsAmount),
      0,
   );
   return {
      ...sendPalletsExcelRowsWithSamePalletType[0],
      palletsAmount: sendPalletsExcelRowsPalletsAmount.toString(),
   };
};
