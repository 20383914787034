import classNames from 'classnames';
import { Trash } from 'tabler-icons-react';

import {
   ImageUploadInput,
   StyledButton,
   StyledRadioButton,
   StyledSelect,
   StyledTextInput,
} from 'components/shared';
import { DAMAGE_PALLETS_IMAGES_NUMBER_LIMIT } from 'constants/damagePalletsImagesNumberLimit';
import { LostDamagedPalletsItem } from 'interfaces';
import { DamageType, Option } from 'interfaces/commonInterfacesAndTypes';
import { ButtonGroup, TableCell, TableRow } from 'storybook';

import styles from './LostDamagedPalletsMainRow.module.css';

type LostDamagedPalletsMainRowProps = {
   damageType: DamageType;
   availablePalletTypes: Option[];
   lostDamagedPalletsItem: LostDamagedPalletsItem;
   lostDamagedPalletsItemTouched: Record<string, boolean>;
   lostDamagedPalletsItemErrors: Record<string, string | boolean>;
   isAvailableDamageAsDamageType: boolean;
   isAvailableLostOrDestructionAsDamageType: boolean;
   onChangeLostDamagedPalletsItem: (
      field: string,
      value: string | string[] | Option | null,
   ) => void;
   onRemoveLostDamagedPalletsItem?: () => void;
};

const LostDamagedPalletsMainRow = ({
   damageType,
   availablePalletTypes,
   lostDamagedPalletsItem,
   lostDamagedPalletsItemTouched,
   lostDamagedPalletsItemErrors,
   isAvailableDamageAsDamageType,
   isAvailableLostOrDestructionAsDamageType,
   onChangeLostDamagedPalletsItem,
   onRemoveLostDamagedPalletsItem,
}: LostDamagedPalletsMainRowProps) => {
   const handleChangePalletType = (value: Option) => {
      onChangeLostDamagedPalletsItem('palletType', value);
      if (lostDamagedPalletsItem.damageType) {
         onChangeLostDamagedPalletsItem('damageType', null);
      }
   };

   return (
      <TableRow className={styles.row}>
         <TableCell className={styles.cell}>
            <StyledSelect
               options={availablePalletTypes}
               onChange={handleChangePalletType}
               value={lostDamagedPalletsItem.palletType}
               error={
                  lostDamagedPalletsItemTouched?.palletType &&
                  !!lostDamagedPalletsItemErrors?.palletType
               }
               classNames={{ select: styles.selectPalletType }}
               portalTarget={document.body}
            />
         </TableCell>
         <TableCell className={classNames(styles.cell, styles.damageTypeCell)}>
            <ButtonGroup>
               <StyledRadioButton
                  label="Zgubienie lub zniszczenie"
                  checked={lostDamagedPalletsItem.damageType === 'LOST_OR_DESTRUCTION'}
                  onChangeHandler={() =>
                     onChangeLostDamagedPalletsItem('damageType', 'LOST_OR_DESTRUCTION')
                  }
                  disabled={!isAvailableLostOrDestructionAsDamageType}
                  error={
                     lostDamagedPalletsItemTouched?.damageType &&
                     !!lostDamagedPalletsItemErrors?.damageType
                  }
                  buttonView
               />
               <StyledRadioButton
                  label="Uszkodzenie"
                  checked={lostDamagedPalletsItem.damageType === 'DAMAGE'}
                  onChangeHandler={() => onChangeLostDamagedPalletsItem('damageType', 'DAMAGE')}
                  disabled={!isAvailableDamageAsDamageType}
                  error={
                     lostDamagedPalletsItemTouched?.damageType &&
                     !!lostDamagedPalletsItemErrors?.damageType
                  }
                  buttonView
               />
            </ButtonGroup>
         </TableCell>
         <TableCell className={classNames(styles.cell, styles.palletsAmountCell)}>
            <StyledTextInput
               type="number"
               value={lostDamagedPalletsItem.palletsAmount}
               onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeLostDamagedPalletsItem('palletsAmount', event.target.value)
               }
               error={
                  lostDamagedPalletsItemTouched?.palletsAmount &&
                  !!lostDamagedPalletsItemErrors?.palletsAmount
               }
               className={styles.palletsAmountInput}
            />
         </TableCell>
         {damageType === 'LOST_DAMAGED_BY_SUPPLIER' &&
            lostDamagedPalletsItem.imageURLs &&
            lostDamagedPalletsItem.images && (
               <TableCell className={classNames(styles.cell, styles.uploadImagesCell)}>
                  <ImageUploadInput
                     onChange={(files64: string[], imageURLs: string[]) => {
                        onChangeLostDamagedPalletsItem('images', files64);
                        onChangeLostDamagedPalletsItem('imageURLs', imageURLs);
                     }}
                     imageURLs={lostDamagedPalletsItem.imageURLs}
                     images={lostDamagedPalletsItem.images}
                     imageNumberLimit={DAMAGE_PALLETS_IMAGES_NUMBER_LIMIT}
                     className={styles.uploadImagesBtn}
                  />
               </TableCell>
            )}
         <TableCell className={classNames(styles.cell, styles.removeRowBtnCell)}>
            {onRemoveLostDamagedPalletsItem && (
               <StyledButton
                  type="button"
                  onClick={onRemoveLostDamagedPalletsItem}
                  icon={<Trash />}
                  variant="text"
                  className={styles.removeRowBtn}
               />
            )}
         </TableCell>
      </TableRow>
   );
};

export default LostDamagedPalletsMainRow;
