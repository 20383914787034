import { FiltersModalProps } from 'components/shared';
import { TransferActionsOverviewFiltersForm, UserRole } from 'interfaces';

import ClientPalletsTransferHistoryFiltersModal from './ClientPalletsTransferHistoryFiltersModal/ClientPalletsTransferHistoryFiltersModal';
import DistributionCenterAndStoragePalletsTransferHistoryFiltersModal from './DistributionCenterAndStoragePalletsTransferHistoryFiltersModal/DistributionCenterAndStoragePalletsTransferHistoryFiltersModal';
import SupplierPalletsTransferHistoryFiltersModal from './SupplierPalletsTransferHistoryFiltersModal/SupplierPalletsTransferHistoryFiltersModal';

export type PalletsTransferHistoryFiltersModalProps = Pick<
   FiltersModalProps,
   'opened' | 'onClose'
> & {
   mode: UserRole;
   initialFormData: TransferActionsOverviewFiltersForm;
   onSubmit: (filters: TransferActionsOverviewFiltersForm) => void;
};

const PalletsTransferHistoryFiltersModal = ({
   mode,
   opened,
   initialFormData,
   onSubmit,
   onClose,
}: PalletsTransferHistoryFiltersModalProps) => {
   if (mode === 'ROLE_SYSTEM_ADMIN') {
      return (
         <SupplierPalletsTransferHistoryFiltersModal
            opened={opened}
            initialFormData={initialFormData}
            onSubmit={onSubmit}
            onClose={onClose}
         />
      );
   }
   if (mode === 'ROLE_CLIENT') {
      return (
         <ClientPalletsTransferHistoryFiltersModal
            opened={opened}
            initialFormData={initialFormData}
            onSubmit={onSubmit}
            onClose={onClose}
         />
      );
   }
   return (
      <DistributionCenterAndStoragePalletsTransferHistoryFiltersModal
         opened={opened}
         initialFormData={initialFormData}
         onSubmit={onSubmit}
         onClose={onClose}
      />
   );
};

export default PalletsTransferHistoryFiltersModal;
