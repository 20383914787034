import Layout from 'components/layout/MainLayout/Layout';
import {
   DISTRIBUTION_CENTER_ORDER_DETAILS_PATH,
   DISTRIBUTION_CENTER_PALLET_BALANCE,
   DISTRIBUTION_CENTER_PALLET_PICKUPS,
   DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
} from 'constants/routes';
import {
   OrderDetailsPage,
   PalletsBalancePage,
   PalletsPickUpsPage,
   PalletsTransferHistoryPage,
} from 'pages/DistributionCenterPanel';

export const distributionCenterRoutes = {
   element: <Layout />,
   children: [
      { path: DISTRIBUTION_CENTER_PALLET_PICKUPS, element: <PalletsPickUpsPage /> },
      { path: DISTRIBUTION_CENTER_PALLET_BALANCE, element: <PalletsBalancePage /> },
      { path: DISTRIBUTION_CENTER_ORDER_DETAILS_PATH(':orderId'), element: <OrderDetailsPage /> },
      {
         path: DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY,
         element: <PalletsTransferHistoryPage />,
      },
   ],
};
