import { PALLET_TYPES } from 'constants/palletTypes';
import { PalletBalance } from 'interfaces';

export const sumPalletsBalances = (
   firstPalletsBalance: PalletBalance,
   secondPalletsBalance: PalletBalance,
) => {
   const edhpPalletType = PALLET_TYPES.EDHP_PALLET;
   const euroPalletType = PALLET_TYPES.EURO_PALLET;
   const halfPalletNDHPType = PALLET_TYPES.HALF_PALLET_NDHP;
   const quarterPalletCDHPType = PALLET_TYPES.QUARTER_PALLET_CDHP;
   const newPalletsBalance = { ...firstPalletsBalance };
   newPalletsBalance[edhpPalletType] += secondPalletsBalance[edhpPalletType];
   newPalletsBalance[euroPalletType] += secondPalletsBalance[euroPalletType];
   newPalletsBalance[halfPalletNDHPType] += secondPalletsBalance[halfPalletNDHPType];
   newPalletsBalance[quarterPalletCDHPType] += secondPalletsBalance[quarterPalletCDHPType];
   return newPalletsBalance;
};
