import { ChangeEvent, MouseEvent as ReactMouseEvent } from 'react';

import classNames from 'classnames';

import styles from './Checkbox.module.css';

export interface CheckboxProps {
   id?: string;
   name?: string;
   checked: boolean;
   label?: string;
   onChangeHandler: (event: ReactMouseEvent & ChangeEvent<HTMLInputElement>) => void;
   error?: boolean;
   className?: string;
   disabled?: boolean;
   indeterminate?: boolean;
}

export const Checkbox = ({
   id,
   name,
   checked,
   label,
   onChangeHandler,
   error,
   className,
   disabled,
   indeterminate,
}: CheckboxProps) => {
   return (
      <div
         {...(!disabled ? { onClick: onChangeHandler } : {})}
         className={classNames(styles.checkbox, className, {
            [styles.error]: error,
            [styles.disabled]: disabled,
            [styles.indeterminate]: indeterminate,
         })}
      >
         <input
            disabled={disabled}
            id={id}
            name={name}
            checked={checked}
            type="checkbox"
            readOnly
         />
         <label>{label}</label>
      </div>
   );
};
