import { ImagesContainer } from 'components/shared';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { REPORT_TYPE_LABELS } from 'constants/reportTypes';
import { DamagedPalletDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

type DamagedLostPalletsTableSubRowProps = {
   damagedPalletsItem: DamagedPalletDTO;
   isReceivedDamaged: boolean;
};

const DamagedLostPalletsTableSubRow = ({
   damagedPalletsItem,
   isReceivedDamaged,
}: DamagedLostPalletsTableSubRowProps) => (
   <TableRow>
      <TableCell />
      <TableCell />
      {isReceivedDamaged && (
         <TableCell>{REPORT_TYPE_LABELS[damagedPalletsItem.reportType]}</TableCell>
      )}
      <TableCell>{PALLET_TYPE_LABELS[damagedPalletsItem.type]}</TableCell>
      <TableCell align="right">{damagedPalletsItem.amount}</TableCell>
      {isReceivedDamaged && (
         <TableCell>
            <ImagesContainer imageURLs={damagedPalletsItem.images} />
         </TableCell>
      )}
   </TableRow>
);

export default DamagedLostPalletsTableSubRow;
