import { Loader } from '@mantine/core';

import styles from './LoadingPage.module.css';

const LoadingPage = () => (
   <div className={styles.container}>
      <Loader color={'var(--light-green)'} />
   </div>
);

export default LoadingPage;
