import { useNavigate } from 'react-router-dom';

import { ClientForm } from 'components/SupplierPanel';
import {
   clientDataFormInitialValues,
   palletTypesFormInitialValues,
   ratesConfigurationFormInitialValues,
} from 'constants/clientFormStepsInitialValues';
import { COMMERCIAL_NETWORK_DEFAULT_ITEM } from 'constants/commercialNetworksFormDefaultValues';
import { SUPPLIER_CLIENTS } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import {
   ClientDataFormProps,
   CommercialNetworkItem,
   CreateCommercialNetworkAddressesDTO,
   PalletTypesFormProps,
   RateConfigForm,
} from 'interfaces';
import { HTTPService, rateConfigFormToRateConfigDTOMapper } from 'service';
import { logNetworkError } from 'utils/logNetworkError';
import { parsePalletsPricingPerAddressFormDataIntoClientDeliveryAddress } from 'utils/parsers';

const AddClientPage = () => {
   const navigate = useNavigate();

   const handleAddClient = (
      clientData: ClientDataFormProps,
      palletTypes: PalletTypesFormProps,
      rateConfigData: RateConfigForm,
      commercialNetworksData: CommercialNetworkItem[],
      onFinish: () => void,
      onError: (error: unknown) => void,
   ) => {
      const commercialNetworks: CreateCommercialNetworkAddressesDTO[] = commercialNetworksData.map(
         commercialNetworksDataItem => ({
            commercialNetworkId: commercialNetworksDataItem.name.value,
            distributionCenterIds: commercialNetworksDataItem.addresses.map(
               address => address.value,
            ),
         }),
      );
      const clientDeliveryAddresses = rateConfigData.palletRates.perAddress.map(
         palletRatesPerAddress =>
            parsePalletsPricingPerAddressFormDataIntoClientDeliveryAddress(
               palletRatesPerAddress,
               clientData.deliveryAddresses,
            ),
      );
      const rateConfig = rateConfigFormToRateConfigDTOMapper(rateConfigData);
      HTTPService.createClient({
         ...clientData,
         nip: clientData.nip.replaceAll('-', ''),
         deliveryAddresses: clientDeliveryAddresses,
         ...palletTypes,
         rateConfig,
         commercialNetworks,
      })
         .then(() => navigate(SUPPLIER_CLIENTS, { state: { successCreateClient: true } }))
         .catch(error => {
            onError(error);
            logNetworkError(error);
         })
         .finally(() => onFinish());
   };

   const commercialNetworksFormInitialValues = [COMMERCIAL_NETWORK_DEFAULT_ITEM()];

   return (
      <ClientForm
         commercialNetworksFormInitialValues={commercialNetworksFormInitialValues}
         clientDataFormInitialValues={clientDataFormInitialValues}
         palletTypesFormInitialValues={palletTypesFormInitialValues}
         ratesConfigurationFormInitialValues={ratesConfigurationFormInitialValues}
         onSubmit={handleAddClient}
      />
   );
};

export default WithAuth(AddClientPage, 'ROLE_SYSTEM_ADMIN');
