import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Eye } from 'tabler-icons-react';

import { GoToIcon } from 'assets';
import { REPORT_STATUSES_AND_LABELS } from 'constants/reportStatuses';
import { REPORT_TYPES } from 'constants/reportTypes';
import { SUPPLIER_ORDERS_DETAILS, SUPPLIER_PALLETS_DETAILS } from 'constants/routes';
import { PalletReportDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';
import { formatISODate } from 'utils/functions';

import { StyledBadge, StyledButton } from '../shared';

import styles from './DamagedLostPalletTableItem.module.css';

type DamagedLostPalletTableItemProps = {
   pallet: PalletReportDTO;
};

const TypeMap = {
   DAMAGE: 'Uszkodzenie',
   LOST_OR_DESTRUCTION: 'Zniszczenie / zgubienie',
};

const DamageType = (types?: string[]) => {
   if (!types?.length) {
      return;
   }
   const damagedType = types.some(type => type === REPORT_TYPES.DAMAGE);
   const lostType = types.some(type => type === REPORT_TYPES.LOST_OR_DESTRUCTION);
   if (damagedType && lostType) {
      return 'Zniszczenie / zgubienie i uszkodzenie';
   } else {
      return TypeMap[types[0] as keyof typeof DamageType];
   }
};

const DamagedLostPalletTableItem = ({ pallet }: DamagedLostPalletTableItemProps) => (
   <TableRow key={pallet.id} className={styles.tableRow}>
      <TableCell className={styles.tableCell}>
         {!pallet.read && <StyledBadge variant="NEW" text="nowe" />}
      </TableCell>
      <TableCell className={classNames({ [styles.bold]: !pallet.read })}>
         <Link to={SUPPLIER_ORDERS_DETAILS(pallet.orderId)}>
            <div className={styles.goToIcon}>
               {pallet.orderId}
               <GoToIcon />
            </div>
         </Link>
      </TableCell>
      <TableCell className={classNames({ [styles.bold]: !pallet.read }, styles.tableCell)}>
         {pallet.assignedUser.companyName}
      </TableCell>
      <TableCell className={classNames({ [styles.bold]: !pallet.read }, styles.tableCell)}>
         {formatISODate(pallet.createdDate)}
      </TableCell>
      <TableCell>
         <StyledBadge
            variant={pallet.status!}
            text={REPORT_STATUSES_AND_LABELS[pallet.status!].label}
         />
      </TableCell>
      <TableCell
         className={classNames({ [styles.bold]: !pallet.read }, styles.tableCell)}
         align="right"
      >
         {pallet?.totalPallets}
      </TableCell>
      <TableCell className={classNames({ [styles.bold]: !pallet.read }, styles.tableCell)}>
         {DamageType(pallet?.palletReportTypes)}
      </TableCell>
      <TableCell className={styles.tableCell}>
         <div className={styles.actionContainer}>
            <Link to={SUPPLIER_PALLETS_DETAILS(pallet.id)}>
               <StyledButton
                  type="button"
                  text="Szczegóły"
                  icon={<Eye />}
                  variant="text"
                  className={styles.actionBtn}
               />
            </Link>
         </div>
      </TableCell>
   </TableRow>
);

export default DamagedLostPalletTableItem;
