import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from '@mantine/core';

import { OverviewPalletsConfigurationSection } from 'components/Order';
import { OrderAdditionalInfo } from 'components/OrderDetails';
import OrderDocuments from 'components/OrderDetails/OrderDocuments/OrderDocuments';
import {
   FreeOrderBanner,
   FreeRelatedOrdersBanner,
   RetentionDateExpiredBanner,
} from 'components/Orders';
import StyledBadge from 'components/shared/StyledBadge/StyledBadge';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { ORDER_STATUSES_BADGE_DATA } from 'constants/orderStatuses';
import { useAuth } from 'context/auth/AuthContextProvider';
import WithAuth from 'hoc/withAuth';
import { useOrderDetails } from 'hooks/useOrderDetails';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { BannerData, ClientOrderDetailsPageModalsOrDialogs, OrderDetails } from 'interfaces';
import { CommercialNetworkDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner, Divider, Tab } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { getOrderDetailsData, getOrderIdLabel, scrollToElement } from 'utils/functions';

import RegularOrderDetailsPage from './RegularOrderDetailsPage/RegularOrderDetailsPage';
import { ORDER_STATUS_BANNER_INFO } from './helpers';
import styles from './OrderDetailsPage.module.css';

const OrderDetailsPage = () => {
   const [activeTab, setActiveTab] = useState<'ORDER' | 'DOCUMENTS'>('ORDER');
   const [order, setOrder] = useState<OrderDetails>();
   const [commercialNetworks, setCommercialNetworks] = useState<CommercialNetworkDetailsDTO[]>();
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const { pallets, palletsSum, delayTimeInDaysFromRetentionDate, isRetentionDateExpired } =
      useOrderDetails({
         order,
      });
   const { user: client } = useAuth();
   const params = useParams();
   const orderDetailsHeaderRef = useRef<HTMLDivElement | null>(null);
   const regularOrderDetailsPageRef = useRef<{
      openModal: (selectedModal: ClientOrderDetailsPageModalsOrDialogs) => void;
   }>();
   const { isMobile } = useResponsiveLayout();

   useEffect(() => {
      const fetchData = async () => {
         setIsDataLoading(true);
         if (params.orderId) {
            try {
               const getOrderDetailsResponse = await HTTPService.getOrderDetails(params.orderId);
               const orderDetailsData = getOrderDetailsData(getOrderDetailsResponse.data);
               setOrder(orderDetailsData);
               if (orderDetailsData.mode === 'REGULAR_ORDER') {
                  const getCommercialNetworksResponse =
                     await HTTPService.getUserCommercialNetworks();
                  setCommercialNetworks(getCommercialNetworksResponse.data.commercialNetworks);
               }
            } catch (error) {
               handleFetchDataError(error);
            } finally {
               setIsDataLoading(false);
            }
         }
      };
      fetchData();
   }, [params.orderId]);

   const fetchOrder = useCallback((orderId: string) => {
      HTTPService.getOrderDetails(orderId)
         .then(({ data: orderDetails }) => {
            const orderDetailsData = getOrderDetailsData(orderDetails);
            setOrder(orderDetailsData);
         })
         .catch(handleFetchDataError);
   }, []);

   const handleFetchDataError = (error: unknown) => {
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );
   };

   const handleCloseBanner = useCallback(() => setBannerData(null), []);

   const scrollToTop = useCallback(() => scrollToElement(orderDetailsHeaderRef), []);

   const renderContent = () => {
      if (order && client) {
         if (order.mode === 'REGULAR_ORDER' && commercialNetworks) {
            return (
               <RegularOrderDetailsPage
                  ref={regularOrderDetailsPageRef}
                  order={order}
                  client={client}
                  commercialNetworks={commercialNetworks}
                  pallets={pallets}
                  palletsSum={palletsSum}
                  fetchOrder={fetchOrder}
                  setOrder={setOrder}
                  scrollToTop={scrollToTop}
                  showBanner={setBannerData}
               />
            );
         } else if (order.mode === 'ADDED_TO_NEXT_ORDER') {
            return (
               <OverviewPalletsConfigurationSection
                  pallets={pallets}
                  palletsSum={palletsSum}
                  isClientView
               />
            );
         }
      }
   };

   const isOrderFree = order?.deliveryMethod?.free;

   return (
      <>
         {params.orderId && order && !isDataLoading && (
            <div className={styles.detailsContainer}>
               <div ref={orderDetailsHeaderRef} className={styles.detailsHeader}>
                  <div className={styles.headerLeft}>
                     <div className={styles.orderNumberContainer}>
                        <h2>Zamówienie {getOrderIdLabel(params.orderId)}</h2>
                        <StyledBadge
                           style={{ marginRight: 24 }}
                           text={ORDER_STATUSES_BADGE_DATA[order.status].label}
                           variant={ORDER_STATUSES_BADGE_DATA[order.status].variant}
                        />
                     </div>
                     <OrderAdditionalInfo
                        createdDate={order.createdDate}
                        internalOrderId={order.internalOrderId}
                        onInternalOrderIdChangeClick={() =>
                           regularOrderDetailsPageRef.current?.openModal(
                              'EDIT_INTERNAL_ORDER_ID_MODAL',
                           )
                        }
                     />
                  </div>
                  {order.status === 'DELIVERED' && (
                     <StyledButton
                        variant="outlined-primary"
                        text="Zgłoś zgubione lub uszkodzone palety"
                        className={styles.reportLostDamagedPalletsButton}
                        onClick={() =>
                           regularOrderDetailsPageRef.current?.openModal(
                              'REPORT_DAMAGE_LOSS_PALLETS_MODAL',
                           )
                        }
                     />
                  )}
               </div>
               {!isOrderFree && ORDER_STATUS_BANNER_INFO[order.status] && (
                  <Banner
                     variant="info"
                     children={ORDER_STATUS_BANNER_INFO[order.status]}
                     fullWidth
                     className={styles.banner}
                  />
               )}
               {isOrderFree && (
                  <FreeOrderBanner
                     orderStatus={order.status}
                     isOrderAddedToNextOrder={!!order.deliveryMethod?.addedToNextOrder}
                     isClientView
                     orderAddedToOrderId={order.addedToOrderId}
                     className={styles.banner}
                  />
               )}
               {order.includesReceivedDamagedOrLostPallets && (
                  <Banner
                     variant="info"
                     children="W tym zamówieniu zostały zgłoszone uszkodzone/zniszczone palety z winy NDHP."
                     fullWidth
                     className={styles.banner}
                  />
               )}
               {!!order.relatedAdditionalOrderIDs.length && (
                  <FreeRelatedOrdersBanner
                     freeRelatedOrderIds={order.relatedAdditionalOrderIDs}
                     className={styles.banner}
                     isClientView
                  />
               )}
               {!isMobile && activeTab === 'DOCUMENTS' && (
                  <Banner
                     fullWidth
                     variant="warning"
                     children="Jeśli chcesz dodać nadania paletowe, należy wejść w zakładkę „Zamówienia” poniżej,  znaleźć sekcję  „Nadania” na dole strony i kliknąć przycisk  „Nadaj palety”. Jeśli dodasz ten dokument tutaj, palety nie zostaną rozliczone."
                     className={styles.banner}
                  />
               )}
               {isRetentionDateExpired && (
                  <RetentionDateExpiredBanner
                     role="ROLE_CLIENT"
                     delayTimeInDaysFromRetentionDate={delayTimeInDaysFromRetentionDate}
                  />
               )}
               {bannerData && (
                  <Banner
                     variant={bannerData.variant}
                     title={bannerData.title}
                     children={bannerData.description}
                     onClose={handleCloseBanner}
                     withCloseIcon
                     className={styles.banner}
                     fullWidth
                  />
               )}
               <div className={styles.tabsSection}>
                  <div className={styles.tabsContainer}>
                     <Tab
                        active={activeTab === 'ORDER'}
                        text="Zamówienie"
                        onClick={() => setActiveTab('ORDER')}
                     />
                     <Tab
                        active={activeTab === 'DOCUMENTS'}
                        text="Dokumenty"
                        onClick={() => setActiveTab('DOCUMENTS')}
                     />
                  </div>
                  <Divider className={styles.tabsBorder} variant="horizontal" />
               </div>
               {activeTab === 'ORDER' ? (
                  renderContent()
               ) : (
                  <OrderDocuments displayBanner={setBannerData} orderId={order.id} />
               )}
            </div>
         )}
         {isDataLoading && (
            <div className={styles.loaderContainer}>
               <Loader color="var(--primary-green)" />
            </div>
         )}
      </>
   );
};

export default WithAuth(OrderDetailsPage, 'ROLE_CLIENT');
