import { Modal, ModalProps } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';

import styles from './WarningModal.module.css';

type WarningModalProps = ModalProps & {
   heading: string;
   description: string;
   cancelBtnText: string;
   confirmBtnText: string;
   onConfirm: () => void;
};

const WarningModal = ({
   heading,
   description,
   cancelBtnText,
   confirmBtnText,
   onConfirm,
   ...props
}: WarningModalProps) => (
   <Modal {...props} centered className={styles.modal}>
      <div className={styles.container}>
         <div className={styles.contentContainer}>
            <AlertCircle size={48} color="#FCC100" style={{ marginBottom: '26' }} />
            <h3>{heading}</h3>
            <p>{description}</p>
         </div>
         <div className={styles.btnsContainer}>
            <StyledButton text={cancelBtnText} onClick={props.onClose} variant="outlined-warning" />
            <StyledButton text={confirmBtnText} onClick={onConfirm} variant="filled-warning" />
         </div>
      </div>
   </Modal>
);
export default WarningModal;
