import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { Search } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import DamagedLostPalletTableItem from 'components/DamagedLostPalletTableItem/DamagedLostPalletTableItem';
import { NoItemsNotification, StyledTable, StyledTextInput } from 'components/shared';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_SIZE } from 'constants/pagination';
import { supplierPalletsTableHeaders } from 'constants/tableHeaders';
import WithAuth from 'hoc/withAuth';
import { BannerData, PalletReportDTO } from 'interfaces';
import { HTTPService } from 'service';
import { PaginationResponse } from 'service';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './PalletsPage.module.css';

const PalletsPage = () => {
   const tableContainer = useRef<HTMLDivElement>(null);
   const [pallets, setPallets] = useState<PalletReportDTO[]>([]);
   const [nextPage, setNextPage] = useState<number>(0);
   const [last, setLast] = useState<boolean>(false);
   const [search, setSearch] = useState<string>('');
   const [debouncedSearchValue] = useDebouncedValue(search, DEBOUNCE_DELAY);
   const [firstPalletsFetchLoading, setFirstPalletsFetchLoading] = useState(true);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const fetchPallets = useCallback(
      (page: number) => {
         HTTPService.getReceivedLostOrDamagedPallets(undefined, debouncedSearchValue, {
            size: PAGINATION_SIZE,
            page: page,
         })
            .then(({ data }) => {
               setAfterFetch(data, page);
            })
            .catch(handleFetchPalletsError);
      },
      [debouncedSearchValue],
   );

   const handleFetchPalletsError = (error: unknown) =>
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );

   const setAfterFetch = (data: PaginationResponse<PalletReportDTO>, page = 0) => {
      setPallets(prevState => (page === 0 ? data.content : [...prevState, ...data.content]));
      setNextPage(page + 1);
      setLast(data.last);
      setFirstPalletsFetchLoading(false);
      page === 0 && tableContainer.current && (tableContainer.current.scrollTop = 0);
   };

   useEffect(() => {
      fetchPallets(0);
   }, [fetchPallets]);

   const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) =>
      setSearch(event.target.value);

   const fetchNext = () => {
      fetchPallets(nextPage);
   };

   return (
      <div className={styles.mainContainer}>
         <div className={styles.headerContainer}>
            {bannerData && (
               <Banner
                  fullWidth
                  withCloseIcon
                  className={styles.banner}
                  variant={bannerData.variant}
                  children={bannerData.description}
                  onClose={() => setBannerData(null)}
               />
            )}
            <StyledTextInput
               type="text"
               value={search}
               placeholder="wpisz ID zamówienia lub nazwę firmy"
               leftIcon={<Search />}
               className={styles.searchInput}
               onChange={handleSearchChange}
            />
         </div>
         <div className={styles.tableLoaderContainer}>
            <div id="tableContainerId" ref={tableContainer} className={styles.tableContainer}>
               <InfiniteScroll
                  dataLength={pallets.length}
                  next={fetchNext}
                  hasMore={!last && !firstPalletsFetchLoading}
                  loader={
                     <div className={styles.loader}>
                        <Loader color={'var(--primary-green)'} />
                     </div>
                  }
                  scrollableTarget={'tableContainerId'}
               >
                  <StyledTable
                     columnHeaders={supplierPalletsTableHeaders}
                     headerClassName={styles.tableHeader}
                     className={styles.palletsTable}
                  >
                     {pallets.map(pallet => (
                        <DamagedLostPalletTableItem key={pallet.id} pallet={pallet} />
                     ))}
                  </StyledTable>
               </InfiniteScroll>
               {pallets.length === 0 && last && (
                  <NoItemsNotification
                     icon={<PalletsStackIcon />}
                     description="Nie ma jeszcze żadnych zgłoszeń."
                  />
               )}
            </div>
            {firstPalletsFetchLoading && (
               <div className={classNames(styles.loader, styles.mainTableLoader)}>
                  <Loader color={'var(--primary-green)'} />
               </div>
            )}
         </div>
      </div>
   );
};

export default WithAuth(PalletsPage, 'ROLE_SYSTEM_ADMIN');
