import { ChangeEvent, memo, MouseEvent } from 'react';

import { DatePicker } from '@mantine/dates';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { AlertTriangle, Calendar, Plus, Trash } from 'tabler-icons-react';

import { ToolTip } from 'components/shared';
import StyledAutocomplete from 'components/shared/StyledAutocomplete/StyledAutocomplete';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledSelect from 'components/shared/StyledSelect/StyledSelect';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { addressTypesWithoutPossibilityAddCustomAddress } from 'constants/addressTypesWithoutPossibilityAddCustomAddress';
import {
   CommercialNetworkAddressOptions,
   Option,
   SendPalletsItem,
   SendPalletsItemsErrors,
   SendPalletsItemsTouched,
} from 'interfaces';
import { Checkbox, TableCell, TableRow } from 'storybook';

import PalletsItemRow from './PalletsItemRow/PalletsItemRow';
import styles from './SendPalletsItemRow.module.css';

type SendPalletsItemRowProps = {
   sendPalletsItem: SendPalletsItem;
   sendPalletsItemErrors: SendPalletsItemsErrors;
   sendPalletsItemTouched: SendPalletsItemsTouched;
   destinationNameOptions: Option[];
   destinationAddressOptions: CommercialNetworkAddressOptions;
   availablePalletTypes: Option[];
   rowIndex: number;
   sendDamagedPalletsMode?: boolean;
   isModalEditMode?: boolean;
   isVisibleRemoveButton: boolean;
   onAddPalletsItem: (sendPalletsItemIndex: number) => void;
   onChangeIncludesDamagedPallets: (
      rowIndex: number,
      value: MouseEvent & ChangeEvent<HTMLInputElement>,
      sendPalletsItem: SendPalletsItem,
   ) => void;
   onChangeSendPalletsItem: (
      sendPalletsItemIndex: number,
      field: string,
      value: string | Date | null | Option | boolean,
      sendPalletsItem: SendPalletsItem,
      clearTouchedState?: boolean,
   ) => void;
   onSelectDestinationOptions: (
      rowIndex: number,
      value: Option,
      sendPalletsItem: SendPalletsItem,
   ) => void;
   onAddCommercialNetwork: (newCommercialNetwork: string) => void;
   onAddCommercialNetworkAddress: (
      commercialNetwork: string,
      newCommercialNetworkAddress: string,
   ) => void;
   onChangePalletsItem: (
      sendPalletsItemIndex: number,
      palletsItemIndex: number,
      field: string,
      value: string | Option,
   ) => void;
   onRemoveSendPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemovePalletsItem: (sendPalletsItemIndex: number, palletsItemIndex: number) => void;
};

const SendPalletsItemRow = memo(
   ({
      sendPalletsItem,
      sendPalletsItemErrors,
      sendPalletsItemTouched,
      destinationNameOptions,
      destinationAddressOptions,
      availablePalletTypes,
      rowIndex,
      sendDamagedPalletsMode,
      isModalEditMode,
      isVisibleRemoveButton,
      onAddPalletsItem,
      onChangeIncludesDamagedPallets,
      onChangeSendPalletsItem,
      onSelectDestinationOptions,
      onAddCommercialNetwork,
      onAddCommercialNetworkAddress,
      onChangePalletsItem,
      onRemoveSendPalletsItem,
      onRemovePalletsItem,
   }: SendPalletsItemRowProps) => (
      <>
         <TableRow className={styles.tableRow}>
            {sendDamagedPalletsMode && (
               <TableCell
                  className={classNames(styles.tableCell, styles.sendDamagedPalletsAlertCell)}
               >
                  <ToolTip content="Nadanie uszkodzonych palet" variant="right" visible>
                     <AlertTriangle color={'var(--primary-red)'} />
                  </ToolTip>
               </TableCell>
            )}
            {isModalEditMode && (
               <TableCell>
                  <Checkbox
                     checked={sendPalletsItem.includesDamagedPallets}
                     onChangeHandler={value =>
                        onChangeIncludesDamagedPallets(rowIndex, value, sendPalletsItem)
                     }
                  />
               </TableCell>
            )}
            <TableCell className={classNames(styles.tableCell, styles.sendDateCell)}>
               <DatePicker
                  onChange={value =>
                     onChangeSendPalletsItem(rowIndex, 'sendDate', value, sendPalletsItem)
                  }
                  value={sendPalletsItem.sendDate}
                  inputFormat="DD/MM/YYYY"
                  clearable={false}
                  icon={<Calendar color="#3E3E4B" size="20" />}
                  locale="pl"
                  className={styles.sendDatePicker}
                  error={sendPalletsItemTouched.sendDate && !!sendPalletsItemErrors.sendDate}
                  withinPortal
               />
            </TableCell>
            <TableCell
               className={classNames(styles.tableCell, styles.externalCommercialNetworkNumberCell)}
            >
               <StyledTextInput
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                     onChangeSendPalletsItem(
                        rowIndex,
                        'externalCommercialNetworkNumber',
                        event.target.value,
                        sendPalletsItem,
                     )
                  }
                  value={sendPalletsItem.externalCommercialNetworkNumber}
                  className={styles.externalCommercialNetworkNumberInput}
                  isTooltipVisible
                  isVisibleRightPart
               />
            </TableCell>
            <TableCell className={classNames(styles.tableCell, styles.commercialNetworkNameCell)}>
               <StyledAutocomplete
                  options={destinationNameOptions}
                  onSelect={(value: Option) => {
                     onSelectDestinationOptions(rowIndex, value, sendPalletsItem);
                  }}
                  value={sendPalletsItem.commercialNetworkName}
                  onlyTextMatch
                  isTooltipVisible
                  error={
                     sendPalletsItemTouched.commercialNetworkName &&
                     !!sendPalletsItemErrors.commercialNetworkName
                  }
                  dropdownButtonProps={{
                     onClick: (enteredCommercialNetwork: string) =>
                        onAddCommercialNetwork(enteredCommercialNetwork),
                     text: 'Dodaj',
                     displayEnteredText: true,
                     disableOnTextMatch: true,
                     icon: <Plus />,
                     fullWidth: true,
                     className: styles.dropdownBtn,
                  }}
                  classNames={{
                     container: styles.selectCommercialNetworkNameContainer,
                  }}
                  portalTarget={document.body}
               />
            </TableCell>
            <TableCell className={styles.tableCell}>
               <StyledAutocomplete
                  options={
                     destinationAddressOptions[sendPalletsItem.commercialNetworkName?.value] || []
                  }
                  onSelect={(value: Option) =>
                     onChangeSendPalletsItem(
                        rowIndex,
                        'commercialNetworkAddress',
                        value,
                        sendPalletsItem,
                     )
                  }
                  value={sendPalletsItem.commercialNetworkAddress}
                  onlyTextMatch
                  disabled={!sendPalletsItem.commercialNetworkName}
                  error={
                     sendPalletsItemTouched.commercialNetworkAddress &&
                     !!sendPalletsItemErrors.commercialNetworkAddress
                  }
                  dropdownButtonProps={
                     !addressTypesWithoutPossibilityAddCustomAddress.includes(
                        sendPalletsItem.commercialNetworkName?.value,
                     )
                        ? {
                             onClick: (enteredCommercialNetworkAddress: string) =>
                                onAddCommercialNetworkAddress(
                                   sendPalletsItem.commercialNetworkName!.value,
                                   enteredCommercialNetworkAddress,
                                ),
                             text: 'Dodaj',
                             displayEnteredText: true,
                             disableOnTextMatch: true,
                             icon: <Plus />,
                             fullWidth: true,
                             className: styles.dropdownBtn,
                          }
                        : undefined
                  }
                  classNames={{
                     container: styles.selectCommercialNetworkAddressContainer,
                  }}
                  portalTarget={document.body}
               />
            </TableCell>
            <TableCell className={classNames(styles.tableCell, styles.palletTypeCell)}>
               <StyledSelect
                  options={availablePalletTypes}
                  onChange={(value: Option) =>
                     onChangePalletsItem(rowIndex, 0, 'palletType', value)
                  }
                  value={sendPalletsItem.palletsItems[0].palletType}
                  error={
                     sendPalletsItemTouched.palletsItems[0].palletType &&
                     !!sendPalletsItemErrors.palletsItems[0].palletType
                  }
                  classNames={{ select: styles.selectPalletType }}
                  portalTarget={document.body}
               />
            </TableCell>
            <TableCell className={classNames(styles.tableCell, styles.palletsAmountCell)}>
               <StyledTextInput
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                     onChangePalletsItem(rowIndex, 0, 'palletsAmount', event.target.value)
                  }
                  value={sendPalletsItem.palletsItems[0].palletsAmount}
                  error={
                     sendPalletsItemTouched.palletsItems[0].palletsAmount &&
                     !!sendPalletsItemErrors.palletsItems[0].palletsAmount
                  }
                  className={styles.palletsAmountInput}
               />
            </TableCell>
            <TableCell className={classNames(styles.tableCell, styles.removeTableRowBtnCell)}>
               {isVisibleRemoveButton && (
                  <StyledButton
                     type="button"
                     onClick={() => onRemoveSendPalletsItem(rowIndex)}
                     icon={<Trash />}
                     variant="text"
                     className={styles.removeTableRowBtn}
                  />
               )}
            </TableCell>
         </TableRow>

         {sendPalletsItem.palletsItems.map(
            (palletsItem, palletsItemIndex) =>
               !!palletsItemIndex && (
                  <PalletsItemRow
                     key={palletsItem.uuid}
                     palletsItem={palletsItem}
                     sendPalletsItem={sendPalletsItem}
                     palletsItemIndex={palletsItemIndex}
                     sendPalletsItemIndex={rowIndex}
                     availablePalletTypes={availablePalletTypes}
                     palletsItemError={sendPalletsItemErrors.palletsItems[palletsItemIndex]}
                     palletsItemTouched={sendPalletsItemTouched.palletsItems[palletsItemIndex]}
                     onChangePalletsItem={onChangePalletsItem}
                     onRemovePalletsItem={onRemovePalletsItem}
                     sendDamagedPalletsMode={sendDamagedPalletsMode}
                     isModalEditMode={!!isModalEditMode}
                  />
               ),
         )}

         <TableRow className={styles.tableRow}>
            {(sendDamagedPalletsMode || isModalEditMode) && <TableCell />}
            <TableCell className={styles.tableCell} />
            <TableCell className={styles.tableCell} />
            <TableCell className={styles.tableCell} />
            <TableCell className={styles.tableCell} />
            <TableCell className={styles.tableCell}>
               <StyledButton
                  type="button"
                  onClick={() => onAddPalletsItem(rowIndex)}
                  text="Dodaj typ palety"
                  icon={<Plus />}
                  variant="text"
                  className={styles.addPalletTypeBtn}
               />
            </TableCell>
            <TableCell className={styles.tableCell} />
            <TableCell className={styles.tableCell} />
         </TableRow>
      </>
   ),
   (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

export default SendPalletsItemRow;
