const FREE_PALLETS_LABELS: Record<string, string> = {
   one: 'bezpłatna',
   few: 'bezpłatne',
   many: 'bezpłatnych',
};

export const getFreePalletsLabel = (palletAmount: number) => {
   const pluralRules = new Intl.PluralRules('pl-PL');
   const grammaticalNumber = pluralRules.select(palletAmount);
   return FREE_PALLETS_LABELS[grammaticalNumber];
};
