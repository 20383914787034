import { MainLayout } from 'components/layout';
import { STORAGE_PALLET_BALANCE, STORAGE_PALLETS_TRANSFER_HISTORY } from 'constants/routes';
import { PalletBalancePage, PalletsTransferHistoryPage } from 'pages/StoragePanel';

export const storageRoutes = {
   element: <MainLayout />,
   children: [
      { path: STORAGE_PALLET_BALANCE, element: <PalletBalancePage /> },
      { path: STORAGE_PALLETS_TRANSFER_HISTORY, element: <PalletsTransferHistoryPage /> },
   ],
};
