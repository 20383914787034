import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { Ban, UserOff } from 'tabler-icons-react';

import { PlusWhiteIcon } from 'assets';
import { ActionsDropdown, StyledButton } from 'components/shared';
import {
   BlockClientModal,
   CannotDeactivateClientModal,
   ClientDetailsBanHistory,
   ClientDetailsData,
   ClientDetailsPallets,
   ClientDetailsPrivateComments,
   ClientDetailsRetailChains,
   DeactivateClientModal,
   UnblockClientModal,
} from 'components/SupplierPanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { SUPPLIER_EDIT_CLIENT_PATH, SUPPLIER_PLACE_ORDER } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { BannerData, ClientUserDTO, CommercialNetworkDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner, Tab } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './ClientDetailsPage.module.css';

type CurrentTab = 'DATA' | 'PALLETS' | 'RETAIL_CHAINS' | 'BAN_HISTORY' | 'PRIVATE_COMMENTS';

const ClientDetailsPage = () => {
   const [cannotDeactivateClientModal, setCannotDeactivateClientModal] = useState(false);
   const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
   const [client, setClient] = useState<ClientUserDTO>();
   const [commercialNetworksForClient, setCommercialNetworksForClient] = useState<
      CommercialNetworkDetailsDTO[]
   >([]);
   const [deactivatedUserInfo, setDeactivatedUserInfo] = useState(false);
   const [currentTab, setCurrentTab] = useState<CurrentTab>('DATA');
   const { id } = useParams();
   const [isChangeClientStatusModalOpen, setIsChangeClientStatusModalOpen] = useState<
      'BLOCK' | 'UNBLOCK' | null
   >(null);
   const [successUnblockClientBannerOpen, setSuccessUnblockClientBannerOpen] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const handleFetchClientDataError = useCallback(
      (error: unknown) =>
         errorHandler(error, () =>
            setBannerData({
               variant: 'error',
               description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
            }),
         ),
      [],
   );

   useEffect(() => {
      if (id) {
         Promise.all([
            HTTPService.getUserDetails(id),
            HTTPService.getCommercialNetworks(
               { userId: id },
               { page: 0, size: PAGINATION_MAX_SIZE },
            ),
         ])
            .then(([getUserDetailsResponse, getCommercialNetworksResponse]) => {
               const { content: commercialNetworksContent } = getCommercialNetworksResponse.data;
               const { data: clientDetails } = getUserDetailsResponse;
               setClient(clientDetails);
               setCommercialNetworksForClient(commercialNetworksContent);
            })
            .catch(handleFetchClientDataError);
      }
   }, [id, handleFetchClientDataError]);

   const handleSuccessBlockClient = () => {
      if (client) {
         const updatedClient = { ...client, suspension: { blocked: true, reason: '' } };
         setClient(updatedClient);
      }
   };
   const handleSuccessUnblockClient = () => {
      if (client) {
         const updatedClient = { ...client, suspension: { blocked: false, reason: '' } };
         setClient(updatedClient);
         setSuccessUnblockClientBannerOpen(true);
      }
   };
   const handleCloseChangeClientStatusModal = useCallback(
      () => setIsChangeClientStatusModalOpen(null),
      [],
   );
   const handleCloseDeactivateClientModal = useCallback(() => setIsDeactivateModalOpen(false), []);
   const returnSection = useCallback(() => {
      switch (currentTab) {
         case 'DATA':
            return <ClientDetailsData {...(client as ClientUserDTO)} />;
         case 'PALLETS':
            return <ClientDetailsPallets {...(client as ClientUserDTO)} />;
         case 'RETAIL_CHAINS':
            return (
               client && (
                  <ClientDetailsRetailChains
                     client={client}
                     commercialNetworksForClient={commercialNetworksForClient}
                  />
               )
            );
         case 'BAN_HISTORY':
            return <ClientDetailsBanHistory {...(client as ClientUserDTO)} />;
         case 'PRIVATE_COMMENTS':
            return (
               <ClientDetailsPrivateComments
                  client={client!}
                  onFetchCommentsDataError={handleFetchClientDataError}
               />
            );
      }
   }, [currentTab, client, commercialNetworksForClient, handleFetchClientDataError]);

   const isBannersContainerVisible =
      deactivatedUserInfo ||
      !client?.active ||
      client?.suspension.blocked ||
      successUnblockClientBannerOpen ||
      bannerData;

   return (
      <>
         <BlockClientModal
            opened={isChangeClientStatusModalOpen === 'BLOCK'}
            onSuccessBlockClient={handleSuccessBlockClient}
            onClose={handleCloseChangeClientStatusModal}
            selectedUserId={client?.id || ''}
         />
         <DeactivateClientModal
            onClose={handleCloseDeactivateClientModal}
            clientId={client?.id || ''}
            opened={isDeactivateModalOpen}
            setDeactivatedUserInfo={setDeactivatedUserInfo}
            setCannotDeactivateClientModal={setCannotDeactivateClientModal}
         />
         <CannotDeactivateClientModal
            opened={cannotDeactivateClientModal}
            onClose={() => setCannotDeactivateClientModal(false)}
         />
         <UnblockClientModal
            opened={isChangeClientStatusModalOpen === 'UNBLOCK'}
            onSuccessBlockClient={handleSuccessUnblockClient}
            onClose={handleCloseChangeClientStatusModal}
            selectedUserId={client?.id || ''}
         />
         <div className={styles.mainContainer}>
            {isBannersContainerVisible && (
               <div className={styles.banners}>
                  {deactivatedUserInfo && (
                     <Banner
                        icon={<UserOff />}
                        fullWidth
                        withCloseIcon
                        onClose={() => setDeactivatedUserInfo(false)}
                        variant="error"
                        className={styles.banner}
                     >
                        Klient został dezaktywowany i jest dostępny na samym dole listy.
                     </Banner>
                  )}
                  {client && !client.active && (
                     <Banner
                        variant="error"
                        fullWidth
                        icon={<UserOff size={20} strokeWidth={2} color={'var(--primary-red)'} />}
                        className={styles.banner}
                     >
                        Klient został dezaktywowany i nie może zalogować się na swoje konto.
                     </Banner>
                  )}
                  {client?.suspension.blocked && (
                     <Banner
                        variant="error"
                        fullWidth
                        icon={<Ban size={20} strokeWidth={2} color={'var(--primary-red)'} />}
                        className={styles.banner}
                     >
                        Klient został zablokowany i nie może składać zamówień.
                     </Banner>
                  )}
                  {successUnblockClientBannerOpen && (
                     <Banner
                        variant="success"
                        fullWidth
                        withCloseIcon
                        onClose={() => setSuccessUnblockClientBannerOpen(false)}
                        className={styles.banner}
                     >
                        Klient został odblokowany. Od teraz ponownie może składać zamówienia.
                     </Banner>
                  )}
                  {bannerData && (
                     <Banner
                        variant={bannerData.variant}
                        title={bannerData.title}
                        children={bannerData.description}
                        className={styles.banner}
                        onClose={() => setBannerData(null)}
                        withCloseIcon
                        fullWidth
                     />
                  )}
               </div>
            )}
            {client ? (
               <>
                  <div className={styles.clientHeader}>
                     <div>
                        <div className={styles.clientBasicInfo}>
                           <h2>{client.companyName}</h2>
                           <span>Nr klienta: {client.id}</span>
                        </div>
                        <Link to={SUPPLIER_EDIT_CLIENT_PATH(client.id)}>
                           <StyledButton variant="outlined-primary" text="Edytuj klienta" />
                        </Link>
                        <Link to={SUPPLIER_PLACE_ORDER} state={{ clientId: client.id }}>
                           <StyledButton
                              icon={<PlusWhiteIcon />}
                              variant="filled-primary"
                              text="Dodaj zamówienie"
                              disabled={client.suspension.blocked}
                           />
                        </Link>
                     </div>
                     <ActionsDropdown
                        options={
                           client.suspension.blocked
                              ? [
                                   {
                                      text: 'Odblokuj',
                                      icon: <Ban size={20} />,
                                      onClick: () => setIsChangeClientStatusModalOpen('UNBLOCK'),
                                   },
                                   {
                                      text: 'Dezaktywuj',
                                      lastOption: true,
                                      icon: <UserOff size={20} />,
                                      onClick: () => {
                                         setIsDeactivateModalOpen(true);
                                      },
                                   },
                                ]
                              : [
                                   {
                                      text: 'Zablokuj',
                                      icon: <Ban size={20} />,
                                      onClick: () => setIsChangeClientStatusModalOpen('BLOCK'),
                                   },
                                   {
                                      text: 'Dezaktywuj',
                                      lastOption: true,
                                      icon: <UserOff size={20} />,
                                      onClick: () => {
                                         setIsDeactivateModalOpen(true);
                                      },
                                   },
                                ]
                        }
                     />
                  </div>
                  <div className={styles.tabsContainer}>
                     <Tab
                        onClick={() => setCurrentTab('DATA')}
                        active={currentTab === 'DATA'}
                        text="Dane"
                     />
                     <Tab
                        onClick={() => setCurrentTab('PALLETS')}
                        active={currentTab === 'PALLETS'}
                        text="Palety"
                     />
                     <Tab
                        onClick={() => setCurrentTab('RETAIL_CHAINS')}
                        active={currentTab === 'RETAIL_CHAINS'}
                        text="Sieci Handlowe"
                     />
                     <Tab
                        onClick={() => setCurrentTab('BAN_HISTORY')}
                        active={currentTab === 'BAN_HISTORY'}
                        text="Historia blokad"
                     />
                     <Tab
                        onClick={() => setCurrentTab('PRIVATE_COMMENTS')}
                        active={currentTab === 'PRIVATE_COMMENTS'}
                        text="Komentarze prywatne"
                     />
                  </div>
                  {returnSection()}
               </>
            ) : (
               <div className={styles.loaderContainer}>
                  <Loader color="var(--primary-green)" />
               </div>
            )}
         </div>
      </>
   );
};

export default WithAuth(ClientDetailsPage, 'ROLE_SYSTEM_ADMIN');
