import { FormEvent, ReactNode } from 'react';

import { LoadingOverlay, Modal, ModalProps } from '@mantine/core';
import classNames from 'classnames';
import {
   AlertTriangle,
   Ban,
   FileCheck,
   FileLike,
   ThumbUp,
   Trash,
   UserExclamation,
   UserOff,
} from 'tabler-icons-react';

import { Banner } from 'storybook';

import StyledButton, { ButtonVariants } from '../StyledButton/StyledButton';
import { CloseWarningModal } from '..';

import styles from './CustomModal.module.css';

export type CustomModalProps = Omit<ModalProps, 'onSubmit'> & {
   icon?: Icons;
   innerTitle?: string;
   modalContentClassName?: string;
   children?: ReactNode;
   primaryButtonProps?: ButtonProps;
   secondaryButtonProps?: ButtonProps;
   closeWarningModalProps?: CloseWarningModalProps;
   isLoading?: boolean;
   error?: string;
   leftSideModalContent?: boolean;
   centeredButtons?: boolean;
   onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
};

type Icons =
   | 'FILE_LIKE'
   | 'FILE_CHECK'
   | 'ALERT_TRIANGLE'
   | 'THUMB_UP'
   | 'USER_EXCLAMATION'
   | 'BAN'
   | 'TRASH'
   | 'USER_OFF';

type ButtonProps = {
   text?: string;
   className?: string;
   variant?: ButtonVariants;
   disabled?: boolean;
   onClick?: () => void;
};

type CloseWarningModalProps = {
   opened: boolean;
   onClose: () => void;
   onConfirm: () => void;
};

const ICONS = {
   FILE_LIKE: <FileLike className={classNames(styles.icon, styles.green)} />,
   ALERT_TRIANGLE: <AlertTriangle className={classNames(styles.icon, styles.red)} />,
   FILE_CHECK: <FileCheck className={classNames(styles.icon, styles.green)} />,
   THUMB_UP: <ThumbUp className={classNames(styles.icon, styles.green)} />,
   USER_EXCLAMATION: <UserExclamation className={classNames(styles.icon, styles.green)} />,
   BAN: <Ban className={classNames(styles.icon, styles.red)} />,
   TRASH: <Trash className={classNames(styles.icon, styles.red)} />,
   USER_OFF: <UserOff className={classNames(styles.icon, styles.red)} />,
};

const CustomModal = ({
   icon,
   title,
   innerTitle,
   children,
   primaryButtonProps,
   secondaryButtonProps,
   closeWarningModalProps,
   isLoading,
   error,
   modalContentClassName,
   classNames: modalclassNames,
   leftSideModalContent,
   centeredButtons,
   onSubmit,
   ...props
}: CustomModalProps) => {
   const content = (
      <>
         <div
            className={classNames(styles.contentContainer, modalContentClassName, {
               [styles.leftSide]: leftSideModalContent,
            })}
         >
            {error && (
               <Banner variant="error" fullWidth style={{ marginBottom: 24 }} children={error} />
            )}
            {icon && ICONS[icon]}
            {innerTitle && <h3>{innerTitle}</h3>}
            {children}
            <LoadingOverlay
               visible={!!isLoading}
               overlayBlur={3}
               loaderProps={{ color: 'var(--light-green)' }}
            />
         </div>
         <div className={classNames(styles.btnsContainer, { [styles.centered]: centeredButtons })}>
            {secondaryButtonProps && (
               <StyledButton
                  type="button"
                  text={secondaryButtonProps.text}
                  onClick={secondaryButtonProps.onClick}
                  variant={secondaryButtonProps.variant || 'text'}
                  disabled={secondaryButtonProps.disabled}
                  className={secondaryButtonProps?.className}
               />
            )}
            {primaryButtonProps && (
               <StyledButton
                  type={onSubmit ? 'submit' : 'button'}
                  text={primaryButtonProps?.text}
                  onClick={primaryButtonProps.onClick}
                  variant={primaryButtonProps.variant || 'filled-primary'}
                  disabled={isLoading || primaryButtonProps.disabled}
                  className={primaryButtonProps?.className}
               />
            )}
         </div>
      </>
   );

   return (
      <Modal
         centered
         title={title}
         size={600}
         classNames={{
            modal: classNames(styles.modal, modalclassNames?.modal),
            body: classNames(styles.modalBody, modalclassNames?.body),
            ...modalclassNames,
         }}
         {...props}
      >
         <>
            {closeWarningModalProps && (
               <CloseWarningModal
                  opened={closeWarningModalProps.opened}
                  onClose={closeWarningModalProps.onClose}
                  onConfirm={closeWarningModalProps.onConfirm}
               />
            )}
            {onSubmit ? <form onSubmit={onSubmit}>{content}</form> : content}
         </>
      </Modal>
   );
};

export default CustomModal;
