import React from 'react';

import { StyledTable } from 'components/shared';
import { EditClientBalanceTableRow } from 'components/Storages/index';
import { editUserBalanceHeaders } from 'constants/tableHeaders';
import { editPalletBalance } from 'interfaces';

import styles from './EditClientBalanceTable.module.css';

type EditClientBalanceTableProps = {
   rows: editPalletBalance[];
   errors: Record<string, string | boolean>[];
   touched: Record<string, boolean>[];
   handleChangeValue: (rowIndex: number, field: string, value: string) => void;
};

const EditClientBalanceTable = ({
   errors,
   touched,
   rows,
   handleChangeValue,
}: EditClientBalanceTableProps) => {
   return (
      <StyledTable className={styles.table} columnHeaders={editUserBalanceHeaders}>
         {rows.map((row, rowIndex) => (
            <EditClientBalanceTableRow
               key={rowIndex}
               row={row}
               rowIndex={rowIndex}
               errors={errors}
               touched={touched}
               handleChangeValue={handleChangeValue}
            />
         ))}
      </StyledTable>
   );
};

export default EditClientBalanceTable;
