import { ClientOrderSummaryPallet, OrderDetailsPallet, PalletAmountListItem } from 'interfaces';
import { isOrderDetailsPalletArray } from 'utils/typeGuards';

import { countPallets } from '.';

export const getPalletAmountListItemsData = (
   pallets: ClientOrderSummaryPallet[] | OrderDetailsPallet[],
) => {
   const isOrderDetailsPallets = isOrderDetailsPalletArray(pallets);
   if (!isOrderDetailsPallets) {
      return pallets.reduce<PalletAmountListItem[]>((allPallets, currentPalletsItem) => {
         const newRegularPalletsItem = mapPalletToPalletAmountListItem(currentPalletsItem);
         return [...allPallets, newRegularPalletsItem];
      }, []);
   }
   return pallets.reduce<PalletAmountListItem[]>((allPallets, currentPalletsItem) => {
      const newRegularPalletsItem = mapPalletToPalletAmountListItem(currentPalletsItem);
      if (currentPalletsItem.freePalletsAmount) {
         const newFreePalletsItem = mapPalletToPalletAmountListItem(
            currentPalletsItem,
            currentPalletsItem.freePalletsAmount,
         );
         return [...allPallets, newRegularPalletsItem, newFreePalletsItem];
      }
      return [...allPallets, newRegularPalletsItem];
   }, []);
};

const mapPalletToPalletAmountListItem = (
   pallet: ClientOrderSummaryPallet | OrderDetailsPallet,
   freePalletsAmount?: number,
) => {
   const { name, label, dimensions } = pallet;
   return {
      name,
      label,
      dimensions,
      amount: freePalletsAmount || countPallets(pallet),
      isFree: !!freePalletsAmount,
   };
};
