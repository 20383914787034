import WarningModal from '../WarningModal';

type GoBackWarningModalProps = {
   opened: boolean;
   onClose: () => void;
   onConfirm: () => void;
};

const GoBackWarningModal = ({ opened, onClose, onConfirm }: GoBackWarningModalProps) => (
   <WarningModal
      heading="Czy na pewno chcesz przejść do poprzedniego kroku?"
      description="Cofając się do poprzedniego kroku utracisz wszystkie dotychczas zdefiniowane dane."
      cancelBtnText="Zostań"
      confirmBtnText="Przejdź wstecz bez zapisu danych"
      opened={opened}
      size="620"
      onClose={onClose}
      onConfirm={onConfirm}
   />
);

export default GoBackWarningModal;
