import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { Check, Trash, X } from 'tabler-icons-react';

import { OrderAdditionalInfo } from 'components/OrderDetails';
import OrderDocuments from 'components/OrderDetails/OrderDocuments/OrderDocuments';
import {
   FinishEditingSectionButtonTooltip,
   FreeOrderBanner,
   FreeRelatedOrdersBanner,
   RetentionDateExpiredBanner,
} from 'components/Orders';
import { ActionsDropdown, CustomSwitch, StyledBadge, StyledButton } from 'components/shared';
import {
   CancelOrderModal,
   ClientSection,
   RemoveOrderModal,
   SelectedDeliveryTypeSection,
} from 'components/SupplierPanel';
import { SUPPLIER_ORDER_DETAILS_BANNERS } from 'constants/banners';
import {
   CREATE_AND_SEND_INVOICE_ERROR_DESCRIPTION,
   DEFAULT_ERROR_DESCRIPTIONS,
} from 'constants/errorDescriptions';
import { ORDER_STATUSES_BADGE_DATA } from 'constants/orderStatuses';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { SUPPLIER_ORDERS_ALL_STATUSES_TAB } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { useOrderDetails } from 'hooks/useOrderDetails';
import {
   BannerData,
   ClientUserDTO,
   CommercialNetworkDetailsDTO,
   OrderDetails,
   OrderDetailsDTO,
   OrderDetailsPageSection,
   OrderStatus,
   PalletDataWithPrice,
   PalletReportDTO,
   StorageDTO,
   SupplierEditAddedToNextOrderData,
   SupplierEditRegularOrderData,
   SupplierOrderDetailsPageModalsOrDialogs,
} from 'interfaces';
import { HTTPService } from 'service';
import { ChangeOrderStatusAction } from 'service/http/requests';
import { Banner, Divider, Tab } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import {
   calculatePalletsAmountAsLogisticMinimum,
   countPalletsOrderTotalPrice,
   getFullAddress,
   getOrderDetailsData,
   parseDate,
   scrollToElement,
} from 'utils/functions';
import { currencyFormatter } from 'utils/numberFormatter';

import AddedToNextOrderDetailsPage from './AddedToNextOrderDetailsPage/AddedToNextOrderDetailsPage';
import RegularOrderDetailsPage from './RegularOrderDetailsPage/RegularOrderDetailsPage';
import styles from './OrderDetailsPage.module.css';

const SECTIONS = {
   DELIVERY: 'deliverySection',
   RENTAL_PERIOD: 'rentalPeriodSection',
   PALLETS_CONFIGURATION: 'palletsConfigurationSection',
   STORAGE: 'storageSection',
};

const sectionsEditModeReducer = (
   state: {
      deliverySection: boolean;
      rentalPeriodSection: boolean;
      palletsConfigurationSection: boolean;
      storageSection: boolean;
   },
   action: { section: OrderDetailsPageSection; editMode: boolean },
) => {
   const { section, editMode } = action;
   return { ...state, [SECTIONS[section]]: editMode };
};

const sectionsLoadingReducer = (
   state: {
      deliverySection: boolean;
      rentalPeriodSection: boolean;
      palletsConfigurationSection: boolean;
      storageSection: boolean;
   },
   action: { section: OrderDetailsPageSection; isLoading: boolean },
) => {
   const { section, isLoading } = action;
   return { ...state, [SECTIONS[section]]: isLoading };
};

const REDUCER_INITIAL_STATE = {
   deliverySection: false,
   rentalPeriodSection: false,
   palletsConfigurationSection: false,
   storageSection: false,
};

const orderWithInvoicingStatuses: OrderStatus[] = ['IN_PROGRESS', 'DELIVERED', 'DURING_BILLING'];

const OrderDetailsPage = () => {
   const params = useParams<{ id: string }>();
   const navigate = useNavigate();
   const [order, setOrder] = useState<OrderDetails>();
   const [client, setClient] = useState<ClientUserDTO>();
   const [storages, setStorages] = useState<StorageDTO[]>();
   const [commercialNetworks, setCommercialNetworks] = useState<CommercialNetworkDetailsDTO[]>();
   const [lostDamagedPalletsReports, setLostDamagedPalletsReports] = useState<PalletReportDTO[]>();
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [isSendAndCreateInvoiceLoading, setIsSendAndCreateInvoiceLoading] = useState(false);
   const [activeTab, setActiveTab] = useState<'ORDER' | 'DOCUMENTS'>('ORDER');
   const [selectedModalOrDialog, setSelectedModalOrDialog] =
      useState<SupplierOrderDetailsPageModalsOrDialogs>(null);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const [sectionsEditMode, dispatchSectionsEditMode] = useReducer(
      sectionsEditModeReducer,
      REDUCER_INITIAL_STATE,
   );
   const [sectionsLoading, dispatchSectionsLoading] = useReducer(
      sectionsLoadingReducer,
      REDUCER_INITIAL_STATE,
   );
   const orderDetailsHeaderRef = useRef<null | HTMLDivElement>(null);
   const orderDocumentsContentRef = useRef<{ fetchDocuments: () => void }>(null);
   const { pallets, palletsSum, delayTimeInDaysFromRetentionDate, isRetentionDateExpired } =
      useOrderDetails({
         order,
      });

   const isOrderNonstandard = order?.personalPickUp || !order?.hasMetLogisticsMinimum;

   const scrollToTop = useCallback(() => scrollToElement(orderDetailsHeaderRef), []);

   const handleError = useCallback(
      (error: unknown, errorDescription: string) => {
         scrollToTop();
         errorHandler(error, () =>
            setBannerData({
               variant: 'error',
               description: errorDescription,
            }),
         );
      },
      [scrollToTop],
   );

   useEffect(() => {
      const fetchData = async () => {
         setIsDataLoading(true);
         if (params.id) {
            try {
               const getOrderDetailsResponse = await HTTPService.getOrderDetails(params.id);
               const orderDetailsData = getOrderDetailsData(getOrderDetailsResponse.data);
               setOrder(orderDetailsData);
               if (orderDetailsData.mode === 'REGULAR_ORDER') {
                  const clientId = orderDetailsData.createdBy.id;
                  const [
                     getClientDetailsResponse,
                     getStoragesResponse,
                     getReceivedLostOrDamagedPalletsResponse,
                     getCommercialNetworksResponse,
                  ] = await Promise.all([
                     HTTPService.getUserDetails(clientId),
                     HTTPService.getStorageList({ size: PAGINATION_MAX_SIZE }),
                     HTTPService.getReceivedLostOrDamagedPallets(params.id, undefined, {
                        size: PAGINATION_MAX_SIZE,
                     }),
                     HTTPService.getCommercialNetworks(
                        { userId: clientId },
                        { page: 0, size: PAGINATION_MAX_SIZE },
                     ),
                  ]);
                  setClient(getClientDetailsResponse.data);
                  setStorages(getStoragesResponse.data.content);
                  setLostDamagedPalletsReports(
                     getReceivedLostOrDamagedPalletsResponse.data.content,
                  );
                  setCommercialNetworks(getCommercialNetworksResponse.data.content);
               } else {
                  const getClientDetailsResponse = await HTTPService.getUserDetails(
                     orderDetailsData.createdBy.id,
                  );
                  setClient(getClientDetailsResponse.data);
               }
            } catch (error) {
               handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA);
            } finally {
               setIsDataLoading(false);
            }
         }
      };
      fetchData();
   }, [params.id, handleError]);

   const fetchOrder = useCallback(() => {
      if (order?.id) {
         setIsDataLoading(true);
         HTTPService.getOrderDetails(order.id)
            .then(res => {
               const orderDetailsData = getOrderDetailsData(res.data);
               setOrder(orderDetailsData);
            })
            .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA))
            .finally(() => setIsDataLoading(false));
      }
   }, [order?.id, handleError]);

   const handleCreateAndSendInvoice = () => {
      setIsSendAndCreateInvoiceLoading(true);
      HTTPService.createAndSendInvoice(order!.id)
         .then(() => {
            setOrder(prevOrder => prevOrder && { ...prevOrder, hasInvoice: true });
            setActiveTab('ORDER');
            setSelectedModalOrDialog('SUCCESS_CREATE_AND_REPORT_INVOICE_DIALOG');
         })
         .catch(error => handleError(error, CREATE_AND_SEND_INVOICE_ERROR_DESCRIPTION))
         .finally(() => setIsSendAndCreateInvoiceLoading(false));
   };

   const handleOpenCancelOrderModal = () => setSelectedModalOrDialog('CANCEL_ORDER_MODAL');

   const handleOpenRemoveOrderModal = () => setSelectedModalOrDialog('REMOVE_ORDER_MODAL');

   const handleCloseModalOrDialog = useCallback(() => setSelectedModalOrDialog(null), []);

   const handleCloseBanner = () => setBannerData(null);

   const handleSuccessOrderAction = (
      action: ChangeOrderStatusAction,
      updatedOrder: OrderDetailsDTO,
   ) => {
      setBannerData(SUPPLIER_ORDER_DETAILS_BANNERS[action]);
      setOrder(getOrderDetailsData(updatedOrder));
      scrollToElement(orderDetailsHeaderRef);
   };

   const handleSuccessCancelOrder = (updatedOrder: OrderDetailsDTO) => {
      handleSuccessOrderAction('CANCEL', updatedOrder);
      if (activeTab === 'ORDER') {
         return;
      }
      orderDocumentsContentRef.current?.fetchDocuments();
   };

   const handleSuccessRemoveOrder = () =>
      navigate(SUPPLIER_ORDERS_ALL_STATUSES_TAB, {
         replace: true,
         state: { successRemoveOrder: true },
      });

   const handleClickAcceptOrderBtn = useCallback(() => {
      if (!order) {
         return;
      }
      if (!order.storage) {
         setSelectedModalOrDialog('NO_PICKUP_ADDRESS_MODAL');
         return;
      }
      setSelectedModalOrDialog('ACCEPT_ORDER_MODAL');
   }, [order]);

   const renderContent = () => {
      if (order && client && orderFormValues) {
         if (
            order.mode === 'REGULAR_ORDER' &&
            orderFormValues.orderType === 'REGULAR_ORDER' &&
            storages &&
            commercialNetworks &&
            lostDamagedPalletsReports
         ) {
            return (
               <RegularOrderDetailsPage
                  order={order}
                  initialFormValues={orderFormValues}
                  client={client}
                  storages={storages}
                  commercialNetworks={commercialNetworks}
                  lostDamagedPalletsReports={lostDamagedPalletsReports}
                  pallets={pallets}
                  palletsSum={palletsSum}
                  chargesTotalAmount={chargesTotalAmount}
                  isOrderFree={isFree}
                  isOrderNonstandard={isOrderNonstandard}
                  sectionsEditMode={sectionsEditMode}
                  selectedModalOrDialog={selectedModalOrDialog}
                  sectionsLoading={sectionsLoading}
                  fetchOrder={fetchOrder}
                  setOrder={setOrder}
                  setLostDamagedPalletsReports={setLostDamagedPalletsReports}
                  showModalOrDialog={setSelectedModalOrDialog}
                  handleCloseModalOrDialog={handleCloseModalOrDialog}
                  handleSuccessOrderAction={handleSuccessOrderAction}
                  dispatchSectionsEditMode={dispatchSectionsEditMode}
                  dispatchSectionsLoading={dispatchSectionsLoading}
                  scrollToTop={scrollToTop}
                  showBanner={setBannerData}
                  onError={handleError}
               />
            );
         } else if (
            order.mode === 'ADDED_TO_NEXT_ORDER' &&
            orderFormValues.orderType === 'ADDED_TO_NEXT_ORDER'
         ) {
            return (
               <AddedToNextOrderDetailsPage
                  order={order}
                  initialFormValues={orderFormValues}
                  client={client}
                  pallets={pallets}
                  palletsSum={palletsSum}
                  isPalletsConfigurationSectionEditMode={
                     sectionsEditMode.palletsConfigurationSection
                  }
                  isPalletsConfigurationSectionLoading={sectionsLoading.palletsConfigurationSection}
                  setOrder={setOrder}
                  dispatchSectionsEditMode={dispatchSectionsEditMode}
                  dispatchSectionsLoading={dispatchSectionsLoading}
                  onError={handleError}
               />
            );
         }
      }
   };

   const orderFormValues = useMemo<
      SupplierEditRegularOrderData | SupplierEditAddedToNextOrderData | undefined
   >(() => {
      if (order) {
         const palletsData: PalletDataWithPrice[] = order.pallets.map(pallet => ({
            name: pallet.palletType,
            amount: pallet.shownAsMultipleOfTheMinimum
               ? calculatePalletsAmountAsLogisticMinimum(
                    pallet.palletType,
                    pallet.amount,
                 ).toString()
               : pallet.amount.toString(),
            orderType: pallet.shownAsMultipleOfTheMinimum ? 'LOGISTIC_MINIMUM' : 'CUSTOM',
            pricePerUnit: pallet.pricePerUnit,
         }));
         if (order.mode === 'REGULAR_ORDER') {
            const {
               personalPickUp,
               address: deliveryAddress,
               storage,
               palletDeliveryDate: formattedPalletDeliveryDate,
            } = order;
            const address =
               !personalPickUp && deliveryAddress
                  ? {
                       label: getFullAddress(
                          deliveryAddress.street,
                          deliveryAddress.zipCode,
                          deliveryAddress.city,
                          deliveryAddress.name,
                       ),
                       value: {
                          name: deliveryAddress.name,
                          street: deliveryAddress.street,
                          city: deliveryAddress.city,
                          zipCode: deliveryAddress.zipCode,
                       },
                    }
                  : 'SELF_PICKUP';
            const orderFormData: SupplierEditRegularOrderData = {
               orderType: 'REGULAR_ORDER',
               address,
               palletDeliveryDate: parseDate(formattedPalletDeliveryDate),
               pallets: palletsData,
               storage: storage
                  ? {
                       value: { storageId: storage.id, storageType: storage.type },
                       label: `${storage.address.street}, ${storage.address.zipCode} ${storage.address.city}`,
                    }
                  : null,
               palletCustomPrices: {
                  edhpPalletPrice: 0,
                  euroPalletPrice: 0,
                  halfPalletNDHPPrice: 0,
                  quarterPalletCDHPPrice: 0,
               },
            };
            return orderFormData;
         } else if (order.mode === 'ADDED_TO_NEXT_ORDER') {
            const orderFormData: SupplierEditAddedToNextOrderData = {
               orderType: 'ADDED_TO_NEXT_ORDER',
               pallets: palletsData,
            };
            return orderFormData;
         }
      }
   }, [order]);

   const chargesTotalAmount = useMemo(
      () =>
         order
            ? order.additionalCharges.reduce(
                 (summaryTotalAmount, charge) => (summaryTotalAmount += charge.totalAmount || 0),
                 0,
              )
            : 0,
      [order],
   );

   const orderDetailsOptions = useMemo(() => {
      if (order?.status === 'ENDED') {
         return;
      }
      if (order?.status !== 'CANCELED') {
         return [
            {
               icon: <X />,
               text: 'Anuluj zamówienie',
               onClick: handleOpenCancelOrderModal,
            },
         ];
      }
      if (order?.status === 'CANCELED') {
         return [
            {
               icon: <Trash />,
               text: 'Usuń zamówienie',
               onClick: handleOpenRemoveOrderModal,
               dangerOption: true,
            },
         ];
      }
   }, [order?.status]);

   const isSomeSectionInEditMode =
      sectionsEditMode.deliverySection ||
      sectionsEditMode.palletsConfigurationSection ||
      sectionsEditMode.rentalPeriodSection ||
      sectionsEditMode.storageSection;

   const actionButtons = useMemo(() => {
      switch (order?.status) {
         case 'WAITING':
            return (
               <>
                  <FinishEditingSectionButtonTooltip isVisible={isSomeSectionInEditMode}>
                     <StyledButton
                        icon={<X size={20} />}
                        text={
                           isOrderNonstandard
                              ? 'Odrzuć zamówienie niestandardowe'
                              : 'Odrzuć zamówienie'
                        }
                        variant="filled-danger"
                        onClick={() => setSelectedModalOrDialog('REJECT_ORDER_MODAL')}
                        disabled={isSomeSectionInEditMode}
                     />
                  </FinishEditingSectionButtonTooltip>
                  <FinishEditingSectionButtonTooltip isVisible={isSomeSectionInEditMode}>
                     <StyledButton
                        icon={<Check size={20} />}
                        text={
                           isOrderNonstandard
                              ? 'Zaakceptuj zamówienie niestandardowe'
                              : 'Zaakceptuj zamówienie'
                        }
                        variant="filled-primary"
                        onClick={handleClickAcceptOrderBtn}
                        disabled={isSomeSectionInEditMode}
                     />
                  </FinishEditingSectionButtonTooltip>
               </>
            );
         case 'DELIVERED':
            return (
               <FinishEditingSectionButtonTooltip isVisible={isSomeSectionInEditMode}>
                  <StyledButton
                     variant="filled-primary"
                     text="Prześlij do rozliczenia"
                     onClick={() => setSelectedModalOrDialog('SETTLEMENT_MODAL')}
                     disabled={isSomeSectionInEditMode}
                  />
               </FinishEditingSectionButtonTooltip>
            );
         case 'DURING_BILLING':
            return (
               <StyledButton
                  variant="filled-primary"
                  text={
                     !!order.additionalCharges.length
                        ? 'Prześlij do zapłaty i wygeneruj fakturę za koszty dodatkowe'
                        : 'Prześlij do zapłaty'
                  }
                  onClick={() => setSelectedModalOrDialog('SEND_FOR_PAYMENT')}
               />
            );
         case 'TO_PAY':
            return (
               <StyledButton
                  icon={<Check size={20} />}
                  variant="filled-primary"
                  text="Zamknij zamówienie"
                  onClick={() => setSelectedModalOrDialog('CLOSE_ORDER_MODAL')}
               />
            );
         default:
            return null;
      }
   }, [
      order?.status,
      order?.additionalCharges.length,
      handleClickAcceptOrderBtn,
      isOrderNonstandard,
      isSomeSectionInEditMode,
   ]);

   const isFree = order?.deliveryMethod?.free;
   const orderTotalPrice = !isFree
      ? `${currencyFormatter.format(countPalletsOrderTotalPrice(pallets) + chargesTotalAmount)} PLN`
      : 'bezpłatne';

   return (
      <>
         {isDataLoading && (
            <div className={styles.loaderContainer}>
               <Loader color="var(--primary-green)" />
            </div>
         )}
         {order && !isDataLoading && (
            <>
               <CancelOrderModal
                  opened={selectedModalOrDialog === 'CANCEL_ORDER_MODAL'}
                  onClose={handleCloseModalOrDialog}
                  selectedOrderId={order.id}
                  onSuccessCancelOrder={handleSuccessCancelOrder}
               />
               <RemoveOrderModal
                  opened={selectedModalOrDialog === 'REMOVE_ORDER_MODAL'}
                  onClose={handleCloseModalOrDialog}
                  selectedOrderId={order.id}
                  onSuccessRemoveOrder={handleSuccessRemoveOrder}
               />
               <div ref={orderDetailsHeaderRef} className={styles.detailsHeader}>
                  <div className={styles.headerLeft}>
                     <div className={styles.orderNumberContainer}>
                        <h2>Zamówienie #{params.id}</h2>
                        <StyledBadge
                           style={{ marginRight: 24 }}
                           text={ORDER_STATUSES_BADGE_DATA[order.status].label}
                           variant={ORDER_STATUSES_BADGE_DATA[order.status].variant}
                        />
                        {orderWithInvoicingStatuses.includes(order.status) &&
                           !order.hasInvoice &&
                           !isFree && (
                              <StyledButton
                                 type="button"
                                 text="Wystaw fakturę"
                                 variant="outlined-primary"
                                 loading={isSendAndCreateInvoiceLoading}
                                 onClick={handleCreateAndSendInvoice}
                              />
                           )}
                     </div>
                     <OrderAdditionalInfo
                        createdDate={order.createdDate}
                        internalOrderId={order.internalOrderId}
                     />
                  </div>
                  {orderDetailsOptions && <ActionsDropdown large options={orderDetailsOptions!} />}
               </div>
               {order.deliveryMethod?.free && (
                  <FreeOrderBanner
                     orderStatus={order.status}
                     isOrderAddedToNextOrder={order.deliveryMethod.addedToNextOrder}
                     orderAddedToOrderId={order.addedToOrderId}
                     className={styles.banner}
                  />
               )}
               {order.includesReceivedDamagedOrLostPallets && (
                  <Banner
                     variant="info"
                     children="W tym zamówieniu zostały zgłoszone uszkodzone/zniszczone palety z winy NDHP."
                     fullWidth
                     className={styles.banner}
                  />
               )}
               {!!order.relatedAdditionalOrderIDs.length && (
                  <FreeRelatedOrdersBanner
                     freeRelatedOrderIds={order.relatedAdditionalOrderIDs}
                     className={styles.banner}
                  />
               )}
               {order.status === 'WAITING' && isOrderNonstandard && (
                  <Banner
                     variant="warning"
                     children={
                        !order.hasMetLogisticsMinimum
                           ? 'To zamówienie jest zamówieniem niestandardowym i nie spełnia minimum logistycznego.'
                           : 'To zamówienie jest zamówieniem niestandardowym.'
                     }
                     className={styles.banner}
                     fullWidth
                  />
               )}
               {isRetentionDateExpired && (
                  <RetentionDateExpiredBanner
                     role="ROLE_SYSTEM_ADMIN"
                     delayTimeInDaysFromRetentionDate={delayTimeInDaysFromRetentionDate}
                  />
               )}
               {bannerData && (
                  <Banner
                     variant={bannerData.variant}
                     title={bannerData.title}
                     children={bannerData.description}
                     onClose={handleCloseBanner}
                     withCloseIcon
                     fullWidth
                     className={styles.banner}
                  />
               )}
               <div className={styles.tabsSection}>
                  <div className={styles.tabsContainer}>
                     <Tab
                        active={activeTab === 'ORDER'}
                        text="Zamówienie"
                        onClick={() => setActiveTab('ORDER')}
                     />
                     <Tab
                        active={activeTab === 'DOCUMENTS'}
                        text="Dokumenty"
                        onClick={() => setActiveTab('DOCUMENTS')}
                     />
                  </div>
                  <Divider className={styles.tabsBorder} variant="horizontal" />
               </div>
               {activeTab === 'ORDER' ? (
                  <>
                     {isFree && (
                        <>
                           <CustomSwitch
                              label="Zamówienie bezpłatne (wymiana uszkodzonych palet)"
                              checked
                              disabled
                              offLabel={<X size={16} color="white" />}
                              onLabel={<Check size={16} color="white" />}
                              style={{ margin: '0 0 48px 24px' }}
                           />
                           <SelectedDeliveryTypeSection
                              selectedDeliveryType={
                                 order.deliveryMethod?.addedToNextOrder
                                    ? 'ADDED_TO_NEXT_ORDER'
                                    : 'SEPARATE_ORDER'
                              }
                           />
                        </>
                     )}
                     {client && <ClientSection {...client} />}
                     {renderContent()}
                     <div className={styles.summaryContainer}>
                        <p>
                           <span>Podsumowanie wszystkich kosztów klienta:</span>
                           <span>{orderTotalPrice}</span>
                        </p>
                     </div>
                     {actionButtons && (
                        <div className={styles.buttonsContainer}>{actionButtons}</div>
                     )}
                  </>
               ) : (
                  <OrderDocuments
                     ref={orderDocumentsContentRef}
                     orderId={order.id}
                     displayBanner={setBannerData}
                     supplierMode
                  />
               )}
            </>
         )}
      </>
   );
};

export default WithAuth(OrderDetailsPage, 'ROLE_SYSTEM_ADMIN');
