import forOwn from 'lodash/forOwn';

import { CommercialNetworkDetailsDTO, DistributionCenterDTO } from 'interfaces';

import { sortOptions } from './sortOptions';
import { getFullAddress } from '.';

export const getCommercialNetworkOptions = (
   commercialNetworks: CommercialNetworkDetailsDTO[],
   getNameValue?: (commercialNetwork: CommercialNetworkDetailsDTO) => string,
   getAddressValues?: (address: DistributionCenterDTO) => string,
) => {
   const nameOptions = commercialNetworks.map(commercialNetwork => ({
      value: getNameValue ? getNameValue(commercialNetwork) : commercialNetwork.name,
      label: commercialNetwork.name,
   }));
   const addressOptionsArray = commercialNetworks.map(commercialNetwork => {
      const commercialNetworkAddressOptions = commercialNetwork.addresses.map(address => {
         const addressLabel = getFullAddress(
            address.address.street,
            address.address.zipCode,
            address.address.city,
         );
         return {
            value: getAddressValues ? getAddressValues(address) : addressLabel,
            label: addressLabel,
         };
      });
      return [
         getNameValue ? getNameValue(commercialNetwork) : commercialNetwork.name,
         commercialNetworkAddressOptions,
      ];
   });
   const addressOptions = Object.fromEntries(addressOptionsArray);
   const sortedNameOptions = sortOptions(nameOptions);
   const sortedAddressOptions = forOwn(addressOptions, options => sortOptions(options));
   return { nameOptions: sortedNameOptions, addressOptions: sortedAddressOptions };
};
