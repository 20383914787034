import classNames from 'classnames';

import { Table, TableProps } from 'storybook';

import styles from './StyledTable.module.css';

const StyledTable = ({ ...props }: TableProps) => (
   <Table
      {...props}
      className={classNames(styles.table, props.className)}
      headerClassName={classNames(styles.tableHeader, props.headerClassName)}
   >
      {props.children}
   </Table>
);

export default StyledTable;
