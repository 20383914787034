import { ChangeEvent } from 'react';

import { DatePicker } from '@mantine/dates';
import classNames from 'classnames';
import { AlertTriangle, Calendar, Plus, Trash } from 'tabler-icons-react';

import { ToolTip } from 'components/shared';
import StyledAutocomplete from 'components/shared/StyledAutocomplete/StyledAutocomplete';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { addressTypesWithoutPossibilityAddCustomAddress } from 'constants/addressTypesWithoutPossibilityAddCustomAddress';
import { DATE_PICKER_DATE_FORMAT } from 'constants/dateFormats';
import { Option } from 'interfaces/commonInterfacesAndTypes';
import {
   CommercialNetworkAddressOptions,
   SendPalletsItem,
   SendPalletsItemsErrors,
   SendPalletsItemsTouched,
} from 'interfaces/sendPalletsForm';
import { Checkbox } from 'storybook/components/Inputs/Checkbox/Checkbox';
import { getCommercialNetworkAddressOptions } from 'utils/functions/getCommercialNetworkAddressOptions';

import PalletInputGroup from './PalletInputGroup/PalletInputGroup';
import styles from './SendPalletsItemCard.module.css';

type SendPalletsItemCardProps = {
   index: number;
   item: SendPalletsItem;
   errors: SendPalletsItemsErrors;
   touched: SendPalletsItemsTouched;
   destinationNameOptions: Option[];
   destinationAddressOptions: CommercialNetworkAddressOptions;
   availablePalletTypes: Option[];
   onRemoveItem: (itemIndex: number) => void;
   onAddPalletsItem: (sendPalletsItemIndex: number) => void;
   onRemovePalletsItem: (sendPalletsItemIndex: number, palletsItemIndex: number) => void;
   onChangeItem: (
      sendPalletsItemIndex: number,
      field: string,
      value: string | Date | null | Option,
      sendPalletsItem: SendPalletsItem,
      clearTouchedState?: boolean,
   ) => void;
   onChangePalletsItem: (
      sendPalletsItemIndex: number,
      palletsItemIndex: number,
      field: string,
      value: string | Option,
   ) => void;
   onAddCommercialNetwork: (newCommercialNetwork: string) => void;
   onAddCommercialNetworkAddress: (
      commercialNetwork: string,
      newCommercialNetworkAddress: string,
   ) => void;
   sendDamagedPalletsMode?: boolean;
   isModalEditMode: boolean;
   onChangeIncludesDamagedPallets: (
      rowIndex: number,
      value: React.MouseEvent<Element, MouseEvent> & ChangeEvent<HTMLInputElement>,
      sendPalletsItem: SendPalletsItem,
   ) => void;
};

const SendPalletsItemCard = ({
   index,
   item,
   errors,
   touched,
   destinationNameOptions,
   destinationAddressOptions,
   availablePalletTypes,
   onRemoveItem,
   onAddPalletsItem,
   onRemovePalletsItem,
   onChangePalletsItem,
   onChangeItem,
   onAddCommercialNetwork,
   onAddCommercialNetworkAddress,
   sendDamagedPalletsMode,
   isModalEditMode,
   onChangeIncludesDamagedPallets,
}: SendPalletsItemCardProps) => (
   <div className={styles.container}>
      <div
         className={classNames(styles.removeItemBtnContainer, {
            [styles.withAlertIcon]: sendDamagedPalletsMode,
         })}
      >
         {sendDamagedPalletsMode && (
            <ToolTip content="Nadanie uszkodzonych palet" variant="right" visible>
               <AlertTriangle color={'var(--primary-red)'} />
            </ToolTip>
         )}
         {index !== 0 && (
            <StyledButton
               type="button"
               onClick={() => onRemoveItem(index)}
               icon={<Trash />}
               variant="text"
               className={styles.removeItemBtn}
            />
         )}
      </div>
      {isModalEditMode && (
         <div className={styles.damagedContainer}>
            <span>Uszkodzone</span>
            <Checkbox
               className={styles.checkbox}
               checked={item.includesDamagedPallets}
               onChangeHandler={value => onChangeIncludesDamagedPallets(index, value, item)}
            />
         </div>
      )}
      <DatePicker
         onChange={value => onChangeItem(index, 'sendDate', value, item)}
         value={item.sendDate}
         label="Data nadania"
         inputFormat={DATE_PICKER_DATE_FORMAT}
         clearable={false}
         icon={<Calendar color="#3E3E4B" size="20" />}
         locale="pl"
         style={{ width: '100%' }}
         className={styles.field}
         error={touched.sendDate && !!errors.sendDate}
      />
      <StyledTextInput
         type="text"
         onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeItem(index, 'externalCommercialNetworkNumber', event.target.value, item)
         }
         value={item.externalCommercialNetworkNumber}
         label="Numer zamówienia sieci (opcjonalnie)"
         error={touched.externalCommercialNetworkNumber && !!errors.externalCommercialNetworkNumber}
         fullWidth
         className={classNames(styles.field, styles.externalCommercialNetworkNumberInput)}
      />
      <StyledAutocomplete
         options={destinationNameOptions}
         onSelect={(value: Option) => {
            onChangeItem(index, 'commercialNetworkName', value, item);
            const newCommercialNetworkAddressValue =
               destinationAddressOptions[value.value].length === 1
                  ? destinationAddressOptions[value.value][0]
                  : {
                       value: '',
                       label: '',
                    };
            onChangeItem(
               index,
               'commercialNetworkAddress',
               newCommercialNetworkAddressValue,
               item,
               true,
            );
         }}
         value={item.commercialNetworkName}
         onlyTextMatch
         label="Miejsce nadania"
         error={touched.commercialNetworkName && !!errors.commercialNetworkName}
         dropdownButtonProps={{
            onClick: (enteredCommercialNetwork: string) =>
               onAddCommercialNetwork(enteredCommercialNetwork),
            text: 'Dodaj',
            displayEnteredText: true,
            disableOnTextMatch: true,
            icon: <Plus />,
            fullWidth: true,
            className: styles.dropdownBtn,
         }}
         fullWidth
         classNames={{ container: styles.field }}
      />
      <StyledAutocomplete
         options={getCommercialNetworkAddressOptions(item, destinationAddressOptions)}
         onSelect={(value: Option) => onChangeItem(index, 'commercialNetworkAddress', value, item)}
         value={item.commercialNetworkAddress}
         onlyTextMatch
         label="Adres nadania"
         disabled={!item.commercialNetworkName}
         error={touched.commercialNetworkAddress && !!errors.commercialNetworkAddress}
         dropdownButtonProps={
            !addressTypesWithoutPossibilityAddCustomAddress.includes(
               item.commercialNetworkName?.value,
            )
               ? {
                    onClick: (enteredCommercialNetworkAddress: string) =>
                       onAddCommercialNetworkAddress(
                          item.commercialNetworkName!.value,
                          enteredCommercialNetworkAddress,
                       ),
                    text: 'Dodaj',
                    displayEnteredText: true,
                    disableOnTextMatch: true,
                    icon: <Plus />,
                    fullWidth: true,
                    className: styles.dropdownBtn,
                 }
               : undefined
         }
         fullWidth
         classNames={{ container: styles.field }}
      />
      {item.palletsItems.map((palletsItem, palletsItemIndex) => (
         <PalletInputGroup
            key={palletsItem.uuid}
            uuid={palletsItem.uuid}
            palletType={palletsItem.palletType}
            palletsAmount={palletsItem.palletsAmount}
            withRemoveButton={palletsItemIndex !== 0}
            palletTypeError={
               touched.palletsItems[palletsItemIndex].palletType &&
               !!errors.palletsItems[palletsItemIndex].palletType
            }
            palletsAmountError={
               touched.palletsItems[palletsItemIndex].palletsAmount &&
               !!errors.palletsItems[palletsItemIndex].palletsAmount
            }
            availablePalletTypes={availablePalletTypes}
            onRemove={() => onRemovePalletsItem(index, palletsItemIndex)}
            onChange={(field: string, value: string | Option) =>
               onChangePalletsItem(index, palletsItemIndex, field, value)
            }
         />
      ))}
      <div className={styles.addPalletsItemBtnContainer}>
         <StyledButton
            type="button"
            onClick={() => onAddPalletsItem(index)}
            text="Dodaj typ palety"
            icon={<Plus />}
            variant="text"
         />
      </div>
   </div>
);

export default SendPalletsItemCard;
