import { read, utils } from 'xlsx';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const uploadImage = (file: any) => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
         resolve((reader.result as string).split(',')[1]);
      };
      reader.onerror = () => {
         reject(new Error('Błąd podczas ładowania pliku'));
      };
      reader.readAsDataURL(file);
   });
};

export const uploadExcelFile = <T>(file: File) => {
   return new Promise((resolve: (value: T[]) => void, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
         const workBook = read(reader.result, { type: 'array' });
         const workSheetName = workBook.SheetNames[0];
         const workSheet = workBook.Sheets[workSheetName];
         const workSheetData = utils.sheet_to_json<T>(workSheet, { raw: false });
         resolve(workSheetData);
      };
      reader.onerror = () => {
         reject(new Error('Błąd podczas ładowania pliku'));
      };
      reader.readAsArrayBuffer(file);
   });
};
