import { PalletsStackIcon } from 'assets';
import { NoItemsNotification, StyledTable } from 'components/shared';
import {
   damagedDestroyedLostPalletsByClientTableHeaders,
   lostDamagedPalletsInDeliveryClientTableHeaders,
} from 'constants/tableHeaders';
import { PalletReport } from 'interfaces';

import DamagedLostPalletsTableItem from './DamagedLostPalletsTableItem/DamagedLostPalletsTableItem';

type DamagedLostPalletsTableProps = {
   palletReports: PalletReport[];
   isDamagedLostBySupplier?: boolean;
};

const DamagedLostPalletsTable = ({
   palletReports,
   isDamagedLostBySupplier,
}: DamagedLostPalletsTableProps) => (
   <StyledTable
      columnHeaders={
         isDamagedLostBySupplier
            ? lostDamagedPalletsInDeliveryClientTableHeaders
            : damagedDestroyedLostPalletsByClientTableHeaders
      }
   >
      {palletReports.length ? (
         palletReports.map(palletReport => (
            <DamagedLostPalletsTableItem key={palletReport.id} palletReport={palletReport} />
         ))
      ) : (
         <tr>
            <td colSpan={isDamagedLostBySupplier ? 6 : 4}>
               <NoItemsNotification icon={<PalletsStackIcon />} description="Brak zgłoszeń." />
            </td>
         </tr>
      )}
   </StyledTable>
);

export default DamagedLostPalletsTable;
