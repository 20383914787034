import { Modal } from '@mantine/core';

import AddStorageForm from './AddStorageForm/AddStorageForm';
import styles from './AddStorageModal.module.css';

type AddStorageModalProps = {
   onClose: () => void;
   onSuccess: () => void;
};

const AddStorageModal = ({ onClose, onSuccess }: AddStorageModalProps) => (
   <Modal
      opened
      className={styles.modal}
      size={900}
      title="Dodaj nowy punkt magazynowy"
      onClose={onClose}
   >
      <div className={styles.container}>
         <AddStorageForm onClose={onClose} onSuccess={onSuccess} />
      </div>
   </Modal>
);
export default AddStorageModal;
