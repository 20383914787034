import classNames from 'classnames';

import styles from './SharedGridTable.module.css';

type SharedGridTableProps = {
   headers: React.ReactNode[];
   rows: React.ReactNode;
   style?: React.CSSProperties;
   className?: {
      headerRow?: string;
      headerCell?: string;
   };
};

const SharedGridTable = ({ headers, rows, style, className }: SharedGridTableProps) => {
   return (
      <div style={style} className={styles.container}>
         <div className={classNames(styles.headerRow, className?.headerRow)}>
            {headers.map((header, index) => (
               <div key={index} className={classNames(styles.headerCell, className?.headerCell)}>
                  {header}
               </div>
            ))}
         </div>
         {rows}
      </div>
   );
};

export default SharedGridTable;
