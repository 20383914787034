import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PageLayout } from 'components/layout';
import { PalletsBalanceContent, PalletsBalancePageHeader } from 'components/Pallets';
import { EDIT_USER_BALANCE_BANNER } from 'constants/banners';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import {
   SUPPLIER_DAMAGED_BY_CLIENT_PALLETS_PATH,
   SUPPLIER_DAMAGED_DESTROYED_BY_SUPPLIER_PALLETS_PATH,
   SUPPLIER_LOST_DESTROYED_BY_CLIENT_PALLETS_PATH,
} from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { useClientPalletsBalance } from 'hooks';
import {
   AddressOption,
   BannerData,
   ClientUserDTO,
   editPalletBalance,
   editPalletBalanceRequest,
   LostDamagedPalletsBalance,
   PalletAmountDTO,
   PalletBalance,
   PalletsBalanceModal,
} from 'interfaces';
import { HTTPService } from 'service';
import { getClientAddressOptions } from 'utils/functions';
import { logNetworkError } from 'utils/logNetworkError';
import { parseClientDeliveryAddressIntoDeliveryAddress } from 'utils/parsers';

import styles from './ClientPalletBalanceDetailsPage.module.css';

const ClientPalletBalanceDetailsPage = () => {
   const [client, setClient] = useState<ClientUserDTO>();
   const [clientAddressOptions, setClientAddressOptions] = useState<AddressOption>();
   const [isLoading, setIsLoading] = useState(true);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const [selectedModal, setSelectedModal] = useState<PalletsBalanceModal>(null);
   const {
      rentedPallets,
      damagedByClientPallets,
      lostOrDestroyedByClientPallets,
      damagedOrLostBySupplierPallets,
      remainingPallets,
      setRentedPallets,
      setDamagedByClientPallets,
      setLostOrDestroyedByClientPallets,
      setDamagedOrLostBySupplierPallets,
      setRemainingPallets,
      handleLostDamagedBySupplierPalletReports,
      handleLostByClientPalletReports,
      handleDamagedByClientPalletReports,
   } = useClientPalletsBalance();
   const { id: clientId } = useParams();
   const navigate = useNavigate();

   const handleData = useCallback(
      (
         clientData: ClientUserDTO,
         remainingPalletsData: PalletAmountDTO[],
         rentedPalletsBalanceData: PalletBalance,
         lostDamagedPalletsBalanceData: LostDamagedPalletsBalance,
      ) => {
         const { damagedByUserFault, lostOrDestroyedByUserFault, receivedDamaged } =
            lostDamagedPalletsBalanceData;
         setClient(clientData);
         setRentedPallets(rentedPalletsBalanceData);
         setDamagedByClientPallets(damagedByUserFault);
         setLostOrDestroyedByClientPallets(lostOrDestroyedByUserFault);
         setDamagedOrLostBySupplierPallets(receivedDamaged);
         setRemainingPallets(remainingPalletsData);
      },
      [
         setDamagedByClientPallets,
         setDamagedOrLostBySupplierPallets,
         setLostOrDestroyedByClientPallets,
         setRentedPallets,
         setRemainingPallets,
      ],
   );

   const prepareClientAddresses = useCallback(
      (clientData: ClientUserDTO) => {
         const clientDeliveryAddresses = clientData.deliveryAddresses.map(
            parseClientDeliveryAddressIntoDeliveryAddress,
         );
         const clientDeliveryAddressOptions = getClientAddressOptions(clientDeliveryAddresses);
         setClientAddressOptions({
            nameOptions: clientDeliveryAddressOptions.clientNameOption,
            addressOptions: clientDeliveryAddressOptions.clientAddressOptions,
         });
      },
      [setClientAddressOptions],
   );

   const fetchData = useCallback(() => {
      if (!clientId) {
         return;
      }
      setIsLoading(true);
      Promise.all([
         HTTPService.getUserDetails(clientId),
         HTTPService.getClientRentedPalletsBalance(clientId),
         HTTPService.getClientLostDamagedPalletsBalance(clientId),
         HTTPService.getRemainingPalletsByAdmin(clientId),
      ])
         .then(
            ([
               getClientDetailsResponse,
               getClientRentedPalletsBalanceResponse,
               getClientLostDamagedPalletsBalanceResponse,
               getRemainingPalletsByAdminResponse,
            ]) => {
               handleData(
                  getClientDetailsResponse.data,
                  getRemainingPalletsByAdminResponse.data.pallets,
                  getClientRentedPalletsBalanceResponse.data.summedAmountByPalletType,
                  getClientLostDamagedPalletsBalanceResponse.data,
               );
               prepareClientAddresses(getClientDetailsResponse.data);
            },
         )
         .catch(error => {
            setBannerData({ description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA, variant: 'error' });
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   }, [clientId, handleData, prepareClientAddresses]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   const displayReportLostDamagedPalletsModal = () =>
      setSelectedModal('REPORT_DAMAGE_LOSS_PALLETS');

   const displaySendPalletsModal = () => setSelectedModal('SEND_PALLETS');

   const displaySuccessReportLostDamagedPalletsModal = () =>
      setSelectedModal('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL');

   const displayAdmissionModal = () => setSelectedModal('ADMISSION_MODAL');

   const openEditBalanceModal = () => setSelectedModal('EDIT_USER_BALANCE');

   const closeModal = () => setSelectedModal(null);

   const displayBanner = (bannerDataToDisplay: BannerData) => setBannerData(bannerDataToDisplay);

   const hideBanner = () => setBannerData(null);

   const handleSuccessAdmissionPallet = () => {
      fetchData();
      closeModal();
   };

   const handleClickDamagedByClientPalletsDetails = () =>
      navigate(SUPPLIER_DAMAGED_BY_CLIENT_PALLETS_PATH(clientId || ''));

   const handleClickLostOrDestroyedByClientPalletsDetails = () =>
      navigate(SUPPLIER_LOST_DESTROYED_BY_CLIENT_PALLETS_PATH(clientId || ''));

   const handleClickDamagedOrLostBySupplierDetails = () =>
      navigate(SUPPLIER_DAMAGED_DESTROYED_BY_SUPPLIER_PALLETS_PATH(clientId || ''));

   const editUserBalance = (values: editPalletBalance[]) => {
      const mappedEditPalletBalance: editPalletBalanceRequest[] = values
         .filter(obj => obj.isAvailableForUser)
         .map(obj => ({
            palletType: obj.palletName,
            balance: {
               rented: Number(obj.rented),
               lostOrDestroyedByUserFault: Number(obj.lostOrDestroyedByUserFault),
               damagedByUserFault: Number(obj.damagedByUserFault),
               receivedDamaged: Number(obj.receivedDamaged),
            },
         }));
      setIsLoading(true);

      HTTPService.editClientBalance(clientId!, mappedEditPalletBalance)
         .then(() => {
            fetchData();
            closeModal();
            setBannerData(EDIT_USER_BALANCE_BANNER.ADD_SUCCESS);
         })
         .catch(error => {
            logNetworkError(error);
            setBannerData({
               variant: 'error',
               description: DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION,
            });
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <PageLayout
         headerElement={
            <PalletsBalancePageHeader
               isSupplierMode
               isButtonsDisabled={isLoading}
               displayReportLostDamagedPalletsModal={displayReportLostDamagedPalletsModal}
               displaySendPalletsModal={displaySendPalletsModal}
            />
         }
         bannerData={bannerData}
         isLoading={isLoading}
         classNames={{ pageContainer: styles.pageContainer, pageLoader: styles.pageLoader }}
         closeBanner={hideBanner}
      >
         <PalletsBalanceContent
            rentedPallets={rentedPallets}
            damagedByClientPallets={damagedByClientPallets}
            lostOrDestroyedByClientPallets={lostOrDestroyedByClientPallets}
            damagedOrLostBySupplierPallets={damagedOrLostBySupplierPallets}
            remainingPallets={remainingPallets}
            client={client}
            clientAddressOptions={clientAddressOptions}
            selectedModal={selectedModal}
            clientCompanyName={client?.companyName}
            onLostDamagedBySupplierPalletReports={handleLostDamagedBySupplierPalletReports}
            onLostByClientPalletReports={handleLostByClientPalletReports}
            onDamagedByClientPalletReports={handleDamagedByClientPalletReports}
            displayBanner={displayBanner}
            displaySendPalletsModal={displaySendPalletsModal}
            displaySuccessReportLostDamagedPalletsModal={
               displaySuccessReportLostDamagedPalletsModal
            }
            displayAdmissionModal={displayAdmissionModal}
            closeModal={closeModal}
            onClickDamagedByClientPalletsDetails={handleClickDamagedByClientPalletsDetails}
            onClickDamagedOrLostBySupplierDetails={handleClickDamagedOrLostBySupplierDetails}
            onClickLostOrDestroyedByClientPalletsDetails={
               handleClickLostOrDestroyedByClientPalletsDetails
            }
            onSuccessAdmissionPallet={handleSuccessAdmissionPallet}
            onClickEditUserBalance={editUserBalance}
            openEditBalanceModal={openEditBalanceModal}
         />
      </PageLayout>
   );
};

export default WithAuth(ClientPalletBalanceDetailsPage, 'ROLE_SYSTEM_ADMIN');
