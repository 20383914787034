import WarningModal from '../WarningModal';

type CloseWarningModalProps = {
   opened: boolean;
   onClose: () => void;
   onConfirm: () => void;
};

const CloseWarningModal = ({ opened, onClose, onConfirm }: CloseWarningModalProps) => (
   <WarningModal
      heading="Czy na pewno chcesz zamknąć to okno?"
      description="Jeśli zamkniesz to okno, zdefiniowane dotychczas dane nie zostaną zapisane."
      cancelBtnText="Nie zamykaj"
      confirmBtnText="Tak, zamknij okno bez zapisu danych"
      opened={opened}
      size="600"
      onClose={onClose}
      onConfirm={onConfirm}
   />
);

export default CloseWarningModal;
