import { EMPTY_INPUT_ERROR } from 'constants/validationErrors';
import { SendPalletsItem } from 'interfaces';
import { validateSendPalletsItemField } from 'utils/validation';

export const getSendPalletsItemErrors = (sendPalletsItem: SendPalletsItem) => {
   const palletsItems = sendPalletsItem.palletsItems.map(({ palletType, palletsAmount }) => ({
      palletType: validateSendPalletsItemField('palletType', palletType),
      palletsAmount: validateSendPalletsItemField('palletsAmount', palletsAmount),
   }));
   const { sendDate, commercialNetworkName, commercialNetworkAddress } = sendPalletsItem;
   return {
      sendDate: validateSendPalletsItemField('sendDate', sendDate),
      externalCommercialNetworkNumber: false as const,
      commercialNetworkName: commercialNetworkName
         ? validateSendPalletsItemField('commercialNetworkName', commercialNetworkName)
         : EMPTY_INPUT_ERROR,
      commercialNetworkAddress: commercialNetworkAddress
         ? validateSendPalletsItemField('commercialNetworkAddress', commercialNetworkAddress)
         : EMPTY_INPUT_ERROR,
      palletsItems: palletsItems,
   };
};
