import { Divider } from 'storybook';
import { getPalletLabel } from 'utils/functions';
import { currencyFormatter } from 'utils/numberFormatter';

import styles from './PalletsSummaryWithTotalPriceAndAmount.module.css';

type PalletsSummaryWithTotalPriceAndAmountProps = {
   palletsTotalPrice: number;
   palletsSum: number;
   isOrderFree: boolean;
};

const PalletsSummaryWithTotalPriceAndAmount = ({
   palletsTotalPrice,
   palletsSum,
   isOrderFree,
}: PalletsSummaryWithTotalPriceAndAmountProps) => (
   <div className={styles.palletsSummary}>
      <h3>Suma:</h3>
      <span>
         <span>{`${palletsSum} ${getPalletLabel(palletsSum)}`}</span>
         <Divider variant="vertical" className={styles.palletsSummaryDivider} />
         <span>
            {isOrderFree ? 'Bezpłatne' : `${currencyFormatter.format(palletsTotalPrice)} PLN`}
         </span>
      </span>
   </div>
);

export default PalletsSummaryWithTotalPriceAndAmount;
