import { PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR } from 'constants/validationErrors';

import { moreThanZero } from './validationFunctions';

const validateTransferPallets = (palletsCount: string) => {
   if (palletsCount === '') {
      return false;
   }
   return !moreThanZero(palletsCount) && PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR;
};

export const transferPalletsValidations = {
   validAvailablePallets: validateTransferPallets,
   invalidAvailablePallets: validateTransferPallets,
   validReturnPallets: validateTransferPallets,
   invalidReturnPallets: validateTransferPallets,
};
