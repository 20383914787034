import { Dispatch } from 'react';

import {
   EditPalletsConfigurationSection,
   OverviewPalletsConfigurationSection,
} from 'components/Order';
import { editableOrderStatuses } from 'constants/editableOrderStatuses';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { palletTypesData } from 'constants/palletTypes';
import { useOrderForm } from 'hooks/useOrderForm';
import {
   AddedToNextOrderDetails,
   ClientUserDTO,
   OrderDetails,
   OrderDetailsPageSection,
   OrderDetailsPallet,
   Pallet,
   PalletDataWithPrice,
   PalletSpecification,
   SupplierEditAddedToNextOrderData,
} from 'interfaces';
import { HTTPService } from 'service';
import {
   calculatePalletsAmountAsLogisticMinimum,
   countPalletsOrderTotalPrice,
   getOrderDetailsData,
   getPalletsFullData,
} from 'utils/functions';
import { editAddedToNextOrderFormValidations } from 'utils/validation';

type AddedToNextOrderDetailsPageProps = {
   order: AddedToNextOrderDetails;
   initialFormValues: SupplierEditAddedToNextOrderData;
   client: ClientUserDTO;
   pallets: OrderDetailsPallet[];
   palletsSum: number;
   isPalletsConfigurationSectionEditMode: boolean;
   isPalletsConfigurationSectionLoading: boolean;
   setOrder: Dispatch<React.SetStateAction<OrderDetails | undefined>>;
   dispatchSectionsEditMode: (dispatchData: {
      section: OrderDetailsPageSection;
      editMode: boolean;
   }) => void;
   dispatchSectionsLoading: (dispatchData: {
      section: OrderDetailsPageSection;
      isLoading: boolean;
   }) => void;
   onError: (error: unknown, errorDescription: string) => void;
};

const AddedToNextOrderDetailsPage = ({
   order,
   initialFormValues,
   client,
   pallets,
   palletsSum,
   isPalletsConfigurationSectionEditMode,
   isPalletsConfigurationSectionLoading,
   setOrder,
   dispatchSectionsEditMode,
   dispatchSectionsLoading,
   onError,
}: AddedToNextOrderDetailsPageProps) => {
   const {
      values,
      touched,
      errorsList,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      setValues,
   } = useOrderForm({
      initialState: initialFormValues,
      validations: editAddedToNextOrderFormValidations,
   });

   const handleSaveSectionData = () => {
      if (!errorsList.pallets) {
         const edittedPalletsData = prepareEdittedPalletsData();
         sendEditPalletsRequest(edittedPalletsData);
      }
   };

   const prepareEdittedPalletsData = () => {
      const palletsWithAdditionalInfo = getPalletsFullData(
         values.pallets,
         (pallet: PalletDataWithPrice) => (palletType: PalletSpecification) =>
            palletType.name === pallet.name,
      );
      const edittedPalletsData = palletsWithAdditionalInfo.map(palletWithAdditionalInfo => {
         const amount =
            palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM'
               ? parseInt(palletWithAdditionalInfo.amount) *
                 palletWithAdditionalInfo.logisticMinimum
               : parseInt(palletWithAdditionalInfo.amount);
         return {
            palletType: palletWithAdditionalInfo.name,
            amount,
            pricePerUnit: 0,
            shownAsMultipleOfTheMinimum: palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM',
         };
      });
      return edittedPalletsData;
   };

   const sendEditPalletsRequest = (edittedPalletsData: Pallet[]) => {
      dispatchSectionsLoading({ section: 'PALLETS_CONFIGURATION', isLoading: true });
      HTTPService.editPallets(order.id, edittedPalletsData)
         .then(({ data: editPalletsResponse }) => {
            setOrder(prevOrder =>
               prevOrder ? getOrderDetailsData(editPalletsResponse) : undefined,
            );
            dispatchSectionsEditMode({ section: 'PALLETS_CONFIGURATION', editMode: false });
         })
         .catch(error => onError(error, DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION))
         .finally(() =>
            dispatchSectionsLoading({ section: 'PALLETS_CONFIGURATION', isLoading: false }),
         );
   };

   const handleCancelSectionDataChanges = () => {
      dispatchSectionsEditMode({
         section: 'PALLETS_CONFIGURATION',
         editMode: false,
      });
      const palletsData: PalletDataWithPrice[] = order.pallets.map(pallet => ({
         name: pallet.palletType,
         amount: pallet.shownAsMultipleOfTheMinimum
            ? calculatePalletsAmountAsLogisticMinimum(pallet.palletType, pallet.amount).toString()
            : pallet.amount.toString(),
         orderType: pallet.shownAsMultipleOfTheMinimum ? 'LOGISTIC_MINIMUM' : 'CUSTOM',
         pricePerUnit: pallet.pricePerUnit,
      }));
      setValues(prevValues => ({
         ...prevValues,
         pallets: palletsData,
      }));
   };

   return (
      <>
         {isPalletsConfigurationSectionEditMode ? (
            <EditPalletsConfigurationSection
               pallets={values.pallets}
               availablePalletTypes={client.availablePalletTypes}
               palletTypes={palletTypesData}
               showPalletPrices
               isOrderFree
               selectedAddress={values.address}
               onPalletRadioChange={handlePalletRadioChange}
               onPalletQuantityChange={palletQuantityChangeHandler}
               onClearPallet={handleClearPallet}
               onSave={() => handleSaveSectionData()}
               onCancel={() => handleCancelSectionDataChanges()}
               errors={errorsList.pallets}
               touched={touched.pallets}
               isLoading={isPalletsConfigurationSectionLoading}
            />
         ) : (
            <OverviewPalletsConfigurationSection
               isOrderFree
               pallets={pallets}
               palletsSum={palletsSum}
               palletsTotalPrice={countPalletsOrderTotalPrice(pallets)}
               onClickEdit={
                  editableOrderStatuses.includes(order.status)
                     ? () =>
                          dispatchSectionsEditMode({
                             section: 'PALLETS_CONFIGURATION',
                             editMode: true,
                          })
                     : undefined
               }
            />
         )}
      </>
   );
};

export default AddedToNextOrderDetailsPage;
