import 'dayjs/locale/pl';

import { EditPalletsConfigurationSection } from 'components/Order';
import EditDeliverySection from 'components/Order/EditDeliverySection/EditDeliverySection';
import EditRentalPeriodSection from 'components/Order/EditRentalPeriodSection/EditRentalPeriodSection';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import { palletTypesData } from 'constants/palletTypes';
import { useOrderForm } from 'hooks/useOrderForm';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { ClientOrderData, DeliveryAddress } from 'interfaces';
import { placeOrderByClientFormValidations } from 'utils/validation/orderFormValidations';

import AddInternalOrderIdSection from './AddInternalOrderIdSection/AddInternalOrderIdSection';
import styles from './PlaceOrderForm.module.css';

type PlaceOrderFormProps = {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   onSubmit: (values: any) => void;
   onChangeLogisticMinimumAchieved: React.Dispatch<React.SetStateAction<boolean>>;
   initialState: ClientOrderData;
   clientDeliveryAddresses: DeliveryAddress[];
   availablePalletTypes: string[];
   retentionPeriodDays?: number;
};

const PlaceOrderForm = ({
   onSubmit,
   onChangeLogisticMinimumAchieved,
   initialState,
   clientDeliveryAddresses,
   availablePalletTypes,
   retentionPeriodDays,
}: PlaceOrderFormProps) => {
   const {
      values,
      touched,
      errorsList,
      handleSubmit,
      selectChangeHandler,
      changeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      deliveryAddressOptions,
      holdingPeriod,
   } = useOrderForm({
      initialState,
      deliveryAddresses: clientDeliveryAddresses,
      retentionPeriodDays,
      onSubmit,
      onChangeLogisticMinimumAchieved,
      validations: placeOrderByClientFormValidations,
   });
   const { isMobile } = useResponsiveLayout();

   return (
      <form name="place-order" onSubmit={handleSubmit} className={styles.orderForm}>
         <AddInternalOrderIdSection
            internalOrderId={values.internalOrderId}
            onChangeInternalOrderId={changeHandler}
         />
         <EditDeliverySection
            selectedAddress={values.address}
            availableAddresses={deliveryAddressOptions}
            onChangeAddress={selectChangeHandler}
            error={errorsList.address}
            touched={touched.address}
            isMobile={isMobile}
         />
         <EditRentalPeriodSection
            deliveryDate={values.palletDeliveryDate}
            holdingPeriod={holdingPeriod}
            retentionPeriodDays={retentionPeriodDays}
            onSelectChange={selectChangeHandler}
            error={errorsList.palletDeliveryDate}
            touched={touched.palletDeliveryDate}
         />
         <EditPalletsConfigurationSection
            pallets={values.pallets}
            availablePalletTypes={availablePalletTypes}
            palletTypes={palletTypesData}
            onPalletRadioChange={handlePalletRadioChange}
            onPalletQuantityChange={palletQuantityChangeHandler}
            onClearPallet={handleClearPallet}
            errors={errorsList.pallets}
            touched={touched.pallets}
         />
         <StyledButton
            style={{ float: 'right', marginTop: 10 }}
            type="submit"
            variant="outlined-primary"
            text="Przejdź do podsumowania"
            className={styles.submitButton}
         />
      </form>
   );
};

export default PlaceOrderForm;
