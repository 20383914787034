import { ComponentType, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { onAuthStateChanged } from 'firebase/auth';

import LoadingPage from 'components/shared/LoadingPage/LoadingPage';
import { auth } from 'config/firebase';
import { UserRole } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import { LOGIN } from '../constants/routes';
import { useAuth } from '../context/auth/AuthContextProvider';
import { getDefaultUserPageByRole } from '../utils/functions';

const WithAuth = (Page: ComponentType, allowedRole: UserRole) => {
   const AuthenticatedPage = () => {
      const [isPageVisible, setIsPageVisible] = useState(false);
      const { user, setUser } = useAuth();
      const navigate = useNavigate();

      const handleNoUser = useCallback(() => {
         setUser(null);
         navigate(LOGIN);
      }, [navigate, setUser]);

      const fetchUserData = useCallback(
         () =>
            HTTPService.getCurrentUserDetails().then(({ data: userDetails }) => {
               setUser(userDetails);
               return userDetails;
            }),
         [setUser],
      );

      const redirectByUserRoleIfNeed = useCallback(
         (userRole: UserRole) =>
            userRole !== allowedRole &&
            navigate(getDefaultUserPageByRole(userRole), { replace: true }),
         [navigate],
      );

      const handleUserData = useCallback(
         (userRole: UserRole) => {
            redirectByUserRoleIfNeed(userRole);
            setIsPageVisible(true);
         },
         [redirectByUserRoleIfNeed],
      );

      const handleAddUserSignInObserver = useCallback(
         () =>
            onAuthStateChanged(auth, firebaseUser => {
               if (!firebaseUser) {
                  handleNoUser();
                  return;
               }
               fetchUserData()
                  .then(userData => handleUserData(userData.mainRole))
                  .catch((error: Error) => logNetworkError(error));
            }),
         [fetchUserData, handleNoUser, handleUserData],
      );

      useEffect(() => {
         if (!user) {
            const unsubscribe = handleAddUserSignInObserver();
            return () => unsubscribe();
         } else {
            handleUserData(user.mainRole);
         }
      }, [handleAddUserSignInObserver, user, handleUserData]);

      return isPageVisible ? <Page /> : <LoadingPage />;
   };
   return AuthenticatedPage;
};

export default WithAuth;
