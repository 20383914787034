import { BadgeIcon } from 'assets';

import styles from './PalletsPricingRowLabel.module.css';

type PalletsPricingRowLabelProps = {
   addressName: string;
   isPricingDifferentFromBase: boolean;
};

const PalletsPricingRowLabel = ({
   addressName,
   isPricingDifferentFromBase,
}: PalletsPricingRowLabelProps) => (
   <span className={styles.container}>
      {addressName}
      {isPricingDifferentFromBase && <BadgeIcon />}
   </span>
);

export default PalletsPricingRowLabel;
