import { NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } from 'constants/validationErrors';

import { moreOrEqualZero } from './validationFunctions';

export const editPalletsBalanceValidations = {
   validAvailablePallets: (validAvailablePallets: string) =>
      !moreOrEqualZero(validAvailablePallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   invalidAvailablePallets: (invalidAvailablePallets: string) =>
      !moreOrEqualZero(invalidAvailablePallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   validReturnPallets: (validReturnPallets: string) =>
      !moreOrEqualZero(validReturnPallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   invalidReturnPallets: (invalidReturnPallets: string) =>
      !moreOrEqualZero(invalidReturnPallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   sumOfReturnPallets: (sumOfReturnPallets: string) =>
      !moreOrEqualZero(sumOfReturnPallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   sumOfAvailablePallets: (sumOfAvailablePallets: string) =>
      !moreOrEqualZero(sumOfAvailablePallets) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
};
