import { useMemo } from 'react';

import {
   DamagedLostByClientPalletsSummarySection,
   DamagedLostBySupplierPalletsSummarySection,
   RentedPalletsSummarySection,
} from 'components/Pallets';
import { ReportLostDamagedPalletsModal } from 'components/ReportLostDamagedPalletsModal';
import { SendPalletsModal } from 'components/SendPalletsModal';
import { EditClientBalanceModal } from 'components/Storages';
import SuccessReportLostDamagedPalletsBySupplierModal from 'components/SuccessReportLostDamagedPalletsBySupplierModal/SuccessReportLostDamagedPalletsBySupplierModal';
import { MovePalletsModal } from 'components/SupplierPanel';
import { ClientSection } from 'components/SupplierPanel';
import { useSendPalletsForm } from 'hooks/useSendPalletsForm';
import {
   AddressOption,
   BannerData,
   BannerVariants,
   ClientUserDTO,
   GroupedLostDamagedPalletItems,
   OrderDTO,
   PalletAmountDTO,
   PalletBalance,
   PalletReportDTO,
   PalletsBalanceModal,
} from 'interfaces';
import { editPalletBalance } from 'interfaces/dto/editPalletBalance';
import { getOrderLabel, getOrdersIdsLabel, getPalletTypes } from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';

type PalletsBalanceContentProps = {
   rentedPallets: PalletBalance;
   damagedByClientPallets: PalletBalance;
   lostOrDestroyedByClientPallets: PalletBalance;
   damagedOrLostBySupplierPallets: PalletBalance;
   remainingPallets: PalletAmountDTO[];
   client?: ClientUserDTO;
   clientCompanyName?: string;
   clientAddressOptions?: AddressOption;
   selectedModal: PalletsBalanceModal;
   onLostDamagedBySupplierPalletReports: (reports: PalletReportDTO[]) => void;
   onLostByClientPalletReports: (reports: PalletReportDTO[]) => void;
   onDamagedByClientPalletReports: (reports: PalletReportDTO[]) => void;
   displayBanner: (bannerData: BannerData) => void;
   displaySuccessReportLostDamagedPalletsModal: () => void;
   displaySendPalletsModal: () => void;
   displayAdmissionModal?: () => void;
   closeModal: () => void;
   onClickDamagedByClientPalletsDetails: () => void;
   onClickLostOrDestroyedByClientPalletsDetails: () => void;
   onClickDamagedOrLostBySupplierDetails: () => void;
   onSuccessAdmissionPallet?: () => void;
   openEditBalanceModal?: () => void;
   onClickEditUserBalance?: (palletBalances: editPalletBalance[]) => void;
};

const PalletsBalanceContent = ({
   rentedPallets,
   damagedByClientPallets,
   lostOrDestroyedByClientPallets,
   damagedOrLostBySupplierPallets,
   remainingPallets,
   client,
   clientCompanyName,
   clientAddressOptions,
   selectedModal,
   onLostDamagedBySupplierPalletReports,
   onLostByClientPalletReports,
   onDamagedByClientPalletReports,
   displayBanner,
   displaySuccessReportLostDamagedPalletsModal,
   displaySendPalletsModal,
   displayAdmissionModal,
   closeModal,
   onClickDamagedByClientPalletsDetails,
   onClickLostOrDestroyedByClientPalletsDetails,
   onClickDamagedOrLostBySupplierDetails,
   onSuccessAdmissionPallet,
   onClickEditUserBalance,
   openEditBalanceModal,
}: PalletsBalanceContentProps) => {
   const {
      groupedLostDamagedPalletItems,
      sendingAddressOptions,
      setGroupedLostDamagedPalletItems,
      handleAddCommercialNetworkOption,
      handleAddCommercialNetworkAddressOption,
   } = useSendPalletsForm(clientAddressOptions, client?.id);

   const handleSuccessSendPallets = (orders: OrderDTO[]) => {
      const ordersIds = orders.map(order => order.id);
      displayBanner({
         title: 'Poprawnie nadano palety',
         description: `Palety rozdysponowano do ${getOrderLabel(
            ordersIds.length,
         )} ${getOrdersIdsLabel(ordersIds)}.`,
         variant: 'success',
      });
   };

   const handleSuccessReportLostDestroyedPalletsByClient = (reports: PalletReportDTO[]) => {
      const ordersIds = reports.map(report => report.orderId);
      onLostByClientPalletReports(reports);
      onDamagedByClientPalletReports(reports);
      displayBanner({
         title: `Zgubione lub zniszczone palety zostały doliczone do ${getOrderLabel(
            ordersIds.length,
         )} ${getOrdersIdsLabel(ordersIds)}.`,
         description:
            'Szczegółowe informacje dotyczące zniszczenia lub zgubienia palet znajdziesz poniżej lub w podglądzie poszczególnych zamówień w sekcji “Dodatkowe opłaty”.',
         variant: 'success',
      });
   };

   const handleSuccessReportLostDestroyedPalletsBySupplier = (reports: PalletReportDTO[]) => {
      const ordersIds = reports.map(report => report.orderId);
      onLostDamagedBySupplierPalletReports(reports);
      displaySuccessReportLostDamagedPalletsModal();
      displayBanner({
         title: `Zgubione lub zniszczone palety zostały doliczone do ${getOrderLabel(
            ordersIds.length,
         )} ${getOrdersIdsLabel(ordersIds)}.`,
         variant: 'success',
      });
   };

   const handleReportDamagedPallets = (
      groupedLostDamagedPalletItemsData: GroupedLostDamagedPalletItems,
   ) => {
      setGroupedLostDamagedPalletItems(groupedLostDamagedPalletItemsData);
      displaySendPalletsModal();
   };

   const handleSuccessReportAndSendLostDamagedPalletsFromMultipleOrders = (
      damagedBySupplier: boolean,
      palletReports: PalletReportDTO[],
   ) => {
      const ordersIds = palletReports.map(report => report.orderId);
      if (damagedBySupplier) {
         onLostDamagedBySupplierPalletReports(palletReports);
         displaySuccessReportLostDamagedPalletsModal();
      } else {
         onLostByClientPalletReports(palletReports);
         onDamagedByClientPalletReports(palletReports);
      }
      setGroupedLostDamagedPalletItems(null);
      displayBanner({
         title: `Zgubione/zniszczone i/lub uszkodzone palety zostały doliczone do ${getOrderLabel(
            ordersIds.length,
         )} ${getOrdersIdsLabel(ordersIds)} oraz utworzono do nich nowe nadania paletowe.`,
         description:
            'Szczegółowe informacje dotyczące zniszczenia, zgubienia lub uszkodzenia palet znajdziesz poniżej lub w podglądzie poszczególnych zamówień w sekcji “Dodatkowe opłaty”.',
         variant: 'success',
      });
   };

   const availablePalletTypes = useMemo(() => {
      if (groupedLostDamagedPalletItems) {
         const palletTypes = groupedLostDamagedPalletItems.damaged.map(
            itemToSend => itemToSend.palletType,
         );
         return sortOptions(palletTypes);
      } else if (remainingPallets) {
         return getPalletTypes(remainingPallets);
      } else {
         return [];
      }
   }, [remainingPallets, groupedLostDamagedPalletItems]);

   const isSupplierView = !!client;

   return (
      <>
         {client && (
            <EditClientBalanceModal
               onClose={closeModal}
               onSubmit={onClickEditUserBalance!}
               isOpen={selectedModal === 'EDIT_USER_BALANCE'}
               client={client}
               rentedPalletsBalance={rentedPallets}
               damagedByClientPallets={damagedByClientPallets}
               lostOrDestroyedByClientPallets={lostOrDestroyedByClientPallets}
               damagedOrLostBySupplierPallets={damagedOrLostBySupplierPallets}
            />
         )}
         <SendPalletsModal
            groupedLostDamagedPalletItems={groupedLostDamagedPalletItems}
            availablePalletTypes={availablePalletTypes}
            clientId={client?.id}
            clientCompanyName={clientCompanyName}
            onSuccessSendPalletsFromMultipleOrders={handleSuccessSendPallets}
            onSuccessReportAndSendLostDamagedPalletsFromMultipleOrders={
               handleSuccessReportAndSendLostDamagedPalletsFromMultipleOrders
            }
            opened={selectedModal === 'SEND_PALLETS'}
            onClose={() => {
               closeModal();
               setGroupedLostDamagedPalletItems(null);
            }}
            sendingAddressOptions={sendingAddressOptions}
            onAddCommercialNetwork={handleAddCommercialNetworkOption}
            onAddCommercialNetworkAddress={handleAddCommercialNetworkAddressOption}
         />
         <ReportLostDamagedPalletsModal
            availablePalletTypes={availablePalletTypes}
            remainingPalletsToBeSend={remainingPallets}
            pricesForPallets={client?.rateConfig?.palletRates}
            clientId={client?.id}
            isSupplier={isSupplierView}
            onSuccessReportLostDestroyedPalletsByClient={
               handleSuccessReportLostDestroyedPalletsByClient
            }
            onSuccessReportLostDestroyedPalletsBySupplier={
               handleSuccessReportLostDestroyedPalletsBySupplier
            }
            opened={selectedModal === 'REPORT_DAMAGE_LOSS_PALLETS'}
            onClose={closeModal}
            onReportDamagedPallets={handleReportDamagedPallets}
         />
         <SuccessReportLostDamagedPalletsBySupplierModal
            isSupplier={!!client}
            opened={selectedModal === 'SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL'}
            onClose={closeModal}
         />
         {selectedModal === 'ADMISSION_MODAL' && onSuccessAdmissionPallet && (
            <MovePalletsModal
               onClose={closeModal}
               onSuccess={onSuccessAdmissionPallet}
               showBanner={(variant: BannerVariants, description: string) =>
                  displayBanner({ variant: variant, description: description })
               }
               mode="CLIENT"
               client={client}
            />
         )}
         {client && <ClientSection {...client} />}
         <RentedPalletsSummarySection
            isSupplierView={isSupplierView}
            rentedPalletsSummaryData={rentedPallets}
            openAdmissionPalletsModal={displayAdmissionModal}
            onEditBalanceClick={openEditBalanceModal}
         />
         <DamagedLostByClientPalletsSummarySection
            damagedByClientPallets={damagedByClientPallets}
            lostOrDestroyedByClientPallets={lostOrDestroyedByClientPallets}
            isSupplierView={isSupplierView}
            onClickDamagedByClientPalletsDetails={onClickDamagedByClientPalletsDetails}
            onClickLostOrDestroyedByClientPalletsDetails={
               onClickLostOrDestroyedByClientPalletsDetails
            }
         />
         <DamagedLostBySupplierPalletsSummarySection
            damagedOrLostBySupplierPallets={damagedOrLostBySupplierPallets}
            onClickDamagedOrLostBySupplierDetails={onClickDamagedOrLostBySupplierDetails}
            isSupplierView={isSupplierView}
         />
      </>
   );
};

export default PalletsBalanceContent;
