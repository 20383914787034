import { useState } from 'react';

import FsLightbox from 'fslightbox-react';

import { ImagesPreview } from '..';

type ImagesContainerProps = {
   imageURLs: string[];
   isImagesPreviewLeftSidePosition?: boolean;
};

const DEFAULT_FIRST_SLIDE_TO_DISPLAY_INDEX = 1;

const ImagesContainer = ({ imageURLs, isImagesPreviewLeftSidePosition }: ImagesContainerProps) => {
   const [imageViewerToggler, setImageViewerToggler] = useState(false);
   const [firstSlideToDisplayIndex, setFirstSlideToDisplayIndex] = useState(
      DEFAULT_FIRST_SLIDE_TO_DISPLAY_INDEX,
   );

   const handleClickImage = (imageIndex: number) => {
      setFirstSlideToDisplayIndex(imageIndex + DEFAULT_FIRST_SLIDE_TO_DISPLAY_INDEX);
      setImageViewerToggler(prevImageViewerToggler => !prevImageViewerToggler);
   };

   return (
      <>
         <FsLightbox
            type="image"
            toggler={imageViewerToggler}
            sources={imageURLs}
            slide={firstSlideToDisplayIndex}
         />
         <ImagesPreview
            imageURLs={imageURLs}
            isLeftSidePosition={isImagesPreviewLeftSidePosition}
            onClickImage={handleClickImage}
         />
      </>
   );
};

export default ImagesContainer;
