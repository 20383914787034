import { BannerData } from 'interfaces';

export const SUPPLIER_ORDER_DETAILS_BANNERS: { [key: string]: BannerData } = {
   CANCEL: {
      variant: 'error',
      description:
         'Anulowano zamówienie. Jeśli została wystawiona faktura do tego zamówienia, to pamiętaj o wycofaniu jej z systemu!',
   },
   REFUSE: {
      variant: 'error',
      description: 'Odrzucono ofertę i anulowano zamówienie.',
   },
   ACCEPT: {
      variant: 'success',
      title: 'Oferta została zaakceptowana.',
      description: 'Zamówienie zostało przesłane do klienta i zmieniło status na „W realizacji”.',
   },
   TO_PAY: {
      variant: 'success',
      description: 'Zamówienie zostało wysłane do zapłaty.',
   },
   ENDED: {
      variant: 'success',
      description: 'Zamówienie zostało zamknięte.',
   },
   SUBMIT_FOR_BILLING: {
      variant: 'success',
      title: 'Przesłano do rozliczenia.',
      description:
         'Zamówienie zmieniło status na „Rozliczane”, czeka na sprawdzenie i potwierdzenie dodatkowych obciążeń oraz wystawienie faktur.',
   },
   ADD_ADDITIONAL_CHARGES: {
      variant: 'success',
      description: 'Dodatkowe obciążenia zostały dodane do zamówienia.',
   },
   SEND_PALLETS: {
      variant: 'success',
      description: 'Poprawnie nadano palety.',
   },
   EDIT_SEND_PALLETS: {
      variant: 'success',
      description: 'Poprawnie edytowano nadania paletowe.',
   },
   SEND_DAMAGED_PALLETS: {
      variant: 'success',
      title: 'Zgubione, zniszczone lub uszkodzone palety zostały dodane do Tego zamówienia oraz utworzono nowe nadania paletowe.',
      description:
         'Szczegółowe informacje dotyczące zniszczenia, zgubienia lub uszkodzenia palet znajdziesz w sekcji “Dodatkowe opłaty” oraz “Nadania” poniżej.',
   },
   REPORT_LOST_DESTROYED_PALLETS_BY_CLIENT: {
      variant: 'success',
      title: 'Zgubione lub zniszczone palety zostały dodane do Tego zamówienia.',
      description:
         'Szczegółowe informacje dotyczące zgubienia lub zniszczenia palet znajdziesz w sekcji “Dodatkowe opłaty” poniżej.',
   },
   REPORT_LOST_DESTROYED_PALLETS_BY_SUPPLIER: {
      variant: 'success',
      title: 'Zgubione lub zniszczone palety zostały dodane do Tego zamówienia.',
      description:
         'Szczegółowe informacje dotyczące zgubienia lub zniszczenia palet znajdziesz w sekcji poniżej.',
   },
};

export const CLIENT_ORDER_DETAILS_BANNERS = {
   REPORT_LOST_DESTROYED_PALLETS_BY_CLIENT: {
      variant: 'success',
      title: 'Zgubione lub zniszczone palety zostały dodane do Twojego zamówienia.',
      description:
         'Szczegółowe informacje dotyczące zgubienia lub zniszczenia palet znajdziesz w sekcji “Dodatkowe opłaty” poniżej lub w zakładce “Palety - Saldo”.',
   },
   REPORT_LOST_DESTROYED_PALLETS_BY_SUPPLIER: {
      variant: 'success',
      title: 'Zgubione lub zniszczone palety zostały dodane do Twojego zamówienia.',
   },
   SEND_FOR_SETTLEMENT: {
      variant: 'success',
      title: 'Przesłano do rozliczenia.',
      description: 'Twoje zamówienie zostało przesłane do działu księgowości NDHP.',
   },
   SEND_PALLETS: {
      variant: 'success',
      description: 'Poprawnie nadano palety.',
   },
   SEND_DAMAGED_PALLETS: {
      variant: 'success',
      title: 'Zgubione, zniszczone lub uszkodzone palety zostały dodane do Tego zamówienia oraz utworzono nowe nadania paletowe.',
      description:
         'Szczegółowe informacje dotyczące zniszczenia, zgubienia lub uszkodzenia palet znajdziesz w sekcji “Dodatkowe opłaty” oraz “Nadania” poniżej.',
   },
   EDIT_INTERNAL_ORDER_ID_SUCCESS: {
      variant: 'success',
      description: 'Udało się! Numer wewnętrzny zamówienia został zmieniony.',
   },
} as const;

export const ORDER_DOCUMENTS_BANNERS = {
   ADD_DOCUMENT_SUCCESS: {
      variant: 'success',
      description: 'Poprawnie dodano dokument.',
   },
   ADD_DOCUMENT_ERROR: {
      variant: 'error',
      description: 'Podczas dodawania dokumentu wystąpił błąd.',
   },
   REMOVE_DOCUMENT_SUCCESS: { variant: 'success', description: 'Dokument został usunięty.' },
   REMOVE_DOCUMENT_ERROR: {
      variant: 'error',
      description: 'Podczas usuwania dokumentu wystąpił błąd.',
   },
   FETCH_DOCUMENTS_ERROR: {
      variant: 'error',
      description: 'Podczas pobierania dokumentów wystąpił błąd.',
   },
} as const;

export const COMMERCIAL_NETWORK_PAGE_BANNERS: { [key: string]: BannerData } = {
   ADD_DISTRIBUTION_CENTER_SUCCESS: {
      variant: 'success',
      description: 'Centrum Dystrybucyjne zostało dodane.',
      withCloseIcon: true,
   },
   DELETE_COMMERCIAL_NETWORK: {
      variant: 'success',
      withCloseIcon: true,
      description: 'Sieć Handlowa została usunięta.',
   },
   DELETE_DISTRIBUTION_CENTER: {
      variant: 'success',
      withCloseIcon: true,
      description: 'Centrum Dystrybucyjne zostało usunięte.',
   },
};

export const SUPPLIER_ORDERS_DASHBOARD_BANNERS: { [key: string]: BannerData } = {
   SUCCESS_PLACE_ORDER: {
      variant: 'success',
      title: 'Zamówienie dodane.',
      description: 'Zamówienie będzie widoczne u klienta ze statusem “W realizacji”.',
   },
   SUCCESS_REMOVE_ORDER: {
      variant: 'error',
      description: 'Zamówienie zostało usunięte.',
   },
};

export const ADD_OR_EDIT_CLIENT_PAGE_BANNERS = {
   ADD_COMMERCIAL_NETWORK_SUCCESS: {
      variant: 'success',
      description: 'Sieć Handlowa została dodana.',
   },
   ADD_DISTRIBUTION_CENTER_SUCCESS: {
      variant: 'success',
      description: 'Centrum Dystrybucyjne zostało dodane.',
   },
} as const;

export const DISTRIBUTION_CENTER_PAGE_BANNERS: { [key: string]: BannerData } = {
   TRANSFER_PALLETS_SUCCESS: {
      variant: 'success',
      description: 'Palety zostały przerzucone na saldo „Do odbioru”.',
      withCloseIcon: true,
   },
   ADMISSION_PALLETS: {
      variant: 'success',
      withCloseIcon: true,
      description: 'Palety zostały przerzucone do wybranego magazynu.',
   },
};

export const PALLET_PICK_UP_PAGE_BANNERS = {
   ADD_SUCCESS: {
      variant: 'success',
      description: 'Prośba odbioru palet została zgłoszona wynajmującemu. ',
   },
} as const;

export const EDIT_USER_BALANCE_BANNER = {
   ADD_SUCCESS: {
      variant: 'success',
      description: 'Saldo palet zostało zaktualizowane.',
   },
} as const;

export const LOGOUT_ERROR_BANNER = {
   variant: 'error',
   description: 'Nie udało się wylogować. Spróbuj ponownie.',
} as const;

export const PALLETS_TRANSFER_HISTORY_PAGE_BANNERS = {
   GET_DATA_IN_XLSX_FORMAT_SUCCESS: {
      variant: 'success',
      description: 'Udało się! Dane zostały pobrane.',
      withCloseIcon: true,
   },
   GET_DATA_IN_XLSX_FORMAT_ERROR: {
      variant: 'error',
      description: 'Podczas pobierania danych wystąpił błąd.',
      withCloseIcon: true,
   },
} as const;

export const SUPPLIER_PALLETS_PICK_UPS_PAGE_BANNERS: Record<string, BannerData> = {
   ACCEPT_PALLET_PICK_UP_SUCCESS: {
      variant: 'success',
      description: 'Odbiór palet został potwierdzony.',
      withCloseIcon: true,
   },
   REJECT_PALLET_PICK_UP_SUCCESS: {
      variant: 'error',
      description: 'Odbiór palet został odrzucony.',
      withCloseIcon: true,
   },
};
