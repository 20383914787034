import classNames from 'classnames';
import { Trash } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledSelect from 'components/shared/StyledSelect/StyledSelect';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { Option } from 'interfaces/commonInterfacesAndTypes';
import {
   PalletsItem,
   PalletsItemError,
   PalletsItemTouched,
   SendPalletsItem,
} from 'interfaces/sendPalletsForm';
import { TableCell, TableRow } from 'storybook';

import styles from './PalletsItemRow.module.css';

type PalletsItemRow = {
   palletsItem: PalletsItem;
   sendPalletsItem: SendPalletsItem;
   palletsItemIndex: number;
   sendPalletsItemIndex: number;
   availablePalletTypes: Option[];
   palletsItemError: PalletsItemError;
   palletsItemTouched: PalletsItemTouched;
   onChangePalletsItem: (
      sendPalletsItemIndex: number,
      palletsItemIndex: number,
      field: string,
      value: string | Option,
   ) => void;
   onRemovePalletsItem: (sendPalletsItemIndex: number, palletsItemIndex: number) => void;
   sendDamagedPalletsMode?: boolean;
   isModalEditMode: boolean;
};

const PalletsItemRow = ({
   palletsItem,
   sendPalletsItem,
   palletsItemIndex,
   sendPalletsItemIndex,
   availablePalletTypes,
   palletsItemError,
   palletsItemTouched,
   onChangePalletsItem,
   onRemovePalletsItem,
   sendDamagedPalletsMode,
   isModalEditMode,
}: PalletsItemRow) => (
   <TableRow key={palletsItem.uuid} className={styles.tableRow}>
      {(sendDamagedPalletsMode || isModalEditMode) && <TableCell />}
      <TableCell className={classNames(styles.tableCell, styles.sendDateCell)} />
      <TableCell
         className={classNames(styles.tableCell, styles.externalCommercialNetworkNumberCell)}
      />
      <TableCell className={classNames(styles.tableCell, styles.commercialNetworkNameCell)} />
      <TableCell className={classNames(styles.tableCell, styles.commercialNetworkAddressCell)} />
      <TableCell className={classNames(styles.tableCell, styles.palletTypeCell)}>
         <StyledSelect
            options={availablePalletTypes.filter(availablePalletType =>
               sendPalletsItem.palletsItems.every(
                  item => item.palletType.value !== availablePalletType.value,
               ),
            )}
            onChange={(value: Option) =>
               onChangePalletsItem(sendPalletsItemIndex, palletsItemIndex, 'palletType', value)
            }
            value={sendPalletsItem.palletsItems[palletsItemIndex].palletType}
            error={palletsItemTouched.palletType && !!palletsItemError.palletType}
            portalTarget={document.body}
            classNames={{ select: styles.selectPalletType }}
         />
      </TableCell>
      <TableCell className={classNames(styles.tableCell, styles.palletsAmountCell)}>
         <StyledTextInput
            type="number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
               onChangePalletsItem(
                  sendPalletsItemIndex,
                  palletsItemIndex,
                  'palletsAmount',
                  event.target.value,
               )
            }
            value={sendPalletsItem.palletsItems[palletsItemIndex].palletsAmount}
            error={palletsItemTouched.palletsAmount && !!palletsItemError.palletsAmount}
            className={styles.palletsAmountInput}
         />
      </TableCell>
      <TableCell className={styles.tableCell}>
         <StyledButton
            type="button"
            onClick={() => onRemovePalletsItem(sendPalletsItemIndex, palletsItemIndex)}
            icon={<Trash />}
            variant="text"
            className={styles.removeTableRowBtn}
         />
      </TableCell>
   </TableRow>
);

export default PalletsItemRow;
