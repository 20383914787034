import { ImagesContainer } from 'components/shared';
import StyledBadge from 'components/shared/StyledBadge/StyledBadge';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { REPORT_STATUSES_AND_LABELS } from 'constants/reportStatuses';
import { REPORT_TYPE_LABELS } from 'constants/reportTypes';
import { PalletReportDTO } from 'interfaces';

import styles from './DamagedPalletsInDeliveryCard.module.css';

type DamagedPalletsInDeliveryCardProps = {
   damagedPalletsReport: PalletReportDTO;
   supplierMode?: boolean;
};

const DamagedPalletsInDeliveryCard = ({
   damagedPalletsReport,
   supplierMode,
}: DamagedPalletsInDeliveryCardProps) => (
   <div className={styles.container}>
      {!supplierMode && (
         <div className={styles.dataItem}>
            <span className={styles.label}>Numer zamówienia</span>
            <span className={styles.value}>#{damagedPalletsReport.orderId}</span>
         </div>
      )}
      <div className={styles.dataItem}>
         <span className={styles.label}>Status zgłoszenia</span>
         <span className={styles.value}>
            <StyledBadge
               text={REPORT_STATUSES_AND_LABELS[damagedPalletsReport.status!].label}
               variant={REPORT_STATUSES_AND_LABELS[damagedPalletsReport.status!].variant}
            />
         </span>
      </div>
      {damagedPalletsReport.damagedPallets.map((damagedPalletsItem, index) => (
         <div key={index} className={styles.innerContainer}>
            {!supplierMode && (
               <div className={styles.dataItem}>
                  <span className={styles.label}>Rodzaj uszkodzenia</span>
                  <span className={styles.value}>
                     {REPORT_TYPE_LABELS[damagedPalletsItem.reportType]}
                  </span>
               </div>
            )}
            <div className={styles.dataItemsGroup}>
               <div>
                  <span className={styles.label}>Typ palety</span>
                  <span className={styles.value}>
                     {PALLET_TYPE_LABELS[damagedPalletsItem.type]}
                  </span>
               </div>
               <div>
                  <span className={styles.label}>Ilość palet</span>
                  <span className={styles.value}>{damagedPalletsItem.amount}</span>
               </div>
            </div>
            <ImagesContainer
               imageURLs={damagedPalletsItem.images}
               isImagesPreviewLeftSidePosition
            />
         </div>
      ))}
   </div>
);

export default DamagedPalletsInDeliveryCard;
