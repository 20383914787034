import { DistributionCenterPalletsBalanceDTO, PalletType } from 'interfaces';

export const transformObjectPalletToArray = (obj: DistributionCenterPalletsBalanceDTO) => {
   const arrayFromObject = [];
   for (const [key, value] of Object.entries(obj)) {
      const objectWithPalletName = {
         palletName: key as PalletType,
         ...value,
      };
      arrayFromObject.push(objectWithPalletName);
   }
   return arrayFromObject;
};
