import { memo, useMemo, useState } from 'react';

import { ChevronDown, ChevronRight, Plus, Trash } from 'tabler-icons-react';

import { CommercialNetworkDetailsDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';
import { getAddressLabel } from 'utils/functions';

import { ActionsDropdown } from '../shared';

import DistributionCenterTableItem from './DistributionCenterTableItem/DistributionCenterTableItem';
import styles from './CommercialNetworkTableItem.module.css';

type CommercialNetworkTableItemProps = {
   network: CommercialNetworkDetailsDTO;
   onAddDistributionCenter: (network: CommercialNetworkDetailsDTO) => void;
   onRemoveCommercialNetwork: (id: string) => void;
};

const CommercialNetworkTableItem = ({
   network,
   onAddDistributionCenter,
   onRemoveCommercialNetwork,
}: CommercialNetworkTableItemProps) => {
   const [extend, setExtend] = useState<boolean>(false);

   const toggleExtend = () => setExtend(!extend);

   const distributionCenterItems = useMemo(
      () =>
         network.addresses.map(center => (
            <DistributionCenterTableItem key={center.id} center={center} />
         )),
      [network.addresses],
   );

   const icon = extend ? <ChevronDown size={20} /> : <ChevronRight size={20} />;

   return (
      <>
         <TableRow className={styles.networkTableRow} onClick={toggleExtend}>
            <TableCell className={styles.tableCell}>
               <div className={styles.nameCell}>
                  <span>{icon}</span>
                  {network.name}
               </div>
            </TableCell>
            <TableCell className={styles.addressLengthCell}>
               {`${network.addresses.length} ${getAddressLabel(network.addresses.length)}`}
            </TableCell>
            <TableCell className={styles.bold} align="right">
               {network.sum.availableForCollection.sum}
            </TableCell>
            <TableCell align="right">{network.sum.availableForCollection.valid}</TableCell>
            <TableCell align="right">{network.sum.availableForCollection.invalid}</TableCell>
            <TableCell className={styles.bold} align="right">
               {network.sum.palletReturn.sum}
            </TableCell>
            <TableCell align="right">{network.sum.palletReturn.valid}</TableCell>
            <TableCell align="right">{network.sum.palletReturn.invalid}</TableCell>
            <TableCell align="right">
               <div className={styles.actionContainer}>
                  <ActionsDropdown
                     options={[
                        {
                           text: 'Dodaj Centrum Dystrybucyjne',
                           icon: <Plus size={30} />,
                           onClick: () => {
                              onAddDistributionCenter(network);
                           },
                        },
                        {
                           text: 'Usuń Sieć Handlową',
                           dangerOption: true,
                           lastOption: true,
                           icon: <Trash size={30} />,
                           onClick: () => {
                              onRemoveCommercialNetwork(network.id);
                           },
                        },
                     ]}
                     portalTarget={document.body}
                  />
               </div>
            </TableCell>
         </TableRow>
         {extend && distributionCenterItems}
      </>
   );
};
export default memo(CommercialNetworkTableItem);
