import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';

import { VGLClientExcelRow } from '../types';

import { getVGLClientSendPalletsItemFromVGLClientSendPalletsDataItems } from './getVGLClientSendPalletsItemFromVGLClientSendPalletsDataItems';
import { mergeVGLClientSendPalletsDataItemsWithSamePalletType } from './mergeVGLClientSendPalletsDataItemsWithSamePalletType';

export const getGroupedVGLClientSendPalletsItems = (sendPalletsData: VGLClientExcelRow[]) => {
   const sendPalletsDataGroupedBySendDate = groupBy(sendPalletsData, 'Data ost.mod.');
   return flatten(
      Object.values(sendPalletsDataGroupedBySendDate).map(sendPalletsDataItemWithSameSendDate => {
         const sendPalletsItemsGroupedByExternalCommercialNetworkNumber = groupBy(
            sendPalletsDataItemWithSameSendDate,
            'nr STO',
         );
         return flatten(
            Object.values(sendPalletsItemsGroupedByExternalCommercialNetworkNumber).map(
               sendPalletsDataItemWithSameExternalCommercialNetworkNumber => {
                  const sendPalletsItemsGroupedByCommercialNetworkAddress = groupBy(
                     sendPalletsDataItemWithSameExternalCommercialNetworkNumber,
                     'do Centrum Dystr.',
                  );
                  return flatten(
                     Object.values(sendPalletsItemsGroupedByCommercialNetworkAddress).map(
                        sendPalletsItemWithSameCommercialNetworkAddress => {
                           const sendPalletsItemsGroupedByPalletTypes = groupBy(
                              sendPalletsItemWithSameCommercialNetworkAddress,
                              'typ palety',
                           );
                           const sendPalletsItemsMergedByPalletTypes = Object.values(
                              sendPalletsItemsGroupedByPalletTypes,
                           ).map(sendPalletsItemsWithSamePalletType =>
                              mergeVGLClientSendPalletsDataItemsWithSamePalletType(
                                 sendPalletsItemsWithSamePalletType,
                              ),
                           );
                           return getVGLClientSendPalletsItemFromVGLClientSendPalletsDataItems(
                              sendPalletsItemsMergedByPalletTypes,
                           );
                        },
                     ),
                  );
               },
            ),
         );
      }),
   );
};
