import { useMemo } from 'react';

import { PalletsStackIcon } from 'assets';
import { DamagedLostByClientPalletsCard, DamagedPalletsInDeliveryCard } from 'components/Pallets';
import { NoItemsNotification } from 'components/shared';
import { PalletReport } from 'interfaces';

type DamagedLostPalletsMobileListProps = {
   palletReports: PalletReport[];
   isDamagedLostBySupplier?: boolean;
};

const DamagedLostPalletsMobileList = ({
   palletReports,
   isDamagedLostBySupplier,
}: DamagedLostPalletsMobileListProps) => {
   const damagedLostPalletsListContent = useMemo(() => {
      if (!palletReports.length) {
         return <NoItemsNotification icon={<PalletsStackIcon />} description="Brak zgłoszeń." />;
      } else if (isDamagedLostBySupplier) {
         return (
            <>
               {palletReports.map(palletReport => (
                  <DamagedPalletsInDeliveryCard
                     key={palletReport.id}
                     damagedPalletsReport={palletReport}
                  />
               ))}
            </>
         );
      }
      return (
         <>
            {palletReports.map(palletReport => (
               <DamagedLostByClientPalletsCard
                  key={palletReport.id}
                  damageLossPalletsData={palletReport}
               />
            ))}
         </>
      );
   }, [palletReports, isDamagedLostBySupplier]);

   return damagedLostPalletsListContent;
};

export default DamagedLostPalletsMobileList;
