import { useCallback, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import classNames from 'classnames';
import { Trash } from 'tabler-icons-react';

import { InfoCircleIcon } from 'assets';
import { MaskedInput, SectionContainer, StyledButton, StyledTextInput } from 'components/shared';
import { GUS_API_COMPANY_NOT_FOUND } from 'constants/errorCodes';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useForm } from 'hooks/useForm';
import { ClientDataFormProps, DeliveryAddress, FormStepProps } from 'interfaces';
import { HTTPService } from 'service';
import { Banner, Checkbox } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { isZipCode } from 'utils/validation';

import FormStepsFooter from '../../FormStepsFooter/FormStepsFooter';

import GUSLoadingModal from './GUSLoadingModal/GUSLoadingModal';
import styles from './ClientDataForm.module.css';
import { clientDataFormValidations } from './clientDataFormValidations';

const ClientDataForm = ({
   initialState,
   onSubmit,
   setCurrentStep,
}: FormStepProps<ClientDataFormProps>) => {
   const [sameEmail, setSameEmail] = useState(false);
   const [gusFetching, setGusFetching] = useState<{
      loading: boolean;
      status: 'error' | 'success' | null;
      fetched: boolean;
      errorMessage?: string;
   }>({
      loading: false,
      status: null,
      fetched: false,
   });
   const {
      errorsList,
      values,
      touched,
      changeHandler,
      submitHandler,
      setValues,
      setTouched,
      handleDeliveryAddressChange,
   } = useForm({
      initialState,
      validations: clientDataFormValidations,
      onSubmit: () => {
         onSubmit(values as ClientDataFormProps);
         setCurrentStep(prevValue => prevValue + 1);
      },
   });
   const fetchGusData = useCallback(() => {
      setGusFetching(prevValues => ({ ...prevValues, loading: true }));
      HTTPService.getGUSData(values.nip.replaceAll('-', ''))
         .then(res => {
            const { data } = res;
            setValues(prevValues => ({
               ...prevValues,
               companyName: data.name,
               address: data.street + ' ' + data.buildingNumber,
               zipCode: data.zipCode,
               city: data.city,
            }));
            setGusFetching(prevValues => ({ ...prevValues, fetched: true, status: 'success' }));
         })
         .catch(handleFetchGUSDataError)
         .finally(() => setGusFetching(prevValues => ({ ...prevValues, loading: false })));
   }, [values.nip, setValues]);

   const handleFetchGUSDataError = (error: unknown) => {
      const errorMessage =
         error instanceof AxiosError && error.response?.data.code === GUS_API_COMPANY_NOT_FOUND
            ? 'Brak danych dla podanego numeru NIP.'
            : DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA;
      errorHandler(error, () =>
         setGusFetching(prevValues => ({
            ...prevValues,
            fetched: true,
            status: 'error',
            errorMessage,
         })),
      );
   };

   const isLastAddressValid = useMemo(() => {
      const lastDeliveryAddressIndex = values.deliveryAddresses.length - 1;
      return !!(
         values.deliveryAddresses[lastDeliveryAddressIndex].name &&
         isZipCode(values.deliveryAddresses[lastDeliveryAddressIndex].zipCode) &&
         values.deliveryAddresses[lastDeliveryAddressIndex].city &&
         values.deliveryAddresses[lastDeliveryAddressIndex].address
      );
   }, [values.deliveryAddresses]);
   const handleAddNewDeliveryAddress = useCallback(() => {
      setValues(prevValues => ({
         ...prevValues,
         deliveryAddresses: [
            ...prevValues.deliveryAddresses,
            {
               name: '',
               zipCode: '',
               city: '',
               address: '',
            },
         ],
      }));
      setTouched(prevTouched => ({
         ...prevTouched,
         deliveryAddresses: false,
      }));
   }, [setValues, setTouched]);

   const handleSameEmailClick = useCallback(() => {
      if (!sameEmail) {
         setValues(prevValues => ({
            ...prevValues,
            email: prevValues.invoiceEmail,
         }));
      } else {
         setValues(prevValues => ({
            ...prevValues,
            email: '',
         }));
      }
      setSameEmail(prevValue => !prevValue);
   }, [sameEmail, setValues]);

   const handleDeleteDeliveryAddress = useCallback(
      (index: number) => {
         setValues(prevValues => ({
            ...prevValues,
            deliveryAddresses: prevValues.deliveryAddresses.filter(
               (_: DeliveryAddress, i: number) => i !== index,
            ),
         }));
      },
      [setValues],
   );

   return (
      <form onSubmit={submitHandler}>
         <GUSLoadingModal
            title="Pobieranie danych"
            withCloseButton={false}
            opened={gusFetching.loading}
            onClose={() => setGusFetching({ ...gusFetching, loading: false })}
         />
         <SectionContainer title="Dane podstawowe">
            {gusFetching.fetched && gusFetching.status && (
               <Banner style={{ marginBottom: 24 }} variant={gusFetching.status}>
                  {gusFetching.status === 'error'
                     ? gusFetching.errorMessage
                     : 'Pobrano dane z GUS.'}
               </Banner>
            )}
            <div className={styles.formRow}>
               <MaskedInput
                  onChange={changeHandler}
                  name="nip"
                  mask="999-99-99-999"
                  label="NIP"
                  type="text"
                  additionalContent={
                     <div className={styles.inputInfo}>
                        <InfoCircleIcon /> NIP, który znajduję się w bazie Subiekta
                     </div>
                  }
                  error={touched.nip && errorsList.nip}
                  value={values.nip}
               />
               <StyledButton
                  style={{ marginTop: 52 }}
                  onClick={fetchGusData}
                  type="button"
                  variant="outlined-primary"
                  text="Pobierz dane z GUS"
               />
            </div>
            <div className={styles.formRow}>
               <StyledTextInput
                  value={values.companyName}
                  fullWidth
                  label="Nazwa firmy"
                  type="text"
                  name="companyName"
                  error={touched.companyName && errorsList.companyName}
                  helperText={touched.companyName && errorsList.companyName}
                  onChange={changeHandler}
               />
            </div>
            <div className={styles.formRow}>
               <StyledTextInput
                  fullWidth
                  label="Ulica, numer domu / numer mieszkania"
                  type="text"
                  name="address"
                  value={values.address}
                  error={touched.address && errorsList.address}
                  helperText={touched.address && errorsList.address}
                  onChange={changeHandler}
               />
            </div>
            <div className={styles.formRow}>
               <MaskedInput
                  mask="99-999"
                  onChange={changeHandler}
                  name="zipCode"
                  type="text"
                  label="Kod pocztowy"
                  error={touched.zipCode && errorsList.zipCode}
                  value={values.zipCode}
               />
               <StyledTextInput
                  onChange={changeHandler}
                  value={values.city}
                  name="city"
                  label="Miejscowość"
                  error={touched.city && errorsList.city}
                  helperText={touched.city && errorsList.city}
                  type="text"
               />
            </div>
         </SectionContainer>
         <SectionContainer title="Dane kontaktowe">
            <div className={styles.formRow}>
               <StyledTextInput
                  className={styles.phoneNumberInput}
                  fullWidth
                  onChange={changeHandler}
                  value={values.phoneNumber}
                  error={touched.phoneNumber && errorsList.phoneNumber}
                  helperText={touched.phoneNumber && errorsList.phoneNumber}
                  name="phoneNumber"
                  label="Telefon"
                  type="number"
               />
            </div>
            <div className={styles.formRow}>
               <StyledTextInput
                  fullWidth
                  onChange={changeHandler}
                  value={values.invoiceEmail}
                  error={touched.invoiceEmail && errorsList.invoiceEmail}
                  helperText={touched.invoiceEmail && errorsList.invoiceEmail}
                  name="invoiceEmail"
                  label="E-mail dla faktur"
                  type="text"
               />
            </div>
            <div className={styles.formRow}>
               <Checkbox
                  className={styles.checkbox}
                  onChangeHandler={handleSameEmailClick}
                  checked={sameEmail}
                  label="Taki sam login"
               />
            </div>
            <div className={styles.formRow}>
               <StyledTextInput
                  fullWidth
                  onChange={changeHandler}
                  value={sameEmail ? values.invoiceEmail : values.email}
                  error={touched.email && errorsList.email}
                  helperText={touched.email && errorsList.email}
                  name="email"
                  label="E-mail login / e-mail kontaktowy"
                  type="text"
               />
            </div>
         </SectionContainer>
         <SectionContainer title="Adres dostawy">
            {values.deliveryAddresses.map((address: DeliveryAddress, index: number) => (
               //TODO: pass unique id to key prop
               <div
                  className={classNames({
                     [styles.deliveryAddressWrapper]: values.deliveryAddresses.length > 1,
                  })}
                  key={index}
               >
                  <div className={styles.deliveryAddressTitle}>
                     <h4>Adres {index + 1}</h4>
                     {index !== 0 && (
                        <Trash
                           size={20}
                           style={{ cursor: 'pointer' }}
                           onClick={() => handleDeleteDeliveryAddress(index)}
                        />
                     )}
                  </div>
                  <div className={styles.formRow}>
                     <StyledTextInput
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           handleDeliveryAddressChange(index, e);
                           setTouched(prevTouched => ({
                              ...prevTouched,
                              deliveryAddresses: true,
                           }));
                        }}
                        value={address.name}
                        error={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.name
                        }
                        helperText={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.name
                        }
                        name="name"
                        label="Nazwa"
                        type="text"
                     />
                  </div>
                  <div className={styles.formRow}>
                     <StyledTextInput
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                           handleDeliveryAddressChange(index, e)
                        }
                        value={address.address}
                        error={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.address
                        }
                        helperText={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.address
                        }
                        name="address"
                        label="Ulica, numer domu / numer mieszkania"
                        type="text"
                     />
                  </div>
                  <div className={styles.formRow}>
                     <MaskedInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                           handleDeliveryAddressChange(index, e)
                        }
                        value={address.zipCode}
                        error={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.zipCode
                        }
                        helperText={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.zipCode
                        }
                        mask="99-999"
                        name="zipCode"
                        label="Kod pocztowy"
                        type="text"
                     />
                     <StyledTextInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                           handleDeliveryAddressChange(index, e)
                        }
                        value={address.city}
                        error={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.city
                        }
                        helperText={
                           errorsList.deliveryAddresses &&
                           touched.deliveryAddresses &&
                           errorsList.deliveryAddresses[index]?.city
                        }
                        name="city"
                        label="Miejscowość"
                        type="text"
                     />
                  </div>
               </div>
            ))}
            <StyledButton
               onClick={handleAddNewDeliveryAddress}
               disabled={!isLastAddressValid}
               type="button"
               variant="outlined-primary"
               text="Dodaj kolejny adres"
            />
         </SectionContainer>
         <FormStepsFooter
            backDisabled
            onBack={() => setCurrentStep((prevValue: number) => prevValue - 1)}
         />
      </form>
   );
};
export default ClientDataForm;
