import {
   AddressDeliveryRatePerPalletConfig,
   PalletsPricingPerAddressFormDataItem,
} from 'interfaces';
import { parseNumber } from 'utils/functions';

export const parsePalletsPricingPerPalletFormDataItemIntoAddressDeliveryRatePerPalletConfig: (
   palletsPricingPerPalletFormDataItem: PalletsPricingPerAddressFormDataItem,
) => AddressDeliveryRatePerPalletConfig = palletsPricingPerPalletFormDataItem => {
   const { palletType, deliveryPrice: deliveryPriceFormData } = palletsPricingPerPalletFormDataItem;
   return { palletType, deliveryPrice: parseNumber(deliveryPriceFormData) };
};
