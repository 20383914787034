import { ClientOrdersTableItem, OrderDTO, SupplierOrdersTableItem } from 'interfaces';
import { getDate, getFreePalletAmount } from 'utils/functions';

export const orderDTOToSupplierOrdersTableItemMapper: (
   order: OrderDTO,
) => SupplierOrdersTableItem = (order: OrderDTO) => {
   const {
      id,
      createdBy: { name, active },
      createdDate,
      palletDeliveryDate,
      retentionDate,
      status,
      palletSum,
      read,
      deliveryMethod,
      additionalPallets,
   } = order;
   const freePalletsAmount = getFreePalletAmount(additionalPallets);
   return {
      id,
      clientName: name,
      blockedClient: !active,
      createdDate: getDate(createdDate),
      deliveryDate: palletDeliveryDate || '',
      retentionDate: retentionDate || '',
      status,
      palletsAmount: palletSum,
      read,
      free: !!deliveryMethod?.free,
      freePalletsAmount,
   };
};

export const orderDTOToClientOrdersTableItemMapper: (order: OrderDTO) => ClientOrdersTableItem = (
   order: OrderDTO,
) => {
   const {
      id,
      createdDate,
      palletDeliveryDate,
      retentionDate,
      status,
      palletSum,
      deliveryMethod,
      additionalPallets,
      internalOrderId,
   } = order;
   const freePalletsAmount = getFreePalletAmount(additionalPallets);
   return {
      id: id,
      createdDate: getDate(createdDate),
      deliveryDate: palletDeliveryDate || '',
      status: status,
      palletsAmount: palletSum,
      retentionDate: retentionDate || '',
      free: !!deliveryMethod?.free,
      freePalletsAmount,
      internalOrderId: internalOrderId || '',
   };
};
