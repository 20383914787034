import { PalletReport, PalletReportDTO } from 'interfaces';

export const palletReportDTOMappersToPalletReportMapper: (
   palletReport: PalletReportDTO,
) => PalletReport = (palletReport: PalletReportDTO) => {
   const { receivedDamaged, status } = palletReport;
   if (receivedDamaged && status) {
      return {
         ...palletReport,
         receivedDamaged: true,
         status,
      };
   }
   return {
      ...palletReport,
      receivedDamaged: false,
      status: null,
   };
};
