import { useCallback, useState } from 'react';

type ModalProps = {
   loading?: boolean;
   onClose: () => void;
};

export const useModal = ({ loading, onClose }: ModalProps) => {
   const [isLoading, setIsLoading] = useState(loading);
   const [errorInfo, setErrorInfo] = useState('');
   const [showCloseWarning, setShowCloseWarning] = useState(false);

   const handleShowCloseWarning = () => setShowCloseWarning(true);

   const handleHideCloseWarning = () => setShowCloseWarning(false);

   const closeModalHandler = useCallback(() => {
      errorInfo && setErrorInfo('');
      showCloseWarning && setShowCloseWarning(false);
      onClose();
   }, [errorInfo, onClose, showCloseWarning]);

   return {
      isLoading,
      errorInfo,
      showCloseWarning,
      setIsLoading,
      setErrorInfo,
      handleShowCloseWarning,
      handleHideCloseWarning,
      closeModalHandler,
   };
};
