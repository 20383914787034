import { Pencil } from 'tabler-icons-react';

import { GreyReplaceIcon } from 'assets';
import { ActionsDropdown } from 'components/shared';

type RentedPalletsSummarySectionActionsDropdownProps = {
   onClickMovePallets: () => void;
   onClickEditPalletsBalance: () => void;
};

const RentedPalletsSummarySectionActionsDropdown = ({
   onClickMovePallets,
   onClickEditPalletsBalance,
}: RentedPalletsSummarySectionActionsDropdownProps) => {
   const options = [
      {
         text: 'Przerzuć palety',
         icon: <GreyReplaceIcon />,
         onClick: onClickMovePallets,
      },
      {
         text: 'Edytuj stan magazynowy palet',
         icon: <Pencil color={'#A6A6B2'} />,

         onClick: onClickEditPalletsBalance,
      },
   ];

   return <ActionsDropdown options={options} />;
};

export default RentedPalletsSummarySectionActionsDropdown;
