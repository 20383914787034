import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { OrderDetailsLayout } from 'components/layout';
import { SendPalletsSection } from 'components/OrderDetails';
import { ClientSection } from 'components/SupplierPanel';
import WithAuth from 'hoc/withAuth';
import { ClientContactDetails, OrderDetails } from 'interfaces';
import { HTTPService } from 'service';
import { getOrderDetailsData } from 'utils/functions';
import { logNetworkError } from 'utils/logNetworkError';

const OrderDetailsPage = () => {
   const [order, setOrder] = useState<OrderDetails>();
   const [client, setClient] = useState<ClientContactDetails>();
   const [isDataLoading, setIsDataLoading] = useState(false);
   const [didFetchDataErrorOccur, setDidFetchDataErrorOccur] = useState(false);
   const params = useParams();

   const fetchData = useCallback(async () => {
      if (!params.orderId) {
         return;
      }
      setIsDataLoading(true);
      try {
         const getOrderDetailsResponse = await HTTPService.getOrderDetails(params.orderId);
         const orderDetailsData = getOrderDetailsData(getOrderDetailsResponse.data);
         const getClientDetailsResponse = await HTTPService.getClientContactDetails(
            orderDetailsData.createdBy.id,
         );
         setOrder(orderDetailsData);
         setClient(getClientDetailsResponse.data);
      } catch (error) {
         handleFetchDataError(error);
      } finally {
         setIsDataLoading(false);
      }
   }, [params.orderId]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   const handleFetchDataError = (error: unknown) => {
      setDidFetchDataErrorOccur(true);
      logNetworkError(error);
   };

   const isDataReady = order && client;
   const headerOrderData = order
      ? {
           id: order.id,
           createdDate: order.createdDate,
        }
      : undefined;

   return (
      <OrderDetailsLayout
         headerOrderData={headerOrderData}
         isLoading={isDataLoading}
         didFetchDataErrorOccur={didFetchDataErrorOccur}
      >
         {isDataReady && (
            <>
               <ClientSection {...client} />
               <SendPalletsSection sendPallets={order.sendPallets} />
            </>
         )}
      </OrderDetailsLayout>
   );
};

export default WithAuth(OrderDetailsPage, 'ROLE_DISTRIBUTION_CENTER');
