import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { PalletType } from 'interfaces';

import { sortOptions } from './sortOptions';

type PalletItem = { palletType: PalletType };

export const getPalletTypes = (pallets: PalletItem[]) => {
   const palletTypeItems = new Set(pallets.map(pallet => pallet.palletType));
   const palletTypeOptions = Array.from(palletTypeItems).map(palletTypeItem => ({
      label: PALLET_TYPE_LABELS[palletTypeItem],
      value: palletTypeItem,
   }));
   return sortOptions(palletTypeOptions);
};
