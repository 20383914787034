import { useMemo } from 'react';

import { ClientOrderSummaryPallet, OrderDetailsPallet } from 'interfaces';
import { getPalletAmountListItemsData } from 'utils/functions';

import PalletAmountListItem from './PalletAmountListItem/PalletAmountListItem';

type PalletAmountListProps = {
   pallets: ClientOrderSummaryPallet[] | OrderDetailsPallet[];
};

const PalletAmountList = ({ pallets }: PalletAmountListProps) => {
   const palletAmountListItemsData = useMemo(
      () => getPalletAmountListItemsData(pallets),
      [pallets],
   );

   return (
      <>
         {palletAmountListItemsData.map(pallet => {
            const { name, amount, label, dimensions, isFree } = pallet;
            const palletInfo = `${label} ${dimensions}`;
            return pallet.amount ? (
               <PalletAmountListItem
                  key={`${name}/${isFree}`}
                  palletInfo={isFree ? `Dodatkowe palety z wymiany ${palletInfo}` : palletInfo}
                  palletCount={amount}
                  isFree={isFree}
               />
            ) : null;
         })}
      </>
   );
};

export default PalletAmountList;
