import classNames from 'classnames';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import { DamageType } from 'interfaces';
import { Card } from 'storybook';

import styles from './SelectDamageTypeModalContent.module.css';

type SelectDamageTypeModalContentProps = {
   selectedDamageType: DamageType | null;
   onSelectDamageType: (damageType: DamageType) => void;
   onGoToDamagePalletsForm: () => void;
   isSupplier?: boolean;
};

const SelectDamageTypeModalContent = ({
   selectedDamageType,
   onSelectDamageType,
   onGoToDamagePalletsForm,
   isSupplier,
}: SelectDamageTypeModalContentProps) => {
   const firstTitle = isSupplier
      ? 'Zgubione, zniszczone lub uszkodzone z winy klienta'
      : 'Ja uszkodziłem';
   const secondTitle = isSupplier
      ? 'Zgubione, zniszczone lub uszkodzone w dostawie (z winy NDHP)'
      : 'Dostałem uszkodzone';
   return (
      <>
         <div className={styles.damageLossTypesContainer}>
            <Card
               title={firstTitle}
               onClick={() => onSelectDamageType('LOST_DAMAGED_BY_CLIENT')}
               checked={selectedDamageType === 'LOST_DAMAGED_BY_CLIENT'}
               displayCheckedIcon
               className={classNames(styles.damageLossTypeCard, {
                  [styles.checkedCard]: selectedDamageType === 'LOST_DAMAGED_BY_CLIENT',
               })}
            />
            <Card
               title={secondTitle}
               onClick={() => onSelectDamageType('LOST_DAMAGED_BY_SUPPLIER')}
               checked={selectedDamageType === 'LOST_DAMAGED_BY_SUPPLIER'}
               displayCheckedIcon
               className={classNames(styles.damageLossTypeCard, {
                  [styles.checkedCard]: selectedDamageType === 'LOST_DAMAGED_BY_SUPPLIER',
               })}
            />
         </div>
         <div className={styles.buttonsContainer}>
            <StyledButton
               variant="filled-primary"
               text="Następny krok"
               onClick={onGoToDamagePalletsForm}
               disabled={selectedDamageType === null}
            />
         </div>
      </>
   );
};

export default SelectDamageTypeModalContent;
