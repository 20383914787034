import { OrderSummaryAndOrderDetailsPallet } from 'interfaces';
import { displayPalletsAmountAsMultipleOfTheMinimum, getPalletLabel } from 'utils/functions';
import { isOrderDetailsPallet } from 'utils/typeGuards';

import styles from './PalletAmountAsLogisticMinimumInfo.module.css';

type PalletAmountAsLogisticMinimumInfoProps = {
   pallet: OrderSummaryAndOrderDetailsPallet;
};

const PalletAmountAsLogisticMinimumInfo = ({ pallet }: PalletAmountAsLogisticMinimumInfoProps) => {
   const isOrderDetailsPalletMode = isOrderDetailsPallet(pallet);

   const renderContent = () => {
      if (isOrderDetailsPalletMode) {
         return (
            <p className={styles.amountInfo}>
               {displayPalletsAmountAsMultipleOfTheMinimum(pallet.amount, pallet.logisticMinimum)}
            </p>
         );
      }
      return (
         <span className={styles.amountInfo}>
            {pallet.amount} x {pallet.logisticMinimum}
            {` ${getPalletLabel(pallet.logisticMinimum)}`}
         </span>
      );
   };

   return <div className={styles.container}>{renderContent()}</div>;
};

export default PalletAmountAsLogisticMinimumInfo;
