import { useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { ChevronRightIcon } from 'assets';
import NotFoundOrderAlertModal from 'components/shared/NotFoundOrderAlertModal/NotFoundOrderAlertModal';
import GoBackWarningModal from 'components/shared/WarningModal/WarningModals/GoBackWarningModal';
import { LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM } from 'constants/palletsTableDefaultValues';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { useTableForm } from 'hooks/useTableForm';
import {
   CommonRatePerPalletConfig,
   ExcessPallets,
   GroupedLostDamagedPalletItems,
   LostDamagedPalletsByClientItem,
   LostDamagedPalletsItemsWithPrice,
   Option,
   PalletAmount,
   PalletReportDTO,
   PalletType,
} from 'interfaces';
import { HTTPService } from 'service';
import { PalletReportRequest } from 'service/http/requests';
import { Banner, Step } from 'storybook';
import { getExcessPallets } from 'utils/functions/getExcessPallets';
import { logNetworkError } from 'utils/logNetworkError';
import { lostDamagedPalletsFormValidations } from 'utils/validation/lostDamagedPalletsFormValidations';

import LostDamagedPalletsMobileList from '../LostDamagedPalletsBySupplierModalContent/LostDamagedPalletsMobileList/LostDamagedPalletsMobileList';
import LostDamagedPalletsStepFooter from '../LostDamagedPalletsStepFooter/LostDamagedPalletsStepFooter';
import LostDamagedPalletsTable from '../LostDamagedPalletsTable/LostDamagedPalletsTable';

import AdditionalFeeWarning from './AdditionalFeeWarning/AdditionalFeeWarning';
import styles from './LostDamagedPalletsByClientModalContent.module.css';

type LostDamagedPalletsByClientModalContentProps = {
   isSupplier?: boolean;
   pricesForPallets?: CommonRatePerPalletConfig[];
   orderId?: string;
   availablePalletTypes: Option[];
   remainingPalletsToBeSend: PalletAmount[];
   clientId?: string;
   onReportDamagedPallets: (groupedLostDamagedPalletItems: GroupedLostDamagedPalletItems) => void;
   onSuccessReportLostDestroyedPallets: (reports: PalletReportDTO[]) => void;
   onGoToSelectDamageType: () => void;
};

const LostDamagedPalletsByClientModalContent = ({
   pricesForPallets,
   isSupplier,
   orderId,
   availablePalletTypes,
   remainingPalletsToBeSend,
   clientId,
   onReportDamagedPallets,
   onSuccessReportLostDestroyedPallets,
   onGoToSelectDamageType,
}: LostDamagedPalletsByClientModalContentProps) => {
   const handleSubmitForm = () => setActiveStep('ADDITIONAL_FEE');

   const [activeStep, setActiveStep] = useState<'DETAILS' | 'ADDITIONAL_FEE'>('DETAILS');
   const [loading, setLoading] = useState(false);
   const [reportLostDestroyedPalletsError, setReportLostDestroyedPalletsError] = useState(false);
   const [showGoBackWarning, setShowGoBackWarning] = useState(false);
   const [excessPallets, setExcessPallets] = useState<ExcessPallets | null>(null);
   const [pallets, setPallets] = useState<LostDamagedPalletsItemsWithPrice[]>();
   const {
      rows: lostDamagedPalletsItems,
      errors: lostDamagedPalletsItemsErrors,
      touched: lostDamagedPalletsItemsTouched,
      addRow: handleAddLostDamagedPalletsItem,
      removeRow: handleRemoveLostDamagedPalletsItem,
      handleChangeValue: handleChangeLostDamagedPalletsItem,
      handleSubmitForm: handleSubmitLostDamagedPalletsTableForm,
   } = useTableForm<LostDamagedPalletsByClientItem>({
      initialState: [LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM()],
      onSubmit: handleSubmitForm,
      validations: lostDamagedPalletsFormValidations,
   });
   const { isTablet } = useResponsiveLayout();

   useEffect(() => {
      const lostDamagedPalletsItemsWithPrice = lostDamagedPalletsItems.map(item => {
         const searchPrice = pricesForPallets?.find(el => el.palletType === item.palletType.value);

         return {
            ...item,
            reportPrice:
               item.damageType === 'DAMAGE' ? searchPrice?.damagePrice : searchPrice?.lostPrice,
         };
      });
      setPallets(lostDamagedPalletsItemsWithPrice);
   }, [lostDamagedPalletsItems, pricesForPallets]);

   const reportPallets = (palletReport: PalletReportRequest) => {
      if (orderId) {
         return HTTPService.reportPalletsFromOrder(orderId, palletReport).then(
            ({ data: responseData }) => onSuccessReportLostDestroyedPallets([responseData]),
         );
      }
      if (!clientId) {
         return HTTPService.reportPalletsFromMultipleOrders(palletReport).then(
            ({ data: responseData }) => onSuccessReportLostDestroyedPallets(responseData.reports),
         );
      }
      return HTTPService.reportPalletsFromMultipleOrdersByAdmin(clientId, palletReport).then(
         ({ data: responseData }) => onSuccessReportLostDestroyedPallets(responseData.reports),
      );
   };

   const handleReportLostDestroyedPallets = () => {
      setLoading(true);
      setReportLostDestroyedPalletsError(false);
      const palletReport = {
         receivedDamaged: false,
         pallets: lostDamagedPalletsItems.map(item => ({
            type: item.palletType.value as PalletType,
            reportType: item.damageType,
            amount: parseInt(item.palletsAmount, 10),
         })),
         sendPallets: [],
      };
      reportPallets(palletReport)
         .catch(error => {
            setReportLostDestroyedPalletsError(true);
            logNetworkError(error);
         })
         .finally(() => setLoading(false));
   };

   const handleReportLostDamagedPallets = () => {
      const foundExcessPallets = getExcessPallets(
         lostDamagedPalletsItems,
         remainingPalletsToBeSend,
      );
      if (isEmpty(foundExcessPallets)) {
         const damagedPalletsItems = lostDamagedPalletsItems.filter(
            lostDamagedPalletsItem => lostDamagedPalletsItem.damageType === 'DAMAGE',
         );
         if (damagedPalletsItems.length) {
            const lostDestroyedPalletsItems = lostDamagedPalletsItems.filter(
               lostDamagedPalletsItem =>
                  lostDamagedPalletsItem.damageType === 'LOST_OR_DESTRUCTION',
            );
            onReportDamagedPallets({
               damaged: damagedPalletsItems,
               lostOrDestroyed: lostDestroyedPalletsItems,
            });
         } else {
            handleReportLostDestroyedPallets();
         }
      } else {
         setExcessPallets(foundExcessPallets);
      }
   };

   const handleReturnToDetails = () => {
      setActiveStep('DETAILS');
      reportLostDestroyedPalletsError && setReportLostDestroyedPalletsError(false);
      excessPallets && setExcessPallets(null);
   };

   const handleReturnToSelectDamageType = () => {
      const dataAdded = lostDamagedPalletsItemsTouched.some(damagePalletsItemTouched =>
         Object.values(damagePalletsItemTouched).some(fieldTouched => fieldTouched),
      );
      dataAdded ? setShowGoBackWarning(true) : onGoToSelectDamageType();
   };

   const commonErrors = useMemo(() => {
      const tableAllErrors: string[] = [];
      lostDamagedPalletsItemsErrors.forEach((damagePalletsItemErrors, rowTableIndex) => {
         Object.entries(damagePalletsItemErrors).forEach(([key, value]) => {
            value &&
               lostDamagedPalletsItemsTouched[rowTableIndex][key] &&
               tableAllErrors.push(value as string);
         });
      });
      return new Set(tableAllErrors);
   }, [lostDamagedPalletsItemsErrors, lostDamagedPalletsItemsTouched]);

   const stepContent =
      activeStep === 'DETAILS' ? (
         <div className={styles.detailsContainer}>
            {!isTablet ? (
               <LostDamagedPalletsTable
                  damageType="LOST_DAMAGED_BY_CLIENT"
                  lostDamagedPalletsItems={lostDamagedPalletsItems}
                  lostDamagedPalletsItemsTouched={lostDamagedPalletsItemsTouched}
                  lostDamagedPalletsItemsErrors={lostDamagedPalletsItemsErrors}
                  availablePalletTypes={availablePalletTypes}
                  onAddLostDamagedPalletsItem={handleAddLostDamagedPalletsItem}
                  onRemoveLostDamagedPalletsItem={handleRemoveLostDamagedPalletsItem}
                  onChangeLostDamagedPalletsItem={handleChangeLostDamagedPalletsItem}
               />
            ) : (
               <>
                  <Banner
                     variant="info"
                     children="Możesz dodać zdjęcia palet tylko z przeglądarki na komputerze."
                     fullWidth
                     className={styles.banner}
                  />
                  <LostDamagedPalletsMobileList
                     damageType="LOST_DAMAGED_BY_CLIENT"
                     lostDamagedPalletsItems={lostDamagedPalletsItems}
                     lostDamagedPalletsItemsTouched={lostDamagedPalletsItemsTouched}
                     lostDamagedPalletsItemsErrors={lostDamagedPalletsItemsErrors}
                     availablePalletTypes={availablePalletTypes}
                     onAddLostDamagedPalletsItem={handleAddLostDamagedPalletsItem}
                     onRemoveLostDamagedPalletsItem={handleRemoveLostDamagedPalletsItem}
                     onChangeLostDamagedPalletsItem={handleChangeLostDamagedPalletsItem}
                  />
               </>
            )}
            {!!commonErrors.size && (
               <div className={styles.bannersContainer}>
                  {Array.from(commonErrors).map(commonError => (
                     <Banner
                        key={commonError}
                        variant="error"
                        children={commonError}
                        fullWidth
                        className={styles.banner}
                     />
                  ))}
               </div>
            )}
         </div>
      ) : (
         <div
            className={classNames(styles.additionalFeeWarningContainer, {
               [styles.additionalFeeWarningContainerError]: reportLostDestroyedPalletsError,
            })}
         >
            <AdditionalFeeWarning isSupplier={isSupplier} pricesForPallets={pallets} />
            {reportLostDestroyedPalletsError && (
               <Banner
                  variant="error"
                  children="Podczas wysyłania zgłoszenia wystąpił błąd"
                  fullWidth
                  className={styles.additionalFeeWarningErrorBanner}
               />
            )}
         </div>
      );

   return (
      <>
         <GoBackWarningModal
            opened={showGoBackWarning}
            onConfirm={onGoToSelectDamageType}
            onClose={() => setShowGoBackWarning(false)}
         />
         <NotFoundOrderAlertModal
            opened={!!excessPallets}
            onClose={handleReturnToDetails}
            modalType="REPORT_DAMAGE_PALLETS"
            oneOrderAction={!!orderId}
            excessPallets={excessPallets}
            returnBtnText="Wróć do zglaszania palet"
            onReturn={handleReturnToDetails}
         />
         {loading ? (
            <div className={styles.loader}>
               <Loader color={'var(--light-green)'} />
            </div>
         ) : (
            <>
               {!isTablet && (
                  <div className={styles.stepsContainer}>
                     <Step
                        completed={activeStep === 'ADDITIONAL_FEE'}
                        active={activeStep === 'DETAILS'}
                        text="Szczegóły"
                        stepCount={1}
                        className={classNames(styles.step, {
                           [styles.active]: activeStep === 'DETAILS',
                        })}
                     />
                     <ChevronRightIcon className={styles.chevron} />
                     <Step
                        active={activeStep === 'ADDITIONAL_FEE'}
                        text="Obciążenie"
                        stepCount={2}
                        className={classNames(styles.step, {
                           [styles.active]: activeStep === 'ADDITIONAL_FEE',
                        })}
                     />
                  </div>
               )}
               {stepContent}
               <LostDamagedPalletsStepFooter
                  onNextClick={
                     activeStep === 'DETAILS'
                        ? handleSubmitLostDamagedPalletsTableForm
                        : handleReportLostDamagedPallets
                  }
                  onPrevClick={
                     activeStep === 'DETAILS'
                        ? handleReturnToSelectDamageType
                        : handleReturnToDetails
                  }
                  nextButtonText={
                     activeStep === 'DETAILS'
                        ? 'Następny krok'
                        : 'Zgłoś zgubienie lub uszkodzenie palet'
                  }
                  className={classNames({
                     [styles.errorStepFooter]:
                        !!commonErrors.size ||
                        (activeStep === 'ADDITIONAL_FEE' && reportLostDestroyedPalletsError),
                  })}
               />
            </>
         )}
      </>
   );
};

export default LostDamagedPalletsByClientModalContent;
