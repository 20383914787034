import {
   EMPTY_INPUT_ERROR,
   PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR,
} from 'constants/validationErrors';

import { isNonEmpty, moreThanZero } from '.';

export const lostDamagedPalletsFormValidations = {
   palletType: (palletType: { value: string; label: string }) =>
      !isNonEmpty(palletType.value) && EMPTY_INPUT_ERROR,
   damageType: (damageType: 'LOST_OR_DESTRUCTION' | 'DAMAGE' | null) =>
      !isNonEmpty(damageType) && 'Zaznacz rodzaj uszkodzenia.',
   palletsAmount: (palletsAmount: string) => {
      if (!isNonEmpty(palletsAmount)) {
         return EMPTY_INPUT_ERROR;
      }
      if (!moreThanZero(palletsAmount)) {
         return PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR;
      }
      return false;
   },
};
