import classNames from 'classnames';
import { Dots } from 'tabler-icons-react';

import { StyledButton, ToolTip } from 'components/shared';
import { TransferActionType, TransferEntryDTO } from 'interfaces';

import PalletsTransferHistoryActionBodyItem from './PalletsTransferHistoryActionBodyItem/PalletsTransferHistoryActionBodyItem';
import styles from './PalletsTransferHistoryActionBody.module.css';

export type PalletsTransferHistoryActionBodyProps = {
   actionType: TransferActionType;
   transferActionItemsData: TransferEntryDTO[];
   className?: string;
   onMoreTransferActionItemsDataClick?: () => void;
};

const PalletsTransferHistoryActionBody = ({
   actionType,
   transferActionItemsData,
   className,
   onMoreTransferActionItemsDataClick,
}: PalletsTransferHistoryActionBodyProps) => (
   <ul className={classNames(styles.list, className)}>
      {transferActionItemsData.map((transferActionItem, index) => {
         const { palletType, balanceAfter, balanceType, address, amount, effectiveDate, user } =
            transferActionItem;

         return (
            <PalletsTransferHistoryActionBodyItem
               key={index}
               user={user}
               effectiveDate={effectiveDate}
               actionType={actionType}
               palletType={palletType}
               balanceType={balanceType}
               address={address}
               amount={amount}
               balanceAfter={balanceAfter}
            />
         );
      })}
      {onMoreTransferActionItemsDataClick && (
         <ToolTip content="Zobacz wszystkie" variant="top" visible>
            <StyledButton
               icon={<Dots size={16} />}
               className={styles.moreButton}
               onClick={onMoreTransferActionItemsDataClick}
            />
         </ToolTip>
      )}
   </ul>
);

export default PalletsTransferHistoryActionBody;
