import { BalancePickUpDTO } from 'interfaces/dto/balancePickUpDTO';
import { CommercialNetworkAddress } from 'interfaces/dto/commercialNetworkAddress';
import { DistributionCenterBalanceItemDTO } from 'interfaces/dto/distributionCenterBalanceItemDTO';
import { TransferPalletsDistributionCenter } from 'service';

export enum PickUpStatus {
   CREATED = 'CREATED',
   ACCEPTED = 'ACCEPTED',
   REJECTED = 'REJECTED',
}

type PalletPickUpDistributionCenterDTO = {
   id: string;
   commercialNetworkName: string;
   address: CommercialNetworkAddress;
};

export type PalletPickUpAdminDTO = {
   id: string;
   createdDate: string;
   createdBy: string;
   distributionCenter: PalletPickUpDistributionCenterDTO;
   pickUpTime: string;
   status: PickUpStatus;
   palletBalances: BalancePickUpDTO[];
   palletBalanceDifferences: BalancePickUpDTO[];
   totalSum: DistributionCenterBalanceItemDTO;
   totalSumDifference: DistributionCenterBalanceItemDTO;
   read: boolean;
};

export type AcceptPickUpRequest = {
   storageId: string;
   palletBalances: TransferPalletsDistributionCenter[];
};

export type DistributionCenterPalletPickUpFilters = {
   nameSearch?: string;
   status: PickUpStatus;
};
