import React, { CSSProperties, MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import {
   ErrorIcon,
   InfoBlueIcon,
   InfoGrayIcon,
   SuccessIcon,
   WarningIcon,
   XIcon,
} from 'storybook/assets';

import styles from './Banner.module.css';

export interface BannerProps {
   variant: 'info' | 'info_gray' | 'warning' | 'error' | 'success';
   children: ReactNode;
   title?: string;
   withCloseIcon?: boolean;
   icon?: ReactNode;
   onClick?: (event: ReactMouseEvent) => void;
   onClose?: (event: ReactMouseEvent) => void;
   fullWidth?: boolean;
   className?: string;
   style?: CSSProperties;
}

const icons = {
   info: <InfoBlueIcon />,
   info_gray: <InfoGrayIcon />,
   warning: <WarningIcon />,
   error: <ErrorIcon />,
   success: <SuccessIcon />,
};

export const Banner = ({
   children,
   withCloseIcon,
   icon,
   variant,
   title,
   onClick,
   onClose,
   fullWidth,
   className,
   style,
}: BannerProps) => (
   <div
      onClick={onClick}
      className={classNames(
         styles.banner,
         {
            [styles.fullWidth]: fullWidth,
            [styles.info]: variant === 'info',
            [styles.infoGray]: variant === 'info_gray',
            [styles.warning]: variant === 'warning',
            [styles.error]: variant === 'error',
            [styles.success]: variant === 'success',
            [styles.withCloseIcon]: withCloseIcon,
            [styles.withTitle]: title,
         },
         className,
      )}
      style={style}
   >
      <span className={styles.icon}>{icon || icons[variant]}</span>
      <div className={styles.textContainer}>
         {title && <h2>{title}</h2>}
         {<span>{children}</span>}
      </div>
      {withCloseIcon && <XIcon onClick={onClose} className={styles.closeIcon} />}
   </div>
);
