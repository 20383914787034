import { CustomModal } from 'components/shared';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';

import styles from './SuccessReportLostDamagedPalletsBySupplierModal.module.css';

type SuccessReportLostDamagedPalletsBySupplierModalProps = {
   opened: boolean;
   isSupplier?: boolean;
   onClose: () => void;
};

const SuccessReportLostDamagedPalletsBySupplierModal = ({
   opened,
   isSupplier,
   onClose,
}: SuccessReportLostDamagedPalletsBySupplierModalProps) => {
   const { isTablet } = useResponsiveLayout();

   const modalBody = isSupplier
      ? 'Skontaktuj się z klientem w celu ustalenia terminu wymiany palet na nowe.'
      : 'Nasz pracownik skontaktuje się z Tobą jak najszybciej w celu ustalenia terminu wymiany palet na nowe.';
   const innerTitle = isSupplier ? 'Palety zostały zgłoszone.' : 'Dziękujemy za wiadomość!';
   const icon = isSupplier ? 'USER_EXCLAMATION' : 'THUMB_UP';

   return (
      <CustomModal
         opened={opened}
         onClose={onClose}
         innerTitle={innerTitle}
         icon={icon}
         primaryButtonProps={{
            text: 'Zamknij',
            variant: isTablet ? 'filled-primary' : 'outlined-primary',
            onClick: onClose,
         }}
         modalContentClassName={styles.modalContent}
         centeredButtons
      >
         <p className={styles.text}>{modalBody}</p>
      </CustomModal>
   );
};

export default SuccessReportLostDamagedPalletsBySupplierModal;
