import { EMPTY_INPUT_ERROR } from 'constants/validationErrors';
import { AddCommercialNetworkFormValues } from 'interfaces';

import { addDistributionCenterFormValidations } from './addDistributionCenterFormValidation';
import { isNonEmpty } from '.';

export const addNetworkCenterFormValidation = [
   ({ name }: AddCommercialNetworkFormValues) => !isNonEmpty(name) && { name: EMPTY_INPUT_ERROR },
   ...addDistributionCenterFormValidations,
];
