import { PageLayout, PageLayoutProps } from '..';

import OrderDetailsHeader, {
   OrderDetailsHeaderOrderData,
} from './OrderDetailsHeader/OrderDetailsHeader';
import styles from './OrderDetailsLayout.module.css';

type OrderDetailsLayoutProps = Pick<PageLayoutProps, 'children' | 'isLoading'> & {
   headerOrderData?: OrderDetailsHeaderOrderData;
   didFetchDataErrorOccur?: boolean;
};

const OrderDetailsLayout = ({
   children,
   headerOrderData,
   isLoading,
   didFetchDataErrorOccur,
}: OrderDetailsLayoutProps) => (
   <PageLayout
      headerElement={
         headerOrderData ? <OrderDetailsHeader orderData={headerOrderData} /> : undefined
      }
      isLoading={isLoading}
      didFetchDataErrorOccur={didFetchDataErrorOccur}
      classNames={{ pageContainer: styles.pageContainer, pageLoader: styles.pageLoader }}
   >
      {children}
   </PageLayout>
);

export default OrderDetailsLayout;
