import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DamagedLostPalletsLayout } from 'components/layout';
import { DamagedLostPalletsListSection } from 'components/Pallets';
import WithAuth from 'hoc/withAuth';
import { useDamagedLostPalletsPage } from 'hooks';
import { HTTPService } from 'service';
import { palletReportDTOMappersToPalletReportMapper } from 'service/http/mappers';

const DamagedPalletsByClientPage = () => {
   const { palletReports, isLoading, bannerData, handleError, setIsLoading, setPalletReports } =
      useDamagedLostPalletsPage();
   const { id: clientId } = useParams();

   useEffect(() => {
      if (!clientId) {
         return;
      }
      setIsLoading(true);
      HTTPService.getClientDamagedLostPallets(clientId, false, 'DAMAGE')
         .then(({ data: responseData }) => {
            const mappedPalletReports = responseData.reports.map(
               palletReportDTOMappersToPalletReportMapper,
            );
            setPalletReports(mappedPalletReports);
         })
         .catch(handleError)
         .finally(() => setIsLoading(false));
   }, [setIsLoading, setPalletReports, handleError, clientId]);

   return (
      <DamagedLostPalletsLayout bannerData={bannerData}>
         <DamagedLostPalletsListSection
            palletReports={palletReports}
            title="Uszkodzone palety z winy klienta"
            isLoading={isLoading}
         />
      </DamagedLostPalletsLayout>
   );
};

export default WithAuth(DamagedPalletsByClientPage, 'ROLE_SYSTEM_ADMIN');
