import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { STORAGE_TYPE_PL } from 'constants/storageType';
import { SendPalletDTO } from 'interfaces';

import { parseDate } from './parseDate';

export const getEditPalletsItems = (editPalletsItems?: SendPalletDTO[]) =>
   editPalletsItems?.map((pallet, key) => {
      const labelCommercialNetwork = (sendPallet: SendPalletDTO) => {
         const commercialNetwork = sendPallet.destination.type;
         if (commercialNetwork === 'USER_ADDRESS') {
            return 'Adres własny';
         }
         if (commercialNetwork === 'STORAGE') {
            return `Magazyn ${STORAGE_TYPE_PL[pallet.destination.storage.type!]}`;
         }
         return pallet.displayName;
      };

      const valueCommercialNetwork = (sendPallet: SendPalletDTO) => {
         const commercialNetwork = sendPallet.destination.type;
         if (commercialNetwork === 'USER_ADDRESS') {
            return 'CLIENT';
         }
         if (commercialNetwork === 'STORAGE') {
            return sendPallet.destination.storage.type;
         }
         return pallet.displayName;
      };

      return {
         commercialNetworkAddress: {
            label:
               pallet.destination.type === 'USER_ADDRESS'
                  ? `${pallet.displayName} ${pallet.displayAddress}`
                  : pallet.displayAddress,
            value:
               pallet.destination.type === 'USER_ADDRESS'
                  ? `${pallet.displayName} ${pallet.displayAddress}`
                  : pallet.displayAddress,
         },
         commercialNetworkName: {
            label: labelCommercialNetwork(pallet),
            value: valueCommercialNetwork(pallet),
         },
         externalCommercialNetworkNumber: pallet.externalCommercialNetworkNumber!,
         palletsItems: pallet.pallets.map((item, index) => ({
            uuid: index.toString(),
            palletType: { value: item.palletType, label: PALLET_TYPE_LABELS[item.palletType] },
            palletsAmount: item.amount.toString(),
         })),
         sendDate: parseDate(pallet.dateOfShipment),
         uuid: key.toString(),
         includesDamagedPallets: pallet.includesDamagedPallets,
      };
   });
