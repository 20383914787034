import { PalletReportDTO } from 'interfaces';
import { PalletBalance, ReportType } from 'interfaces/commonInterfacesAndTypes';

import { getDefaultPalletsBalanceValues, sumPalletsBalances } from '.';

export const getDamagedPalletsFromReports = (reports: PalletReportDTO[], damageType?: ReportType) =>
   reports.reduce<PalletBalance>((reportsSummary, currentReport) => {
      const damagedPalletsFromReport = currentReport.damagedPallets.reduce<PalletBalance>(
         (palletsSummary, currentPallet) => {
            if (!damageType) {
               palletsSummary[currentPallet.type] =
                  palletsSummary[currentPallet.type] + currentPallet.amount;
            } else {
               damageType === currentPallet.reportType &&
                  (palletsSummary[currentPallet.type] =
                     palletsSummary[currentPallet.type] + currentPallet.amount);
            }

            return palletsSummary;
         },
         getDefaultPalletsBalanceValues(),
      );
      return sumPalletsBalances(reportsSummary, damagedPalletsFromReport);
   }, getDefaultPalletsBalanceValues());
