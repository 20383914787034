import { useEffect, useMemo, useState } from 'react';

import DistributionCenterTable from 'components/DistributionCenterTable/DistributionCenterTable';
import { CustomModal, StyledSelect } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { STORAGE_TYPES_FOR_ADMISSION_PICKER } from 'constants/storageType';
import { useTableForm } from 'hooks';
import {
   CommercialNetwork,
   DistributionCenter,
   Option,
   StorageDTO,
   TransferPalletBalance,
} from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';
import {
   checkIfDefinedSomePalletsToPickUp,
   getDefaultTransferPalletsValues,
} from 'utils/functions';
import { getStoragesAddressOptions } from 'utils/functions/getStoragesAddressOptions';
import { transferPalletsValidations } from 'utils/validation/transferPalletsDistributionCenter';

import styles from './AdmissionPalletsDistributionCenterModal.module.css';

type AdmissionPalletsDistributionCenterModalProps = {
   opened: boolean;
   distributionCenter: DistributionCenter;
   commercialNetwork: CommercialNetwork;
   setIsOpenedAdmission: (state: boolean) => void;
   onSubmit: (
      palletBalance: TransferPalletBalance[],
      destination: { userAddressId?: string; storageId: string },
      setError: (value: string) => void,
      setRows: (rows: TransferPalletBalance[]) => void,
   ) => void;
};

const AdmissionPalletsDistributionCenterModal = ({
   opened,
   distributionCenter,
   commercialNetwork,
   onSubmit,
   setIsOpenedAdmission,
}: AdmissionPalletsDistributionCenterModalProps) => {
   const [errorInfo, setErrorInfo] = useState<string>('');
   const [toStorage, setToStorage] = useState<Option | undefined>(undefined);
   const [toAddress, setToAddress] = useState<Option | undefined>(undefined);
   const [storages, setStorages] = useState<StorageDTO[]>([]);
   const [isLoading, setIsLoading] = useState<boolean>(true);

   useEffect(() => {
      setIsLoading(true);
      Promise.all([HTTPService.getStorageList({ size: PAGINATION_MAX_SIZE })])
         .then(([storagesResponse]) => {
            setStorages(storagesResponse.data.content);
         })
         .catch(handleFetchDataError)
         .finally(() => setIsLoading(false));
   }, []);

   const handleFetchDataError = (error: unknown) =>
      errorHandler(error, () => setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA));

   const admissionSubmit = (values: TransferPalletBalance[]) => {
      onSubmit(
         values,
         {
            userAddressId: undefined,
            storageId: toAddress ? toAddress?.value : null,
         },
         setErrorInfo,
         setRows,
      );
   };

   const clearStateForModal = () => {
      setRows(getDefaultTransferPalletsValues());
      setErrorInfo('');
      setToAddress(undefined);
      setToStorage(undefined);
      setIsOpenedAdmission(false);
   };

   const {
      rows,
      errors,
      touched,
      handleChangeValue,
      handleSubmitForm,
      setRows,
      hasErrors: isInvalidPalletsData,
      commonErrorsOfTouchedInputs: commonErrorsOfPalletsDataTouchedInputs,
   } = useTableForm<TransferPalletBalance>({
      initialState: getDefaultTransferPalletsValues(),
      onSubmit: admissionSubmit,
      validations: transferPalletsValidations,
   });

   const areDefinedSomePalletsToPickUp = useMemo(
      () => checkIfDefinedSomePalletsToPickUp(rows),
      [rows],
   );

   const isInvalidForm = isInvalidPalletsData || !areDefinedSomePalletsToPickUp || !toAddress;

   return (
      <>
         <CustomModal
            className={styles.container}
            modalContentClassName={styles.modalContent}
            opened={opened}
            size={798}
            title="Przyjęcie na magazyn"
            innerTitle="W lewej kolumnie wybierz z jakiego miejsca i jaki rodzaj palet chcesz odebrać , w prawej kolumnie wybierz magazyn docelowy, do którego trafią palety.  Możesz wybrać tylko jeden rodzaj palet jednocześnie."
            onClose={clearStateForModal}
            primaryButtonProps={{ text: 'Przyjmij na magazyn', disabled: isInvalidForm }}
            secondaryButtonProps={{
               text: 'Anuluj',
               onClick: clearStateForModal,
            }}
            onSubmit={handleSubmitForm}
            isLoading={isLoading}
            error={errorInfo}
         >
            <div className={styles.commercialNetworkNameContainer}>
               <p>Magazyn początkowy</p>
               <p>{`${commercialNetwork.name}, ul. ${distributionCenter.address.street}, ${distributionCenter.address.zipCode} ${distributionCenter.address.city}`}</p>
            </div>
            <form>
               <div className={styles.inputRow}>
                  <StyledSelect
                     label="Wybierz magazyn docelowy"
                     options={STORAGE_TYPES_FOR_ADMISSION_PICKER}
                     onChange={(value: Option) => {
                        setToStorage(value);
                        setToAddress(undefined);
                     }}
                     value={toStorage}
                  />
                  <StyledSelect
                     label="Adres docelowy"
                     disabled={!toStorage}
                     options={getStoragesAddressOptions(storages, toStorage)}
                     onChange={setToAddress}
                     value={toAddress}
                  />
               </div>
               <div className={styles.modalContent}>
                  <DistributionCenterTable
                     mode="transfer"
                     handleChangeValue={handleChangeValue}
                     rows={rows}
                     errors={errors}
                     touched={touched}
                     isVisibleErrorBanner={!!commonErrorsOfPalletsDataTouchedInputs.size}
                  />
               </div>
            </form>
         </CustomModal>
      </>
   );
};

export default AdmissionPalletsDistributionCenterModal;
