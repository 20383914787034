import { PickUpStatus } from 'interfaces';

export const pickUpStatusesLegend: {
   status: string;
   description: string;
   variant: PickUpStatus;
}[] = [
   {
      status: 'Weryfikacja',
      description: ' palety zgłoszone do odbioru',
      variant: PickUpStatus.CREATED,
   },

   {
      status: 'Odebrane',
      description: ' zaakceptowane zlecenia',
      variant: PickUpStatus.ACCEPTED,
   },
   {
      status: 'Anulowane',
      description: ' anulowane zlecenia',
      variant: PickUpStatus.REJECTED,
   },
];

export const PALLET_PICK_UP_STATUSES_BADGE_DATA: Record<
   PickUpStatus,
   { label: string; variant: PickUpStatus }
> = {
   [PickUpStatus.CREATED]: { label: 'Weryfikacja', variant: PickUpStatus.CREATED },
   [PickUpStatus.ACCEPTED]: { label: 'Odebrane', variant: PickUpStatus.ACCEPTED },
   [PickUpStatus.REJECTED]: { label: 'Anulowane', variant: PickUpStatus.REJECTED },
};
