import React from 'react';

import { PackgeImport, SquareOff } from 'tabler-icons-react';

import SupplierPalletsNumberToPickUp from 'components/PickUps/supplierPanel/SupplierPalletsNumberToPickUp/SupplierPalletsNumberToPickUp';
import { ActionsDropdown, StyledBadge, StyledButton } from 'components/shared';
import { PALLET_PICK_UP_STATUSES_BADGE_DATA } from 'constants/pickUpStatuses';
import { PalletPickUpAdminDTO, PickUpStatus, SupplierPalletsPickUpsModalType } from 'interfaces';
import { TableCell, TableRow } from 'storybook';
import { getFormattedDateFromString, getTimeFromString } from 'utils/functions';

import styles from './SupplierPalletsPickUpsTableRow.module.css';

export type SupplierPalletsPickUpsTableRowProps = {
   distributionCenterPalletsPickUpData: PalletPickUpAdminDTO;
   onPalletsPickUpOpenModalClick: ({
      distributionCenterPalletsPickUpData,
      modalType,
   }: {
      distributionCenterPalletsPickUpData: PalletPickUpAdminDTO;
      modalType: SupplierPalletsPickUpsModalType;
   }) => void;
};

const SupplierPalletsPickUpsTableRow = ({
   distributionCenterPalletsPickUpData,
   onPalletsPickUpOpenModalClick,
}: SupplierPalletsPickUpsTableRowProps) => {
   const {
      distributionCenter,
      createdDate,
      pickUpTime,
      status,
      totalSum,
      totalSumDifference,
      read,
   } = distributionCenterPalletsPickUpData;
   const { commercialNetworkName, address: distributionCenterAddress } = distributionCenter;

   const handleRejectPalletsPickUpButtonClick = () =>
      onPalletsPickUpOpenModalClick({
         distributionCenterPalletsPickUpData,
         modalType: SupplierPalletsPickUpsModalType.REJECT,
      });

   const handleAcceptPalletsPickUpButtonClick = () =>
      onPalletsPickUpOpenModalClick({
         distributionCenterPalletsPickUpData,
         modalType: SupplierPalletsPickUpsModalType.ACCEPT,
      });

   const formattedCreatedDate = getFormattedDateFromString(createdDate);
   const formattedPickUpDate = getFormattedDateFromString(pickUpTime);
   const formattedPickUpTime = getTimeFromString(pickUpTime);
   const { variant: statusBadgeVariant, label: statusBadgeLabel } =
      PALLET_PICK_UP_STATUSES_BADGE_DATA[status];

   return (
      <TableRow className={styles.tableRow}>
         {!read && (
            <TableCell className={styles.tableCell}>
               <StyledBadge variant="NEW" text="nowe" />
            </TableCell>
         )}
         <TableCell className={styles.tableCell}>
            <p className={styles.addressFirstLine}>{commercialNetworkName}</p>
            <p
               className={styles.addressSecondLine}
            >{`${distributionCenterAddress.zipCode} ${distributionCenterAddress.city}`}</p>
         </TableCell>
         <TableCell className={styles.tableCell}>{formattedCreatedDate}</TableCell>
         <TableCell className={styles.tableCell}>{formattedPickUpDate}</TableCell>
         <TableCell className={styles.tableCell}>{formattedPickUpTime}</TableCell>
         <TableCell className={styles.tableCell}>
            <StyledBadge text={statusBadgeLabel} variant={statusBadgeVariant} />
         </TableCell>
         <TableCell className={styles.tableCell}>
            <SupplierPalletsNumberToPickUp
               totalSum={totalSum}
               totalSumDifference={totalSumDifference}
            />
         </TableCell>
         {status === PickUpStatus.CREATED && (
            <TableCell className={styles.tableCell}>
               <div className={styles.actionsContainer}>
                  <StyledButton
                     variant="text"
                     text="Odbierz"
                     icon={<PackgeImport />}
                     onClick={handleAcceptPalletsPickUpButtonClick}
                  />
                  <ActionsDropdown
                     options={[
                        {
                           text: 'Odrzuć',
                           dangerOption: true,
                           icon: <SquareOff size={20} />,
                           onClick: handleRejectPalletsPickUpButtonClick,
                        },
                     ]}
                     portalTarget={document.body}
                     className={styles.actionsDropdown}
                  />
               </div>
            </TableCell>
         )}
      </TableRow>
   );
};

export default SupplierPalletsPickUpsTableRow;
