import { Fragment } from 'react';

import { TransferActionOverviewDTO } from 'interfaces';
import { Divider } from 'storybook';

import PalletsTransferHistoryListItem from './PalletsTransferHistoryListItem/PalletsTransferHistoryListItem';
import styles from './PalletsTransferHistoryList.module.css';

type PalletsTransferHistoryListProps = {
   transferActionData: TransferActionOverviewDTO[];
   onMoreTransferActionItemsDataClick: (
      transferActionOverviewDTO: TransferActionOverviewDTO,
   ) => void;
};

const PalletsTransferHistoryList = ({
   transferActionData,
   onMoreTransferActionItemsDataClick,
}: PalletsTransferHistoryListProps) => (
   <ul className={styles.list}>
      {transferActionData.map((transferActionDataItem, index) => {
         const { id, type, createdDate, createdBy, orderId, entries, hasMoreEntries } =
            transferActionDataItem;
         const isLastListItem = index === transferActionData.length - 1;

         return (
            <Fragment key={id}>
               <PalletsTransferHistoryListItem
                  type={type}
                  createdDate={createdDate}
                  createdBy={createdBy}
                  orderId={orderId}
                  transferActionItemsData={entries}
                  onMoreTransferActionItemsDataClick={
                     hasMoreEntries
                        ? () => onMoreTransferActionItemsDataClick(transferActionDataItem)
                        : undefined
                  }
               />
               {!isLastListItem && <Divider variant="horizontal" className={styles.divider} />}
            </Fragment>
         );
      })}
   </ul>
);

export default PalletsTransferHistoryList;
