import React, {
   forwardRef,
   ReactNode,
   useCallback,
   useImperativeHandle,
   useMemo,
   useState,
} from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { isEqual } from 'lodash';

import { useDropdown } from 'hooks/useDropdown';
import { Option } from 'interfaces';
import { Checkbox, TextInput } from 'storybook';
import { ChevronDownIcon } from 'storybook/assets';

import styles from './MultiSelect.module.css';

export interface MultiSelectProps {
   values: Option[];
   placeholder?: string;
   label?: string;
   options: Option[];
   onChange: (selectedValues: Option[]) => void;
   withFilter?: boolean;
   icon?: ReactNode;
   helperText?: string;
   dropdownBottomSection?: ReactNode;
   error?: boolean;
   disabled?: boolean;
   filterProps?: {
      type?: 'text' | 'number';
      placeholder?: string;
      icon?: ReactNode;
   };
   portalTarget?: HTMLElement;
   classNames?: {
      container?: string;
      optionsContainer?: string;
      filterInput?: string;
      option?: string;
      groupOption?: string;
   };
   width?: string;
   fullWidth?: boolean;
   transformSelectedOptionsLabel?: (values: Option[]) => string;
}

export const MultiSelect = forwardRef(
   (
      {
         values,
         placeholder,
         label,
         options,
         icon,
         onChange,
         withFilter,
         helperText,
         dropdownBottomSection,
         error,
         disabled,
         filterProps,
         portalTarget,
         classNames: multiSelectClassNames,
         width,
         fullWidth,
         transformSelectedOptionsLabel,
      }: MultiSelectProps,
      ref,
   ) => {
      const [enteredText, setEnteredText] = useState('');

      const handleCloseSelect = useCallback(() => setEnteredText(''), []);

      const {
         expanded,
         open,
         close,
         toggleRef: multiSelectRef,
         dropdownContentRefFunc,
         dropdownContentPosition,
         dropdownContentMinWidth,
      } = useDropdown({
         portalTarget,
         onClose: handleCloseSelect,
      });

      useImperativeHandle(ref, () => ({
         closeDropdown: close,
      }));

      const handleMultiSelectOpen = () => {
         if (disabled) return;
         open();
      };

      const handleSelectOption = (option: Option) => {
         let newValues;
         if (isSelected(option)) {
            newValues = values.filter(value => !isEqual(value.value, option.value));
         } else {
            newValues = [...values, option];
         }
         onChange(newValues);
      };

      const handleSelectAll = () => onChange(values.length === options.length ? [] : [...options]);

      const isSelected = (option: Option) =>
         values.some(selectedValue => isEqual(selectedValue.value, option.value));

      const selected = useMemo(() => {
         if (withFilter && options) {
            return options.filter(option =>
               option.label.toLowerCase().trim().includes(enteredText.toLowerCase().trim()),
            );
         } else {
            return options;
         }
      }, [withFilter, options, enteredText]);

      const selectedValuesLabel = useMemo(() => {
         if (!values.length) {
            return placeholder;
         } else if (transformSelectedOptionsLabel) {
            return transformSelectedOptionsLabel(values);
         } else {
            return values.reduce(
               (finalLabel, currentValue, index) =>
                  index !== 0 ? `${finalLabel}, ${currentValue.label}` : currentValue.label,
               '',
            );
         }
      }, [transformSelectedOptionsLabel, values, placeholder]);

      const optionsContent = (
         <div
            ref={dropdownContentRefFunc}
            style={{
               width: width,
               minWidth: dropdownContentMinWidth,
               ...dropdownContentPosition,
            }}
            className={classNames(
               styles.options,
               {
                  [styles.expanded]: expanded,
                  [styles.withPortal]: portalTarget,
                  [styles.withLabel]: label,
               },
               multiSelectClassNames?.optionsContainer,
            )}
         >
            {withFilter && (
               <TextInput
                  type={filterProps?.type || 'text'}
                  value={enteredText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                     setEnteredText(e.target.value)
                  }
                  placeholder={filterProps?.placeholder}
                  leftIcon={filterProps?.icon}
                  className={classNames(styles.filterInput, multiSelectClassNames?.filterInput)}
               />
            )}
            {!!selected?.length ? (
               <>
                  {!enteredText && (
                     <Checkbox
                        label={`Zaznacz wszystkie ${values.length}/${options.length}`}
                        className={classNames(
                           styles.groupOption,
                           multiSelectClassNames?.groupOption,
                        )}
                        onChangeHandler={handleSelectAll}
                        checked={values.length === options.length}
                        indeterminate
                     />
                  )}
                  {selected?.map((option, index) => (
                     <Checkbox
                        key={index}
                        label={option.label}
                        className={classNames(
                           styles.option,
                           {
                              [styles.selected]: isSelected(option),
                           },
                           multiSelectClassNames?.option,
                        )}
                        checked={isSelected(option)}
                        onChangeHandler={() => handleSelectOption(option)}
                     />
                  ))}
               </>
            ) : (
               <div className={styles.noOptionsMessage}>
                  <span>Brak opcji</span>
               </div>
            )}
            {dropdownBottomSection && (
               <div className={styles.dropdownBottomSection}>{dropdownBottomSection}</div>
            )}
         </div>
      );

      return (
         <div
            className={classNames(
               styles.multiSelectContainer,
               { [styles.fullWidth]: fullWidth },
               multiSelectClassNames?.container,
            )}
         >
            {label && <p className={styles.multiSelectLabel}>{label}</p>}
            <div
               ref={multiSelectRef}
               style={{ width: width }}
               onClick={handleMultiSelectOpen}
               className={classNames(styles.multiSelect, {
                  [styles.error]: error,
                  [styles.disabled]: disabled,
                  [styles.expanded]: expanded,
               })}
            >
               <div className={styles.multiSelectLeftContent}>
                  {icon}
                  <span>{selectedValuesLabel}</span>
               </div>
               <ChevronDownIcon />
            </div>
            {expanded &&
               (portalTarget ? createPortal(optionsContent, portalTarget) : optionsContent)}
            {helperText && (
               <p
                  className={classNames(styles.helperText, {
                     [styles.error]: error,
                  })}
               >
                  {helperText}
               </p>
            )}
         </div>
      );
   },
);
