import { TransferActionsOverviewFiltersForm } from 'interfaces';

export const initializeDefaultTransferActionsOverviewFiltersForm: () => TransferActionsOverviewFiltersForm =
   () => ({
      client: undefined,
      clientAddress: undefined,
      commercialNetwork: undefined,
      distributionCenter: undefined,
      storage: undefined,
      dateFrom: undefined,
      dateTo: undefined,
   });
