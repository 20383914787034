import { useCallback, useState } from 'react';

import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { BannerData, PalletReport } from 'interfaces';
import { logNetworkError } from 'utils/logNetworkError';

export const useDamagedLostPalletsPage = () => {
   const [palletReports, setPalletReports] = useState<PalletReport[]>([]);
   const [isLoading, setIsLoading] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const handleError = useCallback((error: unknown) => {
      setBannerData({ variant: 'error', description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA });
      logNetworkError(error);
   }, []);

   return {
      palletReports,
      isLoading,
      bannerData,
      handleError,
      setPalletReports,
      setIsLoading,
      setBannerData,
   };
};
