import { useCallback } from 'react';

import { cloneDeep } from 'lodash';

import { SectionContainer } from 'components/shared';
import { BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX } from 'constants/basePalletsPricingPerAddressDataIndex';
import { useForm } from 'hooks/useForm';
import {
   CommonRatePerPalletConfigFormData,
   DelayChargePerPalletConfigForm,
   FormStepProps,
   PalletsPricingPerAddressFormData,
   PalletType,
   RateConfigForm,
   RetentionConfigForm,
   RetentionPalletCostConfigForm,
} from 'interfaces';
import { Banner } from 'storybook';
import { palletRatesConfigurationFormValidations } from 'utils/validation';

import FormStepsFooter from '../../FormStepsFooter/FormStepsFooter';

import CommonPalletsPricing from './CommonPalletsPricing/CommonPalletsPricing';
import DelaysCharges, { DelaysChargesFields } from './DelaysCharges/DelaysCharges';
import PalletsPricingPerAddress from './PalletsPricingPerAddress/PalletsPricingPerAddress';
import RentPeriod from './RentPeriod/RentPeriod';
import styles from './RatesConfigurationForm.module.css';

const RatesConfigurationForm = ({
   initialState,
   onSubmit,
   setCurrentStep,
}: FormStepProps<RateConfigForm>) => {
   const {
      touched,
      values,
      submitHandler,
      errorsList: sectionsErrors,
      setValues,
   } = useForm({
      initialState: {
         palletRates: initialState.palletRates,
         retentionConfig: initialState.retentionConfig,
         delaysCharges: initialState.delaysCharges,
      },
      onSubmit: () => {
         onSubmit(values as RateConfigForm);
         setCurrentStep(prevStep => prevStep + 1);
      },
      validations: palletRatesConfigurationFormValidations,
   });

   const handleCommonPalletsPricingChange = useCallback(
      (enteredValue: string, priceType: string, palletType: PalletType) => {
         setValues((prevValues: RateConfigForm) => {
            const clonedPrevValues = cloneDeep(prevValues);
            const newCommonPalletsPricing = clonedPrevValues.palletRates.common.map(
               commonPalletsPricingItem => {
                  if (commonPalletsPricingItem.palletType === palletType) {
                     return { ...commonPalletsPricingItem, [priceType]: enteredValue };
                  } else {
                     return commonPalletsPricingItem;
                  }
               },
            );
            clonedPrevValues.palletRates.common = newCommonPalletsPricing;
            return clonedPrevValues;
         });
      },
      [setValues],
   );

   const handlePalletsPricingPerAddressChange = useCallback(
      (
         isBasePalletsPricing: boolean,
         enteredValue: string,
         palletType: PalletType,
         address: string,
      ) => {
         setValues((prevValues: RateConfigForm) => {
            const clonedPrevPalletRatesPerAddressValues = cloneDeep(
               prevValues.palletRates.perAddress,
            );
            const basePalletsPricingPerAddressData =
               clonedPrevPalletRatesPerAddressValues[BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX];
            let newPalletRatesPerAddressValues = [];
            if (!isBasePalletsPricing) {
               newPalletRatesPerAddressValues = clonedPrevPalletRatesPerAddressValues.map(
                  palletsPricingPerAddress => {
                     if (palletsPricingPerAddress.addressName === address) {
                        const updatedPalletsPricing = palletsPricingPerAddress.palletsPricing.map(
                           palletsPricingItem => {
                              if (palletsPricingItem.palletType === palletType) {
                                 return { ...palletsPricingItem, deliveryPrice: enteredValue };
                              }
                              return palletsPricingItem;
                           },
                        );
                        return {
                           ...palletsPricingPerAddress,
                           palletsPricing: updatedPalletsPricing,
                        };
                     }
                     return palletsPricingPerAddress;
                  },
               );
            } else {
               newPalletRatesPerAddressValues = clonedPrevPalletRatesPerAddressValues.map(
                  palletsPricingPerAddress => {
                     const updatedPalletsPricing = palletsPricingPerAddress.palletsPricing.map(
                        palletsPricingItem => {
                           const basePalletsPricingPerAddressItem =
                              basePalletsPricingPerAddressData.palletsPricing.find(
                                 basePalletsPricingItem =>
                                    basePalletsPricingItem.palletType ===
                                    palletsPricingItem.palletType,
                              );
                           if (!basePalletsPricingPerAddressItem) {
                              return palletsPricingItem;
                           }
                           const isBasePriceDifferent =
                              basePalletsPricingPerAddressItem.deliveryPrice !==
                              palletsPricingItem.deliveryPrice;
                           if (
                              palletsPricingItem.palletType === palletType &&
                              !isBasePriceDifferent
                           ) {
                              return { ...palletsPricingItem, deliveryPrice: enteredValue };
                           }
                           return palletsPricingItem;
                        },
                     );

                     return {
                        ...palletsPricingPerAddress,
                        palletsPricing: updatedPalletsPricing,
                     };
                  },
               );
            }
            return {
               ...prevValues,
               palletRates: {
                  ...prevValues.palletRates,
                  perAddress: newPalletRatesPerAddressValues,
               },
            };
         });
      },
      [setValues],
   );

   const handleChangeRetentionPeriodCost = (enteredValue: string, palletType: string) => {
      setValues((prevValues: RateConfigForm) => {
         const clonedPrevValues = cloneDeep(prevValues);
         const newRetentionPalletCosts = clonedPrevValues.retentionConfig.retentionPalletCosts.map(
            (palletCost: RetentionPalletCostConfigForm) => {
               if (palletCost.palletType === palletType) {
                  return { ...palletCost, price: enteredValue };
               }
               return palletCost;
            },
         );
         clonedPrevValues.retentionConfig.retentionPalletCosts = newRetentionPalletCosts;
         return clonedPrevValues;
      });
   };

   const handleRetentionPeriodDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValues((prevValues: RateConfigForm) => ({
         ...prevValues,
         retentionConfig: {
            ...prevValues.retentionConfig,
            retentionPeriodDays: Number(e.target.value),
         },
      }));
   };

   const handleChangeDelaysCharge = (
      enteredValue: string,
      palletType: string,
      field: DelaysChargesFields,
   ) => {
      setValues((prevValues: RateConfigForm) => {
         const clonedPrevValues = cloneDeep(prevValues);
         const newDelaysCharges = clonedPrevValues.delaysCharges.map(
            (delayCharge: DelayChargePerPalletConfigForm) => {
               if (delayCharge.palletType === palletType) {
                  return { ...delayCharge, [field]: enteredValue };
               }
               return delayCharge;
            },
         );
         clonedPrevValues.delaysCharges = newDelaysCharges;
         return clonedPrevValues;
      });
   };

   return (
      <form onSubmit={submitHandler}>
         <SectionContainer title="Konfiguracja stawek">
            <SectionContainer title="Cennik" innerSection>
               <Banner variant="warning" fullWidth style={{ marginBottom: 24 }}>
                  Aby zmienić cenę zamówienia dla innego adresu dostawy, kliknij na dany adres w
                  tabeli. Domyślnie „Cena z dostawą” jest taka sama, ale można ją zmienić w
                  odpowiednim polu dla drugiego i kolejnych adresów.
               </Banner>
               <div className={styles.palletsPricingInnerContainer}>
                  <CommonPalletsPricing
                     values={values.palletRates.common as CommonRatePerPalletConfigFormData[]}
                     errors={sectionsErrors}
                     touched={touched}
                     onChangePalletsPrice={handleCommonPalletsPricingChange}
                  />
                  <PalletsPricingPerAddress
                     palletsPricingPerAddresses={
                        values.palletRates.perAddress as PalletsPricingPerAddressFormData[]
                     }
                     errors={sectionsErrors}
                     touched={touched}
                     onChangePalletsPrice={handlePalletsPricingPerAddressChange}
                  />
               </div>
            </SectionContainer>
            <SectionContainer title="Okres wynajmu" innerSection>
               <RentPeriod
                  values={values.retentionConfig as RetentionConfigForm}
                  errors={sectionsErrors}
                  touched={touched}
                  onChangeRetentionPeriodCost={handleChangeRetentionPeriodCost}
                  onRetentionPeriodDaysChange={handleRetentionPeriodDaysChange}
               />
            </SectionContainer>
            <SectionContainer title="Opłaty" innerSection>
               <DelaysCharges
                  values={values.delaysCharges as DelayChargePerPalletConfigForm[]}
                  errors={sectionsErrors}
                  touched={touched}
                  onChangeDelaysCharge={handleChangeDelaysCharge}
               />
            </SectionContainer>
         </SectionContainer>
         <FormStepsFooter
            backDisabled={false}
            onBack={() => setCurrentStep((prevValue: number) => prevValue - 1)}
         />
      </form>
   );
};

export default RatesConfigurationForm;
