import InputMask from 'react-input-mask';

import classNames from 'classnames';

import { TextInputProps } from 'storybook';

import styles from './MaskedInput.module.css';

type MaskedInputProps = {
   mask: string;
} & Omit<
   TextInputProps,
   'leftIcon' | 'width' | 'clearButton' | 'onClick' | 'onClear' | 'style' | 'fullWidth'
>;

export const MaskedInput = ({
   type,
   value,
   mask,
   name,
   label,
   placeholder,
   additionalContent,
   helperText,
   error,
   className,
   disabled,
   onChange,
}: MaskedInputProps) => (
   <div className={classNames(styles.inputContainer, { [styles.containerError]: error })}>
      {!!label && <label htmlFor={name}>{label}</label>}
      {!!additionalContent && additionalContent}
      <InputMask
         name={name}
         type={type}
         value={value}
         mask={mask}
         placeholder={placeholder}
         className={classNames(styles.textInput, { [styles.error]: error }, className)}
         disabled={disabled}
         onChange={onChange}
      />
      {!!error && <span className={styles.errorMessage}>{helperText}</span>}
   </div>
);
