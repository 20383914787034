import { SectionContainer } from 'components/shared';
import { DeliveryType } from 'interfaces';

import styles from './SelectedDeliveryTypeSection.module.css';

type SelectedDeliveryTypeSectionProps = {
   selectedDeliveryType: DeliveryType;
};

const SelectedDeliveryTypeSection = ({
   selectedDeliveryType,
}: SelectedDeliveryTypeSectionProps) => (
   <SectionContainer title="Sposób dostawy">
      <p className={styles.deliveryTypeText}>
         {selectedDeliveryType === 'SEPARATE_ORDER'
            ? 'Wyślij w osobnym zamówieniu'
            : 'Dołącz do następnego zamówienia'}
      </p>
   </SectionContainer>
);

export default SelectedDeliveryTypeSection;
