import { RetentionConfig, RetentionConfigForm, RetentionPalletCostConfigForm } from 'interfaces';
import { formatNumberToPrice } from 'utils/functions';

export const parseRetentionConfigIntoRetentionConfigForm: (
   retentionConfig: RetentionConfig,
) => RetentionConfigForm = retentionConfig => {
   const { retentionPeriodDays, retentionPalletCosts } = retentionConfig;

   const retentionPalletCostsFormData: RetentionPalletCostConfigForm[] = retentionPalletCosts.map(
      retentionPalletCostsItem => ({
         palletType: retentionPalletCostsItem.palletType,
         price: formatNumberToPrice(retentionPalletCostsItem.price),
      }),
   );

   return { retentionPeriodDays, retentionPalletCosts: retentionPalletCostsFormData };
};
