import { ReactNode } from 'react';

import styles from './CombinedFiltersRow.module.css';

type CombinedFiltersRowProps = {
   children: ReactNode;
};

const CombinedFiltersRow = ({ children }: CombinedFiltersRowProps) => (
   <div className={styles.container}>{children}</div>
);

export default CombinedFiltersRow;
