import { useCallback, useEffect, useMemo, useState } from 'react';

import { WhiteReplaceIcon } from 'assets';
import { PageHeader, PageLayout } from 'components/layout';
import { StyledButton } from 'components/shared';
import StorageItem from 'components/StorageItem/StorageItem';
import { MovePalletsInsideStorageModal } from 'components/StoragePanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import WithAuth from 'hoc/withAuth';
import { Balance, BannerType, StorageWithDate } from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';
import {
   getFormattedDate,
   getPalletTypesOptionsFromStorageBalance,
   parseStorageDTOIntoStorageWithDate,
} from 'utils/functions';

import styles from './PalletBalancePage.module.css';

const PalletBalancePage = () => {
   const [storage, setStorage] = useState<StorageWithDate>();
   const [isLoading, setIsLoading] = useState(true);
   const [isFetchStorageDataError, setIsFetchStorageDataError] = useState(false);
   const [isPaletteTransferModalOpen, setIsPaletteTransferModalOpen] = useState(false);
   const [bannerData, setBannerData] = useState<BannerType | null>(null);
   const [reload, setReload] = useState<boolean>(false);

   const handleDateChangeError = useCallback(
      (error: unknown) =>
         errorHandler(error, () =>
            setBannerData({ variant: 'error', description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA }),
         ),
      [],
   );

   useEffect(() => {
      HTTPService.getStorageList({
         page: 0,
         size: PAGINATION_MAX_SIZE,
      })
         .then(({ data }) => {
            const parsedStorageWithDate = parseStorageDTOIntoStorageWithDate(data.content[0]);
            setStorage(parsedStorageWithDate);
         })
         .catch(() => setIsFetchStorageDataError(true))
         .finally(() => setIsLoading(false));
   }, [reload]);

   const replaceStorageBalance = useCallback(
      (balance: Balance, date: Date) =>
         setStorage(prevStorage => {
            if (!prevStorage) {
               return;
            }
            return { ...prevStorage, balanceForToday: balance, date };
         }),
      [],
   );

   const handleDateChange = useCallback(
      (date: Date | null, id: string, onFinish: () => void) => {
         if (date) {
            HTTPService.getStorageBalanceByDate(id, getFormattedDate(date))
               .then(({ data }) => replaceStorageBalance(data, date))
               .catch(handleDateChangeError)
               .finally(onFinish);
         }
      },
      [replaceStorageBalance, handleDateChangeError],
   );

   const handleMovePalletsSuccess = () => {
      setReload(prevReload => !prevReload);
      setIsPaletteTransferModalOpen(false);
      setBannerData({ variant: 'success', description: 'Palety zostały przerzucone' });
   };

   const storagePaletteTypesOptions = useMemo(() => {
      if (!storage?.balanceForToday) {
         return [];
      }
      return getPalletTypesOptionsFromStorageBalance(storage.balanceForToday);
   }, [storage]);

   const pageHeader = (
      <PageHeader
         className={styles.header}
         headerLeftElement={
            <div>
               <StyledButton
                  icon={<WhiteReplaceIcon />}
                  variant="filled-primary"
                  text="Przerzuć palety"
                  disabled={!storage}
                  onClick={() => setIsPaletteTransferModalOpen(true)}
               />
            </div>
         }
      />
   );

   return (
      <>
         {isPaletteTransferModalOpen && (
            <MovePalletsInsideStorageModal
               storageId={storage?.id || ''}
               storagePaletteTypesOptions={storagePaletteTypesOptions}
               internal={storage?.type === 'INTERNAL'}
               onSuccess={handleMovePalletsSuccess}
               onClose={() => setIsPaletteTransferModalOpen(false)}
            />
         )}
         <PageLayout
            isLoading={isLoading}
            didFetchDataErrorOccur={isFetchStorageDataError}
            bannerData={bannerData ? { withCloseIcon: true, ...bannerData } : bannerData}
            headerElement={storage && pageHeader}
            closeBanner={() => setBannerData(null)}
         >
            {storage && (
               <StorageItem
                  storage={storage}
                  internal={storage.type === 'INTERNAL'}
                  handleDateChange={handleDateChange}
               />
            )}
         </PageLayout>
      </>
   );
};

export default WithAuth(PalletBalancePage, 'ROLE_STORAGE');
