import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { Balance } from 'interfaces';

import { sortOptions } from './sortOptions';

export const getPalletTypesOptionsFromStorageBalance = (storageBalance: Balance) => {
   const paletteTypesOptions = Object.keys(storageBalance).map(palletType => ({
      label: PALLET_TYPES_LABELS_WITH_DIMENSIONS[
         palletType as keyof typeof PALLET_TYPES_LABELS_WITH_DIMENSIONS
      ].label,
      value: palletType,
   }));
   return sortOptions(paletteTypesOptions);
};
