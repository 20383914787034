export const getSubmitButtonText = (
   isSendPalletsDataFileUploaded: boolean,
   isSendPalletsDataFileVerified: boolean,
   isModalEditMode: boolean,
) => {
   if (isSendPalletsDataFileUploaded && !isSendPalletsDataFileVerified) {
      return 'Sprawdź adresy';
   }
   return isModalEditMode ? 'Zapisz zmiany' : 'Nadaj palety';
};
