import {
   EMPTY_INPUT_ERROR,
   PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR,
} from 'constants/validationErrors';
import { Option } from 'interfaces';

import { isNonEmpty, moreThanZero } from '.';

export const sendPalletsFormValidations: {
   [key: string]:
      | ((arg: string) => string | false)
      | ((arg: Option) => string | false)
      | ((arg: Date | null) => string | false);
} = {
   sendDate: (sendDate: Date | null) => !isNonEmpty(sendDate) && EMPTY_INPUT_ERROR,
   commercialNetworkName: (commercialNetworkName: Option) =>
      !isNonEmpty(commercialNetworkName.value) && EMPTY_INPUT_ERROR,
   commercialNetworkAddress: (commercialNetworkAddress: Option) =>
      !isNonEmpty(commercialNetworkAddress.value) && EMPTY_INPUT_ERROR,
   palletType: (palletType: Option) => !isNonEmpty(palletType.value) && EMPTY_INPUT_ERROR,
   palletsAmount: (palletsAmount: string) => {
      if (!isNonEmpty(palletsAmount)) {
         return EMPTY_INPUT_ERROR;
      }
      if (!moreThanZero(palletsAmount)) {
         return PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR;
      }
      return false;
   },
};

export const validateSendPalletsItemField = (field: string, value: Option | Date | string | null) =>
   !!sendPalletsFormValidations[field]
      ? sendPalletsFormValidations[field](value as Option & Date & string & null)
      : false;
