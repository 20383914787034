import classNames from 'classnames';

import { Divider, Tab, TabProps } from 'storybook';

import styles from './Tabs.module.css';

type Tabs<T> = {
   tabs: Tab<T>[];
   activeTabName: T;
   className?: string;
   onTabClick: (tabName: T) => void;
};

type Tab<T> = {
   label: string;
   value: T;
} & Pick<TabProps, 'infoBadgeProps'>;

const Tabs = <T extends string>({ tabs, activeTabName, className, onTabClick }: Tabs<T>) => (
   <div className={classNames(styles.outerTabsContainer, className)}>
      <div className={styles.innerTabsContainer}>
         {tabs.map(tab => (
            <Tab
               key={tab.value}
               active={activeTabName === tab.value}
               text={tab.label}
               infoBadgeProps={tab.infoBadgeProps}
               onClick={() => onTabClick(tab.value)}
            />
         ))}
      </div>
      <Divider variant="horizontal" className={styles.tabsBottomBorder} />
   </div>
);

export default Tabs;
