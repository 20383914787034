import { ImagesPreview } from 'components/shared';
import { TableCell, TableRow } from 'storybook';

import styles from './LostDamagedPalletsImagesRow.module.css';

type LostDamagedPalletsImagesRowProps = {
   imageURLs: string[];
   onClickDeleteImage: (imageURL: string) => void;
};

const LostDamagedPalletsImagesRow = ({
   imageURLs,
   onClickDeleteImage,
}: LostDamagedPalletsImagesRowProps) => (
   <TableRow className={styles.lostDamagedPalletsImagesRow}>
      <TableCell className={styles.lostDamagedPalletsImagesCell}>
         <ImagesPreview imageURLs={imageURLs} onDeleteImage={onClickDeleteImage} />
      </TableCell>
   </TableRow>
);

export default LostDamagedPalletsImagesRow;
