import { useEffect, useMemo, useState } from 'react';

import { LoadingOverlay } from '@mantine/core';
import { AxiosError } from 'axios';
import { isEqual } from 'lodash';

import { ADD_OR_EDIT_CLIENT_PAGE_BANNERS } from 'constants/banners';
import { ADD_OR_EDIT_CLIENT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import {
   BannerData,
   ClientDataFormProps,
   CommercialNetworkDetailsDTO,
   CommercialNetworkItem,
   CommonRatePerPalletConfigFormData,
   DelayChargePerPalletConfigForm,
   DistributionCenterDTO,
   Option,
   PalletsPricingPerAddressFormData,
   PalletsPricingPerAddressFormDataItem,
   PalletTypesFormProps,
   RateConfigForm,
   RetentionPalletCostConfigForm,
} from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { getCommercialNetworkOptions, getFullAddress } from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';
import { logNetworkError } from 'utils/logNetworkError';

import FormStepsHeader from './FormStepsHeader/FormStepsHeader';
import styles from './ClientForm.module.css';
import {
   ClientDataForm,
   CommercialNetworksForm,
   PalletTypesForm,
   RatesConfigurationForm,
} from './ClientFormSteps';

type CheckAvailablePalletTypesField =
   | CommonRatePerPalletConfigFormData[]
   | PalletsPricingPerAddressFormDataItem[]
   | DelayChargePerPalletConfigForm[]
   | RetentionPalletCostConfigForm[];

type ClientFormProps = {
   clientDataFormInitialValues: ClientDataFormProps;
   palletTypesFormInitialValues: PalletTypesFormProps;
   ratesConfigurationFormInitialValues: RateConfigForm;
   commercialNetworksFormInitialValues: CommercialNetworkItem[];
   onSubmit: (
      clientData: ClientDataFormProps,
      palletTypes: PalletTypesFormProps,
      ratesConfiguration: RateConfigForm,
      commercialNetworksData: CommercialNetworkItem[],
      onFinish: () => void,
      onError: (error: unknown) => void,
   ) => void;
};

const ClientForm = ({
   clientDataFormInitialValues,
   palletTypesFormInitialValues,
   ratesConfigurationFormInitialValues,
   commercialNetworksFormInitialValues,
   onSubmit,
}: ClientFormProps) => {
   const [clientDataForm, setClientDataForm] = useState(clientDataFormInitialValues);
   const [palletTypesForm, setPalletTypesForm] = useState(palletTypesFormInitialValues);
   const [ratesConfigurationForm, setRatesConfigurationForm] = useState(
      ratesConfigurationFormInitialValues,
   );
   const [commercialNetworksForm, setCommercialNetworksForm] = useState(
      commercialNetworksFormInitialValues,
   );
   const [commercialNetworkNameOptions, setCommercialNetworkNameOptions] = useState<Option[]>([]);
   const [commercialNetworkAddressOptions, setCommercialNetworkAddressOptions] = useState<{
      [key: string]: Option[];
   }>({});
   const [isLoading, setIsLoading] = useState(true);
   const [fetchDataError, setFetchDataError] = useState(false);
   const [banner, setBanner] = useState<BannerData | null>(null);
   const [currentStep, setCurrentStep] = useState(0);

   const initialPalletRatesData = useMemo(() => {
      const commonRatePerPalletConfigFormInitialData: CommonRatePerPalletConfigFormData[] =
         palletTypesForm.availablePalletTypes.map(palletType => ({
            palletType: palletType,
            personalPickUpPrice: '',
            damagePrice: '',
            lostPrice: '',
            wrongReceiver: '',
         }));
      const palletsPricingPerAddressFormInitialData: PalletsPricingPerAddressFormData[] =
         clientDataForm.deliveryAddresses.map(deliveryAddress => {
            const palletsPricing: PalletsPricingPerAddressFormDataItem[] =
               palletTypesForm.availablePalletTypes.map(palletType => ({
                  palletType: palletType,
                  deliveryPrice: '',
               }));
            return {
               addressName: deliveryAddress.name,
               palletsPricing,
            };
         });
      return {
         common: commonRatePerPalletConfigFormInitialData,
         perAddress: palletsPricingPerAddressFormInitialData,
      };
   }, [clientDataForm.deliveryAddresses, palletTypesForm.availablePalletTypes]);

   const initialRetentionPalletCosts = useMemo(
      () =>
         palletTypesForm.availablePalletTypes.map(palletType => ({
            palletType: palletType,
            price: '',
         })),
      [palletTypesForm.availablePalletTypes],
   );

   const initialDelayChargePerPallet = useMemo(
      () =>
         palletTypesForm.availablePalletTypes.map(palletType => ({
            palletType: palletType,
            upTo21Days: '',
            upTo30Days: '',
            over30Days: '',
         })),
      [palletTypesForm.availablePalletTypes],
   );

   useEffect(() => {
      setIsLoading(true);
      HTTPService.getCommercialNetworks(undefined, { size: PAGINATION_MAX_SIZE })
         .then(({ data: responseData }) => {
            const commercialNetworksData = responseData.content;
            const { nameOptions, addressOptions } = getCommercialNetworkOptions(
               commercialNetworksData,
               (commercialNetwork: CommercialNetworkDetailsDTO) => commercialNetwork.id,
               (address: DistributionCenterDTO) => address.id,
            );
            setCommercialNetworkNameOptions(nameOptions);
            setCommercialNetworkAddressOptions(addressOptions);
         })
         .catch(error => {
            setFetchDataError(true);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   }, []);

   const checkDeliveryAddressesChange = () => {
      return isEqual(
         clientDataForm.deliveryAddresses,
         clientDataFormInitialValues.deliveryAddresses,
      );
   };

   const checkAvailablePalletTypesChange = (field: CheckAvailablePalletTypesField) => {
      return palletTypesForm.availablePalletTypes.every(
         palletType =>
            field.some(fieldValue => palletType === fieldValue.palletType) &&
            palletTypesForm.availablePalletTypes.length === field.length,
      );
   };

   const handleSubmitLastStep = (values: CommercialNetworkItem[]) => {
      setCommercialNetworksForm(values);
      setIsLoading(true);
      onSubmit(
         clientDataForm,
         palletTypesForm,
         ratesConfigurationForm,
         values,
         () => setIsLoading(false),
         handleAddClientError,
      );
   };

   const handleAddClientError = (error: unknown) => {
      const isAxiosErrorDescriptionExists =
         error instanceof AxiosError &&
         error.response?.data?.code &&
         ADD_OR_EDIT_CLIENT_ERROR_DESCRIPTIONS[error.response.data.code];
      setBanner({
         variant: 'error',
         description: isAxiosErrorDescriptionExists
            ? ADD_OR_EDIT_CLIENT_ERROR_DESCRIPTIONS[error.response?.data.code]
            : getUnknownClientCreationOrEditionError(),
      });
   };

   const getUnknownClientCreationOrEditionError = () =>
      clientDataFormInitialValues.id
         ? 'Podczas edycji klienta wystąpił błąd. Spróbuj ponownie.'
         : 'Podczas dodania klienta wystąpił błąd. Spróbuj ponownie.';

   const handleAddCommercialNetwork = (addedCommercialNetwork: CommercialNetworkDetailsDTO) => {
      const {
         id: commercialNetworkId,
         name: commercialNetworkName,
         addresses: commercialNetworkAddresses,
      } = addedCommercialNetwork;
      const distributionCenter = commercialNetworkAddresses[0];
      const {
         street: distributionCenterStreet,
         zipCode: distributionCenterZipCode,
         city: distributionCenterCity,
      } = distributionCenter.address;
      const addressLabel = getFullAddress(
         distributionCenterStreet,
         distributionCenterZipCode,
         distributionCenterCity,
      );
      const nameOption = { value: commercialNetworkId, label: commercialNetworkName };
      const addressOption: Option = {
         value: distributionCenter.id,
         label: addressLabel,
      };
      setCommercialNetworkNameOptions(prevOptions => {
         const newNameOptions = [...prevOptions, nameOption];
         return sortOptions(newNameOptions);
      });
      setCommercialNetworkAddressOptions(prevOption => ({
         ...prevOption,
         [commercialNetworkId]: [addressOption],
      }));
      setBanner(ADD_OR_EDIT_CLIENT_PAGE_BANNERS.ADD_COMMERCIAL_NETWORK_SUCCESS);
   };

   const handleAddDistributionCenter = (
      selectedCommercialNetworkId: string,
      addedDistributionCenter: DistributionCenterDTO,
   ) => {
      const { street, zipCode, city } = addedDistributionCenter.address;
      const addressLabel = getFullAddress(street, zipCode, city);
      const addressOption: Option = {
         value: addedDistributionCenter.id,
         label: addressLabel,
      };
      setCommercialNetworkAddressOptions(prevOption => {
         const newAddressOptions = [...prevOption[selectedCommercialNetworkId], addressOption];
         const sortedAddressOptions = sortOptions(newAddressOptions);
         return {
            ...prevOption,
            [selectedCommercialNetworkId]: sortedAddressOptions,
         };
      });
      setBanner(ADD_OR_EDIT_CLIENT_PAGE_BANNERS.ADD_DISTRIBUTION_CENTER_SUCCESS);
   };

   const renderForm = () => {
      switch (currentStep) {
         case 0:
            return (
               <ClientDataForm
                  initialState={clientDataForm}
                  setCurrentStep={setCurrentStep}
                  onSubmit={setClientDataForm}
               />
            );
         case 1:
            return (
               <PalletTypesForm
                  initialState={palletTypesForm}
                  setCurrentStep={setCurrentStep}
                  onSubmit={setPalletTypesForm}
               />
            );
         case 2:
            return (
               <RatesConfigurationForm
                  initialState={{
                     palletRates: {
                        common: checkAvailablePalletTypesChange(
                           ratesConfigurationForm.palletRates.common,
                        )
                           ? ratesConfigurationForm.palletRates.common
                           : initialPalletRatesData.common,
                        perAddress:
                           checkAvailablePalletTypesChange(
                              ratesConfigurationForm.palletRates.common,
                           ) && checkDeliveryAddressesChange()
                              ? ratesConfigurationForm.palletRates.perAddress
                              : initialPalletRatesData.perAddress,
                     },
                     retentionConfig: {
                        ...ratesConfigurationForm.retentionConfig,
                        retentionPalletCosts: checkAvailablePalletTypesChange(
                           ratesConfigurationForm.retentionConfig.retentionPalletCosts,
                        )
                           ? ratesConfigurationForm.retentionConfig.retentionPalletCosts
                           : initialRetentionPalletCosts,
                     },
                     delaysCharges: checkAvailablePalletTypesChange(
                        ratesConfigurationForm.delaysCharges,
                     )
                        ? ratesConfigurationForm.delaysCharges
                        : initialDelayChargePerPallet,
                  }}
                  setCurrentStep={setCurrentStep}
                  onSubmit={setRatesConfigurationForm}
               />
            );
         case 3:
            return (
               <CommercialNetworksForm
                  setCurrentStep={setCurrentStep}
                  initialState={commercialNetworksForm}
                  commercialNetworkAddressOptions={commercialNetworkAddressOptions}
                  commercialNetworkNameOptions={commercialNetworkNameOptions}
                  onSubmit={handleSubmitLastStep}
                  onAddCommercialNetwork={handleAddCommercialNetwork}
                  onAddDistributionCenter={handleAddDistributionCenter}
               />
            );
         default:
            return (
               <ClientDataForm
                  setCurrentStep={setCurrentStep}
                  initialState={clientDataForm}
                  onSubmit={setClientDataForm}
               />
            );
      }
   };
   return (
      <div className={styles.formContainer}>
         {(fetchDataError || banner) && (
            <div className={styles.bannersContainer}>
               {fetchDataError && (
                  <Banner
                     variant="error"
                     className={styles.banner}
                     children="Podczas pobierania danych wystąpił błąd. Spróbuj ponownie."
                     fullWidth
                  />
               )}
               {banner && (
                  <Banner
                     variant={banner.variant}
                     className={styles.banner}
                     children={banner.description}
                     fullWidth
                     withCloseIcon
                     onClose={() => setBanner(null)}
                  />
               )}
            </div>
         )}
         <FormStepsHeader currentStep={currentStep} />
         {!isLoading && renderForm()}
         <LoadingOverlay
            visible={!!isLoading}
            overlayBlur={10}
            radius="md"
            loaderProps={{ color: 'var(--light-green)' }}
         />
      </div>
   );
};

export default ClientForm;
