import truckImg from 'assets/images/truck.jpg';
import LoginForm from 'components/Auth/LoginForm/LoginForm';

import styles from './LoginPage.module.css';

const LoginPage = () => (
   <div className={styles.container}>
      <div className={styles.containerLeft}>
         <div className={styles.innerLeftContainer}>
            <LoginForm />
            <p>2022 Software Partner. All rights reserved.</p>
         </div>
      </div>
      <div style={{ backgroundImage: `url(${truckImg})` }} className={styles.containerRight}>
         <div className={styles.imageOverlay} />
      </div>
   </div>
);

export default LoginPage;
