import { CustomModal } from 'components/shared';

import styles from './NoSelectedStorageAlertModal.module.css';

type NoSelectedStorageAlertModalProps = {
   opened: boolean;
   isPersonalPickUpAddress?: boolean;
   onClose: () => void;
};

const NoSelectedStorageAlertModal = ({
   opened,
   isPersonalPickUpAddress,
   onClose,
}: NoSelectedStorageAlertModalProps) => (
   <CustomModal
      opened={opened}
      onClose={onClose}
      innerTitle={isPersonalPickUpAddress ? 'Brak adresu odbioru!' : 'Nie wybrano magazynu!'}
      icon="ALERT_TRIANGLE"
      secondaryButtonProps={{
         text: 'Zamknij',
         variant: 'outlined-danger',
         onClick: onClose,
      }}
      modalContentClassName={styles.modalContent}
   >
      {isPersonalPickUpAddress ? (
         <p className={styles.modalText}>
            Klient wybrał opcję „Odbiór osobisty”.
            <br />
            Przed zaakceptowaniem zamówienia musisz dodać adres odbioru palet.
         </p>
      ) : (
         <p className={styles.modalText}>
            Została wybrana opcja „Dostawa pod adres”.
            <br />
            Przed zaakceptowaniem zamówienia musisz dodać magazyn, z którego zostaną odebrane
            palety.
         </p>
      )}
   </CustomModal>
);

export default NoSelectedStorageAlertModal;
