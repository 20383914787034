import React, {
   forwardRef,
   ReactNode,
   useCallback,
   useImperativeHandle,
   useMemo,
   useState,
} from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import { useDropdown } from 'hooks/useDropdown';
import { Option } from 'interfaces';
import { TextInput } from 'storybook';
import { ChevronDownIcon } from 'storybook/assets';

import styles from './Select.module.css';

export interface SelectProps {
   value?: Option;
   placeholder?: string;
   label?: string;
   options?: Option[];
   onChange: (value: Option) => void;
   withFilter?: boolean;
   icon?: ReactNode;
   helperText?: string;
   dropdownBottomSection?: ReactNode;
   error?: boolean;
   disabled?: boolean;
   filterProps?: {
      type?: 'text' | 'number';
      placeholder?: string;
      icon?: ReactNode;
   };
   portalTarget?: HTMLElement;
   classNames?: {
      container?: string;
      select?: string;
      optionsContainer?: string;
      filterInput?: string;
   };
   width?: string;
   fullWidth?: boolean;
   groups?: { title: string; options: Option[] }[];
   transformSelectedOptionLabel?: (value: Option) => string;
}

export const Select = forwardRef(
   (
      {
         value,
         placeholder,
         label,
         options,
         icon,
         onChange,
         withFilter,
         helperText,
         dropdownBottomSection,
         error,
         disabled,
         filterProps,
         portalTarget,
         classNames: selectClassNames,
         width,
         fullWidth,
         groups,
         transformSelectedOptionLabel,
      }: SelectProps,
      ref,
   ) => {
      const [enteredText, setEnteredText] = useState('');

      const handleClose = useCallback(() => setEnteredText(''), []);

      const {
         expanded,
         open,
         close,
         toggleRef: selectRef,
         dropdownContentRefFunc,
         dropdownContentPosition,
         dropdownContentMinWidth,
      } = useDropdown({
         portalTarget,
         onClose: handleClose,
      });

      useImperativeHandle(ref, () => ({
         closeDropdown: close,
      }));

      const handleOptionClick = (option: Option) => {
         onChange(option);
         close();
      };

      const handleSelectExtend = () => {
         if (disabled) return;
         open();
      };

      const selectOptions = useMemo(() => {
         if (withFilter && options) {
            return options.filter(option =>
               option.label.toLowerCase().trim().includes(enteredText.toLowerCase().trim()),
            );
         } else {
            return options;
         }
      }, [withFilter, options, enteredText]);

      const selectedLabel =
         value &&
         (transformSelectedOptionLabel ? transformSelectedOptionLabel(value) : value.label);

      const optionsContent = (
         <div
            ref={dropdownContentRefFunc}
            style={{
               width: width,
               minWidth: dropdownContentMinWidth,
               ...dropdownContentPosition,
            }}
            className={classNames(
               styles.options,
               {
                  [styles.expanded]: expanded,
                  [styles.withPortal]: portalTarget,
                  [styles.withLabel]: label,
               },
               selectClassNames?.optionsContainer,
            )}
         >
            {withFilter && (
               <TextInput
                  type={filterProps?.type || 'text'}
                  value={enteredText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                     setEnteredText(e.target.value)
                  }
                  placeholder={filterProps?.placeholder}
                  leftIcon={filterProps?.icon}
                  className={classNames(styles.filterInput, selectClassNames?.filterInput)}
               />
            )}
            {!!selectOptions?.length || !!groups?.length ? (
               <>
                  {selectOptions?.map((option, index) => (
                     <div
                        key={index}
                        className={classNames(styles.option, {
                           [styles.selected]: option.value === value?.value,
                        })}
                        onClick={() => handleOptionClick(option)}
                     >
                        <span>{option.label}</span>
                     </div>
                  ))}
                  {groups?.map((group, index) => (
                     <div key={index} className={styles.group}>
                        <span>{group.title}</span>
                        <div>
                           {group.options.map((option, optionIndex) => (
                              <div
                                 key={optionIndex}
                                 className={classNames(styles.option, {
                                    [styles.selected]: option.value === value?.value,
                                 })}
                                 onClick={() => handleOptionClick(option)}
                              >
                                 <span>{option.label}</span>
                              </div>
                           ))}
                        </div>
                     </div>
                  ))}
               </>
            ) : (
               <div className={styles.noOptionsMessage}>
                  <span>Brak opcji</span>
               </div>
            )}
            {dropdownBottomSection && (
               <div className={styles.dropdownBottomSection}>{dropdownBottomSection}</div>
            )}
         </div>
      );

      return (
         <div
            className={classNames(
               styles.selectContainer,
               { [styles.fullWidth]: fullWidth },
               selectClassNames?.container,
            )}
         >
            {label && <p className={styles.selectLabel}>{label}</p>}
            <div
               ref={selectRef}
               style={{
                  width: width,
               }}
               onClick={handleSelectExtend}
               className={classNames(
                  styles.select,
                  {
                     [styles.error]: error,
                     [styles.disabled]: disabled,
                     [styles.expanded]: expanded,
                  },
                  selectClassNames?.select,
               )}
            >
               <div className={styles.selectLeftContent}>
                  {icon}
                  <span>{selectedLabel || placeholder}</span>
               </div>
               <ChevronDownIcon />
            </div>
            {expanded &&
               (portalTarget ? createPortal(optionsContent, portalTarget) : optionsContent)}
            {helperText && (
               <p
                  className={classNames(styles.helperText, {
                     [styles.error]: error,
                  })}
               >
                  {helperText}
               </p>
            )}
         </div>
      );
   },
);
