import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Eye } from 'tabler-icons-react';

import { StyledBadge, StyledButton, ToolTip } from 'components/shared';
import { EMPTY_VALUE_LABEL } from 'constants/commonLabels';
import { ORDER_STATUSES_BADGE_DATA } from 'constants/orderStatuses';
import { CLIENT_ORDER_DETAILS_PATH, SUPPLIER_ORDERS_DETAILS } from 'constants/routes';
import { supplierOrdersPageTabsWithNewOrderTag } from 'constants/supplierOrdersPageTabsWithNewOrderTag';
import { ClientOrdersTableItem, SupplierOrdersPageTab, SupplierOrdersTableItem } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import RetentionDateInfo from './RetentionDateInfo/RetentionDateInfo';
import styles from './OrderTableRow.module.css';

type OrderTableRowProps = SupplierOrderTableRowProps | ClientOrderTableRowProps;

type SupplierOrderTableRowProps = CommonOrderTableRowProps & {
   mode: 'SUPPLIER_TABLE';
   order: SupplierOrdersTableItem;
   activeTabName: SupplierOrdersPageTab;
};

type ClientOrderTableRowProps = CommonOrderTableRowProps & {
   mode: 'CLIENT_TABLE';
   order: ClientOrdersTableItem;
   activeTabName?: null;
};

type CommonOrderTableRowProps = {
   isTagColumnVisible: boolean;
};

const OrderTableRow = ({ mode, order, activeTabName, isTagColumnVisible }: OrderTableRowProps) => {
   const isVisibleNewOrderTag =
      mode === 'SUPPLIER_TABLE' &&
      supplierOrdersPageTabsWithNewOrderTag.includes(activeTabName) &&
      !order.read;

   return (
      <TableRow
         className={classNames(styles.tableRow, {
            [styles.newOrder]: isVisibleNewOrderTag,
            [styles.blockedClientOrder]: mode === 'SUPPLIER_TABLE' && order.blockedClient,
         })}
      >
         {isTagColumnVisible && (
            <TableCell
               className={classNames(styles.tableCell, {
                  [styles.severalTags]: isVisibleNewOrderTag && order.free,
               })}
            >
               {isVisibleNewOrderTag && <StyledBadge variant="NEW" text="nowe" />}
               {order.free && <StyledBadge variant="FREE" text="bezpłatne" />}
            </TableCell>
         )}
         <TableCell className={styles.tableCell}>#{order.id}</TableCell>
         {mode === 'CLIENT_TABLE' && (
            <TableCell className={styles.tableCell}>
               {order.internalOrderId || EMPTY_VALUE_LABEL}
            </TableCell>
         )}
         {mode === 'SUPPLIER_TABLE' && (
            <TableCell className={styles.tableCell}>{order.clientName}</TableCell>
         )}
         <TableCell className={styles.tableCell}>{order.createdDate}</TableCell>
         <TableCell className={styles.tableCell}>{order.deliveryDate}</TableCell>
         <TableCell className={styles.tableCell}>
            <RetentionDateInfo retentionDate={order.retentionDate} orderStatus={order.status} />
         </TableCell>
         <TableCell className={styles.tableCell}>
            <StyledBadge
               text={ORDER_STATUSES_BADGE_DATA[order.status].label}
               variant={ORDER_STATUSES_BADGE_DATA[order.status].variant}
            />
         </TableCell>
         <TableCell align="right" className={styles.tableCell}>
            {order.palletsAmount}
            <ToolTip content="Bezpłatne palety doliczone do zamówienia" variant="top" visible>
               {!!order.freePalletsAmount && (
                  <span
                     className={classNames(styles.freePalletsAmount, {
                        [styles.green]: mode === 'CLIENT_TABLE',
                     })}
                  >{` + ${order.freePalletsAmount}`}</span>
               )}
            </ToolTip>
         </TableCell>
         <TableCell className={styles.tableCell}>
            <Link
               to={
                  mode === 'SUPPLIER_TABLE'
                     ? SUPPLIER_ORDERS_DETAILS(order.id)
                     : CLIENT_ORDER_DETAILS_PATH(order.id)
               }
            >
               <StyledButton
                  type="button"
                  text="Szczegóły"
                  icon={<Eye />}
                  variant="text"
                  className={styles.actionBtn}
               />
            </Link>
         </TableCell>
      </TableRow>
   );
};

export default OrderTableRow;
