import { Trash } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledRadioButton from 'components/shared/StyledRadioButton/StyledRadioButton';
import StyledSelect from 'components/shared/StyledSelect/StyledSelect';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { LostDamagedPalletsItem, Option } from 'interfaces';
import { ButtonGroup } from 'storybook';

import styles from './LostDamagedPalletsItemCard.module.css';

type LostDamagedPalletsItemCardProps = {
   index: number;
   item: LostDamagedPalletsItem;
   errors: Record<string, string | boolean>;
   touched: Record<string, boolean>;
   availablePalletTypes: Option[];
   items: LostDamagedPalletsItem[];
   onRemoveItem: (itemIndex: number) => void;
   onChangeItem: (itemIndex: number, field: string, value: string | string[] | Option) => void;
};

const LostDamagedPalletsItemCard = ({
   index,
   item,
   errors,
   touched,
   availablePalletTypes,
   items,
   onRemoveItem,
   onChangeItem,
}: LostDamagedPalletsItemCardProps) => (
   <div className={styles.container}>
      {index !== 0 && (
         <StyledButton
            type="button"
            onClick={() => onRemoveItem(index)}
            icon={<Trash />}
            variant="text"
            className={styles.removeBtn}
         />
      )}
      <StyledSelect
         options={availablePalletTypes}
         onChange={(value: Option) => onChangeItem(index, 'palletType', value)}
         value={item.palletType}
         error={touched.palletType && !!errors.palletType}
         label="Typ palety"
         fullWidth
         mobileView
         classNames={{ container: styles.selectPalletType }}
      />
      <ButtonGroup
         label="Rodzaj uszkodzenia"
         containerClassName={styles.damageTypeBtnGroupContainer}
      >
         <StyledRadioButton
            label="Zgubienie lub zniszczenie"
            checked={item.damageType === 'LOST_OR_DESTRUCTION'}
            onChangeHandler={() => onChangeItem(index, 'damageType', 'LOST_OR_DESTRUCTION')}
            disabled={items.some(
               damagePalletsItem =>
                  damagePalletsItem.palletType === item.palletType &&
                  damagePalletsItem.damageType === 'LOST_OR_DESTRUCTION',
            )}
            error={touched.damageType && !!errors.damageType}
            buttonView
         />
         <StyledRadioButton
            label="Uszkodzenie"
            checked={item.damageType === 'DAMAGE'}
            onChangeHandler={() => onChangeItem(index, 'damageType', 'DAMAGE')}
            disabled={items.some(
               damagePalletsItem =>
                  damagePalletsItem.palletType === item.palletType &&
                  damagePalletsItem.damageType === 'DAMAGE',
            )}
            error={touched.damageType && !!errors.damageType}
            buttonView
         />
      </ButtonGroup>
      <StyledTextInput
         type="number"
         label="Ilość palet"
         value={item.palletsAmount}
         onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeItem(index, 'palletsAmount', event.target.value)
         }
         error={touched.palletsAmount && !!errors.palletsAmount}
         fullWidth
      />
   </div>
);

export default LostDamagedPalletsItemCard;
