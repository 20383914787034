import {
   ClientOrderSummaryPallet,
   OrderDetailsDTO,
   OrderDetailsPallet,
   OrderSummaryAndOrderDetailsPallet,
   OrderSummaryAndOrderDetailsPallets,
   RegularOrderDetails,
   SupplierOrderSummaryPallet,
} from 'interfaces';

export const isOrderDetailsPallet = (
   pallet: OrderSummaryAndOrderDetailsPallet,
): pallet is OrderDetailsPallet =>
   (pallet as SupplierOrderSummaryPallet | ClientOrderSummaryPallet).orderType === undefined;

export const isClientOrderSummaryPallet = (
   pallet: OrderSummaryAndOrderDetailsPallet,
): pallet is ClientOrderSummaryPallet =>
   (pallet as SupplierOrderSummaryPallet | OrderDetailsPallet).pricePerUnit === undefined;

export const isRegularOrderDetails = (order: OrderDetailsDTO): order is RegularOrderDetails =>
   !!order.palletDeliveryDate &&
   !!order.retentionDate &&
   !!order.remainingPalletsToBeSend &&
   !order.deliveryMethod?.addedToNextOrder;

export const isOrderDetailsPalletArray = (
   pallets: OrderSummaryAndOrderDetailsPallets,
): pallets is OrderDetailsPallet[] => isOrderDetailsPallet(pallets[0]);
