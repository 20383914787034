import { SelfPickupIcon } from 'assets';
import { SectionContainer, StyledButton } from 'components/shared';
import ToolTip from 'components/shared/ToolTip/ToolTip';
import { OrderStorageDTO } from 'interfaces';
import { Card } from 'storybook';

import styles from './OverviewStorageSection.module.css';

type OverviewStorageSectionProps = {
   storage: OrderStorageDTO | null;
   onClickEdit?: () => void;
   disabledEditAction?: boolean;
};

const OverviewStorageSection = ({
   storage,
   onClickEdit,
   disabledEditAction,
}: OverviewStorageSectionProps) => {
   return (
      <SectionContainer title="Dostarczone z magazynu">
         {onClickEdit && (
            <ToolTip
               content="Zakończ edycję dostawy, aby edytować magazyn"
               visible={disabledEditAction}
               variant="right"
            >
               <StyledButton
                  text="Edytuj magazyn"
                  variant="outlined-primary"
                  onClick={onClickEdit}
                  disabled={disabledEditAction}
               />
            </ToolTip>
         )}
         <Card
            bottomContent={
               storage?.hasPalletsFromStorage ? (
                  <p className={styles.cardAddress}>
                     {storage.address.street}, {storage.address.zipCode} {storage.address.city}{' '}
                     (Magazyn
                     {storage.type === 'EXTERNAL' ? ' zewnętrzny' : ' wewnętrzny'})
                  </p>
               ) : (
                  <p className={styles.noStorageText}>Nie zdefiniowano magazynu</p>
               )
            }
            className={styles.card}
            icon={<SelfPickupIcon />}
            title="Magazyn"
         />
      </SectionContainer>
   );
};

export default OverviewStorageSection;
