import classNames from 'classnames';

import { SharedGridTableElementProps } from 'interfaces';

import styles from './SharedGridTableRow.module.css';

const SharedGridTableRow = ({ children, className, onClick }: SharedGridTableElementProps) => (
   <div className={classNames(styles.row, className)} onClick={onClick}>
      {children}
   </div>
);
export default SharedGridTableRow;
