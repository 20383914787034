import { SectionContainer } from 'components/shared';
import { ClientUserDTO } from 'interfaces';

import styles from './SelectedClientSection.module.css';

type SelectedClientSectionProps = {
   selectedClient: ClientUserDTO | null;
};

const SelectedClientSection = ({ selectedClient }: SelectedClientSectionProps) => (
   <SectionContainer title="Klient">
      <p className={styles.userInfoText}>
         {selectedClient &&
            `${selectedClient.companyName}, ${selectedClient.address}, ${selectedClient.zipCode} ${selectedClient.city}`}
      </p>
   </SectionContainer>
);

export default SelectedClientSection;
