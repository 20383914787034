import { sendPalletsExcelRowToExcelPalletsItemMapper } from '../mappers';
import { SendPalletsExcelRow } from '../types';

export const getExcelSendPalletsItemsFromSendPalletsExcelRows = (
   sendPalletsExcelRowsData: SendPalletsExcelRow[],
) => {
   const excelPalletsItems = sendPalletsExcelRowsData.map(
      sendPalletsExcelRowToExcelPalletsItemMapper,
   );
   return {
      ...sendPalletsExcelRowsData[0],
      pallets: excelPalletsItems,
   };
};
