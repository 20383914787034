import { MouseEvent as ReactMouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import { CheckGreenIcon } from 'storybook/assets';

import styles from './Card.module.css';

export interface CardProps {
   size?: 'sm';
   icon?: ReactNode;
   title: string;
   bottomContent?: ReactNode;
   onClick?: (event: ReactMouseEvent) => void;
   className?: string;
   checked?: boolean;
   disabled?: boolean;
   displayCheckedIcon?: boolean;
}

export const Card = ({
   size,
   icon,
   title,
   bottomContent,
   checked,
   className,
   onClick,
   disabled,
   displayCheckedIcon,
}: CardProps) => {
   return (
      <div
         {...(!disabled ? { onClick } : {})}
         className={classNames(styles.card, className, {
            [styles.checked]: checked,
            [styles.small]: size === 'sm',
            [styles.disabled]: disabled,
         })}
      >
         {checked && displayCheckedIcon && (
            <div className={styles.checkContainer}>
               <CheckGreenIcon />
            </div>
         )}
         {icon}
         <h2 className={styles.title}>{title}</h2>
         {bottomContent}
      </div>
   );
};
