import { SectionContainer } from 'components/shared';
import { ClientUserDTO } from 'interfaces';

import styles from './ClientSection.module.css';

type ClientUserData = Pick<
   ClientUserDTO,
   'nip' | 'companyName' | 'address' | 'zipCode' | 'city' | 'phoneNumber' | 'email'
>;

const ClientSection = (client: ClientUserData) => (
   <SectionContainer title="Klient">
      <div className={styles.container}>
         <div className={styles.column}>
            <p>Dane podstawowe</p>
            <div className={styles.infoBlock}>
               <p>NIP</p>
               <p>{client.nip}</p>
            </div>
            <div className={styles.infoBlock}>
               <p>Nazwa firmy</p>
               <p>{client.companyName}</p>
            </div>
            <div className={styles.infoBlock}>
               <p>Ulica, numer domu / numer mieszkania</p>
               <p>{client.address}</p>
            </div>
            <div className={styles.infoBlocksContainer}>
               <div className={styles.infoBlock}>
                  <p>Kod pocztowy</p>
                  <p>{client.zipCode}</p>
               </div>
               <div className={styles.infoBlock}>
                  <p>Miejscowość</p>
                  <p>{client.city}</p>
               </div>
            </div>
         </div>
         <div className={styles.column}>
            <p>Dane kontaktowe</p>
            <div className={styles.infoBlock}>
               <p>Telefon</p>
               <p>{client.phoneNumber}</p>
            </div>
            <div className={styles.infoBlock}>
               <p>E-mail kontaktowy</p>
               <p>{client.email}</p>
            </div>
         </div>
      </div>
   </SectionContainer>
);

export default ClientSection;
