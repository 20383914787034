import 'dayjs/locale/pl';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Check, X } from 'tabler-icons-react';

import { EditPalletsConfigurationSection } from 'components/Order';
import EditDeliverySection from 'components/Order/EditDeliverySection/EditDeliverySection';
import EditRentalPeriodSection from 'components/Order/EditRentalPeriodSection/EditRentalPeriodSection';
import { CustomSwitch } from 'components/shared';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import {
   SelectClientSection,
   SelectDeliveryTypeSection,
   SelectedClientSection,
} from 'components/SupplierPanel';
import EditStorageSection from 'components/SupplierPanel/Order/EditStorageSection/EditStorageSection';
import { DEFAULT_PALLET_CUSTOM_PRICES } from 'constants/defaultPalletCustomPrices';
import { ADDED_TO_NEXT_ORDER } from 'constants/deliveryTypes';
import { SELF_PICKUP } from 'constants/orderAddress';
import { palletTypesData } from 'constants/palletTypes';
import { useOrderForm } from 'hooks/useOrderForm';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import {
   ClientUserDTO,
   DeliveryType,
   PalletContractPrices,
   StorageDTO,
   SupplierPlaceOrderData,
} from 'interfaces';
import { getFullAddress, parseClientToCompanyInfoString } from 'utils/functions';
import { getPalletContractPrices, getPalletPrice } from 'utils/functions/palletPrices';
import { parseClientDeliveryAddressIntoDeliveryAddress } from 'utils/parsers';
import {
   placeAddedToNextOrderBySupplierFormValidations,
   placeRegularOrderBySupplierFormValidations,
} from 'utils/validation/orderFormValidations';

import ChangePalletsPricesModal from './ChangePalletsPricesModal/ChangePalletsPricesModal';
import styles from './PlaceOrderForm.module.css';

type PlaceOrderFormProps = {
   predefinedClientId?: string;
   initialState: SupplierPlaceOrderData;
   clients: ClientUserDTO[];
   storages: StorageDTO[];
   isOrderFree?: boolean;
   onChangeLogisticMinimumAchieved?: (logisticMinimumAchieved: boolean) => void;
   onSubmit: (values: SupplierPlaceOrderData) => void;
};

const PlaceOrderForm = ({
   predefinedClientId,
   initialState,
   clients,
   storages,
   isOrderFree,
   onChangeLogisticMinimumAchieved,
   onSubmit,
}: PlaceOrderFormProps) => {
   const [isFree, setIsFree] = useState(isOrderFree || !!initialState.deliveryType);
   const [palletContractPrices, setPalletContractPrices] = useState<PalletContractPrices | null>(
      initialState.client ? getPalletContractPrices(initialState.client) : null,
   );
   const [formValidations, setFormValidations] = useState(
      initialState.deliveryType !== 'ADDED_TO_NEXT_ORDER'
         ? placeRegularOrderBySupplierFormValidations
         : placeAddedToNextOrderBySupplierFormValidations,
   );
   const [selectedModal, setSelectedModal] = useState<'CHANGE_PALETS_PRICES_MODAL' | null>(null);
   const { isMobile } = useResponsiveLayout();

   const handleSubmitForm = (formValues: SupplierPlaceOrderData) => {
      const fullAddress =
         formValues.address && formValues.address !== SELF_PICKUP
            ? getFullAddress(
                 formValues.address.value.street,
                 formValues.address.value.zipCode,
                 formValues.address.value.city,
                 formValues.address.value.name,
              )
            : undefined;

      const palletsWithPrices = formValues.pallets.map(palletItem => {
         return {
            ...palletItem,
            pricePerUnit: palletContractPrices
               ? Number(
                    getPalletPrice(
                       palletItem.name,
                       palletContractPrices,
                       palletCustomPrices,
                       fullAddress,
                    ),
                 )
               : 0,
         };
      });
      formValues.pallets = palletsWithPrices;
      onSubmit({ ...formValues, palletCustomPrices });
   };

   const clientDeliveryAddresses = useMemo(
      () =>
         initialState.client?.deliveryAddresses.map(parseClientDeliveryAddressIntoDeliveryAddress),
      [initialState.client?.deliveryAddresses],
   );

   const {
      values,
      touched,
      errorsList,
      setValues,
      setTouched,
      handleSubmit,
      selectChangeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      handleChangeAddress,
      setAddresses,
      deliveryAddressOptions,
      storageOptions,
      holdingPeriod,
      setRetentionPeriod,
      palletCustomPrices,
      setPalletCustomPrices,
      handleChangePalletPrices,
   } = useOrderForm({
      initialState,
      storages,
      deliveryAddresses: clientDeliveryAddresses,
      retentionPeriodDays: initialState.client?.rateConfig?.retentionConfig.retentionPeriodDays,
      onSubmit: handleSubmitForm,
      onChangeLogisticMinimumAchieved,
      validations: formValidations,
   });

   const handleChangeClient = useCallback(
      (clientId: string) => {
         const selectedUser = clients.find(client => client.id === clientId);
         if (selectedUser) {
            selectChangeHandler(selectedUser, 'client');
            selectChangeHandler(null, 'address', !values.address);
            selectChangeHandler(null, 'storage', !values.storage);
            const selectedUserDeliveryAddresses = selectedUser.deliveryAddresses.map(
               parseClientDeliveryAddressIntoDeliveryAddress,
            );
            setAddresses(selectedUserDeliveryAddresses);
            setRetentionPeriod(selectedUser.rateConfig?.retentionConfig.retentionPeriodDays);
            const palletContractPricesData = getPalletContractPrices(selectedUser);
            setPalletContractPrices(palletContractPricesData);
            setPalletCustomPrices(DEFAULT_PALLET_CUSTOM_PRICES());
         }
      },
      [
         selectChangeHandler,
         setAddresses,
         setRetentionPeriod,
         values.address,
         values.storage,
         clients,
         setPalletCustomPrices,
      ],
   );

   useEffect(() => {
      if (predefinedClientId && clients && !values.client) {
         handleChangeClient(predefinedClientId);
      }
   }, [predefinedClientId, handleChangeClient, clients, values.client]);

   const handleCloseModal = useCallback(() => setSelectedModal(null), []);

   const handleToggleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      setIsFree(checked);
      if (!checked) {
         if (formValidations !== placeRegularOrderBySupplierFormValidations) {
            setFormValidations(placeRegularOrderBySupplierFormValidations);
         }
         setValues(prevValues => ({
            ...prevValues,
            deliveryType: null,
         }));
         setTouched(prevValues => ({
            ...prevValues,
            deliveryType: false,
         }));
      }
   };

   const handleDeliveryTypeChange = (deliveryType: DeliveryType) => {
      selectChangeHandler(deliveryType, 'deliveryType');
      if (deliveryType === 'SEPARATE_ORDER') {
         setFormValidations(placeRegularOrderBySupplierFormValidations);
      } else {
         setFormValidations(placeAddedToNextOrderBySupplierFormValidations);
         setValues(prevValues => ({
            ...prevValues,
            address: null,
            palletDeliveryDate: null,
            storage: null,
         }));
         setTouched(prevValues => ({
            ...prevValues,
            address: false,
            palletDeliveryDate: false,
            storage: false,
         }));
         setPalletCustomPrices(DEFAULT_PALLET_CUSTOM_PRICES());
      }
   };

   const clientOptions = useMemo(
      () =>
         clients.map(client => {
            const { id, companyName, zipCode, address, city } = client;
            return {
               value: id,
               label: companyName,
               zipCode: zipCode || '',
               address: address || '',
               city: city || '',
            };
         }),
      [clients],
   );

   const isNotSelectedDeliveryType = isFree && !values.deliveryType;
   const selectedFullAddress =
      values.address && values.address !== SELF_PICKUP
         ? getFullAddress(
              values.address.value.street,
              values.address.value.zipCode,
              values.address.value.city,
              values.address.value.name,
           )
         : undefined;

   return (
      <form name="placeOrder" onSubmit={handleSubmit} className={styles.orderForm}>
         <ChangePalletsPricesModal
            availablePalletTypes={values.client ? values.client.availablePalletTypes : []}
            address={selectedFullAddress}
            palletContractPrices={palletContractPrices}
            palletCustomPrices={palletCustomPrices}
            onSubmitChanges={handleChangePalletPrices}
            opened={selectedModal === 'CHANGE_PALETS_PRICES_MODAL'}
            onClose={handleCloseModal}
         />
         <CustomSwitch
            label="Zamówienie bezpłatne (wymiana uszkodzonych palet)"
            checked={isFree}
            disabled={isOrderFree}
            onChange={handleToggleSwitch}
            offLabel={<X size={16} color="white" />}
            onLabel={<Check size={16} color="white" />}
            style={{ margin: '0 0 48px 24px' }}
         />
         {isFree && (
            <SelectDeliveryTypeSection
               selectedDeliveryType={values.deliveryType}
               onDeliveryTypeChange={handleDeliveryTypeChange}
            />
         )}
         {!isNotSelectedDeliveryType && (
            <>
               {!predefinedClientId ? (
                  <SelectClientSection
                     selectedClient={
                        values.client ? parseClientToCompanyInfoString(values.client) : ''
                     }
                     onChangeClient={handleChangeClient}
                     clientOptions={clientOptions}
                     error={errorsList.client}
                     touched={touched.client}
                  />
               ) : (
                  <SelectedClientSection selectedClient={values.client} />
               )}

               {values.deliveryType !== ADDED_TO_NEXT_ORDER && (
                  <>
                     <EditDeliverySection
                        selectedAddress={values.address}
                        availableAddresses={deliveryAddressOptions}
                        selectedStorage={values.storage}
                        availableStorages={storageOptions}
                        onChangeAddress={handleChangeAddress}
                        disabledSelfPickup={!values.client}
                        error={errorsList.address}
                        touched={touched.address}
                        isMobile={isMobile}
                     />
                     <EditRentalPeriodSection
                        isSupplierMode
                        deliveryDate={values.palletDeliveryDate}
                        holdingPeriod={holdingPeriod}
                        retentionPeriodDays={
                           values.client
                              ? values.client.rateConfig.retentionConfig.retentionPeriodDays
                              : null
                        }
                        onSelectChange={selectChangeHandler}
                        error={errorsList.palletDeliveryDate}
                        touched={touched.palletDeliveryDate}
                     />
                  </>
               )}
               <EditPalletsConfigurationSection
                  pallets={values.pallets}
                  availablePalletTypes={values.client ? values.client.availablePalletTypes : []}
                  palletTypes={palletTypesData}
                  palletContractPrices={palletContractPrices}
                  palletCustomPrices={palletCustomPrices}
                  showPalletPrices
                  isOrderFree={isFree}
                  selectedAddress={values.address}
                  onPalletRadioChange={handlePalletRadioChange}
                  onPalletQuantityChange={palletQuantityChangeHandler}
                  onClearPallet={handleClearPallet}
                  onClickChangePalletsPrices={() => setSelectedModal('CHANGE_PALETS_PRICES_MODAL')}
                  errors={errorsList.pallets}
                  touched={touched.pallets}
               />
               {values.deliveryType !== ADDED_TO_NEXT_ORDER && (
                  <EditStorageSection
                     selectedStorage={values.storage}
                     availableStorages={storageOptions}
                     onSelectChange={selectChangeHandler}
                     disabled={values.address === SELF_PICKUP}
                     error={errorsList.storage}
                     touched={touched.storage}
                  />
               )}
            </>
         )}
         <StyledButton
            style={{ float: 'right', marginTop: 10 }}
            type="submit"
            variant="outlined-primary"
            text="Przejdź do podsumowania"
            className={styles.submitButton}
            disabled={isFree && !values.deliveryType}
         />
      </form>
   );
};

export default PlaceOrderForm;
