import { OrderDetails, OrderDetailsDTO } from 'interfaces';
import { isRegularOrderDetails } from 'utils/typeGuards';

export const getOrderDetailsData: (order: OrderDetailsDTO) => OrderDetails = (
   order: OrderDetailsDTO,
) => {
   const isRegularOrder = isRegularOrderDetails(order);
   if (isRegularOrder) {
      return {
         ...order,
         mode: 'REGULAR_ORDER',
         palletDeliveryDate: order.palletDeliveryDate,
         retentionDate: order.retentionDate,
         address: order.address,
         storage: order.storage,
         remainingPalletsToBeSend: order.remainingPalletsToBeSend,
      };
   }
   return {
      ...order,
      mode: 'ADDED_TO_NEXT_ORDER',
      palletDeliveryDate: null,
      retentionDate: null,
      address: null,
      storage: null,
      remainingPalletsToBeSend: null,
      internalOrderId: null,
   };
};
