import { StyledButton } from 'components/shared';

import styles from './FormStepsFooter.module.css';

type FormStepsFooterProps = {
   onBack: () => void;
   onSave?: () => void;
   backDisabled: boolean;
   lastStep?: boolean;
   disabledNextButton?: boolean;
};

const FormStepsFooter = ({
   onBack,
   backDisabled,
   lastStep,
   disabledNextButton,
}: FormStepsFooterProps) => {
   return (
      <div className={styles.footerBtnsContainer}>
         {!backDisabled && (
            <StyledButton type="button" variant="outlined-primary" text="Wstecz" onClick={onBack} />
         )}
         <StyledButton
            type="submit"
            variant="filled-primary"
            text={lastStep ? 'Dodaj klienta' : 'Dalej'}
            disabled={disabledNextButton}
         />
      </div>
   );
};
export default FormStepsFooter;
