import { useMemo } from 'react';

import { CustomModal, CustomModalProps } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { NO_SELECTED_ORDERS_ERROR } from 'constants/validationErrors';
import { useForm } from 'hooks';
import { useModal } from 'hooks/useModal';
import { AddAdditionalOrdersFormValues, AdditionalOrder } from 'interfaces';
import { HTTPService } from 'service';
import { countOrdersTotalPalletsAmount, findOrdersByIds, getPalletLabel } from 'utils/functions';
import { logNetworkError } from 'utils/logNetworkError';
import { addAdditionalOrdersFormValidation } from 'utils/validation/addAdditionalOrdersFormValidation';

import AdditionalOrdersList from './AdditionalOrdersList/AdditionalOrdersList';
import styles from './AddFreeOrdersToNextOrderModal.module.css';

type AddFreeOrdersToNextOrderModalProps = CustomModalProps & {
   availableAdditionalOrders: AdditionalOrder[];
   parentOrderId: string;
   onSuccess?: () => void;
};

const INITIAL_FORM_STATE: AddAdditionalOrdersFormValues = {
   selectedAdditionalOrderIds: [],
};

const AddFreeOrdersToNextOrderModal = ({
   availableAdditionalOrders,
   parentOrderId,
   onSuccess,
   onClose,
   ...props
}: AddFreeOrdersToNextOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleAddAdditionaOrders = () => {
      setIsLoading(true);
      HTTPService.mergeAdditionalOrders(parentOrderId, selectedAdditionalOrderIds)
         .then(() => (onSuccess ? onSuccess() : closeModalHandler()))
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION_SHORT);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   const { values, touched, formValid, multiSelectChangeHandler, submitHandler } = useForm({
      initialState: INITIAL_FORM_STATE,
      validations: addAdditionalOrdersFormValidation,
      onSubmit: handleAddAdditionaOrders,
   });

   const handleSelectAdditionalOrder = (orderId: string) =>
      multiSelectChangeHandler(orderId, 'selectedAdditionalOrderIds');

   const selectedAdditionalOrderIds = values.selectedAdditionalOrderIds;

   const selectedPalletsAmount = useMemo(() => {
      if (availableAdditionalOrders) {
         const foundSelectedAdditionalOrders = findOrdersByIds(
            availableAdditionalOrders,
            selectedAdditionalOrderIds as string[],
         );
         return countOrdersTotalPalletsAmount(foundSelectedAdditionalOrders);
      } else {
         return 0;
      }
   }, [selectedAdditionalOrderIds, availableAdditionalOrders]);

   const isFormInvalid = !formValid;
   const isAnyAdditionalOrderTouched = touched.selectedAdditionalOrderIds;
   const modalErrorMessage =
      isFormInvalid && isAnyAdditionalOrderTouched ? NO_SELECTED_ORDERS_ERROR : errorInfo;

   return (
      <CustomModal
         {...props}
         title="Dodatkowe palety"
         error={modalErrorMessage}
         modalContentClassName={styles.modalContent}
         leftSideModalContent
         isLoading={isLoading}
         onSubmit={submitHandler}
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: 'Dołącz do zamówienia',
            variant: 'filled-primary',
         }}
         secondaryButtonProps={{
            text: 'Pomiń',
            variant: 'text',
            onClick: closeModalHandler,
         }}
      >
         <>
            <p className={styles.description}>
               Wybierz, które bezpłatne palety mają zostać dołączone do tego zamówienia.
               <br />
               Jeśli pominiesz wybór, dołączanie palet wyświetli się w kolejnym zamówieniu.
            </p>
            {availableAdditionalOrders && (
               <AdditionalOrdersList
                  additionalOrders={availableAdditionalOrders}
                  selectedAdditionalOrderIds={selectedAdditionalOrderIds}
                  isAnyAdditionalOrderTouched={isAnyAdditionalOrderTouched}
                  isFormInvalid={isFormInvalid}
                  onSelectAdditionalOrder={handleSelectAdditionalOrder}
               />
            )}
            <div className={styles.selectedPalletsAmout}>
               <span>Suma wybranych:</span>
               <span>{`${selectedPalletsAmount} ${getPalletLabel(selectedPalletsAmount)}`}</span>
            </div>
         </>
      </CustomModal>
   );
};

export default AddFreeOrdersToNextOrderModal;
