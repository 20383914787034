import { BalancePickUpDTO, TransferPalletBalance } from 'interfaces';

export const parseBalancePickUpDTOIntoTransferPalletBalance: (
   balancePickUpDTO: BalancePickUpDTO,
) => TransferPalletBalance = balancePickUpDTO => {
   const { palletType, balance } = balancePickUpDTO;
   const { invalid, valid } = balance;

   return {
      palletName: palletType,
      validAvailablePallets: valid ? valid.toString() : '',
      invalidAvailablePallets: invalid ? invalid.toString() : '',
   };
};
