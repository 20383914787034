import { useCallback, useMemo } from 'react';

import { BannerList, BannerListItem, SharedGridTable } from 'components/shared';
import { BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX } from 'constants/basePalletsPricingPerAddressDataIndex';
import { palletsPricingPerAddressTableHeaders } from 'constants/tableHeaders';
import { PalletsPricingPerAddressFormData, PalletType } from 'interfaces';
import { formatStringToPrice, getUniqueErrorMessagesFromErrorsObjectList } from 'utils/functions';
import { isNumeric } from 'utils/validation';

import PalletsPricingPerAddressFormTableRow from './PalletsPricingPerAddressFormTableRow/PalletsPricingPerAddressFormTableRow';
import styles from './PalletsPricingPerAddress.module.css';

type PalletsPricingPerAddressProps = {
   palletsPricingPerAddresses: PalletsPricingPerAddressFormData[];
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   errors: any;
   touched: {
      [x: string]: boolean;
   };
   onChangePalletsPrice: (
      isBasePalletsPricing: boolean,
      enteredValue: string,
      palletType: PalletType,
      address: string,
   ) => void;
};

const PalletsPricingPerAddress = ({
   palletsPricingPerAddresses,
   errors,
   touched,
   onChangePalletsPrice,
}: PalletsPricingPerAddressProps) => {
   const handlePalletDeliveryPriceBlur = useCallback(
      (
         isBasePalletsPricing: boolean,
         enteredValue: string,
         palletType: PalletType,
         address: string,
      ) => {
         const isNumericValue = isNumeric(enteredValue);
         if (!isNumericValue || !enteredValue) {
            return;
         }
         const formattedValue = formatStringToPrice(enteredValue);
         onChangePalletsPrice(isBasePalletsPricing, formattedValue, palletType, address);
      },
      [onChangePalletsPrice],
   );

   const palletRatesErrorBannersData = useMemo(() => {
      if (!errors.palletRates?.perAddress || !touched.palletRates) {
         return null;
      }
      const errorMessages = (errors.palletRates.perAddress as { [p: string]: string }[][])
         .map(palletRatesPerAddressErrorsItem =>
            getUniqueErrorMessagesFromErrorsObjectList(palletRatesPerAddressErrorsItem),
         )
         .flat();
      const uniqueErrorMessages = Array.from(new Set(errorMessages));
      return uniqueErrorMessages.map<BannerListItem>(uniqueErrorMessage => ({
         key: uniqueErrorMessage,
         variant: 'error',
         children: uniqueErrorMessage,
         fullWidth: true,
      }));
   }, [errors.palletRates, touched.palletRates]);

   const rows = useMemo(
      () =>
         palletsPricingPerAddresses.map((palletsPricingPerAddressesItem, index) => (
            <PalletsPricingPerAddressFormTableRow
               key={index}
               palletsPricingPerAddress={palletsPricingPerAddressesItem}
               basePalletsPricingPerAddressData={
                  palletsPricingPerAddresses[BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX]
               }
               isBasePalletsPricingRows={index === BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX}
               error={
                  errors?.palletRates?.perAddress &&
                  touched.palletRates &&
                  errors?.palletRates.perAddress[index]
               }
               onPalletPricingChange={onChangePalletsPrice}
               onPalletPricingBlur={handlePalletDeliveryPriceBlur}
            />
         )),
      [
         errors?.palletRates?.perAddress,
         handlePalletDeliveryPriceBlur,
         onChangePalletsPrice,
         palletsPricingPerAddresses,
         touched.palletRates,
      ],
   );

   return (
      <>
         {palletRatesErrorBannersData && <BannerList bannersData={palletRatesErrorBannersData} />}
         <SharedGridTable
            headers={palletsPricingPerAddressTableHeaders}
            className={{ headerRow: styles.tableHeader }}
            rows={rows}
         />
      </>
   );
};

export default PalletsPricingPerAddress;
