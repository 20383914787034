import React from 'react';

import classNames from 'classnames';

import { CheckWhiteIcon } from 'storybook/assets';

import styles from './Step.module.css';

export interface StepProps {
   text: string;
   stepCount?: number;
   className?: string;
   active?: boolean;
   completed?: boolean;
}

export const Step = ({ text, stepCount, className, active, completed }: StepProps) => {
   return (
      <div
         className={classNames(styles.step, className, {
            [styles.active]: active || completed,
         })}
      >
         <div
            className={classNames(styles.counterContainer, {
               [styles.counterActive]: active,
               [styles.counterCompleted]: completed,
            })}
         >
            {completed ? <CheckWhiteIcon /> : stepCount}
         </div>
         <span>{text}</span>
      </div>
   );
};
