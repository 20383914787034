import { FocusEvent, useMemo } from 'react';

import {
   BannerList,
   BannerListItem,
   SharedGridTable,
   SharedGridTableCell,
   SharedGridTableRow,
   StyledTextInput,
} from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { DelayChargePerPalletConfigForm } from 'interfaces';
import { formatStringToPrice, getUniqueErrorMessagesFromErrorsObjectList } from 'utils/functions';
import { isNumeric } from 'utils/validation';

import styles from './DelaysCharges.module.css';

type DelaysChargesProps = {
   values: DelayChargePerPalletConfigForm[];
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   errors: any;
   touched: {
      [x: string]: boolean;
   };
   onChangeDelaysCharge: (
      enteredValue: string,
      palletType: string,
      field: DelaysChargesFields,
   ) => void;
};

export type DelaysChargesFields = 'upTo21Days' | 'upTo30Days' | 'over30Days';

export const rowsHeaders = ['Do 21 dni', 'Do 30 dni', 'Powyżej 30 dni'];

const DelaysCharges = ({ values, errors, touched, onChangeDelaysCharge }: DelaysChargesProps) => {
   const handleDelaysChargesChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      field: DelaysChargesFields,
   ) => {
      const {
         target: { name, value },
      } = event;
      onChangeDelaysCharge(value, name, field);
   };

   const handleDelaysChargesBlur = (
      event: FocusEvent<HTMLInputElement>,
      field: DelaysChargesFields,
   ) => {
      const {
         target: { name, value: enteredValue },
      } = event;
      const isNumericValue = isNumeric(enteredValue);
      if (!isNumericValue || enteredValue === '') {
         return;
      }
      const formattedValue = formatStringToPrice(enteredValue);
      onChangeDelaysCharge(formattedValue, name, field);
   };

   const headers = useMemo(
      () => [
         'Długość opóźnienia',
         ...values.map(delayCharge => {
            return (
               <div className={styles.columnHeader}>
                  <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[delayCharge.palletType].label}</h3>
                  <p>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[delayCharge.palletType].dimensions}</p>
               </div>
            );
         }),
      ],
      [values],
   );

   const delaysChargesErrorBannersData = useMemo(() => {
      if (!errors.delaysCharges || !touched.delaysCharges) {
         return null;
      }
      const uniqueErrorMessages = getUniqueErrorMessagesFromErrorsObjectList(errors.delaysCharges);
      return uniqueErrorMessages.map<BannerListItem>(uniqueErrorMessage => ({
         key: uniqueErrorMessage,
         variant: 'error',
         children: uniqueErrorMessage,
         fullWidth: true,
      }));
   }, [errors.delaysCharges, touched.delaysCharges]);

   return (
      <>
         {delaysChargesErrorBannersData && (
            <BannerList bannersData={delaysChargesErrorBannersData} />
         )}
         <SharedGridTable
            headers={headers}
            rows={[
               <SharedGridTableRow>
                  <SharedGridTableCell>
                     <p className={styles.rowHeader}>{rowsHeaders[0]}</p>
                  </SharedGridTableCell>
                  {values.map((delayCharge, index) => (
                     <SharedGridTableCell key={delayCharge.palletType}>
                        <StyledTextInput
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleDelaysChargesChange(e, 'upTo21Days')
                           }
                           onBlur={event => handleDelaysChargesBlur(event, 'upTo21Days')}
                           name={delayCharge.palletType}
                           value={delayCharge.upTo21Days}
                           type="text"
                           fullWidth
                           error={
                              errors?.delaysCharges &&
                              touched.delaysCharges &&
                              errors?.delaysCharges[index]?.upTo21Days
                           }
                        />
                     </SharedGridTableCell>
                  ))}
               </SharedGridTableRow>,
               <SharedGridTableRow>
                  <SharedGridTableCell>
                     <p className={styles.rowHeader}>{rowsHeaders[1]}</p>
                  </SharedGridTableCell>
                  {values.map((delayCharge, index) => (
                     <SharedGridTableCell key={delayCharge.palletType}>
                        <StyledTextInput
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleDelaysChargesChange(e, 'upTo30Days')
                           }
                           onBlur={event => handleDelaysChargesBlur(event, 'upTo30Days')}
                           name={delayCharge.palletType}
                           value={delayCharge.upTo30Days}
                           type="text"
                           fullWidth
                           error={
                              errors?.delaysCharges &&
                              touched.delaysCharges &&
                              errors?.delaysCharges[index]?.upTo30Days
                           }
                        />
                     </SharedGridTableCell>
                  ))}
               </SharedGridTableRow>,
               <SharedGridTableRow>
                  <SharedGridTableCell>
                     <p className={styles.rowHeader}>{rowsHeaders[2]}</p>
                  </SharedGridTableCell>
                  {values.map((delayCharge, index) => (
                     <SharedGridTableCell key={delayCharge.palletType}>
                        <StyledTextInput
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleDelaysChargesChange(e, 'over30Days')
                           }
                           onBlur={event => handleDelaysChargesBlur(event, 'over30Days')}
                           name={delayCharge.palletType}
                           value={delayCharge.over30Days}
                           type="text"
                           fullWidth
                           error={
                              errors?.delaysCharges &&
                              touched.delaysCharges &&
                              errors?.delaysCharges[index]?.over30Days
                           }
                        />
                     </SharedGridTableCell>
                  ))}
               </SharedGridTableRow>,
            ]}
         />
      </>
   );
};

export default DelaysCharges;
