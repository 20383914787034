import { DelayChargePerPalletConfig, DelayChargePerPalletConfigForm } from 'interfaces';
import { formatNumberToPrice } from 'utils/functions';

export const parseDelayChargePerPalletConfigIntoDelayChargePerPalletConfigForm: (
   delayChargePerPalletConfig: DelayChargePerPalletConfig,
) => DelayChargePerPalletConfigForm = delayChargePerPalletConfig => {
   const { palletType, upTo21Days, upTo30Days, over30Days } = delayChargePerPalletConfig;

   return {
      palletType,
      upTo21Days: formatNumberToPrice(upTo21Days),
      upTo30Days: formatNumberToPrice(upTo30Days),
      over30Days: formatNumberToPrice(over30Days),
   };
};
