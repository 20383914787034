import { Link } from 'react-router-dom';

import { Logo } from 'components/shared';
import { LOGIN } from 'constants/routes';

import styles from './AuthTopBar.module.css';

const AuthTopBar = () => (
   <header className={styles.header}>
      <nav>
         <Link to={LOGIN}>
            <Logo className={styles.logo} />
         </Link>
      </nav>
   </header>
);

export default AuthTopBar;
