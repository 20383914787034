import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { Loader } from '@mantine/core';
import classNames from 'classnames';

import { ChevronRightIcon } from 'assets';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import {
   AddFreeOrdersToNextOrderModal,
   OrderSummary,
   PlaceOrderForm,
} from 'components/SupplierPanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { PLACE_ORDER_FORM_DEFAULT_VALUES } from 'constants/placeOrderFormDefaultValues';
import { REPORT_STATUSES } from 'constants/reportStatuses';
import { SUPPLIER_ORDERS_ALL_STATUSES_TAB, SUPPLIER_PALLETS_DETAILS } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import {
   AdditionalOrder,
   BannerData,
   ClientUserDTO,
   DefaultErrors,
   OrderDTO,
   Pallet,
   PalletDataWithPrice,
   PalletSpecification,
   StorageDTO,
   SupplierPlaceOrderData,
} from 'interfaces';
import { HTTPService } from 'service';
import { damagedPalletDTOToDamagedPalletDataMapper } from 'service/http/mappers';
import { PlaceOrderByAdminRequest } from 'service/http/requests';
import { Banner, Step } from 'storybook';
import {
   filterActiveClients,
   getPalletsDataFromReportedLostOrDamagedPallets,
   scrollToElement,
} from 'utils/functions';
import { getFormattedDate } from 'utils/functions/getFormattedDate';
import { getPalletsFullData } from 'utils/functions/getPalletsFullData';
import { logNetworkError } from 'utils/logNetworkError';

import {
   INITIAL_REQUEST_STATUSES,
   LostOrDamagedPalletsReportData,
   REQUEST_TYPE,
   REQUEST_TYPE_TO_STATUS_MAPPER,
} from './helpers';
import styles from './PlaceOrderPage.module.css';

const PlaceOrderPage = () => {
   const [orderData, setOrderData] = useState<SupplierPlaceOrderData>(
      PLACE_ORDER_FORM_DEFAULT_VALUES(),
   );
   const [createdOrderId, setCreatedOrderId] = useState<string>();
   const [clients, setClients] = useState<ClientUserDTO[]>([]);
   const [storages, setStorages] = useState<StorageDTO[]>([]);
   const [lostOrDamagedPalletsReportData, setLostOrDamagedPalletsReportData] =
      useState<LostOrDamagedPalletsReportData>();
   const [availableAdditionalOrders, setAvailableAdditionalOrders] = useState<AdditionalOrder[]>(
      [],
   );
   const [activeStep, setActiveStep] = useState<'ORDER_DETAILS' | 'SUMMARY'>('ORDER_DETAILS');
   const [requestStatuses, setRequestStatuses] = useState(INITIAL_REQUEST_STATUSES);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const formStepsContainerRef = useRef<null | HTMLDivElement>(null);
   const navigate = useNavigate();
   const location = useLocation();

   const predefinedClientId = location.state?.clientId || lostOrDamagedPalletsReportData?.clientId;

   const fetchMainData = useCallback(() => {
      Promise.all([
         HTTPService.getUsers({
            page: 0,
            size: PAGINATION_MAX_SIZE,
         }),
         HTTPService.getStorageList({ page: 0, size: PAGINATION_MAX_SIZE }),
      ])
         .then(([getUsersResponse, getStoragesResponse]) =>
            setMainData(getUsersResponse.data.content, getStoragesResponse.data.content),
         )
         .catch(error => handleError(error, 'FETCH_DATA'))
         .finally(() => changeRequestStatuses('FETCH_DATA', false));
   }, []);

   const fetchMainDataWithLostOrDamagedPalletsReportData = useCallback((palletReportId: string) => {
      Promise.all([
         HTTPService.getUsers({
            page: 0,
            size: PAGINATION_MAX_SIZE,
         }),
         HTTPService.getStorageList({ page: 0, size: PAGINATION_MAX_SIZE }),
         HTTPService.getLostOrDamagedPalletsReportDetails(palletReportId),
      ])
         .then(([getUsersResponse, getStoragesResponse, getLostOrDamagedPalletsReportResponse]) => {
            const {
               id: reportId,
               assignedUser: { id: clientId },
               damagedPallets,
            } = getLostOrDamagedPalletsReportResponse.data;
            const damagedPalletsData = damagedPallets.map(
               damagedPalletDTOToDamagedPalletDataMapper,
            );
            const orderInitialPalletsData =
               getPalletsDataFromReportedLostOrDamagedPallets(damagedPalletsData);
            setMainData(getUsersResponse.data.content, getStoragesResponse.data.content);
            setLostOrDamagedPalletsReportData({ reportId, clientId });
            setOrderData(prevOrderData => ({
               ...prevOrderData,
               pallets: orderInitialPalletsData,
            }));
         })
         .catch(error => handleError(error, 'FETCH_DATA'))
         .finally(() => changeRequestStatuses('FETCH_DATA', false));
   }, []);

   useEffect(() => {
      changeRequestStatuses('FETCH_DATA', true);
      const palletReportId = location.state?.palletReportId;
      if (palletReportId) {
         fetchMainDataWithLostOrDamagedPalletsReportData(palletReportId);
      } else {
         fetchMainData();
      }
   }, [
      location.state?.palletReportId,
      fetchMainDataWithLostOrDamagedPalletsReportData,
      fetchMainData,
   ]);

   const setMainData = (clientsData: ClientUserDTO[], storagesData: StorageDTO[]) => {
      const activeClients = filterActiveClients(clientsData);
      setClients(activeClients);
      setStorages(storagesData);
   };

   const handleDetailsSubmit = (values: SupplierPlaceOrderData) => {
      setOrderData(values);
      setActiveStep('SUMMARY');
      scrollToTop();
   };

   const handleBackClick = () => {
      setActiveStep('ORDER_DETAILS');
      scrollToTop();
   };

   const handlePlaceOrder = () => {
      if (orderData.client) {
         const preparedOrderDataToPlace = prepareOrderDataToPlace(orderData.client);
         placeOrder(preparedOrderDataToPlace);
      }
   };

   const prepareOrderDataToPlace = (client: ClientUserDTO) => {
      const palletsWithAdditionalInfo = getPalletsFullData(
         orderData.pallets,
         (pallet: PalletDataWithPrice) => (palletType: PalletSpecification) =>
            palletType.name === pallet.name,
      );
      const orderPallets: Pallet[] = palletsWithAdditionalInfo.map(palletWithAdditionalInfo => {
         const amount =
            palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM'
               ? parseInt(palletWithAdditionalInfo.amount) *
                 palletWithAdditionalInfo.logisticMinimum
               : parseInt(palletWithAdditionalInfo.amount);
         return {
            palletType: palletWithAdditionalInfo.name,
            amount,
            pricePerUnit: palletWithAdditionalInfo.pricePerUnit || 0,
            shownAsMultipleOfTheMinimum: palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM',
         };
      });
      const order: PlaceOrderByAdminRequest = {
         userID: client.id,
         palletDeliveryDate: orderData.palletDeliveryDate
            ? getFormattedDate(orderData.palletDeliveryDate)
            : undefined,
         pallets: orderPallets,
         addressId:
            orderData.address && typeof orderData.address === 'object'
               ? orderData.address.value.id
               : undefined,
         personalPickUp: orderData.address === 'SELF_PICKUP',
         deliveryMethod: {
            addedToNextOrder: orderData.deliveryType === 'ADDED_TO_NEXT_ORDER',
            free: !!orderData.deliveryType,
         },
         storageID: orderData.storage?.value.storageId,
      };
      return order;
   };

   const placeOrder = (order: PlaceOrderByAdminRequest) => {
      changeRequestStatuses('PLACE_ORDER', true);
      lostOrDamagedPalletsReportData
         ? placeOrderForReportedPallets(order, lostOrDamagedPalletsReportData.reportId)
         : placeRegularOrFreeOrder(order);
   };

   const placeOrderForReportedPallets = (
      order: PlaceOrderByAdminRequest,
      lostOrDamagedPalletsReportId: string,
   ) => {
      Promise.all([
         HTTPService.placeOrderByAdmin(order),
         HTTPService.changeReportStatus(lostOrDamagedPalletsReportId, REPORT_STATUSES.REVIEWED),
      ])
         .then(() =>
            navigate(SUPPLIER_PALLETS_DETAILS(lostOrDamagedPalletsReportId), {
               state: { successPlaceOrder: true },
               replace: true,
            }),
         )
         .catch(handlePlaceOrderError)
         .finally(() => changeRequestStatuses('PLACE_ORDER', false));
   };

   const placeRegularOrFreeOrder = (order: PlaceOrderByAdminRequest) => {
      HTTPService.placeOrderByAdmin(order)
         .then(({ data: placeOrderByAdminResponseData }) => {
            const isOrderFree = order.deliveryMethod.free;
            return isOrderFree
               ? redirectToOrdersPage()
               : fetchAdditionalOrdersForAddingToPlacedOrder(
                    order.userID,
                    placeOrderByAdminResponseData,
                 );
         })
         .catch(handlePlaceOrderError)
         .finally(() => changeRequestStatuses('PLACE_ORDER', false));
   };

   const fetchAdditionalOrdersForAddingToPlacedOrder = (
      clientId: string,
      placeOrderByAdminResponseData: OrderDTO,
   ) =>
      HTTPService.getAdditionalOrders(clientId).then(
         ({ data: getAdditionalOrdersResponseData }) => {
            const additionalOrdersData = getAdditionalOrdersResponseData.additionalOrders;
            if (additionalOrdersData.length) {
               setAvailableAdditionalOrders(additionalOrdersData);
               setCreatedOrderId(placeOrderByAdminResponseData.id);
            } else {
               redirectToOrdersPage();
            }
         },
      );

   const redirectToOrdersPage = () =>
      navigate(SUPPLIER_ORDERS_ALL_STATUSES_TAB, {
         state: { successPlaceOrder: true },
      });

   const changeRequestStatuses = (requestType: REQUEST_TYPE, isLoading: boolean) =>
      setRequestStatuses(prevRequestStatuses => ({
         ...prevRequestStatuses,
         [REQUEST_TYPE_TO_STATUS_MAPPER[requestType]]: isLoading,
      }));

   const handlePlaceOrderError = (error: unknown) => {
      handleError(error, 'COMPLETE_ACTION');
      scrollToTop();
   };

   const handleError = (error: unknown, errorType: DefaultErrors) => {
      logNetworkError(error);
      setBannerData({
         variant: 'error',
         description: DEFAULT_ERROR_DESCRIPTIONS[errorType],
      });
   };

   const scrollToTop = () => scrollToElement(formStepsContainerRef);

   const renderStepContent = () => {
      if (isDataFetching) {
         return (
            <div className={styles.loaderContainer}>
               <Loader color={'var(--primary-green)'} />
            </div>
         );
      } else if (activeStep === 'ORDER_DETAILS') {
         return (
            <PlaceOrderForm
               predefinedClientId={predefinedClientId}
               initialState={orderData}
               clients={clients}
               storages={storages}
               isOrderFree={!!lostOrDamagedPalletsReportData}
               onSubmit={handleDetailsSubmit}
            />
         );
      } else {
         return (
            <>
               <OrderSummary
                  data={orderData}
                  bottomButtons={
                     <>
                        <StyledButton
                           style={{ marginRight: 12 }}
                           variant="outlined-primary"
                           text="Poprzedni krok"
                           onClick={handleBackClick}
                           className={styles.cancelBtn}
                        />
                        <StyledButton
                           onClick={handlePlaceOrder}
                           variant="filled-primary"
                           className={styles.submitBtn}
                           text="Złóż zamówienie"
                           loading={isPlaceOrderRequestSending}
                        />
                     </>
                  }
               />
               {createdOrderId && (
                  <AddFreeOrdersToNextOrderModal
                     availableAdditionalOrders={availableAdditionalOrders}
                     parentOrderId={createdOrderId}
                     opened
                     onClose={redirectToOrdersPage}
                  />
               )}
            </>
         );
      }
   };

   const { isDataFetching, isPlaceOrderRequestSending } = requestStatuses;

   return (
      <div className={styles.placeOrderContainer}>
         {bannerData && (
            <Banner
               withCloseIcon
               fullWidth
               variant={bannerData.variant}
               children={bannerData.description}
               className={styles.banner}
               onClose={() => setBannerData(null)}
            />
         )}
         <div ref={formStepsContainerRef} className={styles.stepsContainer}>
            <Step
               completed={activeStep === 'SUMMARY'}
               active={activeStep === 'ORDER_DETAILS'}
               text="Szczegóły zamówienia"
               stepCount={1}
               className={classNames({ [styles.hideTextMobile]: activeStep !== 'ORDER_DETAILS' })}
            />
            <ChevronRightIcon className={styles.chevron} />
            <Step
               active={activeStep === 'SUMMARY'}
               text="Podsumowanie"
               stepCount={2}
               className={classNames({ [styles.hideTextMobile]: activeStep !== 'SUMMARY' })}
            />
         </div>
         {renderStepContent()}
      </div>
   );
};

export default WithAuth(PlaceOrderPage, 'ROLE_SYSTEM_ADMIN');
