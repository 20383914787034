import { Textarea, TextareaProps } from '@mantine/core';

const StyledTextArea = (props: TextareaProps) => {
   return (
      <Textarea
         {...props}
         styles={{
            root: {
               fontFamily: 'Source Sans Pro, sans-serif',
            },
            label: {
               fontSize: 14,
               marginBottom: 4,
               color: 'var(--secondary-text)',
            },
            input: {
               borderRadius: 4,
               color: 'var(--primary-text)',
               '::placeholder': {
                  color: 'var(--secondary-text)',
               },
               ':focus': {
                  borderColor: 'var(--primary-green)',
               },
               fontFamily: 'Source Sans Pro, sans-serif',
            },
         }}
      />
   );
};
export default StyledTextArea;
