import styles from './StyledModalTitle.module.css';

type StyledModalTitleProps = { title: string; subtitle: string };

const StyledModalTitle = ({ title, subtitle }: StyledModalTitleProps) => (
   <>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.subtitle}>{subtitle} </p>
   </>
);

export default StyledModalTitle;
