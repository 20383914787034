import { BalanceType } from 'interfaces';

export const BALANCE_TYPE_LABELS: Record<BalanceType, string> = {
   CLIENT_LOST_OR_DESTROYED_BY_USER: 'Zgubione/Zniszczone z winy klienta',
   CLIENT_RENTED: 'Wynajęte',
   CLIENT_RECEIVED_DAMAGED: 'Uszkodzone z winy NDHP',
   CLIENT_DAMAGED_BY_USER: 'Uszkodzone z winy klienta',
   STORAGE_DAMAGED: 'Uszkodzone',
   STORAGE_LOST: 'Stracone',
   STORAGE_READY_TO_RENT: 'Gotowe do wynajmu',
   DISTRIBUTION_CENTER_FOR_COLLECTION_INVALID: 'Do odbioru uszkodzone',
   DISTRIBUTION_CENTER_RETURN_INVALID: 'Do zwrotu uszkodzone',
   DISTRIBUTION_CENTER_RETURN_VALID: 'Do zwrotu dobre',
   DISTRIBUTION_CENTER_FOR_COLLECTION_VALID: 'Do odbioru dobre',
};
