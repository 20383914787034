export const REMOVE_MODAL_CONSTANTS = {
   NO_PALLETS_COMMERCIAL_NETWORK:
      'Brak palet na magazynach. Czy jesteś pewien, że chcesz usunąć tą Sieć Handlową?',
   PALLETS_EXIST_COMMERCIAL_NETWORK:
      'W tej Sieci Handlowej znajdują się Centra Dystrybucyjne oraz palety. Użyj funkcji „Przerzuć palety" aby przenieść je do innego magazynu. W przeciwnym razie ich ilości zostaną usunięte razem z Centrami Dystrybucyjnymi.',
   PALLETS_EXIST_DISTRIBUTION_CENTER:
      'W tym magazynie znajdują się palety. Użyj funkcji „Przerzuć palety" aby przenieść je do innego magazynu. W przeciwnym razie ich ilości zostaną usunięte razem z magazynem.',
   NO_PALLETS_DISTRIBUTION_CENTER:
      'Brak palet na magazynie. Czy jesteś pewien, że chcesz usunąć to Centrum Dystrybucyjne?',
};
