import { useMemo, useState } from 'react';

import { groupBy } from 'lodash';

import { DEFAULT_PALLET_CUSTOM_PRICES } from 'constants/defaultPalletCustomPrices';
import { STORAGE_TYPE_LABELS } from 'constants/storageType';
import {
   DeliveryAddress,
   OrderPallet,
   PalletCustomPrices,
   PalletSpecification,
   SelectValue,
   StorageDTO,
   StorageOption,
} from 'interfaces';
import { StoragesByTypeDTO } from 'interfaces/dto/storageDTO';
import {
   checkIfLogisticMinimumAchieved,
   getFullAddress,
   getPalletsFullData,
   parseNumber,
} from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';

import { useForm } from './useForm';

type OrderFormProps<T, G> = {
   initialState: T;
   deliveryAddresses?: DeliveryAddress[];
   storages?: StorageDTO[];
   retentionPeriodDays?: number;
   onSubmit?: (values: T) => void;
   onChangeLogisticMinimumAchieved?: (value: boolean) => void;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   validations: ((pallets: G) => any)[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useOrderForm = <T extends Record<string, any>, G extends Record<string, any>>({
   initialState,
   deliveryAddresses,
   storages,
   retentionPeriodDays,
   onSubmit,
   onChangeLogisticMinimumAchieved,
   validations,
}: OrderFormProps<T, G>) => {
   const [addressList, setAddressList] = useState<DeliveryAddress[]>(deliveryAddresses || []);
   const [retentionPeriod, setRetentionPeriod] = useState<number | undefined>(retentionPeriodDays);
   const [storageList, setStorageList] = useState<StorageDTO[]>(storages || []);
   const [palletCustomPrices, setPalletCustomPrices] = useState<PalletCustomPrices>(
      initialState.palletCustomPrices || {},
   );

   const handleSubmit = () => {
      checkLogisticMinimum();
      onSubmit?.(values as T);
   };

   const {
      values,
      touched,
      errorsList,
      submitHandler,
      selectChangeHandler,
      changeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      setValues,
      setTouched,
   } = useForm({
      initialState: initialState,
      onSubmit: handleSubmit,
      validations: validations,
   });

   const checkLogisticMinimum = () => {
      if (!onChangeLogisticMinimumAchieved) {
         return;
      }
      const pallets = getPalletsFullData(
         values.pallets,
         (pallet: OrderPallet) => (palletType: PalletSpecification) =>
            palletType.name === pallet.name,
      );
      const isLogisticMinimumAchieved = checkIfLogisticMinimumAchieved(pallets);
      onChangeLogisticMinimumAchieved(isLogisticMinimumAchieved);
   };

   const holdingPeriod = useMemo(() => {
      if (values.palletDeliveryDate && retentionPeriod) {
         const deliveryDay = new Date(values.palletDeliveryDate);
         deliveryDay.setDate(deliveryDay.getDate() + retentionPeriod);
         return deliveryDay;
      } else {
         return null;
      }
   }, [values.palletDeliveryDate, retentionPeriod]);

   const deliveryAddressOptions = useMemo(() => {
      const addressOptions = addressList.map(address => ({
         label: getFullAddress(address.address, address.zipCode, address.city, address.name),
         value: {
            street: address.address,
            city: address.city,
            name: address.name,
            zipCode: address.zipCode,
            id: address.id,
         },
      }));
      return sortOptions(addressOptions);
   }, [addressList]);

   const storageOptions = useMemo(() => {
      const groupedStorages = groupBy(storageList, 'type');
      const orderedAndGroupedStorages = Object.keys(groupedStorages)
         .reverse()
         .reduce((obj: StoragesByTypeDTO, key) => {
            obj[key] = groupedStorages[key];
            return obj;
         }, {});
      return Object.entries(orderedAndGroupedStorages).map(([storageType, storageItems]) => {
         const options = storageItems.map(storageItem => ({
            value: { storageId: storageItem.id, storageType: storageItem.type },
            label: `${storageItem.address.street}, ${storageItem.address.zipCode} ${storageItem.address.city}`,
         }));
         const sortedOptions = sortOptions(options);
         return {
            title: STORAGE_TYPE_LABELS[storageType],
            options: sortedOptions,
         };
      });
   }, [storageList]);

   const handleChangeAddress = (
      address: SelectValue | StorageOption | string | null,
      field: string,
   ) => {
      selectChangeHandler(address, field);
      setPalletCustomPrices(DEFAULT_PALLET_CUSTOM_PRICES());
   };

   const handleChangePalletPrices = (customPrices: { [key: string]: string }) => {
      setPalletCustomPrices({
         edhpPalletPrice: parseNumber(customPrices.edhpPalletPrice),
         euroPalletPrice: parseNumber(customPrices.euroPalletPrice),
         halfPalletNDHPPrice: parseNumber(customPrices.halfPalletNDHPPrice),
         quarterPalletCDHPPrice: parseNumber(customPrices.quarterPalletCDHPPrice),
      });
   };

   return {
      values,
      setRetentionPeriod,
      touched,
      errorsList,
      selectChangeHandler,
      changeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      handleSubmit: submitHandler,
      handleChangeAddress,
      setAddresses: setAddressList,
      deliveryAddressOptions,
      setStorages: setStorageList,
      storageOptions,
      holdingPeriod,
      setValues,
      setTouched,
      palletCustomPrices,
      handleChangePalletPrices,
      setPalletCustomPrices,
   };
};
