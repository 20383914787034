import { useState } from 'react';

import classNames from 'classnames';
import { ChevronDown } from 'tabler-icons-react';

import { CloseIcon } from 'assets';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledRadioButton from 'components/shared/StyledRadioButton/StyledRadioButton';
import { Option } from 'interfaces/commonInterfacesAndTypes';

import styles from './MobileSelect.module.css';

type MobileSelectProps = {
   value?: Option;
   placeholder?: string;
   label?: string;
   options?: {
      value: string;
      label: string;
   }[];
   onChange: (option: Option) => void;
   disabled?: boolean;
   error?: boolean;
   classNames?: {
      container?: string;
      select?: string;
   };
};

const MobileSelect = ({
   value,
   placeholder,
   label,
   options,
   error,
   onChange,
   disabled,
   classNames: selectClassNames,
}: MobileSelectProps) => {
   const [opened, setOpened] = useState(false);

   const handleOpen = () => !disabled && setOpened(true);

   const handleClose = () => setOpened(false);

   return (
      <div className={classNames(styles.mainContainer, selectClassNames?.container)}>
         {label && <p className={styles.selectLabel}>{label}</p>}
         <div
            onClick={handleOpen}
            className={classNames(
               styles.select,
               {
                  [styles.error]: error,
                  [styles.disabled]: disabled,
               },
               selectClassNames?.select,
            )}
         >
            <div className={styles.selectLeftContent}>
               <span>{value?.label || placeholder}</span>
            </div>
            <ChevronDown className={styles.selectIcon} />
         </div>

         {opened && (
            <div
               className={classNames(styles.selectOptionsContainer, {
                  [styles.selectOptionsContainerExpanded]: opened,
               })}
            >
               <div className={styles.closeIconContainer}>
                  <CloseIcon onClick={handleClose} />
               </div>
               {label && <h4 className={styles.selectOptionsContainerLabel}>{label}</h4>}
               <div className={styles.selectOptionsInnerContainer}>
                  {options?.map((option, index) => (
                     <StyledRadioButton
                        key={index}
                        checked={option.value === value?.value}
                        label={option.label}
                        onChangeHandler={() => onChange(option)}
                        className={styles.option}
                     />
                  ))}
               </div>
               <StyledButton
                  variant="filled-primary"
                  text="Wybierz"
                  onClick={handleClose}
                  disabled={!value?.value}
                  fullWidth
                  className={styles.selectOptionsContainerBtn}
               />
            </div>
         )}
      </div>
   );
};

export default MobileSelect;
