import forOwn from 'lodash/forOwn';

import { STORAGE_TYPE, STORAGE_TYPE_LABELS_SINGULAR } from 'constants/storageType';
import { DeliveryAddress, Option, StorageDTO } from 'interfaces';

import { sortOptions } from './sortOptions';
import { getFullAddress } from '.';

export const getStorageAddressOptions = (storages: StorageDTO[]) => {
   const storageNameOptions: Option[] = [];
   const storageAddressOptions: { [key: string]: Option[] } = {};
   const isSomeStorageInternal = storages.some(storage => storage.type === 'INTERNAL');
   const isSomeStorageExternal = storages.some(storage => storage.type === 'EXTERNAL');
   if (isSomeStorageInternal) {
      storageNameOptions.push({
         value: STORAGE_TYPE.INTERNAL,
         label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.INTERNAL],
      });
      storageAddressOptions[STORAGE_TYPE.INTERNAL] = [];
   }
   if (isSomeStorageExternal) {
      storageNameOptions.push({
         value: STORAGE_TYPE.EXTERNAL,
         label: STORAGE_TYPE_LABELS_SINGULAR[STORAGE_TYPE.EXTERNAL],
      });
      storageAddressOptions[STORAGE_TYPE.EXTERNAL] = [];
   }
   storages.forEach(storage => {
      const addressLabel = getFullAddress(
         storage.address.street,
         storage.address.zipCode,
         storage.address.city,
      );
      storageAddressOptions[storage.type].push({
         value: addressLabel,
         label: addressLabel,
      });
   });
   const sortedStorageAddressOptions = forOwn(storageAddressOptions, options =>
      sortOptions(options),
   );
   return {
      storageNameOptions,
      storageAddressOptions: sortedStorageAddressOptions,
   };
};

export const getClientAddressOptions = (deliveryAddresses: DeliveryAddress[]) => {
   const clientNameOption = { label: 'Adres własny', value: STORAGE_TYPE.CLIENT };
   const clientAddressOptions = deliveryAddresses.map(deliveryAddress => {
      const addressLabel = getFullAddress(
         deliveryAddress.address,
         deliveryAddress.zipCode,
         deliveryAddress.city,
         deliveryAddress.name,
      );
      return {
         value: addressLabel,
         label: addressLabel,
      };
   });
   const sortedClientAddressOptions = sortOptions(clientAddressOptions);
   return {
      clientNameOption: [clientNameOption],
      clientAddressOptions: { [STORAGE_TYPE.CLIENT]: sortedClientAddressOptions },
   };
};
