import { DatePicker } from '@mantine/dates';
import { Pencil, Trash } from 'tabler-icons-react';

import { OutlinedCalendarIcon } from 'assets';
import { DATE_PICKER_DATE_FORMAT } from 'constants/dateFormats';
import { StorageAddress } from 'interfaces';
import { getFullAddress } from 'utils/functions';

import { ActionDropdownItem, ActionsDropdown } from '../shared';

import styles from './StorageHeader.module.css';

type StorageHeaderProps = {
   id: string;
   address: StorageAddress;
   date: Date;
   handleDateChange: (date: Date | null, id: string) => void;
   handleDeleteClick?: () => void;
   handleEditClick?: () => void;
};
const StorageHeader = ({
   id,
   address,
   date,
   handleDateChange,
   handleDeleteClick,
   handleEditClick,
}: StorageHeaderProps) => {
   const actions: ActionDropdownItem[] = [];

   if (handleEditClick) {
      actions.push({
         text: 'Edytuj stan magazynowy palet',
         icon: <Pencil color={'#a6a6b2'} size={30} />,
         onClick: handleEditClick,
      });
   }
   if (handleDeleteClick) {
      actions.push({
         text: 'Usuń magazyn',
         icon: <Trash size={20} />,
         onClick: handleDeleteClick,
         lastOption: true,
      });
   }

   return (
      <div className={styles.header}>
         <div>
            <h4 className={styles.headerTitle}>{address.name}</h4>
            <p className={styles.headerSubtitle}>
               {getFullAddress(address.street, address.zipCode, address.city)}
            </p>
         </div>
         <div className={styles.actions}>
            <DatePicker
               value={date}
               onChange={value => handleDateChange(value, id)}
               inputFormat={DATE_PICKER_DATE_FORMAT}
               icon={<OutlinedCalendarIcon />}
               clearable={false}
               locale="pl"
            />
            {!!actions.length && <ActionsDropdown options={actions} />}
         </div>
      </div>
   );
};

export default StorageHeader;
