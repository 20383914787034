import classNames from 'classnames';

import { RadioButton, RadioButtonProps } from 'storybook';

import styles from './StyledRadioButton.module.css';

const StyledRadioButton = (props: RadioButtonProps) => (
   <RadioButton
      {...props}
      className={classNames(
         styles.radioButton,

         {
            [styles.buttonView]: props.buttonView,
            [styles.error]: props.error,
            [styles.checked]: props.checked,
            [styles.disabled]: props.disabled,
         },
         props.className,
      )}
   />
);

export default StyledRadioButton;
