import {
   BannerVariants,
   DamagedPalletDTO,
   LostDamagedPalletsByClientItem,
   LostDamagedPalletsBySupplierItem,
   Pallet,
   PalletReportDTO,
   PickUpStatus,
} from 'interfaces';

// general
export type SelectValue = {
   value: string | Date | null;
   label: string;
};

export type OptionGroup = {
   groupName: string;
   options: Option[];
};

export type Option = {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   value: any;
   label: string;
   description?: string;
};

export type SharedGridTableElementProps = {
   children?: React.ReactNode;
   className?: string;
   onClick?: () => void;
};

export type Address = { street: string; zipCode: string; city: string };

export type SupplierOrdersPageTab =
   | 'ALL'
   | 'WAITING'
   | 'IN_PROGRESS'
   | 'DELIVERED'
   | 'DURING_BILLING'
   | 'TO_PAY'
   | 'ARCHIVED';

export type StoragePageTab =
   | 'internal-storages'
   | 'external-storages'
   | 'commercial-networks'
   | 'clients';

export type SupplierPalletsPickUpsTab = PickUpStatus;

export type PalletsBalanceModal =
   | null
   | 'REPORT_DAMAGE_LOSS_PALLETS'
   | 'SEND_PALLETS'
   | 'SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL'
   | 'ADMISSION_MODAL'
   | 'EDIT_USER_BALANCE';

export enum SupplierPalletsPickUpsModalType {
   ACCEPT = 'ACCEPT',
   REJECT = 'REJECT',
}

export type BannerData = {
   variant: BannerVariants;
   title?: string;
   description?: string;
   withCloseIcon?: boolean;
};

// pallets
export type PalletData = {
   name: PalletType;
   amount: string;
   orderType: 'LOGISTIC_MINIMUM' | 'CUSTOM';
};

export type PalletDataWithPrice = PalletData & {
   pricePerUnit: number;
};

export type PalletSpecification = {
   name: PalletType;
   label: string;
   dimensions: string;
   logisticMinimum: number;
   logisticMinimumUpperRange?: number;
};

export type SupplierOrderSummaryPallet = PalletSpecification & PalletDataWithPrice;

export type ClientOrderSummaryPallet = PalletSpecification & PalletData;

export type OrderDetailsPallet = PalletWithSpecification & {
   freePalletsAmount: number;
};

export type PalletWithSpecification = PalletSpecification & Pallet;

export type OrderSummaryAndOrderDetailsPallets =
   | SupplierOrderSummaryPallet[]
   | ClientOrderSummaryPallet[]
   | OrderDetailsPallet[];

export type OrderSummaryAndOrderDetailsPallet =
   | SupplierOrderSummaryPallet
   | ClientOrderSummaryPallet
   | OrderDetailsPallet;

export type PalletType = 'EDHP_PALLET' | 'EURO_PALLET' | 'HALF_PALLET_NDHP' | 'QUARTER_PALLET_CDHP';

export type ExcessPallets = { [key: string]: number };

export type LostDamagedPalletsItemsWithPrice = {
   reportPrice: number | undefined;
   uuid: string;
   palletType: Option;
   damageType: ReportType | null;
   palletsAmount: string;
};

export type PalletAmount = { palletType: PalletType; amount: number };

export type DamagedPalletData = Omit<DamagedPalletDTO, 'type'> & {
   palletType: PalletType;
};

export type PalletBalance = Record<PalletType, number>;

export type PalletAmountListItem = {
   name: string;
   label: string;
   dimensions: string;
   amount: number;
   isFree: boolean;
};

// orders
export type ClientOrdersTableItem = {
   id: string;
   createdDate: string;
   deliveryDate: string;
   status: OrderStatus;
   palletsAmount: number;
   retentionDate: string;
   free: boolean;
   freePalletsAmount: number;
   internalOrderId: string;
};

export type SupplierOrdersTableItem = Omit<ClientOrdersTableItem, 'internalOrderId'> & {
   clientName: string;
   blockedClient: boolean;
   retentionDate: string;
   read: boolean;
};

export type OrderStatus =
   | 'WAITING'
   | 'IN_PROGRESS'
   | 'DELIVERED'
   | 'ENDED'
   | 'CANCELED'
   | 'DURING_BILLING'
   | 'TO_PAY';

export type DeliveryMethod = { free: boolean; addedToNextOrder: boolean };

// storages
export type StorageType = 'INTERNAL' | 'EXTERNAL';

export type StoragePalletsStates = 'readyToRent' | 'damaged' | 'lost';

// commercial-network
export type CommercialNetworkWithAddress = {
   name?: string;
   streetWithCity?: string;
};

export type CommercialNetwork = {
   id: string;
   name: string;
};

// reports
export type DamageType = 'LOST_DAMAGED_BY_CLIENT' | 'LOST_DAMAGED_BY_SUPPLIER';

export type ReportType = 'LOST_OR_DESTRUCTION' | 'DAMAGE';

export type ReportStatus = 'REPORTED' | 'REVIEWED' | 'CANCELED';

export type LostDamagedPalletReport = {
   id: string;
   status: ReportStatus;
   damagedPallets: DamagedPalletDTO[];
};

export type LostDamagedPalletItems =
   | LostDamagedPalletsByClientItem[]
   | LostDamagedPalletsBySupplierItem[];

export type GroupedLostDamagedPalletItems = {
   damaged: LostDamagedPalletItems;
   lostOrDestroyed: LostDamagedPalletItems;
};

export type PalletReport = DamagedBySupplierPalletReport | DamagedByClientPalletReport;

export type CommonPalletReport = Omit<PalletReportDTO, 'receivedDamaged' | 'status'>;

export type DamagedBySupplierPalletReport = CommonPalletReport & {
   receivedDamaged: true;
   status: ReportStatus;
};

export type DamagedByClientPalletReport = CommonPalletReport & {
   receivedDamaged: false;
   status: null;
};

// sending pallets
export type AddressOption = {
   nameOptions: Option[];
   addressOptions: {
      [key: string]: Option[];
   };
};

// charges
export type ChargeType =
   | 'RETENTION'
   | 'WRONG_RECEIVER'
   | 'LOST_PALLET'
   | 'DAMAGE'
   | 'INFORMATION_DELAY'
   | 'OTHER';

// users
export type UserRole =
   | 'ROLE_SYSTEM_ADMIN'
   | 'ROLE_CLIENT'
   | 'ROLE_DISTRIBUTION_CENTER'
   | 'ROLE_STORAGE';

export type DeliveryAddress = {
   name: string;
   address: string;
   zipCode: string;
   city: string;
   id?: string;
};
