import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { AlertTriangle, Search, Trash } from 'tabler-icons-react';

import { EyeGreenIcon } from 'assets';
import { NoDocumentsNotification, ToolTip, UploadPDFButton } from 'components/shared';
import { SectionContainer, StyledButton, StyledTable, StyledTextInput } from 'components/shared';
import { ORDER_DOCUMENTS_BANNERS } from 'constants/banners';
import {
   documentsTableHeaders,
   documentsTableHeadersWithCanceledInvoices,
} from 'constants/tableHeaders';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { ClientDocumentType, DocumentDTO } from 'interfaces';
import { BannerData } from 'interfaces/commonInterfacesAndTypes';
import { HTTPService } from 'service';
import { Banner, TableCell, TableRow } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './OrderDocuments.module.css';

type OrderDocumentsProps = {
   orderId: string;
   displayBanner: (bannerData: BannerData) => void;
   supplierMode?: boolean;
};

const invoiceTypes: ClientDocumentType[] = ['INVOICE', 'ADDITIONAL_CHARGES_INVOICE'];

const OrderDocuments = forwardRef(
   ({ orderId, displayBanner, supplierMode }: OrderDocumentsProps, ref) => {
      const [orderDocuments, setOrderDocuments] = useState<DocumentDTO[] | []>([]);
      const [filteredDocuments, setFilteredDocuments] = useState<DocumentDTO[] | []>([]);
      const [isDocumentActionLoading, setIsDocumentActionLoading] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const { isMobile } = useResponsiveLayout();

      useImperativeHandle(ref, () => ({
         fetchDocuments: handleFetchDocumentsData,
      }));

      const handleFetchDocumentsDataError = useCallback(
         (error: unknown) =>
            errorHandler(error, () => displayBanner(ORDER_DOCUMENTS_BANNERS.FETCH_DOCUMENTS_ERROR)),
         [displayBanner],
      );

      const handleFetchDocumentsData = useCallback(() => {
         setIsLoading(true);
         HTTPService.getOrderDocumentsList(orderId)
            .then(res => {
               setOrderDocuments(res.data.documents);
               setFilteredDocuments(res.data.documents);
            })
            .catch(handleFetchDocumentsDataError)
            .finally(() => setIsLoading(false));
      }, [orderId, handleFetchDocumentsDataError]);

      useEffect(handleFetchDocumentsData, [handleFetchDocumentsData]);

      const handleSearch = useCallback(
         (e: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredDocuments(
               orderDocuments.filter(document =>
                  document.name.toLowerCase().includes(e.target.value.toLowerCase()),
               ),
            );
         },
         [orderDocuments],
      );
      const uploadDocument = (document: FileList) => {
         setIsDocumentActionLoading(true);
         const formData = new FormData();
         formData.append('file', document[0]);
         HTTPService.uploadOrderDocument(orderId, formData)
            .then(res => {
               setOrderDocuments(prevState => [...prevState, res.data]);
               setFilteredDocuments(prevState => [...prevState, res.data]);
               displayBanner(ORDER_DOCUMENTS_BANNERS.ADD_DOCUMENT_SUCCESS);
            })
            .catch(err => {
               logNetworkError(err);
               displayBanner(ORDER_DOCUMENTS_BANNERS.ADD_DOCUMENT_ERROR);
            })
            .finally(() => setIsDocumentActionLoading(false));
      };

      const handleDeleteDocument = (documentId: string) => {
         setIsDocumentActionLoading(true);
         HTTPService.deleteOrderDocument(documentId)
            .then(() => {
               setOrderDocuments(prevState =>
                  prevState.filter(document => document.id !== documentId),
               );
               setFilteredDocuments(prevState =>
                  prevState.filter(document => document.id !== documentId),
               );
               displayBanner(ORDER_DOCUMENTS_BANNERS.REMOVE_DOCUMENT_SUCCESS);
            })
            .catch(err => {
               logNetworkError(err);
               displayBanner(ORDER_DOCUMENTS_BANNERS.REMOVE_DOCUMENT_ERROR);
            })
            .finally(() => setIsDocumentActionLoading(false));
      };

      const isAnyInvoiceCanceled = useMemo(
         () =>
            filteredDocuments.some(
               document => invoiceTypes.includes(document.type) && document.canceled,
            ),
         [filteredDocuments],
      );

      return (
         <div>
            {
               <>
                  {isMobile ? (
                     <Banner
                        style={{ marginBottom: 24 }}
                        children="Możesz dodać dokumenty tylko z przeglądarki na komputerze."
                        variant="info"
                     />
                  ) : (
                     <div className={styles.topContainer}>
                        <StyledTextInput
                           className={styles.searchInput}
                           leftIcon={<Search />}
                           type="text"
                           onChange={handleSearch}
                           placeholder="wpisz nazwę dokumentu"
                        />
                        <UploadPDFButton
                           id="uploadPDFBtnHeader"
                           onUploadDocument={uploadDocument}
                           className={styles.uploadPDFBtnHeader}
                           isLoading={isDocumentActionLoading}
                        />
                     </div>
                  )}
                  <SectionContainer title="Dokumenty" isLoading={isLoading}>
                     <StyledTable
                        className={styles.documentsTable}
                        columnHeaders={
                           isAnyInvoiceCanceled
                              ? documentsTableHeadersWithCanceledInvoices
                              : documentsTableHeaders
                        }
                     >
                        {filteredDocuments.length ? (
                           <>
                              {filteredDocuments.map(document => {
                                 const isCanceledInvoice =
                                    invoiceTypes.includes(document.type) && document.canceled;
                                 return (
                                    <TableRow key={document.id}>
                                       {isAnyInvoiceCanceled && (
                                          <TableCell className={styles.canceledInvoiceAlertCell}>
                                             {isCanceledInvoice && (
                                                <ToolTip
                                                   content="Faktura anulowana. Pamiętaj o usunięciu faktury w subiekcie."
                                                   variant="right"
                                                   visible
                                                >
                                                   <span className={styles.alertIconContainer}>
                                                      <AlertTriangle color={'var(--primary-red)'} />
                                                   </span>
                                                </ToolTip>
                                             )}
                                          </TableCell>
                                       )}
                                       <TableCell>{document.name}</TableCell>
                                       <TableCell className={styles.dateCell}>
                                          {new Date(document.createdDate).toLocaleDateString(
                                             'en-GB',
                                          )}
                                       </TableCell>
                                       <TableCell align="right" className={styles.actionsCell}>
                                          <div className={styles.btnsContainer}>
                                             <div>
                                                <a
                                                   href={document.url}
                                                   rel="noopener"
                                                   target="_blank"
                                                >
                                                   <StyledButton
                                                      text="Podgląd"
                                                      variant="text"
                                                      icon={
                                                         <EyeGreenIcon style={{ marginRight: 8 }} />
                                                      }
                                                   />
                                                </a>
                                                {document.type === 'OTHER' && supplierMode && (
                                                   <StyledButton
                                                      text="Usuń"
                                                      variant="text"
                                                      onClick={() =>
                                                         handleDeleteDocument(document.id)
                                                      }
                                                      icon={<Trash />}
                                                      className={styles.actionBtnDanger}
                                                   />
                                                )}
                                             </div>
                                          </div>
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           </>
                        ) : (
                           <tr>
                              <td colSpan={3}>
                                 <NoDocumentsNotification
                                    uploadBtnId="uploadPDFBtnTable"
                                    onUploadDocument={uploadDocument}
                                    supplierMode={supplierMode}
                                 />
                              </td>
                           </tr>
                        )}
                     </StyledTable>
                  </SectionContainer>
               </>
            }
         </div>
      );
   },
);

export default OrderDocuments;
