import { LostDamagedPalletsByClientItem } from 'interfaces';

import AdditionalFeeListItem from '../AdditionalFeeListItem/AdditionalFeeListItem';
import styles from '../AdditionalFeeWarning.module.css';

type AdditionalFeeSectionProps = {
   damageType: string;

   totalAmount: number;
   pricesForPallets: LostDamagedPalletsByClientItem[];
   multipleLine?: boolean;
   sumPerCategory?: number;
   sumLine?: boolean;
};

const AdditionalFeeSection = ({
   damageType,
   totalAmount,
   pricesForPallets,
   multipleLine,
   sumPerCategory,
   sumLine,
}: AdditionalFeeSectionProps) => {
   return (
      <>
         <p>
            Dodatkowa opłata za <b>{damageType} </b>
            palet w wysokości:
         </p>
         <ul className={styles.additionalFeeList}>
            {pricesForPallets?.map((el, index) => (
               <AdditionalFeeListItem element={el} key={index} />
            ))}
         </ul>
         <p>
            <b>Razem: {sumPerCategory},00 PLN</b>
         </p>
         <br />
         {sumLine && (
            <p>
               <b>
                  o łącznej wartości <u>{totalAmount},00 PLN </u>
               </b>
               zostanie doliczona do zamówienia.
            </p>
         )}

         {multipleLine && <p>zostanie doliczona do zamówienia.</p>}
      </>
   );
};

export default AdditionalFeeSection;
