import { SharedGridTableCell, SharedGridTableRow } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { CommonRatePerPalletConfig } from 'interfaces';
import { currencyFormatter } from 'utils/numberFormatter';

type CommonPalletsPricingTableRowProps = { commonPalletsPricingItem: CommonRatePerPalletConfig };

export const CommonPalletsPricingTableRow = ({
   commonPalletsPricingItem,
}: CommonPalletsPricingTableRowProps) => {
   const { palletType, damagePrice, lostPrice, personalPickUpPrice, wrongReceiver } =
      commonPalletsPricingItem;
   return (
      <SharedGridTableRow>
         <SharedGridTableCell>
            <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].label}</h3>
            <p>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].dimensions}</p>
         </SharedGridTableCell>
         <SharedGridTableCell>{currencyFormatter.format(personalPickUpPrice)}</SharedGridTableCell>
         <SharedGridTableCell>{currencyFormatter.format(damagePrice)}</SharedGridTableCell>
         <SharedGridTableCell>{currencyFormatter.format(lostPrice)}</SharedGridTableCell>
         <SharedGridTableCell>{currencyFormatter.format(wrongReceiver)}</SharedGridTableCell>
      </SharedGridTableRow>
   );
};
