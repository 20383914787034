import classNames from 'classnames';

import { SectionContainer } from 'components/shared';
import { ClientUserDTO } from 'interfaces';

import styles from './ClientDetailsData.module.css';

export const ClientDetailsData = (client: ClientUserDTO) => {
   return (
      <>
         <SectionContainer style={{ marginTop: 24 }} title="Dane podstawowe">
            <div className={styles.row}>
               <p className={styles.label}>NIP</p>
               <p>{client.nip}</p>
            </div>
            <div className={styles.row}>
               <p className={styles.label}>Nazwa firmy</p>
               <p>{client.companyName}</p>
            </div>
            <div className={styles.row}>
               <p className={styles.label}>Ulica, numer domu / numer mieszkania</p>
               <p>{client.address}</p>
            </div>
            <div className={classNames(styles.row, styles.flex)}>
               <div>
                  <p className={styles.label}>Kod pocztowy</p>
                  <p>{client.zipCode}</p>
               </div>
               <div>
                  <p className={styles.label}>Miejscowość</p>
                  <p>{client.city}</p>
               </div>
            </div>
         </SectionContainer>
         <SectionContainer title="Dane kontaktowe">
            <div className={styles.row}>
               <p className={styles.label}>Telefon</p>
               <p>{client.phoneNumber}</p>
            </div>
            <div className={styles.row}>
               <p className={styles.label}>E-mail dla faktur</p>
               <p>{client.invoiceEmail}</p>
            </div>
            <div className={styles.row}>
               <p className={styles.label}>E-mail login / e-mail kontaktowy</p>
               <p>{client.email}</p>
            </div>
         </SectionContainer>
         <SectionContainer title="Adres dostawy">
            {client.deliveryAddresses.map((deliveryAddress, index) => {
               const { address, zipCode, city, name } = deliveryAddress.address;
               return (
                  <div key={index}>
                     <h3 className={styles.addressTitle}>Adres {index + 1}</h3>
                     <div className={styles.row}>
                        <p className={styles.label}>Nazwa</p>
                        <p>{name}</p>
                     </div>
                     <div className={styles.row}>
                        <p className={styles.label}>Ulica, numer domu / numer mieszkania</p>
                        <p>{address}</p>
                     </div>
                     <div className={classNames(styles.row, styles.flex)}>
                        <div>
                           <p className={styles.label}>Kod pocztowy</p>
                           <p>{zipCode}</p>
                        </div>
                        <div>
                           <p className={styles.label}>Miejscowość</p>
                           <p>{city}</p>
                        </div>
                     </div>
                  </div>
               );
            })}
         </SectionContainer>
      </>
   );
};
