import { SectionContainer } from 'components/shared';
import { PalletBalance } from 'interfaces';

import { DamageLossPalletsSummary } from '..';

import styles from './DamagedLostBySupplierPalletsSummarySection.module.css';

type DamagedLostBySupplierPalletsSummarySectionProps = {
   damagedOrLostBySupplierPallets: PalletBalance;
   isSupplierView?: boolean;
   onClickDamagedOrLostBySupplierDetails: () => void;
};

const DamagedLostBySupplierPalletsSummarySection = ({
   damagedOrLostBySupplierPallets,
   isSupplierView,
   onClickDamagedOrLostBySupplierDetails,
}: DamagedLostBySupplierPalletsSummarySectionProps) => (
   <SectionContainer
      title={`Dostarczone uszkodzone, zgubione lub zniszczone palety ${
         isSupplierView ? '(z winy NDHP)' : ''
      }`}
      subtitle="Palety z dostarczonych zamówień"
      className={styles.container}
   >
      <DamageLossPalletsSummary
         damageLossPalletsSummaryData={damagedOrLostBySupplierPallets}
         onClickDetails={onClickDamagedOrLostBySupplierDetails}
      />
   </SectionContainer>
);

export default DamagedLostBySupplierPalletsSummarySection;
