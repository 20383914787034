import classNames from 'classnames';

import CustomLoader from 'components/shared/CustomLoader/CustomLoader';

import styles from './ModalLoader.module.css';

type ModalLoaderProps = {
   className?: string;
};

const ModalLoader = ({ className }: ModalLoaderProps) => (
   <div className={classNames(styles.container, className)}>
      <CustomLoader />
   </div>
);

export default ModalLoader;
