import { useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import NotFoundOrderAlertModal from 'components/shared/NotFoundOrderAlertModal/NotFoundOrderAlertModal';
import GoBackWarningModal from 'components/shared/WarningModal/WarningModals/GoBackWarningModal';
import { INVALID_IMAGE_FILE } from 'constants/errorCodes';
import { LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM } from 'constants/palletsTableDefaultValues';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { useTableForm } from 'hooks/useTableForm';
import {
   ExcessPallets,
   GroupedLostDamagedPalletItems,
   LostDamagedPalletsBySupplierItem,
   Option,
   PalletAmount,
   PalletReportDTO,
   PalletType,
} from 'interfaces';
import { HTTPService } from 'service';
import { PalletReportRequest } from 'service/http/requests';
import { Banner } from 'storybook';
import { getExcessPallets } from 'utils/functions/getExcessPallets';
import { logNetworkError } from 'utils/logNetworkError';
import { lostDamagedPalletsFormValidations } from 'utils/validation/lostDamagedPalletsFormValidations';

import LostDamagedPalletsStepFooter from '../LostDamagedPalletsStepFooter/LostDamagedPalletsStepFooter';
import LostDamagedPalletsTable from '../LostDamagedPalletsTable/LostDamagedPalletsTable';

import LostDamagedPalletsMobileList from './LostDamagedPalletsMobileList/LostDamagedPalletsMobileList';
import styles from './LostDamagedPalletsBySupplierModalContent.module.css';

type LostDamagedPalletsBySupplierModalContentProps = {
   isSupplier?: boolean;
   orderId?: string;
   availablePalletTypes: Option[];
   remainingPalletsToBeSend: PalletAmount[];
   clientId?: string;
   onReportDamagedPallets: (groupedLostDamagedPalletItems: GroupedLostDamagedPalletItems) => void;
   onSuccessReportLostDestroyedPallets: (reports: PalletReportDTO[]) => void;
   onGoToSelectDamageType: () => void;
};

const LostDamagedPalletsBySupplierModalContent = ({
   orderId,
   availablePalletTypes,
   remainingPalletsToBeSend,
   clientId,
   onReportDamagedPallets,
   onSuccessReportLostDestroyedPallets,
   onGoToSelectDamageType,
}: LostDamagedPalletsBySupplierModalContentProps) => {
   const handleReportLostDamagedPallets = () => {
      const foundExcessPallets = getExcessPallets(
         lostDamagedPalletsItems,
         remainingPalletsToBeSend,
      );
      if (isEmpty(foundExcessPallets)) {
         const damagedPalletsItems = lostDamagedPalletsItems.filter(
            lostDamagedPalletsItem => lostDamagedPalletsItem.damageType === 'DAMAGE',
         );
         if (damagedPalletsItems.length) {
            const lostDestroyedPalletsItems = lostDamagedPalletsItems.filter(
               lostDamagedPalletsItem =>
                  lostDamagedPalletsItem.damageType === 'LOST_OR_DESTRUCTION',
            );
            onReportDamagedPallets({
               damaged: damagedPalletsItems,
               lostOrDestroyed: lostDestroyedPalletsItems,
            });
         } else {
            handleReportLostDestroyedPallets();
         }
      } else {
         setExcessPallets(foundExcessPallets);
      }
   };

   const [loading, setLoading] = useState(false);
   const [reportLostDestroyedPalletsError, setReportLostDestroyedPalletsError] = useState('');
   const [showGoBackWarning, setShowGoBackWarning] = useState(false);
   const [excessPallets, setExcessPallets] = useState<ExcessPallets | null>(null);
   const {
      rows: lostDamagedPalletsItems,
      errors: lostDamagedPalletsItemsErrors,
      touched: lostDamagedPalletsItemsTouched,
      addRow: handleAddLostDamagedPalletsItem,
      removeRow: handleRemoveLostDamagedPalletsItem,
      handleChangeValue: handleChangeLostDamagedPalletsItem,
      handleSubmitForm: handleSubmitLostDamagedPalletsTableForm,
   } = useTableForm<LostDamagedPalletsBySupplierItem>({
      initialState: [LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM()],
      onSubmit: handleReportLostDamagedPallets,
      validations: lostDamagedPalletsFormValidations,
   });
   const { isTablet } = useResponsiveLayout();

   const handleReturnToSelectDamageType = () => {
      const dataAdded = lostDamagedPalletsItemsTouched.some(lostDamagedPalletsItemTouched =>
         Object.values(lostDamagedPalletsItemTouched).some(fieldTouched => fieldTouched),
      );
      dataAdded ? setShowGoBackWarning(true) : onGoToSelectDamageType();
   };

   const reportPallets = (palletReport: PalletReportRequest) => {
      if (orderId) {
         return HTTPService.reportPalletsFromOrder(orderId, palletReport).then(
            ({ data: responseData }) => onSuccessReportLostDestroyedPallets([responseData]),
         );
      }
      if (!clientId) {
         return HTTPService.reportPalletsFromMultipleOrders(palletReport).then(
            ({ data: responseData }) => onSuccessReportLostDestroyedPallets(responseData.reports),
         );
      }
      return HTTPService.reportPalletsFromMultipleOrdersByAdmin(clientId, palletReport).then(
         ({ data: responseData }) => onSuccessReportLostDestroyedPallets(responseData.reports),
      );
   };

   const handleReportLostDestroyedPallets = () => {
      setLoading(true);
      setReportLostDestroyedPalletsError('');
      const palletReport = {
         receivedDamaged: true,
         pallets: lostDamagedPalletsItems.map(item => ({
            type: item.palletType.value as PalletType,
            reportType: item.damageType,
            amount: parseInt(item.palletsAmount, 10),
            images: item.images,
         })),
         sendPallets: [],
      };
      reportPallets(palletReport)
         .catch(error => {
            if (
               error instanceof AxiosError &&
               error.response?.data['code'] === INVALID_IMAGE_FILE
            ) {
               setReportLostDestroyedPalletsError('Przekroczony limit dla zdjęcia (maks. 5 MB)');
            } else {
               setReportLostDestroyedPalletsError('Podczas wysyłania zgłoszenia wystąpił błąd');
            }
            logNetworkError(error);
         })
         .finally(() => setLoading(false));
   };

   const handleCloseNotFoundOrderAlertModal = () => setExcessPallets(null);

   const commonErrors = useMemo(() => {
      const tableAllErrors: string[] = [];
      lostDamagedPalletsItemsErrors.forEach((lostDamagedPalletsItemErrors, rowTableIndex) => {
         Object.entries(lostDamagedPalletsItemErrors).forEach(([key, value]) => {
            value &&
               lostDamagedPalletsItemsTouched[rowTableIndex][key] &&
               tableAllErrors.push(value as string);
         });
      });
      return new Set(tableAllErrors);
   }, [lostDamagedPalletsItemsErrors, lostDamagedPalletsItemsTouched]);

   return (
      <>
         <GoBackWarningModal
            opened={showGoBackWarning}
            onConfirm={onGoToSelectDamageType}
            onClose={() => setShowGoBackWarning(false)}
         />
         <NotFoundOrderAlertModal
            opened={!!excessPallets}
            onClose={handleCloseNotFoundOrderAlertModal}
            modalType="REPORT_DAMAGE_PALLETS"
            oneOrderAction={!!orderId}
            excessPallets={excessPallets}
            returnBtnText="Wróć do zglaszania palet"
            onReturn={handleCloseNotFoundOrderAlertModal}
         />
         {loading ? (
            <div className={styles.loader}>
               <Loader color={'var(--light-green)'} />
            </div>
         ) : (
            <>
               <div className={styles.lostDamagedPalletsContainer}>
                  {!isTablet ? (
                     <LostDamagedPalletsTable
                        damageType="LOST_DAMAGED_BY_SUPPLIER"
                        lostDamagedPalletsItems={lostDamagedPalletsItems}
                        lostDamagedPalletsItemsTouched={lostDamagedPalletsItemsTouched}
                        lostDamagedPalletsItemsErrors={lostDamagedPalletsItemsErrors}
                        availablePalletTypes={availablePalletTypes}
                        onAddLostDamagedPalletsItem={handleAddLostDamagedPalletsItem}
                        onRemoveLostDamagedPalletsItem={handleRemoveLostDamagedPalletsItem}
                        onChangeLostDamagedPalletsItem={handleChangeLostDamagedPalletsItem}
                     />
                  ) : (
                     <>
                        <Banner
                           variant="info"
                           children="Możesz dodać zdjęcia palet tylko z przeglądarki na komputerze."
                           fullWidth
                           className={classNames(styles.infoBanner, {
                              [styles.additionalBannerMargin]:
                                 !!!commonErrors.size && !reportLostDestroyedPalletsError,
                           })}
                        />
                        <LostDamagedPalletsMobileList
                           damageType="LOST_DAMAGED_BY_SUPPLIER"
                           lostDamagedPalletsItems={lostDamagedPalletsItems}
                           lostDamagedPalletsItemsTouched={lostDamagedPalletsItemsTouched}
                           lostDamagedPalletsItemsErrors={lostDamagedPalletsItemsErrors}
                           availablePalletTypes={availablePalletTypes}
                           onAddLostDamagedPalletsItem={handleAddLostDamagedPalletsItem}
                           onRemoveLostDamagedPalletsItem={handleRemoveLostDamagedPalletsItem}
                           onChangeLostDamagedPalletsItem={handleChangeLostDamagedPalletsItem}
                        />
                     </>
                  )}
                  {(!!commonErrors.size || reportLostDestroyedPalletsError) && (
                     <div className={styles.bannersContainer}>
                        {reportLostDestroyedPalletsError && (
                           <Banner
                              variant="error"
                              children={reportLostDestroyedPalletsError}
                              fullWidth
                              className={styles.errorBanner}
                           />
                        )}
                        {!!commonErrors.size &&
                           Array.from(commonErrors).map(commonError => (
                              <Banner
                                 key={commonError}
                                 variant={'error'}
                                 children={commonError}
                                 fullWidth
                                 className={styles.errorBanner}
                              />
                           ))}
                     </div>
                  )}
               </div>
               <LostDamagedPalletsStepFooter
                  onNextClick={handleSubmitLostDamagedPalletsTableForm}
                  onPrevClick={handleReturnToSelectDamageType}
                  nextButtonText="Następny krok"
                  className={classNames({ [styles.errorStepFooter]: !!commonErrors.size })}
               />
            </>
         )}
      </>
   );
};

export default LostDamagedPalletsBySupplierModalContent;
