import { StyledTable } from 'components/shared';
import { clientPalletBalanceTableHeaders } from 'constants/tableHeaders';
import { ClientWithBalanceDTO } from 'interfaces';

import ClientPalletBalanceTableRow from './ClientPalletBalanceTableRow/ClientPalletBalanceTableRow';
import styles from './ClientPalletBalanceTable.module.css';

type ClientPalletBalanceTableProps = {
   clientPalletBalances: ClientWithBalanceDTO[];
};

const ClientPalletBalanceTable = ({ clientPalletBalances }: ClientPalletBalanceTableProps) => (
   <StyledTable
      columnHeaders={clientPalletBalanceTableHeaders}
      headerClassName={styles.tableHeader}
      isFixedHeader
   >
      {clientPalletBalances.map(clientPalletBalance => (
         <ClientPalletBalanceTableRow
            key={clientPalletBalance.id}
            clientPalletBalance={clientPalletBalance}
         />
      ))}
   </StyledTable>
);

export default ClientPalletBalanceTable;
