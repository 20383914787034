import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';

import {
   DATE_FORMAT,
   DATE_FORMAT_WITH_SHORT_MONTH_SHORT_DAY_SHORT_YEAR,
} from 'constants/dateFormats';
import { SendPalletsExcelRow } from 'service/excel/common';
import { parseDate } from 'utils/functions';

import {
   TARCZYNSKI_CLIENT_COMMERCIAL_NETWORK_DIFFERENT_ADDRESSES,
   TARCZYNSKI_PALLET_TYPES,
} from '../constants';
import { TarczynskiClientExcelRow } from '../types';

export const tarczynskiClientExcelRowToSendPalletsExcelRowMapper: (
   tarczynskiClientExcelRowData: TarczynskiClientExcelRow,
) => SendPalletsExcelRow = (tarczynskiClientExcelRowData: TarczynskiClientExcelRow) => {
   const parsedSendDate = parseDate(
      tarczynskiClientExcelRowData['Data wysyłki palet do sieci'],
      DATE_FORMAT_WITH_SHORT_MONTH_SHORT_DAY_SHORT_YEAR,
   );
   const formattedSendDate = DateTime.fromJSDate(parsedSendDate).toFormat(DATE_FORMAT);
   const commercialNetworkName = capitalize(
      tarczynskiClientExcelRowData['Nazwa sieci'].split(' ')[0],
   );
   const commercialNetworkAddress =
      TARCZYNSKI_CLIENT_COMMERCIAL_NETWORK_DIFFERENT_ADDRESSES[
         tarczynskiClientExcelRowData['Miejsce wysłanych palet']
      ] || tarczynskiClientExcelRowData['Miejsce wysłanych palet'];

   return {
      sendDate: formattedSendDate,
      externalCommercialNetworkNumber: tarczynskiClientExcelRowData['Nr zamówienia sieci'],
      commercialNetworkName,
      commercialNetworkAddress,
      palletType: TARCZYNSKI_PALLET_TYPES[tarczynskiClientExcelRowData['Rodzaj palety wysłanej']],
      palletsAmount: tarczynskiClientExcelRowData['Ilość wysłanych palet do sieci'],
   };
};
