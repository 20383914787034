import {
   EMPTY_INPUT_ERROR,
   INVALID_EMAIL_ERROR,
   INVALID_NIP_ERROR,
   INVALID_ZIP_CODE_ERROR,
   NO_UNIQUE_ADDRESS_NAME_ERROR,
} from 'constants/validationErrors';
import { ClientDataFormProps } from 'interfaces';
import { isEmail, isNonEmpty, isValidNIP, isZipCode } from 'utils/validation';

export const clientDataFormValidations = [
   ({ companyName }: ClientDataFormProps) => {
      if (!companyName) {
         return { companyName: EMPTY_INPUT_ERROR };
      }
   },
   ({ nip }: ClientDataFormProps) => {
      if (!isValidNIP(nip.replaceAll('-', ''))) {
         return { nip: INVALID_NIP_ERROR };
      }
   },
   ({ address }: ClientDataFormProps) => {
      if (!address) {
         return { address: EMPTY_INPUT_ERROR };
      }
   },
   ({ zipCode }: ClientDataFormProps) => {
      if (!isZipCode(zipCode)) {
         return { zipCode: INVALID_ZIP_CODE_ERROR };
      }
   },
   ({ city }: ClientDataFormProps) => {
      if (!city) {
         return { city: EMPTY_INPUT_ERROR };
      }
   },
   ({ phoneNumber }: ClientDataFormProps) => {
      if (!isNonEmpty(phoneNumber)) {
         return { phoneNumber: EMPTY_INPUT_ERROR };
      }
   },
   ({ email }: ClientDataFormProps) => {
      if (!isEmail(email)) {
         return { email: INVALID_EMAIL_ERROR };
      }
   },
   ({ invoiceEmail }: ClientDataFormProps) => {
      if (!isEmail(invoiceEmail)) {
         return { invoiceEmail: INVALID_EMAIL_ERROR };
      }
   },
   ({ deliveryAddresses }: ClientDataFormProps) => {
      const allDeliveryAddressesNames = deliveryAddresses.map(address => address.name);
      const deliveryAddressesErrors = deliveryAddresses.map(address => {
         const isUniqueAddressName =
            allDeliveryAddressesNames.filter(
               deliveryAddressName => deliveryAddressName === address.name,
            ).length === 1;

         return {
            ...(!address.address && { address: EMPTY_INPUT_ERROR }),
            ...(!address.city && { city: EMPTY_INPUT_ERROR }),
            ...(!isZipCode(address.zipCode) && { zipCode: INVALID_ZIP_CODE_ERROR }),
            ...(!address.name && { name: EMPTY_INPUT_ERROR }),
            ...(address.name && !isUniqueAddressName && { name: NO_UNIQUE_ADDRESS_NAME_ERROR }),
         };
      });
      return {
         ...(deliveryAddressesErrors.some(address => Object.keys(address).length !== 0) && {
            deliveryAddresses: deliveryAddressesErrors,
         }),
      };
   },
];
