import {
   AddressDeliveryRatePerPalletConfig,
   PalletsPricingPerAddressFormDataItem,
} from 'interfaces';
import { formatNumberToPrice } from 'utils/functions';

export const parseAddressDeliveryRatePerPalletConfigIntoPalletsPricingPerPalletItem: (
   addressDeliveryRatePerPalletConfig: AddressDeliveryRatePerPalletConfig,
) => PalletsPricingPerAddressFormDataItem = addressDeliveryRatePerPalletConfig => {
   const { palletType, deliveryPrice } = addressDeliveryRatePerPalletConfig;
   return {
      palletType,
      deliveryPrice: formatNumberToPrice(deliveryPrice),
   };
};
