import { NO_SELECTED_ORDERS_ERROR } from 'constants/validationErrors';
import { AddAdditionalOrdersFormValues } from 'interfaces';

export const addAdditionalOrdersFormValidation = [
   ({ selectedAdditionalOrderIds }: AddAdditionalOrdersFormValues) => {
      return (
         !selectedAdditionalOrderIds.length && {
            selectedAdditionalOrderIds: NO_SELECTED_ORDERS_ERROR,
         }
      );
   },
];
