import { Trash } from 'tabler-icons-react';

import { StyledButton, StyledSelect, StyledTextInput } from 'components/shared';
import { Option } from 'interfaces';

import styles from './PalletInputGroup.module.css';

type PalletInputGroupProps = {
   uuid: string;
   palletType: Option;
   palletsAmount: string;
   withRemoveButton: boolean;
   palletTypeError: boolean;
   palletsAmountError: boolean;
   availablePalletTypes: Option[];
   onRemove: () => void;
   onChange: (field: string, value: string | Option) => void;
};

const PalletInputGroup = ({
   uuid,
   palletType,
   palletsAmount,
   withRemoveButton,
   palletTypeError,
   palletsAmountError,
   availablePalletTypes,
   onRemove,
   onChange,
}: PalletInputGroupProps) => (
   <div key={uuid} className={styles.container}>
      {withRemoveButton && (
         <div className={styles.removeBtnContainer}>
            <StyledButton type="button" onClick={onRemove} icon={<Trash />} variant="text" />
         </div>
      )}
      <div className={styles.inputsContainer}>
         <StyledSelect
            options={availablePalletTypes}
            onChange={(value: Option) => onChange('palletType', value)}
            value={palletType}
            label="Typ palety"
            error={palletTypeError}
            mobileView
            classNames={{
               container: styles.selectPalletTypeContainer,
               select: styles.selectPalletType,
            }}
         />
         <StyledTextInput
            type="number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
               onChange('palletsAmount', event.target.value)
            }
            value={palletsAmount}
            label="Ilość palet"
            error={palletsAmountError}
            className={styles.inputPalletsAmount}
         />
      </div>
   </div>
);

export default PalletInputGroup;
