import { PalletSpecification } from 'interfaces';
import { PalletType } from 'interfaces';

import {
   EDHP_PALLET_LOGISTIC_MINIMUM,
   EURO_PALLET_LOGISTIC_MINIMUM,
   HALF_PALLET_NDHP_LOGISTIC_MINIMUM,
   QUARTER_PALLET_CDHP_LOGISTIC_MINIMUM_LOWER_RANGE,
   QUARTER_PALLET_CDHP_LOGISTIC_MINIMUM_UPPER_RANGE,
} from './palletLogisticMinimums';

export const palletTypesData: PalletSpecification[] = [
   {
      name: 'EDHP_PALLET',
      label: 'Paleta EDHP',
      logisticMinimum: EDHP_PALLET_LOGISTIC_MINIMUM,
      dimensions: '800x1200 mm',
   },
   {
      name: 'EURO_PALLET',
      label: 'Euro Paleta',
      logisticMinimum: EURO_PALLET_LOGISTIC_MINIMUM,
      dimensions: '800x1200 mm',
   },
   {
      name: 'HALF_PALLET_NDHP',
      label: 'Półpaleta NDHP',
      logisticMinimum: HALF_PALLET_NDHP_LOGISTIC_MINIMUM,
      dimensions: '600x800 mm',
   },
   {
      name: 'QUARTER_PALLET_CDHP',
      label: 'Ćwierćpaleta CDHP',
      logisticMinimum: QUARTER_PALLET_CDHP_LOGISTIC_MINIMUM_LOWER_RANGE,
      logisticMinimumUpperRange: QUARTER_PALLET_CDHP_LOGISTIC_MINIMUM_UPPER_RANGE,
      dimensions: '400x600 mm',
   },
];

export const PALLET_TYPE_LABELS: { [key: string]: string } = {
   EDHP_PALLET: 'Paleta EDHP 800x1200 mm',
   EURO_PALLET: 'Euro Paleta 800x1200 mm',
   HALF_PALLET_NDHP: 'Półpaleta NDHP 600x800 mm',
   QUARTER_PALLET_CDHP: 'Ćwierćpaleta CDHP 400x600 mm',
};

export const PALLET_TYPES: Record<PalletType, PalletType> = {
   EDHP_PALLET: 'EDHP_PALLET',
   EURO_PALLET: 'EURO_PALLET',
   HALF_PALLET_NDHP: 'HALF_PALLET_NDHP',
   QUARTER_PALLET_CDHP: 'QUARTER_PALLET_CDHP',
} as const;

export const PALLET_TYPES_LABELS_WITH_DIMENSIONS = {
   EDHP_PALLET: { label: 'Paleta EDHP', dimensions: '800x1200 mm' },
   EURO_PALLET: { label: 'Euro Paleta', dimensions: '800x1200 mm' },
   HALF_PALLET_NDHP: { label: 'Półpaleta NDHP', dimensions: '600x800 mm' },
   QUARTER_PALLET_CDHP: { label: 'Ćwierćpaleta CDHP', dimensions: '400x600 mm' },
};

export const palletTypes: PalletType[] = [
   'EDHP_PALLET',
   'EURO_PALLET',
   'HALF_PALLET_NDHP',
   'QUARTER_PALLET_CDHP',
];
