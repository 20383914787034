import { FocusEvent } from 'react';

import classNames from 'classnames';
import { Trash } from 'tabler-icons-react';

import {
   StyledAutocomplete,
   StyledButton,
   StyledRadioButton,
   StyledSelect,
   StyledTextInput,
} from 'components/shared';
import {
   AdditionalCharge,
   AdditionalChargeErrors,
   AdditionalChargeTouched,
   AdditionalChargeType,
} from 'interfaces/addAdditionalChargesForm';
import { Option } from 'interfaces/commonInterfacesAndTypes';
import { Banner } from 'storybook';
import { formatStringToPrice } from 'utils/functions';
import { isNumeric } from 'utils/validation';

import styles from './AdditionalChargeCard.module.css';

type AdditionalChargeCardProps = {
   additionalCharge: AdditionalCharge;
   additionalChargeErrors: AdditionalChargeErrors;
   additionalChargeTouched: AdditionalChargeTouched;
   onRemove?: (uuid: string) => void;
   onChange: (
      uuid: string,
      chargeType: AdditionalChargeType,
      field: string,
      value: string | Option,
   ) => void;
   onChangeChargeType: (uuid: string, type: AdditionalChargeType) => void;
   palletTypes: Option[];
   palletSendingDestinationAddresses: {
      [palletType: string]: Option[];
   } | null;
};

const AdditionalChargeCard = ({
   additionalCharge,
   additionalChargeErrors,
   additionalChargeTouched,
   onRemove,
   onChange,
   onChangeChargeType,
   palletTypes,
   palletSendingDestinationAddresses,
}: AdditionalChargeCardProps) => {
   const { uuid, chargeType, palletType, address, palletsAmount, amount, otherChargeName } =
      additionalCharge;

   const handleAdditionalChargeAmountBlur = (event: FocusEvent<HTMLInputElement>) => {
      const enteredValue = event.target.value;
      const isNumericValue = isNumeric(enteredValue);
      if (!isNumericValue || enteredValue === '') {
         return;
      }
      const formattedValue = formatStringToPrice(enteredValue);
      onChange(uuid, chargeType!, 'amount', formattedValue);
   };

   const renderFormInputs = (selectedChargeType: AdditionalChargeType) => {
      switch (selectedChargeType) {
         case 'LOST_PALLET':
         case 'DAMAGE':
            return (
               <div>
                  <StyledSelect
                     label="Typ palety"
                     options={palletTypes}
                     onChange={(value: Option) =>
                        onChange(uuid, selectedChargeType, 'palletType', value)
                     }
                     value={palletType || { value: '', label: '' }}
                     error={
                        additionalChargeTouched.palletType && !!additionalChargeErrors.palletType
                     }
                     fullWidth
                     classNames={{ container: styles.formField }}
                  />
                  <StyledTextInput
                     type="number"
                     label="Ilość palet"
                     value={palletsAmount || ''}
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(uuid, selectedChargeType, 'palletsAmount', event.target.value)
                     }
                     error={
                        additionalChargeTouched.palletsAmount &&
                        !!additionalChargeErrors.palletsAmount
                     }
                     className={classNames(styles.palletsAmountInput, styles.formField)}
                  />
                  <StyledAutocomplete
                     label="Miejsce, z którego odebrano palety"
                     options={palletSendingDestinationAddresses?.[palletType?.value] || []}
                     value={address || { value: '', label: '' }}
                     onSelect={(value: Option) =>
                        onChange(uuid, selectedChargeType, 'address', value)
                     }
                     onlyTextMatch
                     disabled={!palletType}
                     error={additionalChargeTouched.address && !!additionalChargeErrors.address}
                     fullWidth
                     classNames={{ container: styles.formField }}
                     portalTarget={document.body}
                  />
                  <Banner
                     variant="info"
                     children="Cena zostanie naliczona według cennika w umowie."
                     fullWidth
                  />
               </div>
            );
         case 'OTHER':
            return (
               <div>
                  <StyledTextInput
                     type="text"
                     label="Rodzaj obciążenia"
                     value={otherChargeName || ''}
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(uuid, selectedChargeType, 'otherChargeName', event.target.value)
                     }
                     error={
                        additionalChargeTouched.otherChargeName &&
                        !!additionalChargeErrors.otherChargeName
                     }
                     fullWidth
                     className={styles.formField}
                  />
                  <StyledTextInput
                     type="text"
                     label="Kwota obciążenia (PLN)"
                     value={amount || ''}
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(uuid, selectedChargeType, 'amount', event.target.value)
                     }
                     onBlur={handleAdditionalChargeAmountBlur}
                     error={additionalChargeTouched.amount && !!additionalChargeErrors.amount}
                     className={classNames(styles.amountInput, styles.formField)}
                  />
               </div>
            );
      }
   };

   return (
      <div className={styles.cardContainer}>
         {onRemove && (
            <div className={styles.removeBtnContainer}>
               <StyledButton
                  type="button"
                  onClick={() => onRemove(uuid)}
                  icon={<Trash color="#3E3E4B" />}
                  variant="text"
               />
            </div>
         )}
         <div className={styles.chargeTypeRadioButtonsGroup}>
            <p>Rodzaj obciążenia</p>
            <StyledRadioButton
               label="Zgubienie lub zniszczenie"
               checked={chargeType === 'LOST_PALLET'}
               onChangeHandler={() => onChangeChargeType(uuid, 'LOST_PALLET')}
               error={additionalChargeTouched.chargeType && !!additionalChargeErrors.chargeType}
               className={styles.chargeTypeRadioBtn}
            />
            <StyledRadioButton
               label="Uszkodzenie"
               checked={chargeType === 'DAMAGE'}
               onChangeHandler={() => onChangeChargeType(uuid, 'DAMAGE')}
               error={additionalChargeTouched.chargeType && !!additionalChargeErrors.chargeType}
               className={styles.chargeTypeRadioBtn}
            />
            <StyledRadioButton
               label="Inne"
               checked={chargeType === 'OTHER'}
               onChangeHandler={() => onChangeChargeType(uuid, 'OTHER')}
               error={additionalChargeTouched.chargeType && !!additionalChargeErrors.chargeType}
               className={styles.chargeTypeRadioBtn}
            />
         </div>
         {chargeType && renderFormInputs(chargeType)}
      </div>
   );
};

export default AdditionalChargeCard;
