import { NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } from 'constants/validationErrors';

import { moreOrEqualZero } from './validationFunctions';

export const editUserBalance = {
   rented: (rented?: string) =>
      rented !== undefined && !moreOrEqualZero(rented) && NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   lostOrDestroyedByUserFault: (lostOrDestroyedByUserFault?: string) =>
      lostOrDestroyedByUserFault !== undefined &&
      !moreOrEqualZero(lostOrDestroyedByUserFault) &&
      NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   damagedByUserFault: (damagedByUserFault?: string) =>
      damagedByUserFault !== undefined &&
      !moreOrEqualZero(damagedByUserFault) &&
      NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   receivedDamaged: (receivedDamaged?: string) =>
      receivedDamaged !== undefined &&
      !moreOrEqualZero(receivedDamaged) &&
      NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
};
