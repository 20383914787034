import { EMPTY_VALUE_LABEL } from 'constants/commonLabels';
import { CommercialNetwork, DistributionCenter } from 'interfaces';

import styles from './DistributionCenterData.module.css';

type DistributionCenterDataProps = {
   distributionCenter: DistributionCenter;
   commercialNetwork: CommercialNetwork;
};

const DistributionCenterData = ({
   distributionCenter,
   commercialNetwork,
}: DistributionCenterDataProps) => (
   <div className={styles.container}>
      <div className={styles.column}>
         <div className={styles.row}>
            <p className={styles.sectionLabel}>Sieć Handlowa</p>
            <p className={styles.sectionText}>{commercialNetwork.name}</p>
         </div>
         <div className={styles.row}>
            <p>Ulica, numer domu / numer mieszkania</p>
            <p>{distributionCenter.address.street}</p>
         </div>
         <div className={styles.multiRow}>
            <div className={styles.row}>
               <p className={styles.sectionLabel}>Kod pocztowy</p>
               <p>{distributionCenter.address.zipCode}</p>
            </div>
            <div className={styles.row}>
               <p className={styles.sectionLabel}>Miejscowość</p>
               <p>{distributionCenter.address.city}</p>
            </div>
         </div>
      </div>
      <div className={styles.column}>
         <div className={styles.row}>
            <p className={styles.sectionLabel}>Telefon</p>
            <p>{distributionCenter.phoneNumber || EMPTY_VALUE_LABEL}</p>
         </div>
         <div className={styles.row}>
            <p className={styles.sectionLabel}>E-mail</p>
            <p>{distributionCenter.contactEmail || EMPTY_VALUE_LABEL}</p>
         </div>
      </div>
   </div>
);

export default DistributionCenterData;
