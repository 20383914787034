import { StyledBadge } from 'components/shared';
import { ORDER_STATUSES_BADGE_DATA } from 'constants/orderStatuses';
import { OrderStatus } from 'interfaces';
import { getOrderIdLabel } from 'utils/functions';

import styles from './OrderDetailsHeader.module.css';

type OrderDetailsHeaderProps = {
   orderData: OrderDetailsHeaderOrderData;
};

export type OrderDetailsHeaderOrderData = {
   id: string;
   status?: OrderStatus;
   createdDate: string;
};

const OrderDetailsHeader = ({ orderData }: OrderDetailsHeaderProps) => {
   const { id, status, createdDate } = orderData;

   return (
      <div className={styles.headerContainer}>
         <div className={styles.headerLeft}>
            <div className={styles.headerLeftInnerContainer}>
               <h2>Zamówienie {getOrderIdLabel(id)}</h2>
               {status && (
                  <StyledBadge
                     text={ORDER_STATUSES_BADGE_DATA[status].label}
                     variant={ORDER_STATUSES_BADGE_DATA[status].variant}
                  />
               )}
            </div>
            <p>Data złożenia zamówienia: {new Date(createdDate).toLocaleDateString('en-GB')}</p>
         </div>
      </div>
   );
};

export default OrderDetailsHeader;
