import { Link } from 'react-router-dom';

import { Eye } from 'tabler-icons-react';

import { StyledButton } from '..';

import styles from './DetailsLink.module.css';

type DetailsLinkProps = {
   to: string;
};

const DetailsLink = ({ to }: DetailsLinkProps) => (
   <div className={styles.container}>
      <Link to={to}>
         <StyledButton
            type="button"
            text="Szczegóły"
            icon={<Eye />}
            variant="text"
            className={styles.button}
         />
      </Link>
   </div>
);

export default DetailsLink;
