import { useCallback, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';

import DistributionCenterTable from 'components/DistributionCenterTable/DistributionCenterTable';
import { CustomModal, CustomModalProps, StyledSelect } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { STORAGE_TYPES_FOR_ADMISSION_PICKER } from 'constants/storageType';
import { useModal, useTableForm } from 'hooks';
import { BalancePickUpDTO, Option, StorageDTO, TransferPalletBalance } from 'interfaces';
import { HTTPService, TransferPalletsDistributionCenter } from 'service';
import { errorHandler } from 'utils/errorHandler';
import { checkIfDefinedSomePalletsToPickUp } from 'utils/functions';
import { getStoragesAddressOptions } from 'utils/functions/getStoragesAddressOptions';
import { parseBalancePickUpDTOIntoTransferPalletBalance } from 'utils/parsers';
import { transferPalletsValidations } from 'utils/validation/transferPalletsDistributionCenter';

import styles from './SupplierConfirmPalletsPickUpModal.module.css';

type SupplierConfirmPalletsPickUpModalProps = Omit<CustomModalProps, 'opened'> & {
   selectedPalletsPickUpId: string;
   palletBalances: BalancePickUpDTO[];
   onConfirmPalletsPickUpSuccess: (palletsPickUpId: string) => void;
};

const SupplierConfirmPalletsPickUpModal = ({
   selectedPalletsPickUpId,
   palletBalances,
   onConfirmPalletsPickUpSuccess,
   onClose,
   ...props
}: SupplierConfirmPalletsPickUpModalProps) => {
   const [storages, setStorages] = useState<StorageDTO[]>([]);
   const [selectedStorageType, setSelectedStorageType] = useState<Option>();
   const [selectedStorage, setSelectedStorage] = useState<Option | null>(null);

   const handleCloseModal = useCallback(() => {
      onClose();
   }, [onClose]);

   const {
      isLoading,
      errorInfo,
      setIsLoading,
      setErrorInfo,
      showCloseWarning,
      handleShowCloseWarning,
      handleHideCloseWarning,
      closeModalHandler,
   } = useModal({
      onClose: handleCloseModal,
   });

   const handleFetchDataError = useCallback(
      (error: unknown) =>
         errorHandler(error, () => setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA)),
      [setErrorInfo],
   );

   const fetchStorages = useCallback(async () => {
      try {
         setIsLoading(true);
         const storagesResponse = await HTTPService.getStorageList({ size: PAGINATION_MAX_SIZE });
         setStorages(storagesResponse.data.content);
      } catch (error) {
         handleFetchDataError(error);
      } finally {
         setIsLoading(false);
      }
   }, [handleFetchDataError, setIsLoading]);

   useEffect(() => {
      fetchStorages();
   }, [fetchStorages]);

   const handleSubmit = async (transferPalletBalanceDataItems: TransferPalletBalance[]) => {
      try {
         setIsLoading(true);
         const parsedPalletBalances: TransferPalletsDistributionCenter[] =
            transferPalletBalanceDataItems.map(balance => ({
               palletType: balance.palletName,
               balance: {
                  valid: Number(balance.validAvailablePallets),
                  invalid: Number(balance.invalidAvailablePallets),
               },
            }));
         await HTTPService.acceptPalletPickUp(selectedPalletsPickUpId, {
            storageId: selectedStorage?.value as string,
            palletBalances: parsedPalletBalances,
         });
         onConfirmPalletsPickUpSuccess(selectedPalletsPickUpId);
      } catch (error) {
         errorHandler(error, () => setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION));
      } finally {
         setIsLoading(false);
      }
   };

   const initialPalletBalances = useMemo(
      () => palletBalances.map(parseBalancePickUpDTOIntoTransferPalletBalance),
      [palletBalances],
   );

   const {
      rows,
      errors,
      touched,
      handleChangeValue,
      handleSubmitForm,
      hasErrors,
      commonErrorsOfTouchedInputs,
   } = useTableForm<TransferPalletBalance>({
      initialState: cloneDeep(initialPalletBalances),
      onSubmit: handleSubmit,
      validations: transferPalletsValidations,
   });

   const handleChangeStorageType = (value: Option) => {
      setSelectedStorageType(value);
      setSelectedStorage(null);
   };

   const areDefinedSomePalletsToPickUp = useMemo(
      () => checkIfDefinedSomePalletsToPickUp(rows),
      [rows],
   );

   const isInvalidForm = hasErrors || !areDefinedSomePalletsToPickUp;

   return (
      <>
         <CustomModal
            {...props}
            opened
            title="Potwierdź odbiór palet"
            isLoading={isLoading}
            error={errorInfo}
            size={800}
            className={styles.container}
            modalContentClassName={styles.modalContent}
            primaryButtonProps={{
               text: 'Potwierdź odbiór',
               disabled: isInvalidForm || !selectedStorage,
            }}
            secondaryButtonProps={{
               text: 'Anuluj',
               onClick: handleShowCloseWarning,
            }}
            closeWarningModalProps={{
               opened: showCloseWarning,
               onClose: handleHideCloseWarning,
               onConfirm: closeModalHandler,
            }}
            onClose={handleShowCloseWarning}
            onSubmit={handleSubmitForm}
         >
            <>
               <div className={styles.selectStorageRow}>
                  <StyledSelect
                     label="Wybierz typ magazynu"
                     value={selectedStorageType}
                     options={STORAGE_TYPES_FOR_ADMISSION_PICKER}
                     onChange={handleChangeStorageType}
                  />
                  <StyledSelect
                     label="Wybierz magazyn"
                     value={selectedStorage || undefined}
                     disabled={!selectedStorageType}
                     options={getStoragesAddressOptions(storages, selectedStorageType)}
                     onChange={setSelectedStorage}
                  />
               </div>
               <DistributionCenterTable
                  mode="transfer"
                  rows={rows}
                  errors={errors}
                  touched={touched}
                  initialValues={initialPalletBalances}
                  isVisibleErrorBanner={!!commonErrorsOfTouchedInputs.size}
                  handleChangeValue={handleChangeValue}
               />
            </>
         </CustomModal>
      </>
   );
};

export default SupplierConfirmPalletsPickUpModal;
