import { AlertTriangle } from 'tabler-icons-react';

import { LostDamagedPalletsByClientItem } from 'interfaces';

import AdditionalFeeSection from './AdditionalFeeSection/AdditionalFeeSection';
import styles from './AdditionalFeeWarning.module.css';

type AdditionalFeeWarningProps = {
   isSupplier?: boolean;
   pricesForPallets?: LostDamagedPalletsByClientItem[];
};

const REPORT_TYPE_LABELS = {
   LOST_OR_DESTRUCTION: 'zgubienie/zniszczenie',
   DAMAGE: 'uszkodzenie',
};

const AdditionalFeeWarning = ({ isSupplier, pricesForPallets }: AdditionalFeeWarningProps) => {
   const damagedIssue = pricesForPallets?.some(el => el.damageType === 'DAMAGE');
   const reportContent = damagedIssue
      ? REPORT_TYPE_LABELS.DAMAGE
      : REPORT_TYPE_LABELS.LOST_OR_DESTRUCTION;
   const totalAmount = pricesForPallets?.reduce(
      (acc, currentValue) => acc + currentValue.reportPrice! * Number(currentValue.palletsAmount),
      0,
   );

   const notificationCheck = () => {
      const someDamaged = pricesForPallets!.some(el => el['damageType'] === 'DAMAGE');
      const haveLostOrDesctructioned = pricesForPallets!.some(
         el => el['damageType'] === 'LOST_OR_DESTRUCTION',
      );
      return !!(someDamaged && haveLostOrDesctructioned);
   };

   const multipleFeeBolean = notificationCheck();

   const damagedPallets = pricesForPallets?.filter(el => el.damageType === 'DAMAGE');
   const lostOrDestructionedPallets = pricesForPallets?.filter(
      el => el.damageType === 'LOST_OR_DESTRUCTION',
   );
   const priceDamaged = damagedPallets?.reduce(
      (acc, currentValue) => acc + currentValue.reportPrice! * Number(currentValue.palletsAmount),
      0,
   );
   const priceLostOrDestructioned = lostOrDestructionedPallets?.reduce(
      (acc, currentValue) => acc + currentValue.reportPrice! * Number(currentValue.palletsAmount),
      0,
   );

   const content = () => {
      if (isSupplier) {
         if (multipleFeeBolean) {
            return (
               <>
                  <AdditionalFeeSection
                     damageType={REPORT_TYPE_LABELS.LOST_OR_DESTRUCTION}
                     pricesForPallets={lostOrDestructionedPallets!}
                     sumPerCategory={priceLostOrDestructioned!}
                     totalAmount={totalAmount!}
                  />
                  <AdditionalFeeSection
                     damageType={REPORT_TYPE_LABELS.DAMAGE}
                     pricesForPallets={damagedPallets!}
                     sumPerCategory={priceDamaged!}
                     totalAmount={totalAmount!}
                     sumLine
                  />
               </>
            );
         } else {
            return (
               <AdditionalFeeSection
                  damageType={reportContent}
                  pricesForPallets={pricesForPallets!}
                  totalAmount={totalAmount!}
                  multipleLine
                  sumPerCategory={priceLostOrDestructioned! | priceDamaged!}
               />
            );
         }
      } else {
         return (
            <p>
               Dodatkowa opłata za <b>{reportContent}</b> palet zostanie doliczona do zamówienia.
            </p>
         );
      }
   };

   return (
      <div className={styles.mainContainer}>
         <AlertTriangle width="47" height="43" color={'var(--primary-red)'} />
         <h3>Uwaga!</h3>
         {content()}
      </div>
   );
};

export default AdditionalFeeWarning;
