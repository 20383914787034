import {
   ClientDeliveryAddress,
   DeliveryAddress,
   PalletsPricingPerAddressFormData,
} from 'interfaces';

import { parsePalletsPricingPerPalletFormDataItemIntoAddressDeliveryRatePerPalletConfig } from './parsePalletsPricingPerPalletFormDataItemIntoAddressDeliveryRatePerPalletConfig';

export const parsePalletsPricingPerAddressFormDataIntoClientDeliveryAddress: (
   palletsPricingPerAddressFormData: PalletsPricingPerAddressFormData,
   deliveryAddresses: DeliveryAddress[],
) => ClientDeliveryAddress = (palletsPricingPerAddressFormData, deliveryAddresses) => {
   const { addressName, palletsPricing } = palletsPricingPerAddressFormData;

   const address = deliveryAddresses.find(
      deliveryAddressItem => addressName === deliveryAddressItem.name,
   );
   const palletRates = palletsPricing.map(
      parsePalletsPricingPerPalletFormDataItemIntoAddressDeliveryRatePerPalletConfig,
   );

   return {
      address: address!,
      palletConfig: { palletRates },
   };
};
