import { CommercialNetworkAddressOptions, SendPalletsItem } from 'interfaces';

export const getCommercialNetworkAddressOptions = (
   sendPalletsItem: SendPalletsItem,
   commercialNetworkAddressOptions: CommercialNetworkAddressOptions,
) => {
   if (sendPalletsItem.commercialNetworkName) {
      const availableNetworkAddresses =
         commercialNetworkAddressOptions[sendPalletsItem.commercialNetworkName.value];
      if (sendPalletsItem.commercialNetworkAddress?.value) {
         const selectedNetworkAddress = sendPalletsItem.commercialNetworkAddress;
         return [selectedNetworkAddress, ...availableNetworkAddresses];
      } else {
         return availableNetworkAddresses;
      }
   }
   return [];
};
