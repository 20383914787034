import { FORGOT_PASSWORD, LOGIN, RESET_PASSWORD } from '../../constants/routes';
import { ForgotPasswordPage, LoginPage, ResetPasswordPage } from '../../pages/Auth';

export const authRoutes = {
   children: [
      { path: LOGIN, element: <LoginPage /> },
      { path: FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
      { path: RESET_PASSWORD, element: <ResetPasswordPage /> },
   ],
};
