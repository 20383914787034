import { vglClientSendPalletsDataItemToVGLClientPalletsItemMapper } from '../mappers';
import { VGLClientSendPalletsData } from '../types';

export const getVGLClientSendPalletsItemFromVGLClientSendPalletsDataItems = (
   sendPalletsDataItems: VGLClientSendPalletsData[],
) => {
   const palletsItems = sendPalletsDataItems.map(
      vglClientSendPalletsDataItemToVGLClientPalletsItemMapper,
   );
   const firstSendPalletsItemGroupedByCommercialNetworkAddressAndMergedByPalletTypes =
      sendPalletsDataItems[0];
   const commonSendPalletsData = {
      ['Data ost.mod.']:
         firstSendPalletsItemGroupedByCommercialNetworkAddressAndMergedByPalletTypes[
            'Data ost.mod.'
         ],
      ['nr STO']:
         firstSendPalletsItemGroupedByCommercialNetworkAddressAndMergedByPalletTypes['nr STO'],
      ['do Centrum Dystr.']:
         firstSendPalletsItemGroupedByCommercialNetworkAddressAndMergedByPalletTypes[
            'do Centrum Dystr.'
         ],
   };
   return {
      ...commonSendPalletsData,
      pallets: palletsItems,
   };
};
