import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ForgotPasswordForm from 'components/Auth/ForgotPassword/ForgotPasswordForm/ForgotPasswordForm';
import SentPasswordResetNotification from 'components/Auth/ForgotPassword/SentPasswordResetNotification/SentPasswordResetNotification';
import ResetForgotPasswordLayout from 'components/layout/ResetForgotPasswordLayout/ResetForgotPasswordLayout';
import { LOGIN } from 'constants/routes';

const ForgotPasswordPage = () => {
   const navigate = useNavigate();
   const [sentPasswordReset, setSentPasswordReset] = useState(false);
   const [email, setEmail] = useState('');

   const handleGoToLoginPage = () => navigate(LOGIN);

   const handleSendPasswordReset = (userEmail: string) => {
      setSentPasswordReset(true);
      setEmail(userEmail);
   };

   return (
      <ResetForgotPasswordLayout>
         <>
            {sentPasswordReset ? (
               <SentPasswordResetNotification email={email} onGoToLoginPage={handleGoToLoginPage} />
            ) : (
               <ForgotPasswordForm
                  onGoToLoginPage={handleGoToLoginPage}
                  onSendPasswordReset={handleSendPasswordReset}
               />
            )}
         </>
      </ResetForgotPasswordLayout>
   );
};

export default ForgotPasswordPage;
