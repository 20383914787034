import { ReactNode, useState } from 'react';

import { ChevronDown, ChevronRight } from 'tabler-icons-react';

import { SharedGridTableCell, SharedGridTableRow } from 'components/shared';

import styles from './ExtendableSharedGridTableRow.module.css';

type ExtendableSharedGridTableRowProps = {
   isDefaultOpen?: boolean;
   label: ReactNode;
   extendableContent: ReactNode;
};

const ExtendableSharedGridTableRow = ({
   isDefaultOpen = false,
   label,
   extendableContent,
}: ExtendableSharedGridTableRowProps) => {
   const [extend, setExtend] = useState<boolean>(isDefaultOpen);

   const toggleExtend = () => setExtend(!extend);

   const icon = extend ? <ChevronDown size={20} /> : <ChevronRight size={20} />;

   return (
      <>
         <SharedGridTableRow className={styles.row} onClick={toggleExtend}>
            <SharedGridTableCell className={styles.cell}>
               {icon}
               {label}
            </SharedGridTableCell>
         </SharedGridTableRow>
         {extend && extendableContent}
      </>
   );
};

export default ExtendableSharedGridTableRow;
