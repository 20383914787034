import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingOverlay, Modal, ModalProps } from '@mantine/core';
import { Trash } from 'tabler-icons-react';

import { StyledButton } from 'components/shared';
import { REMOVE_MODAL_CONSTANTS } from 'constants/removeCommercialNetworkModalContent';
import { CommercialNetworkDetailsDTO, DistributionCenter } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './RemoveCommercialNetworkModal.module.css';

type CommercialNetworkDetails = ModalProps & {
   selectedItem: CommercialNetworkDetailsDTO;
   onSuccessDelete: () => void;
   onClose: () => void;
   mode: 'CN';
};
type DistributionCenterDetails = ModalProps & {
   selectedItem: DistributionCenter;
   onSuccessDelete?: () => void;
   onClose: () => void;
   mode: 'DC';
};

interface ModalContentOptions {
   showWarning?: boolean;
   onClick?: () => void;
}

type ModalContent = {
   titleContent: string;
   buttonContent: string;
   descriptionContent: string;
   onClick: () => void;
};

type RemoveCommercialNetworkModalProps = CommercialNetworkDetails | DistributionCenterDetails;

const RemoveCommercialNetworkModal = ({
   selectedItem,
   onSuccessDelete,
   onClose,
   mode,
   ...props
}: RemoveCommercialNetworkModalProps) => {
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);
   const [showErrorBanner, setShowErrorBanner] = useState(false);
   const [canDeleteCommercialNetwork, setCanDeleteCommercialNetwork] = useState(false);
   const [canDeleteDistributionCenter, setCanDeleteDistributionCenter] = useState(false);
   const isInCNMode = mode === 'CN';
   const isInDCMode = mode === 'DC';
   const selectedItemId = selectedItem.id;

   const fetchCanCommercialNetworkBeDeleted = useCallback(() => {
      if (mode === 'CN' && selectedItemId) {
         setIsLoading(true);
         HTTPService.canCommercialNetworkBeDeleted(selectedItemId)
            .then(({ data: canCommercialNetworkBeDeletedResponse }) =>
               setCanDeleteCommercialNetwork(canCommercialNetworkBeDeletedResponse.canBeDeleted),
            )
            .catch(handleError)
            .finally(() => setIsLoading(false));
      }
   }, [mode, selectedItemId]);

   const fetchCanDistributionCenterBeDeleted = useCallback(() => {
      if (mode === 'DC' && selectedItemId && selectedItem.commercialNetworkId) {
         setIsLoading(true);
         HTTPService.canDistributionCenterBeDeleted(
            selectedItem.commercialNetworkId,
            selectedItemId,
         )
            .then(({ data: canDistributionCenterBeDeletedResponse }) =>
               setCanDeleteDistributionCenter(canDistributionCenterBeDeletedResponse.canBeDeleted),
            )
            .catch(handleError)
            .finally(() => setIsLoading(false));
      }
   }, [mode, selectedItem, selectedItemId]);

   useEffect(() => {
      fetchCanCommercialNetworkBeDeleted();
      fetchCanDistributionCenterBeDeleted();
   }, [fetchCanCommercialNetworkBeDeleted, fetchCanDistributionCenterBeDeleted]);

   const isAnyItemSelected = useMemo(() => {
      if (mode === 'CN') {
         return !!selectedItem?.addresses.length;
      }
   }, [mode, selectedItem]);

   const shouldShowWarning = useMemo(() => {
      if (isInCNMode) {
         return isAnyItemSelected && !canDeleteCommercialNetwork;
      } else if (isInDCMode) {
         return !canDeleteDistributionCenter;
      }
      return;
   }, [
      isInCNMode,
      isInDCMode,
      isAnyItemSelected,
      canDeleteCommercialNetwork,
      canDeleteDistributionCenter,
   ]);

   const handleDeleteCommercialNetwork = useCallback(() => {
      if (isInCNMode) {
         setIsLoading(true);
         HTTPService.deleteCommercialNetwork(selectedItemId)
            .then(() => {
               onSuccessDelete!();
               onClose();
            })
            .catch(handleError)
            .finally(() => setIsLoading(false));
      }
   }, [onClose, onSuccessDelete, selectedItemId, isInCNMode]);

   const handleDeleteDistributionCenter = useCallback(() => {
      if (mode === 'DC') {
         setIsLoading(true);
         HTTPService.deleteDistributionCenter(selectedItem.commercialNetworkId!, selectedItemId)
            .then(() => {
               navigate('/supplier/storages/commercial-networks', {
                  state: { successRemoveDistribution: true },
               });
            })
            .catch(handleError)
            .finally(() => {
               setIsLoading(false);
            });
      }
   }, [mode, navigate, selectedItem, selectedItemId]);

   const handleError = (error: unknown) => errorHandler(error, () => setShowErrorBanner(true));

   const getModalContent = (modalMode: 'DC' | 'CN', options: ModalContentOptions): ModalContent => {
      if (modalMode === 'CN') {
         if (options.showWarning) {
            return {
               titleContent: 'Usuń Sieć Handlową',
               buttonContent: 'Usuń Sieć Handlową razem z Centrami Dystrybucyjnymi',
               descriptionContent: REMOVE_MODAL_CONSTANTS.PALLETS_EXIST_COMMERCIAL_NETWORK,
               onClick: handleDeleteCommercialNetwork,
            };
         } else {
            return {
               titleContent: 'Usuń Sieć Handlową',
               buttonContent: 'Usuń Sieć Handlową',
               descriptionContent: REMOVE_MODAL_CONSTANTS.NO_PALLETS_COMMERCIAL_NETWORK,
               onClick: handleDeleteCommercialNetwork,
            };
         }
      }
      if (modalMode === 'DC') {
         if (options.showWarning) {
            return {
               titleContent: 'Usuń Centrum Dystrybucyjne',
               buttonContent: 'Usuń Centrum Dystrybucyjne razem z paletami',
               descriptionContent: REMOVE_MODAL_CONSTANTS.PALLETS_EXIST_DISTRIBUTION_CENTER,
               onClick: handleDeleteDistributionCenter,
            };
         } else {
            return {
               titleContent: 'Usuń Centrum Dystrybucyjne',
               buttonContent: 'Usuń Centrum Dystrybucyjne',
               descriptionContent: REMOVE_MODAL_CONSTANTS.NO_PALLETS_DISTRIBUTION_CENTER,
               onClick: handleDeleteDistributionCenter,
            };
         }
      }
      throw new Error(`Invalid mode "${mode}"`);
   };

   const modal = getModalContent(mode, {
      showWarning: shouldShowWarning,
   });

   const { titleContent, buttonContent, descriptionContent, onClick } = modal;

   return (
      <Modal
         {...props}
         onClose={onClose}
         centered
         classNames={{
            modal: styles.modal,
         }}
      >
         <div className={styles.modalContent}>
            {showErrorBanner && (
               <Banner variant="error" fullWidth style={{ marginBottom: 48 }}>
                  Wystąpił błąd. Odśwież stronę i spróbuj ponownie.
               </Banner>
            )}
            <Trash size={48} strokeWidth={1.5} color={'var(--primary-red)'} />

            <h3>{titleContent}</h3>
            <div className={styles.descriptionContainer}>
               <p className={styles.plainText}>{descriptionContent}</p>
               <br />
               {shouldShowWarning && (
                  <>
                     <p className={styles.plainText}>
                        Czy jesteś pewny, że chcesz usunąć ten magazyn?
                     </p>
                     <br />
                  </>
               )}
               <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
            </div>
            <LoadingOverlay
               visible={isLoading}
               overlayBlur={10}
               loaderProps={{ color: 'var(--light-green)' }}
            />
         </div>
         <div className={styles.btnsContainer}>
            <StyledButton text="Anuluj" onClick={onClose} variant="outlined-danger" />
            <StyledButton
               type="button"
               text={buttonContent}
               onClick={onClick}
               variant="filled-danger"
               disabled={isLoading || showErrorBanner}
            />
         </div>
      </Modal>
   );
};

export default RemoveCommercialNetworkModal;
