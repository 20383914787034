import {
   EMPTY_INPUT_ERROR,
   NO_NUMERIC_VALUE_ERROR,
   NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
} from 'constants/validationErrors';
import { PalletCustomPricesForm } from 'interfaces';

import { isNonEmpty, isNumeric, moreThanZero } from '.';

export const changePalletsPricesFormValidation = [
   ({ edhpPalletPrice }: PalletCustomPricesForm) => {
      if (edhpPalletPrice === '') {
         return false;
      } else if (!isNonEmpty(edhpPalletPrice)) {
         return {
            edhpPalletPrice: EMPTY_INPUT_ERROR,
         };
      } else if (!isNumeric(edhpPalletPrice)) {
         return {
            edhpPalletPrice: NO_NUMERIC_VALUE_ERROR,
         };
      } else if (!moreThanZero(edhpPalletPrice)) {
         return {
            edhpPalletPrice: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
         };
      }
   },
   ({ euroPalletPrice }: PalletCustomPricesForm) => {
      if (euroPalletPrice === '') {
         return false;
      } else if (!isNonEmpty(euroPalletPrice)) {
         return {
            euroPalletPrice: EMPTY_INPUT_ERROR,
         };
      } else if (!isNumeric(euroPalletPrice)) {
         return {
            euroPalletPrice: NO_NUMERIC_VALUE_ERROR,
         };
      } else if (!moreThanZero(euroPalletPrice)) {
         return {
            euroPalletPrice: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
         };
      }
   },
   ({ halfPalletNDHPPrice }: PalletCustomPricesForm) => {
      if (halfPalletNDHPPrice === '') {
         return false;
      } else if (!isNonEmpty(halfPalletNDHPPrice)) {
         return {
            halfPalletNDHPPrice: EMPTY_INPUT_ERROR,
         };
      } else if (!isNumeric(halfPalletNDHPPrice)) {
         return {
            halfPalletNDHPPrice: NO_NUMERIC_VALUE_ERROR,
         };
      } else if (!moreThanZero(halfPalletNDHPPrice)) {
         return {
            halfPalletNDHPPrice: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
         };
      }
   },
   ({ quarterPalletCDHPPrice }: PalletCustomPricesForm) => {
      if (quarterPalletCDHPPrice === '') {
         return false;
      } else if (!isNonEmpty(quarterPalletCDHPPrice)) {
         return {
            quarterPalletCDHPPrice: EMPTY_INPUT_ERROR,
         };
      } else if (!isNumeric(quarterPalletCDHPPrice)) {
         return {
            quarterPalletCDHPPrice: NO_NUMERIC_VALUE_ERROR,
         };
      } else if (!moreThanZero(quarterPalletCDHPPrice)) {
         return {
            quarterPalletCDHPPrice: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
         };
      }
   },
];
