import { SectionContainer } from 'components/shared';
import { PalletBalance } from 'interfaces';
import { getPalletsAmountLabel } from 'utils/functions';

import RentedPalletsSummarySectionActionsDropdown from './RentedPalletsSummarySectionActionsDropdown/RentedPalletsSummarySectionActionsDropdown';
import styles from './RentedPalletsSummarySection.module.css';

type RentedPalletsSummarySectionProps = {
   rentedPalletsSummaryData: PalletBalance;
   isSupplierView?: boolean;
   onEditBalanceClick?: () => void;
   openAdmissionPalletsModal?: () => void;
};

const RentedPalletsSummarySection = ({
   rentedPalletsSummaryData,
   isSupplierView,
   openAdmissionPalletsModal,
   onEditBalanceClick,
}: RentedPalletsSummarySectionProps) => (
   <SectionContainer
      title="Saldo obecnie wynajętych palet"
      subtitle="Palety w dobrym stanie z dostarczonych zamówień, które należy nadać"
      headerRightSideElement={
         isSupplierView ? (
            <RentedPalletsSummarySectionActionsDropdown
               onClickMovePallets={() => {
                  openAdmissionPalletsModal!();
               }}
               onClickEditPalletsBalance={() => onEditBalanceClick && onEditBalanceClick()}
            />
         ) : undefined
      }
   >
      <div className={styles.container}>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Paleta EDHP 800x1200 mm, szt.</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('EDHP_PALLET', rentedPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Euro paleta 800x1200 mm</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('EURO_PALLET', rentedPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Półpaleta NDHP 600x800 mm, szt.</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('HALF_PALLET_NDHP', rentedPalletsSummaryData)}
            </span>
         </span>
         <span className={styles.summaryItem}>
            <span className={styles.summaryItemTitle}>Ćwierćpaleta CDHP 400x600 mm, szt.</span>
            <span className={styles.summaryItemAmount}>
               {getPalletsAmountLabel('QUARTER_PALLET_CDHP', rentedPalletsSummaryData)}
            </span>
         </span>
      </div>
   </SectionContainer>
);

export default RentedPalletsSummarySection;
