import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { ChevronRightIcon } from 'assets';
import { OrderSummary } from 'components/ClientPanel';
import { PlaceOrderForm } from 'components/ClientPanel';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { CLIENT_ORDER_SUCCESS, CLIENT_ORDERS } from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import WithAuth from 'hoc/withAuth';
import { BannerData, ClientOrderData } from 'interfaces';
import { HTTPService } from 'service';
import { PlaceOrderRequest } from 'service/http/requests';
import { Banner, Step } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { scrollToElement } from 'utils/functions';
import { getPalletsFullData } from 'utils/functions/getPalletsFullData';
import { parseClientDeliveryAddressIntoDeliveryAddress } from 'utils/parsers';

import styles from './PlaceOrderPage.module.css';

const PlaceOrderPage = () => {
   const initialState = {
      internalOrderId: '',
      address: null,
      palletDeliveryDate: null,
      pallets: [],
   };
   const [activeStep, setActiveStep] = useState<'ORDER_DETAILS' | 'SUMMARY'>('ORDER_DETAILS');
   const [orderData, setOrderData] = useState<ClientOrderData>(initialState);
   const [logisticMinimumAchieved, setLogisticMinimumAchieved] = useState(false);
   const [isPlaceOrderRequestSending, setIsPlaceOrderRequestSending] = useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const { user } = useAuth();
   const formStepsContainerRef = useRef<null | HTMLDivElement>(null);
   const navigate = useNavigate();

   useEffect(() => {
      user?.suspension.blocked && navigate(CLIENT_ORDERS);
   }, [navigate, user]);

   const handleDetailsSubmit = (values: ClientOrderData) => {
      setOrderData(values);
      setActiveStep('SUMMARY');
      scrollToTop();
   };

   const handleBackClick = () => {
      setActiveStep('ORDER_DETAILS');
      scrollToTop();
   };

   const handlePlaceOrder = () => {
      setIsPlaceOrderRequestSending(true);
      const pallets = getPalletsFullData(
         orderData.pallets,
         pallet => palletType => palletType.name === pallet.name,
      );
      const orderPallets = pallets.map(pallet => {
         const amount =
            pallet.orderType === 'LOGISTIC_MINIMUM'
               ? parseInt(pallet.amount as string) * pallet.logisticMinimum
               : parseInt(pallet.amount as string);
         return {
            palletType: pallet.name,
            amount,
            shownAsMultipleOfTheMinimum: pallet.orderType === 'LOGISTIC_MINIMUM',
         };
      });
      const order: PlaceOrderRequest = {
         pallets: [...orderPallets],
         palletDeliveryDate: orderData.palletDeliveryDate!.toLocaleDateString('en-GB'),
         personalPickUp: orderData.address === 'SELF_PICKUP',
         internalOrderId: orderData.internalOrderId || undefined,
         ...(typeof orderData.address === 'object'
            ? { addressId: orderData.address!.value.id }
            : {}),
      };
      HTTPService.placeOrder(order)
         .then(() => {
            navigate(CLIENT_ORDER_SUCCESS, {
               state: { logisticMinimum: logisticMinimumAchieved, deliveryType: orderData.address },
            });
         })
         .catch(handlePlaceOrderError)
         .finally(() => setIsPlaceOrderRequestSending(false));
   };

   const handlePlaceOrderError = (error: unknown) => {
      scrollToTop();
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION,
         }),
      );
   };

   const scrollToTop = () => scrollToElement(formStepsContainerRef);

   const clientDeliveryAddresses = useMemo(() => {
      if (!user) {
         return [];
      }
      return user.deliveryAddresses.map(parseClientDeliveryAddressIntoDeliveryAddress);
   }, [user]);

   return (
      <div className={styles.placeOrderContainer}>
         {bannerData && (
            <Banner
               withCloseIcon
               fullWidth
               variant={bannerData.variant}
               children={bannerData.description}
               className={styles.banner}
               onClose={() => setBannerData(null)}
            />
         )}
         <div ref={formStepsContainerRef} className={styles.stepsContainer}>
            <Step
               completed={activeStep === 'SUMMARY'}
               active={activeStep === 'ORDER_DETAILS'}
               text="Szczegóły zamówienia"
               stepCount={1}
               className={classNames({ [styles.hideTextMobile]: activeStep !== 'ORDER_DETAILS' })}
            />
            <ChevronRightIcon className={styles.chevron} />
            <Step
               className={classNames({ [styles.hideTextMobile]: activeStep !== 'SUMMARY' })}
               active={activeStep === 'SUMMARY'}
               text="Podsumowanie"
               stepCount={2}
            />
         </div>
         {activeStep === 'ORDER_DETAILS' ? (
            <PlaceOrderForm
               initialState={orderData}
               clientDeliveryAddresses={clientDeliveryAddresses}
               onChangeLogisticMinimumAchieved={setLogisticMinimumAchieved}
               onSubmit={handleDetailsSubmit}
               availablePalletTypes={user ? user.availablePalletTypes : []}
               retentionPeriodDays={user?.rateConfig?.retentionConfig.retentionPeriodDays}
            />
         ) : (
            <OrderSummary
               logisticMinimumAchieved={logisticMinimumAchieved}
               retentionPeriodDays={
                  user?.rateConfig ? user.rateConfig.retentionConfig.retentionPeriodDays : null
               }
               data={orderData}
               bottomButtons={
                  <>
                     <StyledButton
                        style={{ marginRight: 12 }}
                        variant="outlined-primary"
                        text="Poprzedni krok"
                        onClick={handleBackClick}
                        className={styles.cancelBtn}
                     />
                     <StyledButton
                        onClick={handlePlaceOrder}
                        variant="filled-primary"
                        className={styles.submitBtn}
                        text={
                           !logisticMinimumAchieved || orderData.address === 'SELF_PICKUP'
                              ? 'Wyślij zapytanie'
                              : 'Złóż zamówienie'
                        }
                        loading={isPlaceOrderRequestSending}
                     />
                  </>
               }
            />
         )}
      </div>
   );
};

export default WithAuth(PlaceOrderPage, 'ROLE_CLIENT');
