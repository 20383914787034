import { useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GreenPlusIcon, WhiteReplaceIcon } from 'assets';
import AddStorageModal from 'components/AddStorageModal/AddStorageModal';
import { StyledButton } from 'components/shared';
import { MovePalletsModal } from 'components/SupplierPanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { SUPPLIER_STORAGES_BY_NAME_PATH } from 'constants/routes';
import { storagePageTabs as tabs } from 'constants/tabs';
import WithAuth from 'hoc/withAuth';
import { BannerType, BannerVariants } from 'interfaces';
import { Banner, Divider, Tab } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { scrollToElement } from 'utils/functions';

import ClientPalletBalancesPage from './ClientPalletBalancesPage/ClientPalletBalancesPage';
import CommercialNetworkPage from './CommercialNetworkPage/CommercialNetworkPage';
import ExternalStoragePage from './ExternalStoragePage/ExternalStoragePage';
import InternalStoragePage from './InternalStoragePage/InternalStoragePage';
import styles from './StoragesPage.module.css';

const StoragesPage = () => {
   const [paletteTransferOpen, setPaletteTransferOpen] = useState<boolean>(false);
   const [addStorageOpen, setAddStorageOpen] = useState<boolean>(false);
   const [banner, setBanner] = useState<null | BannerType>(null);

   const headerContainerRef = useRef<null | HTMLDivElement>(null);
   const childRef = useRef<{ reloadChild: () => void }>();
   const { storageName } = useParams();
   const navigate = useNavigate();

   const openAddStorage = () => setAddStorageOpen(true);

   const renderContent = () => {
      switch (storageName) {
         case 'internal-storages':
            return (
               <InternalStoragePage
                  ref={childRef}
                  showBanner={showBanner}
                  openAddStorage={openAddStorage}
                  onFetchDataError={handleFetchDataError}
               />
            );
         case 'external-storages':
            return (
               <ExternalStoragePage
                  ref={childRef}
                  showBanner={showBanner}
                  openAddStorage={openAddStorage}
                  onFetchDataError={handleFetchDataError}
               />
            );
         case 'commercial-networks':
            return <CommercialNetworkPage ref={childRef} openAddStorage={openAddStorage} />;
         case 'clients':
            return <ClientPalletBalancesPage showBanner={showBanner} />;
      }
   };

   const showBanner = useCallback((variant: BannerVariants, description: string) => {
      setBanner({ variant, description });
      scrollToElement(headerContainerRef);
   }, []);

   const hideBanner = () => setBanner(null);

   const handleFetchDataError = useCallback(
      (error: unknown) =>
         errorHandler(error, () => showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA)),
      [showBanner],
   );

   return (
      <>
         {addStorageOpen && (
            <AddStorageModal
               onClose={() => setAddStorageOpen(false)}
               onSuccess={() => {
                  showBanner('success', 'Punkt magazynowy został dodany.');
                  childRef?.current?.reloadChild();
                  setAddStorageOpen(false);
               }}
            />
         )}
         {paletteTransferOpen && (
            <MovePalletsModal
               onClose={() => setPaletteTransferOpen(false)}
               onSuccess={() => {
                  childRef?.current?.reloadChild();
                  setPaletteTransferOpen(false);
               }}
               showBanner={showBanner}
            />
         )}
         <div>
            <div ref={headerContainerRef} className={styles.headerContainer}>
               <div className={styles.header}>
                  <StyledButton
                     icon={<WhiteReplaceIcon />}
                     variant="filled-primary"
                     text="Przerzuć palety"
                     onClick={() => setPaletteTransferOpen(true)}
                  />
                  <span className={styles.line} />
                  <StyledButton
                     icon={<GreenPlusIcon />}
                     variant="outlined-primary"
                     text="Dodaj nowy punkt magazynowy"
                     onClick={openAddStorage}
                  />
               </div>
               {!!banner && (
                  <Banner
                     withCloseIcon
                     className={styles.banner}
                     variant={banner?.variant}
                     children={banner?.description}
                     onClose={hideBanner}
                  />
               )}
            </div>
            <div>
               <div className={styles.tabsSection}>
                  <div className={styles.tabsContainer}>
                     {tabs.map(tab => (
                        <Tab
                           key={tab.value}
                           active={storageName === tab.value}
                           text={tab.label}
                           onClick={() => navigate(SUPPLIER_STORAGES_BY_NAME_PATH(tab.value))}
                        />
                     ))}
                  </div>
                  <Divider className={styles.tabsBorder} variant="horizontal" />
               </div>
            </div>
            {renderContent()}
         </div>
      </>
   );
};

export default WithAuth(StoragesPage, 'ROLE_SYSTEM_ADMIN');
