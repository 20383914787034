import { DistributionCenterDTO, Option } from 'interfaces';
import { getFullAddress } from 'utils/functions';

export const parseDistributionCenterDTOIntoOption: (
   distributionCenterDTO: DistributionCenterDTO,
) => Option = distributionCenterDTO => {
   const { id, address } = distributionCenterDTO;
   const { street, zipCode, city } = address;
   const addressLabel = getFullAddress(street, zipCode, city);

   return {
      value: id,
      label: addressLabel,
   };
};
