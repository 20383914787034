export const STORAGE_POINT_TYPES = {
   COMMERCIAL_NETWORK: 'COMMERCIAL_NETWORK',
   INTERNAL_STORAGE: 'INTERNAL_STORAGE',
   EXTERNAL_STORAGE: 'EXTERNAL_STORAGE',
};

export const storagePointTypes = [
   { label: 'Sieć Handlowa', value: STORAGE_POINT_TYPES.COMMERCIAL_NETWORK },
   { label: 'Magazyn zewnętrzny', value: STORAGE_POINT_TYPES.EXTERNAL_STORAGE },
   { label: 'Magazyn wewnętrzny', value: STORAGE_POINT_TYPES.INTERNAL_STORAGE },
];
