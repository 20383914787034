import union from 'lodash/union';

export const getUniqueErrorMessagesFromErrorsObjectList = (
   errorsObjectList: Record<string, string>[],
) => {
   const errorMessages: string[][] = errorsObjectList.map((errorObject: Record<string, string>) =>
      Object.values(errorObject),
   );
   return union(...errorMessages);
};
