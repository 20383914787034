import { OrderDetailsPallet } from 'interfaces';
import { getPalletLabel } from 'utils/functions';

import styles from './RegularPalletAmountInfo.module.css';

type RegularPalletAmountInfoProps = {
   pallet: OrderDetailsPallet;
};

const RegularPalletAmountInfo = ({ pallet }: RegularPalletAmountInfoProps) => (
   <p className={styles.amountInfo}>{`${pallet.amount} ${getPalletLabel(pallet.amount)}`}</p>
);

export default RegularPalletAmountInfo;
