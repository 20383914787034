import React, { useCallback, useMemo, useState } from 'react';

import { clone, isEmpty, mapValues, merge } from 'lodash';

import { DeliveryAddress, PalletData, PalletType } from 'interfaces';

type FormProps = {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   initialState: Record<string, any>;
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   validations: any;
   onSubmit: () => void;
};

const validate = (validations: FormProps['validations'], values: FormProps['initialState']) => {
   const errors = validations.map((validation: ({}) => false | Record<string, string>) => {
      return validation(values);
   });

   return {
      formValid: isEmpty(merge({}, ...errors)),
      errorsList: merge({}, ...errors),
   };
};

export const useForm = ({ initialState, validations, onSubmit }: FormProps) => {
   const touchedValues = useMemo(
      () =>
         Object.keys(initialState).reduce(
            (values: Record<string, boolean>, currentKey) => ({
               ...values,
               [currentKey]: false,
            }),
            {},
         ),
      [initialState],
   );

   const [values, setValues] = useState(initialState);
   const [touched, setTouched] = useState(touchedValues);

   const { formValid, errorsList } = useMemo(
      () => validate(validations, values),
      [values, validations],
   );

   const changeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const {
         target: { type, checked, value, name },
      } = event;
      const enteredValue = type === 'checkbox' ? checked : value;
      changeFieldValue(enteredValue, name);
   }, []);

   const changeFieldValue = (enteredValue: string | boolean, field: string) => {
      setValues(prevValues => ({ ...prevValues, [field]: enteredValue }));
      setTouched(prevValues => ({ ...prevValues, [field]: true }));
   };

   const selectChangeHandler = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (selectValue: any, field: string, withoutTouch?: boolean) => {
         setValues(prevValues => ({ ...prevValues, [field]: selectValue }));
         !withoutTouch && setTouched(prevValues => ({ ...prevValues, [field]: true }));
      },
      [],
   );

   const multiSelectChangeHandler = useCallback((selectValue: string, field: string) => {
      setValues(prevValues => ({
         ...prevValues,
         [field]: prevValues[field].some((valuesField: string) => valuesField === selectValue)
            ? prevValues[field].filter((valuesField: string) => valuesField !== selectValue)
            : [...prevValues[field], selectValue],
      }));
      setTouched(prevValues => ({ ...prevValues, [field]: true }));
   }, []);

   const palletQuantityChangeHandler = useCallback(
      (value: string, name: string) => {
         const palletsCopy = clone(values.pallets);
         palletsCopy.forEach((pallet: PalletData) => {
            if (pallet.name === name) {
               pallet.amount = value;
            }
         });
         setValues(prevValues => ({ ...prevValues, pallets: palletsCopy }));
      },
      [values.pallets],
   );

   const handlePalletRadioChange = useCallback(
      (name: string, orderType: 'LOGISTIC_MINIMUM' | 'CUSTOM') => {
         if (values.pallets.some((pallet: PalletData) => pallet.name === name)) {
            const palletsCopy = clone(values.pallets);
            palletsCopy.forEach((pallet: PalletData) => {
               if (pallet.name === name) {
                  pallet.amount = '';
                  pallet.orderType = orderType;
               }
            });
            setValues(prevValues => ({ ...prevValues, pallets: palletsCopy }));
         } else {
            setValues(prevValues => ({
               ...prevValues,
               pallets: [...values.pallets, { name: name, amount: '', orderType: orderType }],
            }));
         }
      },
      [values.pallets],
   );

   const handleDeliveryAddressChange = useCallback(
      (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
         setValues(prevValues => ({
            ...prevValues,
            deliveryAddresses: prevValues.deliveryAddresses.map(
               (address: DeliveryAddress, addressIndex: number) => {
                  if (addressIndex === index) {
                     return { ...address, [event.target.name]: event.target.value };
                  }
                  return address;
               },
            ),
         }));
      },
      [],
   );

   const clearForm = useCallback(() => {
      setValues(initialState);
      setTouched(mapValues(touched, () => false));
   }, [setValues, initialState, touched]);

   const handleClearPallet = useCallback(
      (name: PalletType) => {
         const palletsCopy = clone(values.pallets);
         const updatedPallets = palletsCopy.filter((pallet: PalletData) => pallet.name !== name);
         setValues(prevValues => ({ ...prevValues, pallets: updatedPallets }));
         setTouched(prevTouched => ({ ...prevTouched, pallets: true }));
      },
      [values.pallets],
   );

   const submitHandler = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
         event.preventDefault();
         event.stopPropagation();
         setTouched(mapValues(touched, () => true));
         if (formValid) {
            onSubmit();
         }
      },
      [touched, formValid, onSubmit],
   );

   return {
      values,
      changeHandler,
      touched,
      submitHandler,
      formValid,
      errorsList,
      selectChangeHandler,
      multiSelectChangeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      clearForm,
      setValues,
      setTouched,
      handleDeliveryAddressChange,
      changeFieldValue,
   };
};
