import { CustomModal } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './SendOrderForPaymentModal.module.css';

type SendOrderForPaymentModalProps = {
   orderId: string;
   onSuccessSendForPayment: (updatedOrder: OrderDetailsDTO) => void;
   hasAdditionalCharges?: boolean;
   opened: boolean;
   onClose: () => void;
};

const SendOrderForPaymentModal = ({
   orderId,
   onSuccessSendForPayment,
   hasAdditionalCharges,
   opened,
   onClose,
}: SendOrderForPaymentModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleSendOrderForPayment = () => {
      setIsLoading(true);
      HTTPService.changeOrderStatus(orderId, 'TO_PAY')
         .then(response => {
            onSuccessSendForPayment(response.data);
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         opened={opened}
         onClose={closeModalHandler}
         isLoading={isLoading}
         error={errorInfo}
         title={
            hasAdditionalCharges
               ? 'Potwierdź dodatkowe obciążenia i prześlij do zapłaty'
               : 'Prześlij do zapłaty'
         }
         innerTitle="Uwaga!"
         icon="ALERT_TRIANGLE"
         primaryButtonProps={{
            text: hasAdditionalCharges
               ? 'Przesyłam i akceptuję dodatkowe obciążenia'
               : 'Prześlij do zapłaty',
            variant: 'filled-primary',
            onClick: handleSendOrderForPayment,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: closeModalHandler,
         }}
         size={670}
         leftSideModalContent
         modalContentClassName={styles.modalContent}
      >
         <p>
            Czy wszystkie opłaty klienta zostały zaksięgowane i jesteś pewien, że chcesz wysłać to
            zamówienie do zapłaty?
         </p>
         <p>Tej akcji nie można cofnąć.</p>
      </CustomModal>
   );
};

export default SendOrderForPaymentModal;
