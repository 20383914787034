import { Option, StorageDTO } from 'interfaces';

import { getFullAddress } from './getFullAddress';

export const getStoragesAddressOptions = (
   storages: StorageDTO[],
   storageOption: Option | undefined,
) => {
   const filterStorages = storages.filter(storage => storage.type === storageOption?.value);
   return filterStorages.map(({ address: { street, zipCode, city }, id }) => ({
      label: getFullAddress(street, zipCode, city),
      value: id,
   }));
};
