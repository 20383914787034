import { ReactNode, useMemo } from 'react';

import { DateTime } from 'luxon';

import {
   OverviewDeliverySection,
   OverviewPalletsConfigurationSection,
   OverviewRentalPeriodSection,
} from 'components/Order';
import { ClientOrderData } from 'interfaces';
import { getHoldingPeriod } from 'utils/functions/getHoldingPeriod';
import { getPalletsFullData } from 'utils/functions/getPalletsFullData';
import { countPalletsOrderSum } from 'utils/functions/palletsCalculations';

import OverviewInternalOrderIdSection from './OverviewInternalOrderIdSection/OverviewInternalOrderIdSection';
import styles from './OrderSummary.module.css';

type OrderSummaryProps = {
   data: ClientOrderData;
   retentionPeriodDays: number | null;
   logisticMinimumAchieved?: boolean;
   bottomButtons: ReactNode;
};

const OrderSummary = ({
   data,
   retentionPeriodDays,
   logisticMinimumAchieved,
   bottomButtons,
}: OrderSummaryProps) => {
   let holdingPeriod: DateTime | null = null;
   if (retentionPeriodDays && data.palletDeliveryDate) {
      holdingPeriod = getHoldingPeriod(data.palletDeliveryDate, retentionPeriodDays);
   }

   const pallets = useMemo(
      () =>
         getPalletsFullData(data.pallets, pallet => palletType => palletType.name === pallet.name),
      [data.pallets],
   );

   return (
      <div>
         <OverviewInternalOrderIdSection internalOrderId={data.internalOrderId} />
         <OverviewDeliverySection
            viewMode="CLIENT"
            address={
               data.address && data.address !== 'SELF_PICKUP' ? data.address.value : data.address
            }
         />
         <OverviewRentalPeriodSection
            palletDeliveryDate={
               data.palletDeliveryDate ? data.palletDeliveryDate.toLocaleDateString('en-GB') : ''
            }
            holdingPeriod={holdingPeriod ? holdingPeriod.toFormat('dd/LL/yyyy') : ''}
            retentionPeriodDays={retentionPeriodDays ? String(retentionPeriodDays) : ''}
         />
         <OverviewPalletsConfigurationSection
            logisticMinimumAchieved={logisticMinimumAchieved}
            pallets={pallets}
            palletsSum={countPalletsOrderSum(pallets)}
         />
         {bottomButtons && <div className={styles.btnsContainer}>{bottomButtons}</div>}
      </div>
   );
};

export default OrderSummary;
