import { ReportStatus } from 'interfaces';

export const REPORT_STATUSES_AND_LABELS: {
   [key: string]: { label: string; variant: ReportStatus };
} = {
   REPORTED: { label: 'Zgłoszone', variant: 'REPORTED' },
   REVIEWED: { label: 'Rozpatrzone', variant: 'REVIEWED' },
   CANCELED: { label: 'Anulowane', variant: 'CANCELED' },
};

export const REPORT_STATUSES: { [key: string]: ReportStatus } = {
   REPORTED: 'REPORTED',
   REVIEWED: 'REVIEWED',
   CANCELED: 'CANCELED',
};
