import { useState } from 'react';

import { PalletAmountDTO, PalletBalance, PalletReportDTO } from 'interfaces';
import {
   getDamagedPalletsFromReports,
   getDefaultPalletsBalanceValues,
   sumPalletsBalances,
} from 'utils/functions';

export const useClientPalletsBalance = () => {
   const [rentedPallets, setRentedPallets] = useState<PalletBalance>(
      getDefaultPalletsBalanceValues(),
   );
   const [damagedByClientPallets, setDamagedByClientPallets] = useState<PalletBalance>(
      getDefaultPalletsBalanceValues(),
   );
   const [lostOrDestroyedByClientPallets, setLostOrDestroyedByClientPallets] =
      useState<PalletBalance>(getDefaultPalletsBalanceValues());
   const [damagedOrLostBySupplierPallets, setDamagedOrLostBySupplierPallets] =
      useState<PalletBalance>(getDefaultPalletsBalanceValues());
   const [remainingPallets, setRemainingPallets] = useState<PalletAmountDTO[]>([]);

   const handleLostDamagedBySupplierPalletReports = (reports: PalletReportDTO[]) => {
      const receivedDamagedPalletsFromReports = getDamagedPalletsFromReports(reports);
      setDamagedOrLostBySupplierPallets(prevPalletsData => {
         return sumPalletsBalances(prevPalletsData, receivedDamagedPalletsFromReports);
      });
   };

   const handleLostByClientPalletReports = (reports: PalletReportDTO[]) => {
      const lostOrDestroyedByUserFaultPalletsFromReports = getDamagedPalletsFromReports(
         reports,
         'LOST_OR_DESTRUCTION',
      );
      setLostOrDestroyedByClientPallets(prevPalletsData => {
         return sumPalletsBalances(prevPalletsData, lostOrDestroyedByUserFaultPalletsFromReports);
      });
   };

   const handleDamagedByClientPalletReports = (reports: PalletReportDTO[]) => {
      const damagedByUserFaultPalletsFromReports = getDamagedPalletsFromReports(reports, 'DAMAGE');
      setDamagedByClientPallets(prevPalletsData => {
         return sumPalletsBalances(prevPalletsData, damagedByUserFaultPalletsFromReports);
      });
   };

   return {
      rentedPallets,
      damagedByClientPallets,
      lostOrDestroyedByClientPallets,
      damagedOrLostBySupplierPallets,
      remainingPallets,
      setRentedPallets,
      setDamagedByClientPallets,
      setLostOrDestroyedByClientPallets,
      setDamagedOrLostBySupplierPallets,
      setRemainingPallets,
      handleLostDamagedBySupplierPalletReports,
      handleLostByClientPalletReports,
      handleDamagedByClientPalletReports,
   };
};
