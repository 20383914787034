import { createContext } from 'react';

import { ClientUserDTO } from 'interfaces';
import { SignInRequest } from 'service/http/requests';

type AuthContextType = {
   user: ClientUserDTO | null;
   signIn: (signInData: SignInRequest) => Promise<void>;
   sendPasswordReset: (email: string) => Promise<void>;
   resetPassword: (oobCode: string, newPassword: string) => Promise<void>;
   logout: () => Promise<void>;
   setUser: React.Dispatch<React.SetStateAction<ClientUserDTO | null>>;
};

const AuthContext = createContext<AuthContextType>({
   user: null,

   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore - empty function
   signIn: () => {
      //do nothing
   },
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore - empty function
   sendPasswordReset: () => {
      //do nothing
   },
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore - empty function
   resetPassword: () => {
      //do nothing
   },
   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
   // @ts-ignore - empty function
   logout: () => {
      //do nothing
   },
   setUser: () => {
      //do nothing
   },
});

export default AuthContext;
