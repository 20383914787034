import {
   EMPTY_INPUT_ERROR,
   NO_NUMERIC_VALUE_ERROR,
   NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
} from 'constants/validationErrors';
import { RateConfigForm } from 'interfaces';

import { isNonEmpty, isNumeric, moreOrEqualZero } from '.';

const validatePalletRates = ({ palletRates }: RateConfigForm) => {
   const commonPalletRatesErrors = palletRates.common.map(commonPalletRate => {
      const { personalPickUpPrice, damagePrice, lostPrice, wrongReceiver } = commonPalletRate;

      const personalPickUpPriceErrors = validatePalletRate(
         personalPickUpPrice,
         'personalPickUpPrice',
      );
      const damagePriceErrors = validatePalletRate(damagePrice, 'damagePrice');
      const lostPriceErrors = validatePalletRate(lostPrice, 'lostPrice');
      const wrongReceiverErrors = validatePalletRate(wrongReceiver, 'wrongReceiver');
      return {
         ...personalPickUpPriceErrors,
         ...damagePriceErrors,
         ...lostPriceErrors,
         ...wrongReceiverErrors,
      };
   });
   const palletRatesPerAddressErrors = palletRates.perAddress.map(palletRatePerAddress => {
      return palletRatePerAddress.palletsPricing.map(palletsPricingItem => {
         const { deliveryPrice } = palletsPricingItem;

         const deliveryPriceErrors = validatePalletRate(deliveryPrice, 'deliveryPrice');

         return {
            ...deliveryPriceErrors,
         };
      });
   });

   const isAnyCommonPalletRatesError = checkIfErrorPresent(commonPalletRatesErrors);
   const isAnyPalletRatesPerAddressError = checkIfErrorPresent(palletRatesPerAddressErrors.flat());

   return {
      ...((isAnyCommonPalletRatesError || isAnyPalletRatesPerAddressError) && {
         palletRates: { common: commonPalletRatesErrors, perAddress: palletRatesPerAddressErrors },
      }),
   };
};

const validateDelaysCharges = ({ delaysCharges }: RateConfigForm) => {
   const delaysChargesErrors = delaysCharges.map(delayCharge => {
      const { upTo21Days, upTo30Days, over30Days } = delayCharge;
      const upTo21DaysErrors = validatePalletRate(upTo21Days, 'upTo21Days');
      const upTo30DaysErrors = validatePalletRate(upTo30Days, 'upTo30Days');
      const dover30DaysErrors = validatePalletRate(over30Days, 'over30Days');
      return {
         ...upTo21DaysErrors,
         ...upTo30DaysErrors,
         ...dover30DaysErrors,
      };
   });
   const isAnyDelaysChargesError = checkIfErrorPresent(delaysChargesErrors);
   return {
      ...(isAnyDelaysChargesError && {
         delaysCharges: delaysChargesErrors,
      }),
   };
};

const validateRetentionConfig = ({ retentionConfig }: RateConfigForm) => {
   const { retentionPalletCosts, retentionPeriodDays } = retentionConfig;
   const retentionPalletCostsErrors = retentionPalletCosts.map(retentionPalletCost => {
      const retentionPalletCostErrors = validatePalletRate(retentionPalletCost.price, 'price');
      return retentionPalletCostErrors;
   });
   const isAnyRetentionPalletCostsError = checkIfErrorPresent(retentionPalletCostsErrors);
   const isAnyError = isAnyRetentionPalletCostsError || !retentionPeriodDays;
   return {
      ...(isAnyError && {
         retentionConfig: {
            ...(isAnyRetentionPalletCostsError && {
               retentionPalletCosts: retentionPalletCostsErrors,
            }),
            ...(!retentionPeriodDays && {
               retentionPeriodDays: EMPTY_INPUT_ERROR,
            }),
         },
      }),
   };
};

const validatePalletRate = (palletRate: string, field: string) => {
   if (!isNonEmpty(palletRate)) {
      return { [field]: EMPTY_INPUT_ERROR };
   } else if (!isNumeric(palletRate)) {
      return {
         [field]: NO_NUMERIC_VALUE_ERROR,
      };
   } else if (!moreOrEqualZero(palletRate)) {
      return {
         [field]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
      };
   }
};

const checkIfErrorPresent = (itemsToCheck: (Record<string, string> | undefined)[]) =>
   itemsToCheck.some(itemToCheck => itemToCheck && Object.keys(itemToCheck).length !== 0);

export const palletRatesConfigurationFormValidations = [
   validatePalletRates,
   validateDelaysCharges,
   validateRetentionConfig,
];
