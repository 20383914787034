import { Ban } from 'tabler-icons-react';

import { StyledTable } from 'components/shared';
import { ClientUserDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './ClientDetailsBanHistory.module.css';

const columnHeaders = [
   { label: 'Data', field: 'date' },
   { label: 'Akcja', field: 'actions' },
   { label: 'Treść', field: 'content' },
];

export const ClientDetailsBanHistory = (client: ClientUserDTO) => {
   return (
      <StyledTable className={styles.tableContainer} columnHeaders={columnHeaders}>
         {client.historyOfSuspensions.length ? (
            client.historyOfSuspensions.map((suspension, index) => {
               const formattedDate = new Date(suspension.createDate).toLocaleDateString();
               return (
                  <TableRow key={index}>
                     <TableCell>{formattedDate}</TableCell>
                     <TableCell>
                        {suspension.suspension.blocked ? (
                           <div className={styles.blockedRow}>
                              <Ban color="var(--primary-red)" />
                              <span style={{ color: 'var(--primary-red)' }}>Zablokowano</span>
                           </div>
                        ) : (
                           <div className={styles.blockedRow}>
                              <Ban />
                              Odblokowano
                           </div>
                        )}
                     </TableCell>
                     <TableCell>{suspension.suspension.reason || '-'}</TableCell>
                  </TableRow>
               );
            })
         ) : (
            <tr>
               <td align="center" colSpan={3}>
                  <div className={styles.noResultsContainer}>
                     <Ban size={42} /> <p>Brak historii blokad.</p>
                  </div>
               </td>
            </tr>
         )}
      </StyledTable>
   );
};
