import classNames from 'classnames';

import { Select, SelectProps } from 'storybook';

import MobileSelect from './MobileSelect/MobileSelect';
import styles from './StyledSelect.module.css';

type StyledSelectProps = SelectProps & {
   selectRef?: React.MutableRefObject<
      | {
           closeDropdown: () => void;
        }
      | undefined
   >;
   mobileView?: boolean;
};

const StyledSelect = ({ mobileView, selectRef, ...props }: StyledSelectProps) => (
   <>
      {!mobileView ? (
         <Select
            ref={selectRef}
            {...props}
            classNames={{
               container: classNames(styles.selectContainer, props.classNames?.container),
               select: classNames(styles.select, props.classNames?.select, {
                  [styles.error]: props.error,
               }),
               optionsContainer: classNames(
                  styles.optionsContainer,
                  props.classNames?.optionsContainer,
               ),
               filterInput: props.classNames?.filterInput,
            }}
         />
      ) : (
         <MobileSelect
            label={props.label}
            options={props.options}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            disabled={props.disabled}
            error={props.error}
            classNames={{
               container: props.classNames?.container,
               select: props.classNames?.select,
            }}
         />
      )}
   </>
);

export default StyledSelect;
