import { useEffect, useState } from 'react';

import { CustomModal, CustomModalProps, ErrorNotification, ModalLoader } from 'components/shared';
import { TransferActionDTO } from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';

import PalletsTransferHistoryActionBody from '../PalletsTransferHistoryActionBody/PalletsTransferHistoryActionBody';
import PalletsTransferHistoryActionHeader from '../PalletsTransferHistoryActionHeader/PalletsTransferHistoryActionHeader';

import styles from './PalletsTransferHistoryActionDetailsModal.module.css';

type PalletsTransferHistoryActionDetailsModalProps = Pick<CustomModalProps, 'onClose'> & {
   transferActionId: string;
};

const MODAL_WIDTH = 1400;

const PalletsTransferHistoryActionDetailsModal = ({
   transferActionId,
   onClose,
}: PalletsTransferHistoryActionDetailsModalProps) => {
   const [transferActionDetailsData, setTransferActionDetailsData] = useState<TransferActionDTO>();
   const [isTransferActionDetailsDataLoading, setIsTransferActionDetailsDataLoading] =
      useState(false);
   const [isTransferActionDetailsDataError, setIsTransferActionDetailsDataError] = useState(false);

   const handleTransferActionDetailsDataError = (error: unknown) =>
      errorHandler(error, () => setIsTransferActionDetailsDataError(true));

   useEffect(() => {
      setIsTransferActionDetailsDataLoading(true);
      HTTPService.getTransferActionDetails(transferActionId)
         .then(({ data: getTransferActionDetailsResponseData }) => {
            setTransferActionDetailsData(getTransferActionDetailsResponseData);
         })
         .catch(handleTransferActionDetailsDataError)
         .finally(() => setIsTransferActionDetailsDataLoading(false));
   }, [transferActionId]);

   const renderContent = () => {
      if (isTransferActionDetailsDataError) {
         return <ErrorNotification />;
      }
      if (isTransferActionDetailsDataLoading) {
         return <ModalLoader className={styles.modalLoader} />;
      }
      if (transferActionDetailsData) {
         const { type, createdBy, orderId, createdDate, entries } = transferActionDetailsData;
         return (
            <>
               <PalletsTransferHistoryActionHeader
                  type={type}
                  createdBy={createdBy}
                  orderId={orderId}
                  createdDate={createdDate}
               />
               <PalletsTransferHistoryActionBody
                  actionType={type}
                  transferActionItemsData={entries}
                  className={styles.actionBodyContainer}
               />
            </>
         );
      }
   };

   return (
      <CustomModal
         opened
         size={MODAL_WIDTH}
         secondaryButtonProps={{
            text: 'Wróć',
            variant: 'outlined-primary',
            onClick: onClose,
         }}
         classNames={{ header: styles.modalHeader }}
         modalContentClassName={styles.modalContent}
         onClose={onClose}
      >
         {renderContent()}
      </CustomModal>
   );
};

export default PalletsTransferHistoryActionDetailsModal;
