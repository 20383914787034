import {
   excelSendPalletsItemToSendPalletsItemMapper,
   getGroupedExcelSendPalletsItems,
} from '../common';

import { tarczynskiClientExcelRowToSendPalletsExcelRowMapper } from './mappers';
import { TarczynskiClientExcelRow } from './types';

export const handleTarczynskiClientSendPalletsDataFromExcel = (
   tarczynskiClientExcelRowsData: TarczynskiClientExcelRow[],
) => {
   // excel footer elimination
   const nonEmptyTarczynskiClientExcelRowsData = tarczynskiClientExcelRowsData.filter(
      excelRowData =>
         excelRowData['Rodzaj palety wysłanej'] &&
         excelRowData['Ilość wysłanych palet do sieci'] &&
         excelRowData['Data wysyłki palet do sieci'],
   );
   const sendPalletsExcelRowsData = nonEmptyTarczynskiClientExcelRowsData.map(
      tarczynskiClientExcelRowToSendPalletsExcelRowMapper,
   );
   const groupedExcelSendPalletsItems = getGroupedExcelSendPalletsItems(sendPalletsExcelRowsData);
   const sendPalletsItemsData = groupedExcelSendPalletsItems.map(
      excelSendPalletsItemToSendPalletsItemMapper,
   );
   return sendPalletsItemsData;
};
