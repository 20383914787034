import { FocusEvent, useCallback, useMemo } from 'react';

import { BannerList, BannerListItem, SharedGridTable } from 'components/shared';
import { commonPalletsPricingTableHeaders } from 'constants/tableHeaders';
import { CommonRatePerPalletConfigFormData, PalletType } from 'interfaces';
import { formatStringToPrice, getUniqueErrorMessagesFromErrorsObjectList } from 'utils/functions';
import { isNumeric } from 'utils/validation';

import CommonPalletsPricingFormTableRow from './CommonPalletsPricingFormTableRow/CommonPalletsPricingFormTableRow';

type CommonPalletsPricingProps = {
   values: CommonRatePerPalletConfigFormData[];
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   errors: any;
   touched: {
      [x: string]: boolean;
   };
   onChangePalletsPrice: (enteredValue: string, priceType: string, palletType: PalletType) => void;
};

const CommonPalletsPricing = ({
   values,
   errors,
   touched,
   onChangePalletsPrice,
}: CommonPalletsPricingProps) => {
   const handlePalletRatesChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>, palletType: PalletType) => {
         const {
            target: { name, value },
         } = event;
         onChangePalletsPrice(value, name, palletType);
      },
      [onChangePalletsPrice],
   );

   const handlePalletRatesBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>, palletType: PalletType) => {
         const {
            target: { name, value: enteredValue },
         } = event;
         const isNumericValue = isNumeric(enteredValue);
         if (!isNumericValue || !enteredValue) {
            return;
         }
         const formattedValue = formatStringToPrice(enteredValue);
         onChangePalletsPrice(formattedValue, name, palletType);
      },
      [onChangePalletsPrice],
   );

   const palletRatesErrorBannersData = useMemo(() => {
      if (!errors.palletRates?.common || !touched.palletRates) {
         return null;
      }
      const uniqueErrorMessages = getUniqueErrorMessagesFromErrorsObjectList(
         errors.palletRates.common,
      );
      return uniqueErrorMessages.map<BannerListItem>(uniqueErrorMessage => ({
         key: uniqueErrorMessage,
         variant: 'error',
         children: uniqueErrorMessage,
         fullWidth: true,
      }));
   }, [errors.palletRates, touched.palletRates]);

   const rows = useMemo(() => {
      return values.map((commonPalletPricingItem, index) => {
         return (
            <CommonPalletsPricingFormTableRow
               key={index}
               palletPricing={commonPalletPricingItem}
               error={
                  errors?.palletRates?.common &&
                  touched.palletRates &&
                  errors?.palletRates.common[index]
               }
               onPalletsPricingChange={handlePalletRatesChange}
               onPalletRatesBlur={handlePalletRatesBlur}
            />
         );
      });
   }, [
      errors?.palletRates,
      handlePalletRatesBlur,
      handlePalletRatesChange,
      touched.palletRates,
      values,
   ]);

   return (
      <>
         {palletRatesErrorBannersData && <BannerList bannersData={palletRatesErrorBannersData} />}
         <SharedGridTable headers={commonPalletsPricingTableHeaders} rows={rows} />
      </>
   );
};

export default CommonPalletsPricing;
