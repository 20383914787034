import React, { useState } from 'react';

import classNames from 'classnames';
import { Eraser } from 'tabler-icons-react';

import { StyledButton, StyledTextInput } from 'components/shared';
import { PalletData, PalletType } from 'interfaces/commonInterfacesAndTypes';
import { Card, CardProps, Divider } from 'storybook';
import { currencyFormatter } from 'utils/numberFormatter';
import { palletImages } from 'utils/palletImages';

import styles from './PalletConfigurationCard.module.css';

interface PalletConfigurationCardProps extends CardProps {
   name: PalletType;
   logisticMinimum: number;
   dimensions: string;
   price?: number;
   showPalletPrices?: boolean;
   onChange: (value: string, name: string) => void;
   onRadioChange: (name: string, orderType: 'LOGISTIC_MINIMUM' | 'CUSTOM') => void;
   onClear: (name: PalletType) => void;
   value?: PalletData;
   errors: { [key: string]: string };
}

const PalletConfigurationCard = ({
   name,
   dimensions,
   price,
   showPalletPrices,
   onRadioChange,
   logisticMinimum,
   onChange,
   onClear,
   value,
   errors,
   ...props
}: PalletConfigurationCardProps) => {
   const [orderType, setOrderType] = useState<'LOGISTIC_MINIMUM' | 'CUSTOM' | null>(
      value?.orderType || null,
   );
   const handleChange = (type: 'LOGISTIC_MINIMUM' | 'CUSTOM') => {
      setOrderType(type);
      onRadioChange(name, type);
   };
   const handleClearCard = (palletType: PalletType) => {
      setOrderType(null);
      onClear(palletType);
   };

   const isCustomPalletsAmountInputInvalid = orderType === 'CUSTOM' && !!errors[name];
   const isPalletsAmountAsLogisticMinimumInputInvalid =
      orderType === 'LOGISTIC_MINIMUM' && !!errors[name];

   return (
      <div className={classNames(styles.cardContainer, { [styles.cardInactive]: props.disabled })}>
         {props.disabled && <div className={styles.disabledContainerActive} />}
         <Card
            disabled={props.disabled}
            displayCheckedIcon
            checked={value && !errors[name]}
            className={classNames(styles.cardSmall, { [styles.cardDisabled]: props.disabled })}
            icon={<img className={styles.cardPalletImage} src={palletImages[name]} />}
            size="sm"
            title={props.title}
            bottomContent={
               <div className={styles.smCardBottomContent}>
                  <p>{dimensions}</p>
                  {showPalletPrices && (
                     <p>Cena (szt.): {price ? currencyFormatter.format(price) : '–'} PLN</p>
                  )}
                  <div className={styles.radioContainer}>
                     <div
                        className={classNames(styles.radioRow, {
                           [styles.radioRowError]: errors[name] && orderType === 'LOGISTIC_MINIMUM',
                        })}
                     >
                        <input
                           onChange={() => handleChange('LOGISTIC_MINIMUM')}
                           checked={orderType === 'LOGISTIC_MINIMUM'}
                           className={styles.radioInput}
                           name={name}
                           type="radio"
                        />
                        <StyledTextInput
                           disabled={orderType !== 'LOGISTIC_MINIMUM'}
                           className={classNames(styles.smInput, {
                              [styles.error]: errors[name],
                           })}
                           label="Minimum logistyczne"
                           name={name}
                           width="70px"
                           type="text"
                           placeholder="np. 2"
                           value={value?.orderType === 'LOGISTIC_MINIMUM' ? value.amount : ''}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              onChange(e.target.value, name)
                           }
                           error={isPalletsAmountAsLogisticMinimumInputInvalid}
                           helperText={
                              isPalletsAmountAsLogisticMinimumInputInvalid
                                 ? errors[name]
                                 : undefined
                           }
                        />
                        <p>x {logisticMinimum} palet</p>
                     </div>
                     <div className={styles.radioRow}>
                        <input
                           onChange={() => handleChange('CUSTOM')}
                           checked={orderType === 'CUSTOM'}
                           className={styles.radioInput}
                           name={name}
                           type="radio"
                        />
                        <StyledTextInput
                           className={classNames(styles.smInput, {
                              [styles.error]: errors[name],
                           })}
                           disabled={orderType !== 'CUSTOM'}
                           label="Niestandardowa liczba"
                           width="115px"
                           type="text"
                           value={value?.orderType === 'CUSTOM' ? value.amount : ''}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              onChange(e.target.value, name)
                           }
                           placeholder="np. 1 000"
                           error={isCustomPalletsAmountInputInvalid}
                           helperText={isCustomPalletsAmountInputInvalid ? errors[name] : undefined}
                        />
                        <p>palet</p>
                     </div>
                  </div>
                  <div className={styles.clearBtnSection}>
                     <Divider variant="horizontal" className={styles.divider} />
                     <StyledButton
                        type="button"
                        variant="text"
                        text="Wyczyść"
                        onClick={() => handleClearCard(name)}
                        icon={<Eraser size="22px" />}
                        disabled={props.disabled || !value?.orderType}
                        className={styles.clearBtn}
                     />
                  </div>
               </div>
            }
         />
      </div>
   );
};

export default PalletConfigurationCard;
