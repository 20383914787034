import { SectionContainer, StyledRadioButton } from 'components/shared';
import { DeliveryType } from 'interfaces';
import { ButtonGroup } from 'storybook';

import styles from './SelectDeliveryTypeSection.module.css';

type SelectDeliveryTypeSectionProps = {
   selectedDeliveryType: DeliveryType;
   onDeliveryTypeChange: (value: DeliveryType) => void;
};

const SelectDeliveryTypeSection = ({
   selectedDeliveryType,
   onDeliveryTypeChange,
}: SelectDeliveryTypeSectionProps) => (
   <SectionContainer title="Sposób dostawy">
      <ButtonGroup label="Wybierz sposób dostawy" containerClassName={styles.buttonGroupContainer}>
         <StyledRadioButton
            label="Dołącz do następnego zamówienia"
            checked={selectedDeliveryType === 'ADDED_TO_NEXT_ORDER'}
            buttonView
            onChangeHandler={() => onDeliveryTypeChange('ADDED_TO_NEXT_ORDER')}
         />
         <StyledRadioButton
            label="Wyślij w osobnym zamówieniu"
            checked={selectedDeliveryType === 'SEPARATE_ORDER'}
            buttonView
            onChangeHandler={() => onDeliveryTypeChange('SEPARATE_ORDER')}
         />
      </ButtonGroup>
   </SectionContainer>
);

export default SelectDeliveryTypeSection;
