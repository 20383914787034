import React, { ReactNode, useState } from 'react';

import classNames from 'classnames';

import { ArrowsIcon, SortDownIcon, SortUpIcon } from 'storybook/assets';

import styles from './Table.module.css';

export interface TableProps {
   className?: string;
   headerClassName?: string;
   columnHeaders: TableHeader[];
   children: ReactNode;
   handleSort?: (field: string, direction: 'DESC' | 'ASC') => void;
   isFixedHeader?: boolean;
   tableRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export type TableHeader = {
   label: string;
   additionalInfo?: string;
   field: string;
   sortable?: boolean;
   align?: 'left' | 'right' | 'center';
   noWrapText?: boolean;
};

export const Table = ({
   className,
   headerClassName,
   columnHeaders,
   children,
   handleSort,
   isFixedHeader,
   tableRef,
}: TableProps) => {
   const [activeSortingField, setActiveSortingField] = useState('');
   const [sortDirection, setSortDirection] = useState('');

   const handleSortClick = (field: string) => {
      setActiveSortingField(field);
      setSortDirection('DESC');
      handleSort && handleSort(field, 'DESC');
   };
   const handleSortDescendingClick = () => {
      setSortDirection('ASC');
      handleSort && handleSort(activeSortingField, 'ASC');
   };
   const handleSortAscendingClick = () => {
      setSortDirection('DESC');
      handleSort && handleSort(activeSortingField, 'DESC');
   };
   return (
      <div ref={tableRef} className={classNames(styles.tableContainer, className)}>
         <table>
            <thead>
               <tr
                  className={classNames(
                     styles.tableHeaderRow,
                     { [styles.fixed]: isFixedHeader },
                     headerClassName,
                  )}
               >
                  {columnHeaders.map((columnHeader, index) => {
                     return (
                        <th className={styles.tableHeader} key={index}>
                           <div
                              className={classNames(styles.tableHeaderInnerContainer, {
                                 [styles.right]: columnHeader.align === 'right',
                                 [styles.left]: columnHeader.align === 'left',
                                 [styles.center]: columnHeader.align === 'center',
                              })}
                           >
                              <div
                                 className={classNames(styles.tableHeaderText, {
                                    [styles.noWrap]: columnHeader.noWrapText,
                                 })}
                              >
                                 <span>{columnHeader.label}</span>
                                 {columnHeader.additionalInfo && (
                                    <span className={styles.tableHeaderAdditionalInfo}>
                                       {columnHeader.additionalInfo}
                                    </span>
                                 )}
                              </div>
                              {columnHeader.sortable &&
                                 activeSortingField !== columnHeader.field && (
                                    <ArrowsIcon
                                       onClick={() => handleSortClick(columnHeader.field)}
                                       className={classNames(styles.sortIcon, styles.grayIcon)}
                                    />
                                 )}
                              {activeSortingField === columnHeader.field &&
                                 sortDirection === 'DESC' && (
                                    <SortDownIcon
                                       onClick={handleSortDescendingClick}
                                       className={classNames(styles.sortIcon, styles.blueIcon)}
                                    />
                                 )}
                              {activeSortingField === columnHeader.field &&
                                 sortDirection === 'ASC' && (
                                    <SortUpIcon
                                       onClick={handleSortAscendingClick}
                                       className={classNames(styles.sortIcon, styles.blueIcon)}
                                    />
                                 )}
                           </div>
                        </th>
                     );
                  })}
               </tr>
            </thead>
            <tbody className={styles.tableBody}>{children}</tbody>
         </table>
      </div>
   );
};
