import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import {
   AdditionalChargesSection,
   EditDeliverySection,
   EditPalletsConfigurationSection,
   EditRentalPeriodSection,
   OverviewDeliverySection,
   OverviewPalletsConfigurationSection,
   OverviewRentalPeriodSection,
} from 'components/Order';
import { SendPalletsSection, SendToSettlementModal } from 'components/OrderDetails';
import { NoSelectedStorageAlertModal } from 'components/Orders';
import { ReportLostDamagedPalletsModal } from 'components/ReportLostDamagedPalletsModal';
import { SendPalletsModal } from 'components/SendPalletsModal';
import { CustomModal } from 'components/shared';
import SuccessReportLostDamagedPalletsBySupplierModal from 'components/SuccessReportLostDamagedPalletsBySupplierModal/SuccessReportLostDamagedPalletsBySupplierModal';
import {
   AcceptOrderModal,
   AddAdditionalChargesModal,
   AddFreeOrdersToNextOrderModal,
   ChangePalletsPricesModal,
   CloseOrderModal,
   EditStorageSection,
   LostDamagedPalletsInDeliverySection,
   OverviewStorageSection,
   RejectOrderModal,
   SendOrderForPaymentModal,
} from 'components/SupplierPanel';
import { SUPPLIER_ORDER_DETAILS_BANNERS } from 'constants/banners';
import { DEFAULT_PALLET_CUSTOM_PRICES } from 'constants/defaultPalletCustomPrices';
import { editableOrderStatuses } from 'constants/editableOrderStatuses';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { SELF_PICKUP } from 'constants/orderAddress';
import { orderStatusesWithVisibleAdditionalSections } from 'constants/orderStatusesWithVisibleAdditionalSections';
import { palletTypesData } from 'constants/palletTypes';
import { useOrderForm } from 'hooks/useOrderForm';
import { useRegularOrderDetails } from 'hooks/useRegularOrderDetails';
import { useSendPalletsForm } from 'hooks/useSendPalletsForm';
import {
   AdditionalOrder,
   BannerData,
   ClientUserDTO,
   CommercialNetworkDetailsDTO,
   GroupedLostDamagedPalletItems,
   LostDamagedPalletReport,
   OrderDetails,
   OrderDetailsDTO,
   OrderDetailsPageSection,
   OrderDetailsPallet,
   OrderDTO,
   PalletCustomPrices,
   PalletDataWithPrice,
   PalletReportDTO,
   PalletSpecification,
   RegularOrderDetails,
   StorageDTO,
   SupplierEditRegularOrderData,
   SupplierOrderDetailsPageModalsOrDialogs,
} from 'interfaces';
import { HTTPService } from 'service';
import { ChangeOrderStatusAction, UpdateOrderRequest } from 'service/http/requests';
import {
   calculatePalletsAmountAsLogisticMinimum,
   countPalletsOrderTotalPrice,
   getClientAddressOptions,
   getCommercialNetworkOptions,
   getFilteredLostDamagedPalletReports,
   getFormattedDate,
   getFullAddress,
   getOrderDetailsData,
   getPalletContractPrices,
   getPalletCustomPrices,
   getPalletPrice,
   getPalletSendingDestinationAddresses,
   getPalletsFullData,
   getSendPalletTypes,
   getStorageAddressLabel,
   getStorageAddressOptions,
   parseDate,
} from 'utils/functions';
import { parseClientDeliveryAddressIntoDeliveryAddress } from 'utils/parsers';
import { editRegularOrderFormValidations } from 'utils/validation';

import styles from './RegularOrderDetailsPage.module.css';

type RegularOrderDetailsPageProps = {
   order: RegularOrderDetails;
   initialFormValues: SupplierEditRegularOrderData;
   client: ClientUserDTO;
   storages: StorageDTO[];
   commercialNetworks: CommercialNetworkDetailsDTO[];
   lostDamagedPalletsReports: PalletReportDTO[];
   pallets: OrderDetailsPallet[];
   palletsSum: number;
   chargesTotalAmount: number;
   isOrderFree?: boolean;
   isOrderNonstandard?: boolean;
   sectionsEditMode: SectionsLoadingAndEditModeState;
   selectedModalOrDialog: SupplierOrderDetailsPageModalsOrDialogs;
   sectionsLoading: SectionsLoadingAndEditModeState;
   fetchOrder: () => void;
   setOrder: Dispatch<SetStateAction<OrderDetails | undefined>>;
   setLostDamagedPalletsReports: Dispatch<SetStateAction<PalletReportDTO[] | undefined>>;
   showModalOrDialog: (modalOrDialogName: SupplierOrderDetailsPageModalsOrDialogs) => void;
   handleCloseModalOrDialog: () => void;
   handleSuccessOrderAction: (
      action: ChangeOrderStatusAction,
      updatedOrder: OrderDetailsDTO,
   ) => void;
   dispatchSectionsEditMode: (dispatchData: {
      section: OrderDetailsPageSection;
      editMode: boolean;
   }) => void;
   dispatchSectionsLoading: (dispatchData: {
      section: OrderDetailsPageSection;
      isLoading: boolean;
   }) => void;
   scrollToTop: () => void;
   showBanner: (bannerData: BannerData | null) => void;
   onError: (error: unknown, errorDescription: string) => void;
};

type SectionsLoadingAndEditModeState = {
   deliverySection: boolean;
   rentalPeriodSection: boolean;
   palletsConfigurationSection: boolean;
   storageSection: boolean;
};

const RegularOrderDetailsPage = ({
   order,
   initialFormValues,
   client,
   storages,
   commercialNetworks,
   lostDamagedPalletsReports,
   pallets,
   palletsSum,
   chargesTotalAmount,
   isOrderFree,
   isOrderNonstandard,
   sectionsEditMode,
   selectedModalOrDialog,
   sectionsLoading,
   fetchOrder,
   setOrder,
   setLostDamagedPalletsReports,
   showModalOrDialog,
   handleCloseModalOrDialog,
   handleSuccessOrderAction,
   dispatchSectionsEditMode,
   dispatchSectionsLoading,
   scrollToTop,
   showBanner,
   onError,
}: RegularOrderDetailsPageProps) => {
   const clientDeliveryAddresses = useMemo(
      () => client.deliveryAddresses.map(parseClientDeliveryAddressIntoDeliveryAddress),
      [client.deliveryAddresses],
   );

   const {
      values,
      touched,
      errorsList,
      selectChangeHandler,
      palletQuantityChangeHandler,
      handlePalletRadioChange,
      handleClearPallet,
      deliveryAddressOptions,
      storageOptions,
      holdingPeriod,
      setValues,
      handleChangeAddress,
      palletCustomPrices,
      handleChangePalletPrices,
      setPalletCustomPrices,
   } = useOrderForm({
      initialState: initialFormValues,
      validations: editRegularOrderFormValidations,
      retentionPeriodDays: client.rateConfig?.retentionConfig.retentionPeriodDays,
      deliveryAddresses: clientDeliveryAddresses,
      storages,
   });
   const {
      groupedLostDamagedPalletItems,
      sendingAddressOptions,
      setStorageAddressOptions,
      setCommercialNetworkAddressOptions,
      setClientAddressOptions,
      setGroupedLostDamagedPalletItems,
      handleAddCommercialNetworkOption,
      handleAddCommercialNetworkAddressOption,
   } = useSendPalletsForm();
   const [isInEditMode, setIsInEditMode] = useState(false);
   const { availablePalletTypesToReport, availablePalletTypesToSend } = useRegularOrderDetails({
      order,
      groupedLostDamagedPalletItems,
      edit: isInEditMode,
   });
   const [initialPalletCustomPrices, setInitialPalletCustomPrices] = useState<PalletCustomPrices>(
      DEFAULT_PALLET_CUSTOM_PRICES(),
   );
   const [availableAdditionalOrders, setAvailableAdditionalOrders] = useState<AdditionalOrder[]>(
      [],
   );

   const palletContractPrices = useMemo(() => getPalletContractPrices(client), [client]);

   const preparePricesData = useCallback(() => {
      const fullAddress =
         order.address && !order.personalPickUp
            ? getFullAddress(
                 order.address.street,
                 order.address.zipCode,
                 order.address.city,
                 order.address.name,
              )
            : undefined;
      const palltCustomPrices = getPalletCustomPrices(
         palletContractPrices,
         order.pallets,
         fullAddress,
      );
      setPalletCustomPrices(palltCustomPrices);
      setInitialPalletCustomPrices(palltCustomPrices);
   }, [
      order.address,
      order.pallets,
      order.personalPickUp,
      palletContractPrices,
      setPalletCustomPrices,
   ]);

   const prepareAddressOptionsData = useCallback(() => {
      const clientDeliveryAddressOptions = getClientAddressOptions(clientDeliveryAddresses);
      const storageDeliveryAddressOptions = getStorageAddressOptions(storages);
      const commercialNetworkDeliveryAddressOptions =
         getCommercialNetworkOptions(commercialNetworks);
      setClientAddressOptions({
         nameOptions: clientDeliveryAddressOptions.clientNameOption,
         addressOptions: clientDeliveryAddressOptions.clientAddressOptions,
      });
      setStorageAddressOptions({
         nameOptions: storageDeliveryAddressOptions.storageNameOptions,
         addressOptions: storageDeliveryAddressOptions.storageAddressOptions,
      });
      setCommercialNetworkAddressOptions({
         nameOptions: commercialNetworkDeliveryAddressOptions.nameOptions,
         addressOptions: commercialNetworkDeliveryAddressOptions.addressOptions,
      });
   }, [
      clientDeliveryAddresses,
      commercialNetworks,
      setClientAddressOptions,
      setCommercialNetworkAddressOptions,
      setStorageAddressOptions,
      storages,
   ]);

   useEffect(() => {
      preparePricesData();
      prepareAddressOptionsData();
   }, [preparePricesData, prepareAddressOptionsData]);

   const handleSaveSectionData = (section: OrderDetailsPageSection) => {
      if (
         order.mode !== 'REGULAR_ORDER' ||
         (section === 'DELIVERY' && errorsList.address) ||
         (section === 'RENTAL_PERIOD' && errorsList.palletDeliveryDate) ||
         (section === 'PALLETS_CONFIGURATION' && errorsList.pallets) ||
         (section === 'STORAGE' && errorsList.storage)
      ) {
         return;
      }
      let updateData: UpdateOrderRequest = {
         palletDeliveryDate: order.palletDeliveryDate,
         pallets: order.pallets,
         addressId: null,
         personalPickUp: order.personalPickUp,
         storageID: order.storage?.id,
      };
      if (section === 'DELIVERY') {
         updateData = {
            ...updateData,
            addressId: typeof values.address === 'object' ? values.address.value.id : undefined,
            personalPickUp: values.address === 'SELF_PICKUP',
            storageID: values.storage?.value.storageId || null,
         };
      }
      if (section === 'RENTAL_PERIOD') {
         const formattedPalletDeliveryDate = getFormattedDate(values.palletDeliveryDate);
         updateData = { ...updateData, palletDeliveryDate: formattedPalletDeliveryDate };
      }
      if (section === 'DELIVERY' || section === 'PALLETS_CONFIGURATION') {
         const palletsWithAdditionalInfo = getPalletsFullData(
            values.pallets,
            (pallet: PalletDataWithPrice) => (palletType: PalletSpecification) =>
               palletType.name === pallet.name,
         );
         const fullAddress =
            values.address && values.address !== SELF_PICKUP
               ? getFullAddress(
                    values.address.value.street,
                    values.address.value.zipCode,
                    values.address.value.city,
                    values.address.value.name,
                 )
               : undefined;
         const orderPallets = palletsWithAdditionalInfo.map(palletWithAdditionalInfo => {
            const amount =
               palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM'
                  ? parseInt(palletWithAdditionalInfo.amount) *
                    palletWithAdditionalInfo.logisticMinimum
                  : parseInt(palletWithAdditionalInfo.amount);
            return {
               palletType: palletWithAdditionalInfo.name,
               amount,
               pricePerUnit: palletContractPrices
                  ? getPalletPrice(
                       palletWithAdditionalInfo.name,
                       palletContractPrices,
                       palletCustomPrices,
                       fullAddress,
                    )
                  : 0,
               shownAsMultipleOfTheMinimum:
                  palletWithAdditionalInfo.orderType === 'LOGISTIC_MINIMUM',
            };
         });
         updateData = { ...updateData, pallets: orderPallets };
      }
      if (section === 'STORAGE') {
         updateData = { ...updateData, storageID: values.storage.value.storageId };
      }
      dispatchSectionsLoading({ section, isLoading: true });
      HTTPService.updateOrder(order.id, updateData)
         .then(responseData => {
            const {
               palletDeliveryDate,
               retentionDate,
               address,
               pallets: palletsData,
               storage,
               personalPickUp,
               hasMetLogisticsMinimum,
            } = responseData.data;
            setOrder(prevOrder => {
               if (
                  prevOrder?.mode === 'REGULAR_ORDER' &&
                  palletDeliveryDate &&
                  retentionDate &&
                  address
               ) {
                  return {
                     ...prevOrder,
                     palletDeliveryDate,
                     pallets: palletsData,
                     address,
                     storage,
                     personalPickUp,
                     hasMetLogisticsMinimum,
                  };
               } else {
                  return undefined;
               }
            });
            dispatchSectionsEditMode({ section, editMode: false });
         })
         .catch(error => onError(error, DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION))
         .finally(() => dispatchSectionsLoading({ section: section, isLoading: false }));
   };

   const handleCancelSectionDataChanges = (section: OrderDetailsPageSection) => {
      dispatchSectionsEditMode({
         section,
         editMode: false,
      });
      switch (section) {
         case 'DELIVERY':
            setValues(prevValues => ({
               ...prevValues,
               address:
                  order.address && !order.personalPickUp
                     ? {
                          label: getFullAddress(
                             order.address.street,
                             order.address.zipCode,
                             order.address.city,
                             order.address.name,
                          ),
                          value: {
                             street: order.address.street,
                             city: order.address.city,
                             name: order.address.name,
                             zipCode: order.address.zipCode,
                          },
                       }
                     : 'SELF_PICKUP',
               storage: order.storage
                  ? {
                       value: { storageId: order.storage.id, storageType: order.storage.type },
                       label: `${order.storage.address.street}, ${order.storage.address.zipCode} ${order.storage.address.city}`,
                    }
                  : null,
            }));
            break;
         case 'RENTAL_PERIOD':
            setValues(prevValues => ({
               ...prevValues,
               palletDeliveryDate: parseDate(order.palletDeliveryDate),
            }));
            break;
         case 'PALLETS_CONFIGURATION':
            const palletsData: PalletDataWithPrice[] = order.pallets.map(pallet => ({
               name: pallet.palletType,
               amount: pallet.shownAsMultipleOfTheMinimum
                  ? calculatePalletsAmountAsLogisticMinimum(
                       pallet.palletType,
                       pallet.amount,
                    ).toString()
                  : pallet.amount.toString(),
               orderType: pallet.shownAsMultipleOfTheMinimum ? 'LOGISTIC_MINIMUM' : 'CUSTOM',
               pricePerUnit: pallet.pricePerUnit,
            }));
            setValues(prevValues => ({
               ...prevValues,
               pallets: palletsData,
            }));
            setPalletCustomPrices(initialPalletCustomPrices);
            break;
         case 'STORAGE':
            setValues(prevValues => ({
               ...prevValues,
               storage: order.storage
                  ? {
                       value: { storageId: order.storage.id, storageType: order.storage.type },
                       label: `${order.storage.address.street}, ${order.storage.address.zipCode} ${order.storage.address.city}`,
                    }
                  : null,
            }));
      }
   };

   const fetchAdditionalOrdersForAddingToOrder = () =>
      HTTPService.getAdditionalOrders(client.id).then(
         ({ data: getAdditionalOrdersResponseData }) => {
            const additionalOrdersData = getAdditionalOrdersResponseData.additionalOrders;
            if (additionalOrdersData.length) {
               setAvailableAdditionalOrders(additionalOrdersData);
               showModalOrDialog('ADD_FREE_ORDERS_TO_NEXT_ORDER_MODAL');
            }
         },
      );

   const handleReportDamagedPallets = useCallback(
      (groupedLostDamagedPalletItemsData: GroupedLostDamagedPalletItems) => {
         setGroupedLostDamagedPalletItems(groupedLostDamagedPalletItemsData);
         showModalOrDialog('SEND_PALLETS_MODAL');
      },
      [setGroupedLostDamagedPalletItems, showModalOrDialog],
   );

   const handleCloseSuccessReportLostDamagedPalletsBySupplierModal = () => {
      handleCloseModalOrDialog();
      scrollToTop();
   };

   const handleSuccessRejectOrder = (updatedOrder: OrderDetailsDTO) =>
      handleSuccessOrderAction('REFUSE', updatedOrder);

   const handleSuccessAcceptOrder = (updatedOrder: OrderDetailsDTO) => {
      handleSuccessOrderAction('ACCEPT', updatedOrder);
      return fetchAdditionalOrdersForAddingToOrder();
   };

   const handleSuccessCloseOrder = (updatedOrder: OrderDetailsDTO) =>
      handleSuccessOrderAction('ENDED', updatedOrder);

   const handleSuccessSendOrderForPayment = (updatedOrder: OrderDetailsDTO) =>
      handleSuccessOrderAction('TO_PAY', updatedOrder);

   const handleSuccessSendForSettlement = (updatedOrder: OrderDetailsDTO) =>
      handleSuccessOrderAction('SUBMIT_FOR_BILLING', updatedOrder);

   const handleSuccessSendOrEditPallets = (orderDetails: OrderDetailsDTO, isEditMode: boolean) => {
      isEditMode
         ? showBanner(SUPPLIER_ORDER_DETAILS_BANNERS.EDIT_SEND_PALLETS)
         : showBanner(SUPPLIER_ORDER_DETAILS_BANNERS.SEND_PALLETS);
      setOrder(getOrderDetailsData(orderDetails));
      scrollToTop();
   };

   const handleSuccessReportSendDamagedPallets = useCallback(
      (damagedBySupplier: boolean, palletReport: PalletReportDTO) => {
         fetchOrder();
         setGroupedLostDamagedPalletItems(null);
         if (damagedBySupplier) {
            setLostDamagedPalletsReports(prevReports =>
               prevReports ? [...prevReports, palletReport] : undefined,
            );
            showModalOrDialog('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL');
         } else {
            scrollToTop();
         }
         showBanner(
            damagedBySupplier
               ? SUPPLIER_ORDER_DETAILS_BANNERS.SEND_DAMAGED_PALLETS
               : SUPPLIER_ORDER_DETAILS_BANNERS.SEND_DAMAGED_PALLETS,
         );
      },
      [
         setGroupedLostDamagedPalletItems,
         setLostDamagedPalletsReports,
         showModalOrDialog,
         showBanner,
         fetchOrder,
         scrollToTop,
      ],
   );

   const handleSuccessAddAdditionalCharges = (orderDetails: OrderDTO) => {
      showBanner(SUPPLIER_ORDER_DETAILS_BANNERS.ADD_ADDITIONAL_CHARGES);
      setOrder(prevOrder => {
         if (prevOrder) {
            return { ...prevOrder, additionalCharges: orderDetails.additionalCharges };
         }
      });
      scrollToTop();
   };

   const handleSuccessReportLostDestroyedPalletsByClient = () => {
      fetchOrder();
      showBanner(SUPPLIER_ORDER_DETAILS_BANNERS.REPORT_LOST_DESTROYED_PALLETS_BY_CLIENT);
      scrollToTop();
   };

   const handleSuccessReportLostDestroyedPalletsBySupplier = (reports: PalletReportDTO[]) => {
      const [report] = reports;
      fetchOrder();
      setLostDamagedPalletsReports(prevReports =>
         prevReports ? [report, ...prevReports] : undefined,
      );
      showModalOrDialog('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL');
      showBanner(SUPPLIER_ORDER_DETAILS_BANNERS.REPORT_LOST_DESTROYED_PALLETS_BY_SUPPLIER);
   };

   const handleSuccessAddFreeOrdersToNextOrder = () => {
      fetchOrder();
      handleCloseModalOrDialog();
   };

   const availablePalletTypesToAddAdditionalCharges = useMemo(
      () => getSendPalletTypes(order.sendPallets),
      [order],
   );

   const palletSendingDestinationAddresses = useMemo(
      () =>
         order.sendPallets.length ? getPalletSendingDestinationAddresses(order.sendPallets) : null,
      [order],
   );

   const lostDestroyedPalletReports = useMemo(
      () => getFilteredLostDamagedPalletReports(lostDamagedPalletsReports, 'LOST_OR_DESTRUCTION'),
      [lostDamagedPalletsReports],
   );

   const damagedPalletReports = useMemo(
      () => getFilteredLostDamagedPalletReports(lostDamagedPalletsReports, 'DAMAGE'),
      [lostDamagedPalletsReports],
   );

   const pricesForPallets = client?.rateConfig?.palletRates;
   const withReportingSendingPalletsAbility = order?.status === 'DELIVERED';
   const isAvailableEditingSentPallets =
      (withReportingSendingPalletsAbility || order?.status === 'DURING_BILLING') &&
      order.sendPallets.length;
   const isVisibleAdditionalSections =
      orderStatusesWithVisibleAdditionalSections.includes(order.status) ||
      (order.previousStatus &&
         orderStatusesWithVisibleAdditionalSections.includes(order.previousStatus));
   const selectedFullAddress =
      values.address && values.address !== SELF_PICKUP
         ? getFullAddress(
              values.address.value.street,
              values.address.value.zipCode,
              values.address.value.city,
              values.address.value.name,
           )
         : undefined;

   return (
      <>
         <RejectOrderModal
            opened={selectedModalOrDialog === 'REJECT_ORDER_MODAL'}
            onClose={handleCloseModalOrDialog}
            selectedOrderId={order.id}
            isOrderNonstandard={isOrderNonstandard}
            onSuccessRejectOrder={handleSuccessRejectOrder}
         />
         <AcceptOrderModal
            opened={selectedModalOrDialog === 'ACCEPT_ORDER_MODAL'}
            onClose={handleCloseModalOrDialog}
            selectedOrderId={order.id}
            isOrderNonstandard={isOrderNonstandard}
            onSuccessAcceptOrder={handleSuccessAcceptOrder}
         />
         <CloseOrderModal
            orderId={order.id}
            onSuccessCloseOrder={handleSuccessCloseOrder}
            opened={selectedModalOrDialog === 'CLOSE_ORDER_MODAL'}
            onClose={handleCloseModalOrDialog}
         />
         <SendOrderForPaymentModal
            opened={selectedModalOrDialog === 'SEND_FOR_PAYMENT'}
            onClose={handleCloseModalOrDialog}
            orderId={order.id}
            onSuccessSendForPayment={handleSuccessSendOrderForPayment}
            hasAdditionalCharges={!!order.additionalCharges.length}
         />
         <SendToSettlementModal
            orderId={order.id}
            additionalCharges={order.additionalCharges}
            remainingPalletsToBeSend={order.remainingPalletsToBeSend}
            onSendForSettlementSuccess={handleSuccessSendForSettlement}
            opened={selectedModalOrDialog === 'SETTLEMENT_MODAL'}
            onClose={handleCloseModalOrDialog}
         />
         <ChangePalletsPricesModal
            availablePalletTypes={client.availablePalletTypes}
            address={selectedFullAddress}
            palletContractPrices={palletContractPrices}
            palletCustomPrices={palletCustomPrices}
            onSubmitChanges={handleChangePalletPrices}
            opened={selectedModalOrDialog === 'CHANGE_PALETS_PRICES_MODAL'}
            onClose={handleCloseModalOrDialog}
         />
         <NoSelectedStorageAlertModal
            opened={selectedModalOrDialog === 'NO_PICKUP_ADDRESS_MODAL'}
            isPersonalPickUpAddress={order.personalPickUp}
            onClose={handleCloseModalOrDialog}
         />
         <SendPalletsModal
            orderId={order.id}
            clientId={client.id}
            availablePalletTypes={availablePalletTypesToSend}
            onSuccessSendOrEditPalletsFromOrder={handleSuccessSendOrEditPallets}
            opened={selectedModalOrDialog === 'SEND_PALLETS_MODAL'}
            clientCompanyName={client.companyName}
            onClose={() => {
               handleCloseModalOrDialog();
               setGroupedLostDamagedPalletItems(null);
               isInEditMode && setIsInEditMode(false);
            }}
            sendingAddressOptions={sendingAddressOptions}
            {...(groupedLostDamagedPalletItems ? { groupedLostDamagedPalletItems } : {})}
            onAddCommercialNetwork={handleAddCommercialNetworkOption}
            onAddCommercialNetworkAddress={handleAddCommercialNetworkAddressOption}
            onSuccessReportSendDamagedPalletsFromOrder={handleSuccessReportSendDamagedPallets}
            editPalletsItems={isInEditMode ? order.sendPallets : undefined}
         />
         <AddAdditionalChargesModal
            orderId={order.id}
            onSuccessAddAdditionalCharges={handleSuccessAddAdditionalCharges}
            palletTypes={availablePalletTypesToAddAdditionalCharges}
            palletSendingDestinationAddresses={palletSendingDestinationAddresses}
            opened={selectedModalOrDialog === 'ADD_ADDITIONAL_CHARGES_MODAL'}
            onClose={handleCloseModalOrDialog}
         />
         <ReportLostDamagedPalletsModal
            orderId={order.id}
            isSupplier
            pricesForPallets={pricesForPallets}
            availablePalletTypes={availablePalletTypesToReport}
            remainingPalletsToBeSend={order.remainingPalletsToBeSend}
            onSuccessReportLostDestroyedPalletsByClient={
               handleSuccessReportLostDestroyedPalletsByClient
            }
            onSuccessReportLostDestroyedPalletsBySupplier={
               handleSuccessReportLostDestroyedPalletsBySupplier
            }
            opened={selectedModalOrDialog === 'REPORT_DAMAGE_LOSS_PALLETS_MODAL'}
            onClose={handleCloseModalOrDialog}
            onReportDamagedPallets={handleReportDamagedPallets}
         />
         <SuccessReportLostDamagedPalletsBySupplierModal
            opened={
               selectedModalOrDialog === 'SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL'
            }
            onClose={handleCloseSuccessReportLostDamagedPalletsBySupplierModal}
            isSupplier
         />
         <CustomModal
            icon="FILE_LIKE"
            innerTitle="Faktura do tego zamówienia została wystawiona."
            opened={selectedModalOrDialog === 'SUCCESS_CREATE_AND_REPORT_INVOICE_DIALOG'}
            centeredButtons
            onClose={handleCloseModalOrDialog}
            primaryButtonProps={{
               text: 'Zamknij',
               variant: 'outlined-primary',
               onClick: handleCloseModalOrDialog,
            }}
         >
            <p className={styles.createAndSendDialogText}>Znajdziesz ją w zakładce „Dokumenty”.</p>
         </CustomModal>
         <AddFreeOrdersToNextOrderModal
            availableAdditionalOrders={availableAdditionalOrders}
            parentOrderId={order.id}
            opened={selectedModalOrDialog === 'ADD_FREE_ORDERS_TO_NEXT_ORDER_MODAL'}
            onSuccess={handleSuccessAddFreeOrdersToNextOrder}
            onClose={handleCloseModalOrDialog}
         />

         {sectionsEditMode.deliverySection ? (
            <EditDeliverySection
               selectedAddress={values.address}
               availableAddresses={deliveryAddressOptions}
               selectedStorage={values.storage}
               availableStorages={storageOptions}
               onChangeAddress={handleChangeAddress}
               onSave={() => handleSaveSectionData('DELIVERY')}
               onCancel={() => handleCancelSectionDataChanges('DELIVERY')}
               error={errorsList.address}
               touched={touched.address}
               isLoading={sectionsLoading.deliverySection}
            />
         ) : (
            <OverviewDeliverySection
               viewMode="SUPPLIER"
               address={order.personalPickUp ? 'SELF_PICKUP' : order.address}
               storageAddress={order.storage ? getStorageAddressLabel(order.storage) : undefined}
               onClickEdit={
                  editableOrderStatuses.includes(order.status)
                     ? () =>
                          dispatchSectionsEditMode({
                             section: 'DELIVERY',
                             editMode: true,
                          })
                     : undefined
               }
               disabledEditAction={
                  editableOrderStatuses.includes(order.status) &&
                  (sectionsEditMode.storageSection || sectionsEditMode.palletsConfigurationSection)
               }
            />
         )}
         {sectionsEditMode.rentalPeriodSection ? (
            <EditRentalPeriodSection
               isSupplierMode
               deliveryDate={values.palletDeliveryDate}
               holdingPeriod={holdingPeriod}
               retentionPeriodDays={client.rateConfig?.retentionConfig.retentionPeriodDays}
               onSelectChange={selectChangeHandler}
               onSave={() => handleSaveSectionData('RENTAL_PERIOD')}
               onCancel={() => handleCancelSectionDataChanges('RENTAL_PERIOD')}
               error={errorsList.palletDeliveryDate}
               touched={touched.palletDeliveryDate}
               isLoading={sectionsLoading.rentalPeriodSection}
            />
         ) : (
            <OverviewRentalPeriodSection
               palletDeliveryDate={order.palletDeliveryDate}
               holdingPeriod={order.retentionDate}
               retentionPeriodDays={String(client.rateConfig?.retentionConfig.retentionPeriodDays)}
               onClickEdit={
                  editableOrderStatuses.includes(order.status)
                     ? () =>
                          dispatchSectionsEditMode({
                             section: 'RENTAL_PERIOD',
                             editMode: true,
                          })
                     : undefined
               }
            />
         )}
         {sectionsEditMode.palletsConfigurationSection ? (
            <EditPalletsConfigurationSection
               pallets={values.pallets}
               availablePalletTypes={client.availablePalletTypes}
               palletTypes={palletTypesData}
               palletContractPrices={palletContractPrices}
               palletCustomPrices={palletCustomPrices}
               showPalletPrices
               isOrderFree={isOrderFree}
               selectedAddress={values.address}
               onPalletRadioChange={handlePalletRadioChange}
               onPalletQuantityChange={palletQuantityChangeHandler}
               onClearPallet={handleClearPallet}
               onClickChangePalletsPrices={() => showModalOrDialog('CHANGE_PALETS_PRICES_MODAL')}
               onSave={() => handleSaveSectionData('PALLETS_CONFIGURATION')}
               onCancel={() => handleCancelSectionDataChanges('PALLETS_CONFIGURATION')}
               errors={errorsList.pallets}
               touched={touched.pallets}
               isLoading={sectionsLoading.palletsConfigurationSection}
            />
         ) : (
            <OverviewPalletsConfigurationSection
               mode="ORDER_DETAILS"
               isOrderFree={isOrderFree}
               logisticMinimumAchieved={
                  order.status === 'WAITING' ? order.hasMetLogisticsMinimum : undefined
               }
               pallets={pallets}
               palletsSum={palletsSum}
               palletsTotalPrice={countPalletsOrderTotalPrice(pallets)}
               onClickEdit={
                  editableOrderStatuses.includes(order.status)
                     ? () =>
                          dispatchSectionsEditMode({
                             section: 'PALLETS_CONFIGURATION',
                             editMode: true,
                          })
                     : undefined
               }
               isDisabledEditAction={
                  editableOrderStatuses.includes(order.status) && sectionsEditMode.deliverySection
               }
            />
         )}
         {!order.personalPickUp &&
            (sectionsEditMode.storageSection ? (
               <EditStorageSection
                  selectedStorage={values.storage}
                  availableStorages={storageOptions}
                  onSelectChange={selectChangeHandler}
                  disabled={values.address === SELF_PICKUP}
                  onSave={() => handleSaveSectionData('STORAGE')}
                  onCancel={() => handleCancelSectionDataChanges('STORAGE')}
                  isLoading={sectionsLoading.storageSection}
                  error={errorsList.storage}
                  touched={touched.storage}
               />
            ) : (
               <OverviewStorageSection
                  storage={order.storage}
                  {...(editableOrderStatuses.includes(order.status)
                     ? {
                          onClickEdit: () =>
                             dispatchSectionsEditMode({
                                section: 'STORAGE',
                                editMode: true,
                             }),
                          disabledEditAction: sectionsEditMode.deliverySection,
                       }
                     : {})}
               />
            ))}
         {isVisibleAdditionalSections && (
            <>
               {/* TODO: remove as LostDamagedPalletReport[] after backend changes */}
               {!!damagedPalletReports.length && (
                  <LostDamagedPalletsInDeliverySection
                     title="Palety uszkodzone w dostawie (do wymiany)"
                     mode="SUPPLIER_ORDER_DETAILS"
                     lostDamagedPalletsData={damagedPalletReports as LostDamagedPalletReport[]}
                  />
               )}
               {/* TODO: remove as LostDamagedPalletReport[] after backend changes */}
               {!!lostDestroyedPalletReports.length && (
                  <LostDamagedPalletsInDeliverySection
                     title="Palety zniszczone / zgubione w dostawie (do wymiany)"
                     mode="SUPPLIER_ORDER_DETAILS"
                     lostDamagedPalletsData={
                        lostDestroyedPalletReports as LostDamagedPalletReport[]
                     }
                  />
               )}
               <AdditionalChargesSection
                  additionalCharges={order.additionalCharges}
                  {...(withReportingSendingPalletsAbility
                     ? {
                          onClickReportDamagedLostPallets: () =>
                             showModalOrDialog('REPORT_DAMAGE_LOSS_PALLETS_MODAL'),
                       }
                     : {})}
                  chargesTotalAmount={chargesTotalAmount}
                  supplierMode
                  orderStatus={order.status}
                  onAddAdditionalCharges={() => showModalOrDialog('ADD_ADDITIONAL_CHARGES_MODAL')}
               />
               <SendPalletsSection
                  sendPallets={order.sendPallets}
                  remainingPalletsToBeSend={order.remainingPalletsToBeSend}
                  orderStatus={order.status}
                  onClickSendPallets={
                     withReportingSendingPalletsAbility
                        ? () => showModalOrDialog('SEND_PALLETS_MODAL')
                        : undefined
                  }
                  onClickEditPallets={
                     isAvailableEditingSentPallets
                        ? () => {
                             showModalOrDialog('SEND_PALLETS_MODAL');
                             setIsInEditMode(true);
                          }
                        : undefined
                  }
               />
            </>
         )}
      </>
   );
};

export default RegularOrderDetailsPage;
