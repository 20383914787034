import { ClientDeliveryAddress, PalletsPricingPerAddressFormData } from 'interfaces';

import { parseAddressDeliveryRatePerPalletConfigIntoPalletsPricingPerPalletItem } from './parseAddressDeliveryRatePerPalletConfigIntoPalletsPricingPerPalletItem';

export const parseClientDeliveryAddressIntoAddressDeliveryRatePerPalletConfig: (
   clientDeliveryAddress: ClientDeliveryAddress,
) => PalletsPricingPerAddressFormData = clientDeliveryAddress => {
   const { address, palletConfig } = clientDeliveryAddress;
   const { name } = address;

   const palletsPricing = palletConfig.palletRates.map(
      parseAddressDeliveryRatePerPalletConfigIntoPalletsPricingPerPalletItem,
   );

   return { addressName: name, palletsPricing: palletsPricing };
};
