export const getAddressLabel = (addressQuantinty: number) => {
   const pluralRules = new Intl.PluralRules('pl-PL');
   const grammaticalNumber = pluralRules.select(addressQuantinty);
   switch (grammaticalNumber) {
      case 'one':
         return 'adres';
      case 'few':
         return 'adresy';
      case 'many':
         return 'adresów';
   }
};
