import { ChangeEvent, FocusEvent } from 'react';

import { SharedGridTableCell, SharedGridTableRow, StyledTextInput } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { CommonRatePerPalletConfigFormData, PalletType } from 'interfaces';

import styles from './CommonPalletsPricingFormTableRow.module.css';

export type CommonPalletsPricingFormTableRowProps = {
   palletPricing: CommonRatePerPalletConfigFormData;
   error?: Record<string, boolean>;
   onPalletsPricingChange: (event: ChangeEvent<HTMLInputElement>, palletType: PalletType) => void;
   onPalletRatesBlur: (event: FocusEvent<HTMLInputElement>, palletType: PalletType) => void;
};

const CommonPalletsPricingFormTableRow = ({
   palletPricing,
   error,
   onPalletsPricingChange,
   onPalletRatesBlur,
}: CommonPalletsPricingFormTableRowProps) => {
   const { palletType, personalPickUpPrice, damagePrice, wrongReceiver, lostPrice } = palletPricing;

   return (
      <SharedGridTableRow className={styles.row}>
         <SharedGridTableCell className={styles.cell}>
            <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].label}</h3>
            <p>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].dimensions}</p>
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            <StyledTextInput
               name="personalPickUpPrice"
               type="text"
               value={personalPickUpPrice}
               fullWidth
               error={error?.personalPickUpPrice}
               onChange={event => onPalletsPricingChange(event, palletType)}
               onBlur={event => onPalletRatesBlur(event, palletType)}
            />
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            <StyledTextInput
               name="damagePrice"
               type="text"
               value={damagePrice}
               fullWidth
               error={error?.damagePrice}
               onChange={event => onPalletsPricingChange(event, palletType)}
               onBlur={event => onPalletRatesBlur(event, palletType)}
            />
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            <StyledTextInput
               name="lostPrice"
               type="text"
               value={lostPrice}
               fullWidth
               error={error?.lostPrice}
               onChange={event => onPalletsPricingChange(event, palletType)}
               onBlur={event => onPalletRatesBlur(event, palletType)}
            />
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            <StyledTextInput
               name="wrongReceiver"
               type="text"
               value={wrongReceiver}
               fullWidth
               error={error?.wrongReceiver}
               onChange={event => onPalletsPricingChange(event, palletType)}
               onBlur={event => onPalletRatesBlur(event, palletType)}
            />
         </SharedGridTableCell>
      </SharedGridTableRow>
   );
};

export default CommonPalletsPricingFormTableRow;
