import { SelfPickupIcon } from 'assets';
import { SectionContainer } from 'components/shared';
import { STORAGE_TYPE_LABELS_SINGULAR } from 'constants/storageType';
import { StorageOption } from 'interfaces';
import { Card } from 'storybook';

import styles from './SelectedStorageSection.module.css';

type SelectedStorageSectionProps = {
   storage: StorageOption;
};

const SelectedStorageSection = ({ storage }: SelectedStorageSectionProps) => (
   <SectionContainer title="Dostarczone z magazynu">
      <Card
         title="Magazyn"
         icon={<SelfPickupIcon />}
         bottomContent={
            <p className={styles.cardText}>
               {`${storage.label} (${STORAGE_TYPE_LABELS_SINGULAR[storage.value.storageType]})`}
            </p>
         }
         className={styles.card}
      />
   </SectionContainer>
);

export default SelectedStorageSection;
