import classNames from 'classnames';

import styles from './WhiteCard.module.css';

type WhiteCardProps = {
   children: string | JSX.Element | JSX.Element[];
   className?: string;
};
const WhiteCard = ({ children, className }: WhiteCardProps) => (
   <div className={styles.tableLoaderContainer}>
      <div className={classNames(styles.tableContainer, className)}>{children}</div>
   </div>
);

export default WhiteCard;
