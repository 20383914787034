import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { PalletAmount } from 'interfaces';
import { getPalletLabel } from 'utils/functions';

import styles from './AdditionalPalletsList.module.css';

type AdditionalPalletsListProps = {
   additionalPallets: PalletAmount[];
};

const AdditionalPalletsList = ({ additionalPallets }: AdditionalPalletsListProps) => (
   <ul className={styles.additionalPalletsList}>
      {additionalPallets.map(additionalPallet => (
         <li className={styles.listItem}>
            <span>{PALLET_TYPE_LABELS[additionalPallet.palletType]}</span>
            <span>{`${additionalPallet.amount} ${getPalletLabel(additionalPallet.amount)}`}</span>
         </li>
      ))}
   </ul>
);

export default AdditionalPalletsList;
