import classNames from 'classnames';
import { ArrowDownRight, ArrowUpRight } from 'tabler-icons-react';

import styles from './SupplierPalletsNumberToPickUpItem.module.css';

type SupplierPalletsNumberToPickUpItemProps = {
   title: string;
   palletsNumber: number;
   palletsDifference?: number;
   isSummary?: boolean;
};

const SupplierPalletsNumberToPickUpItem = ({
   title,
   palletsNumber,
   palletsDifference,
   isSummary,
}: SupplierPalletsNumberToPickUpItemProps) => {
   const isPalletsDifferencePositive = palletsDifference && palletsDifference > 0;
   const differenceIcon = isPalletsDifferencePositive ? (
      <ArrowUpRight size={14} />
   ) : (
      <ArrowDownRight size={14} />
   );

   return (
      <div>
         <span className={styles.title}>{title}</span>
         <div className={styles.palletsNumberInfoContainer}>
            <span className={classNames(styles.palletsNumber, { [styles.summary]: isSummary })}>
               {palletsNumber}
            </span>
            {!!palletsDifference && (
               <div
                  className={classNames(styles.palletsDifference, {
                     [styles.positive]: isPalletsDifferencePositive,
                  })}
               >
                  {differenceIcon}
                  {Math.abs(palletsDifference)}
               </div>
            )}
         </div>
      </div>
   );
};

export default SupplierPalletsNumberToPickUpItem;
