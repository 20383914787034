import { CustomModal, CustomModalProps } from 'components/shared';

import styles from './SendPalletsWithWrongAddressesWarningModal.module.css';

type SendPalletsWithWrongAddressesWarningModalProps = Pick<CustomModalProps, 'opened'> & {
   onConfirm: () => void;
   onCancel: () => void;
};

const SendPalletsWithWrongAddressesWarningModal = ({
   opened,
   onConfirm,
   onCancel,
}: SendPalletsWithWrongAddressesWarningModalProps) => (
   <CustomModal
      opened={opened}
      onClose={onCancel}
      icon="ALERT_TRIANGLE"
      innerTitle="Czy napewno chcesz nadać palety?"
      modalContentClassName={styles.modalContent}
      secondaryButtonProps={{
         text: 'Wróć',
         variant: 'outlined-danger',
         onClick: onCancel,
      }}
      primaryButtonProps={{
         text: 'Nadaj palety',
         variant: 'filled-danger',
         onClick: onConfirm,
      }}
   >
      <p>
         W nadaniu znajdują się adresy, które są nie zweryfikowane, może się to wiązać z dodatkowymi
         opłatami.
      </p>
   </CustomModal>
);

export default SendPalletsWithWrongAddressesWarningModal;
