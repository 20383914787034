import { CustomModal, CustomModalProps } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './DeactivateClientModal.module.css';

interface DeactivateClientModalProps extends CustomModalProps {
   clientId: string;
   setDeactivatedUserInfo: React.Dispatch<React.SetStateAction<boolean>>;
   setCannotDeactivateClientModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeactivateClientModal = ({
   clientId,
   setDeactivatedUserInfo,
   setCannotDeactivateClientModal,
   onClose,
   ...props
}: DeactivateClientModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const deactivateUser = () => {
      setIsLoading(true);
      HTTPService.deactivateUser(clientId)
         .then(() => {
            setDeactivatedUserInfo(true);
         })
         .catch(err => {
            logNetworkError(err);
            if (err.response.data.code === 'USER_HAS_ORDERS') {
               setCannotDeactivateClientModal(true);
            } else {
               setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            }
         })
         .finally(() => {
            closeModalHandler();
            setIsLoading(false);
         });
   };

   return (
      <CustomModal
         {...props}
         title="Dezaktywuj klienta"
         icon="USER_OFF"
         error={errorInfo}
         isLoading={isLoading}
         leftSideModalContent
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: 'Deazktywuj klienta',
            variant: 'filled-danger',
            onClick: deactivateUser,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'outlined-danger',
            onClick: closeModalHandler,
         }}
      >
         <div className={styles.modalContent}>
            <p>Dezaktywując klienta: </p>
            <ul>
               <li>wyszarzysz jego dane i przeniesiesz go na sam koniec listy klientów,</li>
               <li>
                  jego zamówienia nadal bedą widoczne na liście zamówień (ale będą wyszarzone),{' '}
               </li>
               <li>uniemożliwisz klientowi możliwość korzystania z konta.</li>
            </ul>
            <p className={styles.actionInfo}>Tej akcji nie można cofnąć.</p>
            <p>Czy chcesz kontynuować?</p>
         </div>
      </CustomModal>
   );
};
export default DeactivateClientModal;
