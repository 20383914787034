import { PalletTypeCard, SectionContainer } from 'components/shared';
import { palletTypesData } from 'constants/palletTypes';
import { useForm } from 'hooks/useForm';
import { FormStepProps, PalletTypesFormProps } from 'interfaces';
import { Banner } from 'storybook';

import FormStepsFooter from '../../FormStepsFooter/FormStepsFooter';

import styles from './PalletTypesForm.module.css';
import { palletTypesFormValidation } from './PalletTypesFormValidations';

const PalletTypesForm = ({
   initialState,
   onSubmit,
   setCurrentStep,
}: FormStepProps<PalletTypesFormProps>) => {
   const { values, errorsList, multiSelectChangeHandler, touched, submitHandler } = useForm({
      initialState,
      onSubmit: () => {
         onSubmit(values as PalletTypesFormProps);
         setCurrentStep(prevValue => prevValue + 1);
      },
      validations: palletTypesFormValidation,
   });
   return (
      <form onSubmit={submitHandler} className={styles.formContainer}>
         <SectionContainer className={styles.sectionContainer} title="Rodzaje palet">
            <p className={styles.sectionSubtitle}>
               Wybierz, które palety będą dostępne dla klienta.
            </p>
            {touched.availablePalletTypes && errorsList.availablePalletTypes && (
               <Banner fullWidth variant="error" style={{ marginBottom: 24 }}>
                  {errorsList.availablePalletTypes}
               </Banner>
            )}
            <div className={styles.palletsContainer}>
               {palletTypesData.map(palletType => (
                  <PalletTypeCard
                     key={palletType.name}
                     palletType={palletType}
                     onClick={() => {
                        multiSelectChangeHandler(palletType.name, 'availablePalletTypes');
                     }}
                     isSelected={values.availablePalletTypes.includes(palletType.name)}
                  />
               ))}
            </div>
         </SectionContainer>
         <FormStepsFooter
            backDisabled={false}
            onBack={() => setCurrentStep((prevValue: number) => prevValue - 1)}
         />
      </form>
   );
};

export default PalletTypesForm;
