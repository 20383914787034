import classNames from 'classnames';

import { OrderStatus, PickUpStatus, ReportStatus } from 'interfaces';
import { Badge, BadgeProps } from 'storybook';

import styles from './StyledBadge.module.css';

interface StyledBadgeProps extends BadgeProps {
   variant: OrderStatus | ReportStatus | PickUpStatus | 'NEW' | 'FREE' | 'POSITIVE' | 'NEGATIVE';
}

const WAITING_STATES = ['WAITING', 'REPORTED', PickUpStatus.CREATED];
const COMPLETED_STATES = ['ENDED', 'REVIEWED'];
const BLUE_STATES = ['DELIVERED', PickUpStatus.ACCEPTED];
const RED_STATES = ['CANCELED', PickUpStatus.REJECTED];

const StyledBadge = ({ variant, ...props }: StyledBadgeProps) => (
   <Badge
      {...props}
      className={classNames(
         styles.badge,
         {
            [styles.waiting]: WAITING_STATES.includes(variant),
            [styles.inProgress]: variant === 'IN_PROGRESS',
            [styles.blue]: BLUE_STATES.includes(variant),
            [styles.completed]: COMPLETED_STATES.includes(variant),
            [styles.red]: RED_STATES.includes(variant),
            [styles.duringBilling]: variant === 'DURING_BILLING',
            [styles.toPay]: variant === 'TO_PAY',
            [styles.new]: variant === 'NEW',
            [styles.free]: variant === 'FREE',
            [styles.positive]: variant === 'POSITIVE',
            [styles.negative]: variant === 'NEGATIVE',
         },
         props.className,
      )}
   />
);

export default StyledBadge;
