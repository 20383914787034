import { useCallback, useEffect, useMemo, useState } from 'react';

import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { useAuth } from 'context/auth/AuthContextProvider';
import {
   AddressOption,
   CommercialNetworkDetailsDTO,
   GroupedLostDamagedPalletItems,
   Option,
   StorageDTO,
} from 'interfaces';
import { HTTPService } from 'service';
import { getCommercialNetworkOptions, getStorageAddressOptions } from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';
import { logNetworkError } from 'utils/logNetworkError';

export const useSendPalletsForm = (clientAddressOptionData?: AddressOption, clientId?: string) => {
   const [storageAddressOptions, setStorageAddressOptions] = useState<AddressOption | null>();
   const [commercialNetworkAddressOptions, setCommercialNetworkAddressOptions] =
      useState<AddressOption | null>();
   const [clientAddressOptions, setClientAddressOptions] = useState<AddressOption | null>(
      clientAddressOptionData || null,
   );
   //  lost/destroyed pallets to report and damaged pallets to send
   const [groupedLostDamagedPalletItems, setGroupedLostDamagedPalletItems] =
      useState<GroupedLostDamagedPalletItems | null>();
   const { user } = useAuth();

   const isClient = user?.mainRole === 'ROLE_CLIENT';
   const isAdmin = user?.mainRole === 'ROLE_SYSTEM_ADMIN';

   const prepareStoragesAddresses = useCallback(
      (storages: StorageDTO[]) => {
         const storageDeliveryAddressOptions = getStorageAddressOptions(storages);
         setStorageAddressOptions({
            nameOptions: storageDeliveryAddressOptions.storageNameOptions,
            addressOptions: storageDeliveryAddressOptions.storageAddressOptions,
         });
      },
      [setStorageAddressOptions],
   );

   const prepareCommercialNetworksAddresses = useCallback(
      (commercialNetworks: CommercialNetworkDetailsDTO[]) => {
         const commercialNetworkDeliveryAddressOptions =
            getCommercialNetworkOptions(commercialNetworks);
         setCommercialNetworkAddressOptions({
            nameOptions: commercialNetworkDeliveryAddressOptions.nameOptions,
            addressOptions: commercialNetworkDeliveryAddressOptions.addressOptions,
         });
      },
      [setCommercialNetworkAddressOptions],
   );

   useEffect(() => {
      if (isClient) {
         HTTPService.getUserCommercialNetworks()
            .then(getCommercialNetworksResponse => {
               prepareCommercialNetworksAddresses(
                  getCommercialNetworksResponse.data.commercialNetworks,
               );
            })
            .catch(error => {
               //TODO: create context for banner and display it here
               // setBannerData({ description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA, variant: 'error' });
               logNetworkError(error);
            });
         //TODO: move out fetching data outside
         // .finally(() => setIsLoading?.(false));
      }
      if (clientId) {
         Promise.all([
            HTTPService.getStorageList({ page: 0, size: PAGINATION_MAX_SIZE }),
            HTTPService.getCommercialNetworks(
               { userId: clientId },
               { page: 0, size: PAGINATION_MAX_SIZE },
            ),
         ])
            .then(([getStoragesResponse, getCommercialNetworksResponse]) => {
               prepareStoragesAddresses(getStoragesResponse.data.content);
               prepareCommercialNetworksAddresses(getCommercialNetworksResponse.data.content);
            })
            .catch(error => {
               logNetworkError(error);
            });
         //TODO: move out fetching data outside
      }
   }, [isClient, clientId, prepareCommercialNetworksAddresses, prepareStoragesAddresses]);

   const sendingAddressOptions = useMemo(() => {
      let nameOptions: Option[] = [];
      let addressOptions: { [key: string]: Option[] } = {};
      if (isClient && commercialNetworkAddressOptions) {
         nameOptions = [...commercialNetworkAddressOptions.nameOptions];
         addressOptions = {
            ...commercialNetworkAddressOptions.addressOptions,
         };
      }
      if (
         isAdmin &&
         storageAddressOptions &&
         commercialNetworkAddressOptions &&
         clientAddressOptions
      ) {
         nameOptions = [
            ...clientAddressOptions.nameOptions,
            ...storageAddressOptions.nameOptions,
            ...commercialNetworkAddressOptions.nameOptions,
         ];
         addressOptions = {
            ...clientAddressOptions.addressOptions,
            ...storageAddressOptions.addressOptions,
            ...commercialNetworkAddressOptions.addressOptions,
         };
      }
      return { nameOptions, addressOptions };
   }, [
      clientAddressOptions,
      commercialNetworkAddressOptions,
      isAdmin,
      isClient,
      storageAddressOptions,
   ]);

   const handleAddCommercialNetworkOption = useCallback(
      (newCommercialNetwork: string) => {
         const newCommercialNetworkValue = newCommercialNetwork.toLowerCase().replaceAll(' ', '');
         const optionExists = sendingAddressOptions.nameOptions.some(
            option => option.value.toLowerCase().replaceAll(' ', '') === newCommercialNetworkValue,
         );
         if (!optionExists) {
            const formattedCommercialNetworkValue = newCommercialNetwork.trim();
            setCommercialNetworkAddressOptions(prevAddressOptions => {
               if (!prevAddressOptions) {
                  return null;
               }
               const newNameOptions = [
                  ...prevAddressOptions.nameOptions,
                  {
                     value: formattedCommercialNetworkValue,
                     label: formattedCommercialNetworkValue,
                  },
               ];
               const sortedNameOptions = sortOptions(newNameOptions);
               return {
                  nameOptions: sortedNameOptions,
                  addressOptions: {
                     ...prevAddressOptions.addressOptions,
                     [formattedCommercialNetworkValue]: [],
                  },
               };
            });
         }
      },
      [sendingAddressOptions.nameOptions],
   );

   const handleAddCommercialNetworkAddressOption = useCallback(
      (commercialNetwork: string, newCommercialNetworkAddress: string) => {
         const newCommercialNetworkAddressValue = newCommercialNetworkAddress
            .toLowerCase()
            .replaceAll(' ', '');
         const doesCommercialNetworkExist =
            !!sendingAddressOptions.addressOptions[commercialNetwork];
         const doesOptionExist =
            doesCommercialNetworkExist &&
            sendingAddressOptions.addressOptions[commercialNetwork].some(
               option =>
                  option.value.toLowerCase().replaceAll(' ', '') ===
                  newCommercialNetworkAddressValue,
            );
         if (doesOptionExist) {
            return;
         }
         const formattedCommercialNetworkAddressValue = newCommercialNetworkAddress.trim();
         const newAddressOption = {
            value: formattedCommercialNetworkAddressValue,
            label: formattedCommercialNetworkAddressValue,
         };
         if (!doesCommercialNetworkExist) {
            setCommercialNetworkAddressOptions(prevAddressOptions => {
               if (!prevAddressOptions) {
                  return null;
               }
               const newNameOption = {
                  value: commercialNetwork,
                  label: commercialNetwork,
               };
               const newNameOptions = [...prevAddressOptions.nameOptions, newNameOption];
               const newAddressOptions = {
                  ...prevAddressOptions.addressOptions,
                  [commercialNetwork]: [newAddressOption],
               };
               const sortedNameOptions = sortOptions(newNameOptions);
               return {
                  nameOptions: sortedNameOptions,
                  addressOptions: newAddressOptions,
               };
            });
            return;
         }
         setCommercialNetworkAddressOptions(prevAddressOptions => {
            if (!prevAddressOptions) {
               return null;
            }
            const newCommercialNetworkAddressOptions = [
               ...prevAddressOptions.addressOptions[commercialNetwork],
               newAddressOption,
            ];
            const sortedCommercialNetworkAddressOptions = sortOptions(
               newCommercialNetworkAddressOptions,
            );
            const newAddressOptions = {
               ...prevAddressOptions.addressOptions,
               [commercialNetwork]: sortedCommercialNetworkAddressOptions,
            };
            return {
               ...prevAddressOptions,
               addressOptions: newAddressOptions,
            };
         });
      },
      [sendingAddressOptions.addressOptions],
   );

   return {
      groupedLostDamagedPalletItems,
      sendingAddressOptions,
      setStorageAddressOptions,
      setCommercialNetworkAddressOptions,
      setClientAddressOptions,
      setGroupedLostDamagedPalletItems,
      handleAddCommercialNetworkOption,
      handleAddCommercialNetworkAddressOption,
   };
};
