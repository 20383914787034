import { SendPalletsItem } from 'interfaces';

export const getTouchedSendPalletsItem = (sendPalletsItem: SendPalletsItem) => {
   const palletsItems = sendPalletsItem.palletsItems.map(() => ({
      palletType: true,
      palletsAmount: true,
   }));
   return {
      sendDate: true,
      externalCommercialNetworkNumber: true,
      commercialNetworkName: true,
      commercialNetworkAddress: true,
      palletsItems: palletsItems,
   };
};
