import { VGLClientExcelRow } from '../types';

export const mergeVGLClientSendPalletsDataItemsWithSamePalletType = (
   sendPalletsDataItemsWithSamePalletType: VGLClientExcelRow[],
) => {
   const palletsAmount = sendPalletsDataItemsWithSamePalletType.reduce(
      (totalPalletsAmount, currentPalletsItem) =>
         totalPalletsAmount + Number(currentPalletsItem['ilość']),
      0,
   );
   const firstSendPalletsItem = sendPalletsDataItemsWithSamePalletType[0];
   const commonSendPalletsItemsData = {
      'Data ost.mod.': firstSendPalletsItem['Data ost.mod.'],
      'nr STO': firstSendPalletsItem['nr STO'],
      'do Centrum Dystr.': firstSendPalletsItem['do Centrum Dystr.'],
      'typ palety': firstSendPalletsItem['typ palety'],
      ['ilość']: firstSendPalletsItem['ilość'],
   };
   return {
      ...commonSendPalletsItemsData,
      ['ilość']: palletsAmount,
   };
};
