import { CustomModal, CustomModalProps } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './UnblockClientModal.module.css';

type UnblockClientModalProps = CustomModalProps & {
   selectedUserId: string;
   onSuccessBlockClient: (userId: string) => void;
};

const UnblockClientModal = ({
   selectedUserId,
   onSuccessBlockClient,
   onClose,
   ...props
}: UnblockClientModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleUnblockClient = () => {
      setIsLoading(true);
      HTTPService.changeUserBlockedStatus(false, '', selectedUserId)
         .then(() => {
            onSuccessBlockClient(selectedUserId);
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         {...props}
         title="Odblokuj klienta"
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         isLoading={isLoading}
         leftSideModalContent
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: 'Odblokuj klienta',
            variant: 'filled-primary',
            onClick: handleUnblockClient,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: closeModalHandler,
         }}
      >
         <>
            <p className={styles.text}>Klient będzie mógł ponownie składać zamówienia.</p>
            <p className={styles.text}>Czy chcesz kontynuować?</p>
         </>
      </CustomModal>
   );
};

export default UnblockClientModal;
