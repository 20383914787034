import AuthTopBar from './AuthTopBar/AuthTopBar';
import styles from './ResetForgotPasswordLayout.module.css';

type ResetForgotPasswordLayoutProps = {
   children: JSX.Element;
};

const ResetForgotPasswordLayout = ({ children }: ResetForgotPasswordLayoutProps) => (
   <>
      <AuthTopBar />
      <main className={styles.main}>
         {children}
         <span className={styles.authorshipInfo}>2022 Software Partner All rights reserved.</span>
      </main>
   </>
);

export default ResetForgotPasswordLayout;
