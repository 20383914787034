import { EMPTY_INPUT_ERROR, INVALID_EMAIL_ERROR } from 'constants/validationErrors';

import { isEmail, isNonEmpty } from '.';

export const loginFormValidations = [
   ({ email }: FormFields) => {
      if (!isNonEmpty(email)) {
         return { email: EMPTY_INPUT_ERROR };
      } else {
         return !isEmail(email) && { email: INVALID_EMAIL_ERROR };
      }
   },
   ({ password }: FormFields) => {
      return !isNonEmpty(password) && { password: EMPTY_INPUT_ERROR };
   },
];

type FormFields = {
   email: string;
   password: string;
};
