import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './TopBar.module.css';

export interface TopBarProps {
   className?: string;
   contentLeft?: ReactNode;
   contentRight?: ReactNode;
}

export const TopBar = ({ className, contentLeft, contentRight }: TopBarProps) => {
   return (
      <div className={classNames(styles.topBar, className)}>
         {contentLeft}
         {contentRight}
      </div>
   );
};
