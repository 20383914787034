import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import classNames from 'classnames';
import { X } from 'tabler-icons-react';

import { Banner } from 'storybook';

import StatusLegendItem, { StatusLegendItemProps } from './StatusLegendItem/StatusLegendItem';
import styles from './StatusLegend.module.css';

type StatusLegendProps = {
   statusDataItems: StatusLegendItemProps[];
   className?: string;
};

const StatusLegend = ({ statusDataItems, className }: StatusLegendProps) => {
   const [openStatusLegend, setOpenStatusLegend] = useState(false);
   return (
      <OutsideClickHandler onOutsideClick={() => setOpenStatusLegend(false)}>
         <div className={styles.mainContainer}>
            <Banner
               variant="info_gray"
               children={
                  <>
                     <span className={styles.bannerTextDesktopOnly}>Legenda statusów</span>
                     <span className={styles.bannerTextMobileOnly}>Statusy</span>
                  </>
               }
               onClick={() => setOpenStatusLegend(true)}
               className={styles.statusLegendBanner}
            />
            {openStatusLegend && (
               <div className={classNames(styles.statusLegendContainer, className)}>
                  <X
                     onClick={() => setOpenStatusLegend(false)}
                     className={styles.mobileOnlyCloseBtn}
                  />
                  <h4>Statusy i ich funkcje:</h4>
                  <ul>
                     {statusDataItems.map(statusItem => (
                        <StatusLegendItem
                           key={statusItem.variant}
                           status={statusItem.status}
                           description={statusItem.description}
                           variant={statusItem.variant}
                        />
                     ))}
                  </ul>
               </div>
            )}
         </div>
      </OutsideClickHandler>
   );
};

export default StatusLegend;
