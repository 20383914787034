import classNames from 'classnames';

import { OrderDetailsPallet } from 'interfaces';
import { getFreePalletsLabel, getPalletLabel } from 'utils/functions';

import styles from './FreePalletAmountInfo.module.css';

type FreePalletAmountInfoProps = { pallet: OrderDetailsPallet; isClientView?: boolean };

const FreePalletAmountInfo = ({ pallet, isClientView }: FreePalletAmountInfoProps) => (
   <p
      className={classNames(styles.freePalletsAmount, {
         [styles.green]: isClientView,
      })}
   >
      {`+ ${pallet.freePalletsAmount} ${getPalletLabel(
         pallet.freePalletsAmount,
      )} ${getFreePalletsLabel(pallet.freePalletsAmount)}`}
   </p>
);

export default FreePalletAmountInfo;
