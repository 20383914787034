import classNames from 'classnames';

import StyledButton from '../StyledButton/StyledButton';

import styles from './SaveCancelButtonsContainer.module.css';

type SaveCancelButtonsContainerProps = {
   onSave: () => void;
   onCancel: () => void;
   className?: string;
};

const SaveCancelButtonsContainer = ({
   onSave,
   onCancel,
   className,
}: SaveCancelButtonsContainerProps) => (
   <div className={classNames(styles.container, className)}>
      <StyledButton
         type="button"
         text="Zapisz"
         onClick={onSave}
         variant="filled-primary"
         className={styles.btn}
      />
      <StyledButton
         type="button"
         text="Odrzuć"
         onClick={onCancel}
         variant="outlined-primary"
         className={styles.btn}
      />
   </div>
);

export default SaveCancelButtonsContainer;
