import {
   ExcessPallets,
   LostDamagedPalletsByClientItem,
   LostDamagedPalletsBySupplierItem,
   PalletAmount,
} from 'interfaces';

export const getExcessPallets = (
   availablePalletsItems: LostDamagedPalletsByClientItem[] | LostDamagedPalletsBySupplierItem[],
   remainingPalletsToBeSend: PalletAmount[],
) => {
   const excessPallets: ExcessPallets = {};
   availablePalletsItems.forEach(availablePalletsItem => {
      const foundRemainingPalletsItem = remainingPalletsToBeSend.find(
         remainingPalletsItem =>
            remainingPalletsItem.palletType === availablePalletsItem.palletType.value,
      );
      const convertedAvailablePalletsAmount = Number(availablePalletsItem.palletsAmount);
      if (
         foundRemainingPalletsItem &&
         foundRemainingPalletsItem.amount < convertedAvailablePalletsAmount
      ) {
         excessPallets[foundRemainingPalletsItem.palletType] =
            convertedAvailablePalletsAmount - foundRemainingPalletsItem.amount;
      } else if (!foundRemainingPalletsItem) {
         excessPallets[availablePalletsItem.palletType.value] = convertedAvailablePalletsAmount;
      }
   });
   return excessPallets;
};
