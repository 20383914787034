import { OrderSummaryAndOrderDetailsPallet } from 'interfaces';
import { currencyFormatter } from 'utils/numberFormatter';
import { isClientOrderSummaryPallet } from 'utils/typeGuards';

import styles from './PalletInfoSection.module.css';

type PalletInfoSectionProps = {
   pallet: OrderSummaryAndOrderDetailsPallet;
   isOrderFree?: boolean;
   isClientView?: boolean;
};

const PalletInfoSection = ({ pallet, isOrderFree, isClientView }: PalletInfoSectionProps) => {
   const isPalletPriceVisible = !isClientOrderSummaryPallet(pallet) && !isClientView;
   return (
      <>
         <p className={styles.palletDimensions}>{pallet.dimensions}</p>
         {isPalletPriceVisible && (
            <p className={styles.palletPrice}>
               Cena (szt.):{' '}
               <span>
                  {pallet.pricePerUnit && !isOrderFree
                     ? currencyFormatter.format(pallet.pricePerUnit)
                     : '-'}{' '}
                  PLN
               </span>
            </p>
         )}
      </>
   );
};

export default PalletInfoSection;
