import {
   EMPTY_INPUT_ERROR,
   INVALID_EMAIL_ERROR,
   INVALID_ZIP_CODE_ERROR,
} from 'constants/validationErrors';
import { AddCommercialNetworkFormValues } from 'interfaces';

import { isEmail, isNonEmpty, isZipCode } from '.';

export const addDistributionCenterFormValidations = [
   ({ street }: AddCommercialNetworkFormValues) =>
      !isNonEmpty(street) && { street: EMPTY_INPUT_ERROR },
   ({ zipCode }: AddCommercialNetworkFormValues) => {
      if (!isNonEmpty(zipCode)) {
         return { zipCode: EMPTY_INPUT_ERROR };
      } else if (!isZipCode(zipCode)) {
         return { zipCode: INVALID_ZIP_CODE_ERROR };
      }
   },
   ({ city }: AddCommercialNetworkFormValues) => !isNonEmpty(city) && { city: EMPTY_INPUT_ERROR },
   ({ contactEmail }: AddCommercialNetworkFormValues) => {
      if (isNonEmpty(contactEmail) && !isEmail(contactEmail)) {
         return { contactEmail: INVALID_EMAIL_ERROR };
      }
   },
   ({ phoneNumber, contactEmail }: AddCommercialNetworkFormValues) => {
      if (isNonEmpty(phoneNumber) && !isNonEmpty(contactEmail)) {
         return { contactEmail: EMPTY_INPUT_ERROR };
      } else if (!isNonEmpty(phoneNumber) && isNonEmpty(contactEmail)) {
         return { phoneNumber: EMPTY_INPUT_ERROR };
      }
   },
];
