import classNames from 'classnames';

import { SelfPickupIcon, TruckDeliveryIcon } from 'assets';
import { SectionContainer, StyledButton, ToolTip } from 'components/shared';
import { OrderAddress } from 'interfaces';
import { Card } from 'storybook';

import styles from './OverviewDeliverySection.module.css';

type OverviewDeliverySectionProps = {
   address: OrderAddress | 'SELF_PICKUP' | null;
   storageAddress?: string;
   onClickEdit?: () => void;
   disabledEditAction?: boolean;
   viewMode: 'SUPPLIER' | 'CLIENT';
};

const NO_STORAGE_ADDRESS_TEXTS: { [key: string]: string } = {
   SUPPLIER: 'Nie zdefiniowano magazynu,\n z którego klient może odebrać zamówienie.',
   CLIENT: 'Adres magazynu zostanie wyświetlony w szczegółach zamówienia po jego zakceptowaniu',
};

const OverviewDeliverySection = ({
   address,
   storageAddress,
   onClickEdit,
   disabledEditAction,
   viewMode,
}: OverviewDeliverySectionProps) => (
   <SectionContainer title="Dostawa">
      {onClickEdit && (
         <ToolTip
            content="Zakończ edycję magazynu oraz palet, aby edytować dostawę"
            variant="right"
            visible={disabledEditAction}
         >
            <StyledButton
               variant="outlined-primary"
               text="Edytuj dostawę"
               onClick={onClickEdit}
               disabled={disabledEditAction}
            />
         </ToolTip>
      )}
      <Card
         title={address === 'SELF_PICKUP' ? 'Odbiór osobisty' : 'Dostawa pod adres'}
         icon={address === 'SELF_PICKUP' ? <SelfPickupIcon /> : <TruckDeliveryIcon />}
         bottomContent={
            address === 'SELF_PICKUP' ? (
               <p
                  className={classNames(styles.cardText, {
                     [styles.supplierNoStorageAddressText]:
                        address === 'SELF_PICKUP' && !storageAddress && viewMode === 'SUPPLIER',
                  })}
               >
                  {storageAddress || NO_STORAGE_ADDRESS_TEXTS[viewMode]}
               </p>
            ) : (
               <p className={styles.cardText}>
                  {address ? address.name : ''}
                  <br />
                  {address ? `${address.street}, ${address.zipCode} ${address.city}` : ''}
               </p>
            )
         }
         className={styles.addressCard}
      />
   </SectionContainer>
);

export default OverviewDeliverySection;
