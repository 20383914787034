import { ReactNode, useMemo } from 'react';

import { DateTime } from 'luxon';
import { Check, X } from 'tabler-icons-react';

import {
   OverviewDeliverySection,
   OverviewPalletsConfigurationSection,
   OverviewRentalPeriodSection,
} from 'components/Order';
import { CustomSwitch } from 'components/shared';
import { SelectedDeliveryTypeSection } from 'components/SupplierPanel';
import { SupplierPlaceOrderData } from 'interfaces';
import { Banner } from 'storybook';
import { getHoldingPeriod } from 'utils/functions/getHoldingPeriod';
import { getPalletsFullData } from 'utils/functions/getPalletsFullData';
import {
   countPalletsOrderSum,
   countPalletsOrderTotalPrice,
} from 'utils/functions/palletsCalculations';

import SelectedClientSection from './SelectedClientSection/SelectedClientSection';
import OverviewDeliveredFromStorageSection from './SelectedStorageSection/SelectedStorageSection';
import styles from './OrderSummary.module.css';

type OrderSummaryProps = {
   data: SupplierPlaceOrderData;
   logisticMinimumAchieved?: boolean;
   bottomButtons: ReactNode;
};

const OrderSummary = ({ data, logisticMinimumAchieved, bottomButtons }: OrderSummaryProps) => {
   let holdingPeriod: DateTime | null = null;
   if (data.client && data.palletDeliveryDate) {
      holdingPeriod = getHoldingPeriod(
         data.palletDeliveryDate,
         data.client.rateConfig?.retentionConfig.retentionPeriodDays || 0,
      );
   }

   const pallets = useMemo(
      () =>
         getPalletsFullData(data.pallets, pallet => palletType => palletType.name === pallet.name),
      [data.pallets],
   );

   const isFree = !!data.deliveryType;

   const additionalSections =
      data.deliveryType !== 'ADDED_TO_NEXT_ORDER' ? (
         <>
            <OverviewDeliverySection
               viewMode="SUPPLIER"
               address={
                  data.address && data.address !== 'SELF_PICKUP' ? data.address.value : data.address
               }
               storageAddress={data.storage?.label}
            />
            <OverviewRentalPeriodSection
               palletDeliveryDate={
                  data.palletDeliveryDate ? data.palletDeliveryDate.toLocaleDateString('en-GB') : ''
               }
               holdingPeriod={holdingPeriod ? holdingPeriod.toFormat('dd/LL/yyyy') : ''}
               retentionPeriodDays={
                  data.client
                     ? String(data.client.rateConfig?.retentionConfig.retentionPeriodDays)
                     : ''
               }
            />
         </>
      ) : null;

   const isVisibleStorageSection =
      data.deliveryType !== 'ADDED_TO_NEXT_ORDER' && data.storage && data.address !== 'SELF_PICKUP';

   return (
      <div>
         <CustomSwitch
            label="Zamówienie bezpłatne (wymiana uszkodzonych palet)"
            checked={isFree}
            disabled
            offLabel={<X size={16} color="white" />}
            onLabel={<Check size={16} color="white" />}
            style={{ margin: '0 0 24px 24px' }}
         />
         {isFree && (
            <Banner
               variant="info"
               children={
                  data.deliveryType === 'SEPARATE_ORDER' ? (
                     'To zamówienie nie będzie miało możliwości wystawienia faktury.'
                  ) : (
                     <>
                        <p>To zamówienie nie będzie miało możliwości wystawienia faktury.</p>
                        <p>Palety zostaną dołączone do kolejnego zamówienia klienta.</p>
                     </>
                  )
               }
               className={styles.freeOrderInfo}
            />
         )}
         {data.deliveryType && (
            <SelectedDeliveryTypeSection selectedDeliveryType={data.deliveryType} />
         )}
         <SelectedClientSection selectedClient={data.client} />
         {additionalSections}
         <OverviewPalletsConfigurationSection
            logisticMinimumAchieved={logisticMinimumAchieved}
            isOrderFree={isFree}
            pallets={pallets}
            palletsSum={countPalletsOrderSum(pallets)}
            palletsTotalPrice={countPalletsOrderTotalPrice(pallets)}
         />
         {isVisibleStorageSection && (
            <OverviewDeliveredFromStorageSection storage={data.storage!} />
         )}
         {bottomButtons && <div className={styles.btnsContainer}>{bottomButtons}</div>}
      </div>
   );
};

export default OrderSummary;
