import { ClientDataFormProps, ClientUserDTO } from 'interfaces';

import { parseClientDeliveryAddressIntoDeliveryAddress } from './parseClientDeliveryAddressIntoDeliveryAddress';

export const parseClientUserDTOIntoClientDataFormData: (
   clientUserDTO: ClientUserDTO,
) => ClientDataFormProps = clientUserDTO => {
   const {
      id,
      email,
      nip,
      companyName,
      zipCode,
      city,
      address,
      phoneNumber,
      invoiceEmail,
      deliveryAddresses,
   } = clientUserDTO;

   const clientDeliveryAddresses = deliveryAddresses.map(
      parseClientDeliveryAddressIntoDeliveryAddress,
   );

   return {
      id,
      email,
      companyName,
      nip: nip || '',
      zipCode: zipCode || '',
      city: city || '',
      address: address || '',
      phoneNumber: phoneNumber || '',
      invoiceEmail: invoiceEmail || '',
      deliveryAddresses: clientDeliveryAddresses,
   };
};
