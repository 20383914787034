import { SectionContainer } from 'components/shared';
import { useResponsiveLayout } from 'hooks';
import { PalletReport } from 'interfaces';

import DamagedLostPalletsMobileList from './DamagedLostPalletsMobileList/DamagedLostPalletsMobileList';
import DamagedLostPalletsTable from './DamagedLostPalletsTable/DamagedLostPalletsTable';

type DamagedLostPalletsListSectionProps = {
   palletReports: PalletReport[];
   title: string;
   isLoading?: boolean;
   isDamagedLostBySupplier?: boolean;
};

const DamagedLostPalletsListSection = ({
   palletReports,
   title,
   isLoading,
   isDamagedLostBySupplier,
}: DamagedLostPalletsListSectionProps) => {
   const { isTablet } = useResponsiveLayout();

   return (
      <SectionContainer title={title} isLoading={isLoading}>
         {!isTablet ? (
            <DamagedLostPalletsTable
               palletReports={palletReports}
               isDamagedLostBySupplier={isDamagedLostBySupplier}
            />
         ) : (
            <DamagedLostPalletsMobileList
               palletReports={palletReports}
               isDamagedLostBySupplier={isDamagedLostBySupplier}
            />
         )}
      </SectionContainer>
   );
};

export default DamagedLostPalletsListSection;
