import { isString } from 'lodash';

import { OrderTerms } from 'components/PlaceOrderFormCommon';
import { SectionContainer, StyledButton } from 'components/shared';
import { SELF_PICKUP } from 'constants/orderAddress';
import {
   AddressData,
   PalletContractPrices,
   PalletCustomPrices,
   PalletData,
   PalletSpecification,
   PalletType,
} from 'interfaces';
import { Banner } from 'storybook';
import { getFullAddress, getPalletPrice } from 'utils/functions';

import PalletConfigurationCard from './PalletConfigurationCard/PalletConfigurationCard';
import styles from './EditPalletsConfigurationSection.module.css';

type EditPalletsConfigurationSectionProps = {
   pallets: PalletData[];
   availablePalletTypes: string[];
   palletTypes: PalletSpecification[];
   palletContractPrices?: PalletContractPrices | null;
   palletCustomPrices?: PalletCustomPrices;
   showPalletPrices?: boolean;
   isOrderFree?: boolean;
   selectedAddress?: AddressData | 'SELF_PICKUP' | null;
   onPalletRadioChange: (name: string, orderType: 'LOGISTIC_MINIMUM' | 'CUSTOM') => void;
   onPalletQuantityChange: (value: string, name: string) => void;
   onClearPallet: (name: PalletType) => void;
   onClickChangePalletsPrices?: () => void;
   errors?: string | { [key: string]: string };
   touched?: boolean;
   onSave?: () => void;
   onCancel?: () => void;
   isLoading?: boolean;
};

const EditPalletsConfigurationSection = ({
   pallets,
   availablePalletTypes,
   palletTypes,
   palletContractPrices,
   palletCustomPrices,
   showPalletPrices,
   isOrderFree,
   selectedAddress,
   onPalletRadioChange,
   onPalletQuantityChange,
   onClearPallet,
   onClickChangePalletsPrices,
   errors,
   touched,
   onSave,
   onCancel,
   isLoading,
}: EditPalletsConfigurationSectionProps) => {
   const selectedFullAddress =
      selectedAddress && selectedAddress !== SELF_PICKUP
         ? getFullAddress(
              selectedAddress.value.street,
              selectedAddress.value.zipCode,
              selectedAddress.value.city,
              selectedAddress.value.name,
           )
         : undefined;

   return (
      <SectionContainer
         title="Konfiguracja palet"
         onSave={onSave}
         onCancel={onCancel}
         isLoading={isLoading}
         divider
      >
         {!isOrderFree && onClickChangePalletsPrices && (
            <StyledButton
               type="button"
               variant="outlined-primary"
               text="Zmień ceny palet dla tego zamówienia"
               onClick={onClickChangePalletsPrices}
               disabled={!availablePalletTypes.length || !selectedAddress}
               className={styles.changePriceBtn}
            />
         )}
         {isString(errors) && touched && (
            <Banner
               children="Musisz wybrać przynajmniej jeden rodzaj palet."
               fullWidth
               variant="error"
               style={{ marginBottom: 24 }}
            />
         )}
         <div className={styles.cardsContainerSm}>
            {palletTypes.map((palletType, index) => {
               return (
                  <PalletConfigurationCard
                     disabled={
                        !availablePalletTypes.some(
                           availablePalletType => availablePalletType === palletType.name,
                        )
                     }
                     name={palletType.name}
                     onRadioChange={onPalletRadioChange}
                     onChange={onPalletQuantityChange}
                     onClear={onClearPallet}
                     title={palletType.label}
                     dimensions={palletType.dimensions}
                     price={
                        palletContractPrices?.[palletType.name] &&
                        palletCustomPrices &&
                        selectedAddress &&
                        !isOrderFree
                           ? getPalletPrice(
                                palletType.name,
                                palletContractPrices,
                                palletCustomPrices,
                                selectedFullAddress,
                             )
                           : 0
                     }
                     showPalletPrices={showPalletPrices}
                     logisticMinimum={palletType.logisticMinimum}
                     key={index}
                     value={pallets.find((pallet: PalletData) => pallet.name === palletType.name)}
                     errors={!errors || isString(errors) ? {} : errors}
                  />
               );
            })}
         </div>
         <OrderTerms />
      </SectionContainer>
   );
};

export default EditPalletsConfigurationSection;
