import { TransferPalletBalance } from 'interfaces';

export const getDefaultTransferPalletsValues: () => TransferPalletBalance[] = () => [
   {
      palletName: 'EDHP_PALLET',
      invalidAvailablePallets: '',
      validAvailablePallets: '',
   },
   {
      palletName: 'EURO_PALLET',
      invalidAvailablePallets: '',
      validAvailablePallets: '',
   },
   {
      palletName: 'HALF_PALLET_NDHP',
      invalidAvailablePallets: '',
      validAvailablePallets: '',
   },
   {
      palletName: 'QUARTER_PALLET_CDHP',
      invalidAvailablePallets: '',
      validAvailablePallets: '',
   },
];
