import React, { CSSProperties } from 'react';

import classNames from 'classnames';

import styles from './Badge.module.css';

export interface BadgeProps {
   backgroundColor?: string;
   textColor?: string;
   className?: string;
   text: string;
   style?: CSSProperties;
}

export const Badge = ({ backgroundColor, textColor, text, className, style }: BadgeProps) => {
   return (
      <div
         className={classNames(styles.badge, className)}
         style={{ backgroundColor: backgroundColor, color: textColor, ...style }}
      >
         {text}
      </div>
   );
};
