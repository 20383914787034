import classNames from 'classnames';

import { SharedGridTableElementProps } from 'interfaces/commonInterfacesAndTypes';

import styles from './SharedGridTableCell.module.css';

const SharedGridTableCell = ({ children, className }: SharedGridTableElementProps) => {
   return <div className={classNames(styles.cell, className)}>{children}</div>;
};

export default SharedGridTableCell;
