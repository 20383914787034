import { useMemo } from 'react';

import { isEqual } from 'lodash';

import { ExtendableSharedGridTableRow } from 'components/shared';
import { PalletsPricingRowLabel } from 'components/SupplierPanel';
import { PalletsPricingPerAddressFormData, PalletType } from 'interfaces';

import PalletsPricingPerAddressInnerFormTableRow from './PalletsPricingPerAddressInnerFormTableRow/PalletsPricingPerAddressInnerFormTableRow';

type PalletsPricingPerAddressFormTableRowProps = {
   palletsPricingPerAddress: PalletsPricingPerAddressFormData;
   basePalletsPricingPerAddressData: PalletsPricingPerAddressFormData;
   isBasePalletsPricingRows: boolean;
   error?: Record<string, boolean>[];
   onPalletPricingChange: (
      isBasePalletsPricing: boolean,
      enteredValue: string,
      palletType: PalletType,
      address: string,
   ) => void;
   onPalletPricingBlur: (
      isBasePalletsPricing: boolean,
      enteredValue: string,
      palletType: PalletType,
      address: string,
   ) => void;
};

const PalletsPricingPerAddressFormTableRow = ({
   palletsPricingPerAddress,
   basePalletsPricingPerAddressData,
   isBasePalletsPricingRows,
   error,
   onPalletPricingChange,
   onPalletPricingBlur,
}: PalletsPricingPerAddressFormTableRowProps) => {
   const { addressName, palletsPricing } = palletsPricingPerAddress;

   const isPricingDifferentFromBase = !isEqual(
      palletsPricingPerAddress.palletsPricing,
      basePalletsPricingPerAddressData.palletsPricing,
   );

   const palletPricingInnerTableItems = useMemo(() => {
      return palletsPricing.map((item, index) => {
         const basePalletPricing = basePalletsPricingPerAddressData.palletsPricing.find(
            palletsPricingItem => palletsPricingItem.palletType === item.palletType,
         );
         if (!basePalletPricing) {
            return null;
         }
         return (
            <PalletsPricingPerAddressInnerFormTableRow
               key={`${addressName}/${item.palletType}`}
               isBasePalletsPricingRows={isBasePalletsPricingRows}
               fullAddress={addressName}
               palletPricing={item}
               basePalletPricing={basePalletPricing}
               error={error?.[index]}
               onPalletPricingChange={onPalletPricingChange}
               onPalletPricingBlur={onPalletPricingBlur}
            />
         );
      });
   }, [
      basePalletsPricingPerAddressData.palletsPricing,
      error,
      addressName,
      isBasePalletsPricingRows,
      onPalletPricingBlur,
      onPalletPricingChange,
      palletsPricing,
   ]);

   return (
      <ExtendableSharedGridTableRow
         key={addressName}
         label={
            <PalletsPricingRowLabel
               addressName={addressName}
               isPricingDifferentFromBase={isPricingDifferentFromBase}
            />
         }
         isDefaultOpen={isBasePalletsPricingRows}
         extendableContent={palletPricingInnerTableItems}
      />
   );
};

export default PalletsPricingPerAddressFormTableRow;
