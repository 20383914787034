import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './TableCell.module.css';

export interface TableCellProps {
   children?: ReactNode;
   align?: 'left' | 'right' | 'center';
   className?: string;
}

export const TableCell = ({ children, align, className }: TableCellProps) => {
   return (
      <td style={{ textAlign: align }} className={classNames(styles.tableCell, className)}>
         {children}
      </td>
   );
};
