import { ChangeEvent, FormEvent, useState } from 'react';

import { Loader } from '@mantine/core';
import { Search } from 'tabler-icons-react';

import { BuildingWarehouseIcon } from 'assets';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { Balance, BannerVariants, StorageWithDate } from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';
import { getFormattedDate } from 'utils/functions';

import DeleteStorageModal from '../DeleteStorageModal/DeleteStorageModal';
import EditStorageBalanceModal from '../EditStorageBalanceModal/EditStorageBalanceModal';
import { NoItemsNotificationWithLink, StyledTextInput } from '../shared';
import StorageItem from '../StorageItem/StorageItem';

import styles from './StoragesList.module.css';

type StoragesListProps = {
   loading: boolean;
   internal?: boolean;
   storages: StorageWithDate[];
   handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
   handleDateChange: (value: Date | null, id: string, onFinish: () => void) => void;
   searchValue: string;
   handleForceReload: () => void;
   openAddStorage: () => void;
   showBanner: (variant: BannerVariants, description: string) => void;
};

type RemoveState = {
   selectedStorage: string;
   canBeDeleted: boolean;
} | null;

const StoragesList = ({
   loading,
   storages,
   searchValue,
   handleSearchChange,
   handleDateChange,
   handleForceReload,
   openAddStorage,
   showBanner,
   internal,
}: StoragesListProps) => {
   const [storageSelectedForDelete, setStorageSelectedForDelete] = useState<RemoveState>(null);
   const [storageSelectedForEdit, setStorageSelectedForEdit] = useState<StorageWithDate | null>(
      null,
   );
   const [loadingInModal, setLoadingInModal] = useState<boolean>(false);
   const [errorInModal, setErrorInModal] = useState<boolean>(false);

   const handleEditStorageClick = (storage: StorageWithDate) => setStorageSelectedForEdit(storage);

   const handleRemoveStorageClick = (storageId: string) => {
      HTTPService.getStorageRemoveState(storageId)
         .then(({ data }) => {
            setStorageSelectedForDelete({
               selectedStorage: storageId,
               canBeDeleted: data.canBeDeleted,
            });
         })
         .catch(handleActionError);
   };

   const handleRemoveStorage = () => {
      if (storageSelectedForDelete?.selectedStorage) {
         setLoadingInModal(true);
         HTTPService.removeStorage(storageSelectedForDelete.selectedStorage)
            .then(() => {
               handleForceReload();
               showBanner('success', 'Magazyn został usunięty.');
            })
            .catch(handleActionError)
            .finally(() => {
               setLoadingInModal(false);
               handleCloseRemoveModal();
            });
      }
   };

   const handleSubmit = (
      event: FormEvent<HTMLFormElement>,
      balance: Balance,
      date: Date,
      id: string,
   ) => {
      event.preventDefault();
      setLoadingInModal(true);
      HTTPService.updateStorageBalance(id, {
         balance,
         date: getFormattedDate(date),
      })
         .then(() => {
            handleCloseEditModal();
            handleForceReload();
            showBanner('success', 'Saldo palet zostało zaktualizowane.');
         })
         .catch(error => handleActionError(error, true))
         .finally(() => setLoadingInModal(false));
   };

   const handleCloseRemoveModal = () => {
      setStorageSelectedForDelete(null);
      handleForceReload();
   };

   const handleCloseEditModal = () => {
      setStorageSelectedForEdit(null);
      setErrorInModal(false);
   };

   const handleActionError = (error: unknown, isErrorInModal?: boolean) =>
      errorHandler(error, () => actionErrorHandler(isErrorInModal));

   const actionErrorHandler = (isErrorInModal?: boolean) => {
      if (isErrorInModal) {
         setErrorInModal(true);
         return;
      }
      showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION);
   };

   return (
      <>
         {storageSelectedForEdit && (
            <EditStorageBalanceModal
               storage={storageSelectedForEdit}
               onSubmit={handleSubmit}
               showErrorBanner={errorInModal}
               onClose={handleCloseEditModal}
               loading={loadingInModal}
            />
         )}
         {storageSelectedForDelete && (
            <DeleteStorageModal
               opened
               onRemoveClick={handleRemoveStorage}
               canBeDeleted={storageSelectedForDelete?.canBeDeleted}
               onClose={handleCloseRemoveModal}
               loading={loadingInModal}
            />
         )}
         <div className={styles.listContainer}>
            <StyledTextInput
               leftIcon={<Search size={20} color="var(--primary-text)" />}
               type="text"
               placeholder="Wpisz ulicę, kod pocztowy lub miasto"
               value={searchValue}
               onChange={handleSearchChange}
               className={styles.search}
            />
            {loading ? (
               <div className={styles.loaderContainer}>
                  <Loader className={styles.loader} color="var(--light-green)" />
               </div>
            ) : (
               <div className={styles.storagesContainer}>
                  {!!storages.length ? (
                     storages?.map(storage => (
                        <StorageItem
                           key={storage.id}
                           storage={storage}
                           handleDateChange={handleDateChange}
                           handleDeleteClick={() => handleRemoveStorageClick(storage.id)}
                           handleEditClick={() => handleEditStorageClick(storage)}
                           internal={internal}
                        />
                     ))
                  ) : (
                     <NoItemsNotificationWithLink
                        icon={<BuildingWarehouseIcon />}
                        desctiption="Brak magazynów"
                        linkText="Dodaj nowy magazyn"
                        onClick={openAddStorage}
                     />
                  )}
               </div>
            )}
         </div>
      </>
   );
};

export default StoragesList;
