import { useRoutes } from 'react-router-dom';

import { authRoutes } from './routes/auth';
import { clientRoutes } from './routes/client';
import { distributionCenterRoutes } from './routes/distributionCenter';
import { storageRoutes } from './routes/storage';
import { supplierRoutes } from './routes/supplier';

export const AppRouter = () =>
   useRoutes([clientRoutes, authRoutes, supplierRoutes, distributionCenterRoutes, storageRoutes]);
