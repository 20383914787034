import {
   EMPTY_INPUT_ERROR,
   NO_NUMERIC_VALUE_ERROR,
   NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
   PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR,
   PALLETS_AMOUNT_NO_INTEGER_ERROR,
} from 'constants/validationErrors';
import { Option } from 'interfaces';

import { isInteger, isNonEmpty, isNumeric, moreThanZero } from './validationFunctions';

export const OtherTypeChargeValidation: {
   [key: string]: (value: string | undefined) => string | false;
} = {
   otherChargeName: (otherChargeName: string | undefined) =>
      !isNonEmpty(otherChargeName) && EMPTY_INPUT_ERROR,
   amount: (amount: string | undefined) => {
      if (!amount || !isNonEmpty(amount)) {
         return EMPTY_INPUT_ERROR;
      }
      if (!isNumeric(amount)) {
         return NO_NUMERIC_VALUE_ERROR;
      }
      if (!moreThanZero(amount)) {
         return NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR;
      }
      return false;
   },
};

export const DamagedLostChargeValidation: {
   [key: string]: ((arg?: string) => string | false) | ((arg?: Option) => string | false);
} = {
   palletType: (palletType?: Option) => !isNonEmpty(palletType?.value) && EMPTY_INPUT_ERROR,
   palletsAmount: (palletsAmount?: string) => {
      if (!isNonEmpty(palletsAmount)) {
         return EMPTY_INPUT_ERROR;
      }
      if (!moreThanZero(palletsAmount!)) {
         return PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR;
      }
      if (!isInteger(palletsAmount!)) {
         return PALLETS_AMOUNT_NO_INTEGER_ERROR;
      }
      return false;
   },
   address: (address?: Option) => !isNonEmpty(address?.value) && EMPTY_INPUT_ERROR,
};
