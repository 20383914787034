import classNames from 'classnames';

import { ImagesContainer, StyledBadge } from 'components/shared';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { REPORT_STATUSES_AND_LABELS } from 'constants/reportStatuses';
import { LostDamagedPalletReport } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './LostDamagedPalletReportsTableItem.module.css';

type LostDamagedPalletReportsTableItemProps = {
   lostDamagedPalletReport: LostDamagedPalletReport;
   isFirstRow?: boolean;
};

const LostDamagedPalletReportsTableItem = ({
   lostDamagedPalletReport,
   isFirstRow,
}: LostDamagedPalletReportsTableItemProps) => (
   <TableRow
      className={classNames(styles.tableRow, { [styles.tableRowWithTopFreeSpace]: !isFirstRow })}
   >
      <TableCell>
         <StyledBadge
            text={REPORT_STATUSES_AND_LABELS[lostDamagedPalletReport.status].label}
            variant={REPORT_STATUSES_AND_LABELS[lostDamagedPalletReport.status].variant}
         />
      </TableCell>
      <TableCell>{PALLET_TYPE_LABELS[lostDamagedPalletReport.damagedPallets[0].type]}</TableCell>
      <TableCell align="right">{lostDamagedPalletReport.damagedPallets[0].amount}</TableCell>
      <TableCell>
         <ImagesContainer imageURLs={lostDamagedPalletReport.damagedPallets[0].images} />
      </TableCell>
   </TableRow>
);

export default LostDamagedPalletReportsTableItem;
