import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { useDebouncedValue } from '@mantine/hooks';
import { Search } from 'tabler-icons-react';

import { UserOffIcon } from 'assets';
import {
   CustomInfiniteScroll,
   NoItemsNotificationWithLink,
   StyledTextInput,
} from 'components/shared';
import { ClientPalletBalanceTable } from 'components/Storages';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import {
   PAGINATION_FIRST_PAGE,
   PAGINATION_INITIAL_ELEMENT_COUNT,
   PAGINATION_SIZE,
} from 'constants/pagination';
import { SUPPLIER_ADD_CLIENT } from 'constants/routes';
import { SCROLL_TOP_POSITION } from 'constants/scroll';
import { usePagination } from 'hooks';
import { BannerVariants, ClientWithBalanceDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './ClientPalletBalancesPage.module.css';

type ClientPalletBalancesPageProps = {
   showBanner: (variant: BannerVariants, description: string) => void;
};

const ClientPalletBalancesPage = ({ showBanner }: ClientPalletBalancesPageProps) => {
   const [clientPalletBalances, setClientPalletBalances] = useState<ClientWithBalanceDTO[]>([]);
   const [clientPalletBalancesTotalCount, setClientPalletBalancesTotalCount] = useState(
      PAGINATION_INITIAL_ELEMENT_COUNT,
   );
   const [companyNameFilterValue, setCompanyNameFilterValue] = useState('');
   const [isClientPalletBalancesFirstFetchLoading, setIsClientPalletBalancesFirstFetchLoading] =
      useState(false);
   const [debouncedCompanyNameFilterValue] = useDebouncedValue(
      companyNameFilterValue,
      DEBOUNCE_DELAY,
   );
   const tableContainer = useRef<HTMLDivElement>(null);

   const handleClientPalletBalancesFirstPageData = useCallback(
      (clientPalletBalancesData: ClientWithBalanceDTO[], totalElements: number) => {
         setClientPalletBalances(clientPalletBalancesData);
         setClientPalletBalancesTotalCount(totalElements);
         if (!tableContainer.current) {
            return;
         }
         tableContainer.current.scrollTop = SCROLL_TOP_POSITION;
      },
      [],
   );

   const fetchClientPalletBalances = useCallback(
      (currentPage: number, companyNameSearch?: string) => {
         if (currentPage === PAGINATION_FIRST_PAGE) {
            setIsClientPalletBalancesFirstFetchLoading(true);
         }
         HTTPService.getClientPalletBalances({
            page: currentPage,
            size: PAGINATION_SIZE,
            companyNameSearch,
         })
            .then(({ data: { content: clientPalletBalancesData, totalElements } }) => {
               if (currentPage === PAGINATION_FIRST_PAGE) {
                  handleClientPalletBalancesFirstPageData(clientPalletBalancesData, totalElements);
                  return;
               }
               setClientPalletBalances(prevOrders => prevOrders.concat(clientPalletBalancesData));
            })
            .catch(error => {
               logNetworkError(error);
               showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA);
            })
            .finally(() => {
               if (currentPage !== PAGINATION_FIRST_PAGE) {
                  return;
               }
               setIsClientPalletBalancesFirstFetchLoading(false);
            });
      },
      [showBanner, handleClientPalletBalancesFirstPageData],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchClientPalletBalances,
      filter: debouncedCompanyNameFilterValue,
   });

   const handleChangeCompanyNameFilterValue = (event: ChangeEvent<HTMLInputElement>) =>
      setCompanyNameFilterValue(event.target.value);

   return (
      <>
         <div className={styles.header}>
            <StyledTextInput
               type="text"
               onChange={handleChangeCompanyNameFilterValue}
               value={companyNameFilterValue}
               placeholder="wpisz nazwę firmy"
               leftIcon={<Search />}
               className={styles.searchInput}
            />
         </div>
         <CustomInfiniteScroll
            containerId="clientPalletBalanceTableInfiniteScrollContainer"
            containerRef={tableContainer}
            next={handleLoadNextItems}
            dataLength={clientPalletBalances.length}
            hasMore={clientPalletBalances.length !== clientPalletBalancesTotalCount}
            isInitialLoading={isClientPalletBalancesFirstFetchLoading}
            noItemsNotificationElement={
               <NoItemsNotificationWithLink
                  icon={<UserOffIcon />}
                  desctiption="Nie dodałeś jeszcze żadnych klientów."
                  linkText="Dodaj klienta"
                  redirectTo={SUPPLIER_ADD_CLIENT}
               />
            }
         >
            <ClientPalletBalanceTable clientPalletBalances={clientPalletBalances} />
         </CustomInfiniteScroll>
      </>
   );
};

export default ClientPalletBalancesPage;
