import { ChangeEvent, Fragment, useState } from 'react';
import { useMemo } from 'react';

import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { AlertTriangle, Calendar, Search } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import {
   NoItemsNotification,
   SectionContainer,
   StyledButton,
   StyledTable,
   StyledTextInput,
   ToolTip,
} from 'components/shared';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import {
   sendPalletsSectionTableHeaders,
   sendPalletsSectionTableHeadersWithDamagedPallets,
} from 'constants/tableHeaders';
import { useResponsiveLayout } from 'hooks/useResponsiveLayout';
import { OrderStatus, Pallet, SendPalletDTO } from 'interfaces';
import MobileCard from 'pages/ClientPanel/OrdersPage/OrderDetailsPage/MobileCard/MobileCard';
import { Banner, TableCell, TableRow } from 'storybook';
import { getValueLabel } from 'utils/functions';

import styles from './SendPalletsSection.module.css';

type SendPalletsSectionProps = {
   sendPallets: SendPalletDTO[];
   remainingPalletsToBeSend?: Pallet[];
   orderStatus?: OrderStatus;
   onClickSendPallets?: () => void;
   onClickEditPallets?: () => void;
};

const SendPalletsSection = ({
   sendPallets,
   remainingPalletsToBeSend,
   onClickSendPallets,
   orderStatus,
   onClickEditPallets,
}: SendPalletsSectionProps) => {
   const [externalCommercialNetworkNumberFilter, setExternalCommercialNetworkNumberFilter] =
      useState('');
   const [debouncedExternalCommercialNetworkNumberFilter] = useDebouncedValue(
      externalCommercialNetworkNumberFilter,
      DEBOUNCE_DELAY,
   );
   const { isMobile } = useResponsiveLayout();

   const filteredSendPallets = useMemo(
      () =>
         sendPallets.filter(sendPalletsItem => {
            const parsedExternalCommercialNetworkNumber =
               sendPalletsItem.externalCommercialNetworkNumber?.toLowerCase().trim();
            return parsedExternalCommercialNetworkNumber?.includes(
               debouncedExternalCommercialNetworkNumberFilter,
            );
         }),
      [debouncedExternalCommercialNetworkNumberFilter, sendPallets],
   );

   const isAnyFilteredSendPalletsItemIncludesDamagedPallets = useMemo(
      () => filteredSendPallets.some(sendPalletsItem => sendPalletsItem.includesDamagedPallets),
      [filteredSendPallets],
   );

   const noSendPalletsNotificationText = debouncedExternalCommercialNetworkNumberFilter
      ? 'Brak wyników.'
      : 'Nie nadano jeszcze żadnych palet.';

   return (
      <SectionContainer title="Nadania">
         <div className={styles.toolbarContainer}>
            <StyledTextInput
               type="text"
               onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setExternalCommercialNetworkNumberFilter(event.target.value.toLowerCase().trim())
               }
               value={externalCommercialNetworkNumberFilter}
               placeholder="wpisz nr zam. sieci"
               leftIcon={<Search />}
               className={styles.searchInput}
            />
            <div>
               {onClickSendPallets && (
                  <StyledButton
                     variant="outlined-primary"
                     text="Nadaj palety"
                     onClick={onClickSendPallets}
                  />
               )}
               {onClickEditPallets && (
                  <StyledButton
                     variant="outlined-primary"
                     text="Edytuj nadania"
                     onClick={onClickEditPallets}
                  />
               )}
            </div>
         </div>
         {isMobile ? (
            <>
               {filteredSendPallets.length ? (
                  <>
                     {filteredSendPallets.map((sendPalletsItem, index) => (
                        <MobileCard
                           key={index}
                           data={sendPalletsItem}
                           includesDamagedPallets={sendPalletsItem.includesDamagedPallets}
                        />
                     ))}
                  </>
               ) : (
                  <NoItemsNotification
                     icon={<PalletsStackIcon />}
                     description={noSendPalletsNotificationText}
                  />
               )}
            </>
         ) : (
            <StyledTable
               columnHeaders={
                  isAnyFilteredSendPalletsItemIncludesDamagedPallets
                     ? sendPalletsSectionTableHeadersWithDamagedPallets
                     : sendPalletsSectionTableHeaders
               }
               headerClassName={styles.tableHeader}
               className={styles.table}
            >
               {filteredSendPallets.length ? (
                  <>
                     {filteredSendPallets.map((sendPalletsItem, index) => {
                        const isFirstRow = !index;
                        return (
                           <Fragment key={index}>
                              <TableRow
                                 className={classNames({
                                    [styles.tableRowWithTopFreeSpace]: !isFirstRow,
                                 })}
                              >
                                 {isAnyFilteredSendPalletsItemIncludesDamagedPallets && (
                                    <TableCell>
                                       {sendPalletsItem.includesDamagedPallets && (
                                          <ToolTip
                                             content="Nadanie uszkodzonych palet"
                                             variant="right"
                                             visible
                                          >
                                             <AlertTriangle color={'var(--primary-red)'} />
                                          </ToolTip>
                                       )}
                                    </TableCell>
                                 )}
                                 <TableCell className={styles.calendarTableCell}>
                                    <span>
                                       <Calendar />
                                       <span>{sendPalletsItem.dateOfShipment}</span>
                                    </span>
                                 </TableCell>
                                 <TableCell>
                                    {getValueLabel(sendPalletsItem.externalCommercialNetworkNumber)}
                                 </TableCell>
                                 <TableCell>{sendPalletsItem.displayName}</TableCell>
                                 <TableCell>{sendPalletsItem.displayAddress}</TableCell>
                                 <TableCell>
                                    {PALLET_TYPE_LABELS[sendPalletsItem.pallets[0].palletType]}
                                 </TableCell>
                                 <TableCell className={styles.palletCell} align="right">
                                    {sendPalletsItem.pallets[0].amount}
                                 </TableCell>
                              </TableRow>

                              {sendPalletsItem.pallets.map(
                                 (pallet, palletIndex) =>
                                    palletIndex !== 0 && (
                                       <TableRow key={palletIndex} className={styles.palletRow}>
                                          {isAnyFilteredSendPalletsItemIncludesDamagedPallets && (
                                             <TableCell />
                                          )}
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          <TableCell>
                                             {PALLET_TYPE_LABELS[pallet.palletType]}
                                          </TableCell>
                                          <TableCell className={styles.palletCell} align="right">
                                             {pallet.amount}
                                          </TableCell>
                                       </TableRow>
                                    ),
                              )}
                           </Fragment>
                        );
                     })}
                  </>
               ) : (
                  <tr>
                     <td colSpan={6}>
                        <NoItemsNotification
                           icon={<PalletsStackIcon />}
                           description={noSendPalletsNotificationText}
                           desktopSmallNoItemsNotification
                        />
                     </td>
                  </tr>
               )}
            </StyledTable>
         )}
         {remainingPalletsToBeSend && !isEmpty(remainingPalletsToBeSend) && (
            <Banner
               className={styles.remainingPalletsToBeSendBanner}
               fullWidth
               variant="info"
               children={
                  <>
                     Nie rozdysponowano wszystkich palet. pozostały:
                     <ul style={{ marginTop: 0 }}>
                        {remainingPalletsToBeSend.map((pallet, index) => (
                           <li key={index}>
                              {PALLET_TYPE_LABELS[pallet.palletType]}{' '}
                              <strong>{pallet.amount} palet</strong>
                           </li>
                        ))}
                     </ul>
                  </>
               }
            />
         )}
         {isEmpty(remainingPalletsToBeSend) && orderStatus === 'DELIVERED' && (
            <Banner
               variant="success"
               children="Wszystkie palety zostały rozdysponowane."
               fullWidth
               className={styles.allPalletsSendBanner}
            />
         )}
      </SectionContainer>
   );
};

export default SendPalletsSection;
