import { DistributionCenterBalanceItemDTO } from 'interfaces';
import { Divider } from 'storybook';

import SupplierPalletsNumberToPickUpItem from './SupplierPalletsNumberToPickUpItem/SupplierPalletsNumberToPickUpItem';
import styles from './SupplierPalletsNumberToPickUp.module.css';

type SupplierPalletsNumberToPickUpProps = {
   totalSum: DistributionCenterBalanceItemDTO;
   totalSumDifference: DistributionCenterBalanceItemDTO;
};

const SupplierPalletsNumberToPickUp = ({
   totalSum,
   totalSumDifference,
}: SupplierPalletsNumberToPickUpProps) => {
   const { sum, invalid, valid } = totalSum;
   const {
      sum: sumDifference,
      invalid: invalidDifference,
      valid: validDifference,
   } = totalSumDifference;

   return (
      <div className={styles.container}>
         <SupplierPalletsNumberToPickUpItem
            title="Dobre"
            palletsNumber={valid}
            palletsDifference={validDifference}
         />
         <SupplierPalletsNumberToPickUpItem
            title="Uszkodzone"
            palletsNumber={invalid}
            palletsDifference={invalidDifference}
         />
         <Divider variant="vertical" className={styles.divider} />
         <SupplierPalletsNumberToPickUpItem
            title="Łącznie"
            palletsNumber={sum}
            palletsDifference={sumDifference}
            isSummary
         />
      </div>
   );
};

export default SupplierPalletsNumberToPickUp;
