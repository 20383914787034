import { useMemo } from 'react';

import { OrderSummaryAndOrderDetailsPallet } from 'interfaces';
import { countPallets, getPalletLabel } from 'utils/functions';
import { numberFormatter } from 'utils/numberFormatter';
import { isOrderDetailsPallet } from 'utils/typeGuards';

import styles from './PalletTotalAmountInfo.module.css';

type PalletTotalAmountInfoProps = {
   pallet: OrderSummaryAndOrderDetailsPallet;
};

const PalletTotalAmountInfo = ({ pallet }: PalletTotalAmountInfoProps) => {
   const palletsTotalAmount = useMemo(() => {
      const isOrderDetailsPalletMode = isOrderDetailsPallet(pallet);
      if (isOrderDetailsPalletMode) {
         const regularPalletsAmount = countPallets(pallet);
         return regularPalletsAmount + pallet.freePalletsAmount;
      } else {
         const convertedPalletsAmount = Number(pallet.amount);
         return pallet.orderType !== 'CUSTOM'
            ? pallet.logisticMinimum * convertedPalletsAmount
            : convertedPalletsAmount;
      }
   }, [pallet]);

   return (
      <h2 className={styles.palletsAmount}>
         {numberFormatter.format(palletsTotalAmount)}
         <span>{` ${getPalletLabel(palletsTotalAmount)}`}</span>
      </h2>
   );
};

export default PalletTotalAmountInfo;
