import { uniqueId } from 'lodash';

import { DistributionCenter } from 'interfaces';

import { COMMERCIAL_NETWORK_ITEM_PREFIX } from './uniqueIdPrefixes';

export const COMMERCIAL_NETWORK_DEFAULT_ITEM = () => ({
   uuid: uniqueId(COMMERCIAL_NETWORK_ITEM_PREFIX),
   name: { value: '', label: '' },
   addresses: [],
});

export const distributionCenterEmptyObject: DistributionCenter = {
   id: '',
   createdDate: '',
   address: {
      street: '',
      zipCode: '',
      city: '',
   },
   contactEmail: '',
   phoneNumber: '',
   allowedUserIDs: [''],
   todayBalance: {
      id: '',
      balanceSum: {
         availableForCollection: {
            invalid: 0,
            valid: 0,
            sum: 0,
         },
         palletReturn: {
            invalid: 0,
            valid: 0,
            sum: 0,
         },
      },
      balance: [
         {
            palletName: 'EDHP_PALLET',
            availableForCollection: {
               invalid: 0,
               valid: 0,
               sum: 0,
            },
            palletReturn: {
               invalid: 0,
               valid: 0,
               sum: 0,
            },
         },
      ],
      date: '',
   },
   commercialNetworkId: '',
};
