export const EMPTY_INPUT_ERROR = 'Pole nie może być puste.';
export const EMPTY_INPUTS_ERROR = 'Pola nie mogą być puste.';
export const NO_SELECTED_DELIVERY_DAY_ERROR = 'Wybierz dzień dostarczenia palet.';
export const NO_NUMERIC_VALUE_ERROR = 'Wpisz wartość liczbową.';
export const NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR = 'Wpisz wartość większą od 0.';
export const NO_SELECTED_CLIENT_ERROR = 'Wybierz klienta.';
export const INVALID_ZIP_CODE_ERROR = 'Podaj poprawny kod pocztowy.';
export const INVALID_EMAIL_ERROR = 'Wprowadź poprawny e-mail.';
export const PASSWORD_MIN_LENGTH_ERROR = 'Hasło powinno mieć minimum 6 znaków.';
export const PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR = 'Ilość palet musi być większa od 0.';
export const INVALID_PHONE_NUMBER_ERROR = 'Wprowadź poprawny numer telefonu.';
export const INVALID_NIP_ERROR = 'Wprowadź poprawny NIP.';
export const PALLETS_AMOUNT_NO_INTEGER_ERROR = 'Ilość palet musi być liczbą całkowitą (np. 15).';
export const NO_SELECTED_ORDERS_ERROR = 'Zaznacz zamówienie.';
export const SELECTED_TIME_HAS_ALREADY_PASSES_ERROR = 'Wybrana godzina już minęła.';
export const NO_SELECTED_DELIVERY_ERROR = 'Musisz wybrać sposób dostawy.';
export const NO_SELECTED_STORAGE_ERROR = 'Musisz wybrać magazyn.';
export const NO_UNIQUE_ADDRESS_NAME_ERROR = 'Nazwa adresu musi być unikalna.';
export const INVALID_DATE_ERROR = 'Niepoprawna data.';
