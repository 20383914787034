import { Switch, SwitchProps } from '@mantine/core';
import classNames from 'classnames';

import styles from './CustomSwitch.module.css';

const CustomSwitch = ({ disabled, onChange, ...props }: SwitchProps) => (
   <Switch
      {...props}
      onChange={!disabled ? onChange : undefined}
      classNames={{
         root: classNames(styles.root, { [styles.disabledSwitch]: disabled }),
         track: styles.track,
         trackLabel: styles.trackLabel,
         thumb: styles.thumb,
         label: styles.label,
      }}
   />
);

export default CustomSwitch;
