import { EMPTY_INPUT_ERROR, PASSWORD_MIN_LENGTH_ERROR } from 'constants/validationErrors';

import { isNonEmpty, isValidPassword } from '.';

export const resetPasswordFormValidations = [
   ({ newPassword }: FormFields) => {
      if (!isNonEmpty(newPassword)) {
         return { newPassword: EMPTY_INPUT_ERROR };
      } else {
         return !isValidPassword(newPassword) && { newPassword: PASSWORD_MIN_LENGTH_ERROR };
      }
   },
   ({ repeatedPassword }: FormFields) => {
      return !isNonEmpty(repeatedPassword) && { repeatedPassword: EMPTY_INPUT_ERROR };
   },
];

type FormFields = {
   newPassword: string;
   repeatedPassword: string;
};
