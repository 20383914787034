import { PalletsStackIcon } from 'assets';
import {
   NoItemsNotificationWithLink,
   SectionContainer,
   SharedGridTable,
   SharedGridTableCell,
   SharedGridTableRow,
} from 'components/shared';
import { ClientUserDTO, CommercialNetworkDetailsDTO, DistributionCenterDTO } from 'interfaces';

import style from './ClientDetailsRetailChains.module.css';
interface ClientDetailsRetailChainsProps {
   client: ClientUserDTO;
   commercialNetworksForClient: CommercialNetworkDetailsDTO[];
}
const headers = ['Nazwa Sieci Handlowej', 'Adres Centrum Dystrybucyjnego'];

export const ClientDetailsRetailChains = (client: ClientDetailsRetailChainsProps) => {
   const content = client.commercialNetworksForClient.length ? (
      client.commercialNetworksForClient.map((element: CommercialNetworkDetailsDTO) => {
         return (
            <SharedGridTableRow key={element.id} className={style.tableRow}>
               <SharedGridTableCell className={style.tableCellName}>
                  <h3>{element.name}</h3>
               </SharedGridTableCell>
               <SharedGridTableCell>
                  {element.addresses.map((item: DistributionCenterDTO) => (
                     <SharedGridTableCell className={style.tableCellInformation} key={item.id}>
                        {`${item.address.street}, ${item.address.zipCode} ${item.address.city}`}
                     </SharedGridTableCell>
                  ))}
               </SharedGridTableCell>
            </SharedGridTableRow>
         );
      })
   ) : (
      <NoItemsNotificationWithLink
         icon={<PalletsStackIcon />}
         desctiption="Nie ma przypisanych sieci handlowych."
      />
   );

   return (
      <SectionContainer style={{ marginTop: 24 }} title="Sieci Handlowe">
         <SharedGridTable
            style={{ paddingLeft: '12px' }}
            className={{
               headerRow: style.tableInnerContainer,
               headerCell: style.tableHeader,
            }}
            headers={headers}
            rows={content}
         />
      </SectionContainer>
   );
};
