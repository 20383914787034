import '@fontsource/source-sans-pro';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AuthContextProvider from './context/auth/AuthContextProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
   <React.StrictMode>
      <AuthContextProvider>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </AuthContextProvider>
   </React.StrictMode>,
);

reportWebVitals();
