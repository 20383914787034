import Layout from 'components/layout/MainLayout/Layout';
import {
   CLIENT_DAMAGED_PALLETS_BY_CLIENT,
   CLIENT_DESTROYED_PALLETS_BY_SUPPLIER,
   CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT,
   CLIENT_ORDER_SUCCESS,
   CLIENT_ORDERS,
   CLIENT_PALLETS,
   CLIENT_PALLETS_TRANSFER_HISTORY,
   CLIENT_PLACE_ORDER,
} from 'constants/routes';
import {
   DamagedPalletsByClientPage,
   DamagedPalletsBySupplierPage,
   LostDestroyedPalletsByClientPage,
   OrderDetailsPage,
   OrdersPage,
   OrderSuccessPage,
   PalletsPage,
   PalletsTransferHistoryPage,
   PlaceOrderPage,
} from 'pages/ClientPanel';

export const clientRoutes = {
   element: <Layout />,
   children: [
      { path: CLIENT_DAMAGED_PALLETS_BY_CLIENT, element: <DamagedPalletsByClientPage /> },
      { path: CLIENT_DESTROYED_PALLETS_BY_SUPPLIER, element: <DamagedPalletsBySupplierPage /> },
      {
         path: CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT,
         element: <LostDestroyedPalletsByClientPage />,
      },
      { path: CLIENT_ORDERS, element: <OrdersPage /> },
      { path: CLIENT_ORDER_SUCCESS, element: <OrderSuccessPage /> },
      { path: CLIENT_PALLETS, element: <PalletsPage /> },
      { path: CLIENT_PLACE_ORDER, element: <PlaceOrderPage /> },
      { path: CLIENT_ORDERS + '/:orderId', element: <OrderDetailsPage /> },
      { path: CLIENT_PALLETS_TRANSFER_HISTORY, element: <PalletsTransferHistoryPage /> },
   ],
};
