import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StyledButton, StyledTextInput } from 'components/shared';
import { EXPIRED_ACTION_CODE, INVALID_ACTION_CODE, USER_NOT_FOUND } from 'constants/errorCodes';
import { LOGIN } from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import { useForm } from 'hooks/useForm';
import { Banner } from 'storybook';
import { resetPasswordFormValidations } from 'utils/validation/resetPasswordFormValidation';

import styles from './ResetPasswordForm.module.css';

const initialState = { newPassword: '', repeatedPassword: '' };

const errorMessages = new Map([
   [USER_NOT_FOUND, 'Wystąpił nieoczekiwany błąd. Skontaktuj się z administratorem strony.'],
   [EXPIRED_ACTION_CODE, 'Link do resetowania hasła nie jest ważny.'],
   [INVALID_ACTION_CODE, 'Link do resetowania hasła został już użyty lub jest niepoprawny.'],
]);

const ResetPasswordForm = () => {
   const { resetPassword } = useAuth();
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const [resetPasswordError, setResetPasswordError] = useState('');
   const [loading, setLoading] = useState(false);

   const handleSubmit = () => {
      setResetPasswordError('');
      if (values.newPassword !== values.repeatedPassword) {
         setResetPasswordError('Podane hasła nie są identyczne.');
         return;
      }

      const oobCode = searchParams.get('oobCode');
      if (!oobCode) {
         setResetPasswordError('Link do resetowania hasła jest niepoprawny.');
         return;
      }

      setLoading(true);
      resetPassword(oobCode, values.newPassword)
         .then(() => navigateToLoginPage())
         .catch((error: Error) => {
            const errorMessage = errorMessages.get(error.message);
            setResetPasswordError(errorMessage || 'Błąd podczas resetowania hasła.');
         })
         .finally(() => setLoading(false));
   };

   const { values, changeHandler, touched, submitHandler, errorsList } = useForm({
      initialState: initialState,
      validations: resetPasswordFormValidations,
      onSubmit: handleSubmit,
   });

   const navigateToLoginPage = () =>
      navigate(LOGIN, { replace: true, state: { successPasswordReset: true } });

   return (
      <div className={styles.mainContainer}>
         <h1>Ustaw nowe hasło</h1>
         {resetPasswordError && (
            <Banner
               children={resetPasswordError}
               variant="error"
               fullWidth
               className={styles.banner}
            />
         )}
         <form noValidate onSubmit={submitHandler}>
            <StyledTextInput
               name="newPassword"
               value={values.newPassword}
               type="password"
               onChange={changeHandler}
               label="Nowe hasło"
               error={touched.newPassword && errorsList.newPassword}
               helperText={touched.newPassword && errorsList.newPassword}
               className={styles.input}
            />
            <StyledTextInput
               name="repeatedPassword"
               value={values.repeatedPassword}
               type="password"
               onChange={changeHandler}
               label="Powtórz hasło"
               error={touched.repeatedPassword && errorsList.repeatedPassword}
               helperText={touched.repeatedPassword && errorsList.repeatedPassword}
               className={styles.input}
            />
            <StyledButton
               type="submit"
               text="Zmień hasło"
               variant="filled-primary"
               className={styles.button}
               loading={loading}
            />
         </form>
      </div>
   );
};

export default ResetPasswordForm;
