import { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import classNames from 'classnames';
import { ChevronDown } from 'tabler-icons-react';

import { useAuth } from 'context/auth/AuthContextProvider';
import { Avatar } from 'storybook';

import styles from './UserDropdown.module.css';

type UserDropdownProps = {
   onLogoutError?: (error: unknown) => void;
};

const UserDropdown = ({ onLogoutError }: UserDropdownProps) => {
   const [open, setOpen] = useState(false);
   const { user, logout } = useAuth();

   const handleLogoutClick = () => {
      setOpen(false);
      handleLogout();
   };

   const handleLogout = () => {
      logout().catch(onLogoutError);
   };

   return (
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
         <div className={styles.dropdownContainer}>
            <div
               onClick={() => {
                  setOpen(true);
               }}
               className={classNames(styles.dropdownInnerContainer, {
                  [styles.innerContainerActive]: open,
               })}
            >
               <Avatar
                  text={user?.companyName.charAt(0).toUpperCase() || ''}
                  backgroundColor="var(--dark-green)"
                  textColor="white"
               />
               <p className={styles.desktopOnly}>{user?.companyName}</p>
               <ChevronDown className={styles.desktopOnly} />
            </div>
            <div
               onClick={handleLogoutClick}
               className={classNames(styles.expandedContainer, { [styles.active]: open })}
            >
               <p>Wyloguj się</p>
            </div>
         </div>
      </OutsideClickHandler>
   );
};
export default UserDropdown;
