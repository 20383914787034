import { ORDER_STATUSES } from 'constants/orderStatuses';

export const ORDER_STATUS_BANNER_INFO: Record<string, string> = {
   [ORDER_STATUSES.WAITING]:
      'Twoje zamówienie czeka na akceptację. Powiadomimy Cię, gdy zostanie ono zaakceptowane.',
   [ORDER_STATUSES.IN_PROGRESS]:
      'Twoje zamówienie zostało zaakceptowane i czeka na dostarczenie palet we wskazanym terminie.',
   [ORDER_STATUSES.DURING_BILLING]:
      'Trwa rozliczanie Twojego zamówienia. Poinformujemy Cię, jeśli pojawi się nowa faktura do opłacenia.',
   [ORDER_STATUSES.TO_PAY]:
      'Faktura została wysłana e-mailem. Proszę opłać ją, a wtedy zamówienie zostanie zamknięte. ',
};
