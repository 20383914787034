import DistributionCenterPalletTableItem from 'components/DistributionCenterPalletTableItem/DistributionCenterPalletTableItem';
import { StyledTable } from 'components/shared';
import { distributionCenterPalletsTransferTableHeaders } from 'constants/tableHeaders';
import { PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR } from 'constants/validationErrors';
import { PalletType, TransferPalletBalance } from 'interfaces';
import { Banner, TableCell, TableRow } from 'storybook';

import styles from './DistributionCenterTable.module.css';

type DistributionCenterTableProps = {
   mode: string;
   rows: {
      palletName: PalletType;
      invalidAvailablePallets: string;
      validAvailablePallets: string;
   }[];
   errors: Record<string, string | boolean>[];
   touched: Record<string, boolean>[];
   initialValues?: TransferPalletBalance[];
   handleChangeValue: (rowIndex: number, field: string, value: string) => void;
   isVisibleErrorBanner: boolean;
};

const DistributionCenterTable = ({
   mode,
   errors,
   touched,
   initialValues,
   rows,
   handleChangeValue,
   isVisibleErrorBanner,
}: DistributionCenterTableProps) => {
   const transferPalletsColumnsSum = () => {
      const arrayOfSumValidInvalidTotal = [];
      const validPalletsSum = rows.reduce((accumulator, currentValue) => {
         return accumulator + Number(currentValue.validAvailablePallets);
      }, 0);
      const invalidPalletsSum = rows.reduce((accumulator, currentValue) => {
         return accumulator + Number(currentValue.invalidAvailablePallets);
      }, 0);
      const totalSum = +invalidPalletsSum + +validPalletsSum;
      arrayOfSumValidInvalidTotal.push(validPalletsSum, invalidPalletsSum, totalSum);
      return arrayOfSumValidInvalidTotal;
   };

   return (
      <>
         <StyledTable
            className={styles.table}
            columnHeaders={distributionCenterPalletsTransferTableHeaders}
         >
            {rows.map((item, index) => (
               <DistributionCenterPalletTableItem
                  name={item.palletName}
                  handleChangeValue={handleChangeValue}
                  mode={mode}
                  rowIndex={index}
                  error={errors[index]}
                  touched={touched[index]}
                  key={index}
                  availablePallets={{
                     invalidAvailablePallets: item.invalidAvailablePallets || '',
                     validAvailablePallets: item.validAvailablePallets || '',
                  }}
                  initialAvailablePallets={initialValues?.[index]}
               />
            ))}
            <TableRow className={styles.summaryRow}>
               <TableCell className={styles.summaryFirstCell}>Łącznie</TableCell>
               {transferPalletsColumnsSum().map((sumElement, index) => (
                  <TableCell className={styles.summaryCell} key={index}>
                     {sumElement}
                  </TableCell>
               ))}
            </TableRow>
         </StyledTable>
         {isVisibleErrorBanner && (
            <Banner
               style={{ marginTop: 24 }}
               variant="error"
               children={PALLETS_AMOUNT_LESS_OR_EQUAL_ZERO_ERROR}
               fullWidth
            />
         )}
      </>
   );
};

export default DistributionCenterTable;
