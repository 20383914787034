import { useResponsiveLayout } from 'hooks';

import InternalOrderIdInfo, {
   InternalOrderIdInfoProps,
} from './InternalOrderIdInfo/InternalOrderIdInfo';

type OrderAdditionalInfoProps = Pick<InternalOrderIdInfoProps, 'onInternalOrderIdChangeClick'> & {
   createdDate: string;
   internalOrderId: string | null;
};

const OrderAdditionalInfo = ({
   createdDate,
   internalOrderId,
   onInternalOrderIdChangeClick,
}: OrderAdditionalInfoProps) => {
   const { isMobile } = useResponsiveLayout();

   const createdDateText = `Data złożenia zamówienia: ${new Date(createdDate).toLocaleDateString(
      'en-GB',
   )}`;

   if (isMobile) {
      return (
         <>
            <InternalOrderIdInfo
               internalOrderId={internalOrderId}
               onInternalOrderIdChangeClick={onInternalOrderIdChangeClick}
            />
            <p>{createdDateText}</p>
         </>
      );
   }

   return (
      <p>
         <InternalOrderIdInfo
            internalOrderId={internalOrderId}
            onInternalOrderIdChangeClick={onInternalOrderIdChangeClick}
         />
         {` | ${createdDateText}`}
      </p>
   );
};

export default OrderAdditionalInfo;
