import classNames from 'classnames';
import { AlertTriangle, Calendar } from 'tabler-icons-react';

import { costTypeLabels } from 'constants/costTypeLabels';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { Charge, SendPalletDTO } from 'interfaces';
import { getDayLabel, getValueLabel } from 'utils/functions';

import styles from './MobileCard.module.css';

type MobileCardProps = {
   data: SendPalletDTO | Charge;
   includesDamagedPallets?: boolean;
};

const MobileCard = ({ data, includesDamagedPallets }: MobileCardProps) => {
   const chargeMode = 'chargeType' in data;
   return (
      <div className={styles.container}>
         {chargeMode ? (
            <>
               <div className={styles.cardRow}>
                  <span>Typ palety</span>
                  <p>{data.palletType ? PALLET_TYPE_LABELS[data.palletType] : '-'}</p>
               </div>
               <div className={styles.cardRow}>
                  <span>Rodzaj obciążenia</span>
                  <p>{data.otherChargeName || costTypeLabels[data.chargeType]}</p>
               </div>
               <div className={styles.cardRow}>
                  <span>Okres przetrzymania</span>
                  <p>
                     {data.retentionPeriodDays
                        ? `${data.retentionPeriodDays} ${getDayLabel(data.retentionPeriodDays)}`
                        : '-'}
                  </p>
               </div>
               <div className={styles.cardRow}>
                  <span>Ilość palet (szt.)</span>
                  <p>{data.palletsAmount || '-'}</p>
               </div>
            </>
         ) : (
            <>
               <div className={classNames(styles.cardRow, styles.topRow)}>
                  <span>
                     <span>Data nadania</span>
                     <p className={styles.calendarRow}>
                        <Calendar />
                        {data.dateOfShipment}
                     </p>
                  </span>
                  {includesDamagedPallets && <AlertTriangle color={'var(--primary-red)'} />}
               </div>
               <div className={styles.cardRow}>
                  <span>Numer zamówienia sieci</span>
                  <p>{getValueLabel(data.externalCommercialNetworkNumber)}</p>
               </div>
               <div className={styles.cardRow}>
                  <span>Miejsce nadania</span>
                  <p>{data.displayName}</p>
               </div>
               <div className={styles.cardRow}>
                  <span>Adres nadania</span>
                  <p>{data.displayAddress}</p>
               </div>
               {data.pallets.map((pallet, index) => (
                  <div key={index} className={styles.palletRow}>
                     <div>
                        <span>Typ palety</span>
                        <p>{PALLET_TYPE_LABELS[pallet.palletType]}</p>
                     </div>
                     <div>
                        <span>Ilość palet</span>
                        <p>{pallet.amount}</p>
                     </div>
                  </div>
               ))}
            </>
         )}
      </div>
   );
};
export default MobileCard;
