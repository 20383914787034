import { StyledTable } from 'components/shared';
import {
   supplierPalletsPickUpsTableHeadersBase,
   supplierPalletsPickUpsTableHeadersWithActionsAndTags,
} from 'constants/tableHeaders';
import { PalletPickUpAdminDTO, PickUpStatus } from 'interfaces';

import SupplierPalletsPickUpsTableRow, {
   SupplierPalletsPickUpsTableRowProps,
} from './SupplierPalletsPickUpsTableRow/SupplierPalletsPickUpsTableRow';
import styles from './SupplierPalletsPickUpsTable.module.css';

type SupplierPalletsPickUpsTableProps = Pick<
   SupplierPalletsPickUpsTableRowProps,
   'onPalletsPickUpOpenModalClick'
> & {
   palletPickUpsStatus: PickUpStatus;
   distributionCenterPalletsPickUpsData: PalletPickUpAdminDTO[];
};

const SupplierPalletsPickUpsTable = ({
   palletPickUpsStatus,
   distributionCenterPalletsPickUpsData,
   onPalletsPickUpOpenModalClick,
}: SupplierPalletsPickUpsTableProps) => {
   const distributionCenterPalletsPickUpsTableRows = distributionCenterPalletsPickUpsData.map(
      distributionCenterPalletsPickUpData => (
         <SupplierPalletsPickUpsTableRow
            key={distributionCenterPalletsPickUpData.id}
            distributionCenterPalletsPickUpData={distributionCenterPalletsPickUpData}
            onPalletsPickUpOpenModalClick={onPalletsPickUpOpenModalClick}
         />
      ),
   );

   return (
      <StyledTable
         columnHeaders={
            palletPickUpsStatus === PickUpStatus.CREATED
               ? supplierPalletsPickUpsTableHeadersWithActionsAndTags
               : supplierPalletsPickUpsTableHeadersBase
         }
         headerClassName={styles.tableHeader}
      >
         {distributionCenterPalletsPickUpsTableRows}
      </StyledTable>
   );
};

export default SupplierPalletsPickUpsTable;
