import {
   CLIENT_ORDERS,
   DISTRIBUTION_CENTER_PALLET_PICKUPS,
   LOGIN,
   STORAGE_PALLET_BALANCE,
   SUPPLIER_ORDERS_ALL_STATUSES_TAB,
} from 'constants/routes';
import { UserRole } from 'interfaces';

const pageByRoleMapping: Record<UserRole, string> = {
   ROLE_SYSTEM_ADMIN: SUPPLIER_ORDERS_ALL_STATUSES_TAB,
   ROLE_CLIENT: CLIENT_ORDERS,
   ROLE_DISTRIBUTION_CENTER: DISTRIBUTION_CENTER_PALLET_PICKUPS,
   ROLE_STORAGE: STORAGE_PALLET_BALANCE,
};

export const getDefaultUserPageByRole = (userRole: UserRole): string => {
   return pageByRoleMapping[userRole] || LOGIN;
};
