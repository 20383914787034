import React from 'react';

import classNames from 'classnames';

import { Badge } from '../Badge/Badge';

import styles from './Tab.module.css';

export interface TabProps {
   className?: string;
   text: string;
   active?: boolean;
   onClick?: () => void;
   infoBadgeProps?: InfoBadgeProps;
}

interface InfoBadgeProps {
   text: string;
   className?: string;
}

export const Tab = ({ className, text, active, onClick, infoBadgeProps }: TabProps) => {
   return (
      <div
         onClick={onClick}
         className={classNames(styles.tab, className, { [styles.active]: active })}
      >
         <span className={styles.tabText}>{text}</span>
         {infoBadgeProps && (
            <Badge
               text={infoBadgeProps.text}
               className={classNames(styles.infoBadge, infoBadgeProps.className)}
            />
         )}
      </div>
   );
};
