import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { CloseIcon, GoToIcon } from 'assets';
import CancelPalletReportModal from 'components/CancelPalletReportModal/CancelPalletReportModal';
import { ActionsDropdown, StyledBadge, StyledButton, WhiteCard } from 'components/shared';
import { LostDamagedPalletsInDeliverySection } from 'components/SupplierPanel';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { REPORT_STATUSES, REPORT_STATUSES_AND_LABELS } from 'constants/reportStatuses';
import { REPORT_TYPES } from 'constants/reportTypes';
import {
   SUPPLIER_CLIENT_DETAILS,
   SUPPLIER_ORDERS_DETAILS,
   SUPPLIER_PLACE_ORDER,
} from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { BannerData, PalletReportDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';
import { formatISODate, getFullAddress } from 'utils/functions';

import styles from './PalletsDetailsPage.module.css';

const PalletsDetailsPage = () => {
   const [palletReport, setPalletReport] = useState<PalletReportDetailsDTO | null>(null);
   const [banner, setBanner] = useState<BannerData | null>(null);
   const [openPalletReportCancel, setOpenPalletReportCancel] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(true);
   const { id } = useParams();
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      if (id) {
         HTTPService.getLostOrDamagedPalletsReportDetails(id)
            .then(({ data }) => {
               setPalletReport(data);
            })
            .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA))
            .finally(() => setLoading(false));
      }
   }, [id]);

   useEffect(() => {
      if (location.state?.successPlaceOrder) {
         setBanner({
            variant: 'success',
            description:
               'Bezpłatne zamówienie dodane. Status zgłoszenia został zmieniony na "rozpatrzone".',
         });
      }
   }, [location.state?.successPlaceOrder]);

   const handleCancelPalletReport = () => {
      if (id && palletReport) {
         setLoading(true);
         HTTPService.changeReportStatus(id, REPORT_STATUSES.CANCELED)
            .then(() => {
               setBanner({ variant: 'error', description: 'Anulowano zgłoszenie.' });
               setPalletReport({ ...palletReport, status: REPORT_STATUSES.CANCELED });
            })
            .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION))
            .finally(() => {
               setOpenPalletReportCancel(false);
               setLoading(false);
            });
      }
   };

   const handleError = (error: unknown, errorDescription: string) =>
      errorHandler(error, () =>
         setBanner({
            variant: 'error',
            description: errorDescription,
         }),
      );

   return (
      <>
         {openPalletReportCancel && (
            <CancelPalletReportModal
               opened
               onClose={() => setOpenPalletReportCancel(false)}
               loading={loading}
               onAcceptClick={handleCancelPalletReport}
            />
         )}
         {palletReport ? (
            <div className={styles.mainContainer}>
               <div className={styles.headerContainer}>
                  <div>
                     <Link
                        to={SUPPLIER_ORDERS_DETAILS(palletReport.orderId)}
                        className={styles.header}
                     >
                        <h2
                           className={styles.headerTitle}
                        >{`Szczegóły zgłoszenia do zam. nr ${palletReport.orderId}`}</h2>
                        <GoToIcon className={styles.goToIcon} />
                     </Link>
                     <div className={styles.subtitles}>
                        <StyledBadge
                           variant={palletReport.status!}
                           text={REPORT_STATUSES_AND_LABELS[palletReport.status!].label}
                        />
                        <p className={styles.date}>
                           Data zgłoszenia: {`${formatISODate(palletReport.createdDate)}`}
                        </p>
                     </div>
                  </div>
                  {palletReport.status === REPORT_STATUSES.REPORTED && (
                     <StyledButton
                        variant="outlined-primary"
                        text="Dodaj bezpłatne zamówienie"
                        onClick={() =>
                           navigate(SUPPLIER_PLACE_ORDER, {
                              state: { palletReportId: palletReport.id },
                           })
                        }
                     />
                  )}
                  {palletReport.status !== REPORT_STATUSES.CANCELED && (
                     <div className={styles.downDropContainer}>
                        <ActionsDropdown
                           options={[
                              {
                                 text: 'Anuluj zgłoszenie',
                                 icon: <CloseIcon />,
                                 onClick: () => setOpenPalletReportCancel(true),
                              },
                           ]}
                        />
                     </div>
                  )}
               </div>
               {banner && (
                  <Banner
                     withCloseIcon
                     fullWidth
                     variant={banner.variant}
                     children={banner.description}
                     onClose={() => setBanner(null)}
                  />
               )}
               <div className={styles.mainContent}>
                  <WhiteCard className={styles.container}>
                     <h4 className={styles.sectionHeader}>
                        {palletReport.assignedUser.companyName}
                     </h4>
                     <Link
                        to={SUPPLIER_CLIENT_DETAILS(palletReport.assignedUser.id)}
                        className={styles.addressLink}
                     >
                        <p>
                           {getFullAddress(
                              palletReport.assignedUser.address,
                              palletReport.assignedUser.zipCode,
                              palletReport.assignedUser.city,
                           )}
                        </p>
                        <GoToIcon />
                     </Link>
                  </WhiteCard>
                  {palletReport.damagedPallets.some(
                     item => item.reportType === REPORT_TYPES.DAMAGE,
                  ) && (
                     <LostDamagedPalletsInDeliverySection
                        className={styles.container}
                        lostDamagedPalletsData={palletReport?.damagedPallets.filter(
                           item => item.reportType === REPORT_TYPES.DAMAGE,
                        )}
                        title="Palety uszkodzone w dostawie (do wymiany)"
                        mode={'PALLET_REPORT_DETAILS'}
                     />
                  )}
                  {palletReport.damagedPallets.some(
                     item => item.reportType === REPORT_TYPES.LOST_OR_DESTRUCTION,
                  ) && (
                     <LostDamagedPalletsInDeliverySection
                        className={styles.container}
                        lostDamagedPalletsData={palletReport?.damagedPallets.filter(
                           item => item.reportType === REPORT_TYPES.LOST_OR_DESTRUCTION,
                        )}
                        title="Palety zniszczone / zgubione w dostawie (do wymiany)"
                        mode={'PALLET_REPORT_DETAILS'}
                     />
                  )}
               </div>
            </div>
         ) : null}
      </>
   );
};

export default WithAuth(PalletsDetailsPage, 'ROLE_SYSTEM_ADMIN');
