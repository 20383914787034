import { Option, StorageDTO } from 'interfaces';
import { getFullAddress } from 'utils/functions';

export const parseStorageDTOIntoOption: (storageDTO: StorageDTO) => Option = storageDTO => {
   const { id, address } = storageDTO;
   const { street, zipCode, city } = address;
   const addressLabel = getFullAddress(street, zipCode, city);

   return {
      value: id,
      label: addressLabel,
   };
};
