import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { currentEnvName } from './currentEnvironment';

type FirebaseConfig = {
   [key: string]: {
      apiKey: string;
      authDomain: string;
      projectId: string;
      storageBucket: string;
      messagingSenderId: string;
      appId: string;
      measurementId: string;
   };
};

const firebaseConfig: FirebaseConfig = {
   dev: {
      apiKey: 'AIzaSyBew52fB-4kaWwBwmwfQXVCQKnmfRV7kD4',
      authDomain: 'ndhp-dev.firebaseapp.com',
      projectId: 'ndhp-dev',
      storageBucket: 'ndhp-dev.appspot.com',
      messagingSenderId: '772447672153',
      appId: '1:772447672153:web:f3e24b50cccfe8c084de48',
      measurementId: 'G-MXT0JPNLQ3',
   },
   uat: {
      apiKey: 'AIzaSyAGshCLY_gW2IipBjfmncqlG8681UbsdZI',
      authDomain: 'ndhp-uat.firebaseapp.com',
      projectId: 'ndhp-uat',
      storageBucket: 'ndhp-uat.appspot.com',
      messagingSenderId: '676159540789',
      appId: '1:676159540789:web:8a50b97e96bded83eeab7c',
      measurementId: 'G-LXRBWG55S2',
   },
   prod: {
      apiKey: 'AIzaSyBJ_uDa-EJf1XEmhvBeOXjZstf8ZFog6uM',
      authDomain: 'ndhp-prod.firebaseapp.com',
      projectId: 'ndhp-prod',
      storageBucket: 'ndhp-prod.appspot.com',
      messagingSenderId: '574758193012',
      appId: '1:574758193012:web:a5ae9081d5d1724426ce21',
      measurementId: 'G-6RLF3CPQQG',
   },
};

initializeApp(firebaseConfig[currentEnvName]);
export const auth = getAuth();
