import { ReactNode } from 'react';

import styles from './FiltersGroupContainer.module.css';

type FiltersGroupContainerProps = {
   children: ReactNode;
};

const FiltersGroupContainer = ({ children }: FiltersGroupContainerProps) => (
   <div className={styles.container}>{children}</div>
);

export default FiltersGroupContainer;
