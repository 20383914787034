import classNames from 'classnames';

import { CloseIcon } from 'assets';

import styles from './ImagePreview.module.css';

type ImagePreview = {
   imageURL: string;
   className?: string;
   onDelete?: () => void;
   onClick?: () => void;
};

const ImagePreview = ({ imageURL, className, onDelete, onClick }: ImagePreview) => (
   <div className={classNames(styles.container, className)}>
      <img
         src={imageURL}
         onClick={onClick}
         className={classNames(styles.image, { [styles.clickable]: onClick })}
      />
      {onDelete && (
         <span className={styles.closeIcon} onClick={onDelete}>
            <CloseIcon />
         </span>
      )}
   </div>
);

export default ImagePreview;
