import React, { useCallback } from 'react';

import { FileUpload } from 'tabler-icons-react';

import { CustomDropzone } from 'components/shared';
import { palletsToSendFileInputAcceptTypes } from 'constants/fileInputAcceptTypes';
import { SendPalletsItem } from 'interfaces';
import {
   handleTarczynskiClientSendPalletsDataFromExcel,
   handleVglClientSendPalletsDataFromExcel,
   TarczynskiClientExcelRow,
   VGLClientExcelRow,
} from 'service/excel';
import { uploadExcelFile } from 'utils/functions';

import styles from './PalletsToSendFileDropzone.module.css';

type PalletsToSendFileDropzoneProps = {
   isVGLCompany: boolean;
   onStartUpload: () => void;
   onFinishUpload: () => void;
   onSuccessUpload: (sendPalletsItems: SendPalletsItem[]) => void;
   onErrorUpload: (error: unknown) => void;
};

const PalletsToSendFileDropzone = ({
   isVGLCompany,
   onStartUpload,
   onFinishUpload,
   onSuccessUpload,
   onErrorUpload,
}: PalletsToSendFileDropzoneProps) => {
   const handleUploadExcelFile = useCallback(
      async (file: File) => {
         try {
            if (isVGLCompany) {
               const uploadedData = await uploadExcelFile<VGLClientExcelRow>(file);
               onSuccessUpload(handleVglClientSendPalletsDataFromExcel(uploadedData));
               return;
            }
            // for other clients is used Tarczynski excel format
            const uploadedData = await uploadExcelFile<TarczynskiClientExcelRow>(file);
            onSuccessUpload(handleTarczynskiClientSendPalletsDataFromExcel(uploadedData));
         } catch (error) {
            onErrorUpload(error);
         } finally {
            onFinishUpload();
         }
      },
      [isVGLCompany, onErrorUpload, onFinishUpload, onSuccessUpload],
   );

   const onDrop = useCallback(
      (acceptedFiles: File[]) => {
         onStartUpload();
         const file = acceptedFiles[0];
         handleUploadExcelFile(file);
      },
      [handleUploadExcelFile, onStartUpload],
   );

   return (
      <CustomDropzone
         accept={palletsToSendFileInputAcceptTypes}
         maxFiles={1}
         multiple={false}
         noClick={true}
         onDrop={onDrop}
         inputProps={{
            text: 'Wybierz plik',
            className: styles.excelUploadBtn,
            icon: <FileUpload />,
         }}
         className={styles.dropzone}
      >
         <p className={styles.description}>
            lub upuść pliki tutaj, aby rozdysponować automatycznie
            <br />
            <strong>Uwaga!</strong> Wypełnione pola zostaną nadpisane
         </p>
      </CustomDropzone>
   );
};

export default PalletsToSendFileDropzone;
