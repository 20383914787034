import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';

import { useDropdown } from 'hooks/useDropdown';
import { Option, OptionGroup } from 'interfaces';
import { Button } from 'storybook';
import { ChevronDownIcon, XIcon } from 'storybook/assets';

import styles from './Autocomplete.module.css';

export interface AutocompleteProps {
   value?: Option;
   placeholder?: string;
   label?: string;
   options: Option[] | OptionGroup[];
   onSelect: (value: Option) => void;
   onlyTextMatch?: boolean;
   helperText?: string;
   error?: boolean;
   disabled?: boolean;
   dropdownButtonProps?: DropdownButtonProps;
   width?: string;
   fullWidth?: boolean;
   isTooltipVisible?: boolean;
   classNames?: {
      container?: string;
      optionsContainer?: string;
   };
   portalTarget?: HTMLElement;
   onClear?: () => void;
}

interface DropdownButtonProps {
   onClick?: (enteredValue: string) => void;
   icon?: ReactNode;
   text?: string;
   displayEnteredText?: boolean;
   disableOnTextMatch?: boolean;
   fullWidth?: boolean;
   className?: string;
}

export const Autocomplete = ({
   value,
   placeholder,
   label,
   options,
   onSelect,
   onlyTextMatch,
   helperText,
   error,
   disabled,
   dropdownButtonProps,
   width,
   fullWidth,
   isTooltipVisible,
   classNames: autocompleteClassNames,
   portalTarget,
   onClear,
}: AutocompleteProps) => {
   const [enteredText, setEnteredText] = useState('');
   const [selecting, setSelecting] = useState(false);

   const handleCloseSelect = useCallback(() => {
      setEnteredText('');
      setSelecting(false);
   }, []);

   const {
      expanded,
      close,
      open,
      toggleRef: autocompleteRef,
      dropdownContentRefFunc,
      dropdownContentPosition,
      dropdownContentMinWidth,
   } = useDropdown({
      portalTarget,
      onClose: handleCloseSelect,
   });

   const handleEnterText = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelecting(true);
      setEnteredText(e.target.value);
   };

   const handleSuggestionClick = (suggestion: Option) => {
      onSelect(suggestion);
      close();
   };

   const handleClearClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      event.stopPropagation();
      onClear?.();
   };

   const handleAutocompleteExtend = () => {
      if (disabled) return;
      open();
   };

   const handleClickDropdownButton = () => {
      dropdownButtonProps?.onClick?.(enteredText);
      setEnteredText('');
   };

   const autocompleteOptions = useMemo(() => {
      if (options.length === 0) {
         return [];
      }
      if ('value' in options[0]) {
         return options as Option[];
      }
      return (options as OptionGroup[]).reduce<Option[]>((allOptions, currentOptionGroup) => {
         const { options: currentGroupOptions, groupName } = currentOptionGroup;
         const optionsWithDescriptions = currentGroupOptions.map(option => ({
            ...option,
            description: groupName,
         }));
         return allOptions.concat(optionsWithDescriptions);
      }, []);
   }, [options]);

   const suggestions = useMemo(() => {
      let filteredOptions;
      if (onlyTextMatch) {
         filteredOptions = autocompleteOptions.filter(option =>
            option.label
               .toLowerCase()
               .replaceAll(' ', '')
               .includes(enteredText.toLowerCase().replaceAll(' ', '')),
         );
      } else {
         filteredOptions = autocompleteOptions.filter(option =>
            option.label.toLowerCase().trim().includes(enteredText.toLowerCase().trim()),
         );
      }
      return filteredOptions;
   }, [enteredText, autocompleteOptions, onlyTextMatch]);

   const suggestionsContainer = (
      <div
         ref={dropdownContentRefFunc}
         style={{
            width: width,
            minWidth: dropdownContentMinWidth,
            ...dropdownContentPosition,
         }}
         className={classNames(
            styles.suggestionsContainer,
            autocompleteClassNames?.optionsContainer,
            {
               [styles.suggestionsContainerExpanded]: expanded,
               [styles.containerWithLabel]: label,
               [styles.withPortal]: portalTarget,
            },
         )}
      >
         {!!suggestions.length ? (
            suggestions.map((suggestion, index) => (
               <div
                  key={index}
                  className={classNames(styles.suggestion, {
                     [styles.selected]: suggestion.value === value?.value,
                  })}
                  onClick={() => handleSuggestionClick(suggestion)}
               >
                  {suggestion.description && (
                     <span className={styles.description}>{suggestion.description}</span>
                  )}
                  <span>{suggestion.label}</span>
               </div>
            ))
         ) : (
            <div className={styles.noSuggestionsMessage}>
               <span>Brak opcji</span>
            </div>
         )}
         {dropdownButtonProps && (
            <div className={styles.dropdownBtn}>
               <Button
                  type="button"
                  onClick={handleClickDropdownButton}
                  text={
                     dropdownButtonProps.displayEnteredText
                        ? `${dropdownButtonProps.text} „${enteredText}”`
                        : dropdownButtonProps.text
                  }
                  icon={dropdownButtonProps.icon}
                  disabled={
                     !enteredText ||
                     (dropdownButtonProps.disableOnTextMatch &&
                        suggestions.some(
                           suggestion =>
                              suggestion.label.toLowerCase().replaceAll(' ', '') ===
                              enteredText.toLowerCase().replaceAll(' ', ''),
                        ))
                  }
                  fullWidth={dropdownButtonProps.fullWidth}
                  className={dropdownButtonProps.className}
               />
            </div>
         )}
      </div>
   );

   const inputTitle = isTooltipVisible && value?.label ? value.label : '';
   const isValueSelected = !!value?.label;
   const isClearIconVisible = onClear && isValueSelected;

   return (
      <div
         className={classNames(styles.autocompleteContainer, autocompleteClassNames?.container, {
            [styles.fullWidth]: fullWidth,
         })}
      >
         {label && <p className={styles.autocompleteLabel}>{label}</p>}
         <div
            ref={autocompleteRef}
            style={{ width: width }}
            onClick={handleAutocompleteExtend}
            className={classNames(styles.autocomplete, {
               [styles.autocompleteError]: error,
               [styles.disabled]: disabled,
               [styles.autocompleteExpanded]: expanded,
            })}
         >
            <input
               type="text"
               onChange={handleEnterText}
               value={selecting ? enteredText : value?.label || ''}
               disabled={disabled}
               placeholder={placeholder}
               title={inputTitle}
            />
            <span
               className={classNames(styles.chevronDownIconContainer, {
                  [styles.withClearIcon]: isClearIconVisible,
               })}
               onClick={isClearIconVisible ? handleClearClick : handleAutocompleteExtend}
            >
               <ChevronDownIcon className={styles.chevronDownIcon} />
               <XIcon className={styles.clearIcon} />
            </span>
         </div>
         {expanded &&
            (portalTarget
               ? createPortal(suggestionsContainer, portalTarget)
               : suggestionsContainer)}
         {helperText && (
            <p
               className={classNames(styles.helperText, {
                  [styles.helperTextError]: error,
               })}
            >
               {helperText}
            </p>
         )}
      </div>
   );
};
