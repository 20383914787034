import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';

type DropdownProps = {
   portalTarget?: HTMLElement;
   onClose?: () => void;
};

const DROPDOWN_CONTENT_MARGIN = 2;

export const useDropdown = ({ portalTarget, onClose }: DropdownProps) => {
   const [expanded, setExpanded] = useState(false);
   const toggleRef = useRef<HTMLDivElement>(null);
   const [dropdownContentElement, setDropdownContentElement] = useState<HTMLDivElement | null>(
      null,
   );
   const [dropdownContentDimensions, setDropdownContentDimensions] = useState<DOMRect | null>(null);

   const toggleDimensions = toggleRef.current?.getBoundingClientRect();

   const dropdownContentPosition = useMemo(() => {
      if (expanded) {
         const position: CSSProperties = {};
         if (!dropdownContentDimensions || !toggleDimensions) {
            return position;
         }
         const windowHeight = window.innerHeight;
         const dropdownContentWidth = dropdownContentDimensions?.width || 0;
         const dropdownContentHeight = dropdownContentDimensions?.height || 0;
         const dropdownBottomPosition = toggleDimensions.bottom + dropdownContentHeight;
         const dropdownOpenTop = dropdownBottomPosition >= windowHeight;
         if (portalTarget) {
            position.top = dropdownOpenTop
               ? toggleDimensions.top - dropdownContentHeight - DROPDOWN_CONTENT_MARGIN
               : toggleDimensions.bottom + DROPDOWN_CONTENT_MARGIN;
            position.left = toggleDimensions.right - dropdownContentWidth;
         } else if (dropdownOpenTop) {
            position.top = -dropdownContentHeight - DROPDOWN_CONTENT_MARGIN;
         }
         return position;
      }
   }, [dropdownContentDimensions, portalTarget, expanded, toggleDimensions]);

   const close = useCallback(() => {
      onClose?.();
      setExpanded(false);
   }, [onClose]);

   const handleClick = useCallback(
      (event: MouseEvent) => {
         if (!dropdownContentElement?.contains(event.target as Node | null) && expanded) {
            close();
         }
      },
      [expanded, close, dropdownContentElement],
   );

   const handleScrollResizePage = useCallback(
      (event: Event) => {
         if (expanded && event.target !== dropdownContentElement) {
            close();
         }
      },
      [expanded, dropdownContentElement, close],
   );

   useEffect(() => {
      document.addEventListener('click', handleClick, true);
      return () => document.removeEventListener('click', handleClick, true);
   }, [handleClick]);

   useEffect(() => {
      document.addEventListener('scroll', handleScrollResizePage, true);
      window.addEventListener('resize', handleScrollResizePage, true);
      return () => {
         document.removeEventListener('scroll', handleScrollResizePage, true);
         window.removeEventListener('resize', handleScrollResizePage, true);
      };
   }, [handleScrollResizePage]);

   const dropdownContentRefFunc = useCallback((element: HTMLDivElement) => {
      if (element !== null) {
         setDropdownContentElement(element);
         setDropdownContentDimensions(element.getBoundingClientRect());
      }
   }, []);

   const open = () => setExpanded(true);

   return {
      expanded,
      close,
      open,
      toggleRef,
      dropdownContentRefFunc,
      dropdownContentPosition,
      dropdownContentMinWidth: toggleDimensions?.width,
   };
};
