import React, { ReactNode } from 'react';
import { DropzoneInputProps } from 'react-dropzone';

import styles from './DropzoneInput.module.css';

type CustomDropzoneInputProps = DropzoneInputProps & DropzoneInputAdditionalProps;

export type DropzoneInputAdditionalProps = {
   text?: string;
   className?: string;
   innerRef?: string;
   icon?: ReactNode;
};

const DropzoneInput = ({
   text,
   className,
   innerRef,
   icon,
   onClick,
   ...props
}: CustomDropzoneInputProps) => (
   <label className={className}>
      <div onClick={onClick} className={styles.container}>
         <input {...props} ref={innerRef} />
         <div>
            {icon && <span>{icon}</span>}
            {text && <span>{text}</span>}
         </div>
      </div>
   </label>
);

export default DropzoneInput;
