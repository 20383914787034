import { DetailsLink } from 'components/shared';
import { palletTypes } from 'constants/palletTypes';
import { SUPPLIER_CLIENT_PALLETS_BALANCE_DETAILS_PATH } from 'constants/routes';
import { ClientWithBalanceDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './ClientPalletBalanceTableRow.module.css';

type ClientPalletBalanceTableRowProps = {
   clientPalletBalance: ClientWithBalanceDTO;
};

export const ClientPalletBalanceTableRow = ({
   clientPalletBalance,
}: ClientPalletBalanceTableRowProps) => {
   const { id: clientId, companyName, totalSum, rentedPalletBalances } = clientPalletBalance;

   const renderRentedPalletBalanceCells = () =>
      palletTypes.map(palletType => {
         const rentedPalletBalance = rentedPalletBalances.find(
            rentedPalletBalancesItem => rentedPalletBalancesItem.palletType === palletType,
         );
         return (
            <TableCell key={palletType} align="right">
               {rentedPalletBalance ? rentedPalletBalance.amount : '-'}
            </TableCell>
         );
      });

   return (
      <TableRow className={styles.tableRow}>
         <TableCell>{companyName}</TableCell>
         <TableCell align="right" className={styles.totalSum}>
            {totalSum}
         </TableCell>
         {renderRentedPalletBalanceCells()}
         <TableCell align="right">
            <DetailsLink to={SUPPLIER_CLIENT_PALLETS_BALANCE_DETAILS_PATH(clientId)} />
         </TableCell>
      </TableRow>
   );
};

export default ClientPalletBalanceTableRow;
