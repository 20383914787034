import { FileOff } from 'tabler-icons-react';

import { UploadPDFButton } from 'components/shared';

import NoItemsNotification from '../../NoItemsNotification';

import styles from './NoDocumentsNotification.module.css';

type NoDocumentsNotification = {
   supplierMode?: boolean;
   onUploadDocument: (document: FileList) => void;
   uploadBtnId?: string;
};

const NoDocumentsNotification = ({
   supplierMode,
   onUploadDocument,
   uploadBtnId,
}: NoDocumentsNotification) => (
   <NoItemsNotification
      icon={<FileOff color=" var(--gray-section-subtitle)" size={48} />}
      description={supplierMode ? 'Brak dokumentów.' : 'Nie dodałeś jeszcze żadnych dokumentów.'}
      button={
         <UploadPDFButton
            id={uploadBtnId}
            onUploadDocument={onUploadDocument}
            variant="outlined-primary"
         />
      }
      className={styles.container}
   />
);

export default NoDocumentsNotification;
