import { useMemo } from 'react';

import { Tabs } from 'components/shared';
import { supplierOrdersPageTabs } from 'constants/tabs';
import { SupplierOrdersPageTab } from 'interfaces';

import styles from './OrdersTabs.module.css';

type OrdersTabsProps = {
   activeTabName: SupplierOrdersPageTab;
   unreadOrdersCounts: { [key: string]: number };
   className?: string;
   onTabClick: (tabName: SupplierOrdersPageTab) => void;
};

const tabsWithUnreadOrdersCountInfo: SupplierOrdersPageTab[] = ['WAITING', 'DURING_BILLING'];

const OrdersTabs = ({
   activeTabName,
   unreadOrdersCounts,
   className,
   onTabClick,
}: OrdersTabsProps) => {
   const transformedTabs = useMemo(
      () =>
         supplierOrdersPageTabs.map(supplierOrdersPageTab => {
            const isUnreadOrdersCountsTabVisible =
               tabsWithUnreadOrdersCountInfo.includes(supplierOrdersPageTab.value) &&
               unreadOrdersCounts[supplierOrdersPageTab.value];
            return {
               label: supplierOrdersPageTab.label,
               value: supplierOrdersPageTab.value,
               infoBadgeProps: isUnreadOrdersCountsTabVisible
                  ? {
                       text: String(unreadOrdersCounts[supplierOrdersPageTab.value]),
                       className: styles.tabInfoBadge,
                    }
                  : undefined,
            };
         }),
      [unreadOrdersCounts],
   );

   return (
      <Tabs
         tabs={transformedTabs}
         activeTabName={activeTabName}
         className={className}
         onTabClick={onTabClick}
      />
   );
};

export default OrdersTabs;
