import { ChangeEvent, useState } from 'react';

import { CustomModal, CustomModalProps, StyledTextInput } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';

type EditInternalOrderIdModalProps = Pick<CustomModalProps, 'onClose'> & {
   orderId: string;
   internalOrderId: string | null;
   onEditInternalOrderIdSuccess: (updatedOrderDetailsDTO: OrderDetailsDTO) => void;
};

const MODAL_WIDTH = 550;

const EditInternalOrderIdModal = ({
   orderId,
   internalOrderId,
   onEditInternalOrderIdSuccess,
   onClose,
}: EditInternalOrderIdModalProps) => {
   const [enteredInternalOrderId, setEnteredInternalOrderId] = useState(internalOrderId ?? '');

   const {
      isLoading,
      errorInfo,
      setIsLoading,
      setErrorInfo,
      showCloseWarning,
      handleShowCloseWarning,
      handleHideCloseWarning,
      closeModalHandler,
   } = useModal({
      onClose,
   });

   const handleEditInternalOrderIdError = (error: unknown) =>
      errorHandler(error, () => setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN));

   const handleEditInternalOrderId = () => {
      setIsLoading(true);
      HTTPService.editInternalOrderId(orderId, {
         internalOrderId: enteredInternalOrderId ? enteredInternalOrderId : undefined,
      })
         .then(editInternalOrderIdResponse => {
            closeModalHandler();
            onEditInternalOrderIdSuccess(editInternalOrderIdResponse.data);
         })
         .catch(handleEditInternalOrderIdError)
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         opened
         onClose={handleShowCloseWarning}
         title="Edytuj numer wewnętrzny zamówienia"
         primaryButtonProps={{
            text: 'Zmień numer',
            onClick: handleEditInternalOrderId,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: handleShowCloseWarning,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         isLoading={isLoading}
         error={errorInfo}
         size={MODAL_WIDTH}
      >
         <StyledTextInput
            type="text"
            value={enteredInternalOrderId}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
               setEnteredInternalOrderId(event.currentTarget.value)
            }
            fullWidth
         />
      </CustomModal>
   );
};

export default EditInternalOrderIdModal;
