import React from 'react';

import styles from './Avatar.module.css';

export interface AvatarProps {
   className?: string;
   backgroundColor?: string;
   textColor?: string;
   text: string;
}

export const Avatar = ({ backgroundColor, textColor, text }: AvatarProps) => {
   return (
      <div className={styles.avatar} style={{ backgroundColor: backgroundColor, color: textColor }}>
         {text}
      </div>
   );
};
