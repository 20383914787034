import { ALL_STATUSES_TAB_NAME } from './ordersPageTabNames';

export const LOGIN = '/';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';

export const CLIENT_ORDERS = '/client/orders';
export const CLIENT_PALLETS = '/client/pallets';
export const CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT = '/client/pallets/lost-destroyed-by-client';
export const CLIENT_DAMAGED_PALLETS_BY_CLIENT = '/client/pallets/damaged-by-client';
export const CLIENT_DESTROYED_PALLETS_BY_SUPPLIER = '/client/pallets/damaged-destroyed-by-supplier';
export const CLIENT_PLACE_ORDER = '/client/orders/place-order';
export const CLIENT_ORDER_SUCCESS = '/client/orders/place-order/successful-order';
export const CLIENT_ORDER_DETAILS_PATH = (id: string) => `/client/orders/${id}`;
export const CLIENT_PALLETS_TRANSFER_HISTORY = '/client/pallets-transfer-history';

export const SUPPLIER_ORDERS = '/supplier/orders';
export const SUPPLIER_ORDERS_ALL_STATUSES_TAB = `/supplier/orders?status=${ALL_STATUSES_TAB_NAME}`;
export const SUPPLIER_ORDERS_DETAILS = (id: string) => `/supplier/orders/${id}`;
export const SUPPLIER_CLIENTS = '/supplier/clients';
export const SUPPLIER_PALLETS = '/supplier/pallets';
export const SUPPLIER_PLACE_ORDER = '/supplier/orders/place-order';
export const SUPPLIER_ADD_CLIENT = '/supplier/clients/add-client';
export const SUPPLIER_EDIT_CLIENT = `/supplier/clients/edit-client`;
export const SUPPLIER_PALLETS_TRANSFER_HISTORY = '/supplier/pallets-transfer-history';

export const SUPPLIER_CLIENT_PALLETS_BALANCE_DETAILS_PATH = (clientId: string) =>
   `/supplier/storages/clients/${clientId}`;
export const SUPPLIER_LOST_DESTROYED_BY_CLIENT_PALLETS_PATH = (clientId: string) =>
   `/supplier/storages/clients/${clientId}/lost-destroyed-by-client`;
export const SUPPLIER_DAMAGED_BY_CLIENT_PALLETS_PATH = (clientId: string) =>
   `/supplier/storages/clients/${clientId}/damaged-by-client`;
export const SUPPLIER_DAMAGED_DESTROYED_BY_SUPPLIER_PALLETS_PATH = (clientId: string) =>
   `/supplier/storages/clients/${clientId}/damaged-destroyed-by-supplier`;

export const SUPPLIER_PALLETS_DETAILS = (id: string) => `/supplier/pallets/${id}`;

export const SUPPLIER_DISTRIBUTION_CENTER_DETAILS = '/supplier/distribution-center/';
export const SUPPLIER_DISTRIBUTION_CENTER_DETAILS_PATH = (id: string) =>
   `/supplier/distribution-center/${id}`;

export const SUPPLIER_EDIT_CLIENT_PATH = (id: string) => `/supplier/clients/edit-client/${id}`;
export const SUPPLIER_CLIENT_DETAILS_PATH = (id: string) => `/supplier/clients/${id}`;
export const SUPPLIER_PALLETS_PICK_UPS = `/supplier/pallets-pick-ups`;
export const SUPPLIER_STORAGES = `/supplier/storages/`;
export const SUPPLIER_INTERNAL_STORAGES = `/supplier/storages/internal-storages`;
export const SUPPLIER_EXTERNAL_STORAGES = `/supplier/storages/external-storages`;
export const SUPPLIER_COMMERCIAL_NETWORKS = `/supplier/storages/commercial-networks`;
export const SUPPLIER_CLIENT_PALLET_BALANCES = `/supplier/storages/clients`;
export const SUPPLIER_STORAGES_BY_NAME_PATH = (storageName: string) =>
   `/supplier/storages/${storageName}`;

export const SUPPLIER_CLIENT_DETAILS = (id: string) => `/supplier/clients/${id}`;

export const DISTRIBUTION_CENTER_PALLET_PICKUPS = '/distribution-center/pallet-pick-ups';
export const DISTRIBUTION_CENTER_PALLET_BALANCE = '/distribution-center/pallet-balance';
export const DISTRIBUTION_CENTER_ORDER_DETAILS_PATH = (orderId: string) =>
   `/distribution-center/pallet-balance/${orderId}`;
export const DISTRIBUTION_CENTER_PALLETS_TRANSFER_HISTORY =
   '/distribution-center/pallets-transfer-history';

export const STORAGE_PALLET_BALANCE = '/storage/pallet-balance';
export const STORAGE_PALLETS_TRANSFER_HISTORY = '/storage/pallets-transfer-history';
