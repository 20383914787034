import classNames from 'classnames';

import { CustomLoader } from '..';

import styles from './PageLoader.module.css';

type PageLoaderProps = {
   className?: string;
};

const PageLoader = ({ className }: PageLoaderProps) => (
   <div className={classNames(styles.container, className)}>
      <CustomLoader />
   </div>
);

export default PageLoader;
