import React, { useMemo, useState } from 'react';

import { DatePicker } from '@mantine/dates';
import classNames from 'classnames';
import { DateTime } from 'luxon';

import { OutlinedCalendarIcon } from 'assets';
import DistributionCenterTable from 'components/DistributionCenterTable/DistributionCenterTable';
import { CustomModal, CustomTimeInput } from 'components/shared';
import { DATE_PICKER_DATE_FORMAT } from 'constants/dateFormats';
import { SELECTED_TIME_HAS_ALREADY_PASSES_ERROR } from 'constants/validationErrors';
import { useTableForm } from 'hooks';
import { useModal } from 'hooks/useModal';
import { BalancePickUpDTO, TransferPalletBalance } from 'interfaces';
import { Banner } from 'storybook';
import {
   checkIfDefinedSomePalletsToPickUp,
   getDefaultTransferPalletsValues,
   joinDateAndTime,
} from 'utils/functions';
import { transferPalletsValidations } from 'utils/validation/transferPalletsDistributionCenter';

import styles from './CreatePalletPickUpModal.module.css';

type CreatePalletPickUpModal = {
   showErrorBanner: boolean;
   isLoading: boolean;
   onClose: () => void;
   onSubmit: (palletBalances: BalancePickUpDTO[], dateTime: DateTime) => void;
};

const CreatePalletPickUpModal = ({
   showErrorBanner,
   isLoading,
   onClose,
   onSubmit,
}: CreatePalletPickUpModal) => {
   const [palletPickUpDate, setPalletPickUpDate] = useState<Date>(new Date());
   const [palletPickUpTime, setPalletPickUpTime] = useState<Date>(new Date());
   const [dateTimeError, setDateTimeError] = useState(false);

   const submitNewPalletPickUp = (values: TransferPalletBalance[]) => {
      const mappedValues = values.map(transferPallet => {
         const valid = Number(transferPallet.validAvailablePallets);
         const invalid = Number(transferPallet.invalidAvailablePallets);
         return {
            palletType: transferPallet.palletName,
            balance: {
               valid: valid,
               invalid: invalid,
               sum: valid + invalid,
            },
         };
      });
      const dateTime = joinDateAndTime(palletPickUpDate, palletPickUpTime);
      validateDateTime(dateTime);

      if (!isInvalidPalletsData && !dateTimeError) {
         onSubmit(mappedValues, dateTime);
      }
   };

   const {
      rows,
      errors,
      touched,
      handleChangeValue,
      handleSubmitForm,
      hasErrors: isInvalidPalletsData,
      commonErrorsOfTouchedInputs: commonErrorsOfPalletsDataTouchedInputs,
   } = useTableForm<TransferPalletBalance>({
      initialState: getDefaultTransferPalletsValues(),
      onSubmit: submitNewPalletPickUp,
      validations: transferPalletsValidations,
   });

   const { showCloseWarning, handleShowCloseWarning, handleHideCloseWarning, closeModalHandler } =
      useModal({ onClose });

   const validateDateTime = (dateTime: DateTime) => {
      setDateTimeError(dateTime < DateTime.now());
   };

   const areDefinedSomePalletsToPickUp = useMemo(
      () => checkIfDefinedSomePalletsToPickUp(rows),
      [rows],
   );

   const isInvalidForm = isInvalidPalletsData || !areDefinedSomePalletsToPickUp || dateTimeError;

   return (
      <CustomModal
         size={798}
         onClose={handleShowCloseWarning}
         opened
         primaryButtonProps={{ text: 'Zgłoś odbiór', disabled: isInvalidForm }}
         secondaryButtonProps={{
            text: 'Anuluj',
            onClick: handleShowCloseWarning,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         title="Zgłoś odbiór palet"
         isLoading={isLoading}
         onSubmit={handleSubmitForm}
         modalContentClassName={styles.modalContent}
      >
         {showErrorBanner && (
            <Banner variant="error" fullWidth className={styles.banner}>
               Wystąpił błąd. Odśwież stronę i spróbuj ponownie.
            </Banner>
         )}
         <div className={styles.inputRow}>
            <DatePicker
               locale="pl"
               value={palletPickUpDate}
               inputFormat={DATE_PICKER_DATE_FORMAT}
               icon={<OutlinedCalendarIcon />}
               className={styles.datePicker}
               classNames={{
                  input: classNames({
                     [styles.errorInput]: dateTimeError,
                  }),
               }}
               clearable={false}
               onChange={value => {
                  setPalletPickUpDate(value!);
                  validateDateTime(joinDateAndTime(value!, palletPickUpTime));
               }}
               label="Wybierz dzień odbioru palet"
               minDate={new Date()}
            />
            <CustomTimeInput
               value={palletPickUpTime}
               onChange={value => {
                  setPalletPickUpTime(value);
                  validateDateTime(joinDateAndTime(palletPickUpDate, value!));
               }}
               label="Wpisz godzinę"
               className={styles.timePicker}
               helperText={dateTimeError ? SELECTED_TIME_HAS_ALREADY_PASSES_ERROR : undefined}
               error={dateTimeError}
            />
         </div>
         <div className={styles.modalContent}>
            <DistributionCenterTable
               mode="transfer"
               rows={rows}
               errors={errors}
               touched={touched}
               handleChangeValue={handleChangeValue}
               isVisibleErrorBanner={!!commonErrorsOfPalletsDataTouchedInputs.size}
            />
         </div>
      </CustomModal>
   );
};

export default CreatePalletPickUpModal;
