import { useEffect } from 'react';

import { DamagedLostPalletsLayout } from 'components/layout';
import { DamagedLostPalletsListSection } from 'components/Pallets';
import WithAuth from 'hoc/withAuth';
import { useDamagedLostPalletsPage } from 'hooks';
import { HTTPService } from 'service';
import { palletReportDTOMappersToPalletReportMapper } from 'service/http/mappers';

const DamagedPalletsBySupplierPage = () => {
   const { palletReports, isLoading, handleError, setIsLoading, setPalletReports } =
      useDamagedLostPalletsPage();

   useEffect(() => {
      setIsLoading(true);
      HTTPService.getUserPallets(true)
         .then(({ data: responseData }) => {
            const mappedPalletReports = responseData.reports.map(
               palletReportDTOMappersToPalletReportMapper,
            );
            setPalletReports(mappedPalletReports);
         })
         .catch(handleError)
         .finally(() => setIsLoading(false));
   }, [setIsLoading, setPalletReports, handleError]);

   return (
      <DamagedLostPalletsLayout>
         <DamagedLostPalletsListSection
            palletReports={palletReports}
            title="Palety uszkodzone w dostawie (do wymiany)"
            isDamagedLostBySupplier
            isLoading={isLoading}
         />
      </DamagedLostPalletsLayout>
   );
};

export default WithAuth(DamagedPalletsBySupplierPage, 'ROLE_CLIENT');
