import { SectionContainer, SharedGridTable } from 'components/shared';
import { BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX } from 'constants/basePalletsPricingPerAddressDataIndex';
import {
   commonPalletsPricingTableHeaders,
   palletsPricingPerAddressTableHeaders,
} from 'constants/tableHeaders';
import { ClientDeliveryAddress, CommonRatePerPalletConfig } from 'interfaces';

import { CommonPalletsPricingTableRow } from './CommonPalletsPricingTableRow/CommonPalletsPricingTableRow';
import { PalletsPricingPerAddressesTableRow } from './PalletsPricingPerAddressesTableRow/PalletsPricingPerAddressesTableRow';
import styles from './PalletsPricing.module.css';

type PalletsPricingProps = {
   commonPalletsPricing: CommonRatePerPalletConfig[];
   palletsPricingPerAddresses: ClientDeliveryAddress[];
};

const PalletsPricing = ({
   commonPalletsPricing,
   palletsPricingPerAddresses,
}: PalletsPricingProps) => (
   <SectionContainer innerSection title="Cennik">
      <SharedGridTable
         headers={commonPalletsPricingTableHeaders}
         style={{ paddingLeft: 12, marginBottom: 32 }}
         rows={commonPalletsPricing.map((commonPalletsPricingItem, index) => (
            <CommonPalletsPricingTableRow
               key={index}
               commonPalletsPricingItem={commonPalletsPricingItem}
            />
         ))}
      />
      <SharedGridTable
         headers={palletsPricingPerAddressTableHeaders}
         style={{ paddingLeft: 12 }}
         className={{ headerRow: styles.palletsPricingPerAddressesTableHeader }}
         rows={palletsPricingPerAddresses.map((palletsPricingPerAddress, index) => (
            <PalletsPricingPerAddressesTableRow
               isBasePalletsPricingPerAddressRow={
                  index === BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX
               }
               palletsPricingPerAddress={palletsPricingPerAddress}
               basePalletsPricingPerAddressData={
                  palletsPricingPerAddresses[BASE_PALLETS_PRICING_PER_ADDRESS_DATA_INDEX]
               }
            />
         ))}
      />
   </SectionContainer>
);

export default PalletsPricing;
