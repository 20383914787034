import PalletsTransferHistoryActionBody, {
   PalletsTransferHistoryActionBodyProps,
} from '../../PalletsTransferHistoryActionBody/PalletsTransferHistoryActionBody';
import PalletsTransferHistoryActionHeader, {
   PalletsTransferHistoryActionHeaderProps,
} from '../../PalletsTransferHistoryActionHeader/PalletsTransferHistoryActionHeader';

import styles from './PalletsTransferHistoryListItem.module.css';

export type PalletsTransferHistoryListItemProps = PalletsTransferHistoryActionHeaderProps &
   Pick<
      PalletsTransferHistoryActionBodyProps,
      'transferActionItemsData' | 'onMoreTransferActionItemsDataClick'
   >;

const PalletsTransferHistoryListItem = ({
   type,
   orderId,
   createdBy,
   createdDate,
   transferActionItemsData,
   onMoreTransferActionItemsDataClick,
}: PalletsTransferHistoryListItemProps) => (
   <li className={styles.listItem}>
      <PalletsTransferHistoryActionHeader
         type={type}
         createdBy={createdBy}
         orderId={orderId}
         createdDate={createdDate}
      />
      <PalletsTransferHistoryActionBody
         actionType={type}
         transferActionItemsData={transferActionItemsData}
         onMoreTransferActionItemsDataClick={onMoreTransferActionItemsDataClick}
      />
   </li>
);

export default PalletsTransferHistoryListItem;
