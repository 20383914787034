import {
   DISTRIBUTION_CENTER_ALREADY_EXISTS,
   EMAIL_ALREADY_EXISTS,
   ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED,
   ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED,
} from './errorCodes';

export const DEFAULT_ERROR_DESCRIPTIONS = {
   UNKNOWN: 'Wystąpił błąd. Odśwież stronę i spróbuj ponownie.',
   FETCH_DATA: 'Podczas pobierania danych wystąpił błąd. Odśwież stronę.',
   COMPLETE_ACTION: 'Podczas dokonania akcji wystąpił błąd. Odśwież stronę i spróbuj ponownie.',
   COMPLETE_ACTION_SHORT: 'Podczas dokonania akcji wystąpił błąd.',
};

export const ADD_ADDITIONAL_CHARGES_ERROR_DESCRIPTIONS: { [key: string]: string } = {
   [ORDER_ADDITIONAL_CHARGE_AMOUNT_EXCEEDED]: 'Została przekroczona liczba palet w zamówieniu.',
   [ORDER_ADDITIONAL_CHARGE_DAMAGED_AND_LOST_AMOUNT_EXCEEDED]:
      'Została przekroczona liczba całych palet w zamówieniu.',
   OTHER: 'Podczas dodania obciążenia wystąpił błąd.',
};

export const ADD_OR_EDIT_CLIENT_ERROR_DESCRIPTIONS: Record<string, string> = {
   [EMAIL_ALREADY_EXISTS]: 'Klient z podanym adresem e-mail już istnieje.',
};

export const ADD_STORAGE_POINT_ERROR_DESCRIPTIONS: Record<string, string> = {
   [EMAIL_ALREADY_EXISTS]: 'Centrum Dystrybucyjne z podanym adresem e-mail już istnieje.',
};

export const ADD_OR_EDIT_DISTRIBUTION_CENTER_ERROR_DESCRIPTIONS: Record<string, string> = {
   [DISTRIBUTION_CENTER_ALREADY_EXISTS]: 'Centrum Dystrybucyjne z podanym adresem już istnieje.',
   [EMAIL_ALREADY_EXISTS]: 'Centrum Dystrybucyjne z podanym adresem e-mail już istnieje.',
};

export const CREATE_AND_SEND_INVOICE_ERROR_DESCRIPTION =
   'Podczas wystawienia faktury wystąpił błąd. Odśwież stronę i spróbój ponownie.';
