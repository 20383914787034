import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './NavItem.module.css';

export interface NavItemProps {
   className?: string;
   icon: ReactNode;
   text: string;
   active?: boolean;
   activeIcon?: ReactNode;
}

export const NavItem = ({ className, icon, active, text, activeIcon }: NavItemProps) => {
   return (
      <div
         className={classNames(styles.navItem, className, {
            [styles.active]: active,
         })}
      >
         {active && activeIcon}
         {(!active || (active && !activeIcon)) && icon}
         {text}
      </div>
   );
};
