import classNames from 'classnames';

import { LogoImage } from 'assets';

import styles from './Logo.module.css';

type LogoProps = {
   className?: string;
};

const Logo = ({ className }: LogoProps) => (
   <img src={LogoImage} className={classNames(styles.logo, className)} alt="Logo" />
);

export default Logo;
