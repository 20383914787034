import { Loader, Modal, ModalProps } from '@mantine/core';

import styles from './FileUploadingModal.module.css';

const FileUploadingModal = (props: ModalProps) => {
   return (
      <Modal {...props} title="Dodawanie pliku" size={600} withCloseButton={false} centered>
         <div className={styles.container}>
            <span className={styles.loader}>
               <Loader color={'var(--light-green)'} size={38} />
            </span>
            <p>Trwa dodawanie pliku. Prosimy o cierpliwość.</p>
         </div>
      </Modal>
   );
};

export default FileUploadingModal;
