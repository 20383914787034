import { PalletAmount } from 'interfaces';

export const countOrdersTotalPalletsAmount = <T extends { pallets: PalletAmount[] }>(
   orders: (T | undefined)[],
) =>
   orders.reduce((totalPalletsAmount, currentOrder) => {
      if (!currentOrder) {
         return totalPalletsAmount;
      }
      const orderPalletsAmount = currentOrder.pallets.reduce(
         (totalOrderPalletsAmount, currentPalletItem) =>
            totalOrderPalletsAmount + currentPalletItem.amount,
         0,
      );
      return totalPalletsAmount + orderPalletsAmount;
   }, 0);
