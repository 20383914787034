import classNames from 'classnames';

import { errorHandler } from 'utils/errorHandler';
import { uploadImage } from 'utils/functions/uploadFiles';

import styles from './ImageUploadInput.module.css';

type ImageUploadInputProps = {
   imageNumberLimit: number;
   imageURLs: string[];
   images: string[];
   onChange: (files64: string[], imageURLs: string[]) => void;
   className?: string;
};

const ImageUploadInput = ({
   imageNumberLimit,
   imageURLs,
   images,
   onChange,
   className,
}: ImageUploadInputProps) => {
   const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files.length <= imageNumberLimit - images.length) {
         const previewURLS: string[] = [];
         /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
         const filePromises: any = [];
         /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
         // @ts-ignore
         [...files].forEach(file => {
            previewURLS.push(URL.createObjectURL(file));
            filePromises.push(uploadImage(file));
         });

         Promise.all(filePromises)
            .then(base64Files =>
               onChange([...images, ...base64Files], [...imageURLs, ...previewURLS]),
            )
            .catch(errorHandler);
      }
   };

   return (
      <label>
         <div onChange={handleSelectFile} className={classNames(styles.container, className)}>
            <input type="file" accept=".png, .jpg, .jpeg" multiple />
            <span>+ dodaj zdjęcia</span>
         </div>
      </label>
   );
};

export default ImageUploadInput;
