import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './TableRow.module.css';

export interface TableRowProps extends React.HTMLProps<HTMLTableRowElement> {
   children: ReactNode;
   className?: string;
   active?: boolean;
}

export const TableRow = ({ children, className, ...props }: TableRowProps) => {
   return (
      <tr
         {...props}
         className={classNames(styles.tableRow, className, {
            [styles.tableRowActive]: props.active,
         })}
      >
         {children}
      </tr>
   );
};
