import { sumBy } from 'lodash';

import { Pallet, PalletWithSpecification } from 'interfaces';

export const mergeRegularPalletsWithFreePallets = (
   regularPallets: PalletWithSpecification[],
   freePallets: Pallet[],
) =>
   regularPallets.map(regularPallet => {
      const filteredFreePallets = freePallets.filter(
         freePallet => freePallet.palletType === regularPallet.palletType,
      );
      const freePalletsAmount = sumBy(filteredFreePallets, (pallet: Pallet) => pallet.amount);
      return { ...regularPallet, freePalletsAmount };
   });
