import { palletTypesData } from 'constants/palletTypes';
import { PalletSpecification } from 'interfaces';

export const getPalletsFullData = <T>(
   pallets: T[],
   findPredicate: (pallet: T) => (palletType: PalletSpecification) => boolean,
) =>
   pallets.map((pallet: T) => {
      const palletConfig = palletTypesData.find(findPredicate(pallet));
      return { ...pallet, ...palletConfig! };
   });
