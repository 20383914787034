import { Modal, ModalProps } from '@mantine/core';

import { StyledButton } from 'components/shared';

import styles from './CannotDeactivateClientModal.module.css';

const CannotDeactivateClientModal = (props: ModalProps) => {
   return (
      <Modal size={600} title="Nie można dezaktywować klienta" centered {...props}>
         <div className={styles.modalContent}>
            <p>Klient posiada otwarte zamówienia.</p>
            <p>
               Aby dezaktywować klienta, wszystkie jego zamówienia muszą być zakończone lub
               anulowane.
            </p>
            <StyledButton onClick={props.onClose} variant="outlined-danger" text="Wróć" />
         </div>
      </Modal>
   );
};
export default CannotDeactivateClientModal;
