import classNames from 'classnames';

import { SelfPickupGreenIcon, SelfPickupIcon } from 'assets';
import { SectionContainer, StyledSelect } from 'components/shared';
import { STORAGE_TYPE_LABELS_SINGULAR } from 'constants/storageType';
import { Option, SelectValue, StorageGroupOption, StorageOption } from 'interfaces';
import { Banner, Card } from 'storybook';

import styles from './EditStorageSection.module.css';

type EditStorageSectionProps = {
   selectedStorage?: Option;
   availableStorages: StorageGroupOption[];
   onSelectChange: (selectValue: SelectValue | StorageOption | string, field: string) => void;
   disabled?: boolean;
   touched?: boolean;
   onSave?: () => void;
   onCancel?: () => void;
   isLoading?: boolean;
   error?: string;
};

const EditStorageSection = ({
   selectedStorage,
   availableStorages,
   onSelectChange,
   disabled,
   error,
   touched,
   onSave,
   onCancel,
   isLoading,
}: EditStorageSectionProps) => (
   <SectionContainer
      title="Dostarczone z magazynu"
      onSave={onSave}
      onCancel={onCancel}
      isLoading={isLoading}
   >
      {error && touched && (
         <Banner fullWidth variant="error" children={error} className={styles.banner} />
      )}
      <Card
         title="Magazyn"
         displayCheckedIcon
         icon={selectedStorage && !disabled ? <SelfPickupGreenIcon /> : <SelfPickupIcon />}
         checked={selectedStorage && !disabled}
         bottomContent={
            <div className={styles.cardContent}>
               <p className={styles.cardText}>
                  Wybierz z którego magazynu palety zostaną wysłane do klienta (pamiętaj, że palety
                  zostaną odjęte z salda wybranego magazynu).
               </p>
               <StyledSelect
                  value={selectedStorage && !disabled ? selectedStorage : undefined}
                  onChange={(value: StorageOption) => onSelectChange(value, 'storage')}
                  groups={availableStorages}
                  {...(selectedStorage
                     ? {
                          transformSelectedOptionLabel: (value: StorageOption) =>
                             `${value.label} (${
                                STORAGE_TYPE_LABELS_SINGULAR[value.value.storageType]
                             })`,
                       }
                     : {})}
                  disabled={disabled}
                  fullWidth
                  classNames={{ select: styles.addressSelect }}
                  portalTarget={document.body}
               />
            </div>
         }
         className={classNames(styles.card, { [styles.cardDisabled]: disabled })}
      />
   </SectionContainer>
);

export default EditStorageSection;
