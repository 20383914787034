import { ReactNode } from 'react';

import classNames from 'classnames';

import { CurvedArrowDownIcon } from '../../../assets';

import styles from './NoItemsNotification.module.css';

type NoItemsNotificationProps = {
   icon: ReactNode;
   description: string;
   button?: ReactNode;
   withMobileBtnArrow?: boolean;
   desktopSmallNoItemsNotification?: boolean;
   className?: string;
};

const NoItemsNotification = ({
   icon,
   description,
   button,
   withMobileBtnArrow,
   desktopSmallNoItemsNotification,
   className,
}: NoItemsNotificationProps) => (
   <div
      className={classNames(
         styles.mainContainer,
         { [styles.small]: desktopSmallNoItemsNotification },
         className,
      )}
   >
      <div className={styles.iconContainer}>{icon}</div>
      <p className={classNames({ [styles.withoutMargin]: !button })}>{description}</p>
      {button}
      {withMobileBtnArrow && <CurvedArrowDownIcon className={styles.arrowDown} />}
   </div>
);

export default NoItemsNotification;
