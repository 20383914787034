import { ReactNode } from 'react';

import { LoadingOverlay } from '@mantine/core';
import classNames from 'classnames';

import SaveCancelButtonsContainer from '../SaveCancelButtonsContainer/SaveCancelButtonsContainer';

import styles from './SectionContainer.module.css';

type SectionContainerProps = {
   title?: string;
   subtitle?: string;
   children: ReactNode;
   headerRightSideElement?: ReactNode;
   onSave?: () => void;
   onCancel?: () => void;
   isLoading?: boolean;
   className?: string;
   innerSection?: boolean;
   style?: React.CSSProperties;
   divider?: boolean;
};

const SectionContainer = ({
   title,
   subtitle,
   children,
   headerRightSideElement,
   onSave,
   onCancel,
   isLoading,
   className,
   innerSection,
   style,
   divider,
}: SectionContainerProps) => (
   <div
      style={style}
      className={classNames(styles.container, className, { [styles.innerContainer]: innerSection })}
   >
      <div className={styles.header}>
         <div>
            <h3
               className={classNames(styles.containerTitle, {
                  [styles.innerContainerTitle]: innerSection,
                  [styles.withSubtitle]: !!subtitle,
               })}
            >
               {title}
            </h3>
            {!!subtitle && <p className={styles.subtitle}>{subtitle}</p>}
         </div>
         {headerRightSideElement}
      </div>
      {onSave && onCancel && (
         <SaveCancelButtonsContainer
            onSave={onSave}
            onCancel={onCancel}
            className={classNames(styles.saveCancelBtnsContainer, { [styles.divider]: divider })}
         />
      )}
      {children}
      <LoadingOverlay
         visible={!!isLoading}
         overlayBlur={10}
         radius="md"
         zIndex="1"
         loaderProps={{ color: 'var(--light-green)' }}
      />
   </div>
);

export default SectionContainer;
