import { ChangeEvent } from 'react';

import { FileExport, Filter, Search } from 'tabler-icons-react';

import { PageHeader } from 'components/layout';
import { StyledButton, StyledTextInput } from 'components/shared';

import styles from './PalletsTransferHistoryHeader.module.css';

type PalletsTransferHistoryHeaderProps = {
   orderIdFilter: string;
   additionalFiltersCount: number;
   disabled: boolean;
   isTransferActionsDataInXLSXFormatLoading: boolean;
   onOrderIdFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
   onFiltersButtonClick: () => void;
   onClearFiltersClick: () => void;
   onExportClick: () => void;
};

export const PalletsTransferHistoryHeader = ({
   orderIdFilter,
   additionalFiltersCount,
   disabled,
   isTransferActionsDataInXLSXFormatLoading,
   onOrderIdFilterChange,
   onFiltersButtonClick,
   onClearFiltersClick,
   onExportClick,
}: PalletsTransferHistoryHeaderProps) => {
   const leftPart = (
      <div className={styles.leftPartHeader}>
         <StyledTextInput
            type="text"
            value={orderIdFilter}
            placeholder="Wyszukaj po ID zamówienia"
            disabled={disabled}
            className={styles.searchInput}
            leftIcon={<Search size={20} color="var(--primary-text)" />}
            onChange={onOrderIdFilterChange}
         />
         <StyledButton
            variant="outlined-primary"
            text={`Filtry ${additionalFiltersCount ? additionalFiltersCount : ''}`}
            disabled={disabled}
            icon={<Filter size={20} />}
            onClick={onFiltersButtonClick}
         />
         <StyledButton
            variant="text"
            text="Wyczyść"
            className={styles.clearFiltersButton}
            disabled={disabled}
            onClick={onClearFiltersClick}
         />
      </div>
   );

   const rightPart = (
      <StyledButton
         variant="filled-primary"
         icon={<FileExport size={20} />}
         text="Export (XLSX)"
         loading={isTransferActionsDataInXLSXFormatLoading}
         disabled={disabled}
         onClick={onExportClick}
      />
   );

   return (
      <PageHeader
         headerLeftElement={leftPart}
         headerRightElement={rightPart}
         className={styles.header}
      />
   );
};

export default PalletsTransferHistoryHeader;
