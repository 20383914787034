import { Calendar } from 'tabler-icons-react';

import { SectionContainer, StyledButton } from 'components/shared';
import { getDayLabel } from 'utils/functions';

import styles from './OverviewRentalPeriodSection.module.css';

type OverviewRentalPeriodSectionProps = {
   palletDeliveryDate: string;
   holdingPeriod: string;
   retentionPeriodDays: string;
   onClickEdit?: () => void;
};

const OverviewRentalPeriodSection = ({
   palletDeliveryDate,
   holdingPeriod,
   retentionPeriodDays,
   onClickEdit,
}: OverviewRentalPeriodSectionProps) => (
   <SectionContainer title="Okres wynajmu">
      {onClickEdit && (
         <StyledButton
            style={{ marginBottom: 24 }}
            variant="outlined-primary"
            text="Edytuj termin dostarczenia"
            onClick={onClickEdit}
         />
      )}
      <div className={styles.dateContainer}>
         <div className={styles.dateInfo}>
            <p>Dzień dostarczenia palet</p>
            <div>
               <Calendar color="#3E3E4B" />
               {palletDeliveryDate}
            </div>
         </div>
         <div className={styles.dateInfo}>
            <p>Termin przetrzymania</p>
            <div>
               <Calendar color="#3E3E4B" />
               {holdingPeriod}
               {` (${retentionPeriodDays} ${getDayLabel(Number(retentionPeriodDays))})`}
            </div>
         </div>
      </div>
   </SectionContainer>
);

export default OverviewRentalPeriodSection;
