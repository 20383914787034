import { flatten, mergeWith } from 'lodash';

import { Option, SendPalletDTO } from 'interfaces';

import { getFullAddress } from '.';

export const getPalletSendingDestinationAddresses = (sendPallets: SendPalletDTO[]) => {
   const palletTypesWithDestinationAddress = sendPallets.map(sendPalletsItem => {
      const { destination } = sendPalletsItem;
      let address: Option;
      switch (destination.type) {
         case 'COMMERCIAL_NETWORK': {
            const {
               commercialNetworkName,
               address: { street, zipCode, city },
            } = destination.commercialNetwork;
            const fullAddress = getFullAddress(street, zipCode, city);
            address = {
               label: `${commercialNetworkName}, ${fullAddress}`,
               value: { name: commercialNetworkName, streetWithCity: fullAddress },
            };
            break;
         }
         case 'NEW_ADDRESS': {
            const { name, address: addressValue } = destination.newAddress;
            address = {
               label: `${name}, ${addressValue}`,
               value: { name, streetWithCity: addressValue },
            };
            break;
         }
         case 'STORAGE': {
            const { name, street, zipCode, city } = destination.storage.address;
            const fullAddress = getFullAddress(street, zipCode, city);
            address = {
               label: `${name}, ${fullAddress}`,
               value: { name, streetWithCity: fullAddress },
            };
            break;
         }
         case 'USER_ADDRESS': {
            const { name, address: addressValue, zipCode, city } = destination.userAddress;
            const fullAddress = getFullAddress(addressValue, zipCode, city);
            address = {
               label: `Adres własny, ${name}, ${fullAddress}`,
               value: { name, streetWithCity: fullAddress },
            };
            break;
         }
      }
      return sendPalletsItem.pallets.map(pallet => ({ [pallet.palletType]: address }));
   });
   const flattenPalletTypesWithDestinationAddress = flatten(palletTypesWithDestinationAddress);
   const customizer = (objValue: Option[] | undefined, srcValue: Option) => {
      if (!objValue) {
         return [srcValue];
      } else {
         return [...objValue, srcValue];
      }
   };
   const mergedPalletTypesWithDestinationAddress = flattenPalletTypesWithDestinationAddress.reduce(
      (mergedObj, currentObj) => mergeWith(mergedObj, currentObj, customizer),
      {} as { [palletType: string]: Option[] },
   );
   return mergedPalletTypesWithDestinationAddress;
};
