import { useMemo } from 'react';

import { StyledTable } from 'components/shared';
import { supplierOrdersPageTabsWithNewOrderTag } from 'constants/supplierOrdersPageTabsWithNewOrderTag';
import {
   clientOrdersTableHeaders,
   clientOrdersTableHeadersWithTags,
   supplierOrdersTableHeaders,
   supplierOrdersTableHeadersWithTags,
} from 'constants/tableHeaders';
import { ClientOrdersTableItem, SupplierOrdersPageTab, SupplierOrdersTableItem } from 'interfaces';

import OrderTableRow from './OrderTableRow/OrderTableRow';
import styles from './OrderTable.module.css';

type OrderTableProps = SupplierOrderTableProps | ClientOrderTableProps;

type SupplierOrderTableProps = CommonTableProps & {
   mode: 'SUPPLIER_TABLE';
   orders: SupplierOrdersTableItem[];
   activeTabName: SupplierOrdersPageTab;
};

type ClientOrderTableProps = CommonTableProps & {
   mode: 'CLIENT_TABLE';
   orders: ClientOrdersTableItem[];
   activeTabName?: null;
};

type CommonTableProps = { className?: string };

const TABLE_HEADERS = {
   SUPPLIER_TABLE: {
      regular: supplierOrdersTableHeaders,
      withTags: supplierOrdersTableHeadersWithTags,
   },
   CLIENT_TABLE: {
      regular: clientOrdersTableHeaders,
      withTags: clientOrdersTableHeadersWithTags,
   },
};

const OrderTable = ({ mode, orders, activeTabName, className }: OrderTableProps) => {
   const isTagColumnVisible = useMemo(() => {
      const isAnyOrderFree = orders.some(order => order.free);
      if (mode === 'SUPPLIER_TABLE') {
         const isAnyOrderUnread = orders.some(order => !order.read);
         return (
            (isAnyOrderUnread && supplierOrdersPageTabsWithNewOrderTag.includes(activeTabName)) ||
            isAnyOrderFree
         );
      }
      return isAnyOrderFree;
   }, [mode, activeTabName, orders]);

   const tableHeaders = TABLE_HEADERS[mode];

   const orderTableRows =
      mode === 'SUPPLIER_TABLE'
         ? orders.map(order => (
              <OrderTableRow
                 key={order.id}
                 mode="SUPPLIER_TABLE"
                 order={order}
                 activeTabName={activeTabName}
                 isTagColumnVisible={isTagColumnVisible}
              />
           ))
         : orders.map(order => (
              <OrderTableRow
                 key={order.id}
                 mode="CLIENT_TABLE"
                 order={order}
                 isTagColumnVisible={isTagColumnVisible}
              />
           ));

   return (
      <StyledTable
         columnHeaders={isTagColumnVisible ? tableHeaders.withTags : tableHeaders.regular}
         headerClassName={styles.tableHeader}
         className={className}
      >
         {orderTableRows}
      </StyledTable>
   );
};

export default OrderTable;
