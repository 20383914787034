import { LoadingOverlay, Modal } from '@mantine/core';
import { Trash } from 'tabler-icons-react';

import { StyledButton } from '../shared';

import styles from './DeleteStorageModal.module.css';

type DeleteStorageModalProps = {
   canBeDeleted: boolean;
   onClose: () => void;
   onRemoveClick: () => void;
   opened: boolean;
   loading: boolean;
};

const DeleteStorageModal = ({
   canBeDeleted,
   onClose,
   opened,
   loading,
   onRemoveClick,
}: DeleteStorageModalProps) => {
   const renderDescription = !canBeDeleted ? (
      <>
         <p className={styles.plainText}>
            W tym magazynie znajdują się palety. Użyj funkcji „Przerzuć palety" aby przenieść je do
            innego magazynu. W przeciwnym razie ich ilości zostaną usunięte razem z magazynem.
         </p>
         <p className={styles.plainText}>Czy jesteś pewny, że chcesz usunąć ten magazyn?</p>
      </>
   ) : (
      <p className={styles.plainText}>
         Brak palet na magazynie. Czy jesteś pewien, że chcesz go usunąć?
      </p>
   );

   return (
      <Modal
         onClose={onClose}
         opened={opened}
         centered
         classNames={{
            modal: styles.modal,
         }}
      >
         <>
            <div className={styles.modalContent}>
               <Trash
                  size={48}
                  strokeWidth={1.5}
                  color={'var(--primary-red)'}
                  style={{ marginBottom: 26 }}
               />
               <h3>Usuń magazyn</h3>
               <div className={styles.descriptionContainer}>
                  {renderDescription}
                  <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
               </div>
               <LoadingOverlay
                  visible={loading}
                  overlayBlur={10}
                  loaderProps={{ color: 'var(--light-green)' }}
               />
            </div>
            <div className={styles.btnsContainer}>
               <StyledButton text="Nie usuwaj" onClick={onClose} variant="outlined-danger" />
               <StyledButton
                  type="button"
                  text={canBeDeleted ? 'Usuń magazyn' : 'Usuń magazyn razem z paletami '}
                  onClick={onRemoveClick}
                  variant="filled-danger"
                  disabled={loading}
               />
            </div>
         </>
      </Modal>
   );
};

export default DeleteStorageModal;
