import { Fragment } from 'react';

import { Plus } from 'tabler-icons-react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import {
   LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM,
   LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM,
} from 'constants/palletsTableDefaultValues';
import {
   lostDamagedPalletsByClientTableHeaders,
   lostDamagedPalletsBySupplierTableHeaders,
} from 'constants/tableHeaders';
import { LostDamagedPalletsItem } from 'interfaces';
import { DamageType, Option } from 'interfaces/commonInterfacesAndTypes';
import { Table } from 'storybook';
import { checkIfAvailableDamageTypeForPallet } from 'utils/functions';

import LostDamagedPalletsImagesRow from './LostDamagedPalletsImagesRow/LostDamagedPalletsImagesRow';
import LostDamagedPalletsMainRow from './LostDamagedPalletsMainRow/LostDamagedPalletsMainRow';
import styles from './LostDamagedPalletsTable.module.css';

type LostDamagedPalletsTableProps = {
   damageType: DamageType;
   lostDamagedPalletsItems: LostDamagedPalletsItem[];
   lostDamagedPalletsItemsErrors: Record<string, string | boolean>[];
   lostDamagedPalletsItemsTouched: Record<string, boolean>[];
   availablePalletTypes: Option[];
   onAddLostDamagedPalletsItem: (newItem: LostDamagedPalletsItem) => void;
   onRemoveLostDamagedPalletsItem: (itemIndex: number) => void;
   onChangeLostDamagedPalletsItem: (
      itemIndex: number,
      field: string,
      value: string | string[] | Option | null,
   ) => void;
};

const NOT_FOUND_IMAGE_URL_INDEX = -1;

const LostDamagedPalletsTable = ({
   damageType,
   lostDamagedPalletsItems,
   lostDamagedPalletsItemsErrors,
   lostDamagedPalletsItemsTouched,
   availablePalletTypes,
   onAddLostDamagedPalletsItem,
   onRemoveLostDamagedPalletsItem,
   onChangeLostDamagedPalletsItem,
}: LostDamagedPalletsTableProps) => {
   const handleDeleteImage = (
      itemIndex: number,
      selectedImageURL: string,
      files64: string[],
      imageURLs: string[],
   ) => {
      const selectedImageURLIndex = imageURLs.findIndex(imageURL => imageURL === selectedImageURL);
      if (selectedImageURLIndex === NOT_FOUND_IMAGE_URL_INDEX) {
         return;
      }
      const convertedSelectedImageURLIndex = Number(selectedImageURLIndex);
      const images = [...files64];
      const imagePreviewURLs = [...imageURLs];
      images.splice(convertedSelectedImageURLIndex, 1);
      imagePreviewURLs.splice(convertedSelectedImageURLIndex, 1);
      onChangeLostDamagedPalletsItem(itemIndex, 'images', images);
      onChangeLostDamagedPalletsItem(itemIndex, 'imageURLs', imagePreviewURLs);
   };

   return (
      <>
         <Table
            columnHeaders={
               damageType === 'LOST_DAMAGED_BY_CLIENT'
                  ? lostDamagedPalletsByClientTableHeaders
                  : lostDamagedPalletsBySupplierTableHeaders
            }
            className={styles.table}
            headerClassName={styles.tableHeader}
         >
            {lostDamagedPalletsItems.map((lostDamagedPalletsItem, rowIndex) => {
               const isAvailableDamageAsDamageType = checkIfAvailableDamageTypeForPallet(
                  lostDamagedPalletsItem,
                  lostDamagedPalletsItems,
                  'DAMAGE',
               );
               const isAvailableLostOrDestructionAsDamageType = checkIfAvailableDamageTypeForPallet(
                  lostDamagedPalletsItem,
                  lostDamagedPalletsItems,
                  'LOST_OR_DESTRUCTION',
               );
               const isVisibleImagesRow =
                  lostDamagedPalletsItem.images && lostDamagedPalletsItem.imageURLs?.length;

               return (
                  <Fragment key={lostDamagedPalletsItem.uuid}>
                     <LostDamagedPalletsMainRow
                        damageType={damageType}
                        availablePalletTypes={availablePalletTypes}
                        lostDamagedPalletsItem={lostDamagedPalletsItem}
                        lostDamagedPalletsItemTouched={lostDamagedPalletsItemsTouched[rowIndex]}
                        lostDamagedPalletsItemErrors={lostDamagedPalletsItemsErrors[rowIndex]}
                        isAvailableDamageAsDamageType={isAvailableDamageAsDamageType}
                        isAvailableLostOrDestructionAsDamageType={
                           isAvailableLostOrDestructionAsDamageType
                        }
                        onChangeLostDamagedPalletsItem={(
                           field: string,
                           value: string | string[] | Option | null,
                        ) => onChangeLostDamagedPalletsItem(rowIndex, field, value)}
                        onRemoveLostDamagedPalletsItem={
                           rowIndex ? () => onRemoveLostDamagedPalletsItem(rowIndex) : undefined
                        }
                     />
                     {!!isVisibleImagesRow && (
                        <LostDamagedPalletsImagesRow
                           imageURLs={lostDamagedPalletsItem.imageURLs!}
                           onClickDeleteImage={(imageURL: string) =>
                              handleDeleteImage(
                                 rowIndex,
                                 imageURL,
                                 lostDamagedPalletsItem.images!,
                                 lostDamagedPalletsItem.imageURLs!,
                              )
                           }
                        />
                     )}
                  </Fragment>
               );
            })}
         </Table>
         <StyledButton
            type="button"
            onClick={() =>
               onAddLostDamagedPalletsItem(
                  damageType === 'LOST_DAMAGED_BY_CLIENT'
                     ? LOST_DAMAGED_PALLETS_BY_CLIENT_DEFAULT_ITEM()
                     : LOST_DAMAGED_PALLETS_BY_SUPPLIER_DEFAULT_ITEM(),
               )
            }
            text="Dodaj typ palety"
            icon={<Plus />}
            variant="text"
            className={styles.addPalletTypeBtn}
         />
      </>
   );
};

export default LostDamagedPalletsTable;
