import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';

import { SEND_PALLETS_EXCEL_ROW_FIELDS } from '../constants';
import { SendPalletsExcelRow } from '../types';
import {
   getExcelSendPalletsItemsFromSendPalletsExcelRows,
   mergeSendPalletsExcelRowsWithSamePalletType,
} from '../utils';

export const getGroupedExcelSendPalletsItems = (
   sendPalletsExcelRowsData: SendPalletsExcelRow[],
) => {
   const sendPalletsExcelRowsDataGroupedBySendDate = groupBy(
      sendPalletsExcelRowsData,
      SEND_PALLETS_EXCEL_ROW_FIELDS.SEND_DATE,
   );

   return flatten(
      Object.values(sendPalletsExcelRowsDataGroupedBySendDate).map(
         sendPalletsExcelRowsDataItemWithSameSendDate => {
            const sendPalletsExcelRowsDataGroupedByExternalCommercialNetworkNumber = groupBy(
               sendPalletsExcelRowsDataItemWithSameSendDate,
               SEND_PALLETS_EXCEL_ROW_FIELDS.EXTERNAL_COMMERCIAL_NETWORK_NUMBER,
            );
            return flatten(
               Object.values(sendPalletsExcelRowsDataGroupedByExternalCommercialNetworkNumber).map(
                  sendPalletsExcelRowsDataItemWithSameExternalCommercialNetworkNumber => {
                     const sendPalletsExcelRowsDataGroupedByCommercialNetworkAddress = groupBy(
                        sendPalletsExcelRowsDataItemWithSameExternalCommercialNetworkNumber,
                        SEND_PALLETS_EXCEL_ROW_FIELDS.COMMERCIAL_NETWORK_ADDRESS,
                     );
                     return flatten(
                        Object.values(
                           sendPalletsExcelRowsDataGroupedByCommercialNetworkAddress,
                        ).map(sendPalletsExcelRowsDataItemGroupedByCommercialNetworkAddress => {
                           const sendPalletsExcelRowsDataGroupedByPalletTypes = groupBy(
                              sendPalletsExcelRowsDataItemGroupedByCommercialNetworkAddress,
                              SEND_PALLETS_EXCEL_ROW_FIELDS.PALLET_TYPE,
                           );
                           const sendPalletsExcelRowsDataMergedByPalletTypes = Object.values(
                              sendPalletsExcelRowsDataGroupedByPalletTypes,
                           ).map(sendPalletsItemsWithSamePalletType =>
                              mergeSendPalletsExcelRowsWithSamePalletType(
                                 sendPalletsItemsWithSamePalletType,
                              ),
                           );
                           return getExcelSendPalletsItemsFromSendPalletsExcelRows(
                              sendPalletsExcelRowsDataMergedByPalletTypes,
                           );
                        }),
                     );
                  },
               ),
            );
         },
      ),
   );
};
