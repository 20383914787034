import classNames from 'classnames';

import StyledButton from 'components/shared/StyledButton/StyledButton';

import styles from './LostDamagedPalletsStepFooter.module.css';

type LostDamagedPalletsStepFooterProps = {
   onNextClick: () => void;
   onPrevClick: () => void;
   nextButtonText: string;
   className?: string;
};

const LostDamagedPalletsStepFooter = ({
   onNextClick,
   onPrevClick,
   nextButtonText,
   className,
}: LostDamagedPalletsStepFooterProps) => (
   <div className={classNames(styles.buttonsContainer, className)}>
      <StyledButton variant="text" text="Wstecz" onClick={onPrevClick} />
      <StyledButton variant="filled-primary" text={nextButtonText} onClick={onNextClick} />
   </div>
);

export default LostDamagedPalletsStepFooter;
