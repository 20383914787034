import { OptionGroup, StorageDTO } from 'interfaces';
import { sortOptions } from 'utils/functions/sortOptions';

import { parseStorageDTOIntoOption } from './parseStorageDTOIntoOption';

export const parseStorageDTOsIntoOptionGroups: (
   storageDTOs: StorageDTO[],
) => OptionGroup[] = storageDTOs => {
   const storageOptionGroups: OptionGroup[] = [];
   const externalStorages = storageDTOs.filter(storage => storage.type === 'EXTERNAL');
   const internalStorages = storageDTOs.filter(storage => storage.type === 'INTERNAL');

   if (externalStorages.length) {
      const externalStorageOptions = externalStorages.map(parseStorageDTOIntoOption);
      const sortedExternalStorageOptions = sortOptions(externalStorageOptions);
      storageOptionGroups.push({
         groupName: 'Magazyn zewnętrzny',
         options: sortedExternalStorageOptions,
      });
   }

   if (internalStorages.length) {
      const internalStorageOptions = internalStorages.map(parseStorageDTOIntoOption);
      const sortedInternalStorageOptions = sortOptions(internalStorageOptions);
      storageOptionGroups.push({
         groupName: 'Magazyn wewnętrzny',
         options: sortedInternalStorageOptions,
      });
   }

   return storageOptionGroups;
};
