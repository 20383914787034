import { StyledBadge } from 'components/shared';
import { OrderStatus, PickUpStatus } from 'interfaces';

import styles from './StatusLegendItem.module.css';

export type StatusLegendItemProps = {
   status: string;
   description: string;
   variant: OrderStatus | PickUpStatus;
};

const StatusLegendItem = ({ status, description, variant }: StatusLegendItemProps) => (
   <li className={styles.mainContainer}>
      <div className={styles.badgeContainer}>
         <StyledBadge text={status} variant={variant} />
      </div>
      <p>{description}</p>
   </li>
);

export default StatusLegendItem;
