import { ChangeEvent, FormEvent, useMemo, useState } from 'react';

import { LoadingOverlay, Modal } from '@mantine/core';
import { AxiosError } from 'axios';

import { ArrowRightIcon } from 'assets';
import {
   CloseWarningModal,
   StyledButton,
   StyledModalTitle,
   StyledSelect,
   StyledTextInput,
} from 'components/shared';
import { TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT } from 'constants/errorCodes';
import { PALETTE_STATUSES, PALETTE_STATUSES_OPTIONS } from 'constants/paletteStatuses';
import { BannerType, Option } from 'interfaces';
import { HTTPService, TransferStoragePalletsRequest } from 'service';
import { Banner } from 'storybook';
import { logNetworkError } from 'utils/logNetworkError';
import { moreThanZero } from 'utils/validation';

import styles from './MovePalletsInsideStorageModal.module.css';

type MovePalletsInsideStorageModalProps = {
   storageId: string;
   storagePaletteTypesOptions: Option[];
   internal: boolean;
   onSuccess: () => void;
   onClose: () => void;
};

const Arrow = () => (
   <div className={styles.arrow}>
      <ArrowRightIcon />
   </div>
);

const DIFFERENT_STATUSES_ERROR_MESSAGE =
   'Nie można przerzucić tych samych palet z takim samym statusem.';
const NO_ENOUGH_PALLETS_ERROR_MESSAGE = 'Nie masz tylu palet w Magazynie.';

const MovePalletsInsideStorageModal = ({
   storageId,
   storagePaletteTypesOptions,
   internal,
   onSuccess,
   onClose,
}: MovePalletsInsideStorageModalProps) => {
   const [isLoading, setIsLoading] = useState(false);
   const [bannerData, setBannerData] = useState<BannerType | null>(null);
   const [paletteType, setPaletteType] = useState<Option>();
   const [fromPaletteStatus, setFromPaletteStatus] = useState<Option>();
   const [toPaletteStatus, setToPaletteStatus] = useState<Option>();
   const [amount, setAmount] = useState('');
   const [showCloseWarningModal, setShowCloseWarningModal] = useState(false);

   const validateForm = () => {
      if (fromPaletteStatus?.value === toPaletteStatus?.value) {
         setBannerData({ variant: 'error', description: DIFFERENT_STATUSES_ERROR_MESSAGE });
         return false;
      }
      return true;
   };

   const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);
      const isValidForm = validateForm();
      if (isValidForm) {
         try {
            const transferStoragePalletsData: TransferStoragePalletsRequest = {
               palletAmount: parseInt(amount, 10),
               palletType: paletteType?.value,
               palletStatusFrom: fromPaletteStatus?.value,
               palletStatusDestination: toPaletteStatus?.value,
            };
            await HTTPService.transferPalletsInsideStorage(storageId, transferStoragePalletsData);
            onSuccess();
         } catch (error) {
            logNetworkError(error);
            if (error instanceof AxiosError) {
               const errorCode = error.response?.data.code;
               if (errorCode === TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT) {
                  setBannerData({
                     variant: 'error',
                     description: NO_ENOUGH_PALLETS_ERROR_MESSAGE,
                  });
               }
            }
         }
      }
      setIsLoading(false);
   };

   const handleModalClose = () => setShowCloseWarningModal(true);

   const handleChangePalletsAmount = (event: ChangeEvent<HTMLInputElement>) =>
      setAmount(event.target.value);

   const handleCloseWarningModalConfirm = () => {
      setShowCloseWarningModal(false);
      onClose();
   };

   const handleCloseWarningModalClose = () => setShowCloseWarningModal(false);

   const paletteStatuses = useMemo(() => {
      if (internal) {
         return PALETTE_STATUSES_OPTIONS;
      }
      return PALETTE_STATUSES_OPTIONS.filter(status => status.value !== PALETTE_STATUSES.LOST);
   }, [internal]);

   const areAllTouched =
      fromPaletteStatus?.value && toPaletteStatus?.value && paletteType?.value && amount;
   const isAmountInvalid = areAllTouched && !moreThanZero(amount);
   const isSubmitButtonDisabled = isLoading || !areAllTouched || isAmountInvalid;

   return (
      <Modal
         title={
            <StyledModalTitle
               title="Przerzuć palety"
               subtitle="W lewej kolumnie wybierz jaki rodzaj palety chcesz przerzucić. Możesz przerzucać tylko jeden rodzaj palet jednocześnie."
            />
         }
         opened
         onClose={handleModalClose}
         size={800}
      >
         <CloseWarningModal
            opened={showCloseWarningModal}
            onClose={handleCloseWarningModalClose}
            onConfirm={handleCloseWarningModalConfirm}
         />
         <form onSubmit={handleSubmit}>
            <div className={styles.modalContent}>
               <div className={styles.inputsContainer}>
                  {bannerData && (
                     <Banner
                        className={styles.banner}
                        variant={bannerData.variant}
                        children={bannerData.description}
                     />
                  )}
                  {
                     <div className={styles.inputsRow}>
                        <StyledSelect
                           classNames={{
                              container: styles.selectContainer,
                              select: styles.select,
                           }}
                           onChange={setPaletteType}
                           options={storagePaletteTypesOptions}
                           label="Rodzaj palet"
                           value={paletteType}
                        />
                     </div>
                  }
                  {paletteType && (
                     <div className={styles.inputsRow}>
                        <StyledSelect
                           classNames={{
                              container: styles.selectContainer,
                              select: styles.select,
                           }}
                           onChange={setFromPaletteStatus}
                           options={paletteStatuses}
                           label="Status palet"
                           value={fromPaletteStatus}
                        />
                        <Arrow />
                        <StyledSelect
                           classNames={{
                              container: styles.selectContainer,
                              select: styles.select,
                           }}
                           onChange={setToPaletteStatus}
                           options={paletteStatuses}
                           label="Status palet"
                           value={toPaletteStatus}
                        />
                     </div>
                  )}
                  {fromPaletteStatus && toPaletteStatus && (
                     <div className={styles.inputsRow}>
                        <StyledTextInput
                           className={styles.inputContainer}
                           type="text"
                           error={isAmountInvalid}
                           helperText={
                              isAmountInvalid ? 'Wartość musi być liczbą większą od 0' : ''
                           }
                           label="Ilość (szt.)"
                           value={amount}
                           onChange={handleChangePalletsAmount}
                        />
                     </div>
                  )}
               </div>
               <LoadingOverlay
                  visible={isLoading}
                  overlayBlur={10}
                  loaderProps={{ color: 'var(--light-green)' }}
               />
            </div>
            <div className={styles.actions}>
               <StyledButton
                  type="button"
                  variant="text"
                  text="Anuluj"
                  onClick={handleModalClose}
               />
               <StyledButton
                  variant="filled-primary"
                  text="Przerzuć palety"
                  disabled={isSubmitButtonDisabled}
               />
            </div>
         </form>
      </Modal>
   );
};

export default MovePalletsInsideStorageModal;
