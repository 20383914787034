import CustomModal, { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './RemoveOrderModal.module.css';

type RemoveOrderModalProps = CustomModalProps & {
   selectedOrderId: string;
   onSuccessRemoveOrder: () => void;
};

const RemoveOrderModal = ({
   selectedOrderId,
   onSuccessRemoveOrder,
   onClose,
   ...props
}: RemoveOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleRemoveOrder = () => {
      setIsLoading(true);
      HTTPService.deleteOrder(selectedOrderId)
         .then(() => {
            onSuccessRemoveOrder();
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         {...props}
         icon="TRASH"
         innerTitle="Usuń zamówienie"
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         isLoading={isLoading}
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: 'Usuń zamówienie',
            variant: 'filled-danger',
            onClick: handleRemoveOrder,
         }}
         secondaryButtonProps={{
            text: 'Nie usuwaj',
            variant: 'outlined-danger',
            onClick: closeModalHandler,
         }}
      >
         <div className={styles.descriptionContainer}>
            <p className={styles.plainText}>Czy jesteś pewny, że chcesz usunąć to zamówienie?</p>
            <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
         </div>
      </CustomModal>
   );
};

export default RemoveOrderModal;
