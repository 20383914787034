import { CustomModal, CustomModalProps } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './AcceptOrderModal.module.css';

type AcceptOrderModalProps = CustomModalProps & {
   selectedOrderId: string;
   isOrderNonstandard?: boolean;
   onSuccessAcceptOrder: (updatedOrder: OrderDetailsDTO) => Promise<void>;
};

const AcceptOrderModal = ({
   selectedOrderId,
   isOrderNonstandard,
   onSuccessAcceptOrder,
   onClose,
   ...props
}: AcceptOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleAcceptOrder = () => {
      setIsLoading(true);
      HTTPService.changeOrderStatus(selectedOrderId, 'ACCEPT')
         .then(response => {
            closeModalHandler();
            return onSuccessAcceptOrder(response.data);
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   const title = isOrderNonstandard
      ? 'Zaakceptuj zamówienie niestandardowe'
      : 'Zaakceptuj zamówienie';

   return (
      <CustomModal
         {...props}
         icon="FILE_CHECK"
         innerTitle={title}
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         isLoading={isLoading}
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: title,
            variant: 'filled-primary',
            onClick: handleAcceptOrder,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: closeModalHandler,
         }}
      >
         <div className={styles.descriptionContainer}>
            <p className={styles.plainText}>
               {isOrderNonstandard
                  ? 'Czy chcesz zaakceptować to niestandardowe zamówienie?'
                  : 'Czy chcesz zaakceptować to zamówienie?'}
            </p>
            <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
         </div>
      </CustomModal>
   );
};

export default AcceptOrderModal;
