import { Rotate2 } from 'tabler-icons-react';

import { SharedGridTableCell, SharedGridTableRow } from 'components/shared';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { AddressDeliveryRatePerPalletConfig } from 'interfaces';
import { currencyFormatter } from 'utils/numberFormatter';

import styles from './PalletsPricingPerAddressesTableInnerRow.module.css';

export type PalletsPricingPerAddressesTableInnerRowProps = {
   addressDeliveryPalletPricing: AddressDeliveryRatePerPalletConfig;
   baseAddressDeliveryPalletPricing: AddressDeliveryRatePerPalletConfig;
};

export const PalletsPricingPerAddressesTableInnerRow = ({
   addressDeliveryPalletPricing,
   baseAddressDeliveryPalletPricing,
}: PalletsPricingPerAddressesTableInnerRowProps) => {
   const { palletType, deliveryPrice } = addressDeliveryPalletPricing;
   const { deliveryPrice: baseDeliveryPrice } = baseAddressDeliveryPalletPricing;

   const isBaseDeliveryPriceDifferent = deliveryPrice !== baseDeliveryPrice;

   return (
      <SharedGridTableRow className={styles.row}>
         <SharedGridTableCell>
            <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].label}</h3>
            <p>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[palletType].dimensions}</p>
         </SharedGridTableCell>
         <SharedGridTableCell className={styles.cell}>
            {currencyFormatter.format(deliveryPrice)}
            {isBaseDeliveryPriceDifferent && <Rotate2 />}
         </SharedGridTableCell>
      </SharedGridTableRow>
   );
};
