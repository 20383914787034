import { useCallback, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '@mantine/core';
import { Message, Pencil, Plus, Trash } from 'tabler-icons-react';

import { StyledButton, StyledTable } from 'components/shared';
import { PAGINATION_SIZE } from 'constants/pagination';
import { commentsTableHeaders } from 'constants/tableHeaders';
import { usePagination } from 'hooks/usePagination';
import { ClientUserDTO, CommentDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner, TableCell, TableRow } from 'storybook';

import ClientDetailsCommentModal from './ClientDetailsCommentModal/ClientDetailsCommentModal';
import styles from './ClientDetailsPrivateComments.module.css';

type ClientDetailsPrivateCommentsProps = {
   client: ClientUserDTO;
   onFetchCommentsDataError: (error: unknown) => void;
};

type CommentModal = {
   open: boolean;
   comment: string;
   action: 'ADD' | 'EDIT' | 'DELETE';
   commentId: string;
};

export const ClientDetailsPrivateComments = ({
   client,
   onFetchCommentsDataError,
}: ClientDetailsPrivateCommentsProps) => {
   const [loading, setLoading] = useState(false);
   const [itemsTotalCount, setItemsTotalCount] = useState(0);
   const [comments, setComments] = useState<CommentDTO[]>([]);
   const [commentModal, setCommentModal] = useState<CommentModal>({
      open: false,
      comment: '',
      action: 'ADD',
      commentId: '',
   });

   const fetchComments = useCallback(
      (currentPage: number) => {
         setLoading(true);
         HTTPService.getUserComments(client.id, { page: currentPage, size: PAGINATION_SIZE })
            .then(res => {
               if (currentPage === 0) {
                  setComments(res.data.content);
                  setItemsTotalCount(res.data.totalElements);
               } else {
                  setComments(prevValues => [...prevValues, ...res.data.content]);
               }
            })
            .catch(onFetchCommentsDataError)
            .finally(() => setLoading(false));
      },
      [client, onFetchCommentsDataError],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchComments,
   });

   const handleCloseClientDetailsCommentModal = useCallback(
      () => setCommentModal(prevValues => ({ ...prevValues, open: false })),
      [],
   );

   return (
      <>
         {commentModal.open && (
            <ClientDetailsCommentModal
               commentId={commentModal.commentId}
               comment={commentModal.comment}
               action={commentModal.action}
               centered
               opened={commentModal.open}
               onClose={handleCloseClientDetailsCommentModal}
               setComments={setComments}
               clientId={client.id}
               setItemsTotalCount={setItemsTotalCount}
            />
         )}
         <StyledButton
            onClick={() =>
               setCommentModal({
                  open: true,
                  action: 'ADD',
                  comment: '',
                  commentId: '',
               })
            }
            style={{ marginTop: 24 }}
            icon={<Plus color="var(--primary-green)" />}
            variant="outlined-primary"
            text="Dodaj komentarz"
         />
         <Banner
            style={{ marginTop: 24 }}
            fullWidth
            children="Te komentarze nie będą widoczne dla klienta."
            variant="info"
         />
         <div className={styles.tableContainer} style={{ marginTop: 24 }} id="tableContainer">
            <InfiniteScroll
               scrollableTarget="tableContainer"
               dataLength={itemsTotalCount}
               next={handleLoadNextItems}
               hasMore={comments.length !== itemsTotalCount}
               loader={<Loader style={{ marginTop: 8 }} color="var(--primary-green)" />}
            >
               <StyledTable className={styles.commentsTable} columnHeaders={commentsTableHeaders}>
                  {loading ? (
                     <tr>
                        <td align="center" colSpan={3}>
                           <Loader color="var(--primary-green)" />
                        </td>
                     </tr>
                  ) : comments.length ? (
                     comments.map(comment => (
                        <TableRow key={comment.id}>
                           <TableCell>
                              {new Date(comment.createdDate).toLocaleDateString()}
                           </TableCell>
                           <TableCell>{comment.content}</TableCell>
                           <TableCell align="right">
                              <div className={styles.btnsContainer}>
                                 <StyledButton
                                    icon={<Pencil color="var(--primary-green)" />}
                                    variant="text"
                                    text="Edytuj"
                                    onClick={() =>
                                       setCommentModal({
                                          open: true,
                                          action: 'EDIT',
                                          comment: comment.content,
                                          commentId: comment.id,
                                       })
                                    }
                                 />
                                 <StyledButton
                                    icon={<Trash color="var(--primary-text)" />}
                                    className={styles.deleteBtn}
                                    variant="text"
                                    text="Usuń"
                                    onClick={() =>
                                       setCommentModal({
                                          open: true,
                                          action: 'DELETE',
                                          comment: '',
                                          commentId: comment.id,
                                       })
                                    }
                                 />
                              </div>
                           </TableCell>
                        </TableRow>
                     ))
                  ) : (
                     <tr>
                        <td colSpan={3} align="center">
                           <div className={styles.noResultsContainer}>
                              <Message size={52} />
                              <p>Nie dodałeś jeszcze żadnych prywatnych komentarzy.</p>
                              <StyledButton
                                 onClick={() =>
                                    setCommentModal({
                                       open: true,
                                       action: 'ADD',
                                       comment: '',
                                       commentId: '',
                                    })
                                 }
                                 icon={<Plus color="var(--primary-green)" />}
                                 variant="outlined-primary"
                                 text="Dodaj komentarz"
                              />
                           </div>
                        </td>
                     </tr>
                  )}
               </StyledTable>
            </InfiniteScroll>
         </div>
      </>
   );
};
