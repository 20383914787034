import ResetPasswordForm from 'components/Auth/ResetPasswordForm/ResetPasswordForm';
import ResetForgotPasswordLayout from 'components/layout/ResetForgotPasswordLayout/ResetForgotPasswordLayout';

const ResetPasswordPage = () => (
   <ResetForgotPasswordLayout>
      <ResetPasswordForm />
   </ResetForgotPasswordLayout>
);

export default ResetPasswordPage;
