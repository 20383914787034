import { Modal, ModalProps } from '@mantine/core';
import { Loader } from '@mantine/core';

import styles from './GUSLoadingModal.module.css';
const GUSLoadingModal = (props: ModalProps) => {
   return (
      <Modal centered size={600} {...props} styles={{ title: { fontFamily: 'Source Sans Pro' } }}>
         <div className={styles.contentContainer}>
            <Loader style={{ marginBottom: 24 }} color="var(--primary-green)" />
            <div>
               <p>Trwa pobieranie danych z GUS.</p>
               <p>Prosimy o cierpliwość.</p>
            </div>
         </div>
      </Modal>
   );
};

export default GUSLoadingModal;
