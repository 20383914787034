import { DateTime } from 'luxon';

const dateFormat = 'dd/LL/yyyy';
const timeFormat = 'HH:mm';
const dateTimeFormat = `${dateFormat}, ${timeFormat}`;

export const getFormattedDate = (date: Date, format?: string) =>
   DateTime.fromJSDate(date).toFormat(format ? format : dateFormat);

export const getFormattedDateFromString = (date: string) =>
   DateTime.fromISO(date).toFormat(dateFormat);

export const getTimeFromString = (date: string) => DateTime.fromISO(date).toFormat(timeFormat);

export const getFormattedDateTimeFromString = (date: string) =>
   DateTime.fromISO(date).toFormat(dateTimeFormat);
