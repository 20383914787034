import classNames from 'classnames';
import { Edit } from 'tabler-icons-react';

import { EMPTY_VALUE_LABEL } from 'constants/commonLabels';

import styles from './InternalOrderIdInfo.module.css';

export type InternalOrderIdInfoProps = {
   internalOrderId: string | null;
   onInternalOrderIdChangeClick?: () => void;
};

const InternalOrderIdInfo = ({
   internalOrderId,
   onInternalOrderIdChangeClick,
}: InternalOrderIdInfoProps) => (
   <span className={styles.mainContainer}>
      Nr wewnętrzny zamówienia:
      <span
         className={classNames(styles.internalOrderIdContainer, {
            [styles.clickable]: !!onInternalOrderIdChangeClick,
         })}
         onClick={onInternalOrderIdChangeClick}
      >
         {internalOrderId || EMPTY_VALUE_LABEL}
         {onInternalOrderIdChangeClick && <Edit color="var(--primary-text)" />}
      </span>
   </span>
);

export default InternalOrderIdInfo;
