import { useMemo } from 'react';

import { sumBy } from 'lodash';
import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'constants/dateFormats';
import { OrderDetails } from 'interfaces';
import {
   getOrderDelayTimeInDaysFromRetentionDate,
   getPalletsFullData,
   isBeforeToPayStatus,
   isOrderWithRetentionDateExpiredAndWithPalletsToSend,
   mergeRegularPalletsWithFreePallets,
} from 'utils/functions';

type OrderDetailsProps = {
   order?: OrderDetails;
};

const ZERO_DELAY_TIME_IN_DAYS_FROM_RETENTION_DATE = 0;

export const useOrderDetails = ({ order }: OrderDetailsProps) => {
   const parsedRetentionDate = order?.retentionDate
      ? DateTime.fromFormat(order.retentionDate, DATE_FORMAT)
      : null;
   const isBeforeToPayStatusValue = order ? isBeforeToPayStatus(order.status) : false;
   const isRetentionDateExpired =
      order && parsedRetentionDate
         ? isOrderWithRetentionDateExpiredAndWithPalletsToSend(
              parsedRetentionDate,
              isBeforeToPayStatusValue,
           )
         : false;
   const delayTimeInDaysFromRetentionDate = parsedRetentionDate
      ? getOrderDelayTimeInDaysFromRetentionDate(parsedRetentionDate)
      : ZERO_DELAY_TIME_IN_DAYS_FROM_RETENTION_DATE;

   const pallets = useMemo(() => {
      if (!order) {
         return [];
      }
      const regularPallets = order.pallets;
      const additionalPallets = order.additionalPallets;
      const regularPalletTypes = regularPallets.map(regularPallet => regularPallet.palletType);
      const additionalPalletsWithMissingTypes = additionalPallets.filter(
         additionalPallet => !regularPalletTypes.includes(additionalPallet.palletType),
      );
      const transformedAdditionalPalletsWithMissingTypes = additionalPalletsWithMissingTypes.map(
         additionalPalletWithMissingType => ({ ...additionalPalletWithMissingType, amount: 0 }),
      );
      const availablePallets = [...regularPallets, ...transformedAdditionalPalletsWithMissingTypes];
      const palletsWithFullData = getPalletsFullData(
         availablePallets,
         pallet => palletType => palletType.name === pallet.palletType,
      );
      return mergeRegularPalletsWithFreePallets(palletsWithFullData, additionalPallets);
   }, [order]);

   const palletsSum = useMemo(
      () => sumBy(pallets, pallet => pallet.amount + pallet.freePalletsAmount),
      [pallets],
   );

   return {
      pallets,
      palletsSum,
      delayTimeInDaysFromRetentionDate,
      isRetentionDateExpired,
   };
};
