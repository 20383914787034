import { ReactNode } from 'react';

import classNames from 'classnames';

import { TableCell } from 'storybook';
import { TableRow } from 'storybook/components/Table/TableRow/TableRow';

import styles from './DistributionCenterPalletsTableItem.module.css';

type DistributionCenterPalletsTableItemProps = {
   onClick?: () => void;
   active?: boolean;
   key?: number | string;
   items: string[] | number[] | ReactNode[];
   palletsTable?: boolean;
};

const DistributionCenterPalletsTableItem = ({
   onClick,
   active,
   key,
   items,
   palletsTable,
}: DistributionCenterPalletsTableItemProps) => {
   return (
      <TableRow
         onClick={() => onClick && onClick()}
         active={active}
         key={key}
         className={styles.palletTableRow}
      >
         {items.map((item, index) => (
            <TableCell
               key={index}
               className={classNames({ [styles.tableCellOrder]: palletsTable })}
            >
               {item}
            </TableCell>
         ))}
      </TableRow>
   );
};

export default DistributionCenterPalletsTableItem;
