import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useDebouncedValue } from '@mantine/hooks';

import { PalletsStackIcon } from 'assets';
import { PageLayout } from 'components/layout';
import {
   SupplierCancelPalletsPickUpModal,
   SupplierConfirmPalletsPickUpModal,
   SupplierPalletsPickUpsHeader,
   SupplierPalletsPickUpsTable,
} from 'components/PickUps';
import { CustomInfiniteScroll, NoItemsNotification, Tabs } from 'components/shared';
import { SUPPLIER_PALLETS_PICK_UPS_PAGE_BANNERS } from 'constants/banners';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { PAGINATION_SIZE } from 'constants/pagination';
import { supplierPalletsPickUpsPageTabs } from 'constants/tabs';
import WithAuth from 'hoc/withAuth';
import { usePagination } from 'hooks';
import {
   BannerData,
   DistributionCenterPalletPickUpFilters,
   PalletPickUpAdminDTO,
   PickUpStatus,
   SupplierPalletsPickUpsModalType,
   SupplierPalletsPickUpsTab,
} from 'interfaces';
import { HTTPService } from 'service';
import { errorHandler } from 'utils/errorHandler';

import styles from './PalletsPickUpsPage.module.css';

const INFINITE_SCROLL_CONTAINER_ID = 'palletsPickUpsListInfiniteScrollContainer';
const PALLETS_PICK_UPS_STATUS_SEARCH_PARAM = 'status';

const PalletsPickUpsPage = () => {
   const [distributionCenterPalletsPickUpsData, setDistributionCenterPalletsPickUpsData] = useState<
      PalletPickUpAdminDTO[]
   >([]);
   const [
      distributionCenterPalletsPickUpsDataItemsTotalCount,
      setDistributionCenterPalletsPickUpsDataItemsTotalCount,
   ] = useState(0);
   const [
      isDistributionCenterPalletsPickUpsDataFirstFetchLoading,
      setIsDistributionCenterPalletsPickUpsDataFirstFetchLoading,
   ] = useState(false);
   const [
      isDistributionCenterPalletsPickUpsDataError,
      setIsDistributionCenterPalletsPickUpsDataError,
   ] = useState(false);

   const [
      selectedDistributionCenterPalletsPickUpModalData,
      setSelectedDistributionCenterPalletsPickUpModalData,
   ] = useState<{
      distributionCenterPalletsPickUpData: PalletPickUpAdminDTO;
      modalType: SupplierPalletsPickUpsModalType;
   } | null>(null);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);

   const [clientNameFilter, setClientNameFilter] = useState('');
   const [debouncedClientNameFilter] = useDebouncedValue(clientNameFilter, DEBOUNCE_DELAY);

   const infiniteScrollContainer = useRef<HTMLDivElement>(null);

   const [searchParams, setSearchParams] = useSearchParams();

   const statusSearchParam = searchParams.get(
      PALLETS_PICK_UPS_STATUS_SEARCH_PARAM,
   ) as SupplierPalletsPickUpsTab | null;
   const activeTabName: SupplierPalletsPickUpsTab = statusSearchParam || PickUpStatus.CREATED;

   const isOpenedDistributionCenterPalletsPickUpAcceptModal =
      !!selectedDistributionCenterPalletsPickUpModalData?.distributionCenterPalletsPickUpData &&
      selectedDistributionCenterPalletsPickUpModalData.modalType ===
         SupplierPalletsPickUpsModalType.ACCEPT;
   const isOpenedDistributionCenterPalletsPickUpCancelModal =
      !!selectedDistributionCenterPalletsPickUpModalData?.distributionCenterPalletsPickUpData &&
      selectedDistributionCenterPalletsPickUpModalData.modalType ===
         SupplierPalletsPickUpsModalType.REJECT;

   const selectedPalletsPickUpId =
      selectedDistributionCenterPalletsPickUpModalData?.distributionCenterPalletsPickUpData.id ||
      '';
   const selectedPalletBalances =
      selectedDistributionCenterPalletsPickUpModalData?.distributionCenterPalletsPickUpData
         .palletBalances;

   const handleTabClick = (tab: SupplierPalletsPickUpsTab) => setSearchParams({ status: tab });

   const handleDistributionCenterPalletsPickUpsDataError = (error: unknown) =>
      errorHandler(error, () => setIsDistributionCenterPalletsPickUpsDataError(true));

   const fetchDistributionCenterPalletsPickUpsData = useCallback(
      async (currentPage: number, filters?: DistributionCenterPalletPickUpFilters) => {
         try {
            if (currentPage === 0) {
               setIsDistributionCenterPalletsPickUpsDataFirstFetchLoading(true);
            }
            const { data: getDistributionCenterPalletsPickUpsResponseData } =
               await HTTPService.getDistributionCenterPalletsPickUps(
                  { page: currentPage, size: PAGINATION_SIZE },
                  {
                     status: filters?.status || PickUpStatus.CREATED,
                     nameSearch: filters?.nameSearch,
                  },
               );
            if (currentPage === 0) {
               setDistributionCenterPalletsPickUpsData(
                  getDistributionCenterPalletsPickUpsResponseData.content,
               );
               setDistributionCenterPalletsPickUpsDataItemsTotalCount(
                  getDistributionCenterPalletsPickUpsResponseData.totalElements,
               );
               infiniteScrollContainer.current && (infiniteScrollContainer.current.scrollTop = 0);
            } else {
               setDistributionCenterPalletsPickUpsData(prevDistributionCenterPalletsPickUpsData =>
                  prevDistributionCenterPalletsPickUpsData.concat(
                     getDistributionCenterPalletsPickUpsResponseData.content,
                  ),
               );
            }
         } catch (error) {
            handleDistributionCenterPalletsPickUpsDataError(error);
         } finally {
            if (currentPage === 0) {
               setIsDistributionCenterPalletsPickUpsDataFirstFetchLoading(false);
            }
         }
      },
      [],
   );

   const filters = useMemo<DistributionCenterPalletPickUpFilters>(
      () => ({
         status: activeTabName,
         nameSearch: debouncedClientNameFilter,
      }),
      [activeTabName, debouncedClientNameFilter],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchDistributionCenterPalletsPickUpsData,
      filter: filters,
   });

   const handleClientNameFilterChange = (event: ChangeEvent<HTMLInputElement>) =>
      setClientNameFilter(event.target.value);

   const handleDistributionCenterPalletsPickUpOpenModalClick = ({
      distributionCenterPalletsPickUpData,
      modalType,
   }: {
      distributionCenterPalletsPickUpData: PalletPickUpAdminDTO;
      modalType: SupplierPalletsPickUpsModalType;
   }) =>
      setSelectedDistributionCenterPalletsPickUpModalData({
         distributionCenterPalletsPickUpData,
         modalType,
      });

   const handleActionSuccess = (palletsPickUpId: string, bannerConfig: BannerData) => {
      setDistributionCenterPalletsPickUpsData(prevDistributionCenterPalletsPickUpsData =>
         prevDistributionCenterPalletsPickUpsData.filter(
            distributionCenterPalletsPickUpsDataItem =>
               distributionCenterPalletsPickUpsDataItem.id !== palletsPickUpId,
         ),
      );
      setDistributionCenterPalletsPickUpsDataItemsTotalCount(
         prevDistributionCenterPalletsPickUpsDataItemsTotalCount =>
            prevDistributionCenterPalletsPickUpsDataItemsTotalCount - 1,
      );
      setBannerData(bannerConfig);
      setSelectedDistributionCenterPalletsPickUpModalData(null);
   };

   const handleConfirmPalletsPickUpSuccess = (palletsPickUpId: string) =>
      handleActionSuccess(
         palletsPickUpId,
         SUPPLIER_PALLETS_PICK_UPS_PAGE_BANNERS.ACCEPT_PALLET_PICK_UP_SUCCESS,
      );

   const handleCancelPalletsPickUpSuccess = (palletsPickUpId: string) =>
      handleActionSuccess(
         palletsPickUpId,
         SUPPLIER_PALLETS_PICK_UPS_PAGE_BANNERS.REJECT_PALLET_PICK_UP_SUCCESS,
      );

   const handleCloseModal = useCallback(
      () => setSelectedDistributionCenterPalletsPickUpModalData(null),
      [],
   );

   return (
      <>
         {isOpenedDistributionCenterPalletsPickUpAcceptModal && selectedPalletBalances && (
            <SupplierConfirmPalletsPickUpModal
               selectedPalletsPickUpId={selectedPalletsPickUpId}
               palletBalances={selectedPalletBalances}
               onConfirmPalletsPickUpSuccess={handleConfirmPalletsPickUpSuccess}
               onClose={handleCloseModal}
            />
         )}
         {isOpenedDistributionCenterPalletsPickUpCancelModal && (
            <SupplierCancelPalletsPickUpModal
               selectedPalletsPickUpId={selectedPalletsPickUpId}
               onCancelPalletsPickUpSuccess={handleCancelPalletsPickUpSuccess}
               onClose={handleCloseModal}
            />
         )}
         <PageLayout
            didFetchDataErrorOccur={isDistributionCenterPalletsPickUpsDataError}
            bannerData={bannerData}
            closeBanner={() => setBannerData(null)}
            headerElement={
               !isDistributionCenterPalletsPickUpsDataError ? (
                  <SupplierPalletsPickUpsHeader
                     disabled={isDistributionCenterPalletsPickUpsDataError}
                     clientNameFilter={clientNameFilter}
                     onClientNameFilterChange={handleClientNameFilterChange}
                  />
               ) : null
            }
         >
            <Tabs
               tabs={supplierPalletsPickUpsPageTabs}
               activeTabName={activeTabName}
               className={styles.tabs}
               onTabClick={handleTabClick}
            />
            <CustomInfiniteScroll
               containerId={INFINITE_SCROLL_CONTAINER_ID}
               containerRef={infiniteScrollContainer}
               next={handleLoadNextItems}
               dataLength={distributionCenterPalletsPickUpsData.length}
               hasMore={
                  distributionCenterPalletsPickUpsData.length !==
                  distributionCenterPalletsPickUpsDataItemsTotalCount
               }
               isInitialLoading={isDistributionCenterPalletsPickUpsDataFirstFetchLoading}
               infiniteScrollClassNames={{
                  innerContainer: styles.infiniteScrollInnerContainer,
                  outerLoader: styles.infiniteScrollOuterLoader,
               }}
               noItemsNotificationElement={
                  <NoItemsNotification
                     icon={<PalletsStackIcon />}
                     description="Brak palet do odbioru."
                  />
               }
            >
               {!!distributionCenterPalletsPickUpsData.length && (
                  <SupplierPalletsPickUpsTable
                     palletPickUpsStatus={activeTabName}
                     distributionCenterPalletsPickUpsData={distributionCenterPalletsPickUpsData}
                     onPalletsPickUpOpenModalClick={
                        handleDistributionCenterPalletsPickUpOpenModalClick
                     }
                  />
               )}
            </CustomInfiniteScroll>
         </PageLayout>
      </>
   );
};

export default WithAuth(PalletsPickUpsPage, 'ROLE_SYSTEM_ADMIN');
