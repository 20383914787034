import { useState } from 'react';

import { Modal, ModalProps } from '@mantine/core';
import classNames from 'classnames';

import CloseWarningModal from 'components/shared/WarningModal/WarningModals/CloseWarningModal';
import {
   CommonRatePerPalletConfig,
   DamageType,
   GroupedLostDamagedPalletItems,
   Option,
   PalletAmount,
   PalletReportDTO,
   ReportLostDamagedPalletsModalParams,
   ReportLostDamagedPalletsModalViews,
} from 'interfaces';

import LostDamagedPalletsByClientModalContent from './LostDamagedPalletsByClientModalContent/LostDamagedPalletsByClientModalContent';
import LostDamagedPalletsBySupplierModalContent from './LostDamagedPalletsBySupplierModalContent/LostDamagedPalletsBySupplierModalContent';
import SelectDamageTypeModalContent from './SelectDamageTypeModalContent/SelectDamageTypeModalContent';
import styles from './ReportLostDamagedPalletsModal.module.css';

const DEFAULT_MODAL_PARAMS = {
   size: 1150,
   title: 'Zgłoś zgubienie, zniszczenie lub uszkodzenie palet',
};

const viewsWithCloseWindowWarning: ReportLostDamagedPalletsModalViews[] = [
   'LOST_DAMAGED_PALLETS_BY_CLIENT_VIEWS',
   'LOST_DAMAGED_PALLETS_BY_SUPPLIER_FORM',
];

type ReportLostDamagedPalletsModalProps = ModalProps & {
   isSupplier?: boolean;
   pricesForPallets?: CommonRatePerPalletConfig[];
   orderId?: string;
   availablePalletTypes: Option[];
   remainingPalletsToBeSend: PalletAmount[];
   clientId?: string;
   onSuccessReportLostDestroyedPalletsByClient: (reports: PalletReportDTO[]) => void;
   onSuccessReportLostDestroyedPalletsBySupplier: (reports: PalletReportDTO[]) => void;
   onReportDamagedPallets: (groupedLostDamagedPalletItems: GroupedLostDamagedPalletItems) => void;
};

const ReportLostDamagedPalletsModal = ({
   isSupplier,
   pricesForPallets,
   orderId,
   availablePalletTypes,
   remainingPalletsToBeSend,
   clientId,
   onSuccessReportLostDestroyedPalletsByClient,
   onSuccessReportLostDestroyedPalletsBySupplier,
   onReportDamagedPallets,
   ...props
}: ReportLostDamagedPalletsModalProps) => {
   const [shownLostDamagedPalletsForm, setShownLostDamagedPalletsForm] = useState(false);
   const [damageType, setDamageType] = useState<DamageType | null>(null);
   const [modalParams, setModalParams] =
      useState<ReportLostDamagedPalletsModalParams>(DEFAULT_MODAL_PARAMS);
   const [showCloseWarning, setShowCloseWarning] = useState(false);
   const [currentView, setCurrentView] =
      useState<ReportLostDamagedPalletsModalViews>('SELECT_DAMAGE_TYPE');

   const changeModalWidth = (
      visibleLostDamagedPalletsForm: boolean,
      selectedDamageType: DamageType | null,
   ) => {
      if (visibleLostDamagedPalletsForm && selectedDamageType === 'LOST_DAMAGED_BY_SUPPLIER') {
         setModalParams(prevModalParams => ({ ...prevModalParams, size: 1350 }));
      }
      if (!visibleLostDamagedPalletsForm && selectedDamageType === null) {
         setModalParams(prevModalParams => ({ ...prevModalParams, size: 1150 }));
      }
   };

   const handleGoToLostDamagedPalletsForm = () => {
      setShownLostDamagedPalletsForm(true);
      changeModalWidth(true, damageType);
      setCurrentView(
         damageType === 'LOST_DAMAGED_BY_CLIENT'
            ? 'LOST_DAMAGED_PALLETS_BY_CLIENT_VIEWS'
            : 'LOST_DAMAGED_PALLETS_BY_SUPPLIER_FORM',
      );
   };

   const clearModalState = () => {
      setDamageType(null);
      setShownLostDamagedPalletsForm(false);
      changeModalWidth(false, null);
      setCurrentView('SELECT_DAMAGE_TYPE');
   };

   const handleCloseModal = () => {
      clearModalState();
      setModalParams(DEFAULT_MODAL_PARAMS);
      props.onClose();
   };

   const handleConfirmCloseWarningModal = () => {
      setShowCloseWarning(false);
      handleCloseModal();
   };

   const handleSuccessReportLostDestroyedPalletsByClient = (reports: PalletReportDTO[]) => {
      handleCloseModal();
      onSuccessReportLostDestroyedPalletsByClient(reports);
   };

   const handleSuccessReportLostDestroyedPalletsBySupplier = (reports: PalletReportDTO[]) => {
      handleCloseModal();
      onSuccessReportLostDestroyedPalletsBySupplier(reports);
   };

   const handleReportDamagedPallets = (
      groupedLostDamagedPalletItems: GroupedLostDamagedPalletItems,
   ) => {
      handleCloseModal();
      onReportDamagedPallets(groupedLostDamagedPalletItems);
   };

   return (
      <Modal
         {...props}
         title={modalParams.title}
         size={modalParams.size}
         onClose={
            viewsWithCloseWindowWarning.includes(currentView)
               ? () => setShowCloseWarning(true)
               : handleCloseModal
         }
         overflow="inside"
         centered
         className={classNames({
            [styles.modalContentSmall]: modalParams.size === 1150,
            [styles.modalContentLarge]: modalParams.size === 1350,
         })}
      >
         <>
            <CloseWarningModal
               opened={showCloseWarning}
               onConfirm={handleConfirmCloseWarningModal}
               onClose={() => setShowCloseWarning(false)}
            />
            {!shownLostDamagedPalletsForm && (
               <SelectDamageTypeModalContent
                  selectedDamageType={damageType}
                  onSelectDamageType={setDamageType}
                  onGoToDamagePalletsForm={handleGoToLostDamagedPalletsForm}
                  isSupplier={isSupplier}
               />
            )}
            {shownLostDamagedPalletsForm && damageType === 'LOST_DAMAGED_BY_CLIENT' && (
               <LostDamagedPalletsByClientModalContent
                  pricesForPallets={pricesForPallets}
                  isSupplier={isSupplier}
                  orderId={orderId}
                  availablePalletTypes={availablePalletTypes}
                  remainingPalletsToBeSend={remainingPalletsToBeSend}
                  clientId={clientId}
                  onReportDamagedPallets={handleReportDamagedPallets}
                  onSuccessReportLostDestroyedPallets={
                     handleSuccessReportLostDestroyedPalletsByClient
                  }
                  onGoToSelectDamageType={clearModalState}
               />
            )}
            {shownLostDamagedPalletsForm && damageType === 'LOST_DAMAGED_BY_SUPPLIER' && (
               <LostDamagedPalletsBySupplierModalContent
                  isSupplier={isSupplier}
                  orderId={orderId}
                  availablePalletTypes={availablePalletTypes}
                  remainingPalletsToBeSend={remainingPalletsToBeSend}
                  clientId={clientId}
                  onReportDamagedPallets={handleReportDamagedPallets}
                  onSuccessReportLostDestroyedPallets={
                     handleSuccessReportLostDestroyedPalletsBySupplier
                  }
                  onGoToSelectDamageType={clearModalState}
               />
            )}
         </>
      </Modal>
   );
};

export default ReportLostDamagedPalletsModal;
