import { useNavigate } from 'react-router-dom';

import { PeopleConnectingPlugIcon } from 'assets';

import StyledButton from '../StyledButton/StyledButton';

import styles from './ErrorNotification.module.css';

const ErrorNotification = () => {
   const navigate = useNavigate();

   const handleReloadPage = () => navigate(0);

   return (
      <div className={styles.container}>
         <PeopleConnectingPlugIcon />
         <h2>Ups! Coś poszło nie tak!</h2>
         <p>
            Nie możemy wyświetlić danych.
            <br />
            Odśwież stronę lub spróbuj ponownie za chwilę.
         </p>
         <StyledButton variant="filled-primary" text="Odśwież stronę" onClick={handleReloadPage} />
      </div>
   );
};

export default ErrorNotification;
