import { cloneDeep } from 'lodash';

import DistributionCenterPalletTableItem from 'components/DistributionCenterPalletTableItem/DistributionCenterPalletTableItem';
import { CustomModal, StyledTable } from 'components/shared';
import { distributionCenterPalletsTableHeadersChangeOrder } from 'constants/tableHeaders';
import { useTableForm } from 'hooks';
import { BalanceDistributionCenterForHook, DistributionCenter } from 'interfaces';
import { Banner } from 'storybook';
import { getFullAddress } from 'utils/functions';
import { editPalletsBalanceValidations } from 'utils/validation/editBalanceDistributionCenter';

import styles from './EditPalletsCommercialNetworkModal.module.css';

type EditPalletsCommercialNetworkModalProps = {
   opened: boolean;
   distributionCenter: DistributionCenter;
   isLoading: boolean;
   mode: string;
   setEditDistributionPallets: (state: boolean) => void;
   onSubmit: (
      balance: BalanceDistributionCenterForHook[],
      commercialNetworkId: string,
      balanceId: string,
   ) => void;
};

const EditPalletsCommercialNetworkModal = ({
   opened,
   setEditDistributionPallets,
   distributionCenter,
   isLoading,
   mode,
   onSubmit,
}: EditPalletsCommercialNetworkModalProps) => {
   const formattedBalance = distributionCenter.todayBalance.balance.map(
      ({ palletName, availableForCollection, palletReturn }) => ({
         palletName,
         validAvailablePallets: availableForCollection.valid.toString(),
         validReturnPallets: palletReturn.valid.toString(),
         invalidAvailablePallets: availableForCollection.invalid.toString(),
         invalidReturnPallets: palletReturn.invalid.toString(),
      }),
   );

   const balance: BalanceDistributionCenterForHook[] = cloneDeep(formattedBalance);

   const handleSubmit = (values: BalanceDistributionCenterForHook[]) => {
      onSubmit(values, distributionCenter.commercialNetworkId!, distributionCenter.todayBalance.id);
   };

   const { rows, errors, touched, handleChangeValue, handleSubmitForm, hasErrors } =
      useTableForm<BalanceDistributionCenterForHook>({
         initialState: balance,
         onSubmit: handleSubmit,
         validations: editPalletsBalanceValidations,
      });

   return (
      <>
         <CustomModal
            className={styles.container}
            modalContentClassName={styles.modalContent}
            opened={opened}
            onClose={() => setEditDistributionPallets(false)}
            size={1264}
            title="Edytuj saldo"
            innerTitle={`Edytujesz saldo Centrum Dystrybucyjnego „${getFullAddress(
               distributionCenter.address.street,
               distributionCenter.address.zipCode,
               distributionCenter.address.city,
            )}”.`}
            primaryButtonProps={{ text: 'Edytuj saldo magazynu', disabled: hasErrors }}
            secondaryButtonProps={{
               text: 'Anuluj',
               onClick: () => setEditDistributionPallets(false),
            }}
            isLoading={isLoading}
            onSubmit={handleSubmitForm}
         >
            <div className={styles.modalContent}>
               <StyledTable
                  className={styles.table}
                  columnHeaders={distributionCenterPalletsTableHeadersChangeOrder}
               >
                  {rows.map((pallet, index: number) => (
                     <DistributionCenterPalletTableItem
                        key={index}
                        name={pallet.palletName}
                        availablePallets={{
                           invalidAvailablePallets: pallet.invalidAvailablePallets,
                           validAvailablePallets: pallet.validAvailablePallets,
                        }}
                        returnPallets={{
                           invalidReturnPallets: pallet.invalidReturnPallets,
                           validReturnPallets: pallet.validReturnPallets,
                        }}
                        handleChangeValue={handleChangeValue}
                        mode={mode}
                        rowIndex={index}
                        touched={touched[index]}
                        error={errors[index]}
                     />
                  ))}
               </StyledTable>
               {hasErrors && (
                  <Banner
                     style={{ marginTop: 24 }}
                     variant="error"
                     children="Wartości muszą być większe lub równe 0"
                     fullWidth
                  />
               )}
            </div>
         </CustomModal>
      </>
   );
};

export default EditPalletsCommercialNetworkModal;
