import classNames from 'classnames';

import {
   AdditionalCharge,
   AdditionalChargeErrors,
   AdditionalChargeTouched,
   AdditionalChargeType,
} from 'interfaces/addAdditionalChargesForm';
import { Option } from 'interfaces/commonInterfacesAndTypes';

import AdditionalChargeCard from './AdditionalChargeCard/AdditionalChargeCard';
import styles from './AdditionalChargesList.module.css';

type AdditionalChargesListProps = {
   additionalCharges: AdditionalCharge[];
   additionalChargesErrors: AdditionalChargeErrors[];
   additionalChargesTouched: AdditionalChargeTouched[];
   onRemoveAdditionalCharge: (uuid: string) => void;
   onChangeAdditionalCharge: (
      uuid: string,
      chargeType: AdditionalChargeType,
      field: string,
      value: string | Option,
   ) => void;
   onChangeAdditionalChargeType: (uuid: string, type: AdditionalChargeType) => void;
   palletTypes: Option[];
   palletSendingDestinationAddresses: {
      [palletType: string]: Option[];
   } | null;
   className?: string;
};

const AdditionalChargesList = ({
   additionalCharges,
   additionalChargesErrors,
   additionalChargesTouched,
   onRemoveAdditionalCharge,
   onChangeAdditionalCharge,
   onChangeAdditionalChargeType,
   palletTypes,
   palletSendingDestinationAddresses,
   className,
}: AdditionalChargesListProps) => (
   <ul className={classNames(styles.additionalChargesList, className)}>
      {additionalCharges.map((additionalCharge, index) => (
         <li key={additionalCharge.uuid}>
            <AdditionalChargeCard
               additionalCharge={additionalCharge}
               additionalChargeErrors={additionalChargesErrors[index]}
               additionalChargeTouched={additionalChargesTouched[index]}
               {...(index !== 0 ? { onRemove: onRemoveAdditionalCharge } : {})}
               onChange={onChangeAdditionalCharge}
               onChangeChargeType={onChangeAdditionalChargeType}
               palletTypes={palletTypes}
               palletSendingDestinationAddresses={palletSendingDestinationAddresses}
            />
         </li>
      ))}
   </ul>
);

export default AdditionalChargesList;
