import {
   CommonRatePerPalletConfig,
   CommonRatePerPalletConfigFormData,
   DelayChargePerPalletConfig,
   DelayChargePerPalletConfigForm,
   RateConfigDTO,
   RateConfigForm,
   RetentionConfig,
   RetentionConfigForm,
   RetentionPalletCostConfig,
   RetentionPalletCostConfigForm,
} from 'interfaces';
import { parseNumber } from 'utils/functions';

export const rateConfigFormToRateConfigDTOMapper: (
   rateConfigFormData: RateConfigForm,
) => RateConfigDTO = (rateConfigFormData: RateConfigForm) => {
   const {
      palletRates: palletRatesFormData,
      retentionConfig: retentionConfigFormData,
      delaysCharges: delaysChargesFormData,
   } = rateConfigFormData;
   const palletRates = palletRatesFormData.common.map(
      ratePerPalletConfigFormToRatePerPalletConfigMapper,
   );
   const retentionConfig = retentionConfigFormToRetentionConfigMapper(retentionConfigFormData);
   const delaysCharges = delaysChargesFormData.map(
      delayChargePerPalletConfigFormToDelayChargePerPalletConfigMapper,
   );
   return {
      palletRates,
      retentionConfig,
      delaysCharges,
   };
};

const ratePerPalletConfigFormToRatePerPalletConfigMapper: (
   commonRatePerPalletConfigFormData: CommonRatePerPalletConfigFormData,
) => CommonRatePerPalletConfig = commonRatePerPalletConfigFormData => {
   const { palletType, personalPickUpPrice, damagePrice, lostPrice, wrongReceiver } =
      commonRatePerPalletConfigFormData;
   return {
      palletType,
      personalPickUpPrice: parseNumber(personalPickUpPrice),
      damagePrice: parseNumber(damagePrice),
      lostPrice: parseNumber(lostPrice),
      wrongReceiver: parseNumber(wrongReceiver),
   };
};

const retentionConfigFormToRetentionConfigMapper: (
   retentionConfigFormData: RetentionConfigForm,
) => RetentionConfig = (retentionConfigFormData: RetentionConfigForm) => {
   const { retentionPeriodDays, retentionPalletCosts } = retentionConfigFormData;
   const retentionPalletCostsFormData = retentionPalletCosts.map(
      retentionPalletCostConfigFormToRetentionPalletCostConfigMapper,
   );
   return { retentionPeriodDays, retentionPalletCosts: retentionPalletCostsFormData };
};

const retentionPalletCostConfigFormToRetentionPalletCostConfigMapper: (
   retentionPalletCostConfigFormData: RetentionPalletCostConfigForm,
) => RetentionPalletCostConfig = (
   retentionPalletCostConfigFormData: RetentionPalletCostConfigForm,
) => {
   const { palletType, price } = retentionPalletCostConfigFormData;
   return {
      palletType,
      price: parseNumber(price),
   };
};

const delayChargePerPalletConfigFormToDelayChargePerPalletConfigMapper: (
   delayChargePerPalletConfigFormData: DelayChargePerPalletConfigForm,
) => DelayChargePerPalletConfig = (
   delayChargePerPalletConfigFormData: DelayChargePerPalletConfigForm,
) => {
   const { palletType, upTo21Days, upTo30Days, over30Days } = delayChargePerPalletConfigFormData;
   return {
      palletType,
      upTo21Days: parseNumber(upTo21Days),
      upTo30Days: parseNumber(upTo30Days),
      over30Days: parseNumber(over30Days),
   };
};
