import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageLayout } from 'components/layout';
import { PalletsBalanceContent, PalletsBalancePageHeader } from 'components/Pallets';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import {
   CLIENT_DAMAGED_PALLETS_BY_CLIENT,
   CLIENT_DESTROYED_PALLETS_BY_SUPPLIER,
   CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT,
} from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import WithAuth from 'hoc/withAuth';
import { useClientPalletsBalance } from 'hooks';
import {
   BannerData,
   LostDamagedPalletsBalance,
   PalletAmountDTO,
   PalletBalance,
   PalletsBalanceModal,
} from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './PalletsPage.module.css';

const PalletsPage = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const [selectedModal, setSelectedModal] = useState<PalletsBalanceModal>(null);
   const {
      rentedPallets,
      damagedByClientPallets,
      lostOrDestroyedByClientPallets,
      damagedOrLostBySupplierPallets,
      remainingPallets,
      setRentedPallets,
      setDamagedByClientPallets,
      setLostOrDestroyedByClientPallets,
      setDamagedOrLostBySupplierPallets,
      setRemainingPallets,
      handleLostDamagedBySupplierPalletReports,
      handleLostByClientPalletReports,
      handleDamagedByClientPalletReports,
   } = useClientPalletsBalance();
   const { user: client } = useAuth();
   const navigate = useNavigate();

   const handlePalletsData = useCallback(
      (
         remainingPalletsData: PalletAmountDTO[],
         rentedPalletsBalanceData: PalletBalance,
         lostDamagedPalletsBalanceData: LostDamagedPalletsBalance,
      ) => {
         const { damagedByUserFault, lostOrDestroyedByUserFault, receivedDamaged } =
            lostDamagedPalletsBalanceData;
         setRentedPallets(rentedPalletsBalanceData);
         setDamagedByClientPallets(damagedByUserFault);
         setLostOrDestroyedByClientPallets(lostOrDestroyedByUserFault);
         setDamagedOrLostBySupplierPallets(receivedDamaged);
         setRemainingPallets(remainingPalletsData);
      },
      [
         setDamagedByClientPallets,
         setDamagedOrLostBySupplierPallets,
         setLostOrDestroyedByClientPallets,
         setRentedPallets,
         setRemainingPallets,
      ],
   );

   const displayReportLostDamagedPalletsModal = () =>
      setSelectedModal('REPORT_DAMAGE_LOSS_PALLETS');

   const displaySendPalletsModal = () => setSelectedModal('SEND_PALLETS');

   const displaySuccessReportLostDamagedPalletsModal = () =>
      setSelectedModal('SUCCESS_REPORT_LOST_DAMAGED_PALLETS_BY_SUPPLIER_MODAL');

   const closeModal = () => setSelectedModal(null);

   useEffect(() => {
      setIsLoading(true);
      Promise.all([
         HTTPService.getCurrentUserRentedPalletsBalance(),
         HTTPService.getCurrentUserLostDamagedPalletsBalance(),
         HTTPService.getRemainingPallets(),
      ])
         .then(
            ([
               getRentedPalletsBalanceResponse,
               getLostDamagedPalletsBalanceResponse,
               getRemainingPalletsResponse,
            ]) => {
               handlePalletsData(
                  getRemainingPalletsResponse.data.pallets,
                  getRentedPalletsBalanceResponse.data.summedAmountByPalletType,
                  getLostDamagedPalletsBalanceResponse.data,
               );
            },
         )
         .catch(error => {
            setBannerData({ description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA, variant: 'error' });
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   }, [handlePalletsData]);

   const displayBanner = (bannerDataToDisplay: BannerData) => setBannerData(bannerDataToDisplay);

   const hideBanner = () => setBannerData(null);

   const handleClickDamagedByClientPalletsDetails = () =>
      navigate(CLIENT_DAMAGED_PALLETS_BY_CLIENT);

   const handleClickLostOrDestroyedByClientPalletsDetails = () =>
      navigate(CLIENT_LOST_DESTROYED_PALLETS_BY_CLIENT);

   const handleClickDamagedOrLostBySupplierDetails = () =>
      navigate(CLIENT_DESTROYED_PALLETS_BY_SUPPLIER);

   return (
      <PageLayout
         headerElement={
            <PalletsBalancePageHeader
               isButtonsDisabled={isLoading}
               displayReportLostDamagedPalletsModal={displayReportLostDamagedPalletsModal}
               displaySendPalletsModal={displaySendPalletsModal}
            />
         }
         bannerData={bannerData}
         isLoading={isLoading}
         classNames={{ pageContainer: styles.pageContainer, pageLoader: styles.pageLoader }}
         closeBanner={hideBanner}
      >
         <PalletsBalanceContent
            rentedPallets={rentedPallets}
            damagedByClientPallets={damagedByClientPallets}
            lostOrDestroyedByClientPallets={lostOrDestroyedByClientPallets}
            damagedOrLostBySupplierPallets={damagedOrLostBySupplierPallets}
            remainingPallets={remainingPallets}
            selectedModal={selectedModal}
            clientCompanyName={client?.companyName}
            onLostDamagedBySupplierPalletReports={handleLostDamagedBySupplierPalletReports}
            onLostByClientPalletReports={handleLostByClientPalletReports}
            onDamagedByClientPalletReports={handleDamagedByClientPalletReports}
            displayBanner={displayBanner}
            displaySendPalletsModal={displaySendPalletsModal}
            displaySuccessReportLostDamagedPalletsModal={
               displaySuccessReportLostDamagedPalletsModal
            }
            closeModal={closeModal}
            onClickDamagedByClientPalletsDetails={handleClickDamagedByClientPalletsDetails}
            onClickDamagedOrLostBySupplierDetails={handleClickDamagedOrLostBySupplierDetails}
            onClickLostOrDestroyedByClientPalletsDetails={
               handleClickLostOrDestroyedByClientPalletsDetails
            }
         />
      </PageLayout>
   );
};

export default WithAuth(PalletsPage, 'ROLE_CLIENT');
