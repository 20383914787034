import { CustomModal } from 'components/shared';
import { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './RejectOrderModal.module.css';

type RejectOrderModalProps = CustomModalProps & {
   selectedOrderId: string;
   isOrderNonstandard?: boolean;
   onSuccessRejectOrder: (updatedOrder: OrderDetailsDTO) => void;
};

const RejectOrderModal = ({
   selectedOrderId,
   isOrderNonstandard,
   onSuccessRejectOrder,
   onClose,
   ...props
}: RejectOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleRejectOrder = () => {
      setIsLoading(true);
      HTTPService.changeOrderStatus(selectedOrderId, 'REFUSE')
         .then(response => {
            onSuccessRejectOrder(response.data);
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   const title = isOrderNonstandard ? 'Odrzuć zamówienie niestandardowe' : 'Odrzuć zamówienie';

   return (
      <CustomModal
         {...props}
         icon="ALERT_TRIANGLE"
         innerTitle={title}
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         isLoading={isLoading}
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: title,
            variant: 'filled-danger',
            onClick: handleRejectOrder,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'outlined-danger',
            onClick: closeModalHandler,
         }}
      >
         <>
            <p className={styles.plainText}>
               {isOrderNonstandard
                  ? 'Czy jesteś pewny, że chcesz odrzucić i anulować to niestandardowe zamówienie?'
                  : 'Czy jesteś pewny, że chcesz odrzucić i anulować to zamówienie?'}
            </p>
            <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
         </>
      </CustomModal>
   );
};

export default RejectOrderModal;
