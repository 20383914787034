import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useDebouncedValue } from '@mantine/hooks';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Search } from 'tabler-icons-react';

import { PalletsStackIcon } from 'assets';
import { OrdersTabs } from 'components/Orders/Dashboard';
import { CustomInfiniteScroll, NoItemsNotificationWithLink, StatusLegend } from 'components/shared';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { OrderTable } from 'components/SupplierPanel';
import { SUPPLIER_ORDERS_DASHBOARD_BANNERS } from 'constants/banners';
import { DEBOUNCE_DELAY } from 'constants/debounce';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { orderStatusesLegend } from 'constants/orderStatuses';
import { PAGINATION_SIZE } from 'constants/pagination';
import { SUPPLIER_PLACE_ORDER } from 'constants/routes';
import WithAuth from 'hoc/withAuth';
import { usePagination } from 'hooks';
import {
   BannerData,
   SupplierOrdersPageTab,
   SupplierOrdersTableItem,
} from 'interfaces/commonInterfacesAndTypes';
import { HTTPService } from 'service';
import { orderDTOToSupplierOrdersTableItemMapper } from 'service/http/mappers';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import styles from './OrdersPage.module.css';

const ORDERS_STATUS_SEARCH_PARAM = 'status';

const OrdersPage = () => {
   const [orderIdOrCompanyNameFilter, setOrderIdOrCompanyNameFilter] = useState('');
   const [debouncedOrderIdOrCompanyNameFilter] = useDebouncedValue(
      orderIdOrCompanyNameFilter,
      DEBOUNCE_DELAY,
   );
   const [unreadOrdersCounts, setUnreadOrdersCounts] = useState<{ [key: string]: number }>({});
   const [orders, setOrders] = useState<SupplierOrdersTableItem[]>([]);
   const [ordersTotalCount, setOrdersTotalCount] = useState(0);
   const [isOrdersFirstFetchLoading, setIsOrdersFirstFetchLoading] = useState(false);
   const [isUnreadOrdersCountsLoading, setIsUnreadOrdersCountsLoading] = useState(false);
   const [isPendingOrdersShipmentAlertVisible, setIsPendingOrdersShipmentAlertVisible] =
      useState(false);
   const [bannerData, setBannerData] = useState<BannerData | null>(null);
   const tableContainer = useRef<HTMLDivElement>(null);
   const navigate = useNavigate();
   const location = useLocation();
   const [searchParams, setSearchParams] = useSearchParams();

   const statusSearchParam = searchParams.get(
      ORDERS_STATUS_SEARCH_PARAM,
   ) as SupplierOrdersPageTab | null;
   const activeTabName: SupplierOrdersPageTab = statusSearchParam || 'ALL';

   useEffect(() => {
      const { successPlaceOrder, successRemoveOrder } = (location.state || {}) as {
         successPlaceOrder: boolean;
         successRemoveOrder: boolean;
      };
      if (!(successPlaceOrder || successRemoveOrder)) {
         return;
      }
      setBannerData(
         successPlaceOrder
            ? SUPPLIER_ORDERS_DASHBOARD_BANNERS.SUCCESS_PLACE_ORDER
            : SUPPLIER_ORDERS_DASHBOARD_BANNERS.SUCCESS_REMOVE_ORDER,
      );
      window.history.replaceState({}, '');
   }, [location.state]);

   useEffect(() => {
      setIsUnreadOrdersCountsLoading(true);
      HTTPService.getAdminUnreadOrdersCounts()
         .then(({ data: unreadOrdersCountsData }) =>
            setUnreadOrdersCounts(unreadOrdersCountsData.counts),
         )
         .catch(handleFetchOrdersDataError)
         .finally(() => setIsUnreadOrdersCountsLoading(false));
   }, []);

   const fetchOrders = useCallback(
      (
         currentPage: number,
         filter?: { selectedTab: SupplierOrdersPageTab; orderIdOrCompanyNameSearch?: string },
      ) => {
         currentPage === 0 && setIsOrdersFirstFetchLoading(true);
         HTTPService.getAdminOrderList(
            {
               orderStatus: filter?.selectedTab || 'ALL',
               idOrNameSearch: filter?.orderIdOrCompanyNameSearch,
            },
            { page: currentPage, size: PAGINATION_SIZE },
         )
            .then(({ data: responseData }) => {
               const orderTableData = responseData.orders.map(
                  orderDTOToSupplierOrdersTableItemMapper,
               );
               if (currentPage === 0) {
                  setOrders(orderTableData);
                  setOrdersTotalCount(responseData.totalElements);
                  setIsPendingOrdersShipmentAlertVisible(responseData.ordersAlert);
                  tableContainer.current && (tableContainer.current.scrollTop = 0);
               } else {
                  setOrders(prevOrders => prevOrders.concat(orderTableData));
               }
            })
            .catch(handleFetchOrdersDataError)
            .finally(() => currentPage === 0 && setIsOrdersFirstFetchLoading(false));
      },
      [],
   );

   const handleFetchOrdersDataError = (error: unknown) =>
      errorHandler(error, () =>
         setBannerData({
            variant: 'error',
            description: DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA,
         }),
      );

   const fetchOrdersFilter = useMemo(
      () => ({
         selectedTab: activeTabName,
         orderIdOrCompanyNameSearch: debouncedOrderIdOrCompanyNameFilter,
      }),
      [activeTabName, debouncedOrderIdOrCompanyNameFilter],
   );

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchOrders,
      filter: fetchOrdersFilter,
   });

   const currentMonth = DateTime.now().setLocale('pl-PL').monthLong;
   const withAlertBanners = isPendingOrdersShipmentAlertVisible || bannerData;

   return (
      <div className={styles.mainContainer}>
         <div
            className={classNames(styles.bannersContainer, {
               [styles.withAlertBanners]: withAlertBanners,
            })}
         >
            {isPendingOrdersShipmentAlertVisible && (
               <Banner
                  children={`Nie uzupełniono wszystkich NADAŃ PALET dla zamówień z miesiąca: ${currentMonth}!`}
                  variant="error"
                  fullWidth
                  className={styles.banner}
               />
            )}
            {bannerData && (
               <Banner
                  variant={bannerData.variant}
                  title={bannerData.title}
                  children={bannerData.description}
                  onClose={() => setBannerData(null)}
                  withCloseIcon
                  fullWidth
                  className={styles.banner}
               />
            )}
         </div>
         <div className={styles.headerContainer}>
            <div className={styles.header}>
               <div>
                  <StyledTextInput
                     type="text"
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOrderIdOrCompanyNameFilter(event.target.value)
                     }
                     value={orderIdOrCompanyNameFilter}
                     placeholder="np. Nazwa firmy, nr ID zamówienia"
                     leftIcon={<Search />}
                     className={styles.searchInput}
                  />
                  <StyledButton
                     variant="filled-primary"
                     text="Złóż zamówienie"
                     onClick={() => navigate(SUPPLIER_PLACE_ORDER)}
                     className={styles.searchBtn}
                  />
               </div>
               <StatusLegend statusDataItems={orderStatusesLegend} />
            </div>
            <OrdersTabs
               activeTabName={activeTabName}
               unreadOrdersCounts={unreadOrdersCounts}
               className={styles.tabs}
               onTabClick={(tab: SupplierOrdersPageTab) => setSearchParams({ status: tab })}
            />
         </div>
         <CustomInfiniteScroll
            containerId="orderTableInfiniteScrollContainer"
            containerRef={tableContainer}
            next={handleLoadNextItems}
            dataLength={orders.length}
            hasMore={orders.length !== ordersTotalCount}
            isInitialLoading={isOrdersFirstFetchLoading || isUnreadOrdersCountsLoading}
            noItemsNotificationElement={
               <NoItemsNotificationWithLink
                  icon={<PalletsStackIcon />}
                  desctiption="Nie ma jeszcze żadnych zamówień."
                  linkText="Złóż zamówienie"
                  redirectTo={SUPPLIER_PLACE_ORDER}
               />
            }
         >
            <OrderTable
               mode="SUPPLIER_TABLE"
               orders={orders}
               activeTabName={activeTabName}
               className={classNames({
                  [styles.tableHideMobile]: !orders.length,
               })}
            />
         </CustomInfiniteScroll>
      </div>
   );
};

export default WithAuth(OrdersPage, 'ROLE_SYSTEM_ADMIN');
