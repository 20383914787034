import { CustomModal } from 'components/shared';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './CloseOrderModal.module.css';

type CloseOrderModalProps = {
   orderId: string;
   onSuccessCloseOrder: (updatedOrder: OrderDetailsDTO) => void;
   opened: boolean;
   onClose: () => void;
};

const CloseOrderModal = ({
   orderId,
   onSuccessCloseOrder,
   opened,
   onClose,
}: CloseOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const sendOrderForSettlement = () => {
      setIsLoading(true);
      HTTPService.changeOrderStatus(orderId, 'ENDED')
         .then(response => {
            closeModalHandler();
            onSuccessCloseOrder(response.data);
         })
         .catch(err => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(err);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         opened={opened}
         onClose={closeModalHandler}
         title="Zamknij zamówienie"
         innerTitle="Uwaga!"
         icon="ALERT_TRIANGLE"
         primaryButtonProps={{
            text: 'Zamknij zamówienie',
            variant: 'filled-primary',
            onClick: sendOrderForSettlement,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: closeModalHandler,
         }}
         isLoading={isLoading}
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         leftSideModalContent
      >
         <>
            <p>
               Czy wszystkie opłaty klienta zostały zaksięgowane i jesteś pewien, że chcesz
               zakończyć to zamówienie?
            </p>
            <p>Tej akcji nie można cofnąć.</p>
         </>
      </CustomModal>
   );
};

export default CloseOrderModal;
