import { useMemo } from 'react';

import { GroupedLostDamagedPalletItems, OrderDetails } from 'interfaces';
import { getPalletTypes } from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';

type OrderDetailsProps = {
   order: OrderDetails;
   groupedLostDamagedPalletItems?: GroupedLostDamagedPalletItems | null;
   edit?: boolean;
};

export const useRegularOrderDetails = ({
   order,
   groupedLostDamagedPalletItems,
   edit,
}: OrderDetailsProps) => {
   const availablePalletTypesToSend = useMemo(() => {
      if (order.mode === 'ADDED_TO_NEXT_ORDER') {
         return [];
      } else if (groupedLostDamagedPalletItems) {
         const palletTypes = groupedLostDamagedPalletItems.damaged.map(
            itemToSend => itemToSend.palletType,
         );
         return sortOptions(palletTypes);
      } else if (edit) {
         return getPalletTypes(order.pallets);
      } else {
         return getPalletTypes(order.remainingPalletsToBeSend);
      }
   }, [groupedLostDamagedPalletItems, order, edit]);

   const availablePalletTypesToReport = useMemo(
      () =>
         order.mode === 'ADDED_TO_NEXT_ORDER' ? [] : getPalletTypes(order.remainingPalletsToBeSend),
      [order],
   );

   return {
      availablePalletTypesToSend,
      availablePalletTypesToReport,
   };
};
