export type LostOrDamagedPalletsReportData = {
   reportId: string;
   clientId: string;
};

export type REQUEST_TYPE = 'PLACE_ORDER' | 'FETCH_DATA';

export const REQUEST_TYPE_TO_STATUS_MAPPER = {
   PLACE_ORDER: 'isPlaceOrderRequestSending',
   FETCH_DATA: 'isDataFetching',
};

export const INITIAL_REQUEST_STATUSES = {
   isPlaceOrderRequestSending: false,
   isDataFetching: true,
};
