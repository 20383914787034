import { useMemo } from 'react';

import { isEqual } from 'lodash';

import { ExtendableSharedGridTableRow } from 'components/shared';
import { PalletsPricingRowLabel } from 'components/SupplierPanel';
import { ClientDeliveryAddress } from 'interfaces/dto/clientUserDTO';

import { PalletsPricingPerAddressesTableInnerRow } from './PalletsPricingPerAddressesTableInnerRow/PalletsPricingPerAddressesTableInnerRow';

type PalletsPricingPerAddressesTableRowProps = {
   palletsPricingPerAddress: ClientDeliveryAddress;
   basePalletsPricingPerAddressData: ClientDeliveryAddress;
   isBasePalletsPricingPerAddressRow: boolean;
};

export const PalletsPricingPerAddressesTableRow = ({
   palletsPricingPerAddress,
   basePalletsPricingPerAddressData,
   isBasePalletsPricingPerAddressRow,
}: PalletsPricingPerAddressesTableRowProps) => {
   const { address, palletConfig } = palletsPricingPerAddress;
   const { id: addressId, name: addressName } = address;

   const palletsPricingPerAddressesTableRows = useMemo(() => {
      return palletConfig.palletRates.map(palletRatesItem => {
         const basePalletRates = basePalletsPricingPerAddressData.palletConfig.palletRates.find(
            basePalletsRatesItem => basePalletsRatesItem.palletType === palletRatesItem.palletType,
         );
         if (!basePalletRates) {
            return null;
         }
         return (
            <PalletsPricingPerAddressesTableInnerRow
               key={`${addressId}/${palletRatesItem.palletType}`}
               addressDeliveryPalletPricing={palletRatesItem}
               baseAddressDeliveryPalletPricing={basePalletRates}
            />
         );
      });
   }, [addressId, basePalletsPricingPerAddressData.palletConfig, palletConfig]);

   const isPricingDifferentFromBase = useMemo(() => {
      return !isEqual(
         palletsPricingPerAddress.palletConfig,
         basePalletsPricingPerAddressData.palletConfig,
      );
   }, [basePalletsPricingPerAddressData.palletConfig, palletsPricingPerAddress.palletConfig]);

   return (
      <ExtendableSharedGridTableRow
         key={addressId}
         label={
            <PalletsPricingRowLabel
               addressName={addressName}
               isPricingDifferentFromBase={isPricingDifferentFromBase}
            />
         }
         isDefaultOpen={isBasePalletsPricingPerAddressRow}
         extendableContent={palletsPricingPerAddressesTableRows}
      />
   );
};
