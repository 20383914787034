import { freePalletPrice } from 'constants/freePalletPrice';
import { PALLET_TYPES } from 'constants/palletTypes';
import { DamagedPalletData, PalletDataWithPrice } from 'interfaces';

import { countPalletsAmount, getKeys } from '.';

export const getPalletsDataFromReportedLostOrDamagedPallets = (
   lostOrDamagedPallets: DamagedPalletData[],
) => {
   const lostOrDamagedPalletsAmount = countPalletsAmount(lostOrDamagedPallets);
   const palletTypesWithLostOrDamagedPallets = getKeys(lostOrDamagedPalletsAmount).filter(
      palletType => !!lostOrDamagedPalletsAmount[palletType],
   );
   const palletsData: PalletDataWithPrice[] = palletTypesWithLostOrDamagedPallets.map(
      palletTypeWithLostOrDamagedPallets => ({
         name: PALLET_TYPES[palletTypeWithLostOrDamagedPallets],
         amount: lostOrDamagedPalletsAmount[palletTypeWithLostOrDamagedPallets].toString(),
         orderType: 'CUSTOM',
         pricePerUnit: freePalletPrice,
      }),
   );
   return palletsData;
};
