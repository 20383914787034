import { OrderStatus } from 'interfaces';

export const orderStatusesLegend: {
   status: string;
   description: string;
   variant: OrderStatus;
}[] = [
   {
      status: 'Oczekujące',
      description: ' zamówienie oczekuje na akceptację pracownika NDHP',
      variant: 'WAITING',
   },

   {
      status: 'W realizacji',
      description: ' zamówienie zostało zaakceptowane i czeka na realizację',
      variant: 'IN_PROGRESS',
   },
   {
      status: 'Dostarczone',
      description: ' palety są w posiadaniu Najemcy',
      variant: 'DELIVERED',
   },
   {
      status: 'Rozliczane',
      description: ' wszystkie palety zostały nadane, zamówienie w trakcie rozliczania',
      variant: 'DURING_BILLING',
   },
   {
      status: 'Do zapłaty',
      description:
         ' koszty zamówienia zostały potwierdzone, a faktury wystawione. Zamówienie czeka na opłatę',
      variant: 'TO_PAY',
   },
   {
      status: 'Zakończone',
      description: ' wszystkie płatności zostały zaksięgowane a zamówienie zakończone',
      variant: 'ENDED',
   },

   {
      status: 'Anulowane',
      description: ' zamówienie zostało anulowane przez pracownika NDHP',
      variant: 'CANCELED',
   },
];

export const ORDER_STATUSES_BADGE_DATA: { [key: string]: { label: string; variant: OrderStatus } } =
   {
      WAITING: { label: 'Oczekujące', variant: 'WAITING' },
      CANCELED: { label: 'Anulowane', variant: 'CANCELED' },
      DURING_BILLING: { label: 'Rozliczane', variant: 'DURING_BILLING' },
      TO_PAY: { label: 'Do zapłaty', variant: 'TO_PAY' },
      ENDED: { label: 'Zakończone', variant: 'ENDED' },
      IN_PROGRESS: { label: 'W realizacji', variant: 'IN_PROGRESS' },
      DELIVERED: { label: 'Dostarczone', variant: 'DELIVERED' },
   };

export const ORDER_STATUSES: Record<OrderStatus, OrderStatus> = {
   WAITING: 'WAITING',
   IN_PROGRESS: 'IN_PROGRESS',
   DELIVERED: 'DELIVERED',
   DURING_BILLING: 'DURING_BILLING',
   TO_PAY: 'TO_PAY',
   ENDED: 'ENDED',
   CANCELED: 'CANCELED',
};
