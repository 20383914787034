import classNames from 'classnames';

import { ImagesContainer, StyledBadge } from 'components/shared';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { REPORT_STATUSES_AND_LABELS } from 'constants/reportStatuses';
import { REPORT_TYPE_LABELS } from 'constants/reportTypes';
import { PalletReport } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import styles from './DamagedLostPalletsTableMainRow.module.css';

type DamagedLostPalletsTableMainRowProps = {
   palletReport: PalletReport;
};

const DamagedLostPalletsTableMainRow = ({ palletReport }: DamagedLostPalletsTableMainRowProps) => (
   <TableRow
      className={classNames(styles.tableRow, {
         [styles.tableRowWithoutBottomBorder]: palletReport.damagedPallets.length !== 1,
      })}
   >
      <TableCell>#{palletReport.orderId}</TableCell>
      {palletReport.receivedDamaged && (
         <TableCell>
            <StyledBadge
               text={REPORT_STATUSES_AND_LABELS[palletReport.status].label}
               variant={REPORT_STATUSES_AND_LABELS[palletReport.status].variant}
            />
         </TableCell>
      )}
      <TableCell>
         {palletReport.receivedDamaged
            ? REPORT_TYPE_LABELS[palletReport.damagedPallets[0].reportType]
            : REPORT_TYPE_LABELS[palletReport.palletReportTypes[0]]}
      </TableCell>
      <TableCell>{PALLET_TYPE_LABELS[palletReport.damagedPallets[0].type]}</TableCell>
      <TableCell align="right">{palletReport.damagedPallets[0].amount}</TableCell>
      {palletReport.receivedDamaged && (
         <TableCell>
            <ImagesContainer imageURLs={palletReport.damagedPallets[0].images} />
         </TableCell>
      )}
   </TableRow>
);

export default DamagedLostPalletsTableMainRow;
