import { useState } from 'react';

import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { USER_NOT_FOUND } from 'constants/errorCodes';
import { useAuth } from 'context/auth/AuthContextProvider';
import { useForm } from 'hooks/useForm';
import { Banner } from 'storybook';
import { forgotPasswordFormValidations } from 'utils/validation/forgotPasswordFormValidation';

import styles from './ForgotPasswordForm.module.css';

type ForgotPasswordFormProps = {
   onGoToLoginPage: () => void;
   onSendPasswordReset: (email: string) => void;
};

const ForgotPasswordForm = ({ onGoToLoginPage, onSendPasswordReset }: ForgotPasswordFormProps) => {
   const { sendPasswordReset } = useAuth();
   const [loading, setLoading] = useState(false);
   const [passwordResetError, setPasswordResetError] = useState(false);

   const handleSubmit = () => {
      setLoading(true);
      setPasswordResetError(false);
      sendPasswordReset(values.email)
         .then(() => {
            onSendPasswordReset(values.email);
         })
         .catch((error: Error) => {
            if (error.message === USER_NOT_FOUND) {
               onSendPasswordReset(values.email);
            } else {
               setPasswordResetError(true);
            }
         })
         .finally(() => setLoading(false));
   };

   const { values, changeHandler, touched, errorsList, submitHandler } = useForm({
      initialState: { email: '' },
      validations: forgotPasswordFormValidations,
      onSubmit: handleSubmit,
   });

   return (
      <div className={styles.mainContainer}>
         <h1>Resetowanie hasła</h1>
         <p>
            Wpisz e-mail, który został Ci przypisany przez firmę NDHP. Wyślemy Ci maila z łączem
            umożliwiającym zmianę hasła.
         </p>
         {passwordResetError && (
            <Banner
               children="Błąd podczas wysyłania linku."
               variant="error"
               fullWidth
               className={styles.banner}
            />
         )}
         <form noValidate onSubmit={submitHandler}>
            <StyledTextInput
               name="email"
               value={values.email}
               type="email"
               onChange={changeHandler}
               label="E-mail"
               error={touched.email && errorsList.email}
               helperText={touched.email && errorsList.email}
               className={styles.input}
            />
            <div className={styles.buttonsContainer}>
               <StyledButton
                  style={{ padding: '12px 24px' }}
                  text="Wróć do logowania"
                  onClick={onGoToLoginPage}
                  variant="text"
                  className={styles.button}
               />
               <StyledButton
                  type="submit"
                  text="Wyślij łącze na e-maila"
                  variant="filled-primary"
                  className={styles.button}
                  loading={loading}
               />
            </div>
         </form>
      </div>
   );
};

export default ForgotPasswordForm;
