import { useCallback, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { Loader } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { Eye, Pencil } from 'tabler-icons-react';

import { OutlinedCalendarIcon, PalletsStackBoldIcon, TruckDeliveryCancel } from 'assets';
import DistributionCenterPalletsTableItem from 'components/DistributionCenterPalletsTableItem/DistributionCenterPalletsTableItem';
import {
   ActionsDropdown,
   StyledBadge,
   StyledButton,
   StyledTable,
   WhiteCard,
} from 'components/shared';
import { DATE_PICKER_DATE_FORMAT } from 'constants/dateFormats';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { ORDER_STATUSES_BADGE_DATA } from 'constants/orderStatuses';
import { PAGINATION_FIRST_PAGE, PAGINATION_SIZE } from 'constants/pagination';
import { PALLET_TYPE_LABELS } from 'constants/palletTypes';
import { DISTRIBUTION_CENTER_ORDER_DETAILS_PATH, SUPPLIER_ORDERS_DETAILS } from 'constants/routes';
import {
   distributionCenterOrdersTableHeaders,
   distributionCenterPalletsTableHeaders,
   panelDistributionCenterPalletsTableHeaders,
   supplierOrdersTableHeadersWithTags,
} from 'constants/tableHeaders';
import { usePagination } from 'hooks/usePagination';
import { BannerType, BannerVariants, DistributionCenter, OrderDTO } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import {
   getExternalCommercialNetworkNumbers,
   getFormattedDateFromString,
   parseDate,
} from 'utils/functions';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './DistributionCenterPallets.module.css';

type DistributionCenterPalletsProps = {
   distributionCenter: DistributionCenter;
   shouldShowBanner: boolean;
   setShowBanner: (state: boolean) => void;
   handleDataChange: (value: Date) => void;
   onEditPallets?: () => void;
   mode?: 'DISTRIBUTION-CENTER';
};

const DistributionCenterPallets = ({
   distributionCenter,
   shouldShowBanner,
   setShowBanner,
   handleDataChange,
   onEditPallets,
   mode,
}: DistributionCenterPalletsProps) => {
   const [ordersDistributionCenter, setOrdersDistributionCenter] = useState<OrderDTO[]>([]);
   const [isExtend, setIsExtend] = useState<boolean>(false);
   const [ordersTotalCount, setOrdersTotalCount] = useState(0);
   const [chosenRow, setChosenRow] = useState('');
   const [palletName, setPalletName] = useState('');
   const [banner, setBanner] = useState<BannerType | null>(null);
   const tableContainer = useRef<HTMLDivElement>(null);
   const isDistributionCenterPanel = mode === 'DISTRIBUTION-CENTER';

   const toggleTableExtend = (pallet: string): void => {
      setIsExtend(true);
      setPalletName(pallet);
      setChosenRow(pallet);
   };

   const showBanner = (variant: BannerVariants, description: string) =>
      setBanner({ variant, description });

   const ordersItems = (order: OrderDTO) => {
      if (isDistributionCenterPanel) {
         return [
            getExternalCommercialNetworkNumbers(order),
            `#${order.id}`,
            order.createdBy.name,
            order.sendPallets[0].dateOfShipment,
            order.palletSum,
            '',
            <Link to={DISTRIBUTION_CENTER_ORDER_DETAILS_PATH(order.id)}>
               <StyledButton
                  type="button"
                  text="Szczegóły"
                  icon={<Eye />}
                  variant="text"
                  className={styles.detailsBtn}
               />
            </Link>,
         ];
      }
      return [
         order.additionalPallets?.length || order.deliveryMethod?.free ? (
            <StyledBadge variant="FREE" text="bezpłatne" />
         ) : (
            ''
         ),
         `#${order.id}`,
         order.createdBy.name,
         getFormattedDateFromString(order.createdDate),
         order.palletDeliveryDate,
         order.sendPallets[0].dateOfShipment,
         <StyledBadge
            text={ORDER_STATUSES_BADGE_DATA[order.status].label}
            variant={ORDER_STATUSES_BADGE_DATA[order.status].variant}
         />,
         order.palletSum,
         <Link to={SUPPLIER_ORDERS_DETAILS(order.id)}>
            <StyledButton
               type="button"
               text="Szczegóły"
               icon={<Eye />}
               variant="text"
               className={styles.detailsBtn}
            />
         </Link>,
      ];
   };

   const shouldFetchInitialData = (currentPage: number) => currentPage === PAGINATION_FIRST_PAGE;

   const getFetch = useCallback(() => {
      return isDistributionCenterPanel
         ? HTTPService.getOrdersWithShippedPalletsToDistributionCenterUser
         : HTTPService.getOrdersWithShippedPalletsToDistributionCenter;
   }, [isDistributionCenterPanel]);

   const fetchDitributionCenter = useCallback(
      (currentPage: number) => {
         const fetchFunction = getFetch();
         if (!palletName) {
            return;
         }
         fetchFunction(
            { page: currentPage, size: PAGINATION_SIZE },
            palletName,
            distributionCenter.todayBalance.date,
            distributionCenter.id,
         )
            .then(res => {
               if (shouldFetchInitialData(currentPage)) {
                  setOrdersDistributionCenter(res.data.content);
                  setOrdersTotalCount(res.data.totalElements);
                  tableContainer.current && (tableContainer.current.scrollTop = 0);
               } else {
                  setOrdersDistributionCenter(prevOrders => prevOrders.concat(res.data.content));
               }
            })
            .catch(error => {
               logNetworkError(error);
               showBanner('error', DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            });
      },

      [palletName, distributionCenter.id, distributionCenter.todayBalance.date, getFetch],
   );
   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchDitributionCenter,
   });

   const distributionCenterOrders =
      ordersDistributionCenter && ordersDistributionCenter.length > 0 ? (
         <div className={styles.innerContainer} id="tableContainerId" ref={tableContainer}>
            <InfiniteScroll
               dataLength={ordersDistributionCenter.length}
               next={handleLoadNextItems}
               hasMore={ordersDistributionCenter.length !== ordersTotalCount}
               loader={
                  <div className={styles.loader}>
                     <Loader color={'var(--primary-green)'} />
                  </div>
               }
               scrollableTarget={'tableContainerId'}
            >
               <StyledTable
                  columnHeaders={
                     isDistributionCenterPanel
                        ? distributionCenterOrdersTableHeaders
                        : supplierOrdersTableHeadersWithTags
                  }
                  headerClassName={styles.tableHeader}
                  className={styles.tableOrders}
               >
                  {ordersDistributionCenter &&
                     ordersDistributionCenter.map((order, index) => (
                        <DistributionCenterPalletsTableItem
                           key={index}
                           items={ordersItems(order)}
                           palletsTable
                        />
                     ))}
               </StyledTable>
            </InfiniteScroll>
         </div>
      ) : (
         <div className={styles.innerContainerSendPallets}>
            <div className={styles.sendingContainerEmpty}>
               <TruckDeliveryCancel />
               <div>Brak nadań dla wybranego rodzaju palet.</div>
            </div>
         </div>
      );

   return (
      <>
         {banner && (
            <Banner
               className={styles.banner}
               variant={banner?.variant}
               children={banner?.description}
               fullWidth
            />
         )}
         <WhiteCard className={styles.whiteCard}>
            <div className={styles.header}>
               <>
                  <h3>Palety i nadania</h3>
                  <div className={styles.right}>
                     <DatePicker
                        value={parseDate(distributionCenter.todayBalance.date)}
                        inputFormat={DATE_PICKER_DATE_FORMAT}
                        icon={<OutlinedCalendarIcon />}
                        locale="pl"
                        className={styles.datePicker}
                        clearable={false}
                        onChange={value => handleDataChange(value!)}
                     />
                     {!isDistributionCenterPanel && (
                        <ActionsDropdown
                           options={[
                              {
                                 text: 'Edytuj stan magazynowy palet',
                                 icon: <Pencil />,
                                 onClick: onEditPallets!,
                              },
                           ]}
                        />
                     )}
                  </div>
               </>
            </div>
            <>
               {shouldShowBanner && (
                  <Banner
                     className={styles.banner}
                     variant="success"
                     children="Saldo palet zostało zaktualizowane."
                     fullWidth
                     withCloseIcon
                     onClose={() => setShowBanner(false)}
                  />
               )}
               <div className={styles.innerContainer}>
                  <StyledTable
                     columnHeaders={
                        isDistributionCenterPanel
                           ? panelDistributionCenterPalletsTableHeaders
                           : distributionCenterPalletsTableHeaders
                     }
                     className={styles.table}
                  >
                     {distributionCenter.todayBalance.balance.map((pallet, index) => (
                        <DistributionCenterPalletsTableItem
                           key={index}
                           active={chosenRow === pallet.palletName}
                           items={
                              isDistributionCenterPanel
                                 ? [PALLET_TYPE_LABELS[pallet.palletName], pallet.palletReturn.sum]
                                 : [
                                      PALLET_TYPE_LABELS[pallet.palletName],
                                      pallet.availableForCollection.sum,
                                      pallet.availableForCollection.valid,
                                      pallet.availableForCollection.invalid,
                                      pallet.palletReturn.sum,
                                      pallet.palletReturn.valid,
                                      pallet.palletReturn.invalid,
                                   ]
                           }
                           onClick={() => toggleTableExtend(pallet.palletName)}
                        />
                     ))}
                  </StyledTable>
               </div>
            </>
            {!isExtend ? (
               <div className={styles.innerContainerSendPallets}>
                  <div className={styles.sendingContainerEmpty}>
                     <PalletsStackBoldIcon />
                     <div>Wybierz rodzaj palety aby zobaczyć szczegóły.</div>
                  </div>
               </div>
            ) : (
               distributionCenterOrders
            )}
         </WhiteCard>
      </>
   );
};

export default DistributionCenterPallets;
