import { merge } from 'lodash';

import {
   EMPTY_INPUT_ERROR,
   NO_NUMERIC_VALUE_ERROR,
   NO_SELECTED_CLIENT_ERROR,
   NO_SELECTED_DELIVERY_DAY_ERROR,
   NO_SELECTED_DELIVERY_ERROR,
   NO_SELECTED_STORAGE_ERROR,
   NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR,
} from 'constants/validationErrors';
import { PalletDataWithPrice } from 'interfaces';
import { PalletData, PlaceOrderByClientFormValues, SupplierPlaceOrderData } from 'interfaces';

import { isInteger, isNonEmpty } from './validationFunctions';

export const placeOrderByClientFormValidations = [
   ({ address }: PlaceOrderByClientFormValues) => {
      if (!address) {
         return { address: NO_SELECTED_DELIVERY_ERROR };
      }
   },
   ({ palletDeliveryDate }: PlaceOrderByClientFormValues) => {
      return (
         !isNonEmpty(palletDeliveryDate) && {
            palletDeliveryDate: NO_SELECTED_DELIVERY_DAY_ERROR,
         }
      );
   },
   ({ pallets }: PlaceOrderByClientFormValues) => {
      if (pallets!.length === 0) {
         return { pallets: EMPTY_INPUT_ERROR };
      } else {
         const palletsErrors = pallets!.map((pallet: PalletData) => {
            if (!isInteger(pallet.amount)) {
               return { pallets: { [pallet.name]: NO_NUMERIC_VALUE_ERROR } };
            }
            if (parseInt(pallet.amount) === 0) {
               return { pallets: { [pallet.name]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } };
            }
         });
         return merge({}, ...palletsErrors);
      }
   },
];

export const placeRegularOrderBySupplierFormValidations = [
   ({ client }: SupplierPlaceOrderData) => {
      if (!client) {
         return { client: NO_SELECTED_CLIENT_ERROR };
      }
   },
   ({ address }: SupplierPlaceOrderData) => {
      if (!address) {
         return { address: NO_SELECTED_DELIVERY_ERROR };
      }
   },
   ({ palletDeliveryDate }: SupplierPlaceOrderData) => {
      return (
         !isNonEmpty(palletDeliveryDate) && {
            palletDeliveryDate: NO_SELECTED_DELIVERY_DAY_ERROR,
         }
      );
   },
   ({ pallets }: SupplierPlaceOrderData) => {
      if (pallets!.length === 0) {
         return { pallets: EMPTY_INPUT_ERROR };
      } else {
         const palletsErrors = pallets!.map((pallet: PalletDataWithPrice) => {
            if (!isInteger(pallet.amount)) {
               return { pallets: { [pallet.name]: NO_NUMERIC_VALUE_ERROR } };
            }
            if (parseInt(pallet.amount) === 0) {
               return { pallets: { [pallet.name]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } };
            }
         });
         return merge({}, ...palletsErrors);
      }
   },
   ({ storage }: SupplierPlaceOrderData) => {
      if (!storage) {
         return { storage: NO_SELECTED_STORAGE_ERROR };
      }
   },
];

export const placeAddedToNextOrderBySupplierFormValidations = [
   ({ client }: SupplierPlaceOrderData) => {
      if (!client) {
         return { client: NO_SELECTED_CLIENT_ERROR };
      }
   },
   ({ pallets }: SupplierPlaceOrderData) => {
      if (pallets!.length === 0) {
         return { pallets: EMPTY_INPUT_ERROR };
      } else {
         const palletsErrors = pallets!.map((pallet: PalletDataWithPrice) => {
            if (!isInteger(pallet.amount)) {
               return { pallets: { [pallet.name]: NO_NUMERIC_VALUE_ERROR } };
            }
            if (parseInt(pallet.amount) === 0) {
               return { pallets: { [pallet.name]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } };
            }
         });
         return merge({}, ...palletsErrors);
      }
   },
];

export const editRegularOrderFormValidations = [
   ({ address }: SupplierPlaceOrderData) => {
      if (!address) {
         return { address: NO_SELECTED_DELIVERY_ERROR };
      }
   },
   ({ palletDeliveryDate }: SupplierPlaceOrderData) => {
      return (
         !isNonEmpty(palletDeliveryDate) && {
            palletDeliveryDate: NO_SELECTED_DELIVERY_DAY_ERROR,
         }
      );
   },
   ({ pallets }: SupplierPlaceOrderData) => {
      if (pallets!.length === 0) {
         return { pallets: EMPTY_INPUT_ERROR };
      } else {
         const palletsErrors = pallets!.map((pallet: PalletDataWithPrice) => {
            if (!isInteger(pallet.amount)) {
               return { pallets: { [pallet.name]: NO_NUMERIC_VALUE_ERROR } };
            }
            if (parseInt(pallet.amount) === 0) {
               return { pallets: { [pallet.name]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } };
            }
         });
         return merge({}, ...palletsErrors);
      }
   },
   ({ storage }: SupplierPlaceOrderData) => {
      if (!storage) {
         return { storage: NO_SELECTED_STORAGE_ERROR };
      }
   },
];

export const editAddedToNextOrderFormValidations = [
   ({ pallets }: SupplierPlaceOrderData) => {
      if (pallets!.length === 0) {
         return { pallets: EMPTY_INPUT_ERROR };
      } else {
         const palletsErrors = pallets!.map((pallet: PalletDataWithPrice) => {
            if (!isInteger(pallet.amount)) {
               return { pallets: { [pallet.name]: NO_NUMERIC_VALUE_ERROR } };
            }
            if (parseInt(pallet.amount) === 0) {
               return { pallets: { [pallet.name]: NUMERIC_VALUE_LESS_OR_EQUAL_ZERO_ERROR } };
            }
         });
         return merge({}, ...palletsErrors);
      }
   },
];
