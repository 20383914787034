import React, { useCallback, useEffect } from 'react';

import { CustomModal } from 'components/shared';
import { EditClientBalanceTable } from 'components/Storages/index';
import { PALLET_TYPES } from 'constants/palletTypes';
import { useTableForm } from 'hooks';
import { ClientUserDTO, editPalletBalance, PalletBalance } from 'interfaces';
import { Banner } from 'storybook';
import { getKeys } from 'utils/functions';
import { editUserBalance } from 'utils/validation/editUserBalance';

import styles from './EditClientBalanceModal.module.css';

const initialPalletBalances = (
   client: ClientUserDTO,
   rentedPalletsBalance: PalletBalance,
   damagedByClientPallets: PalletBalance,
   lostOrDestroyedByClientPallets: PalletBalance,
   damagedOrLostBySupplierPallets: PalletBalance,
): editPalletBalance[] =>
   getKeys(PALLET_TYPES).map(palletName => ({
      palletName,
      isAvailableForUser: client.availablePalletTypes.includes(palletName),
      rented: rentedPalletsBalance[palletName]?.toString(),
      lostOrDestroyedByUserFault: lostOrDestroyedByClientPallets[palletName]?.toString(),
      damagedByUserFault: damagedByClientPallets[palletName]?.toString(),
      receivedDamaged: damagedOrLostBySupplierPallets[palletName]?.toString(),
   }));

type EditClientBalanceModalProps = {
   onClose: () => void;
   onSubmit: (palletBalances: editPalletBalance[]) => void;
   isOpen: boolean;
   client: ClientUserDTO;
   rentedPalletsBalance: PalletBalance;
   damagedByClientPallets: PalletBalance;
   lostOrDestroyedByClientPallets: PalletBalance;
   damagedOrLostBySupplierPallets: PalletBalance;
};

const EditClientBalanceModal = ({
   onClose,
   onSubmit,
   isOpen,
   client,
   rentedPalletsBalance,
   damagedByClientPallets,
   lostOrDestroyedByClientPallets,
   damagedOrLostBySupplierPallets,
}: EditClientBalanceModalProps) => {
   const { rows, errors, touched, handleChangeValue, handleSubmitForm, setRows, hasErrors } =
      useTableForm<editPalletBalance>({
         initialState: initialPalletBalances(
            client,
            rentedPalletsBalance,
            damagedByClientPallets,
            lostOrDestroyedByClientPallets,
            damagedOrLostBySupplierPallets,
         ),
         onSubmit: onSubmit,
         validations: editUserBalance,
      });

   const clearRows = useCallback(() => {
      setRows(
         initialPalletBalances(
            client,
            rentedPalletsBalance,
            damagedByClientPallets,
            lostOrDestroyedByClientPallets,
            damagedOrLostBySupplierPallets,
         ),
      );
   }, [
      client,
      damagedByClientPallets,
      damagedOrLostBySupplierPallets,
      lostOrDestroyedByClientPallets,
      rentedPalletsBalance,
      setRows,
   ]);

   useEffect(() => {
      clearRows();
   }, [
      clearRows,
      rentedPalletsBalance,
      damagedByClientPallets,
      lostOrDestroyedByClientPallets,
      damagedOrLostBySupplierPallets,
   ]);

   return (
      <CustomModal
         size={1264}
         onClose={() => {
            clearRows();
            onClose();
         }}
         opened={isOpen}
         primaryButtonProps={{ text: 'Edytuj saldo klienta', disabled: hasErrors }}
         secondaryButtonProps={{
            text: 'Anuluj',
            onClick: () => {
               clearRows();
               onClose();
            },
         }}
         title="Edytuj saldo"
         innerTitle={`Edytujesz saldo ${client.companyName} ${client.address}, ${client.zipCode} ${client.city}`}
         onSubmit={handleSubmitForm}
         modalContentClassName={styles.modalContent}
         className={styles.container}
      >
         <EditClientBalanceTable
            rows={rows}
            errors={errors}
            touched={touched}
            handleChangeValue={handleChangeValue}
         />
         {hasErrors && (
            <Banner
               className={styles.errorBanner}
               variant="error"
               children="Wartości muszą być większe lub równe 0"
               fullWidth
            />
         )}
      </CustomModal>
   );
};

export default EditClientBalanceModal;
