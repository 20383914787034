import { useMemo, useState } from 'react';

import DistributionCenterTable from 'components/DistributionCenterTable/DistributionCenterTable';
import { CustomModal } from 'components/shared';
import { useTableForm } from 'hooks';
import { DistributionCenter, TransferPalletBalance } from 'interfaces';
import {
   checkIfDefinedSomePalletsToPickUp,
   getDefaultTransferPalletsValues,
} from 'utils/functions';
import { transferPalletsValidations } from 'utils/validation/transferPalletsDistributionCenter';

import styles from './TransferPalletsDistributionCenterModal.module.css';

type TransferPalletsDistributionCenterModalProps = {
   setTransferPallets: (state: boolean) => void;
   isOpened: boolean;
   mode: string;
   distributionCenter: DistributionCenter;
   isLoading: boolean;
   onSubmit: (
      balance: TransferPalletBalance[],
      commercialNetworkId: string,
      setError: (value: string) => void,
   ) => void;
};

const TransferPalletsDistributionCenterModal = ({
   isOpened,
   mode,
   distributionCenter,
   setTransferPallets,
   onSubmit,
   isLoading,
}: TransferPalletsDistributionCenterModalProps) => {
   const [errorInfo, setErrorInfo] = useState<string>('');
   const admissionSubmit = (values: TransferPalletBalance[]) => {
      onSubmit(values, distributionCenter.commercialNetworkId!, setErrorInfo);
   };

   const {
      rows,
      errors,
      touched,
      handleChangeValue,
      handleSubmitForm,
      setRows,
      hasErrors,
      commonErrorsOfTouchedInputs,
   } = useTableForm<TransferPalletBalance>({
      initialState: getDefaultTransferPalletsValues(),
      onSubmit: admissionSubmit,
      validations: transferPalletsValidations,
   });

   const areDefinedSomePalletsToPickUp = useMemo(
      () => checkIfDefinedSomePalletsToPickUp(rows),
      [rows],
   );

   const isInvalidForm = hasErrors || !areDefinedSomePalletsToPickUp;

   return (
      <>
         <CustomModal
            className={styles.container}
            modalContentClassName={styles.modalContent}
            size={798}
            opened={isOpened}
            primaryButtonProps={{ text: 'Przerzuć do odbioru', disabled: isInvalidForm }}
            secondaryButtonProps={{
               text: 'Anuluj',
               onClick: () => {
                  setTransferPallets(false);
                  setRows(getDefaultTransferPalletsValues());
               },
            }}
            onClose={() => {
               setTransferPallets(false);
               setRows(getDefaultTransferPalletsValues());
            }}
            title="Przerzuć do odbioru"
            innerTitle="W tym miejscu przerzucasz palety ze statusem „Zwroty” na status „Do odbioru”. "
            isLoading={isLoading}
            onSubmit={handleSubmitForm}
            error={errorInfo}
         >
            <div className={styles.modalContent}>
               <DistributionCenterTable
                  mode={mode}
                  handleChangeValue={handleChangeValue}
                  rows={rows}
                  errors={errors}
                  touched={touched}
                  isVisibleErrorBanner={!!commonErrorsOfTouchedInputs.size}
               />
            </div>
         </CustomModal>
      </>
   );
};

export default TransferPalletsDistributionCenterModal;
