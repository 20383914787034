import { UserRole } from 'interfaces';
import { Banner } from 'storybook';
import { getDayLabel } from 'utils/functions';

import styles from './RetentionDateExpiredBanner.module.css';

type RetentionDateExpiredBannerProps = {
   role: Extract<UserRole, 'ROLE_SYSTEM_ADMIN' | 'ROLE_CLIENT'>;
   delayTimeInDaysFromRetentionDate: number;
};
const RetentionDateExpiredBanner = ({
   role,
   delayTimeInDaysFromRetentionDate,
}: RetentionDateExpiredBannerProps) => {
   const roleBasedText = role === 'ROLE_CLIENT' ? 'Twoje zamówienie' : 'Zamówienie';
   const dayLabel = getDayLabel(delayTimeInDaysFromRetentionDate);

   return (
      <Banner
         variant="error"
         children={`${roleBasedText} przekroczyło datę przetrzymania o: ${delayTimeInDaysFromRetentionDate} ${dayLabel}.`}
         fullWidth
         className={styles.banner}
      />
   );
};

export default RetentionDateExpiredBanner;
