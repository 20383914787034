import { SendPalletsItem } from 'interfaces';

import { vglClientSendPalletsItemToSendPalletsItemMapper } from './mappers';
import { VGLClientExcelRow } from './types';
import { getGroupedVGLClientSendPalletsItems } from './utils';

export const handleVglClientSendPalletsDataFromExcel = (excelRowsData: VGLClientExcelRow[]) => {
   const groupedData = getGroupedVGLClientSendPalletsItems(excelRowsData);
   const sendPalletsItemsData: SendPalletsItem[] = groupedData.map(
      vglClientSendPalletsItemToSendPalletsItemMapper,
   );
   return sendPalletsItemsData;
};
