import { LostDamagedPalletsByClientItem } from 'interfaces';
import { currencyFormatter } from 'utils/numberFormatter';

import styles from '../AdditionalFeeWarning.module.css';

type AdditionalFeeListItemProps = {
   element: LostDamagedPalletsByClientItem;
};

const AdditionalFeeListItem = ({ element }: AdditionalFeeListItemProps) => {
   const totalAdditionalFee = currencyFormatter.format(
      +element.palletsAmount * +element.reportPrice!,
   );

   return (
      <li className={styles.additionalFeeListItem}>
         {`${element.palletType.label}: ${currencyFormatter.format(element.reportPrice!)} PLN x ${
            element.palletsAmount
         } szt. = ${totalAdditionalFee} PLN`}
      </li>
   );
};

export default AdditionalFeeListItem;
