import { InfoGrayIcon } from 'assets';
import { palletTypesData } from 'constants/palletTypes';

import styles from './OrderTerms.module.css';

const OrderTerms = () => {
   return (
      <div className={styles.container}>
         <div className={styles.header}>
            <InfoGrayIcon />
            <h3>Warunki zamówienia</h3>
         </div>
         <table className={styles.table}>
            <thead>
               <tr>
                  <th>Typ palety</th>
                  <th>Minimum logistyczne</th>
               </tr>
            </thead>
            <tbody>
               {palletTypesData.map((palletType, index) => (
                  <tr key={index}>
                     <td>{palletType.label + ' ' + palletType.dimensions}</td>
                     <td>
                        {palletType.logisticMinimum}
                        {palletType.logisticMinimumUpperRange &&
                           ' do ' + palletType.logisticMinimumUpperRange}{' '}
                        palet
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
};
export default OrderTerms;
