import classNames from 'classnames';
import { Rotate2 } from 'tabler-icons-react';

import { StyledTextInput } from 'components/shared';
import { TextInputProps } from 'storybook';

import styles from './TextInputWithChangedState.module.css';

type TextInputWithChangedStateProps = TextInputProps & {
   isValueChanged?: boolean;
   iconPosition?: 'left' | 'right';
};

const TextInputWithChangedState = ({
   isValueChanged,
   iconPosition = 'right',
   className,
   ...restProps
}: TextInputWithChangedStateProps) => (
   <StyledTextInput
      className={classNames({ [styles.changedInput]: isValueChanged }, className)}
      leftIcon={iconPosition === 'left' && isValueChanged && <Rotate2 />}
      rightIcon={iconPosition === 'right' && isValueChanged && <Rotate2 />}
      {...restProps}
   />
);

export default TextInputWithChangedState;
