import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { StyledButton } from 'components/shared';

import NoItemsNotification from '../../NoItemsNotification';

import styles from './NoItemsNotificationWithLink.module.css';

type NoItemsNotificationWithLinkProps = {
   icon: ReactNode;
   desctiption: string;
   linkText?: string;
   redirectTo?: string;
   isLinkDisabled?: boolean;
   onClick?: () => void;
};

const NoItemsNotificationWithLink = ({
   icon,
   desctiption,
   linkText,
   redirectTo,
   isLinkDisabled,
   onClick,
}: NoItemsNotificationWithLinkProps) => (
   <NoItemsNotification
      icon={icon}
      description={desctiption}
      button={
         onClick ? (
            <StyledButton
               type="button"
               text={linkText}
               variant="outlined-primary"
               className={styles.btn}
               onClick={onClick}
            />
         ) : redirectTo ? (
            <Link to={redirectTo}>
               <StyledButton
                  type="button"
                  text={linkText}
                  variant="outlined-primary"
                  className={styles.btn}
                  disabled={isLinkDisabled}
               />
            </Link>
         ) : null
      }
      withMobileBtnArrow
   />
);

export default NoItemsNotificationWithLink;
