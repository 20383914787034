import { ChangeEvent, memo } from 'react';

import classNames from 'classnames';

import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { StoragePalletsStates } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import { StyledTextInput } from '../shared';

import styles from './StorageTableItem.module.css';

type StorageTableItem = {
   internal?: boolean;
   name: string;
   readyToRent: number;
   damaged: number;
   lost: number;
   handleValueChange?: (name: string, type: StoragePalletsStates, value: string) => void;
};

const StorageTableItem = ({
   name,
   readyToRent,
   damaged,
   lost,
   internal,
   handleValueChange,
}: StorageTableItem) => {
   const firstCell =
      PALLET_TYPES_LABELS_WITH_DIMENSIONS[name as keyof typeof PALLET_TYPES_LABELS_WITH_DIMENSIONS];
   const classNameForCell = (value: number) => classNames({ [styles.negative]: value < 0 });

   const renderCell = (value: number, type: string) =>
      handleValueChange ? (
         <div className={styles.inputContainer}>
            <StyledTextInput
               type="text"
               value={value}
               className={styles.input}
               onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleValueChange(name, type as StoragePalletsStates, event.target.value)
               }
            />
         </div>
      ) : (
         value
      );

   return (
      <TableRow className={styles.tableRow}>
         <TableCell>
            <div className={styles.nameCell}>
               <p className={styles.paletteType}>{firstCell.label}</p>
               <p className={styles.paletteDimensions}>{firstCell.dimensions}</p>
            </div>
         </TableCell>
         <TableCell align="right" className={classNameForCell(readyToRent)}>
            {renderCell(readyToRent, 'readyToRent')}
         </TableCell>
         <TableCell align="right" className={classNameForCell(damaged)}>
            {renderCell(damaged, 'damaged')}
         </TableCell>
         {internal && (
            <TableCell align="right" className={classNameForCell(lost)}>
               {renderCell(lost, 'lost')}
            </TableCell>
         )}
      </TableRow>
   );
};

export default memo(StorageTableItem);
