import { AdditionalOrder } from 'interfaces';
import { Checkbox } from 'storybook';

import AdditionalPalletsList from './AdditionalPalletsList/AdditionalPalletsList';
import styles from './AdditionalOrdersList.module.css';

type AdditionalOrdersListProps = {
   additionalOrders: AdditionalOrder[];
   selectedAdditionalOrderIds: string[];
   isAnyAdditionalOrderTouched: boolean;
   isFormInvalid: boolean;
   onSelectAdditionalOrder: (orderId: string) => void;
};

const AdditionalOrdersList = ({
   additionalOrders,
   selectedAdditionalOrderIds,
   isAnyAdditionalOrderTouched,
   isFormInvalid,
   onSelectAdditionalOrder,
}: AdditionalOrdersListProps) => (
   <ul className={styles.additionalOrdersList}>
      {additionalOrders?.map(additionalOrder => {
         const orderId = additionalOrder.orderId;
         return (
            <li key={orderId} className={styles.listItem}>
               <Checkbox
                  label={`Zamówienie #${orderId}`}
                  className={styles.checkbox}
                  checked={selectedAdditionalOrderIds.includes(orderId)}
                  error={isAnyAdditionalOrderTouched && isFormInvalid}
                  onChangeHandler={() => onSelectAdditionalOrder(orderId)}
               />
               <AdditionalPalletsList additionalPallets={additionalOrder.pallets} />
            </li>
         );
      })}
   </ul>
);

export default AdditionalOrdersList;
