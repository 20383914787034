import { useCallback } from 'react';

import { CustomModal, StyledTextInput } from 'components/shared';
import { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';
import { isNonEmpty } from 'utils/validation';

import styles from './BlockClientModal.module.css';

type BlockClientModalProps = CustomModalProps & {
   selectedUserId: string;
   onSuccessBlockClient: (userId: string) => void;
};

const INITIAL_STATE = { comment: '' };

const validations = [
   ({ comment }: { comment: string }) =>
      !isNonEmpty(comment) && { comment: 'To pole jest obowiązkowe' },
];

const BlockClientModal = ({
   selectedUserId,
   onSuccessBlockClient,
   onClose,
   ...props
}: BlockClientModalProps) => {
   const handleBlockClient = () => {
      setIsLoading(true);
      HTTPService.changeUserBlockedStatus(true, values.comment, selectedUserId)
         .then(() => {
            onSuccessBlockClient(selectedUserId);
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   const { values, errorsList, touched, changeHandler, submitHandler, clearForm } = useForm({
      initialState: INITIAL_STATE,
      validations,
      onSubmit: handleBlockClient,
   });

   const handleCloseModal = useCallback(() => {
      clearForm();
      onClose();
   }, [clearForm, onClose]);

   const {
      isLoading,
      errorInfo,
      showCloseWarning,
      setIsLoading,
      setErrorInfo,
      handleHideCloseWarning,
      handleShowCloseWarning,
      closeModalHandler,
   } = useModal({ onClose: handleCloseModal });

   return (
      <CustomModal
         {...props}
         onClose={handleShowCloseWarning}
         icon="BAN"
         title="Zablokuj klienta"
         error={errorInfo}
         leftSideModalContent
         isLoading={isLoading}
         onSubmit={submitHandler}
         primaryButtonProps={{
            text: 'Zablokuj klienta',
            variant: 'filled-danger',
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'outlined-danger',
            onClick: handleShowCloseWarning,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
      >
         <>
            <p className={styles.description}>
               Blokując klienta uniemożliwisz mu składanie zamówień.
               <br />
               Tę akcję można cofnąć w dowolnym momencie.
            </p>
            <StyledTextInput
               type="text"
               name="comment"
               label="Komentarz widoczny dla klienta"
               value={values.comment}
               onChange={changeHandler}
               placeholder="np. Proszę opłacić zamówienie nr #123 i #234"
               error={touched.comment && errorsList.comment}
               helperText={touched.comment && errorsList.comment}
               fullWidth
            />
         </>
      </CustomModal>
   );
};

export default BlockClientModal;
