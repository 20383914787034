import { ReactNode } from 'react';

import classNames from 'classnames';

import { ErrorNotification, PageLoader } from 'components/shared';
import { BannerData } from 'interfaces';
import { Banner } from 'storybook';

import styles from './PageLayout.module.css';

export type PageLayoutProps = {
   children: ReactNode;
   headerElement?: ReactNode;
   bannerData?: BannerData | null;
   isLoading?: boolean;
   didFetchDataErrorOccur?: boolean;
   classNames?: { pageContainer?: string; pageLoader?: string };
   closeBanner?: () => void;
};

const PageLayout = ({
   children,
   headerElement,
   bannerData,
   isLoading,
   didFetchDataErrorOccur,
   classNames: pageLayoutClassNames,
   closeBanner,
}: PageLayoutProps) => {
   const renderContent = () => {
      if (isLoading) {
         return <PageLoader className={pageLayoutClassNames?.pageLoader} />;
      } else if (didFetchDataErrorOccur) {
         return <ErrorNotification />;
      } else {
         return children;
      }
   };

   return (
      <div className={classNames(styles.mainContainer, pageLayoutClassNames?.pageContainer)}>
         {headerElement}
         {bannerData && (
            <Banner
               fullWidth
               className={styles.banner}
               variant={bannerData.variant}
               title={bannerData.title}
               children={bannerData.description}
               onClose={closeBanner}
               withCloseIcon
            />
         )}
         {renderContent()}
      </div>
   );
};

export default PageLayout;
