import { DateTime } from 'luxon';

import { INVALID_DATE_ERROR } from 'constants/validationErrors';
import { TransferActionsOverviewFiltersForm } from 'interfaces';

export const transferActionsOverviewFiltersFormValidation = [
   ({ dateFrom, dateTo }: TransferActionsOverviewFiltersForm) => {
      if (!dateFrom || !dateTo) {
         return false;
      }
      const isDateToBeforeDateFrom = DateTime.fromJSDate(dateTo) < DateTime.fromJSDate(dateFrom);
      if (isDateToBeforeDateFrom) {
         return {
            dateFrom: INVALID_DATE_ERROR,
            dateTo: INVALID_DATE_ERROR,
         };
      }
      return false;
   },
];
