import { groupBy } from 'lodash';

import { PALLET_PRICE_LABELS } from 'constants/palletPriceLabels';
import {
   ClientUserDTO,
   Pallet,
   PalletContractPrices,
   PalletCustomPrices,
   PalletType,
} from 'interfaces';

import { getFullAddress } from './getFullAddress';

export const getPalletPrice = (
   palletName: PalletType,
   palletContractPrices: PalletContractPrices,
   palletCustomPrices: PalletCustomPrices,
   selectedAddress?: string,
) => {
   const palletContractPrice = selectedAddress
      ? palletContractPrices[palletName].deliveryPrice[selectedAddress]
      : palletContractPrices[palletName].personalPickUpPrice;
   const palletCustomPrice = palletCustomPrices[PALLET_PRICE_LABELS[palletName]];
   return palletCustomPrice || palletContractPrice;
};

export const getPalletContractPrices: (user: ClientUserDTO) => PalletContractPrices = user => {
   const commonPalletRates = user.rateConfig!.palletRates.reduce<
      Record<string, { personalPickUpPrice: number }>
   >((allCommonPalletRates, currentCommonPalletRates) => {
      return {
         ...allCommonPalletRates,
         [currentCommonPalletRates.palletType]: {
            personalPickUpPrice: currentCommonPalletRates.personalPickUpPrice,
         },
      };
   }, {});

   const flattenDeliveryAddressesData = user.deliveryAddresses.reduce<
      { palletType: PalletType; deliveryPrice: number; address: string }[]
   >((allFlattenDeliveryAddressesData, currentDeliveryAddress) => {
      const { address, zipCode, city, name } = currentDeliveryAddress.address;

      const fullAddress = getFullAddress(address, zipCode, city, name);
      const palletRatesWithAddress = currentDeliveryAddress.palletConfig.palletRates.map(
         palletRatesItem => ({
            ...palletRatesItem,
            address: fullAddress,
         }),
      );

      return [...allFlattenDeliveryAddressesData, ...palletRatesWithAddress.flat()];
   }, []);
   const deliveryAddressesDataGroupedByPalletType = groupBy(
      flattenDeliveryAddressesData,
      'palletType',
   );

   return Object.keys(commonPalletRates).reduce<PalletContractPrices>(
      (contractPrices, palletType) => {
         return {
            ...contractPrices,
            [palletType]: {
               personalPickUpPrice: commonPalletRates[palletType].personalPickUpPrice,
               deliveryPrice: deliveryAddressesDataGroupedByPalletType[palletType].reduce<
                  Record<string, number>
               >((allDeliveryPriceData, deliveryAddressesDataItem) => {
                  return {
                     ...allDeliveryPriceData,
                     [deliveryAddressesDataItem.address]: deliveryAddressesDataItem.deliveryPrice,
                  };
               }, {}),
            },
         };
      },
      {},
   );
};

export const getPalletCustomPrices = (
   palletContractPrices: PalletContractPrices,
   pallets: Pallet[],
   selectedAddress?: string,
) => {
   const palletCustomPrices: PalletCustomPrices & { [key: string]: number } = {
      edhpPalletPrice: 0,
      euroPalletPrice: 0,
      halfPalletNDHPPrice: 0,
      quarterPalletCDHPPrice: 0,
   };
   pallets.forEach(pallet => {
      if (!palletContractPrices[pallet.palletType]) {
         palletCustomPrices[PALLET_PRICE_LABELS[pallet.palletType]] = pallet.pricePerUnit;
      } else if (
         !selectedAddress &&
         pallet.pricePerUnit &&
         pallet.pricePerUnit !== palletContractPrices[pallet.palletType].personalPickUpPrice
      ) {
         palletCustomPrices[PALLET_PRICE_LABELS[pallet.palletType]] = pallet.pricePerUnit;
      } else if (
         selectedAddress &&
         pallet.pricePerUnit &&
         pallet.pricePerUnit !==
            palletContractPrices[pallet.palletType].deliveryPrice[selectedAddress]
      ) {
         palletCustomPrices[PALLET_PRICE_LABELS[pallet.palletType]] = pallet.pricePerUnit;
      }
   });
   return palletCustomPrices;
};
