import { PalletReportDTO, ReportType } from 'interfaces';

export const getFilteredLostDamagedPalletReports = (
   lostDamagedPalletReports: PalletReportDTO[],
   reportTypeFilter: ReportType,
) => {
   const reportsFilteredByReportType = lostDamagedPalletReports.map(report => {
      const filteredLostDamagedPallets = report.damagedPallets.filter(
         lostDamagedPalletsItem => lostDamagedPalletsItem.reportType === reportTypeFilter,
      );
      return {
         id: report.id,
         status: report.status,
         damagedPallets: filteredLostDamagedPallets,
      };
   });
   return reportsFilteredByReportType.filter(report => !!report.damagedPallets.length);
};
