import { Link } from 'react-router-dom';

import { Eye } from 'tabler-icons-react';

import { SUPPLIER_DISTRIBUTION_CENTER_DETAILS_PATH } from 'constants/routes';
import { DistributionCenterDTO } from 'interfaces';
import { TableCell, TableRow } from 'storybook';

import StyledButton from '../../shared/StyledButton/StyledButton';

import styles from './DistributionCenterTableItem.module.css';

type DistributionCenterTableItemProps = {
   center: DistributionCenterDTO;
};

const DistributionCenterTableItem = ({ center }: DistributionCenterTableItemProps) => (
   <TableRow>
      <TableCell></TableCell>
      <TableCell>
         <p className={styles.addressFirstLine}>{`${center.address.street},`}</p>
         <p
            className={styles.addressSecondLine}
         >{`${center.address.zipCode} ${center.address.city}`}</p>
      </TableCell>
      <TableCell className={styles.bold} align="right">
         {center.todayBalance.balanceSum.availableForCollection.sum}
      </TableCell>
      <TableCell align="right">
         {center.todayBalance.balanceSum.availableForCollection.valid}
      </TableCell>
      <TableCell align="right">
         {center.todayBalance.balanceSum.availableForCollection.invalid}
      </TableCell>
      <TableCell className={styles.bold} align="right">
         {center.todayBalance.balanceSum.palletReturn.sum}
      </TableCell>
      <TableCell align="right">{center.todayBalance.balanceSum.palletReturn.valid}</TableCell>
      <TableCell align="right">{center.todayBalance.balanceSum.palletReturn.invalid}</TableCell>
      <TableCell align="right">
         <Link
            to={SUPPLIER_DISTRIBUTION_CENTER_DETAILS_PATH(center.id)}
            state={{ commercialNetworkId: center.commercialNetworkId }}
         >
            <StyledButton
               type="button"
               text="Szczegóły"
               icon={<Eye />}
               variant="text"
               className={styles.actionBtn}
            />
         </Link>
      </TableCell>
   </TableRow>
);
export default DistributionCenterTableItem;
