import { useEffect } from 'react';

import { DatePicker } from '@mantine/dates';
import { Calendar } from 'tabler-icons-react';

import { FiltersModal, FiltersModalProps } from 'components/shared';
import { useForm } from 'hooks';
import { TransferActionsOverviewFiltersForm } from 'interfaces';
import { transferActionsOverviewFiltersFormValidation } from 'utils/validation';

type DistributionCenterAndStoragePalletsTransferHistoryFiltersFormData = Pick<
   TransferActionsOverviewFiltersForm,
   'dateFrom' | 'dateTo'
>;

type DistributionCenterAndStoragePalletsTransferHistoryFiltersModalProps = Pick<
   FiltersModalProps,
   'opened' | 'onClose'
> & {
   initialFormData: DistributionCenterAndStoragePalletsTransferHistoryFiltersFormData;
   onSubmit: (filters: DistributionCenterAndStoragePalletsTransferHistoryFiltersFormData) => void;
};

const DistributionCenterAndStoragePalletsTransferHistoryFiltersModal = ({
   opened,
   initialFormData,
   onSubmit,
   onClose,
}: DistributionCenterAndStoragePalletsTransferHistoryFiltersModalProps) => {
   const {
      values: filterValues,
      errorsList,
      touched,
      setValues: setFilterValues,
      submitHandler,
   } = useForm({
      initialState: initialFormData,
      validations: transferActionsOverviewFiltersFormValidation,
      onSubmit: () => {
         onSubmit(
            filterValues as DistributionCenterAndStoragePalletsTransferHistoryFiltersFormData,
         );
      },
   });

   useEffect(() => {
      setFilterValues(initialFormData);
   }, [initialFormData, setFilterValues]);

   const { dateFrom: selectedDateFrom, dateTo: selectedDateTo } =
      filterValues as DistributionCenterAndStoragePalletsTransferHistoryFiltersFormData;

   const handleChangeDateFrom = (newDate: Date | null) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         dateFrom: newDate,
      }));
   };

   const handleChangeDateTo = (newDate: Date | null) => {
      setFilterValues(prevFilterValues => ({
         ...prevFilterValues,
         dateTo: newDate,
      }));
   };

   return (
      <FiltersModal opened={opened} onSubmit={submitHandler} onClose={onClose}>
         <FiltersModal.CombinedRow>
            <DatePicker
               label="Data akcji od"
               value={selectedDateFrom}
               inputFormat="DD/MM/YYYY"
               icon={<Calendar color="#3E3E4B" size="20" />}
               locale="pl"
               error={touched.dateFrom && errorsList.dateFrom}
               onChange={handleChangeDateFrom}
            />
            <DatePicker
               label="Data akcji do"
               value={selectedDateTo}
               inputFormat="DD/MM/YYYY"
               icon={<Calendar color="#3E3E4B" size="20" />}
               locale="pl"
               error={touched.dateTo && errorsList.dateTo}
               onChange={handleChangeDateTo}
            />
         </FiltersModal.CombinedRow>
      </FiltersModal>
   );
};

export default DistributionCenterAndStoragePalletsTransferHistoryFiltersModal;
