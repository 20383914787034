import { DatePicker } from '@mantine/dates';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Calendar } from 'tabler-icons-react';

import { SectionContainer } from 'components/shared';
import { datePickerDayClassName } from 'utils/datePickerDayClassName';
import { getDayLabel } from 'utils/functions';
import { calculateMinDate } from 'utils/functions/calculateMinDate';

import styles from './EditRentalPeriodSection.module.css';

type EditRentalPeriodSectionProps = {
   isSupplierMode?: boolean;
   deliveryDate: Date | null;
   holdingPeriod: Date | null;
   retentionPeriodDays?: number;
   onSelectChange: (selectValue: Date | null, field: string) => void;
   error?: string;
   touched?: boolean;
   onSave?: () => void;
   onCancel?: () => void;
   isLoading?: boolean;
};

const EditRentalPeriodSection = ({
   isSupplierMode,
   deliveryDate,
   holdingPeriod,
   retentionPeriodDays,
   onSelectChange,
   error,
   touched,
   onSave,
   onCancel,
   isLoading,
}: EditRentalPeriodSectionProps) => (
   <SectionContainer
      title="Okres wynajmu"
      onSave={onSave}
      onCancel={onCancel}
      isLoading={isLoading}
   >
      <div className={styles.datesContainer}>
         <div className={styles.datePickerContainer}>
            <DatePicker
               value={deliveryDate}
               onChange={value => onSelectChange(value, 'palletDeliveryDate')}
               inputFormat="DD/MM/YYYY"
               classNames={{
                  input: classNames(styles.dateInput, {
                     [styles.errorInput]: error && touched,
                  }),
                  label: styles.dateInputLabel,
                  day: styles.datePickerDay,
               }}
               label="Dzień dostarczenia palet"
               icon={<Calendar color="#3E3E4B" />}
               minDate={!isSupplierMode ? calculateMinDate() : undefined}
               excludeDate={date => date.getDay() === 0 || date.getDay() === 6}
               locale="pl"
               dayClassName={datePickerDayClassName}
            />
            {error && touched && <p>{error}</p>}
         </div>
         <div className={styles.dateInfo}>
            <p>Termin przetrzymania</p>
            <div>
               <Calendar color="#3E3E4B" />
               {`${
                  holdingPeriod ? DateTime.fromJSDate(holdingPeriod).toFormat('dd/LL/yyyy') : ''
               } (${retentionPeriodDays || '-'} ${
                  retentionPeriodDays ? getDayLabel(retentionPeriodDays) : 'dni'
               })`}
            </div>
         </div>
      </div>
   </SectionContainer>
);

export default EditRentalPeriodSection;
