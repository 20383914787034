import { OrderSummaryAndOrderDetailsPallet } from 'interfaces';
import { isOrderDetailsPallet } from 'utils/typeGuards';

import FreePalletAmountInfo from './FreePalletAmountInfo/FreePalletAmountInfo';
import PalletAmountAsLogisticMinimumInfo from './PalletAmountAsLogisticMinimumInfo/PalletAmountAsLogisticMinimumInfo';
import PalletTotalAmountInfo from './PalletTotalAmountInfo/PalletTotalAmountInfo';
import RegularPalletAmountInfo from './RegularPalletAmountInfo/RegularPalletAmountInfo';
import styles from './PalletAmountSection.module.css';

type PalletAmountSectionProps = {
   pallet: OrderSummaryAndOrderDetailsPallet;
   isClientView?: boolean;
};

const PalletAmountSection = ({ pallet, isClientView }: PalletAmountSectionProps) => {
   const isOrderDetailsPalletMode = isOrderDetailsPallet(pallet);
   const isSomeFreePalletExists = isOrderDetailsPalletMode && !!pallet.freePalletsAmount;
   const isPalletAmountAsLogisticMinimum =
      ((isOrderDetailsPalletMode && pallet.shownAsMultipleOfTheMinimum) ||
         (!isOrderDetailsPalletMode && pallet.orderType !== 'CUSTOM')) &&
      !!pallet.amount;

   return (
      <div className={styles.container}>
         {!isPalletAmountAsLogisticMinimum && isSomeFreePalletExists && (
            <RegularPalletAmountInfo pallet={pallet} />
         )}
         {isPalletAmountAsLogisticMinimum && <PalletAmountAsLogisticMinimumInfo pallet={pallet} />}
         {isSomeFreePalletExists && (
            <FreePalletAmountInfo pallet={pallet} isClientView={isClientView} />
         )}
         <PalletTotalAmountInfo pallet={pallet} />
      </div>
   );
};

export default PalletAmountSection;
