import React, { MouseEvent as ReactMouseEvent } from 'react';

import classNames from 'classnames';

import styles from './RadioButton.module.css';

export interface RadioButtonProps {
   id?: string;
   name?: string;
   checked: boolean;
   label?: string;
   onChangeHandler: (event: ReactMouseEvent) => void;
   error?: boolean;
   className?: string;
   disabled?: boolean;
   buttonView?: boolean;
}

export const RadioButton = ({
   id,
   name,
   checked,
   label,
   onChangeHandler,
   error,
   className,
   disabled,
   buttonView,
}: RadioButtonProps) => {
   return (
      <div
         onClick={!disabled ? onChangeHandler : undefined}
         className={classNames(styles.radioButton, className, {
            [styles.error]: error,
            [styles.disabled]: disabled,
            [styles.button]: buttonView,
            [styles.selected]: buttonView && checked,
         })}
      >
         <input readOnly disabled={disabled} id={id} name={name} checked={checked} type="radio" />
         <label>{label}</label>
      </div>
   );
};
