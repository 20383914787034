import { uniqueId } from 'lodash';

import {
   AdditionalCharge,
   AdditionalChargeErrors,
   AdditionalChargeTouched,
} from 'interfaces/addAdditionalChargesForm';

import { ADDITIONAL_CHARGE_PREFIX } from './uniqueIdPrefixes';

export const DEFAULT_ADDITIONAL_CHARGE: () => AdditionalCharge = () => ({
   uuid: uniqueId(ADDITIONAL_CHARGE_PREFIX),
   chargeType: undefined,
   palletType: undefined,
   palletsAmount: undefined,
   address: undefined,
   otherChargeName: undefined,
   amount: undefined,
});

export const DEFAULT_ADDITIONAL_CHARGE_ERRORS: () => AdditionalChargeErrors = () => ({
   chargeType: 'Zaznacz rodzaj obciążenia',
   palletType: false,
   palletsAmount: false,
   address: false,
   otherChargeName: false,
   amount: false,
});

export const OTHER_TYPE_CHARGE_ERRORS: () => AdditionalChargeErrors = () => ({
   chargeType: false,
   palletType: false,
   palletsAmount: false,
   address: false,
   otherChargeName: 'Pole nie może być puste',
   amount: 'Pole nie może być puste',
});

export const DAMAGED_LOST_CHARGE_ERRORS: () => AdditionalChargeErrors = () => ({
   chargeType: false,
   palletType: 'Pole nie może być puste',
   palletsAmount: 'Pole nie może być puste',
   address: 'Pole nie może być puste',
   otherChargeName: false,
   amount: false,
});

export const DEFAULT_ADDITIONAL_CHARGE_TOUCHED: () => AdditionalChargeTouched = () => ({
   chargeType: false,
   palletType: false,
   palletsAmount: false,
   address: false,
   otherChargeName: false,
   amount: false,
});
