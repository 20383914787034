import { useMemo } from 'react';

import {
   PalletTypeCard,
   SectionContainer,
   SharedGridTable,
   SharedGridTableCell,
   SharedGridTableRow,
} from 'components/shared';
import { palletTypesData } from 'constants/palletTypes';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import { ClientUserDTO } from 'interfaces';
import { getDayLabel } from 'utils/functions';
import { currencyFormatter } from 'utils/numberFormatter';

import { rentPeriodHeaders } from '../../ClientForm/ClientFormSteps/RatesConfigurationForm/RentPeriod/RentPeriod';

import PalletsPricing from './PalletsPricing/PalletsPricing';
import styles from './ClientDetailsPallets.module.css';

export const ClientDetailsPallets = (client: ClientUserDTO) => {
   const { rateConfig, deliveryAddresses } = client;

   const delaysChargesHeaders = useMemo(
      () => [
         'Długość opóźnienia',
         ...(client.rateConfig
            ? client.rateConfig.delaysCharges.map(delayCharge => {
                 return (
                    <div className={styles.columnHeader}>
                       <h3>{PALLET_TYPES_LABELS_WITH_DIMENSIONS[delayCharge.palletType].label}</h3>
                       <p>
                          {PALLET_TYPES_LABELS_WITH_DIMENSIONS[delayCharge.palletType].dimensions}
                       </p>
                    </div>
                 );
              })
            : []),
      ],
      [client.rateConfig],
   );
   return (
      <>
         <SectionContainer style={{ marginTop: 24 }} title="Rodzaje palet">
            <div className={styles.cardsContainer}>
               {palletTypesData.map(palletType => (
                  <PalletTypeCard
                     key={palletType.name}
                     palletType={palletType}
                     disabled={
                        !client.availablePalletTypes.some(
                           availablePalletType => availablePalletType === palletType.name,
                        )
                     }
                  />
               ))}
            </div>
         </SectionContainer>
         <SectionContainer title="Konfiguracja stawek">
            <PalletsPricing
               commonPalletsPricing={rateConfig?.palletRates || []}
               palletsPricingPerAddresses={deliveryAddresses}
            />
            <SectionContainer innerSection title="Okres wynajmu">
               <div className={styles.rentPeriodContainer}>
                  <p>Okres wynajmu (dni)</p>
                  <p>
                     {client.rateConfig?.retentionConfig.retentionPeriodDays}{' '}
                     {client.rateConfig &&
                        getDayLabel(client.rateConfig.retentionConfig.retentionPeriodDays)}
                  </p>
               </div>
               <SharedGridTable
                  headers={rentPeriodHeaders}
                  rows={[
                     client.rateConfig?.retentionConfig.retentionPalletCosts.map(
                        (retentionPalletCost, index) => (
                           <SharedGridTableRow key={index}>
                              <SharedGridTableCell>
                                 <h3>
                                    {
                                       PALLET_TYPES_LABELS_WITH_DIMENSIONS[
                                          retentionPalletCost.palletType
                                       ].label
                                    }
                                 </h3>
                                 <p>
                                    {
                                       PALLET_TYPES_LABELS_WITH_DIMENSIONS[
                                          retentionPalletCost.palletType
                                       ].dimensions
                                    }
                                 </p>
                              </SharedGridTableCell>
                              <SharedGridTableCell>
                                 {currencyFormatter.format(retentionPalletCost.price)}
                              </SharedGridTableCell>
                           </SharedGridTableRow>
                        ),
                     ),
                  ]}
               />
            </SectionContainer>
            <SectionContainer
               innerSection
               title="Opłaty"
               subtitle="W przypadku opóźnienia w przekazaniu informacji, naliczane za 1 szt. palety."
            >
               <SharedGridTable
                  headers={delaysChargesHeaders}
                  rows={[
                     <SharedGridTableRow>
                        <SharedGridTableCell>
                           <p className={styles.rowHeader}>Do 21 dni</p>
                        </SharedGridTableCell>
                        {client.rateConfig?.delaysCharges.map((delayCharge, index) => {
                           return (
                              <SharedGridTableCell key={index}>
                                 {currencyFormatter.format(delayCharge.upTo21Days)}
                              </SharedGridTableCell>
                           );
                        })}
                     </SharedGridTableRow>,
                     <SharedGridTableRow>
                        <SharedGridTableCell>
                           <p className={styles.rowHeader}>Do 30 dni</p>
                        </SharedGridTableCell>
                        {client.rateConfig?.delaysCharges.map((delayCharge, index) => {
                           return (
                              <SharedGridTableCell key={index}>
                                 {currencyFormatter.format(delayCharge.upTo30Days)}
                              </SharedGridTableCell>
                           );
                        })}
                     </SharedGridTableRow>,
                     <SharedGridTableRow>
                        <SharedGridTableCell>
                           <p className={styles.rowHeader}>Powyżej 30 dni</p>
                        </SharedGridTableCell>
                        {client.rateConfig?.delaysCharges.map((delayCharge, index) => {
                           return (
                              <SharedGridTableCell key={index}>
                                 {currencyFormatter.format(delayCharge.over30Days)}
                              </SharedGridTableCell>
                           );
                        })}
                     </SharedGridTableRow>,
                  ]}
               />
            </SectionContainer>
         </SectionContainer>
      </>
   );
};
