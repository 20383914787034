import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';

import { Logo } from 'components/shared';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import StyledTextInput from 'components/shared/StyledTextInput/StyledTextInput';
import { INVALID_EMAIL, USER_NOT_FOUND, WRONG_PASSWORD } from 'constants/errorCodes';
import { FORGOT_PASSWORD } from 'constants/routes';
import { useAuth } from 'context/auth/AuthContextProvider';
import { useForm } from 'hooks/useForm';
import { UserRole } from 'interfaces';
import { HTTPService } from 'service';
import { Banner } from 'storybook';
import { getDefaultUserPageByRole } from 'utils/functions';
import { loginFormValidations } from 'utils/validation/loginFormValidation';

import styles from './LoginForm.module.css';

const invalidCredentialsErrors = [INVALID_EMAIL, USER_NOT_FOUND, WRONG_PASSWORD];

const SOMETHING_WENT_WRONG_ERROR_MESSAGE =
   'Coś poszło nie tak. Odczekaj chwilę i spróbuj jeszcze raz.';

const LoginForm = () => {
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const location = useLocation();
   const { signIn, user, setUser } = useAuth();
   const initialState = { email: '', password: '' };
   const [bannerMessage, setBannerMessage] = useState('');
   const [bannerType, setBannerType] = useState<'error' | 'success'>('error');

   useEffect(() => {
      const { successPasswordReset } = (location.state || {}) as { successPasswordReset: boolean };
      if (successPasswordReset) {
         setBannerType('success');
         setBannerMessage('Twoje hasło zostało zmienione. Możesz się zalogować.');
      }
   }, [location.state]);

   const navigateToDefaultPage = useCallback(
      (userRole: UserRole) => navigate(getDefaultUserPageByRole(userRole), { replace: true }),
      [navigate],
   );

   useEffect(() => {
      if (!user) {
         return;
      }
      navigateToDefaultPage(user.mainRole);
   }, [user, navigateToDefaultPage]);

   const handleSubmit = async () => {
      setLoading(true);
      setBannerMessage('');
      try {
         await signIn({ email: values.email, password: values.password });
         const { data: userDetails } = await HTTPService.getCurrentUserDetails();
         setUser(userDetails);
      } catch (error) {
         let errorMessage;
         if (error instanceof AxiosError) {
            errorMessage = SOMETHING_WENT_WRONG_ERROR_MESSAGE;
         } else {
            const typedError = error as Error;
            errorMessage = invalidCredentialsErrors.includes(typedError.message)
               ? 'Podane dane są nieprawidłowe, spróbuj jeszcze raz.'
               : SOMETHING_WENT_WRONG_ERROR_MESSAGE;
         }
         setBannerType('error');
         setBannerMessage(errorMessage);
      }
      setLoading(false);
   };

   const { values, changeHandler, touched, errorsList, submitHandler } = useForm({
      initialState: initialState,
      validations: loginFormValidations,
      onSubmit: handleSubmit,
   });

   return (
      <div className={styles.formContainer}>
         <Logo />
         <h1>Zaloguj się</h1>
         {bannerMessage && (
            <div style={{ marginBottom: 36 }}>
               <Banner fullWidth variant={bannerType} children={bannerMessage} />
            </div>
         )}
         <form noValidate onSubmit={submitHandler}>
            <StyledTextInput
               width="100%"
               name="email"
               error={touched.email && errorsList.email}
               helperText={touched.email && errorsList.email}
               value={values.email}
               type="email"
               onChange={changeHandler}
               label="E-mail"
            />
            <div className={styles.inputContainer}>
               <StyledTextInput
                  width="100%"
                  name="password"
                  error={touched.password && errorsList.password}
                  helperText={touched.password && errorsList.password}
                  value={values.password}
                  type="password"
                  onChange={changeHandler}
                  label="Hasło"
               />
            </div>
            <StyledButton
               style={{ float: 'right' }}
               type="button"
               variant="text"
               text="Zapomniałeś hasła?"
               onClick={() => {
                  navigate(FORGOT_PASSWORD);
               }}
            />
            <StyledButton
               style={{ marginTop: 96 }}
               loading={loading}
               fullWidth
               text="Zaloguj się"
               variant="filled-primary"
               type="submit"
            />
         </form>
      </div>
   );
};
export default LoginForm;
