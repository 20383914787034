import { Banner, BannerProps } from 'storybook';

import styles from './BannerList.module.css';

type BannerListProps = {
   bannersData: BannerListItem[];
};

export type BannerListItem = BannerProps & {
   key: string;
};

const BannerList = ({ bannersData }: BannerListProps) => (
   <div className={styles.container}>
      {bannersData.map(bannerData => {
         const { key, ...otherBannerData } = bannerData;
         return <Banner {...otherBannerData} key={key} className={styles.banner} />;
      })}
   </div>
);

export default BannerList;
