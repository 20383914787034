import { ClientUserDTO, Option } from 'interfaces';

export const parseClientUserDTOIntoOption: (
   clientUserDTO: ClientUserDTO,
) => Option = clientUserDTO => {
   const { id, companyName } = clientUserDTO;

   return {
      value: id,
      label: companyName,
   };
};
