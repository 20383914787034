import classNames from 'classnames';
import { PhotoOff } from 'tabler-icons-react';

import { ImagePreview } from '..';

import styles from './ImagesPreview.module.css';

type ImagesPreviewProps = {
   imageURLs: string[];
   isLeftSidePosition?: boolean;
   onClickImage?: (imageIndex: number) => void;
   onDeleteImage?: (imageURL: string) => void;
};

const ImagesPreview = ({
   imageURLs,
   isLeftSidePosition,
   onClickImage,
   onDeleteImage,
}: ImagesPreviewProps) => (
   <div className={classNames(styles.container, { [styles.leftSide]: isLeftSidePosition })}>
      {imageURLs.length ? (
         imageURLs.map((imageURL, imageIndex) => (
            <ImagePreview
               key={imageURL}
               imageURL={imageURL}
               className={classNames(styles.imagePreview, {
                  [styles.withDeleteButton]: onDeleteImage,
               })}
               onClick={onClickImage ? () => onClickImage(imageIndex) : undefined}
               onDelete={onDeleteImage ? () => onDeleteImage(imageURL) : undefined}
            />
         ))
      ) : (
         <PhotoOff size="48" color="#A6A6B2" />
      )}
   </div>
);

export default ImagesPreview;
