import React, { useCallback, useState } from 'react';

import { DateTime } from 'luxon';

import CreatePalletPickUpModal from 'components/PickUps/CreatePalletPickUpModal/CreatePalletPickUpModal';
import { PALLET_PICK_UP_PAGE_BANNERS } from 'constants/banners';
import { DATE_TIME_ISO_FORMAT } from 'constants/dateFormats';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { usePagination } from 'hooks';
import { BalancePickUpDTO, BannerData, PalletPickUpDTO } from 'interfaces';
import { Banner } from 'storybook';
import { errorHandler } from 'utils/errorHandler';

import { PalletPickUpsTable } from '../../../components/PickUps';
import StyledButton from '../../../components/shared/StyledButton/StyledButton';
import WithAuth from '../../../hoc/withAuth';
import HTTPService from '../../../service/http/HTTPService';

import styles from './PalletsPickUpsPage.module.css';

const PalletsPickUpsPage = () => {
   const [pickUps, setPickUps] = useState<PalletPickUpDTO[]>([]);
   const [last, setLast] = useState(false);
   const [firstPickUpFetchLoading, setFirstPickUpFetchLoading] = useState(true);
   const [isOpenCreatePalletPickUpModal, setOpenCreatePalletPickUpModal] = useState(false);
   const [loadingInModal, setLoadingInModal] = useState(false);
   const [banner, setBanner] = useState<BannerData | null>(null);

   const fetchPickUps = useCallback((page: number) => {
      HTTPService.getUserPickUps({ page })
         .then(({ data }) => {
            setPickUps(prevValue => (page === 0 ? data.content : [...prevValue, ...data.content]));
            setLast(data.last);
         })
         .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA))
         .finally(() => setFirstPickUpFetchLoading(false));
   }, []);

   const { handleLoadNextItems } = usePagination({
      fetchFunction: fetchPickUps,
   });

   const createPalletPickUp = (palletBalances: BalancePickUpDTO[], dateTime: DateTime) => {
      setLoadingInModal(true);

      HTTPService.createPalletPickUp(palletBalances, dateTime.toFormat(DATE_TIME_ISO_FORMAT))
         .then(({ data }) => {
            setBanner(PALLET_PICK_UP_PAGE_BANNERS.ADD_SUCCESS);
            setOpenCreatePalletPickUpModal(false);
            setPickUps(prevState => [data, ...prevState]);
         })
         .catch(error => handleError(error, DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION))
         .finally(() => {
            setLoadingInModal(false);
         });
   };

   const handleError = (error: unknown, errorDescription: string) =>
      errorHandler(error, () =>
         setBanner({
            variant: 'error',
            description: errorDescription,
         }),
      );

   const handleCloseCreatePalletPickUpModal = useCallback(
      () => setOpenCreatePalletPickUpModal(false),
      [],
   );

   return (
      <div>
         {isOpenCreatePalletPickUpModal && (
            <CreatePalletPickUpModal
               showErrorBanner={false}
               isLoading={loadingInModal}
               onClose={handleCloseCreatePalletPickUpModal}
               onSubmit={createPalletPickUp}
            />
         )}
         <div className={styles.headerContainer}>
            {banner && (
               <Banner
                  className={styles.banner}
                  fullWidth
                  variant={banner.variant}
                  children={banner.description}
                  withCloseIcon
                  onClose={() => setBanner(null)}
               />
            )}
            <div>
               <StyledButton
                  onClick={() => setOpenCreatePalletPickUpModal(true)}
                  variant="filled-primary"
                  text="Zgłoś odbiór palet"
               />
            </div>
         </div>
         <PalletPickUpsTable
            pickUps={pickUps}
            firstPickUpFetchLoading={firstPickUpFetchLoading}
            handleLoadNextItems={handleLoadNextItems}
            last={last}
            openCreatePalletPickUpModal={() => setOpenCreatePalletPickUpModal(true)}
         />
      </div>
   );
};

export default WithAuth(PalletsPickUpsPage, 'ROLE_DISTRIBUTION_CENTER');
