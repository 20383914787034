import { AddDistributionCenterFormValues, DistributionCenter } from 'interfaces';

import { EMPTY_VALUE_LABEL } from './commonLabels';

export const addDistributionCenterFormDefaultValues: AddDistributionCenterFormValues = {
   phoneNumber: '',
   contactEmail: '',
   street: '',
   zipCode: '',
   city: '',
};

export const editDistributionCenterFormDefaultValues = (
   distributionCenter: DistributionCenter,
): Partial<AddDistributionCenterFormValues> => ({
   phoneNumber: distributionCenter.phoneNumber || EMPTY_VALUE_LABEL,
   contactEmail: distributionCenter.contactEmail,
   street: distributionCenter.address.street,
   zipCode: distributionCenter.address.zipCode,
   city: distributionCenter.address.city,
});
