import { PalletReport } from 'interfaces';

import DamagedLostPalletsTableMainRow from './DamagedLostPalletsTableRows/DamagedLostPalletsTableMainRow/DamagedLostPalletsTableMainRow';
import DamagedLostPalletsTableSubRow from './DamagedLostPalletsTableRows/DamagedLostPalletsTableSubRow';

type DamagedLostPalletsTableItemRow = {
   palletReport: PalletReport;
};

const SUB_ROWS_START_FROM_INDEX = 1;

const DamagedLostPalletsTableItem = ({ palletReport }: DamagedLostPalletsTableItemRow) => (
   <>
      <DamagedLostPalletsTableMainRow palletReport={palletReport} />
      {!!palletReport.damagedPallets.length &&
         palletReport.damagedPallets
            .slice(SUB_ROWS_START_FROM_INDEX)
            .map((damagedPalletsItem, damagedPalletsItemIndex) => (
               <DamagedLostPalletsTableSubRow
                  key={damagedPalletsItemIndex}
                  damagedPalletsItem={damagedPalletsItem}
                  isReceivedDamaged={palletReport.receivedDamaged}
               />
            ))}
   </>
);

export default DamagedLostPalletsTableItem;
