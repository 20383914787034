import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingOverlay, Modal } from '@mantine/core';

import { ArrowRightIcon } from 'assets';
import { TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT } from 'constants/errorCodes';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PAGINATION_MAX_SIZE } from 'constants/pagination';
import { PALETTE_STATUSES, PALETTE_STATUSES_OPTIONS } from 'constants/paletteStatuses';
import { PALLET_TYPES_LABELS_WITH_DIMENSIONS } from 'constants/palletTypes';
import {
   STORAGE_TYPE,
   STORAGE_TYPES_FOR_CLIENT_PICKER,
   STORAGE_TYPES_FOR_PICKER,
} from 'constants/storageType';
import {
   BannerType,
   BannerVariants,
   ClientUserDTO,
   CommercialNetworkDetailsDTO,
   DistributionCenterDTO,
   Option,
   StorageDTO,
} from 'interfaces';
import { HTTPService } from 'service';
import { TransferPaletteDestination } from 'service/http/requests';
import { Banner } from 'storybook';
import { getFullAddress } from 'utils/functions';
import { sortOptions } from 'utils/functions/sortOptions';
import { logNetworkError } from 'utils/logNetworkError';

import { StyledButton, StyledModalTitle, StyledSelect, StyledTextInput } from '../../shared';

import styles from './MovePalletsModal.module.css';

type MovePalletsModalProps = {
   onClose: () => void;
   onSuccess: () => void;
   showBanner: (variant: BannerVariants, description: string) => void;
   mode?: 'CLIENT';
   client?: ClientUserDTO;
};

const Arrow = () => (
   <div className={styles.arrow}>
      <ArrowRightIcon />
   </div>
);

const ErrorMap = {
   TransferPalletDuplicateValidator: 'Nie można przerzucić tych samych palet w to samo miejsce.',
   palletAmount: 'Wartości muszą być większe od 0',
   negativeTransferPalletBalance: 'Nie masz tylu palet w Magazynie początkowym',
};

const MovePalletsModal = ({
   onClose,
   onSuccess,
   showBanner,
   mode,
   client,
}: MovePalletsModalProps) => {
   const [loading, setLoading] = useState<boolean>(true);
   const [banner, setBanner] = useState<null | BannerType>(null);
   const [storages, setStorages] = useState<StorageDTO[]>([]);
   const [networks, setNetworks] = useState<CommercialNetworkDetailsDTO[]>([]);

   const [fromStorage, setFromStorage] = useState<Option | undefined>(undefined);
   const [toStorage, setToStorage] = useState<Option | undefined>(undefined);

   const [fromNetworkName, setFromNetworkName] = useState<Option | undefined>(undefined);
   const [toNetworkName, setToNetworkName] = useState<Option | undefined>(undefined);

   const [fromAddress, setFromAddress] = useState<Option | undefined>(undefined);
   const [toAddress, setToAddress] = useState<Option | undefined>(undefined);

   const [paletteType, setPaletteType] = useState<Option | undefined>(undefined);

   const [fromPaletteStatus, setFromPaletteStatus] = useState<Option | undefined>(undefined);
   const [toPaletteStatus, setToPaletteStatus] = useState<Option | undefined>(undefined);

   const [amount, setAmount] = useState(0);

   const isClientMode = mode === 'CLIENT';

   const clientAddresses = useCallback(() => {
      return client?.deliveryAddresses.map(({ address: clientAddress }) => {
         const { address, zipCode, city } = clientAddress;
         return {
            label: getFullAddress(address, zipCode, city),
            value: client.id,
         };
      });
   }, [client?.id, client?.deliveryAddresses]);

   useEffect(() => {
      if (isClientMode) {
         setFromStorage({ label: 'Adres klienta', value: 'CLIENT' });
         setFromAddress(clientAddresses()![0]);
      }
      Promise.all([
         HTTPService.getStorageList({ size: PAGINATION_MAX_SIZE }),
         HTTPService.getCommercialNetworks(undefined, { size: PAGINATION_MAX_SIZE }),
      ])
         .then(([storagesResponse, networksResponse]) => {
            setStorages(storagesResponse.data.content);
            setNetworks(networksResponse.data.content);
         })
         .catch(error => {
            showBannerInModal('error', DEFAULT_ERROR_DESCRIPTIONS.FETCH_DATA);
            logNetworkError(error);
         })
         .finally(() => setLoading(false));
   }, [isClientMode, clientAddresses]);

   const getTypeOfFromStorageEnum = () => {
      if (fromStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK) {
         return 'COMMERCIAL_NETWORK';
      } else if (fromStorage?.value === STORAGE_TYPE.CLIENT) {
         return 'USER_ADDRESS';
      } else {
         return 'STORAGE';
      }
   };

   const getTypeOfFromStorageId = () => {
      if (fromStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK) {
         return {
            commercialNetwork: {
               commercialNetworkID: fromNetworkName?.value,
               distributionCenterID: fromAddress?.value,
            },
         };
      } else if (fromStorage?.value === STORAGE_TYPE.CLIENT) {
         return { userId: client?.id };
      } else {
         return { storageId: fromAddress?.value };
      }
   };

   const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      const isCommercialNetwork = toStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK;
      let addressForBanner: string;
      if (isCommercialNetwork) {
         const foundNetwork = networks.find(network => network.id === toNetworkName?.value);
         const address = foundNetwork?.addresses?.find(
            center => center.id === toAddress?.value,
         ) as DistributionCenterDTO;
         addressForBanner = getFullAddress(
            address.address.street,
            address.address.zipCode,
            address.address.city,
         );
      } else {
         const {
            address: { street, zipCode, city, name },
         } = storages?.find(storage => storage.id === toAddress?.value) as StorageDTO;
         addressForBanner = getFullAddress(street, zipCode, city, name);
      }

      HTTPService.transferPallets({
         from: {
            type: getTypeOfFromStorageEnum(),
            ...getTypeOfFromStorageId(),
            palletStatus: fromPaletteStatus?.value,
         } as TransferPaletteDestination,
         destination: {
            type:
               toStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK
                  ? 'COMMERCIAL_NETWORK'
                  : 'STORAGE',
            ...(toStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK
               ? {
                    commercialNetwork: {
                       commercialNetworkID: toNetworkName?.value,
                       distributionCenterID: toAddress?.value,
                    },
                 }
               : { storageId: toAddress?.value }),
            palletStatus: toPaletteStatus?.value,
         } as TransferPaletteDestination,
         palletAmount: Number(amount),
         palletType: paletteType?.value,
      })
         .then(() => {
            showBanner('success', `Palety zostały przerzucone do ${addressForBanner}`);
            onSuccess();
         })
         .catch(error => {
            logNetworkError(error);
            const errorCode = error.response.data.code;
            if (
               errorCode === TRANSFER_PALLETS_NEGATIVE_BALANCE_AMOUNT ||
               errorCode === 'TRANSFER_FOR_COLLECTION_NEGATIVE_AMOUNT'
            ) {
               showBannerInModal('error', ErrorMap.negativeTransferPalletBalance);
               return;
            }
            const errors = Object.keys(error.response.data.errors) as (keyof typeof ErrorMap)[];
            errors && showBannerInModal('error', ErrorMap[errors[0]]);
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const networksOptions = useMemo(() => {
      const parsedNetworksOptions = networks.map(network => ({
         label: network.name,
         value: network.id,
      }));
      return sortOptions(parsedNetworksOptions);
   }, [networks]);

   const checkIfNetwork = (field: Option | undefined) =>
      field?.value === STORAGE_TYPE.COMMERCIAL_NETWORK;

   const isFromStorageNetwork = checkIfNetwork(fromStorage);

   const isToStorageNetwork = checkIfNetwork(toStorage);

   const getCommercialNetworksAddressOptions = (value: Option) => {
      const commercialNetwork = networks.find(network => network.id === value.value);
      const commercialNetworksAddressOptions = commercialNetwork?.addresses.map(
         ({ address: { street, zipCode, city }, id }) => ({
            label: getFullAddress(street, zipCode, city),
            value: id,
         }),
      );
      return commercialNetworksAddressOptions
         ? sortOptions(commercialNetworksAddressOptions)
         : commercialNetworksAddressOptions;
   };

   const getStoragesAddressOptions = (value: Option) => {
      const filterStorages = storages.filter(storage => storage.type === value.value);
      const storagesAddressOptions = filterStorages.map(
         ({ address: { street, zipCode, city }, id }) => ({
            label: getFullAddress(street, zipCode, city),
            value: id,
         }),
      );
      return sortOptions(storagesAddressOptions);
   };

   const getPaletteTypesBasedOnFromStorage = useMemo(() => {
      const isCommercialNetwork = fromStorage?.value === STORAGE_TYPE.COMMERCIAL_NETWORK;
      const isClient = fromStorage?.value === STORAGE_TYPE.CLIENT;
      if (isClient) {
         const paletteTypesOptions = client?.availablePalletTypes.map(pallet => ({
            value: pallet,
            label: PALLET_TYPES_LABELS_WITH_DIMENSIONS[pallet].label,
         }));
         return paletteTypesOptions ? sortOptions(paletteTypesOptions) : paletteTypesOptions;
      }
      if (isCommercialNetwork) {
         const foundNetwork = networks.find(network => network.id === fromNetworkName?.value);
         const foundDistributionCenter = foundNetwork?.addresses.find(
            center => center.id === fromAddress?.value,
         );
         const paletteTypesOptions =
            foundDistributionCenter?.todayBalance.balance &&
            Object.keys(foundDistributionCenter.todayBalance.balance).map(type => ({
               label: PALLET_TYPES_LABELS_WITH_DIMENSIONS[
                  type as keyof typeof PALLET_TYPES_LABELS_WITH_DIMENSIONS
               ].label,
               value: type,
            }));
         return paletteTypesOptions ? sortOptions(paletteTypesOptions) : paletteTypesOptions;
      } else {
         const foundStorage = storages.find(storage => storage.id === fromAddress?.value);
         const paletteTypesOptions =
            foundStorage?.balanceForToday &&
            Object.keys(foundStorage.balanceForToday).map(type => ({
               label: PALLET_TYPES_LABELS_WITH_DIMENSIONS[
                  type as keyof typeof PALLET_TYPES_LABELS_WITH_DIMENSIONS
               ].label,
               value: type,
            }));
         return paletteTypesOptions ? sortOptions(paletteTypesOptions) : paletteTypesOptions;
      }
   }, [
      fromAddress?.value,
      fromNetworkName?.value,
      fromStorage?.value,
      networks,
      storages,
      client?.availablePalletTypes,
   ]);

   const getPaletteStatuses = (value: Option) => {
      switch (value.value) {
         case STORAGE_TYPE.COMMERCIAL_NETWORK:
            return PALETTE_STATUSES_OPTIONS.filter(
               status => status.value !== PALETTE_STATUSES.LOST,
            );
         case STORAGE_TYPE.EXTERNAL:
            return PALETTE_STATUSES_OPTIONS.filter(
               status => status.value !== PALETTE_STATUSES.LOST,
            );
         case STORAGE_TYPE.INTERNAL:
            return PALETTE_STATUSES_OPTIONS;
         case STORAGE_TYPE.CLIENT:
            return PALETTE_STATUSES_OPTIONS;
      }
   };

   const allTouched =
      fromStorage?.value &&
      toStorage?.value &&
      fromAddress?.value &&
      toAddress?.value &&
      fromPaletteStatus?.value &&
      toPaletteStatus?.value &&
      paletteType?.value &&
      amount;

   const showBannerInModal = (variant: BannerVariants, description: string) =>
      setBanner({ variant, description });

   const showAddress = !(
      (isToStorageNetwork && !toNetworkName) ||
      (isFromStorageNetwork && !fromNetworkName)
   );

   const optionsForFromAddress = () => {
      if (isClientMode) {
         const deliveryAddressesOptions = client?.deliveryAddresses.map(
            ({ address: clientAddress }) => {
               const { address, zipCode, city, id } = clientAddress;
               return {
                  label: getFullAddress(address, zipCode, city),
                  value: id,
               };
            },
         );
         return deliveryAddressesOptions
            ? sortOptions(deliveryAddressesOptions)
            : deliveryAddressesOptions;
      } else {
         if (isFromStorageNetwork) {
            return fromNetworkName ? getCommercialNetworksAddressOptions(fromNetworkName) : [];
         }
         return getStoragesAddressOptions(fromStorage!);
      }
   };

   const isAmountInvalid = amount < 0;

   return (
      <Modal
         title={
            <StyledModalTitle
               title="Przerzuć palety"
               subtitle="W lewej kolumnie wybierz z jakiego miejsca i jaki rodzaj palety chcesz przerzucić, w prawej kolumnie wybierz magazyn docelowy, do którego trafią palety.  Możesz przerzucać tylko jeden rodzaj palet jednocześnie."
            />
         }
         opened
         onClose={onClose}
         size={800}
      >
         <form onSubmit={handleSubmit}>
            <div className={styles.modalContent}>
               <div className={styles.inputsContainer}>
                  {banner && (
                     <Banner
                        className={styles.banner}
                        variant={banner?.variant}
                        children={banner?.description}
                     />
                  )}
                  <div className={styles.inputsRow}>
                     <StyledSelect
                        classNames={{ container: styles.selectContainer, select: styles.select }}
                        options={
                           isClientMode ? STORAGE_TYPES_FOR_CLIENT_PICKER : STORAGE_TYPES_FOR_PICKER
                        }
                        onChange={(value: Option) => {
                           setFromStorage(value);
                           setFromNetworkName(undefined);
                           setFromAddress(undefined);
                           setPaletteType(undefined);
                           setFromPaletteStatus(undefined);
                           setToPaletteStatus(undefined);
                           setAmount(0);
                        }}
                        label="Magazyn początkowy"
                        value={fromStorage}
                     />
                     <Arrow />
                     <StyledSelect
                        classNames={{ container: styles.selectContainer, select: styles.select }}
                        options={STORAGE_TYPES_FOR_PICKER}
                        onChange={(value: Option) => {
                           setToStorage(value);
                           setToNetworkName(undefined);
                           setToAddress(undefined);
                           setPaletteType(undefined);
                           setFromPaletteStatus(undefined);
                           setToPaletteStatus(undefined);
                           setAmount(0);
                        }}
                        label="Magazyn docelowy"
                        value={toStorage}
                     />
                  </div>
                  {fromStorage && toStorage && (isFromStorageNetwork || isToStorageNetwork) && (
                     <div className={styles.inputsRow}>
                        {isFromStorageNetwork ? (
                           <StyledSelect
                              classNames={{
                                 container: styles.selectContainer,
                                 select: styles.select,
                              }}
                              onChange={(value: Option) => {
                                 setFromNetworkName(value);
                                 setFromAddress(undefined);
                                 setPaletteType(undefined);
                                 setFromPaletteStatus(undefined);
                                 setToPaletteStatus(undefined);
                              }}
                              options={networksOptions}
                              label="Nazwa sieci handlowej"
                              value={fromNetworkName}
                           />
                        ) : (
                           <div className={styles.selectContainer} />
                        )}
                        {isFromStorageNetwork && isToStorageNetwork && <Arrow />}
                        {isToStorageNetwork ? (
                           <StyledSelect
                              classNames={{
                                 container: styles.selectContainer,
                                 select: styles.select,
                              }}
                              onChange={(value: Option) => {
                                 setToNetworkName(value);
                                 setToAddress(undefined);
                                 setPaletteType(undefined);
                                 setFromPaletteStatus(undefined);
                                 setToPaletteStatus(undefined);
                              }}
                              options={networksOptions}
                              label="Nazwa sieci handlowej"
                              value={toNetworkName}
                           />
                        ) : (
                           <div className={styles.selectContainer} />
                        )}
                     </div>
                  )}
                  {fromStorage && toStorage && showAddress && (
                     <div className={styles.inputsRow}>
                        <StyledSelect
                           classNames={{ container: styles.selectContainer, select: styles.select }}
                           onChange={setFromAddress}
                           options={optionsForFromAddress()}
                           label="Adres początkowy"
                           value={fromAddress}
                        />
                        <Arrow />
                        <StyledSelect
                           classNames={{ container: styles.selectContainer, select: styles.select }}
                           onChange={setToAddress}
                           options={
                              isToStorageNetwork
                                 ? toNetworkName
                                    ? getCommercialNetworksAddressOptions(toNetworkName)
                                    : []
                                 : getStoragesAddressOptions(toStorage)
                           }
                           label="Adres docelowy"
                           value={toAddress}
                        />
                     </div>
                  )}
                  {fromAddress && toAddress && (
                     <div className={styles.inputsRow}>
                        <StyledSelect
                           classNames={{ container: styles.selectContainer, select: styles.select }}
                           onChange={setPaletteType}
                           options={getPaletteTypesBasedOnFromStorage}
                           label="Rodzaj palet"
                           value={paletteType}
                        />
                     </div>
                  )}
                  {fromStorage && toStorage && paletteType && (
                     <div className={styles.inputsRow}>
                        <StyledSelect
                           classNames={{ container: styles.selectContainer, select: styles.select }}
                           onChange={setFromPaletteStatus}
                           options={getPaletteStatuses(fromStorage)}
                           label="Status palet"
                           value={fromPaletteStatus}
                        />
                        <Arrow />
                        <StyledSelect
                           classNames={{ container: styles.selectContainer, select: styles.select }}
                           onChange={setToPaletteStatus}
                           options={getPaletteStatuses(toStorage)}
                           label="Status palet"
                           value={toPaletteStatus}
                        />
                     </div>
                  )}
                  {fromPaletteStatus && toPaletteStatus && (
                     <div className={styles.inputsRow}>
                        <StyledTextInput
                           className={styles.inputContainer}
                           type="number"
                           error={isAmountInvalid}
                           helperText={isAmountInvalid ? 'Wartości muszą być większe od 0' : ''}
                           label="Ilość (szt.)"
                           value={amount}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              setAmount(+event.target.value);
                           }}
                        />
                     </div>
                  )}
               </div>
               <LoadingOverlay
                  visible={loading}
                  overlayBlur={10}
                  loaderProps={{ color: 'var(--light-green)' }}
               />
            </div>
            <div className={styles.actions}>
               <StyledButton variant="text" text="Anuluj" onClick={onClose} />
               <StyledButton
                  variant="filled-primary"
                  text="Przerzuć palety"
                  disabled={loading || !allTouched}
               />
            </div>
         </form>
      </Modal>
   );
};

export default MovePalletsModal;
