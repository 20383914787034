import { useCallback } from 'react';

import { AxiosError } from 'axios';

import { CustomModal, MaskedInput, StyledTextInput } from 'components/shared';
import {
   addDistributionCenterFormDefaultValues,
   editDistributionCenterFormDefaultValues,
} from 'constants/addDistributionCenterFormDefaultValues';
import {
   ADD_OR_EDIT_DISTRIBUTION_CENTER_ERROR_DESCRIPTIONS,
   DEFAULT_ERROR_DESCRIPTIONS,
} from 'constants/errorDescriptions';
import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';
import { CommercialNetwork, DistributionCenter, DistributionCenterDTO } from 'interfaces';
import { HTTPService } from 'service';
import { CreateDistributionCenter } from 'service/http/requests';
import { errorHandler } from 'utils/errorHandler';
import { addDistributionCenterFormValidations } from 'utils/validation/addDistributionCenterFormValidation';

import styles from './AddOrEditDistributionCenterModal.module.css';

type AddOrEditDistributionCenterModalBaseProps = {
   commercialNetwork: CommercialNetwork | null;
   opened: boolean;
   distributionCenter?: DistributionCenter;
   onClose: () => void;
   mode: 'edit' | 'add';
};

type AddDistributionCenterModalProps = {
   mode: 'add';
   onSuccess: (
      selectedCommercialNetworkId: string,
      distributionCenter: DistributionCenterDTO,
   ) => void;
} & AddOrEditDistributionCenterModalBaseProps;

type EditDistributionCenterModalProps = {
   mode: 'edit';
   onSuccess: (distributionCenter: DistributionCenterDTO) => void;
} & AddOrEditDistributionCenterModalBaseProps;

type AddOrEditDistributionCenterModalProps =
   | EditDistributionCenterModalProps
   | AddDistributionCenterModalProps;

const AddOrEditDistributionCenterModal = ({
   commercialNetwork,
   distributionCenter,
   opened,
   onClose,
   onSuccess,
   mode,
}: AddOrEditDistributionCenterModalProps) => {
   const isAddDistributionCenterMode = mode === 'add';
   const handleAddOrEditDistributionCenter = () => {
      setIsLoading(true);
      const { city, street, zipCode, contactEmail, phoneNumber } = values;
      const commercialNetworkData: CreateDistributionCenter = {
         address: {
            city,
            street,
            zipCode,
         },
         contactEmail,
         phoneNumber,
      };
      if (mode === 'add') {
         HTTPService.createDistributionCenter(commercialNetwork!.id, commercialNetworkData)
            .then(response => {
               closeModalHandler();
               onSuccess(commercialNetwork!.id, response.data);
            })
            .catch(handleAddOrEditDistributionCenterError)
            .finally(() => setIsLoading(false));
      } else {
         HTTPService.editDistributionCenter(
            commercialNetwork!.id,
            distributionCenter!.id,
            commercialNetworkData,
         )
            .then(({ data }) => {
               handleCloseModal();
               setValues(prevValues => {
                  return {
                     ...prevValues,
                     ...data,
                     city: data.address.city,
                     street: data.address.street,
                     zipCode: data.address.zipCode,
                  };
               });
               onSuccess(data);
            })
            .catch(handleAddOrEditDistributionCenterError)
            .finally(() => setIsLoading(false));
      }
   };

   const handleAddOrEditDistributionCenterError = (error: unknown) => {
      const isAxiosErrorDescriptionExists =
         error instanceof AxiosError &&
         error.response?.data?.code &&
         ADD_OR_EDIT_DISTRIBUTION_CENTER_ERROR_DESCRIPTIONS[error.response.data.code];
      const errorInfo = isAxiosErrorDescriptionExists
         ? ADD_OR_EDIT_DISTRIBUTION_CENTER_ERROR_DESCRIPTIONS[error.response?.data.code]
         : DEFAULT_ERROR_DESCRIPTIONS.COMPLETE_ACTION;
      errorHandler(error, () => setErrorInfo(errorInfo));
   };

   const { values, setValues, touched, errorsList, changeHandler, submitHandler, clearForm } =
      useForm({
         initialState:
            !isAddDistributionCenterMode && distributionCenter
               ? editDistributionCenterFormDefaultValues(distributionCenter)
               : addDistributionCenterFormDefaultValues,
         validations: addDistributionCenterFormValidations,
         onSubmit: handleAddOrEditDistributionCenter,
      });

   const handleCloseModal = useCallback(() => {
      clearForm();
      onClose();
   }, [clearForm, onClose]);

   const {
      isLoading,
      errorInfo,
      showCloseWarning,
      setIsLoading,
      setErrorInfo,
      handleHideCloseWarning,
      handleShowCloseWarning,
      closeModalHandler,
   } = useModal({ onClose: handleCloseModal });

   const modalPrefix = isAddDistributionCenterMode ? 'Dodaj' : 'Edytuj';

   const modalTitleSection = (
      <div className={styles.modalTitleSection}>
         <h3 className={styles.modalTitle}>{`${modalPrefix} Centrum Dystrybucyjne`}</h3>
         <p className={styles.modalInfo}>
            Każde Centrum Dystrybucyjne jest przypisane do konkretnej Sieci Handlowej i stanowi
            punkt magazynowy Sieci Handlowej.
         </p>
      </div>
   );

   return (
      <CustomModal
         opened={opened}
         onClose={handleShowCloseWarning}
         isLoading={isLoading}
         error={errorInfo}
         title={modalTitleSection}
         primaryButtonProps={{
            text: `${modalPrefix} Centrum Dystrybucyjne`,
            variant: 'filled-primary',
            disabled: !commercialNetwork || !!Object.keys(errorsList).length,
         }}
         secondaryButtonProps={{ text: 'Anuluj', variant: 'text', onClick: handleShowCloseWarning }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         size={600}
         modalContentClassName={styles.modalContent}
         onSubmit={submitHandler}
      >
         <div className={styles.formContentContainer}>
            <div className={styles.commercialNetworkNameContainer}>
               <p>Nazwa Sieci Handlowej</p>
               <p>{commercialNetwork?.name || ''}</p>
            </div>
            <StyledTextInput
               type="text"
               name="street"
               label="Ulica, numer domu, numer mieszkania"
               value={values.street}
               helperText={touched.street && errorsList.street}
               className={styles.input}
               onChange={changeHandler}
               error={touched.street && errorsList.street}
               fullWidth
            />
            <div className={styles.inputsGroup}>
               <MaskedInput
                  type="text"
                  name="zipCode"
                  label="Kod pocztowy"
                  value={values.zipCode}
                  helperText={touched.zipCode && errorsList.zipCode}
                  mask="99-999"
                  className={styles.zipCodeInput}
                  onChange={changeHandler}
                  error={touched.zipCode && errorsList.zipCode}
               />
               <StyledTextInput
                  type="text"
                  name="city"
                  label="Miejscowość"
                  value={values.city}
                  helperText={touched.city && errorsList.city}
                  className={styles.cityInput}
                  onChange={changeHandler}
                  error={touched.city && errorsList.city}
               />
            </div>
            <StyledTextInput
               type="number"
               name="phoneNumber"
               label="Numer telefonu (opcjonalnie)"
               value={values.phoneNumber}
               helperText={(touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber}
               className={styles.input}
               onChange={changeHandler}
               error={(touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber}
               fullWidth
            />
            <StyledTextInput
               type="text"
               name="contactEmail"
               label="E-mail kontaktowy (opcjonalnie)"
               value={values.contactEmail}
               helperText={(touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail}
               className={styles.input}
               onChange={changeHandler}
               error={(touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail}
               fullWidth
            />
         </div>
      </CustomModal>
   );
};

export default AddOrEditDistributionCenterModal;
