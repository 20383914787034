import { CustomModal } from 'components/shared';
import { CustomModalProps } from 'components/shared/CustomModal/CustomModal';
import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { useModal } from 'hooks/useModal';
import { OrderDetailsDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './CancelOrderModal.module.css';

type CancelOrderModalProps = CustomModalProps & {
   selectedOrderId: string;
   onSuccessCancelOrder: (updatedOrder: OrderDetailsDTO) => void;
};

const CancelOrderModal = ({
   selectedOrderId,
   onSuccessCancelOrder,
   onClose,
   ...props
}: CancelOrderModalProps) => {
   const { isLoading, errorInfo, setIsLoading, setErrorInfo, closeModalHandler } = useModal({
      onClose,
   });

   const handleCancelOrder = () => {
      setIsLoading(true);
      HTTPService.changeOrderStatus(selectedOrderId, 'CANCEL')
         .then(response => {
            onSuccessCancelOrder(response.data);
            closeModalHandler();
         })
         .catch(error => {
            setErrorInfo(DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
            logNetworkError(error);
         })
         .finally(() => setIsLoading(false));
   };

   return (
      <CustomModal
         {...props}
         icon="ALERT_TRIANGLE"
         innerTitle="Anuluj zamówienie"
         error={errorInfo}
         modalContentClassName={styles.modalContent}
         isLoading={isLoading}
         onClose={closeModalHandler}
         primaryButtonProps={{
            text: 'Anuluj zamówienie',
            variant: 'filled-danger',
            onClick: handleCancelOrder,
         }}
         secondaryButtonProps={{
            text: 'Wróć',
            variant: 'outlined-danger',
            onClick: closeModalHandler,
         }}
      >
         <div className={styles.descriptionContainer}>
            <p className={styles.plainText}>Czy jesteś pewny, że chcesz anulować to zamówienie?</p>
            <p className={styles.boldBlackText}>Tej akcji nie można cofnąć.</p>
            <p className={styles.boldRedText}>
               Jeśli została wystawiona faktura do tego zamówienia, to pamiętaj o jej wycofaniu!
            </p>
         </div>
      </CustomModal>
   );
};

export default CancelOrderModal;
