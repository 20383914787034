import { ClientDataFormProps, PalletTypesFormProps, RateConfigForm } from 'interfaces';

export const clientDataFormInitialValues: ClientDataFormProps = {
   id: '',
   email: '',
   nip: '',
   companyName: '',
   zipCode: '',
   city: '',
   address: '',
   phoneNumber: '',
   invoiceEmail: '',
   deliveryAddresses: [{ name: '', zipCode: '', city: '', address: '' }],
};

export const palletTypesFormInitialValues: PalletTypesFormProps = {
   availablePalletTypes: [],
};

export const ratesConfigurationFormInitialValues: RateConfigForm = {
   palletRates: {
      common: [],
      perAddress: [],
   },
   retentionConfig: {
      retentionPeriodDays: 0,
      retentionPalletCosts: [],
   },
   delaysCharges: [],
};
