import { Fragment } from 'react';

import { ChevronRightIcon } from 'assets';
import { Step } from 'storybook';

import styles from './FormStepsHeader.module.css';

const formSteps = [
   { title: 'Dane klienta', count: 1 },
   { title: 'Rodzaje palet', count: 2 },
   { title: 'Konfiguracja stawek', count: 3 },
   { title: 'Sieci Handlowe', count: 4 },
];

type FormStepsHeaderProps = {
   currentStep: number;
};

const FormStepsHeader = ({ currentStep }: FormStepsHeaderProps) => {
   return (
      <div className={styles.stepsContainer}>
         {formSteps.map(step => (
            <Fragment key={step.count}>
               <Step
                  completed={step.count < currentStep + 1}
                  active={currentStep + 1 === step.count}
                  text={step.title}
                  stepCount={step.count}
               />
               {step.count !== formSteps.length && <ChevronRightIcon />}
            </Fragment>
         ))}
      </div>
   );
};

export default FormStepsHeader;
