import classNames from 'classnames';
import { isString } from 'lodash';

import {
   SelfPickupGreenIcon,
   SelfPickupIcon,
   TruckDeliveryGreenIcon,
   TruckDeliveryIcon,
} from 'assets';
import { SectionContainer, StyledSelect } from 'components/shared';
import { AddressData, Option, SelectValue, StorageGroupOption, StorageOption } from 'interfaces';
import { Banner, Card } from 'storybook';

import styles from './EditDeliverySection.module.css';

type EditDeliverySectionProps = {
   selectedAddress: AddressData | 'SELF_PICKUP' | null;
   availableAddresses: AddressData[];
   selectedStorage?: Option;
   availableStorages?: StorageGroupOption[];
   onChangeAddress: (
      address: SelectValue | StorageOption | 'SELF_PICKUP' | null,
      field: string,
   ) => void;
   disabledSelfPickup?: boolean;
   touched?: boolean;
   isMobile?: boolean;
   onSave?: () => void;
   onCancel?: () => void;
   isLoading?: boolean;
   error?: string;
};

const EditDeliverySection = ({
   selectedAddress,
   availableAddresses,
   selectedStorage,
   availableStorages,
   onChangeAddress,
   disabledSelfPickup,
   error,
   touched,
   isMobile,
   onSave,
   onCancel,
   isLoading,
}: EditDeliverySectionProps) => {
   const handleDeliveryAddressChange = (value: SelectValue) => {
      if (selectedStorage && selectedAddress === 'SELF_PICKUP') {
         onChangeAddress(null, 'storage');
      }
      onChangeAddress(value, 'address');
   };

   return (
      <SectionContainer title="Dostawa" onSave={onSave} onCancel={onCancel} isLoading={isLoading}>
         {error && touched && (
            <Banner fullWidth variant="error" children={error} style={{ marginBottom: 24 }} />
         )}
         <div className={styles.cardsContainer}>
            <Card
               title="Dostawa pod adres"
               displayCheckedIcon
               checked={!error && !!selectedAddress && selectedAddress !== 'SELF_PICKUP'}
               icon={
                  !error && !!selectedAddress && selectedAddress !== 'SELF_PICKUP' ? (
                     <TruckDeliveryGreenIcon />
                  ) : (
                     <TruckDeliveryIcon />
                  )
               }
               bottomContent={
                  <div className={styles.cardContent}>
                     <StyledSelect
                        placeholder="Wybierz adres dostawy"
                        value={
                           isString(selectedAddress) || selectedAddress === null
                              ? undefined
                              : selectedAddress
                        }
                        onChange={handleDeliveryAddressChange}
                        options={availableAddresses}
                        disabled={disabledSelfPickup}
                        mobileView={isMobile}
                        classNames={{ select: styles.addressSelect }}
                     />
                  </div>
               }
               disabled={disabledSelfPickup}
               className={classNames(styles.cardLarge, {
                  [styles.cardDisabled]: disabledSelfPickup,
               })}
            />
            <Card
               displayCheckedIcon
               {...(!availableStorages
                  ? { onClick: () => onChangeAddress('SELF_PICKUP', 'address') }
                  : {})}
               icon={
                  selectedAddress === 'SELF_PICKUP' ? <SelfPickupGreenIcon /> : <SelfPickupIcon />
               }
               checked={selectedAddress === 'SELF_PICKUP'}
               title="Odbiór osobisty "
               bottomContent={
                  !availableStorages ? (
                     <p className={styles.cardText}>
                        Adres magazynu zostanie wyświetlony w szczegółach zamówienia po jego
                        zaakceptowaniu
                     </p>
                  ) : (
                     <>
                        <p className={styles.cardText}>Wybierz miejsce odbioru palet.</p>
                        <StyledSelect
                           value={
                              selectedStorage && selectedAddress === 'SELF_PICKUP'
                                 ? selectedStorage
                                 : undefined
                           }
                           onChange={(value: StorageOption) => {
                              onChangeAddress(value, 'storage');
                              onChangeAddress('SELF_PICKUP', 'address');
                           }}
                           groups={availableStorages}
                           mobileView={isMobile}
                           classNames={{ select: styles.addressSelect }}
                        />
                     </>
                  )
               }
               className={styles.cardLarge}
            />
         </div>
      </SectionContainer>
   );
};

export default EditDeliverySection;
