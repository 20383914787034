import { MailIcon } from 'assets';
import StyledButton from 'components/shared/StyledButton/StyledButton';
import { Banner } from 'storybook';

import styles from './SentPasswordResetNotification.module.css';

type SentPasswordResetNotificationProps = {
   onGoToLoginPage: () => void;
   email: string;
};

const SentPasswordResetNotification = ({
   onGoToLoginPage,
   email,
}: SentPasswordResetNotificationProps) => (
   <div className={styles.mainContainer}>
      <span className={styles.iconContainer}>
         <MailIcon className={styles.icon} />
      </span>
      <h1>Sprawdź e-maila</h1>
      <Banner
         style={{ alignItems: 'baseline' }}
         children={`Wysłaliśmy do Ciebie maila z instrukcją zresetowania hasła na adres ${email}.`}
         variant="success"
         className={styles.banner}
      />
      <p className={styles.boldText}>E-mail nie dotarł?</p>
      <ol>
         <li>Sprawdź folder SPAM.</li>
         <li>Sprawdź czy adres e-mail jest poprawny.</li>
         <li>Odczekaj kilka minut i spróbuj ponownie.</li>
      </ol>
      <p>Jeśli nadal potrzebujesz pomocy, skontaktuj się z nami:</p>
      <p>
         Telefon:
         <span className={styles.boldText}> +48 71 383 06 13</span>
      </p>
      <p>
         E-mail:
         <span className={styles.boldText}> biuro@ndhp.pl</span>
      </p>
      <StyledButton
         text="Wróć do logowania"
         onClick={onGoToLoginPage}
         variant="filled-primary"
         className={styles.button}
      />
   </div>
);

export default SentPasswordResetNotification;
