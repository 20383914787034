import { useCallback, useState } from 'react';

import { CustomModal, CustomModalProps, StyledTextArea } from 'components/shared';
import { useModal } from 'hooks/useModal';
import { CommentDTO } from 'interfaces';
import { HTTPService } from 'service';
import { logNetworkError } from 'utils/logNetworkError';

import styles from './ClientDetailsCommentModal.module.css';

interface ClientDetailsCommentModalProps extends CustomModalProps {
   commentId: string;
   comment: string;
   action: 'ADD' | 'EDIT' | 'DELETE';
   clientId: string;
   setComments: React.Dispatch<React.SetStateAction<CommentDTO[]>>;
   setItemsTotalCount: React.Dispatch<React.SetStateAction<number>>;
}

const ClientDetailsCommentModal = ({
   commentId,
   comment,
   action,
   clientId,
   setComments,
   setItemsTotalCount,
   onClose,
   ...props
}: ClientDetailsCommentModalProps) => {
   const [commentText, setCommentText] = useState(comment);

   const handleClose = useCallback(() => {
      setCommentText('');
      onClose();
   }, [onClose]);

   const {
      isLoading,
      errorInfo,
      setIsLoading,
      setErrorInfo,
      showCloseWarning,
      handleShowCloseWarning,
      handleHideCloseWarning,
      closeModalHandler,
   } = useModal({
      onClose: handleClose,
   });

   const postComment = useCallback(() => {
      setIsLoading(true);
      HTTPService.postUserComment(clientId, commentText)
         .then(res => {
            setComments((prevValues: CommentDTO[]) => [...prevValues, res.data]);
            setItemsTotalCount(prevValue => prevValue + 1);
            closeModalHandler();
         })
         .catch(err => {
            logNetworkError(err);
            setErrorInfo('Podczas dodawania komentarza wystąpił błąd');
         })
         .finally(() => setIsLoading(false));
   }, [
      commentText,
      clientId,
      setIsLoading,
      setComments,
      setItemsTotalCount,
      setErrorInfo,
      closeModalHandler,
   ]);

   const editComment = useCallback(() => {
      setIsLoading(true);
      HTTPService.editUserComment(clientId, commentId!, commentText)
         .then(res => {
            setComments((prevValues: CommentDTO[]) =>
               prevValues.map(item => (item.id === commentId ? res.data : item)),
            );
            closeModalHandler();
         })
         .catch(err => {
            logNetworkError(err);
            setErrorInfo('Podczas edycji komentarza wystąpił błąd');
         })
         .finally(() => setIsLoading(false));
   }, [
      commentText,
      clientId,
      commentId,
      closeModalHandler,
      setComments,
      setIsLoading,
      setErrorInfo,
   ]);

   const deleteComment = useCallback(() => {
      setIsLoading(true);
      HTTPService.deleteUserComment(clientId, commentId!)
         .then(() => {
            setComments((prevValues: CommentDTO[]) =>
               prevValues.filter(item => item.id !== commentId),
            );
            setItemsTotalCount(prevValue => prevValue - 1);
            closeModalHandler();
         })
         .catch(err => {
            logNetworkError(err);
            setErrorInfo('Podczas usuwania komentarza wystąpił błąd');
         })
         .finally(() => setIsLoading(false));
   }, [
      clientId,
      commentId,
      closeModalHandler,
      setComments,
      setIsLoading,
      setItemsTotalCount,
      setErrorInfo,
   ]);

   const renderTitle = useCallback(() => {
      switch (action) {
         case 'ADD':
            return 'Dodaj komentarz';
         case 'EDIT':
            return 'Edytuj komentarz';
         case 'DELETE':
            return 'Usuń komentarz';
      }
   }, [action]);

   const handleActionClick = useCallback(() => {
      switch (action) {
         case 'ADD':
            postComment();
            break;
         case 'EDIT':
            editComment();
            break;
         case 'DELETE':
            deleteComment();
            break;
      }
   }, [editComment, postComment, deleteComment, action]);

   return (
      <CustomModal
         {...props}
         {...(action === 'DELETE'
            ? {
                 icon: 'TRASH',
                 innerTitle: 'Usuń komentarz',
                 onClose: closeModalHandler,
              }
            : {
                 title: renderTitle(),
                 onClose: handleShowCloseWarning,
                 closeWarningModalProps: {
                    opened: showCloseWarning,
                    onClose: handleHideCloseWarning,
                    onConfirm: closeModalHandler,
                 },
              })}
         error={errorInfo}
         isLoading={isLoading}
         primaryButtonProps={{
            text: renderTitle(),
            variant: action === 'DELETE' ? 'filled-danger' : 'filled-primary',
            onClick: handleActionClick,
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: action === 'DELETE' ? 'outlined-danger' : 'text',
            onClick: action === 'DELETE' ? closeModalHandler : handleShowCloseWarning,
         }}
      >
         <div>
            {action === 'DELETE' ? (
               <div className={styles.deleteModalContent}>
                  <p>Czy na pewno chcesz usunąć ten komentarz?</p>
                  <strong>Tej akcji nie można cofnąć.</strong>
               </div>
            ) : (
               <>
                  <p className={styles.text}>
                     Komentarz <strong>nie będzie</strong> widoczny dla kienta.
                  </p>
                  <StyledTextArea
                     value={commentText}
                     onChange={e => setCommentText(e.target.value)}
                     label="Treść komentarza"
                     placeholder="np. Klient jest nieuprzejmy i często nie płaci za zamówienia."
                  />
               </>
            )}
         </div>
      </CustomModal>
   );
};
export default ClientDetailsCommentModal;
