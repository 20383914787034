import { memo, useState } from 'react';

import { LoadingOverlay } from '@mantine/core';

import { DEFAULT_ERROR_DESCRIPTIONS } from 'constants/errorDescriptions';
import { PALLET_TYPES } from 'constants/palletTypes';
import { STORAGE_TYPES_FOR_PICKER } from 'constants/storageType';
import { useForm } from 'hooks/useForm';
import { BannerType, BannerVariants, Option } from 'interfaces';
import { HTTPService } from 'service';
import { CreateCommercialNetworkRequest, CreateStorageRequest } from 'service/http/requests';
import { Banner } from 'storybook';
import { logNetworkError } from 'utils/logNetworkError';
import { addNetworkCenterFormValidation, addStorageFormValidations } from 'utils/validation';

import { MaskedInput, StyledButton, StyledSelect, StyledTextInput } from '../../shared';

import styles from './AddStorageForm.module.css';

type AddStorageFormProps = {
   onClose: () => void;
   onSuccess: () => void;
};

const AddStorageForm = ({ onSuccess, onClose }: AddStorageFormProps) => {
   const [storageType, setStorageType] = useState<Option | undefined>(undefined);
   const [banner, setBanner] = useState<null | BannerType>(null);
   const [loading, setLoading] = useState<boolean>(false);

   const defaultFields = { street: '', zipCode: '', city: '' };
   const validators =
      storageType?.value === 'COMMERCIAL_NETWORK'
         ? addNetworkCenterFormValidation
         : addStorageFormValidations;
   const initialState =
      storageType?.value === 'COMMERCIAL_NETWORK'
         ? { name: '', phoneNumber: '', contactEmail: '', ...defaultFields }
         : defaultFields;

   const showBannerInModal = (variant: BannerVariants, description: string) =>
      setBanner({ variant, description });

   const handleSubmit = () => {
      setLoading(true);
      const address = {
         street: values.street,
         zipCode: values.zipCode,
         city: values.city,
      };
      const data =
         storageType?.value === 'COMMERCIAL_NETWORK'
            ? ({
                 name: values.name,
                 distributionCenter: {
                    address,
                    contactEmail: values.contactEmail,
                    phoneNumber: values.phoneNumber,
                 },
              } as CreateCommercialNetworkRequest)
            : ({
                 address,
                 type: storageType?.value,
                 defaultPalletsForGivenDay: {
                    [PALLET_TYPES.EDHP_PALLET]: { readyToRent: 0, damaged: 0, lost: 0 },
                    [PALLET_TYPES.EURO_PALLET]: { readyToRent: 0, damaged: 0, lost: 0 },
                    [PALLET_TYPES.HALF_PALLET_NDHP]: { readyToRent: 0, damaged: 0, lost: 0 },
                    [PALLET_TYPES.QUARTER_PALLET_CDHP]: { readyToRent: 0, damaged: 0, lost: 0 },
                 },
              } as CreateStorageRequest);
      (storageType?.value === 'COMMERCIAL_NETWORK'
         ? HTTPService.createCommercialNetwork(data as CreateCommercialNetworkRequest)
         : HTTPService.createStorage(data as CreateStorageRequest)
      )
         .then(onSuccess)
         .catch(error => {
            logNetworkError(error);
            showBannerInModal('error', DEFAULT_ERROR_DESCRIPTIONS.UNKNOWN);
         })
         .finally(() => setLoading(false));
   };

   const { values, changeHandler, submitHandler, touched, errorsList } = useForm({
      initialState,
      validations: validators,
      onSubmit: handleSubmit,
   });

   const renderForm = () => {
      const defaultInputs = (
         <>
            <StyledTextInput
               type="text"
               label="Ulica, numer domu, numer mieszkania"
               onChange={changeHandler}
               name="street"
               helperText={touched.street && errorsList.street}
               error={touched.street && errorsList.street}
               fullWidth
            />
            <div className={styles.inputRow}>
               <MaskedInput
                  type="text"
                  name="zipCode"
                  label="Kod pocztowy"
                  value={values.zipCode}
                  helperText={touched.zipCode && errorsList.zipCode}
                  mask="99-999"
                  className={styles.zipCodeInput}
                  onChange={changeHandler}
                  error={touched.zipCode && errorsList.zipCode}
               />
               <StyledTextInput
                  className={styles.city}
                  type="text"
                  label="Miejscowość"
                  onChange={changeHandler}
                  name="city"
                  helperText={touched.city && errorsList.city}
                  error={touched.city && errorsList.city}
               />
            </div>
         </>
      );

      switch (storageType?.value) {
         case 'COMMERCIAL_NETWORK':
            return (
               <>
                  <StyledTextInput
                     type="text"
                     label="Nazwa Sieci Handlowej"
                     onChange={changeHandler}
                     name="name"
                     helperText={touched.name && errorsList.name}
                     error={touched.name && errorsList.name}
                     fullWidth
                  />
                  <h3 className={styles.distributionHeader}>Centrum Dystrybucyjne</h3>
                  {defaultInputs}
                  <div className={styles.inputsContainer}>
                     <StyledTextInput
                        type="number"
                        label="Numer telefonu (opcjonalnie)"
                        onChange={changeHandler}
                        name="phoneNumber"
                        helperText={
                           (touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber
                        }
                        error={
                           (touched.contactEmail || touched.phoneNumber) && errorsList.phoneNumber
                        }
                        fullWidth
                     />
                     <StyledTextInput
                        type="text"
                        label="E-mail kontaktowy (opcjonalnie)"
                        onChange={changeHandler}
                        name="contactEmail"
                        helperText={
                           (touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail
                        }
                        error={
                           (touched.phoneNumber || touched.contactEmail) && errorsList.contactEmail
                        }
                        fullWidth
                     />
                  </div>
               </>
            );
         case 'INTERNAL':
         case 'EXTERNAL':
            return <div className={styles.inputsContainer}>{defaultInputs}</div>;
         default:
            return null;
      }
   };

   return (
      <form className={styles.form} onSubmit={submitHandler}>
         {banner && (
            <Banner
               className={styles.banner}
               variant={banner?.variant}
               children={banner?.description}
            />
         )}
         <div className={styles.formContent}>
            <StyledSelect
               label="Wybierz typ magazynu"
               onChange={setStorageType}
               options={STORAGE_TYPES_FOR_PICKER}
               value={storageType}
            />
            {renderForm()}
            <LoadingOverlay
               visible={loading}
               overlayBlur={10}
               loaderProps={{ color: 'var(--light-green)' }}
            />
         </div>
         <div className={styles.actions}>
            <StyledButton type="button" variant="text" text="Anuluj" onClick={onClose} />
            <StyledButton
               variant="filled-primary"
               text="Dodaj punkt magazynowy"
               disabled={!!Object.keys(errorsList).length}
            />
         </div>
      </form>
   );
};

export default memo(AddStorageForm);
