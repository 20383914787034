import { DateTime } from 'luxon';

import { DATE_FORMAT } from 'constants/dateFormats';

export const parseDate = (date: string, format?: string) =>
   DateTime.fromFormat(date, format || DATE_FORMAT).toJSDate();

export const joinDateAndTime = (inputDate: Date, inputTime: Date) => {
   const { minute, hour } = DateTime.fromJSDate(inputTime).toObject();
   return DateTime.fromJSDate(inputDate).set({ hour, minute });
};
