import { FocusEvent, useCallback, useEffect, useMemo } from 'react';

import { CustomModal, CustomModalProps } from 'components/shared';
import { PALLET_PRICE_LABELS } from 'constants/palletPriceLabels';
import { palletTypesData } from 'constants/palletTypes';
import { useForm } from 'hooks/useForm';
import { useModal } from 'hooks/useModal';
import { PalletContractPrices, PalletCustomPrices, PalletType } from 'interfaces';
import { Banner } from 'storybook';
import { formatNumberToPrice, formatStringToPrice } from 'utils/functions';
import { changePalletsPricesFormValidation, isNumeric } from 'utils/validation';

import ChangePalletPriceCard from './ChangePalletPriceCard/ChangePalletPriceCard';
import styles from './ChangePalletsPricesModal.module.css';

interface ChangePalletsPricesModalProps extends CustomModalProps {
   availablePalletTypes: PalletType[];
   address?: string;
   palletContractPrices: PalletContractPrices | null;
   palletCustomPrices: PalletCustomPrices;
   onSubmitChanges: (customPrices: { [key: string]: string }) => void;
}

const ChangePalletsPricesModal = ({
   availablePalletTypes,
   address,
   palletContractPrices,
   palletCustomPrices,
   onSubmitChanges,
   onClose,
   ...props
}: ChangePalletsPricesModalProps) => {
   const onSubmit = () => {
      onSubmitChanges(values);
      closeModalHandler();
   };

   const customPricesData = useMemo(
      () => ({
         edhpPalletPrice:
            palletCustomPrices.edhpPalletPrice === 0
               ? ''
               : formatNumberToPrice(palletCustomPrices.edhpPalletPrice),
         euroPalletPrice:
            palletCustomPrices.euroPalletPrice === 0
               ? ''
               : formatNumberToPrice(palletCustomPrices.euroPalletPrice),
         halfPalletNDHPPrice:
            palletCustomPrices.halfPalletNDHPPrice === 0
               ? ''
               : formatNumberToPrice(palletCustomPrices.halfPalletNDHPPrice),
         quarterPalletCDHPPrice:
            palletCustomPrices.quarterPalletCDHPPrice === 0
               ? ''
               : formatNumberToPrice(palletCustomPrices.quarterPalletCDHPPrice),
      }),
      [palletCustomPrices],
   );

   const {
      values,
      setValues,
      changeHandler,
      errorsList,
      submitHandler,
      clearForm,
      changeFieldValue,
   } = useForm({
      initialState: customPricesData,
      validations: changePalletsPricesFormValidation,
      onSubmit,
   });

   const handlePriceBlur = (event: FocusEvent<HTMLInputElement>, fieldName: string) => {
      const enteredValue = event.target.value;
      const isNumericValue = isNumeric(enteredValue);
      if (!isNumericValue || !enteredValue) {
         return;
      }
      const formattedValue = formatStringToPrice(enteredValue);
      changeFieldValue(formattedValue, fieldName);
   };

   const handleCloseModal = useCallback(() => {
      clearForm();
      onClose();
   }, [clearForm, onClose]);

   const { showCloseWarning, handleShowCloseWarning, handleHideCloseWarning, closeModalHandler } =
      useModal({ onClose: handleCloseModal });

   useEffect(() => {
      setValues(customPricesData);
   }, [customPricesData, setValues]);

   const availablePalletTypeItems = useMemo(
      () => palletTypesData.filter(palletType => availablePalletTypes.includes(palletType.name)),
      [availablePalletTypes],
   );

   return (
      <CustomModal
         {...props}
         onClose={handleShowCloseWarning}
         title="Edytuj cenę"
         primaryButtonProps={{
            text: 'Zmień cenę',
            variant: 'filled-primary',
         }}
         secondaryButtonProps={{
            text: 'Anuluj',
            variant: 'text',
            onClick: handleShowCloseWarning,
         }}
         closeWarningModalProps={{
            opened: showCloseWarning,
            onClose: handleHideCloseWarning,
            onConfirm: closeModalHandler,
         }}
         onSubmit={submitHandler}
      >
         <Banner
            variant="info"
            children={
               <>
                  <p>Cena zmieni się tylko w obecnym zamówieniu.</p>
                  <p>Zostawienie pustego pola nie zmieni początkowej ceny.</p>
               </>
            }
            fullWidth
            className={styles.infoBanner}
         />
         <div className={styles.palletCardsContainer}>
            {availablePalletTypeItems.map(palletTypeItem => {
               const deliveryPrice =
                  address && palletContractPrices?.[palletTypeItem.name]
                     ? palletContractPrices?.[palletTypeItem.name].deliveryPrice[address]
                     : undefined;
               return (
                  <ChangePalletPriceCard
                     key={palletTypeItem.name}
                     name={palletTypeItem.name}
                     title={palletTypeItem.label}
                     dimensions={palletTypeItem.dimensions}
                     contractPrice={
                        address
                           ? deliveryPrice
                           : palletContractPrices?.[palletTypeItem.name].personalPickUpPrice
                     }
                     value={values[PALLET_PRICE_LABELS[palletTypeItem.name]]}
                     onPriceChange={changeHandler}
                     onPriceBlur={handlePriceBlur}
                     inputName={PALLET_PRICE_LABELS[palletTypeItem.name]}
                     errors={errorsList}
                     className={styles.palletCard}
                  />
               );
            })}
         </div>
      </CustomModal>
   );
};

export default ChangePalletsPricesModal;
