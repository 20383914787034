import { ChangeEvent } from 'react';

import { SectionContainer, StyledTextInput } from 'components/shared';

import styles from './AddInternalOrderIdSection.module.css';

type AddInternalOrderIdSectionProps = {
   internalOrderId: string;
   onChangeInternalOrderId: (event: ChangeEvent<HTMLInputElement>) => void;
};

const AddInternalOrderIdSection = ({
   internalOrderId,
   onChangeInternalOrderId,
}: AddInternalOrderIdSectionProps) => (
   <SectionContainer title="Wewnętrzny numer zamówienia">
      <p className={styles.infoText}>
         Jeżeli Twoja firma obsługuje własne numery zamówień to wpisz go poniżej. Ten numer pojawi
         się również na fakturze.
      </p>
      <StyledTextInput
         name="internalOrderId"
         value={internalOrderId}
         type="text"
         onChange={onChangeInternalOrderId}
         label="Wewnętrzny numer zamówienia (opcjonalnie)"
         className={styles.input}
      />
   </SectionContainer>
);

export default AddInternalOrderIdSection;
